<app-confirmation-modal>
  <ng-container subHeader><strong>Are you sure you want to deny credentials?</strong></ng-container>
  <ng-container content>Denying credentials will remove the participant from the session.</ng-container>

  <ng-container actionButton>
    <button type="button" class="btn btn--outline-dark" (click)="this.backToIdVerificationView()">
      Go Back
    </button>
    <button type="button" class="btn btn--confirm dark-green" (click)="this.confirmRejoinDenial()">
      Deny Credentials
    </button>
  </ng-container>
</app-confirmation-modal>
