import { createAction, props } from '@ngrx/store';

export const AttemptSignalRHubConnection = createAction(
  '[SignalR] Attempt SignalR Hub Connection',
  props<{ payload: { packageUserGuid: string } }>()
);

export const SignalRHubConnectionSuccessful = createAction(
  '[SignalR] SignalR Hub Connection Successful',
  props<{ payload: { packageUserGuid: string } }>()
);

export const SignalRConnectionReconnecting = createAction(
  '[SignalR] SignalR Hub Connection Reconnecting',
  props<{ payload: { errorMessage: string } }>()
);

export const SignalRConnectionReconnected = createAction(
  '[SignalR] SignalR Hub Connection Reconnected',
  props<{ payload: { connectionId: string; packageUserGuid: string } }>()
);

export const SignalRConnectionClosed = createAction(
  '[SignalR] SignalR Hub Connection Closed',
  props<{ payload: { errorMessage: string } }>()
);

export const AttemptSignalRRoomConnection = createAction(
  '[SignalR] Attempt SignalR Room Connection',
  props<{ payload: { packageUserGuid: string } }>()
);

export const SignalRRoomJoined = createAction('[SignalR] SignalR Room Joined');

export const StopSignalRConnection = createAction(
  '[SignalR] Attempt to Stop SignalR Hub Connection'
);

export const LeaveSignalRRoom = createAction(
  '[SignalR] Leave SignalR Room',
  props<{ payload: { packageUserGuid: string } }>()
);

export const SignalRRoomLeft = createAction('[SignalR] SignalR Room Left');

export const ValidateSignalRConnectionForWizardUser = createAction(
  '[SignalR] Validate SignalR Connection for Wizard User'
);

export const StartSignalRHubConnection = createAction(
  '[SignalR] Start SignalR Hub Connection',
  props<{ payload: { packageUserGuid: string } }>()
);

export const RestartHubConnection = createAction('[SignalR] Restart Hub Connection');
