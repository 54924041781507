import { Injectable, OnDestroy } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { filter, takeUntil, tap } from 'rxjs/operators';

import { RootStoreState } from 'src/app/store';
import { ViewerScale } from '../../models';

import { DocumentsSelectors } from '../../store';

@Injectable()
export class ViewportLocationStandardizationService implements OnDestroy {
  constructor(private readonly store: Store<RootStoreState.State>) {
    this.store
      .pipe(
        takeUntil(this.onDestoryNotifier),
        select(DocumentsSelectors.getViewerScale),
        filter<ViewerScale>(Boolean),
        tap((scale) => (this.documentScale = scale))
      )
      .subscribe();
  }

  onDestoryNotifier = new Subject();
  documentScale: ViewerScale;

  public convertToLocalYPosition(standardizedYPosition: number): number {
    return this.roundToTwoDecimal(standardizedYPosition * this.documentScale.scale);
  }

  public convertToStandardizedYPosition(localYPosition: number, widgetOffset: number): number {
    let yPosition: number;
    const documentYPosition = localYPosition - widgetOffset;
    if (this.documentScale.scale === 1) {
      yPosition = this.roundToTwoDecimal(documentYPosition);
    } else {
      const reverseRatio = 1 / this.documentScale.scale;
      const valueToRound = documentYPosition * reverseRatio;
      yPosition = this.roundToTwoDecimal(valueToRound);
    }

    return yPosition;
  }

  public roundToTwoDecimal(valueToRound: number): number {
    return Math.round((valueToRound + Number.EPSILON) * 100) / 100;
  }

  ngOnDestroy() {
    this.onDestoryNotifier.next(undefined);
    this.onDestoryNotifier.complete();
  }
}
