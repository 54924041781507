export interface EndorsementSelections {
  endorsements: Array<EndorsementSelection>;
  selectedEndorsement: EndorsementSelection;
}

export interface EndorsementSelection {
  fontName: string;
  endorsementImage: string;
  staticSelection: boolean;
}
