import { Directive, ElementRef, Input, OnInit } from '@angular/core';

import { Feature } from '../models';
import { FeatureManagementService } from '../services';

@Directive({
  selector: '[appRemoveIfCachedFeatureDisabled]'
})
export class RemoveIfCachedFeatureDisableDirective implements OnInit {
  @Input('appRemoveIfCachedFeatureDisabled') featureName: Feature;

  constructor(private readonly el: ElementRef,
    private readonly featureManagementService: FeatureManagementService) {
  }

  ngOnInit() {
    if (!this.featureManagementService.getIsFeatureEnabledWithCaching(this.featureName)) {
      this.el.nativeElement.parentNode.removeChild(this.el.nativeElement);
    }
  }
}
