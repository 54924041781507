import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { delayedRetry } from 'src/app/operators/delayed-retry.operator';
import { ApplicationInsightsService } from 'src/app/services/application-insights.service';
import { RootStoreState } from 'src/app/store';

import { PackagesActions } from '../../packages';
import { SignalRService } from '../../signal-r';
import {
  CurrentUserKbaAttempts,
  KbaAnswers,
  KbaFailureReason,
  KbaProductType,
  KbaResponse,
  KBATransactionStatus,
} from '../models';

@Injectable()
export class KnowledgeBasedAuthenticationService {
  private listenerConfigured = false;
  private packageUserGuid: string;
  private wizardUserGuid: string;

  constructor(
    private readonly httpClient: HttpClient,
    private readonly applicationInsightsService: ApplicationInsightsService,
    private readonly store: Store<RootStoreState.State>,
    private readonly signalRService: SignalRService
  ) {}

  public putViewInfoPage(packageUserGuid: string) {
    return this.httpClient
      .put(`knowledgeBasedAuthentication/packageUsers/${packageUserGuid}/viewInfo`, null)
      .pipe(
        delayedRetry(1000, 2),
        catchError((res) => throwError(res))
      );
  }

  public getQuestions(packageGuid: string, packageUserGuid: string, deviceCode: string) {
    return this.httpClient
      .get<KbaResponse>(
        `knowledgeBasedAuthentication/packageUsers/${packageUserGuid}/devices/${deviceCode}/questions`
      )
      .pipe(
        delayedRetry(1000, 2),
        catchError((res) => throwError(res))
      );
  }

  public getNumberOfKbaAttempts(packageUserGuid: string) {
    return this.httpClient
      .get<CurrentUserKbaAttempts>(
        `knowledgeBasedAuthentication/packageUsers/${packageUserGuid}/numberOfAttempts`
      )
      .pipe(
        delayedRetry(1000, 2),
        catchError((res) => throwError(res))
      );
  }

  public answerQuestions(
    packageGuid: string,
    packageUserGuid: string,
    deviceCode: string,
    kbaAnswers: KbaAnswers
  ) {
    return this.httpClient
      .put<KbaResponse>(
        `knowledgeBasedAuthentication/packageUsers/${packageUserGuid}/devices/${deviceCode}/answers`,
        kbaAnswers
      )
      .pipe(
        delayedRetry(1000, 2),
        catchError((res) => throwError(res))
      );
  }

  public logLexisNexisResponseToAppInsights(
    packageGuid: string,
    packageUserGuid: string,
    deviceCode: string,
    results: KbaResponse
  ) {
    let logMessage: string;

    // Passed KBA questions
    if (results.status.transactionStatus === KBATransactionStatus.Passed) {
      logMessage =
        'LEXISNEXIS_KBA_PASS: The participant has successfully passed LexisNexis authentication. Request Id: ' +
        results.status.requestId +
        '.';
    }

    // Disambiguous question passed
    else if (results.status.transactionStatus === KBATransactionStatus.Pending) {
      logMessage =
        'LEXISNEXIS_KBA_DISAMBIGUOUS_QUESTION_PASS: The participant has successfully answered the disambiguous question(s). Request Id: ' +
        results.status.requestId +
        '.';
    }

    // Disambiguous question failure
    else if (
      results.status.transactionStatus === KBATransactionStatus.Failed &&
      results.productType === KbaProductType.DISCOVERY
    ) {
      logMessage =
        'LEXISNEXIS_KBA_DISAMBIGUOUS_QUESTION_FAIL: The participant failed to correctly answer the disambiguous question(s). The order has been cancelled. Request Id: ' +
        results.status.requestId +
        '.';
    }

    // Failed question set on 1st attempt
    else if (results.status.transactionStatus === KBATransactionStatus.Failed1stAttempt) {
      logMessage =
        'LEXISNEXIS_KBA_1ST_ATTEMPT_FAIL: The 1st attempt at answering LexisNexis security questions has failed. A new transaction will start. Request Id: ' +
        results.status.requestId +
        '.';
    }

    // Failed final set of questions
    else if (results.status.transactionStatus === KBATransactionStatus.Failed) {
      logMessage =
        'LEXISNEXIS_KBA_FINAL_ATTEMPT_FAIL: The final attempt at answering LexisNexis security questions has failed. All attempts have been exhausted and the order has been cancelled. Request Id: ' +
        results.status.requestId +
        '.';
    }

    // Time expired for answering 1st set of questions
    else if (results.status.transactionStatus === KBATransactionStatus.Expired1stAttempt) {
      logMessage =
        'LEXISNEXIS_KBA_1ST_TRANSACTION_EXPIRED: The 1st KBA transaction has expired. A new transaction will start. Request Id: ' +
        results.status.requestId +
        '.';
    }

    // Time expired for answering final set of questions
    else if (results.status.transactionStatus === KBATransactionStatus.Expired) {
      logMessage =
        'LEXISNEXIS_KBA_TRANSACTION_EXPIRED: The final LexisNexis KBA transaction has expired. All attempts have been exhausted and the order has been cancelled. Request Id: ' +
        results.status.requestId +
        '.';
    }

    // catch all
    else {
      logMessage =
        'LEXISNEXIS_KBA_UNKNOWN_STATUS: A status of ' +
        results.status.transactionStatus +
        '  was returned from LexisNexis while processing the answers for KBA Request Id: ' +
        results.status.requestId +
        '.';

      this.applicationInsightsService.logEvent(logMessage, {
        lexisnexisRequestId: results.status.requestId,
        transactionStatus: results.status.transactionStatus,
        transactionReasonCode: results.status.transactionReasonCode.code,
        packageUserGuid: packageUserGuid,
        packageGuid: packageGuid,
        deviceCode: deviceCode,
      });

      return;
    }

    this.applicationInsightsService.logEvent(logMessage, {
      lexisnexisRequestId: results.status.requestId,
      transactionStatus: results.status.transactionStatus,
      packageUserGuid: packageUserGuid,
      packageGuid: packageGuid,
      deviceCode: deviceCode,
    });
  }

  public configureListeners(packageUserGuid: string, wizardUserGuid: string) {
    if (!this.listenerConfigured) {
      this.packageUserGuid = packageUserGuid;
      this.wizardUserGuid = wizardUserGuid;

      this.signalRService.hubConnection.on('KnowledgeBasedAuthenticationFailedEvent', (data) => {
        this.dispatchKbaFailed(data);
      });

      this.signalRService.hubConnection.on(
        'KnowledgeBasedAuthenticationDisambiguateFailedEvent',
        (data) => {
          this.dispatchKbaFailed(data);
        }
      );

      this.signalRService.hubConnection.on('KnowledgeBasedAuthenticationExpiredEvent', (data) => {
        this.dispatchKbaFailed(data);
      });

      this.listenerConfigured = true;
    }
  }

  // If SignalR is broadcasting that a knowledge base authentication has failed, and the current user
  // is the user that failed kba, then don't dispatch KnowledgeBasedAuthenticationActions.KnowledgeBasedAuthenticationFailed
  // because it has already been dispatched by KnowledgeBasedAuthenticationEffects.answerKbaQuestions
  // The other participants should always be taken to the 'A participant has failed KBA' screen, so hardcode the
  // transaction reason code to KbaFailureReason.FAILEDAUTHQUESTIONS
  dispatchKbaFailed(data: any) {
    if (
      data.packageUserGuid !== this.packageUserGuid &&
      data.packageUserGuid !== this.wizardUserGuid
    ) {
      this.store.dispatch(
        PackagesActions.RedirectKbaFailure({
          transactionReasonCode: KbaFailureReason.FAILEDAUTHQUESTIONS,
        })
      );
    }
  }
}
