<img
  class="feedback-image"
  src="../../../assets/images/appointment.svg"
  alt=""
  data-testid="early-pic"
/>
<div class="message-container">
  <h1 class="message-container__header" data-testid="early-header">You’re early!</h1>
  <p class="message-container__message" data-testid="early-text">
    {{ productSpecificMessage$ | async }}
  </p>
  <app-further-assistance [showDefaultClientInfo]="true"></app-further-assistance>
</div>
