import { Action, createReducer, on } from '@ngrx/store';

import { PackageUsersActions } from 'src/app/features/package-users';
import { PackageScheduleStatus } from '../../models';
import { PackagesActions } from '../actions';

import { PackagesState } from '../state';

const reducer = createReducer(
  PackagesState.initialState,
  on(
    PackagesActions.SetPackage,
    (state: PackagesState.State, { payload }) =>
      ({
        ...state,
        package: payload,
        activePackageGuid: payload.packageGuid,
        activePackageId: payload.packageId,
        activePackageState: payload.stateCode,
        activePackageStatus: payload.statusCode,
        productType: payload.productType,
      } as PackagesState.State)
  ),
  on(
    PackagesActions.FetchPackageScheduledStatusSuccess,
    (state: PackagesState.State, { payload }) => ({
      ...state,
      scheduleStatus: payload.statusCode,
    })
  ),
  on(PackagesActions.ValidatePackage, (state: PackagesState.State) => ({
    ...state,
    scheduleStatus: null,
    productType: null,
    isValid: null,
  })),
  on(PackagesActions.SetMonthlyClosings, (state: PackagesState.State, { payload }) => ({
    ...state,
    totalMonthlyClosings: payload.totalMonthlyClosings,
  })),
  on(PackagesActions.SetCancelPackage, (state: PackagesState.State) => ({
    ...state,
    scheduleStatus: PackageScheduleStatus.CANCELLED,
  })),
  on(PackagesActions.SetCompletePackage, (state: PackagesState.State) => ({
    ...state,
    scheduleStatus: PackageScheduleStatus.COMPLETE,
  })),
  on(PackagesActions.ClearScheduleStatus, (state: PackagesState.State) => ({
    ...state,
    scheduleStatus: null,
  })),
  on(PackagesActions.ValidatePackageSuccessful, (state: PackagesState.State, { payload }) => ({
    ...state,
    scheduleStatus: payload.scheduleStatus,
    productType: payload.productType,
  })),
  on(PackagesActions.SetPackageIsValid, (state: PackagesState.State, { payload }) => ({
    ...state,
    isValid: payload.isValid,
  })),
  on(
    PackageUsersActions.SetActivePackageAndUserGuids,
    (state: PackagesState.State, { payload }) => ({
      ...state,
      activePackageGuid: payload.activePackageGuid,
    })
  ),
  on(PackagesActions.SetIsPreSign, (state: PackagesState.State, { payload }) => ({
    ...state,
    isPreSign: payload.isPreSign,
  })),
  on(PackagesActions.FetchOptOutReasonsSuccess, (state: PackagesState.State, { payload }) => ({
    ...state,
    optOutReasons: payload.typeLookups,
  })),
  on(PackagesActions.UpdatePackageStatus, (state: PackagesState.State, { payload }) => ({
    ...state,
    activePackageStatus: payload.status,
  }))
);

export function packageReducer(state: PackagesState.State | undefined, action: Action) {
  return reducer(state, action);
}
