<input
  type="checkbox"
  [ngClass]="{
    'checkbox-endorsement': true,
    'checkbox-endorsement__checked': isChecked,
    'checkbox-endorsement__disabled': notCheckable,
    'checkbox-endorsement__disabled--unchecked': notCheckableOnDevice && !isChecked
  }"
  [tabindex]="disableFocus || notCheckable || notCheckableOnDevice ? -1 : 0"
  [hidden]="visible"
  appDebounceClick (debouncedClick)="endorsementClicked($event)" [debounceTimer]="500"
  [attr.data-testid]="'endorsement-checkbox-' + this.endorsementLocation?.documentEndorsementLocationId"
  [disabled]="shouldDisable"
/>
