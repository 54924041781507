import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Params, Router, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';

import { combineLatest, Observable, of } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';

import { LobbyActions } from 'src/app/features/lobby';
import { PackageUsersSelectors } from 'src/app/features/package-users';
import { PackagesActions, PackagesSelectors } from 'src/app/features/packages';
import { PackageScheduleStatus } from 'src/app/features/packages/models';
import { INITIAL_ROUTE_PATH_SESSION_KEY } from 'src/app/features/shared/constants';

import { RootStoreState } from '../store';

@Injectable({
  providedIn: 'root',
})
export class UrlTamperingGuard {
  constructor(
    private readonly router: Router,
    private readonly store: Store<RootStoreState.State>
  ) {}

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const initialPath = sessionStorage.getItem(INITIAL_ROUTE_PATH_SESSION_KEY)?.toLowerCase();
    const path = state.url
      .split('/')
      .filter((seg) => !!seg)
      .shift()
      ?.toLowerCase();

    if (!initialPath) {
      sessionStorage.setItem(INITIAL_ROUTE_PATH_SESSION_KEY, path);
    } else if (initialPath === 'consumer-portal') {
      this.router.navigate(['/invalid-link'], { replaceUrl: true });
      return false;
    }

    this.store.dispatch(PackagesActions.ClearScheduleStatus());
    this.store.dispatch(PackagesActions.FetchPackageScheduledStatus());

    return this.executeRedirect(route.queryParams);
  }

  public executeRedirect(queryParams: Params): Observable<boolean> {
    return combineLatest([
      this.store.select(PackagesSelectors.getPackageScheduleStatus),
      this.store.select(PackagesSelectors.getActivePackageGuid),
      this.store.select(PackageUsersSelectors.getActivePackageUserGuid),
      this.store.select(PackageUsersSelectors.getIsActivePackageUserSigningAgent),
    ]).pipe(
      filter(([scheduleStatus]) => !!scheduleStatus),
      switchMap(
        ([scheduleStatus, activePackageGuid, activePackageUserGuid, isActiveUserSigningAgent]) => {
          const queryPackageGuid = queryParams['package'];
          const queryUserGuid = queryParams['user'];

          if (
            this.packageOrUserIsNullOrChanged(
              activePackageGuid,
              activePackageUserGuid,
              queryPackageGuid,
              queryUserGuid,
              isActiveUserSigningAgent
            )
          ) {
            this.store.dispatch(PackagesActions.RedirectInValidLink({ queryParams }));
            return of(false);
          }

          if (scheduleStatus !== PackageScheduleStatus.CURRENT) {
            this.store.dispatch(
              PackagesActions.RedirectInvalidPackageScheduleStatus({
                queryParams: {
                  user: activePackageUserGuid,
                  package: activePackageGuid,
                },
              })
            );
            return of(false);
          }

          return of(true);
        }
      )
    );
  }

  protected packageOrUserIsNullOrChanged(
    activePackageGuid: string,
    activePackageUserGuid: string,
    queryPackageGuid: string,
    queryUserGuid: string,
    isSigningAgent: boolean
  ): boolean {
    if (
      !activePackageGuid ||
      activePackageGuid === '00000000-0000-0000-0000-000000000000' ||
      activePackageGuid?.toUpperCase() !== queryPackageGuid?.toUpperCase()
    ) {
      return true;
    }

    if (
      isSigningAgent &&
      (!activePackageUserGuid ||
        queryUserGuid?.toUpperCase() !== activePackageUserGuid?.toUpperCase())
    ) {
      return true;
    }

    return false;
  }
}
