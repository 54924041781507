import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { SharedModule } from 'src/app/features/shared';
import { WizardModule } from '../wizard/wizard.module';

import { KbaInformationComponent, KbaQuestionsComponent } from './containers';
import { KnowledgeBasedAuthenticationReducers, KnowledgeBasedAuthenticationEffects } from './store';
import {
  KnowledgeBasedAuthenticationService,
  KnowledgeBasedAuthenticationFormsService,
} from './services';

import { CdkStepperModule } from '@angular/cdk/stepper';

import { TimesUpModalComponent } from './components/times-up-modal/times-up-modal.component';
import { UnableToVerifyModalComponent } from './components/unable-to-verify-modal/unable-to-verify-modal.component';
import { PackagesModule } from 'src/app/features/packages';

@NgModule({
  declarations: [
    KbaInformationComponent,
    KbaQuestionsComponent,
    TimesUpModalComponent,
    UnableToVerifyModalComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    ReactiveFormsModule,
    EffectsModule.forFeature([KnowledgeBasedAuthenticationEffects]),
    StoreModule.forFeature(
      'knowledgeBasedAuthentication',
      KnowledgeBasedAuthenticationReducers.knowledgeBasedAuthenticationReducer
    ),
    WizardModule,
    CdkStepperModule,
    PackagesModule,
  ],
  providers: [KnowledgeBasedAuthenticationService, KnowledgeBasedAuthenticationFormsService],
  exports: [KbaInformationComponent, KbaQuestionsComponent],
})
export class KnowledgeBasedAuthenticationModule {}
