import {
  createFeatureSelector,
  createSelector
} from '@ngrx/store';
import { UserTasksState } from '../state';

export const getUserTasksState = createFeatureSelector<UserTasksState.State>(
  'userTasks'
);

export const getActiveUserTasks = createSelector(
  getUserTasksState,
  (state: UserTasksState.State) => state.activeUserTasks
);
