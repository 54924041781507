import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';

import { of } from 'rxjs';
import { catchError, concatMap, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { v4 as uuid } from 'uuid';

import { DeviceGroupSelectors } from 'src/app/features/device-group';
import { EndorsementsActions } from 'src/app/features/endorsements';
import { ModalsActions } from 'src/app/features/modals/store/actions';
import { MsalAuthActions } from 'src/app/features/msal-auth';
import { NotificationsActions, NotificationType } from 'src/app/features/notifications';
import { ExceptionType } from 'src/app/features/notifications/models';
import { PackageUsersSelectors } from 'src/app/features/package-users';
import {
  PackagesActions,
  PackagesSelectors,
  PackageStatusPollingService,
} from 'src/app/features/packages';
import { SignalRActions } from 'src/app/features/signal-r';
import { WizardSelectors } from 'src/app/features/wizard/store/selectors';
import { ApplicationInsightsService } from 'src/app/services/application-insights.service';
import { RootStoreState } from 'src/app/store';

import { TimesUpModalComponent } from '../../components/times-up-modal';
import { UnableToVerifyModalComponent } from '../../components/unable-to-verify-modal';
import { KbaAnswers, KbaResponse, KBATransactionStatus } from '../../models';
import { KnowledgeBasedAuthenticationService } from '../../services';
import { KnowledgeBasedAuthenticationActions } from '../actions';
import { KnowledgeBasedAuthenticationSelectors } from '../selectors';
import { concatLatestFrom } from '@ngrx/operators';
import { WizardActions } from 'src/app/features/wizard';

@Injectable()
export class KnowledgeBasedAuthenticationEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly store: Store<RootStoreState.State>,
    private readonly knowledgeBasedAuthenticationService: KnowledgeBasedAuthenticationService,
    private readonly applicationInsightsService: ApplicationInsightsService,
    private readonly packageStatusService: PackageStatusPollingService
  ) {}

  viewKbaInfoPage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(KnowledgeBasedAuthenticationActions.ViewInfoPage),
      concatMap((action) =>
        of(action).pipe(
          withLatestFrom(this.store.pipe(select(WizardSelectors.getActiveWizardUserGuid)))
        )
      ),
      switchMap(([_, activeWizardUserGuid]) => {
        return this.knowledgeBasedAuthenticationService.putViewInfoPage(activeWizardUserGuid).pipe(
          switchMap(() => []),
          catchError((err) =>
            of(
              NotificationsActions.AddNotification({
                payload: {
                  exception: err,
                  notificationType: NotificationType.Error,
                  id: uuid(),
                  text: 'Unable to save info page viewed step',
                  exceptionType: ExceptionType.None,
                },
              })
            )
          )
        );
      })
    )
  );

  fetchNumberOfAttempts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(KnowledgeBasedAuthenticationActions.FetchCurrentUserNumberOfKbaAttempts),
      concatMap((action) =>
        of(action).pipe(
          withLatestFrom(this.store.pipe(select(WizardSelectors.getActiveWizardUserGuid)))
        )
      ),
      switchMap(([_, packageUserGuid]) => {
        return this.knowledgeBasedAuthenticationService
          .getNumberOfKbaAttempts(packageUserGuid)
          .pipe(
            map((result) => {
              return KnowledgeBasedAuthenticationActions.SetCurrentUserNumberOfKbaAttempts({
                payload: result.numberOfAttempts,
              });
            }),

            catchError((err) =>
              of(
                NotificationsActions.AddNotification({
                  payload: {
                    exception: err,
                    notificationType: NotificationType.Error,
                    id: uuid(),
                    text: 'Failed to retrieve number of kba attempts.',
                    exceptionType: ExceptionType.CannotProceed,
                  },
                })
              )
            )
          );
      })
    )
  );

  fetchKbaQuestions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(KnowledgeBasedAuthenticationActions.StartKbaQuestions),
      concatMap((action) =>
        of(action).pipe(
          withLatestFrom(
            this.store.pipe(select(PackagesSelectors.getActivePackageGuid)),
            this.store.pipe(select(WizardSelectors.getActiveWizardUserGuid)),
            this.store.pipe(select(DeviceGroupSelectors.getDeviceCode))
          )
        )
      ),
      switchMap(([action, packageGuid, packageUserGuid, deviceCode]) => {
        return this.knowledgeBasedAuthenticationService
          .getQuestions(packageGuid, packageUserGuid, deviceCode)
          .pipe(
            tap(() => {
              this.applicationInsightsService.logEvent(
                'LEXISNEXIS_TRANSACTION_STARTED: KBA transaction started. New question set received successfully.',
                {
                  packageUserGuid: packageUserGuid,
                  packageGuid: packageGuid,
                  deviceCode: deviceCode,
                }
              );
            }),
            switchMap((payload) => {
              return [
                KnowledgeBasedAuthenticationActions.FetchCurrentUserNumberOfKbaAttempts(),
                KnowledgeBasedAuthenticationActions.SetKbaQuestions({
                  payload,
                }),
              ];
            }),

            catchError((err) =>
              of(
                NotificationsActions.AddNotification({
                  payload: {
                    exception: err,
                    notificationType: NotificationType.Error,
                    id: uuid(),
                    text: 'LEXISNEXIS_START_TRANSACTION_ERROR: Failed to retrieve knowledge-based questions from server successfully.',
                    exceptionType: ExceptionType.CannotProceed,
                  },
                })
              )
            )
          );
      })
    )
  );

  answerKbaQuestions$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(KnowledgeBasedAuthenticationActions.AnswerKbaQuestions),
      concatLatestFrom(() => [
        this.store.select(WizardSelectors.getActiveWizardUserGuid),
        this.store.select(DeviceGroupSelectors.getDeviceCode),
        this.store.select(KnowledgeBasedAuthenticationSelectors.getKbaStatus),
        this.store.select(KnowledgeBasedAuthenticationSelectors.getProductType),
      ]),
      concatMap(([action, activeWizardUserGuid, deviceCode, kbaStatus, productType]) => {
        return this.knowledgeBasedAuthenticationService
          .answerQuestions(null, activeWizardUserGuid, deviceCode, {
            answers: action.payload,
            conversationId: Number(kbaStatus.conversationId),
            productType,
          } as KbaAnswers)
          .pipe(
            map((response: KbaResponse) =>
              KnowledgeBasedAuthenticationActions.KbaAnswersSubmittedSuccess({ response })
            ),
            catchError((error: Error) =>
              of(KnowledgeBasedAuthenticationActions.KbaAnswersSubmittedFailure({ error }))
            )
          );
      })
    );
  });

  handleTransactionStatus$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(KnowledgeBasedAuthenticationActions.KbaAnswersSubmittedSuccess),
      map((action) => {
        switch (action.response.status.transactionStatus) {
          // passed Kba question requirement
          case KBATransactionStatus.Passed:
            return KnowledgeBasedAuthenticationActions.KbaPassed({ response: action.response });
          // pass disambiguous question
          case KBATransactionStatus.Pending:
            return KnowledgeBasedAuthenticationActions.KbaPending({ response: action.response });
          // failed 1st question set attempt, start new transaction
          case KBATransactionStatus.Failed1stAttempt:
            return KnowledgeBasedAuthenticationActions.KbaFailedAttempt();
          // expired (went over 2 minutes) 1st question set attempt, start a new transaction
          case KBATransactionStatus.Expired1stAttempt:
            return KnowledgeBasedAuthenticationActions.KbaExpiredAttempt();
          // expired (went over 2 minutes) final set of questions
          case KBATransactionStatus.Expired:
            return KnowledgeBasedAuthenticationActions.KbaExpired({
              code: action.response.status.transactionReasonCode.code,
            });
        }

        // default/failed to answer final set of questions correctly or disambiguous question
        return KnowledgeBasedAuthenticationActions.KbaFailed({
          code: action.response.status.transactionReasonCode.code,
        });
      })
    );
  });

  updateVerifiedUserGuids$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(KnowledgeBasedAuthenticationActions.KbaPassed),
      concatLatestFrom(() => this.store.select(WizardSelectors.getActiveWizardUserGuid)),
      map(([_, activeWizardUserGuid]) =>
        MsalAuthActions.KBAPassed({ packageUserGuid: activeWizardUserGuid })
      )
    );
  });

  refreshTokenForVerifiedUser$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(KnowledgeBasedAuthenticationActions.KbaPassed),
      concatLatestFrom(() => this.store.select(WizardSelectors.getActiveWizardUserGuid)),
      map(([_, activeWizardUserGuid]) =>
        MsalAuthActions.RefreshTokenForVerifiedUser({
          payload: {
            packageUserGuid: activeWizardUserGuid,
          },
        })
      )
    );
  });

  updateSignableOnDeviceStatusByActiveWizardUserGuid$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(KnowledgeBasedAuthenticationActions.KbaPassed),
      concatLatestFrom(() => this.store.select(WizardSelectors.getActiveWizardUserGuid)),
      map(([_, activeWizardUserGuid]) =>
        EndorsementsActions.UpdateSignableOnDeviceStatusByPackageUserGuid({
          payload: { packageUserGuid: activeWizardUserGuid, enable: true },
        })
      )
    );
  });

  logKbaAnswerSubmissionandResponse$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(KnowledgeBasedAuthenticationActions.KbaAnswersSubmittedSuccess),
        concatLatestFrom(() => [
          this.store.select(PackagesSelectors.getActivePackageGuid),
          this.store.select(WizardSelectors.getActiveWizardUserGuid),
          this.store.select(DeviceGroupSelectors.getDeviceCode),
        ]),
        tap(([action, activePackageGuid, activeWizardUserGuid, deviceCode]) => {
          this.applicationInsightsService.logEvent(
            `LEXISNEXIS_KBA_ANSWER_SUBMISSION:
            Answers to security questions submitted to LexisNexis successfully.
            Request Id: ${action.response.status.requestId}`,
            {
              lexisNexisRequestId: action.response.status.requestId,
              transactionStatus: action.response.status.transactionStatus,
              packageUserGuid: activeWizardUserGuid,
              packageGuid: activePackageGuid,
              deviceCode: deviceCode,
            }
          );
        }),
        tap(([action, activePackageGuid, activeWizardUserGuid, deviceCode]) => {
          this.knowledgeBasedAuthenticationService.logLexisNexisResponseToAppInsights(
            activePackageGuid,
            activeWizardUserGuid,
            deviceCode,
            action.response
          );
        })
      );
    },
    { dispatch: false }
  );

  completeKba$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(KnowledgeBasedAuthenticationActions.KbaPassed),
      tap(() => {
        // reset focus
        if (document.activeElement instanceof HTMLElement) {
          document.activeElement.blur();
        }
      }),
      tap(() => this.packageStatusService.start()),
      map(() => WizardActions.CompleteCurrentTask())
    );
  });

  showUnableToVerifyModal$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(KnowledgeBasedAuthenticationActions.KbaFailedAttempt),
      map(() =>
        ModalsActions.SetStandaloneModalComponent({
          payload: { component: UnableToVerifyModalComponent },
        })
      )
    );
  });

  showTimesUpModal$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(KnowledgeBasedAuthenticationActions.KbaExpiredAttempt),
      map(() =>
        ModalsActions.SetStandaloneModalComponent({
          payload: { component: TimesUpModalComponent },
        })
      )
    );
  });

  redirectToKbaFailure$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        KnowledgeBasedAuthenticationActions.KbaFailed,
        KnowledgeBasedAuthenticationActions.KbaExpired
      ),
      map((action) =>
        PackagesActions.RedirectKbaFailure({
          transactionReasonCode: action.code,
          isFailedUser: true,
        })
      )
    );
  });

  showCannotProceedNotification$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(KnowledgeBasedAuthenticationActions.KbaAnswersSubmittedFailure),
      map((action) =>
        NotificationsActions.AddNotification({
          payload: {
            exception: action.error,
            notificationType: NotificationType.Error,
            id: uuid(),
            text: '[LexisNexis] Failed to submit answers for knowledge-based questions successfully.',
            exceptionType: ExceptionType.CannotProceed,
          },
        })
      )
    );
  });

  configureListeners$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SignalRActions.SignalRRoomJoined),
        concatMap((action) =>
          of(action).pipe(
            withLatestFrom(
              this.store.pipe(select(PackageUsersSelectors.getActivePackageUserGuid)),
              this.store.pipe(select(WizardSelectors.getActiveWizardUserGuid))
            )
          )
        ),
        map(([action, packageUserGuid, wizardUserGuid]) =>
          this.knowledgeBasedAuthenticationService.configureListeners(
            packageUserGuid,
            wizardUserGuid
          )
        )
      ),
    { dispatch: false }
  );
}
