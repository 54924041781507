import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';

import { SharedModule } from 'src/app/features/shared';
import { _windowFactory, windowToken } from 'src/app/window.token';

import { AudioVideoConnectionMonitorComponent } from './components';
import { NavigatorMediaDeviceWrapperService } from './services';
import { AudioVideoConnectionMonitorReducers } from './store';

@NgModule({
  declarations: [AudioVideoConnectionMonitorComponent],
  imports: [
    CommonModule,
    SharedModule,
    StoreModule.forFeature(
      'audioVideoConnectionMonitor',
      AudioVideoConnectionMonitorReducers.Reducers.audioVideoConnectionMonitorReducer
    ),
  ],
  providers: [
    NavigatorMediaDeviceWrapperService,
    {
      provide: windowToken,
      useFactory: _windowFactory,
    },
  ],
  exports: [AudioVideoConnectionMonitorComponent],
})
export class AudioVideoConnectionMonitorModule {}
