import { CommonModule } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';

@Component({
  imports: [CommonModule],
  selector: 'app-participant-list-timer',
  standalone: true,
  template: `
    <ng-container *ngIf="status !== 'Not Started' && status !== 'Checked-in'">
      {{ prepend }}{{ getTime(seconds) }}
    </ng-container>
  `,
})
export class ParticipantListTimerComponent implements OnInit, OnDestroy {
  @Input() prepend: string;
  @Input() seconds: number;
  @Input() status: string;

  interval: undefined | ReturnType<typeof setInterval>;
  readonly timer = {
    msPerHour: 3600000,
    msPerMinute: 60000,
    msPerSecond: 1000,
    oneSecond: 1,
  };

  ngOnInit(): void {
    this.interval = setInterval(() => this.updateTimer(), this.timer.msPerSecond);
  }

  ngOnDestroy(): void {
    clearInterval(this.interval);
  }

  getHours(ms: number): string {
    const hours = Math.floor(ms / this.timer.msPerHour);
    return hours > 0 ? `${this.padNumber(hours)}:` : '';
  }

  getMinutes(ms: number): string {
    const minutes = Math.floor((ms % this.timer.msPerHour) / this.timer.msPerMinute);
    return minutes > 0 ? `${this.padNumber(minutes)}:` : '00:';
  }

  getSeconds(ms: number): string {
    const seconds = Math.floor((ms % this.timer.msPerMinute) / this.timer.msPerSecond);
    return seconds > 0 ? `${this.padNumber(seconds)}` : '00';
  }

  getTime(inputSeconds: number) {
    const ms = inputSeconds * this.timer.msPerSecond;

    const seconds = this.getSeconds(ms);
    const minutes = this.getMinutes(ms);
    const hours = this.getHours(ms);

    return `${hours}${minutes}${seconds}`;
  }

  padNumber(number: number): string {
    return number.toString().padStart(2, '0');
  }

  updateTimer(): void {
    this.seconds += this.timer.oneSecond;
  }
}
