<p>
  <strong>Before you exit the pre-signing, a few notes:</strong>
  <br />
  - Your progress has been saved
  <br />
  - You can re-join the pre-signing
</p>
<div class="exit-signing-confirmation__options">
  <app-button type="secondary" (click)="continueSigning()" [dataTestId]="'exit-presign-modal-back'">
    Back to Signing
  </app-button>
  <app-button type="primary" (click)="exitSigning()" [dataTestId]="'exit-presign-modal-exit'">
    Exit Pre-Signing
  </app-button>
</div>
