import { Injectable, OnDestroy } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { BehaviorSubject, EMPTY, from, ReplaySubject, Subject } from 'rxjs';
import { catchError, filter, take, takeUntil, tap, timeout } from 'rxjs/operators';

import { PackagesSelectors } from 'src/app/features/packages';
import { waitForElementToRender } from 'src/app/features/shared/util';
import { SignalRSelectors, SignalRService } from 'src/app/features/signal-r';
import { RootStoreState } from 'src/app/store';
import { ViewerLocation, ViewerLocationDTO } from '../../models';
import { DocumentsActions, DocumentsSelectors } from '../../store';

@Injectable()
export class ViewportLocationSyncService implements OnDestroy {
  private readonly viewerLocation$ = new ReplaySubject<ViewerLocation>(1);
  public listenersConfigured$ = new BehaviorSubject<boolean>(false);
  public listenersConfiguredNotifier = new Subject();
  private readonly onDestroyNotifier = new Subject();
  private packageId: number;
  private currentDocumentId: number;
  private previousDocumentId: number;

  constructor(
    private readonly signalRService: SignalRService,
    private readonly store: Store<RootStoreState.State>
  ) {}

  ngOnDestroy() {
    this.onDestroyNotifier.next(undefined);
    this.onDestroyNotifier.complete();
  }

  private configureDependencies(): void {
    this.store
      .select(PackagesSelectors.getActivePackageId)
      .pipe(
        takeUntil(this.onDestroyNotifier),
        filter<number>(Boolean),
        take(1),
        tap((packageId) => (this.packageId = packageId))
      )
      .subscribe();

    this.store
      .select(SignalRSelectors.selectIsSignalRGroupJoined)
      .pipe(
        takeUntil(this.listenersConfiguredNotifier),
        filter((x) => x),
        tap(() => {
          this.configureListeners();
        })
      )
      .subscribe();

    this.store
      .pipe(
        takeUntil(this.onDestroyNotifier),
        select(DocumentsSelectors.getActivePackageDocumentId),
        filter<number>(Boolean),
        tap((documentId) => {
          if (this.previousDocumentId) {
            this.previousDocumentId = this.currentDocumentId;
          } else {
            this.previousDocumentId = documentId;
          }
          this.currentDocumentId = documentId;
        })
      )
      .subscribe();
  }

  public initializeDocumentLocationSync() {
    this.configureDependencies();
    return this.viewerLocation$;
  }

  public broadcastViewerLocationUpdate(viewerLocation: ViewerLocation): void {
    const standardizedViewerLocation = {
      ...viewerLocation,
      packageId: this.packageId.toString(),
      documentId: this.currentDocumentId,
    } as ViewerLocationDTO;

    this.signalRService.hubConnection.send('SendViewerLocation', standardizedViewerLocation);
  }

  public completeDocumentLocationSync(): void {
    this.removeListeners();
    this.viewerLocation$.complete();
  }

  private configureListeners(): void {
    this.signalRService.hubConnection.on(
      'ReceiveViewerLocation',
      (viewerLocation: ViewerLocationDTO) => {
        if (viewerLocation.documentId === this.currentDocumentId) {
          this.updateScrollEvent(viewerLocation);
          return;
        }

        if (viewerLocation.documentId !== this.previousDocumentId) {
          this.store.dispatch(DocumentsActions.VerifyActiveDocument());
        }
        // Ignore scroll event as it was dispatached for the previous document.
      }
    );
    this.listenersConfigured$.next(true);
  }

  private updateScrollEvent(viewerLocation: ViewerLocationDTO) {
    // waits for the document HTML element to finish rendering
    const subscription = from(waitForElementToRender('.document-rendered-view'))
      .pipe(
        timeout(4000),
        tap(() => {
          const nextViewerLocation = {
            yPosition: viewerLocation.yPosition,
            isBottom: viewerLocation.isBottom,
            isTop: viewerLocation.isTop,
          } as ViewerLocation;
          this.viewerLocation$.next(nextViewerLocation);
          subscription.unsubscribe();
        }),
        catchError(() => {
          subscription.unsubscribe();
          return EMPTY;
        })
      )
      .subscribe();
  }

  private removeListeners(): void {
    this.signalRService.hubConnection.off('ReceiveViewerLocation');
  }
}
