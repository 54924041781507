import { AfterViewInit, ChangeDetectorRef, Component, Input, OnInit, QueryList, ViewChildren} from '@angular/core';
import { select, Store } from '@ngrx/store';
import { combineLatest, map, Observable, take, withLatestFrom } from 'rxjs';
import { NotaryModalComponent } from 'src/app/features/check-in/components/notary-modal/notary-modal.component';
import { ModalsActions, ModalSize } from 'src/app/features/modals';
import { PackageUsersSelectors } from 'src/app/features/package-users';
import { getFullNames, PackageUserWithDisplayName } from 'src/app/features/package-users/util';
import { RootStoreState } from 'src/app/store';
import { SigningRoomActions } from '../../store';
import { AddParticipantComponent } from 'src/app/features/signing-room/components/add-participant';
import { DeviceGroupSelectors } from 'src/app/features/device-group';

@Component({
  selector: 'app-session-add-participant-modal',
  templateUrl: './session-add-participant-modal.component.html',
  styleUrl: './session-add-participant-modal.component.scss'
})
export class SessionAddParticipantModalComponent implements OnInit, AfterViewInit {
  static identifier = 'SessionAddParticipantModalComponent';
  @Input() deviceCode: string;
  @Input() activePackageUserGuid: string;
  @ViewChildren(AddParticipantComponent) appParticipantChildren!: QueryList<AddParticipantComponent>;
  nonSigningAgentsEligibleForAddSession$: Observable<PackageUserWithDisplayName[]>;
  isAddUserButtonEnabled = false;  

  constructor(private readonly store: Store<RootStoreState.State>, private readonly changeDetectorRef: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.nonSigningAgentsEligibleForAddSession$ = 
      this.store.pipe(
      withLatestFrom(
        this.store.pipe(select(DeviceGroupSelectors.getUsersOnDevice)),
        this.store.pipe(select(PackageUsersSelectors.getNonSigningAgents))),
      map(([_, usersOnDevice, nonSAs]) => {
         return getFullNames(nonSAs.filter((nonSA) => !usersOnDevice.some(d => d.packageUserGuid === nonSA.packageUserGuid)));
        })
      );
  }

  ngAfterViewInit() {
    this.onParticipantClicked();
    this.changeDetectorRef.detectChanges();
  }

  back() {
    this.store.dispatch(ModalsActions.ClearModalComponent());
  }

  acceptAddParticipant(event) {  
    this.appParticipantChildren.map((child) => {
      child.isSelected$.pipe(take(1),
      map((isSelected) => isSelected))
        .forEach((isSelected) => {
          if (isSelected) {            
            return this.store.dispatch(SigningRoomActions.PromptUserForAuthentication({
              payload: { participant: child.participant }
            }));
          }
          return []
        });
    });
  }

  onParticipantClicked() {
    let selected = 0;
    
    combineLatest(this.appParticipantChildren.map((child) => child.isSelected$))
    .forEach((child) => {
      child.forEach((isSelected) => {
        if (isSelected) {
          selected++;
        }
      })
    });

    this.isAddUserButtonEnabled = selected > 0;
  }

  openModal() {
    this.store.dispatch(
      ModalsActions.SetStandaloneModalComponent({
        payload: {
          component: NotaryModalComponent,
          modalSize: ModalSize.large,
        },
      })
    );
  }
}
