import { Component, Input } from '@angular/core';

import { IdVerificationResult, IdVerificationStatus } from '../../models';

@Component({
  selector: 'app-id-verification-results',
  templateUrl: './id-verification-results.component.html',
  styleUrls: ['./id-verification-results.component.scss']
})
export class IdVerificationResultsComponent {
  @Input() idVerifyResults: IdVerificationResult;

  get idVerificationStatus(): string {
    switch (this.idVerifyResults.idVerificationStatus) {
      case IdVerificationStatus.Passed: {
        return 'Passed';
      }
      case IdVerificationStatus.Failed: {
        return 'Failed';
      }
      case IdVerificationStatus.Undetermined:
      default: {
        return 'Review';
      }
    }
  }
}
