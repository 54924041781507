import { createFeatureSelector, createSelector } from '@ngrx/store';

import { CheckInStatus, PackageUser, PackageUserRole } from '../../models';
import { PackageUsersState } from '../state';
import { PackageUserWithDisplayName, getFullNames } from '../../util';
import { CurrentCheckInStatus } from 'src/app/features/check-in/models/current-checkin-status.enum';

export const getPackageUserState = createFeatureSelector<PackageUsersState.State>('packageUsers');

export const getActivePackageUser = createSelector(
  getPackageUserState,
  (state: PackageUsersState.State) => state.activePackageUser
);

export const getIsActivePackageUserSigningAgent = createSelector(
  getPackageUserState,
  (state: PackageUsersState.State) =>
    state.activePackageUser?.userRoleCode === PackageUserRole.SIGNINGAGENT ?? false
);

export const getActivePackageUserGuid = createSelector(
  getPackageUserState,
  (state: PackageUsersState.State) => state.activePackageUserGuid?.toUpperCase()
);

export const getPackageUsers = createSelector(
  getPackageUserState,
  (state: PackageUsersState.State) => state?.packageUsers ?? []
);

export const getNonSigningAgents = createSelector(getPackageUsers, (packageUsers: PackageUser[]) =>
  packageUsers?.filter((p) => p.userRoleCode !== PackageUserRole.SIGNINGAGENT)
);

export const getNonSigningAgentGuids = createSelector(
  getNonSigningAgents,
  (packageUsers: PackageUser[]) =>
    packageUsers?.map((packageUser) => String(packageUser.packageUserGuid).toUpperCase())
);

export const getSigningAgent = createSelector(getPackageUsers, (packageUsers: PackageUser[]) =>
  packageUsers?.find((p) => p.userRoleCode === PackageUserRole.SIGNINGAGENT)
);

export const getPackageUser = (packageUserGuid: string) =>
  createSelector(getPackageUsers, (packageUsers: PackageUser[]) =>
    packageUsers?.find((p) => p.packageUserGuid.toLowerCase() === packageUserGuid?.toLowerCase())
  );

export const getActivePinAttemptsRemaining = createSelector(
  [getActivePackageUser],
  (packageUser) => {
    const max = 5;
    const remaining = max - (packageUser.pinAttempts || 0);
    return remaining >= 0 && remaining <= max ? remaining : 0;
  }
);

export const getIsSigningAgent = createSelector(
  getPackageUserState,
  (state: PackageUsersState.State) => state.isSigningAgent
);

export const getActivePackageUserRole = createSelector(
  getPackageUserState,
  (state: PackageUsersState.State) => state.activePackageUser.userRoleCode
);

export const getActivePackageUserDashboardUrl = createSelector(
  getPackageUserState,
  (state: PackageUsersState.State) => state.activePackageUserDashboardUrl
);

export const selectNonSigningAgentsCheckedIn = createSelector(
  getNonSigningAgents,
  (nonSAs: PackageUser[]) => nonSAs?.filter((u) => u.checkInStatus?.isCompleted) ?? []
);

export const selectIsCheckInComplete = createSelector(
  getPackageUsers,
  (packageUsers: PackageUser[]) =>
    packageUsers.length > 0 && packageUsers.every((pu) => pu.checkInStatus?.isCompleted)
);

export const selectNonSigningAgentsCheckingInCount = createSelector(
  getNonSigningAgents,
  (nonSAs: PackageUser[]) => nonSAs?.filter((u) => u.checkInStatus?.isStarted).length ?? 0
);

export const selectNonSigningAgentsCount = createSelector(
  getNonSigningAgents,
  (nonSAs: PackageUser[]) => nonSAs?.length ?? 0
);

export const selectIsSigningAgentCheckedIn = createSelector(
  getSigningAgent,
  (sa: PackageUser) => sa?.checkInStatus?.isCompleted ?? false
);

export const selectAreNonSigningAgentsCheckedIn = createSelector(
  getNonSigningAgents,
  (nonSAs: PackageUser[]) => nonSAs?.every((u) => u.checkInStatus?.isCompleted) ?? false
);

export const selectHasOnlySigningAgentNotCheckedIn = createSelector(
  selectAreNonSigningAgentsCheckedIn,
  selectIsSigningAgentCheckedIn,
  (areNonSasCheckedIn, isSaCheckedIn) => areNonSasCheckedIn && !isSaCheckedIn
);

export const selectPackageUsersCount = createSelector(
  getPackageUsers,
  (packageUsers: PackageUser[]) => packageUsers.length
);

export const selectHasMultipleNonSigningAgents = createSelector(
  selectNonSigningAgentsCount,
  (count: number) => count > 1
);

export const selectNonSigningAgentsNotCheckedIn = createSelector(
  getNonSigningAgents,
  (nonSAs: PackageUser[]) => nonSAs?.filter((u) => !u.checkInStatus?.isCompleted) ?? []
);

export const selectNonSigningAgentsWithFullNames = createSelector(
  getNonSigningAgents,
  (nonSAs: PackageUserWithDisplayName[]) => getFullNames(nonSAs) ?? []
);

export const selectHasActivePackageUser = createSelector(
  getActivePackageUser,
  (activePackageUser: PackageUser) => !!activePackageUser
);

export const selectIsNbthOrWitness = (packageUserGuid: string) =>
  createSelector(
    getPackageUser(packageUserGuid),
    (packageUser: PackageUser) =>
      packageUser?.userRoleCode === PackageUserRole.NBTH ||
      packageUser?.userRoleCode === PackageUserRole.WITNESS
  );

export const selectIsStatusVerifyingIdentity = (packageUserGuid: string) =>
  createSelector(
    getPackageUser(packageUserGuid),
    (packageUser: PackageUser) =>
      packageUser?.checkInStatus?.currentStatus === CurrentCheckInStatus.VERIFYING_IDENTITY
  );

export const selectHasNbthWitnessSubmittedAdditionalDetails = (packageUserGuid: string) =>
  createSelector(
    selectIsNbthOrWitness(packageUserGuid),
    selectIsStatusVerifyingIdentity(packageUserGuid),
    (isNbthOrWitness, isVerifyingIdentity) => isNbthOrWitness && isVerifyingIdentity
  );
