import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { EndorsementBase } from '../../models';

@Component({
  selector: 'app-unsupported-endorsement',
  templateUrl: './unsupported-endorsement.component.html',
  styleUrls: ['./unsupported-endorsement.component.scss'],
})
export class UnsupportedEndorsementComponent extends EndorsementBase implements OnInit {
  displayEndorsementType = true;
  constructor() {
    super();
  }

  ngOnInit() {
    if (environment.production) {
      this.displayEndorsementType = false;
    }
  }
}
