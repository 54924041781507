import { ErrorHandler, Injectable } from '@angular/core';
import { SeverityLevel } from '@microsoft/applicationinsights-web';

import { ApplicationInsightsService } from './application-insights.service';

@Injectable()
export class ErrorHandlerService extends ErrorHandler {
  constructor(private readonly applicationInsightsService: ApplicationInsightsService) {
    super();
  }

  handleError(error: Error) {
    this.applicationInsightsService.logException(error, SeverityLevel.Error);
    console.error(error);
  }
}
