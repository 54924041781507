import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';

import { catchError } from 'rxjs/operators';

import { RulesRequest, RulesResult } from '../models';

@Injectable()
export class RulesService {
  constructor(private readonly httpClient: HttpClient) {}

  public GetRulesResult(request: RulesRequest) {
    return this.httpClient.post<RulesResult>(`rules`, request).pipe(
      catchError((res) => {
        return throwError(res);
      })
    );
  }
}
