import { Component } from '@angular/core';

@Component({
  selector: 'app-feedback-layout',
  templateUrl: './feedback-layout.component.html',
  styleUrls: ['./feedback-layout.component.scss'],
})
export class FeedbackLayoutComponent {
  constructor() {}
}
