import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { VideoComponent } from 'src/app/features/video-conference/chime/components/video/video.component';
import { ChimeEffects, ChimeReducers } from 'src/app/features/video-conference/chime/store';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature('chime', ChimeReducers.Reducers.chimeReducer),
    EffectsModule.forFeature([ChimeEffects.ChimeEffects]),
  ],
  declarations: [VideoComponent],
  exports: [VideoComponent],
})
export class ChimeModule {}
