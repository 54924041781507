import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { AuditLogsSaveService } from './services/audit-logs-save.service';

import { AuditLogsEffects } from './store';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    EffectsModule.forFeature([AuditLogsEffects.AuditLogsEffects]),
  ],
  providers: [AuditLogsSaveService],
})
export class AuditLogsModule {}
