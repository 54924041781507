<div
  class="card"
  [ngClass]="{
    'card-passed': idVerificationStatus === 'Passed',
    'card-review': idVerificationStatus === 'Review',
    'card-failed': idVerificationStatus === 'Failed'
  }"
>
  <div
    class="analysis-header"
  >
    <div class="card-body" >
      <img
        class="margin__right-20"
        *ngIf="idVerificationStatus === 'Passed'"
        src="../../../assets/images/check_circle.svg"
        alt="green check circle"
      />
      <img
        class="margin__right-20"
        *ngIf="idVerificationStatus === 'Failed'"
        src="../../../assets/images/cancel-circle.svg"
        alt="red cancel circle"
      />
      <div class="status-label">Credential Analysis</div>
    </div>
    <div
      class="status-result"
    >
      <strong>{{ idVerificationStatus }}</strong>
    </div>
  </div>
  <div class="card-results" data-private>
    <div *ngIf="idVerifyResults.analysisResults.length === 0">
      No Additional notes
    </div>
    <div *ngFor="let result of idVerifyResults.analysisResults">
      <div>{{ result }}</div>
    </div>
  </div>
</div>
