import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import * as LogRocket from 'logrocket';
import createNgrxMiddleware from 'logrocket-ngrx';

import * as GadgetGuideMetaReducer from 'src/app/features/gadget-guide/store/reducers/gadget-guide.metareducer';
import { AudioVideoCheckReducers } from 'src/app/features/av-check/store/reducers';
import { AudioVideoConnectionMonitorReducers } from 'src/app/features/audio-video-connection-monitor/store';
import { CheckInReducers } from 'src/app/features/check-in/store/reducers';
import { DeviceGroupReducers } from 'src/app/features/device-group/store/reducers';
import { DuplicateSessionDetectorReducers } from 'src/app/features/duplicate-session-detector/store/reducers';
import { MsalAuthModule, MsalAuthReducers } from 'src/app/features/msal-auth';
import { PackageUsersReducers } from 'src/app/features/package-users';
import { PackagesReducers } from 'src/app/features/packages';
import { ParticipantVerificationReducers } from 'src/app/features/participant-verification';
import { PinValidationReducers } from 'src/app/features/pin-validation/store/reducers';
import { SigningRoomReducers } from 'src/app/features/signing-room/store/reducers';
import { UserTasksReducers } from 'src/app/features/user-tasks/store';
import { WizardReducers } from 'src/app/features/wizard/store';

import {
  LogRocketActionSanitizer,
  LogRocketStateSanitizer,
} from '../services/log-rocket/log-rocket-configuration';

const logRocketMiddleware = createNgrxMiddleware(LogRocket, {
  ...LogRocketActionSanitizer,
  ...LogRocketStateSanitizer,
});

const metaReducers = [
  ...CheckInReducers.MetaReducers.metaReducers,
  ...MsalAuthReducers.MetaReducers.metaReducers,
  ...PackagesReducers.MetaReducers.metaReducers,
  ...PackageUsersReducers.MetaReducers.metaReducers,
  ...DeviceGroupReducers.MetaReducers.metaReducers,
  ...SigningRoomReducers.MetaReducers.metaReducers,
  ...AudioVideoCheckReducers.MetaReducers.metaReducers,
  ...GadgetGuideMetaReducer.metaReducers,
  ...AudioVideoConnectionMonitorReducers.MetaReducers.metaReducers,
  ...WizardReducers.MetaReducers.metaReducers,
  ...PinValidationReducers.MetaReducers.metaReducers,
  ...UserTasksReducers.MetaReducers.metaReducers,
  ...ParticipantVerificationReducers.MetaReducers.metaReducers,
  ...DuplicateSessionDetectorReducers.MetaReducers.metaReducers,
  logRocketMiddleware,
];

@NgModule({
  imports: [
    CommonModule,
    MsalAuthModule,
    StoreModule.forRoot(
      {},
      {
        metaReducers: metaReducers,
        runtimeChecks: {
          // Turning on state and action immutibility is causing errors when accessing the modal store.
          // This may require an NGRX and/or Angular upgrade to fix
          strictStateImmutability: false,
          strictActionImmutability: false,
        },
      }
    ),
    EffectsModule.forRoot([]),
    // StoreDevtoolsModule.instrument({
    //   maxAge: 25, // Retains last 25 states
    // }),
  ],
  declarations: [],
})
export class RootStoreModule {}
