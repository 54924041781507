import { Injectable, OnDestroy } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Observable, of, Subscription } from 'rxjs';
import { filter, first, switchMap } from 'rxjs/operators';
import { RootStoreState } from 'src/app/store';
import { MsalAuthSelectors } from '../../msal-auth';
import { IdTokenClaims } from '../../msal-auth/models';
import { ClientActions, ClientSelectors } from '../store';

@Injectable({
  providedIn: 'root'
})
export class ClientLoadedResolver  implements OnDestroy {

  private msalSubscription: Subscription;

  constructor(private readonly store: Store<RootStoreState.State>){}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {

    // Clear out the client defaults in the store and attempt to reload client information from server
    // based on msal token claim
    this.store.dispatch(ClientActions.ClearClientInformation());

    // Call the client API to load client information if the msal token is not null and has the package guid claim (ron, hybrid, etc)
    // or the package user guid claim (consumer portal, presign)
    // If neither claim is present, load the nexsys client defaults into the store
    this.msalSubscription = this.store.pipe(
      select(MsalAuthSelectors.getUser))
      .subscribe(user => {
        const idTokenClaims = user?.idTokenClaims as IdTokenClaims;

        if (idTokenClaims?.extension_PackageGuid) {
          this.store.dispatch(
            ClientActions.LoadClientForPackage({
              payload: { packageGuid: idTokenClaims.extension_PackageGuid },
            })
          );
        }
        else if (idTokenClaims?.extension_PackageUserGuids) {
          this.store.dispatch(
            ClientActions.LoadClientForUser()
          );
        }
    });

    return this.store.pipe(
      select(ClientSelectors.getClientTheme),
      filter(theme => !!theme),
      switchMap(() => of(true)),
      first()
    );
  }

  ngOnDestroy(): void {
    this.msalSubscription.unsubscribe();
  }
}
