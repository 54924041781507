import { Component } from '@angular/core';
import { DEFAULT_APPLICATION_PHONE_NUMBER } from '../../constants';

@Component({
  selector: 'app-support-phone',
  templateUrl: './support-phone.component.html',
  styleUrls: ['./support-phone.component.scss'],
})
export class SupportPhoneComponent {
  phone = DEFAULT_APPLICATION_PHONE_NUMBER;
}
