import { createFeatureSelector, createSelector } from '@ngrx/store';

import { VideoState } from '../state';

export const getVideoState = createFeatureSelector<VideoState.State>('video');

export const getVideoSessionKey = createSelector(getVideoState, (state: VideoState.State) => {
  if (state.videoSessionInfo === null) {
    return null;
  } else {
    return state.videoSessionInfo.videoSessionKey;
  }
});

export const getToken = createSelector(
  getVideoState,
  (state: VideoState.State) => state.videoSessionInfo.token
);

export const getApiKey = createSelector(
  getVideoState,
  (state: VideoState.State) => state.videoSessionInfo.apiKey
);

export const getVideoSessionInfo = createSelector(
  getVideoState,
  (state: VideoState.State) => state.videoSessionInfo
);

export const getIsVideoSessionCreated = createSelector(
  getVideoState,
  (state: VideoState.State) => state.isVideoSessionCreated
);

export const getIsVideoSessionInfoLoaded = createSelector(
  getVideoState,
  (state: VideoState.State) => state?.isVideoSessionInfoLoaded ?? null
);

export const getVideoConnection = createSelector(
  getVideoState,
  (state: VideoState.State) => state.videoConnection
);

export const getAreAllVideoStreamsConnected = createSelector(
  getVideoState,
  (state: VideoState.State) => state.areAllVideoStreamsConnected
);

export const getStopVideo = createSelector(
  getVideoState,
  (state: VideoState.State) => state.stopVideo
);

export const getIgnoreParticipantVideoStream = createSelector(
  getVideoState,
  (state: VideoState.State) => state.ignoreParticipantVideoStream
);

export const getIsAdditonalArchiveEnabled = createSelector(
  getVideoState,
  (state: VideoState.State) => state.isAdditonalArchiveEnabled
);

export const getVideoStreamId = createSelector(
  getVideoState,
  (state: VideoState.State) => state.videoStreamId
);

export const getDownloadVideoArchiveIds = createSelector(
  getVideoState,
  (state: VideoState.State) => state.downloadVideoArchiveIds
);
export const getVideoArchiveStatus = createSelector(
  getVideoState,
  (state: VideoState.State) => state.videoArchiveStatus
);
export const getMultiVideoStorageLocatorUrls = createSelector(
  getVideoState,
  (state: VideoState.State) => state.multiVideoStorageLocatorUrls
);
export const areMultiVideoStorageLocatorUrlsAvailable = createSelector(
  getVideoState,
  (state: VideoState.State) => state.multiVideoStorageLocatorUrls !== null ? state.multiVideoStorageLocatorUrls.length > 0 : false
);
