import {
  EndorsementImagesState,
  endorsementImagesAdapter
} from './EndorsementImages.state';
import {
  EndorsementLocationsState,
  endorsementLocationsAdapter,
} from './EndorsementLocations.state';
import {
  UniqueEndorsementImagesState,
  uniqueEndorsementImagesAdapter,
} from './UniqueEndorsementImages.state';
import {
  SystemEndorsementImagesState,
  systemEndorsementImagesAdapter
} from './SystemEndorsementImages.state';

export interface State {
  isLoading: boolean;
  isLoaded: boolean;
  isReadOnly: boolean;
  EndorsementLocationsState: EndorsementLocationsState;
  EndorsementImagesState: EndorsementImagesState;
  UniqueEndorsementImagesState: UniqueEndorsementImagesState;
  SystemEndorsementImagesState: SystemEndorsementImagesState;
  // TODO: User Story 50434: Remove Old Endorsement Application Logic
  // Rename it to lastSignedEndorsementId instead of deleting
  lastAttemptedEndorsementId?: number;
  // TODO: User Story 50434: Remove Old Endorsement Application Logic
  // Rename it to isProcessingEndorsement instead of deleting
  isAttemptingEndorsement: boolean;
  areAllSystemFieldsApplied: boolean;
  areAllEndorsementsSigned: boolean;
  didSystemFieldsFailToApply: boolean;
  systemFieldApplicationFailures: number;
  lastSystemFieldApplicationAttempt: number;
}

export const initialState: State = {
  isLoading: false,
  isLoaded: false,
  isReadOnly: false,
  EndorsementImagesState: endorsementImagesAdapter.getInitialState(),
  EndorsementLocationsState: endorsementLocationsAdapter.getInitialState(),
  UniqueEndorsementImagesState: uniqueEndorsementImagesAdapter.getInitialState(),
  SystemEndorsementImagesState: systemEndorsementImagesAdapter.getInitialState(),
  lastAttemptedEndorsementId: null,
  isAttemptingEndorsement: false,
  areAllSystemFieldsApplied: false,
  areAllEndorsementsSigned: false,
  didSystemFieldsFailToApply: false,
  systemFieldApplicationFailures: 0,
  lastSystemFieldApplicationAttempt: null,
};
