import {
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { select, Store } from '@ngrx/store';
import { DeviceDetectorService } from 'ngx-device-detector';
import { interval, Subject, Subscription } from 'rxjs';
import { distinctUntilChanged, filter, map, takeUntil, tap } from 'rxjs/operators';

import { PackageUser } from 'src/app/features/package-users';
import { LongPollingConfig } from 'src/app/features/shared/configs';
import {
  CheckInTask,
  CheckInTaskCode,
  CheckInTaskGroup,
  WizardActions,
  WizardSelectors,
} from 'src/app/features/wizard';
import { RootStoreState } from 'src/app/store';

import { IdVerificationActions } from '../../store';

@Component({
  selector: 'app-id-verification-step-group',
  templateUrl: './id-verification-step-group.component.html',
})
export class IdVerificationStepGroupComponent
  implements OnInit, OnDestroy
{
  @Input() public user: PackageUser;
  @Input() public initialTasks: CheckInTask[];

  longPollingSubscription: Subscription;
  isMobile: boolean;
  onDestroyNotifier = new Subject();

  constructor(
    private readonly deviceDetectorService: DeviceDetectorService,
    private readonly store: Store<RootStoreState.State>
  ) {
  }

  ngOnInit() {
    this.isMobile = this.deviceDetectorService.isMobile() || this.deviceDetectorService.isTablet();
    if (this.isMobile && this.hasTask(CheckInTaskCode.IdVerificationInstructions)) {
      this.store.dispatch(
        WizardActions.RemoveTask({ taskCode: CheckInTaskCode.IdVerificationInstructions })
      );
    }

    this.store
      .pipe(
        select(WizardSelectors.getCurrentTask),
        takeUntil(this.onDestroyNotifier),
        filter((task) => !!task),
        map((task) => task.taskGroup),
        distinctUntilChanged(),
        tap((taskGroup) => {
          if (taskGroup === CheckInTaskGroup.IdVerification) {
            this.startLongPolling();
          } else {
            this.stopLongPolling();
          }
        })
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.stopLongPolling();
    this.onDestroyNotifier.next(undefined);
    this.onDestroyNotifier.complete();
  }

  startLongPolling() {
    if (this.longPollingSubscription) {
      return;
    }

    this.longPollingSubscription = interval(LongPollingConfig['10Seconds'].interval)
      .pipe(
        tap(() => {
          this.store.dispatch(IdVerificationActions.GetUserIdVerificationResultsStatus());
        })
      )
      .subscribe();
  }

  stopLongPolling() {
    if (this.longPollingSubscription) {
      this.longPollingSubscription.unsubscribe();
      this.longPollingSubscription = null;
    }
  }

  hasTask(code: string): boolean {
    return this.initialTasks?.some((t) => t.taskType === code && !t.isCompleted);
  }
}
