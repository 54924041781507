import { createFeatureSelector, createSelector } from '@ngrx/store';

import { SignerDetailsState } from '../state';

export const getSignerDetailsState =
  createFeatureSelector<SignerDetailsState.State>('signerDetails');

export const getSignerDetailsStatus = createSelector(
  getSignerDetailsState,
  (state: SignerDetailsState.State) => state.signerDetailsStatus
);

export const areSignerDetailsSubmitted = createSelector(
  getSignerDetailsState,
  (state: SignerDetailsState.State) => state.areSignerDetailsSubmitted
);

export const signerDetailsSubmissionErrors = createSelector(
  getSignerDetailsState,
  (state: SignerDetailsState.State) => state.validationErrors
);

export const states = createSelector(
  getSignerDetailsState,
  (state: SignerDetailsState.State) => state.states
);

export const isRequiredToProvideSignerDetails = createSelector(
  getSignerDetailsState,
  (state: SignerDetailsState.State) =>
    state.signerDetailsStatus?.isRequiredToProvideParticipantDetails ?? false
);

export const isMultifactorChallengeCompleted = createSelector(
  getSignerDetailsState,
  (state: SignerDetailsState.State) =>
    !state.signerDetailsStatus?.isRequiredToCompleteMfaChallenge ?? false
);
