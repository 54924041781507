import { Component, HostListener } from "@angular/core";

@Component({
  selector: 'app-leave-site-warning',
  template: ''
})
export class LeaveSiteWarningComponent {
    @HostListener('window:beforeunload', ['$event'])
    unloadNotification($event: any) {
        // This will cause browsers to warn users they are "leaving this site" when they attempt to close this browser tab,
        // when they reload the page, and when they navigate to another domain.
        $event.preventDefault();
        return false;
    }
}
