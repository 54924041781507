import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { MSAL_INSTANCE } from '@azure/msal-angular';
import {
  AccountInfo,
  AuthenticationResult,
  AuthError,
  BrowserAuthError,
  ExternalTokenResponse,
  InteractionRequiredAuthError,
  IPublicClientApplication,
  LoadTokenOptions,
  RedirectRequest,
  SilentRequest,
} from '@azure/msal-browser';
import { Store } from '@ngrx/store';

import { from, Observable } from 'rxjs';

import { ModalsActions } from 'src/app/features/modals';
import { NotificationsActions, NotificationType } from 'src/app/features/notifications';
import { ExceptionType } from 'src/app/features/notifications/models';
import {
  CONSUMER_PORTAL_REDIRECT_STATE,
  CURRENT_AUTHORITY_TYPE_KEY,
  SECURE_TOKEN_STORAGE_KEY,
  SIGNING_REDIRECT_STATE,
} from 'src/app/features/shared/constants';
import { RootStoreState } from 'src/app/store';
import { environment } from 'src/environments/environment';
import { v4 as uuid } from 'uuid';

import {
  MSAL_AUTHORITIES,
  MSAL_ERROR_EXPIRED_TOKEN,
  MSAL_ERROR_FORGOTTEN_PASSWORD,
  MSAL_ERROR_INVALID_TOKEN,
} from '../config';
import { MsalAuthActions } from '../store/actions';

@Injectable({
  providedIn: 'root',
})
export class MsalAuthService {
  private readonly defaultScopes: string[] = environment.consentScopes;
  private readonly consumerPortalRoute = '/#/consumer-portal';
  private readonly signingSessionRoute = '/#/signing-landing';

  constructor(
    @Inject(MSAL_INSTANCE) private readonly instance: IPublicClientApplication,
    private readonly store: Store<RootStoreState.State>,
    private readonly router: Router
  ) {}

  get user() {
    return this.instance.getActiveAccount();
  }

  cacheToken(request: SilentRequest, response: ExternalTokenResponse, options: LoadTokenOptions) {
    const tokenCache = this.instance.getTokenCache();
    tokenCache.loadExternalTokens(request, response, options);
  }

  setActiveAccount(account: AccountInfo | null) {
    return this.instance.setActiveAccount(account);
  }

  handleRedirectObservable(hash?: string): Observable<AuthenticationResult> {
    return from(this.instance.handleRedirectPromise(hash));
  }

  initialize(): Observable<void> {
    return from(this.instance.initialize());
  }

  logout() {
    return from(this.instance.logout());
  }

  login() {
    const request: RedirectRequest = {
      authority: MSAL_AUTHORITIES.default,
      scopes: this.defaultScopes,
      redirectStartPage: this.consumerPortalRoute,
    };
    return from(this.instance.loginRedirect(request));
  }

  pinLogin(tokenHint: string) {
    sessionStorage.setItem(CURRENT_AUTHORITY_TYPE_KEY, 'pinLogin');
    return from(
      this.instance.loginRedirect({
        authority: MSAL_AUTHORITIES.secureLink,
        extraQueryParameters: { id_token_hint: tokenHint },
        state: CONSUMER_PORTAL_REDIRECT_STATE,
        scopes: this.defaultScopes,
        redirectStartPage: this.consumerPortalRoute,
      })
    );
  }

  firstTimeUserLogin(tokenHint: string) {
    return from(
      this.instance.loginRedirect({
        authority: MSAL_AUTHORITIES.invitation,
        extraQueryParameters: { id_token_hint: tokenHint },
        scopes: this.defaultScopes,
        redirectStartPage: this.consumerPortalRoute,
      })
    );
  }

  signingAgentLogin(tokenHint: string) {
    sessionStorage.setItem(CURRENT_AUTHORITY_TYPE_KEY, 'saLogin');
    return from(
      this.instance.loginRedirect({
        authority: MSAL_AUTHORITIES.signingAgent,
        extraQueryParameters: { id_token_hint: tokenHint },
        state: SIGNING_REDIRECT_STATE,
        scopes: this.defaultScopes,
        redirectStartPage: this.signingSessionRoute,
      })
    );
  }

  nonSigningAgentLogin(tokenHint: string) {
    sessionStorage.setItem(CURRENT_AUTHORITY_TYPE_KEY, 'nonSaLogin');
    return from(
      this.instance.loginRedirect({
        authority: MSAL_AUTHORITIES.nonSigningAgent,
        extraQueryParameters: { id_token_hint: tokenHint },
        state: SIGNING_REDIRECT_STATE,
        scopes: this.defaultScopes,
        redirectStartPage: this.signingSessionRoute,
      })
    );
  }

  nonSigningAgentAccountLogin(tokenHint: string) {
    sessionStorage.setItem(CURRENT_AUTHORITY_TYPE_KEY, 'nonSaAccountLogin');
    return from(
      this.instance.loginRedirect({
        authority: MSAL_AUTHORITIES.nonSigningAgentAccount,
        extraQueryParameters: { id_token_hint: tokenHint },
        state: SIGNING_REDIRECT_STATE,
        scopes: this.defaultScopes,
        redirectStartPage: this.signingSessionRoute,
      })
    );
  }

  loginRetry() {
    const tokenHint = sessionStorage.getItem(SECURE_TOKEN_STORAGE_KEY);
    const currentType = sessionStorage.getItem(CURRENT_AUTHORITY_TYPE_KEY);
    if (currentType === 'pinLogin') this.pinLogin(tokenHint);
    else if (currentType === 'saLogin') this.signingAgentLogin(tokenHint);
    else if (currentType === 'nonSaLogin') this.nonSigningAgentLogin(tokenHint);
    else if (currentType === 'nonSaAccountLogin') this.nonSigningAgentAccountLogin(tokenHint);
  }

  resetPassword() {
    const idTokenHint = sessionStorage.getItem(SECURE_TOKEN_STORAGE_KEY);
    return from(
      this.instance.loginRedirect({
        authority: MSAL_AUTHORITIES.passwordReset,
        scopes: this.defaultScopes,
        extraQueryParameters: { id_token_hint: idTokenHint },
      })
    );
  }

  isAuthErrorTokenInvalid(authError: AuthError): boolean {
    return MSAL_ERROR_INVALID_TOKEN.some((re) => re.test(authError.errorMessage));
  }

  isAuthErrorTokenExpired(authError: AuthError): boolean {
    return MSAL_ERROR_EXPIRED_TOKEN.some((re) => re.test(authError.errorMessage));
  }

  isAuthErrorForgottenPassword(authError: AuthError): boolean {
    return MSAL_ERROR_FORGOTTEN_PASSWORD.test(authError.errorMessage);
  }

  isAuthErrorCancelledMfa(authError: AuthError): boolean {
    return authError.message.includes('AADB2C90091');
  }

  isErrorPopupFailure(error: Error): boolean {
    return error instanceof BrowserAuthError && error.errorCode === 'popup_window_error';
  }

  isForgotPasswordAuthority(authority: string): boolean {
    const resetPasswordAuthority = MSAL_AUTHORITIES.passwordReset.toLowerCase();
    return authority?.toLowerCase().includes(resetPasswordAuthority);
  }

  reAuthorizeNonSigningAgent(
    idTokenHint: string,
    packageUserGuid: string,
    startPage: string = null
  ) {
    this.store.dispatch(ModalsActions.ShowLoadingSpinner());
    this.store.dispatch(
      MsalAuthActions.UpdateLastVerifiedUserGuid({
        payload: {
          packageUserGuid,
        },
      })
    );
    startPage = startPage ?? `/#${this.router.url}`;
    return this.instance
      .ssoSilent({
        authority: MSAL_AUTHORITIES.nonSigningAgent,
        extraQueryParameters: { id_token_hint: idTokenHint },
        scopes: this.defaultScopes,
        loginHint: packageUserGuid,
      })
      .then(() => {
        this.handleTokenRefreshSuccess();
      })
      .catch((e) => {
        if (
          e instanceof InteractionRequiredAuthError ||
          (e instanceof BrowserAuthError && e.errorCode === 'monitor_window_timeout')
        ) {
          // User likely has 3rd party cookies blocked which prevents silent authorization
          // requests. So we'll fall back on a redirect
          return this.reAuthorizeNonSigningAgentRedirect(idTokenHint, startPage);
        } else {
          return this.handleTokenRefreshFailure(e);
        }
      });
  }

  private reAuthorizeNonSigningAgentRedirect(idTokenHint: string, startPage: string) {
    return this.instance
      .loginRedirect({
        authority: MSAL_AUTHORITIES.nonSigningAgent,
        extraQueryParameters: { id_token_hint: idTokenHint },
        scopes: this.defaultScopes,
        state: SIGNING_REDIRECT_STATE,
        redirectStartPage: startPage,
      })
      .catch((e) => {
        this.handleTokenRefreshFailure(e);
      });
  }

  private handleTokenRefreshSuccess() {
    this.store.dispatch(MsalAuthActions.LoginSuccessful({ payload: { user: this.user } }));
    this.store.dispatch(ModalsActions.HideLoadingSpinner());
  }

  private handleTokenRefreshFailure(e: Error) {
    this.store.dispatch(
      NotificationsActions.AddNotification({
        payload: {
          text: 'Failed to acquire auth token for user',
          id: uuid(),
          notificationType: NotificationType.Error,
          exception: e,
          exceptionType: ExceptionType.CannotProceed,
        },
      })
    );
    this.store.dispatch(ModalsActions.HideLoadingSpinner());
  }
}
