import { Directive, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';

@Directive()
export abstract class ConditionalDirectiveBase implements OnInit {
  public hasView: boolean;

  constructor(
    private readonly _templateRef: TemplateRef<any>,
    private readonly _viewContainer: ViewContainerRef
  ) {}

  ngOnInit(): void {
    this.updateView();
  }

  updateView() {
    const shouldRender = this.shouldRenderElement();
    if (shouldRender && !this.hasView) {
      this._viewContainer.createEmbeddedView(this._templateRef);
      this.hasView = true;
    } else if (!shouldRender && this.hasView) {
      this._viewContainer.clear();
      this.hasView = false;
    }
  }

  abstract shouldRenderElement(): boolean;
}
