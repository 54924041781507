import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { SharedModule } from 'src/app/features/shared';

import { QrCodeImageComponent } from './components';
import { IdVerificationStepGroupComponent } from './containers';
import { IdVerificationService } from './services';
import {
  IdAppAccessComponent,
  IdInstructionsComponent,
  IdVerificationInProgressComponent,
} from './steps';
import { IdVerificationEffects, IdVerificationReducers } from './store';
import { WizardModule } from '../wizard/wizard.module';

@NgModule({
  declarations: [
    IdAppAccessComponent,
    IdInstructionsComponent,
    IdVerificationInProgressComponent,
    IdVerificationStepGroupComponent,
    QrCodeImageComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    ReactiveFormsModule,
    StoreModule.forFeature('idVerification', IdVerificationReducers.idVerificationReducer),
    EffectsModule.forFeature([IdVerificationEffects.IdVerificationEffects]),
    WizardModule,
  ],
  providers: [IdVerificationService],
  exports: [IdVerificationStepGroupComponent],
})
export class IdVerificationModule {}
