import { computed, inject, Injectable, Signal } from '@angular/core';

import { Store } from '@ngrx/store';

import { AudioVideoCheckService } from 'src/app/features/av-check/services/audio-video-check.service';
import { MicCheckService } from 'src/app/features/av-check/services/mic-check.service';
import { SpeakerCheckService } from 'src/app/features/av-check/services/speaker-check.service';

import {
  selectAreInputAndOutputDevicesSelected,
  selectAreInputDevicesSelected,
  selectMediaStream,
} from 'src/app/features/av-check/store/selectors/audio-video-check.selectors';

@Injectable()
export class SharedComponentService {
  private readonly audioVideoCheckService = inject(AudioVideoCheckService);
  private readonly micCheckService = inject(MicCheckService);
  private readonly speakerCheckService = inject(SpeakerCheckService);
  private readonly store = inject(Store);

  isContinueDisabled = computed(
    () =>
      (this.speakerCheckService.hasSpeakerFeature()
        ? !this.store.selectSignal(selectAreInputAndOutputDevicesSelected)()
        : !this.store.selectSignal(selectAreInputDevicesSelected)()) ||
      !this.mediaStream() ||
      this.audioVideoCheckService.hasUserMediaAudioFailure() ||
      this.audioVideoCheckService.hasUserMediaVideoFailure() ||
      this.micCheckService.micFailure()
  );

  isVideoDetected = computed(
    () =>
      this.audioVideoCheckService.hasVideo() &&
      this.mediaStream()?.active &&
      this.audioVideoCheckService.selectVideoDevice()?.deviceId !== ''
  );

  mediaStream: Signal<MediaStream> = this.store.selectSignal(selectMediaStream);
}
