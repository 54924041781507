import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { NgxMaskModule } from 'ngx-mask';

import { SharedModule } from 'src/app/features/shared';

import { ModalsModule } from '../modals';
import {
  MultiFactorChallengeErrorComponent,
  MultiFactorChallengeModalComponent,
  MultiFactorChallengeOptionsComponent,
  MultiFactorChallengeSecurityCodeComponent,
} from './components';
import { MultiFactorService } from './services';
import { MultiFactorEffects, MultiFactorModalEffects } from './store/effects';
import { MultiFactorReducers } from './store/reducers';

@NgModule({
  declarations: [
    MultiFactorChallengeErrorComponent,
    MultiFactorChallengeSecurityCodeComponent,
    MultiFactorChallengeOptionsComponent,
    MultiFactorChallengeModalComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    SharedModule,
    ReactiveFormsModule,
    StoreModule.forFeature('multiFactor', MultiFactorReducers.multiFactorReducers),
    EffectsModule.forFeature([
      MultiFactorEffects.MultiFactorEffects,
      MultiFactorModalEffects.MultiFactorModalEffects,
    ]),
    NgxMaskModule.forRoot(),
    ModalsModule,
    DragDropModule,
  ],
  providers: [MultiFactorService],
  exports: [
    MultiFactorChallengeOptionsComponent,
    MultiFactorChallengeSecurityCodeComponent,
    MultiFactorChallengeModalComponent,
  ],
})
export class MultiFactorModule {}
