export enum MsalRoles {
  Verified = 'Verified',
  Minimal = 'Minimal',
}

// https://docs.microsoft.com/en-us/azure/active-directory-b2c/error-codes
export const MSAL_ERROR_INVALID_TOKEN: RegExp[] = [
  /AADB2C90209/,
  /AADB2C90210/,
  /AADB2C90232/,
  /AADB2C90233/,
  /AADB2C90237/,
  /AADB2C90238/,
  /AADB2C90239/,
  /AADB2C90240/,
];

export const MSAL_ERROR_EXPIRED_TOKEN: RegExp[] = [/AADB2C90208/, /AADB2C90235/];

export const MSAL_ERROR_FORGOTTEN_PASSWORD = /AADB2C90118/;
