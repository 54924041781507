import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import {
  VerifyPasswordResponseModel,
  VerifyPinResponseModel
} from '../model';

@Injectable({
  providedIn: 'root',
})
export class PINValidationService {
  constructor(private readonly httpClient: HttpClient) {}

  verification(pin: string, packageUserGuid: string) : Observable<VerifyPinResponseModel> {
    return this.httpClient.post<VerifyPinResponseModel>(
      `pinVerifications`,
      { Pin: pin, PackageUserGuid: packageUserGuid }
    );
  }

  verifyPIN(
    packageUserGuid: string,
    pinToVerify: string,
    deviceCode: string
  ): Observable<VerifyPinResponseModel> {
    const data = {
      Pin: pinToVerify,
      PackageUserGuid: packageUserGuid,
      DeviceCode: deviceCode,
    };

    return this.httpClient.put<VerifyPinResponseModel>(
      `packageUsers/${packageUserGuid}/pinVerification`,
      data
    );
  }

  verifyCertificatePassword(
    passwordToVerify: string,
    stateCode: string,
  ): Observable<VerifyPasswordResponseModel> {
    return this.httpClient.put<VerifyPasswordResponseModel>(
      `certificates/verification`, { Password: passwordToVerify, StateCode: stateCode }
    );
  }
}
