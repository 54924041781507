import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';

import { of } from 'rxjs';
import {
  catchError,
  concatMap,
  exhaustMap,
  filter,
  map,
  switchMap,
  withLatestFrom,
} from 'rxjs/operators';
import { v4 as uuid } from 'uuid';

import { CheckInActions } from 'src/app/features/check-in';
import { DeviceGroupSelectors } from 'src/app/features/device-group';
import { ModalsActions } from 'src/app/features/modals';
import { NotificationsActions } from 'src/app/features/notifications';
import {
  ExceptionType,
  NotificationModel,
  NotificationType,
} from 'src/app/features/notifications/models';
import { PackageUsersSelectors } from 'src/app/features/package-users';
import { PackagesActions, PackagesSelectors, ProductType } from 'src/app/features/packages';
import { RootStoreState } from 'src/app/store';

import { MultiFactorChallengeErrorComponent } from '../../components';
import { MultiFactorChallengeError, MultiFactorErrorType } from '../../models';
import { MultiFactorService } from '../../services';
import { MultiFactorActions } from '../actions';
import { MultiFactorSelectors } from '../selectors';

@Injectable()
export class MultiFactorEffects {
  createMultiFactorChallenge$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MultiFactorActions.CreateMultiFactorChallenge),
      concatMap((action) =>
        of(action).pipe(
          withLatestFrom(
            this.store.pipe(select(DeviceGroupSelectors.getDeviceCode)),
            this.store.pipe(select(MultiFactorSelectors.getCurrentlySelectedChallengeType))
          )
        )
      ),
      switchMap(([action, deviceCode, challengeType]) => {
        const packageUserGuid = action.payload.packageUserGuid;

        return this.multiFactorService
          .multiFactorChallenge({
            PackageUserGuid: packageUserGuid,
            ChallengeType: challengeType,
            DeviceCode: deviceCode,
          })
          .pipe(
            switchMap((_) => [
              ModalsActions.HideLoadingSpinner(),
              MultiFactorActions.MultiFactorChallengeCreated({
                payload: {
                  packageUserGuid,
                },
              }),
            ]),
            catchError((error: MultiFactorChallengeError) => {
              const errorLogNotification = NotificationsActions.AddNotification({
                payload: {
                  exception: error.httpError,
                  exceptionType: ExceptionType.None,
                  id: uuid(),
                  logInAppInsights: true,
                  notificationType: NotificationType.Error,
                  text: `${error.errorLogText} challengeType: ${challengeType}`,
                },
              });

              return [
                ModalsActions.HideLoadingSpinner(),
                errorLogNotification,
                MultiFactorActions.CreateMultiFactorChallengeError({
                  packageUserGuid,
                  payload: error,
                }),
              ];
            })
          );
      })
    )
  );

  resendMultiFactorChallenge$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MultiFactorActions.ResendMultiFactorChallengeCode),
      concatMap((action) =>
        of(action).pipe(
          withLatestFrom(
            this.store.pipe(select(MultiFactorSelectors.getActiveMultiFactorAttemptPackageUserGuid))
          )
        )
      ),
      map(([action, packageUserGuid]) =>
        MultiFactorActions.CreateMultiFactorChallenge({
          payload: {
            packageUserGuid,
          },
        })
      )
    )
  );

  completeMultiFactorChallenge$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MultiFactorActions.CompleteMultiFactorChallenge),
      concatMap((action) =>
        of(action).pipe(
          withLatestFrom(
            this.store.pipe(select(MultiFactorSelectors.getCurrentlySelectedChallengeType)),
            this.store.pipe(
              select(MultiFactorSelectors.getActiveMultiFactorAttemptPackageUserGuid)
            ),
            this.store.pipe(select(DeviceGroupSelectors.getDeviceCode))
          )
        )
      ),
      switchMap(([action, challengeType, packageUserGuid, deviceCode]) => {
        return this.multiFactorService
          .completeMFAChallenge({
            PackageUserGuid: packageUserGuid,
            ChallengeType: challengeType,
            DeviceCode: deviceCode,
            ChallengeCode: action.payload.challengeCode,
          })
          .pipe(
            exhaustMap((payload) => {
              const result: any[] = [ModalsActions.HideLoadingSpinner()];

              if (payload.attemptsLeft !== 0 && payload.isSuccess) {
                result.push(
                  MultiFactorActions.MultiFactorAttemptSuccess({
                    payload: { packageUserGuid: packageUserGuid },
                  })
                );
              } else if (payload.attemptsLeft === 0) {
                result.push(
                  MultiFactorActions.CancelMultiFactorChallenge(),
                  MultiFactorActions.MultiFactorChallengeFailed()
                );
              } else if (payload.attemptsLeft !== 0 && !payload.isSuccess) {
                result.push(
                  MultiFactorActions.MultiFactorAttemptInvalidPin({
                    payload: {
                      attemptsRemaining: payload.attemptsLeft,
                    },
                  })
                );
              }
              return result;
            }),
            catchError((error: MultiFactorChallengeError) => {
              const payload: NotificationModel = {
                exception: error.httpError,
                exceptionType: ExceptionType.None,
                id: uuid(),
                logInAppInsights: true,
                notificationType: NotificationType.Error,
                text: `${error.errorLogText} challengeType: ${challengeType}`,
              };

              return of(
                ModalsActions.HideLoadingSpinner(),
                NotificationsActions.AddNotification({ payload }),
                MultiFactorActions.CompleteMultiFactorChallengeError({
                  packageUserGuid,
                  payload: error,
                })
              );
            })
          );
      })
    )
  );

  createMultiFactorChallengeError$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        MultiFactorActions.CreateMultiFactorChallengeError,
        MultiFactorActions.CompleteMultiFactorChallengeError
      ),
      switchMap((action) => {
        const mfaError = action.payload;
        if (mfaError.errorType !== MultiFactorErrorType.Unknown) {
          return [
            MultiFactorActions.RestartMultiFactorChallenge({
              payload: {
                errorMessage: mfaError.userFriendlyMessage,
              },
            }),
          ];
        } else {
          return [
            ModalsActions.SetModalComponent({
              payload: {
                modalTitle: 'An Error Has Occured',
                component: MultiFactorChallengeErrorComponent,
                allowManualClose: true,
                componentData: {
                  errorMessage: mfaError.errorMessageFromResponse,
                },
              },
            }),
          ];
        }
      })
    )
  );

  startCheckInOnSuccessfulMultiFactorAttempt$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MultiFactorActions.MultiFactorAttemptSuccess),
      concatLatestFrom((action) => [
        this.store.select(PackageUsersSelectors.getPackageUser(action.payload.packageUserGuid)),
        this.store.pipe(select(PackagesSelectors.getProductType)),
      ]),
      filter(([_, __, productType]) => productType !== ProductType.RemoteSigning),
      map(() => CheckInActions.RedirectToAvCheckIfRequired())
    )
  );

  multiFactorChallengeFailed$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MultiFactorActions.MultiFactorChallengeFailed),
      switchMap(() => [
        PackagesActions.SetCancelPackage(),
        PackagesActions.RedirectOrderCancelled(),
      ])
    )
  );

  constructor(
    private readonly actions$: Actions,
    private readonly store: Store<RootStoreState.State>,
    private readonly multiFactorService: MultiFactorService,
  ) {}
}
