import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss'],
})
export class CardComponent implements OnInit {
  @Input() backgroundStyle: string; // values: brand, secondary
  @Input() borderStyle: string; // values: line, shadow

  classes: string;

  ngOnInit(): void {
    const classList = ['card'];

    if (!!this.backgroundStyle) {
      classList.push('card--bg-' + this.backgroundStyle);
    }

    if (!!this.borderStyle) {
      classList.push('card--border-' + this.borderStyle);
    }

    this.classes = classList.join(' ');
  }
}
