<div class="step-tracker">
  <p class="step-tracker__label">Start Check-in</p>
  <div
    *ngFor="let step of steps$ | async; index as i"
    [ngClass]="{
      'step-tracker__step': true,
      'step-tracker__step--completed': step.isCompleted,
      'step-tracker__step--active': i === (activeStepIndex$ | async)
    }"
  ></div>
  <p class="step-tracker__label">Join Signing</p>
</div>
