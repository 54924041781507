import { Component, OnInit, Input } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { Store, select } from '@ngrx/store';

import { RootStoreState } from 'src/app/store';
import { PackageDocument } from 'src/app/features/documents';

import { DocumentEndorsementStatus } from '../../models';
import { EndorsementsSelectors } from '../../store';

@Component({
  selector: 'app-document-endorsement-status',
  templateUrl: './document-endorsement-status.component.html',
  styleUrls: ['./document-endorsement-status.component.scss'],
})
export class DocumentEndorsementStatusComponent implements OnInit {
  @Input() packageDocument: PackageDocument;
  endorsementStatus: Observable<DocumentEndorsementStatus>;

  constructor(private readonly store: Store<RootStoreState.State>) {}

  ngOnInit(): void {
    this.endorsementStatus = this.store.pipe(
      select(
        EndorsementsSelectors.selectRequiredEndorsementCountsByPackageDocument,
        { packageDocument: this.packageDocument }
      )
    );
  }
}
