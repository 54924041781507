import {
  HttpClient,
  HttpParams
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  Observable,
  throwError
} from 'rxjs';
import {
  catchError,
  map
} from 'rxjs/operators';

import { delayedRetry } from 'src/app/operators';

import {
  Commission,
  CommissionStamp,
  ApplyCommission,
  VerifyCertificatePasswordResponseModel
} from '../models';

@Injectable()
export class CommissionSelectionService {
  constructor(private readonly httpClient: HttpClient) {}

  public getCommissionList() {
    return this.httpClient.get<Commission[]>(`commissions`).pipe(
      delayedRetry(1000),
      map((commissions) =>
        commissions.map((commission) => {
          const expirationDate = commission.expiresOn
            ? new Date(commission.expiresOn)
            : null;
          return { ...commission, expiresOn: expirationDate } as Commission;
        })
      ),
      catchError((res) => throwError(res))
    );
  }

  public getCountiesList(stateCode: string) {
    return this.httpClient.get(`commissions/counties/${stateCode}`).pipe(
      delayedRetry(1000),
      catchError((res) => throwError(res))
    );
  }

  public putCommission(data: ApplyCommission) {
    return this.httpClient.put(`commissions`, data).pipe(
      delayedRetry(1000),
      catchError((res) => throwError(res))
    );
  }

  public getValidCommissionsExist() {
    return this.httpClient.get<boolean>(`commissions/valid`).pipe(
      delayedRetry(1000, 2),
      catchError((res) => throwError(res))
    );
  }

  public getDynamicStampRequired(stateCode: string): Observable<boolean> {
    if (!stateCode) {
      throw new Error('stateCode required.');
    }

    return this.httpClient.get<boolean>(`commissions/dynamicstamp/${stateCode}`).pipe(
      delayedRetry(1000),
      catchError((res) => throwError(res))
    );
  }

  public getStamp(stampData: CommissionStamp) {
    let httpParams = new HttpParams();
    Object.keys(stampData).forEach((key) => {
      httpParams = httpParams.append(key, stampData[key]);
    });

    return this.httpClient
      .get('commissions/stamp', { params: httpParams })
      .pipe(
        delayedRetry(1000),
        catchError((res) => throwError(res))
      );
  }

  public getSelectedCommission(packageUserGuid: string) {
    return this.httpClient
      .get<Commission>(`commissions/selected/packageuser/${packageUserGuid}`)
      .pipe(
        delayedRetry(1000),
        catchError((res) => throwError(res))
      );
  }

  public postCertificateDetails(password: string, stateCode: string) {
    return this.httpClient.post(`certificates/passwords`, { password, stateCode }).pipe(
      delayedRetry(1000),
      catchError((res) => throwError(res))
    );
  }

  public verifyCertificatePassword(
    passwordToVerify: string,
    stateCode: string,
  ): Observable<VerifyCertificatePasswordResponseModel> {
    return this.httpClient.put<VerifyCertificatePasswordResponseModel>(
      `certificates/verification`, { Password: passwordToVerify, StateCode: stateCode }
    );
  }
}
