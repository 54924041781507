import { Component } from '@angular/core';

@Component({
  selector: 'app-info-icon',
  template: `
    <svg fill="none" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12 4C7.58 4 4 7.58 4 12C4 16.42 7.58 20 12 20C16.42 20 20 16.42 20 12C20 7.58 16.42 4 12 4ZM13 17H11V15H13V17ZM13 13H11V7H13V13Z"
        fill="var(--app-info-icon-background-fill, transparent)"
        opacity="0.3"
      />
      <path
        d="M11.99 2C6.47 2 2 6.48 2 12C2 17.52 6.47 22 11.99 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 11.99 2ZM12 20C7.58 20 4 16.42 4 12C4
           7.58 7.58 4 12 4C16.42 4 20 7.58 20 12C20 16.42 16.42 20 12 20ZM11 15H13V17H11V15ZM11 7H13V13H11V7Z"
      />
    </svg>
  `,
  styleUrls: ['./info-icon.component.scss'],
})
export class InfoIconComponent {}
