<div #subheader><ng-content select="[subheader]"></ng-content></div>
<div *ngIf="!subheader.children.length" class="heads-up-container">
  <div data-testid="welcome-subheader">What you should know before you check in.</div>
</div>

<div #body><ng-content select="[body]"></ng-content></div>
<div *ngIf="!body.children.length">
  <div class="parent-box">
      <div class="content-box">
        <span class="fas fa-2x fa-stopwatch content-panel-icon"></span>
        <div class="string-box">You will need 5 to 10 minutes to complete this check in.</div>
      </div>
      <div class="content-box">
        <span class="fas fa-2x fa-address-card content-panel-icon"></span>
        <div class="string-box">You will need your Driver's License or State ID to verify your identity.</div>
      </div>
      <div class="content-box">
        <span class="fas fa-2x fa-tablet-alt content-panel-icon"></span>
        <div class="string-box">You will need a smartphone or tablet to submit a photo of your ID.</div>
      </div>
      <div class="content-box">
        <span class="fas fa-2x fa-user-shield content-panel-icon"></span>
        <div class="string-box">We will secure your session by asking you a few questions.</div>
      </div>
      <div class="content-box">
        <span class="fas fa-2x fa-headset content-panel-icon"></span>
        <div class="string-box">If you need support during the process you can use the Help option in the Main menu.</div>
      </div>
    </div>
</div>
