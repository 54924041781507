import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import { SignalRService } from 'src/app/features/signal-r';
import { RootStoreState } from 'src/app/store';

import {
  AuditLog,
  IdVerificationUserResultsStatus as IdVerificationUserStatusResults,
} from '../models';
import { IdVerificationActions } from '../store/actions';

@Injectable({
  providedIn: 'root',
})
export class IdVerificationService {
  packageGuid: string;
  userGuid: string;
  deviceCode: string;

  constructor(
    private readonly httpClient: HttpClient,
    private readonly signalRService: SignalRService,
    private readonly store: Store<RootStoreState.State>
  ) {}

  public getIdVerificationStatusResults(packageUserGuid: string) {
    return this.httpClient.get<IdVerificationUserStatusResults>(
      `idVerification/status/packageUserGuid/${packageUserGuid}`
    );
  }

  public createSession(packageUserGuid: string, firstName: string, clientId: number) {
    return this.httpClient.post('idVerification/createSession', {
      packageUserGuid,
      firstName,
      clientId,
    });
  }

  public postPhotoIdBegin(auditLog: AuditLog) {
    return this.httpClient.post('idVerification/photoIdBegin', auditLog);
  }

  public postIdVerificationComplete(packageUserGuid: string) {
    return this.httpClient.post('idVerification/IdVerificationComplete', {
      packageUserGuid,
    });
  }

  public configureListeners() {
    this.signalRService.hubConnection.on('IdVerificationSessionStartedEvent', (Data) => {
      this.store.dispatch(
        IdVerificationActions.SessionStarted({
          payload: {
            packageUserGuid: String(Data.correlationId),
          },
        })
      );
    });

    this.signalRService.hubConnection.on('IdVerificationPassedEvent', (Data) => {
      this.store.dispatch(
        IdVerificationActions.IdVerificationCompleted({
          payload: {
            packageUserGuid: String(Data.correlationId),
          },
        })
      );
    });

    this.signalRService.hubConnection.on('IdVerificationFailedEvent', (Data) => {
      this.store.dispatch(
        IdVerificationActions.IdVerificationCompleted({
          payload: {
            packageUserGuid: String(Data.correlationId),
          },
        })
      );
    });
  }
}
