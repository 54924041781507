import {
    Action,
    createReducer,
    on
  } from '@ngrx/store';
  
  import { DuplicateSessionDetectorActions } from '../actions';
  import { DuplicateSessionDetectorState } from '../state';
  
  const reducer = createReducer(
    DuplicateSessionDetectorState.initialState,
    on(DuplicateSessionDetectorActions.SetIsTabLeader, (state: DuplicateSessionDetectorState.State, { payload }) => ({
      ...state,
      isTabLeader: payload.isLeader
    })),
    on(DuplicateSessionDetectorActions.SetIsTabActive, (state: DuplicateSessionDetectorState.State, { payload }) => ({
      ...state,
      isTabActive: payload.isActive
    })),
    on(DuplicateSessionDetectorActions.SetTabGuid, (state: DuplicateSessionDetectorState.State, { payload }) => ({
      ...state,
      tabGuid: payload.guid
    })),
    on(DuplicateSessionDetectorActions.SetTabCreated, (state: DuplicateSessionDetectorState.State, { payload }) => ({
      ...state,
      tabCreated: payload.created
    })),
    on(DuplicateSessionDetectorActions.SetIsEnabled, (state: DuplicateSessionDetectorState.State, { payload }) => ({
      ...state,
      isEnabled: payload.enabled
    }))
  );
  
  export function duplicateSessionDetectorReducer(state: DuplicateSessionDetectorState.State | undefined, action: Action) {
    return reducer(state, action);
  }
  