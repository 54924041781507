import { createAction, props } from '@ngrx/store';

import { PackageUser } from 'src/app/features/package-users';

import { RulesResult } from '../../models';

export const GetRulesForUser = createAction(
  '[Rules] Get Rules For User',
  props<{
    payload: {
      packageUser?: PackageUser,
      modifiedProperties: {} // Values that differs from default rules request
    }
  }>()
);

export const SetRulesForUser = createAction(
  '[Rules] Set Rules For User',
  props<{
    payload: {
      result: RulesResult;
      packageUser: PackageUser;
      modifiedProperties: {} // Values that differs from default rules request
    };
  }>()
);
