<div class="video-signal-degraded-modal">
  <div class="video-signal-degraded-modal__header">Video quality/connection issue</div>

  <div class="video-signal-degraded-modal__body">
    <p>
      We're having trouble with your video connection. Please check your internet connection, wait a
      moment, and <a (click)="refresh()">refresh</a> the page.
    </p>
  </div>
  <div class="video-signal-degraded-modal__footer">
    If this issue persists, please call us:
    <span
      ><a href="tel:{{ DEFAULT_APPLICATION_PHONE_NUMBER | phone }}">{{
        DEFAULT_APPLICATION_PHONE_NUMBER | phone
      }}</a></span
    >
  </div>
</div>
