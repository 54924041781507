import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';

import { combineLatest, merge, Observable, Subscription } from 'rxjs';
import { filter, map, switchMap, take, tap } from 'rxjs/operators';

import { ModalsActions } from 'src/app/features/modals/store/actions';
import { PackageUser, PackageUsersSelectors } from 'src/app/features/package-users';
import { VideoActions } from 'src/app/features/video/store';
import { RootStoreState } from 'src/app/store';

import {
  CredentialDenialConfirmationModalComponent,
  CredentialRejoinDenialConfirmationModalComponent,
} from '../../components';
import { IdVerification } from '../../models';
import { ParticipantVerificationActions } from '../../store/actions';
import { ParticipantVerificationSelectors } from '../../store/selectors';

@Component({
  selector: 'app-participant-verification',
  templateUrl: './participant-verification.component.html',
  styleUrls: ['./participant-verification.component.scss'],
})
export class ParticipantVerificationComponent implements OnInit, OnDestroy {
  @Input() topOfPageTarget: HTMLDivElement;

  currentParticipant$: Observable<PackageUser>;
  packageUser$: Observable<PackageUser>;
  rejoiningPackageUser$: Observable<PackageUser>;
  isRejoiningParticipant$: Observable<boolean>;
  idVerificationAndUserAddressResults$: Observable<IdVerification>;
  participantBeingVerified: PackageUser;

  protected currentParticipantForVerificationSubscription: Subscription;
  protected idVerificationSubscription: Subscription;

  constructor(private readonly store: Store<RootStoreState.State>) {}

  ngOnInit() {
    this.isRejoiningParticipant$ = combineLatest([
      this.store.pipe(select(ParticipantVerificationSelectors.getNextUnapprovedPackageUserGuid)),
      this.store.pipe(select(ParticipantVerificationSelectors.getRejoinedPackageUsers)),
    ]).pipe(
      map(([currentUserGuid, rejoiningUsers]) =>
        rejoiningUsers.some((u) => u.packageUserGuid === currentUserGuid)
      )
    );

    this.setCurrentParticipantDetails();
  }

  ngOnDestroy() {
    this.currentParticipantForVerificationSubscription?.unsubscribe();
    this.idVerificationSubscription?.unsubscribe();
  }

  approveParticipant(): void {
    this.topOfPageTarget.scrollTop = 0;

    this.store.dispatch(
      ParticipantVerificationActions.ApproveParticipant({
        payload: this.participantBeingVerified.packageUserGuid,
      })
    );
  }

  denyParticipant(): void {
    this.store.dispatch(
      ModalsActions.SetStandaloneModalComponent({
        payload: {
          component: CredentialDenialConfirmationModalComponent,
          componentData: {
            participantBeingVerified: this.participantBeingVerified,
          },
        },
      })
    );
  }

  approveRejoiningParticipant(): void {
    this.store.dispatch(
      ParticipantVerificationActions.ApproveRejoiningParticipant({
        deviceCode: this.participantBeingVerified.deviceCode,
        packageUserGuid: this.participantBeingVerified.packageUserGuid,
      })
    );

    setTimeout(() => {
      this.store.dispatch(VideoActions.SetIgnoreParticipantVideoStream({ payload: false }));
    }, 5000);
  }

  denyRejoiningParticipant(): void {
    this.store.dispatch(
      ModalsActions.SetStandaloneModalComponent({
        payload: {
          component: CredentialRejoinDenialConfirmationModalComponent,
          componentData: {
            participantBeingVerified: this.participantBeingVerified,
          },
        },
      })
    );
  }

  private handleIdVerification(doesIdVerificationExist) {
    if (!doesIdVerificationExist) {
      this.store.dispatch(ParticipantVerificationActions.ShowUnableToProcessModal());

      return;
    }

    this.setPackageUser();
    this.setRejoiningPackageUser();
    this.setParticipantBeingVerified();
    this.setIdVerificationAndUserAddressResults();
  }

  private setPackageUser(): void {
    this.packageUser$ = this.store
      .select(ParticipantVerificationSelectors.getNextUnapprovedPackageUserGuid)
      .pipe(
        switchMap((packageUserGuid) =>
          this.store.select(PackageUsersSelectors.getPackageUser(packageUserGuid))
        )
      );
  }

  private setParticipantBeingVerified(): void {
    this.currentParticipant$ = merge(this.packageUser$, this.rejoiningPackageUser$);
    this.currentParticipantForVerificationSubscription = this.currentParticipant$.subscribe(
      (packageUser) => {
        this.participantBeingVerified = packageUser;
      }
    );
  }

  private setIdVerificationAndUserAddressResults(): void {
    this.idVerificationAndUserAddressResults$ = combineLatest([
      this.store.select(ParticipantVerificationSelectors.getNextUnapprovedPackageUserGuid),
    ]).pipe(
      filter(([packageUserGuid]) => !!packageUserGuid),
      switchMap(([packageUserGuid]) =>
        this.store.pipe(
          select(
            ParticipantVerificationSelectors.getCurrentParticipantIdVerification(packageUserGuid)
          )
        )
      )
    );
  }

  private setCurrentParticipantDetails(): void {
    this.idVerificationSubscription = this.store
      .select(ParticipantVerificationSelectors.doesIdVerificationExist)
      .pipe(
        take(1),
        tap((doesIdVerificationExist) => this.handleIdVerification(doesIdVerificationExist))
      )
      .subscribe();
  }

  private setRejoiningPackageUser(): void {
    this.rejoiningPackageUser$ = this.store.pipe(
      select(ParticipantVerificationSelectors.getNextRejoinedUser),
      filter((rejoinedUser) => !!rejoinedUser),
      tap(() =>
        this.store.dispatch(VideoActions.SetIgnoreParticipantVideoStream({ payload: true }))
      ),
      switchMap((rejoinedUser) => {
        return this.store
          .select(PackageUsersSelectors.getPackageUser(rejoinedUser.packageUserGuid))
          .pipe(
            filter((user) => !!user),
            map((rejoiningPackageUser) => {
              rejoiningPackageUser.deviceCode = rejoinedUser.deviceCode;

              return rejoiningPackageUser;
            })
          );
      })
    );
  }
}
