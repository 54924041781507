<div class="form-step__subheading" data-private>
  {{ user?.firstName }} {{ user?.lastName }}, your previously uploaded signature is shown below and
  will be used for the signing session.
</div>
<app-signature-view
  [signature]="commission?.signature"
  [imageTypeProvided]="false"
  [dataTestId]="'uploaded-signature-preview'"
></app-signature-view>
<app-button-container>
  <app-button
    [dataTestId]="'next-button-uploaded-signature'"
    type="primary"
    (click)="goToNextStep()"
  >
    Next
  </app-button>
</app-button-container>
