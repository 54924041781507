<app-wizard-step-wrapper [showStep]="showStep" [formGroup]="formStep" hideHeader="true">
  <div class="instructions">
    <div class="instructions__image">
      <img
        class="instructions__image"
        src="../../../../../assets/images/check-list.svg"
        alt="check list"
        data-testid="kba-check-list"
      />
    </div>
    <div class="instructions instructions__container" data-testid="kba-instructions-text">
      <h2 class="instructions__container instructions__heading">Security Questions</h2>
      <p class="instructions__container instructions__text">
        You have <b>2 minutes</b> to answer 5 security questions.<br *appDesktopOnly />
        We use these to verify your identity.
      </p>
    </div>
    <app-button-container class="instructions__container">
      <app-button
        [type]="'primary'"
        [dataTestId]="'next-button-kba-start-questions'"
        (click)="goToNextStep()"
        >Start Questions</app-button
      >
    </app-button-container>
  </div>
</app-wizard-step-wrapper>
