import { Injectable } from '@angular/core';
import {
  AbstractControl,
  UntypedFormControl,
  Validators,
  UntypedFormGroup,
} from '@angular/forms';

import {
  AnsweredQuestion,
  Answers,
  Question,
  QuestionSet,
  SelectedChoice,
} from '../models';

@Injectable()
export class KnowledgeBasedAuthenticationFormsService {
  constructor() {}

  public convertQuestionSetToFormControls(questionSet: QuestionSet) {
    const reducer = (
      accumulator: { [key: string]: UntypedFormControl },
      currentValue: Question
    ) => ({
      ...accumulator,
      [currentValue.questionId]: new UntypedFormControl('', Validators.required),
    });
    return new UntypedFormGroup(questionSet.questions.reduce(reducer, {}));
  }

  public convertFormGroupToAnswers(
    form: UntypedFormGroup,
    questionSet: QuestionSet
  ): Answers {
    const answers = {
      questionSetId: questionSet.questionSetId,
      questions: [],
    } as Answers;

    questionSet.questions.forEach((question) => {
      answers.questions.push({
        questionId: question.questionId,
        choices: [
          {
            choice: Number(form.get(question.questionId.toString()).value),
          } as SelectedChoice,
        ],
      } as AnsweredQuestion);
    });

    return answers;
  }
}
