<div class="unable-to-process-modal-container">
  <div class="unable-to-process-modal-container__header">
    <div class="image">
      <div class="circle">
        <span class="fas fa-exclamation-triangle"></span>
      </div>
    </div>
    <div class="sub-header">Unable to Process Identification Images</div>
  </div>
  <div class="unable-to-process-modal-container__body">
    <p>This action will cancel the session</p>
    <p><strong>Once you cancel, you'll need to start a new session.</strong></p>
  </div>
  <div class="unable-to-process-modal-container__footer">
    <button
      type="button"
      class="btn btn--outline-dark"
      (click)="this.cancelPackageOnUserRequest()"
    >
      Cancel Session
    </button>
  </div>
</div>
