import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';

import { PackageUser } from 'src/app/features/package-users';
import { SignatureModel } from 'src/app/features/rules/models';
import { RootStoreState } from 'src/app/store';

import { ActiveEndorsementSelection, EndorsementSelection } from '../../models';
import { EndorsementsCreationActions, EndorsementsCreationSelectors } from '../../store';

@Component({
  selector: 'app-select-signature',
  templateUrl: './select-signature.component.html',
  styleUrls: ['./select-signature.component.scss'],
})
export class SelectSignatureComponent implements OnInit, OnDestroy {
  @Input() user: PackageUser;
  @Output() stepComplete = new EventEmitter();

  formStep: UntypedFormGroup;
  signatureOptions$: Observable<Array<EndorsementSelection>>;
  selectedSignatures$: Observable<ActiveEndorsementSelection>;
  selectedSignaturesSubscription: Subscription;
  signatureHasChanged: boolean;
  signatureTypeEligibility: Observable<SignatureModel>;

  constructor(private readonly store: Store<RootStoreState.State>) {
    this.formStep = new UntypedFormGroup({
      signatureSelected: new UntypedFormControl('', [Validators.requiredTrue]),
    });
  }

  ngOnInit() {
    this.signatureHasChanged = false;
    this.signatureOptions$ = this.store.pipe(
      select(EndorsementsCreationSelectors.getSignaturePreviews)
    );
    this.selectedSignatures$ = this.store.pipe(
      select(EndorsementsCreationSelectors.getActiveSignatureIndex)
    );
    this.selectedSignaturesSubscription = this.selectedSignatures$.subscribe(
      (selectedSignatures) => {
        if (selectedSignatures?.staticSelection) {
          this.formStep.controls.signatureSelected.setValue(true);
        }
      }
    );

    this.store.dispatch(EndorsementsCreationActions.FetchSignaturePreviews());
  }

  ngOnDestroy() {
    this.selectedSignaturesSubscription?.unsubscribe();
  }

  updateActiveSignature(index: number) {
    this.formStep.controls.signatureSelected.setValue(true);
    this.store.dispatch(EndorsementsCreationActions.SetActiveSignatureByIndex({ payload: index }));
    this.signatureHasChanged = true;
  }

  selectSignature() {
    if (this.signatureHasChanged) {
      this.signatureHasChanged = false;
      this.store.dispatch(EndorsementsCreationActions.SignatureSelected());
    }
  }

  public goToNextStep(payload?: any): void {
    if (!this.formStep.valid) {
      this.formStep.setErrors({ error: 'select a signature' });
      return;
    }

    this.selectSignature();
    this.stepComplete.emit();
  }
}
