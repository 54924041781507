import { BrowserCacheLocation, Configuration } from '@azure/msal-browser';

import { environment } from 'src/environments/environment';

export const MSAL_AUTHORITIES = {
  default: environment.authority,
  invitation: environment.accountInvitationAuthority,
  nonSigningAgent: environment.nonSigningAgentAuthority,
  nonSigningAgentAccount: environment.nonSigningAgentSignUpSignInAuthority,
  passwordReset: environment.passwordResetAuthority,
  secureLink: environment.secureLinkLoginAuthority,
  signingAgent: environment.signingAgentAuthority,
};

const isIE =
  window.navigator.userAgent.indexOf('MSIE ') > -1 ||
  window.navigator.userAgent.indexOf('Trident/') > -1;

export const MSAL_CONFIG: Configuration = {
  auth: {
    authority: environment.authority,
    clientId: environment.client_id,
    navigateToLoginRequestUrl: true,
    postLogoutRedirectUri: environment.postLogoutRedirectUri,
    redirectUri: environment.redirect_url,
    knownAuthorities: [
      MSAL_AUTHORITIES.default,
      MSAL_AUTHORITIES.invitation,
      MSAL_AUTHORITIES.nonSigningAgent,
      MSAL_AUTHORITIES.passwordReset,
      MSAL_AUTHORITIES.secureLink,
      MSAL_AUTHORITIES.signingAgent,
      MSAL_AUTHORITIES.nonSigningAgentAccount,
    ],
  },
  cache: {
    claimsBasedCachingEnabled: true,
    cacheLocation: BrowserCacheLocation.SessionStorage,
    storeAuthStateInCookie: isIE, // set to true for IE 11
  },
  system: {
    iframeHashTimeout: 12000, // Default was 6000
  }
}
