<app-collapsible type="parent" isOpen="true" data-testid="contact-support">
  <div slot="title">
    <app-spark-icon icon="call-team-member" size="24"></app-spark-icon>
    <span>Contact Support</span>
  </div>

  <p class="contact-phone">
    You can reach us at <app-support-phone></app-support-phone>
  </p>

  <p>Monday - Friday 8:30 a.m. - 9 p.m. ET</p>
  <p>Saturday 9 a.m. - 5 p.m. ET</p>
</app-collapsible>
