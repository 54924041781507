import { Directive, HostListener, TemplateRef, ViewContainerRef } from '@angular/core';

import { ConditionalDirectiveBase } from 'src/app/directives/conditional-directive-base';

@Directive({
  selector: '[appDesktopOnly]',
})
export class DesktopOnlyDirective extends ConditionalDirectiveBase {
  private readonly DESKTOP_MIN_WIDTH = 992; // based off bootstrap lg media-query

  constructor(
    private readonly templateRef: TemplateRef<any>,
    private readonly viewContainer: ViewContainerRef
  ) {
    super(templateRef, viewContainer);
  }

  @HostListener('window:resize', ['$event'])
  onResize(_) {
    this.updateView();
  }

  shouldRenderElement(): boolean {
    return window.innerWidth >= this.DESKTOP_MIN_WIDTH;
  }
}
