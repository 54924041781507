<p>
  You have successfully signed all the documents for this Pre-Signing session. To review your
  documents, visit the signing dashboard.
</p>
<p>Your signed documents will be available after you complete the signing with the notary.</p>
<app-button-container>
  <app-button type="primary" (click)="completeSigning()" [dataTestId]="'modal-exit-signing'">
    Exit Signing
  </app-button>
</app-button-container>
