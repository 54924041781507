import { IdVerificationStatus } from "./id-verification-status-enum.model";

export interface IdVerificationResult {
  analysisResults: string[];
  firstName: string;
  lastName: string;
  idVerificationStatus: IdVerificationStatus;
  stateCode: string;
  streetAddress: string;
  city: string;
  zipCode: string;
}
