<section class="stepper">
  <header>
    <ol class="stepper__steps">
      <ng-container *ngFor="let step of steps; let i = index">
        <a (click)="selectedIndex = i"
           [attr.aria-label]="(i+1) + ' of ' + steps.length + ' ' + ariaLabelText"
        >
          <div class="stepper__step_wrapper">
            <li
              class="stepper__step"
              [ngClass]="selectedIndex === i ? activeClass : ''"
              [class.completed]="step.completed"
            >
              <ng-container
                *ngIf="step.stepLabel; else showLabelText"
                [ngTemplateOutlet]="step.stepLabel.template"
              >
              </ng-container>
              <ng-template #showLabelText>
                {{ step.label }}
              </ng-template>
            </li>
            <div
              *ngIf="steps.length !== i + 1"
              class="stepper__divider"
            ></div>
          </div>
        </a>
      </ng-container>
    </ol>
  </header>

  <div class="stepper__body">
    <ng-container [ngTemplateOutlet]="selected?.content"></ng-container>
  </div>
</section>
