<div class="snack-bar__top">
  <div class="snack-bar__top--content">
    <app-info-icon></app-info-icon>
    <div class="snack-bar__top--info">
      <div class="content-title">All participants have checked in</div>
      <div class="content-message">
        Your signing session will begin automatically <span>in <strong>{{remainingSeconds}} seconds</strong> or click "join
        signing" to join now.</span>
      </div>
    </div>
  </div>
</div>
<div class="snack-bar__bottom">
  <div class="snack-bar__bottom--content">
    <app-button matSnackBarAction type="secondary" [dataTestId]="'join-signing-button'" (click)="handleAction()">
      Join Signing
    </app-button>
  </div>
</div>
