<app-wizard-step-wrapper
  [showStep]="showStep"
  [stepTitle]="stepMetadata.stepName"
  [formGroup]="formStep"
>
  <p class="form-step__subheading" data-testid="check-in-subheader" data-private>
    {{ user?.firstName }} {{ user?.lastName }}, {{ signatureMessage$ | async }}
  </p>

  <div aria-label="Your chosen signature and initial styles are displayed here">
    <app-signature-view
      [signature]="(signatureImagePreview$ | async)?.imageString"
      [imageTypeProvided]="(signatureImagePreview$ | async)?.imageStringHasPrefix"
      [dataTestId]="'signature-preview'"
    ></app-signature-view>

    <app-signature-view
      [signature]="(initialsImagePreview$ | async)?.imageString"
      [imageTypeProvided]="(initialsImagePreview$ | async)?.imageStringHasPrefix"
      [dataTestId]="'initials-preview'"
    ></app-signature-view>
  </div>

  <h3 [class.title--error]="submissionFailure">
    Signature Consent Agreement{{ submissionFailure ? '*' : '' }}
  </h3>

  <div class="consent">
    <mat-checkbox
      class="consent__checkbox"
      data-testid="signature-consent-agreement-checkbox"
      [formControl]="signatureConsentAgreementCheckbox"
      (change)="toggleSignatureConsentAgreementCheckbox()"
    >
      I agree to the
    </mat-checkbox>
    <a
      href="javascript:"
      class="consent__link"
      (click)="openSignatureConsentAgreementModal()"
      data-testid="signature-consent-agreement-modal"
      >Signature Consent Agreement</a
    >
  </div>

  <p class="consent-error-message" *ngIf="submissionFailure">
    Please agree to the signature consent agreement before continuing.
  </p>

  <p class="consent-text">{{ (endorsementConsentText$ | async)?.consentText }}</p>

  <app-button-container>
    <app-button
      *ngIf="allowGoToPreviousStep$ | async"
      [dataTestId]="'previous-button-confirm-signature'"
      type="tertiary"
      (click)="goToPreviousStep()"
    >
      Back
    </app-button>
    <app-button
      [dataTestId]="'next-button-confirm-signature'"
      type="primary"
      (click)="goToNextStep()"
    >
      Accept
    </app-button>
  </app-button-container>
</app-wizard-step-wrapper>
