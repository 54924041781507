import { createAction, props } from '@ngrx/store';

import { PackageUser } from 'src/app/features/package-users';

export const FetchLobbyDetails = createAction('[Lobby] Fetch Lobby Details');

export const FetchLobbyDetailsSuccessful = createAction(
  '[Lobby] Fetch Lobby Details Successful',
  props<{ payload: PackageUser[] }>()
);

export const StartCheckIn = createAction(
  '[Lobby] Start Check In',
  props<{ payload: { packageUserGuid: string } }>()
);

export const StartRejoin = createAction(
  '[Lobby] StartRejoin',
  props<{ payload: { packageUserGuid: string } }>()
);
export const ClearCurrentParticipantCheckIn = createAction(
  '[Lobby] Clear Current Participant Check In'
);

//TODO: needs to check this for RON and Hybrid as well, if these actions can be used instead of making a copy in each product
export const LobbyViewed = createAction('[Lobby] Lobby Viewed');
