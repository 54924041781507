import { Injectable } from '@angular/core';
import { Route, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';

import { combineLatest, Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { MsalAuthSelectors } from 'src/app/features/msal-auth';
import { PackagesSelectors } from 'src/app/features/packages';
import { INITIAL_ROUTE_PATH_SESSION_KEY } from 'src/app/features/shared/constants';

import { NavigationService } from '../services/navigation.service';
import { RootStoreState } from '../store';

@Injectable({
  providedIn: 'root',
})
export class PortalGuard {
  constructor(
    private readonly router: Router,
    private readonly store: Store<RootStoreState.State>,
    private readonly navigationService: NavigationService
  ) {}

  private isPathValid(path: string) {
    return ['pre-sign', 'consumer-portal'].some((route) => route === path);
  }

  private requiresRedirect(path: string): boolean {
    const initialPath = sessionStorage.getItem(INITIAL_ROUTE_PATH_SESSION_KEY);

    if (!initialPath) {
      sessionStorage.setItem(INITIAL_ROUTE_PATH_SESSION_KEY, path);
    } else if (initialPath && !this.isPathValid(initialPath)) {
      return true;
    }

    return false;
  }

  canLoad(route: Route): Observable<boolean> {
    return combineLatest([
      this.store.pipe(select(PackagesSelectors.isPreSign)),
      this.store.pipe(select(MsalAuthSelectors.getUser)),
    ]).pipe(
      switchMap(([isPresign, user]) => {
        // If the user is authenticated and a package is loaded with isPresign = false, it means he got here
        // through the back button or url manipulation after joining a signing session, so redirect him
        if (user && isPresign === false) {
          // If there is a router history and it's not the site root, send the user
          // back to the previous route.
          // If there is no history or it's the site root, send him to the landing page.
          const lastRoute = this.navigationService.peekHistory();
          if (lastRoute && lastRoute !== '/') {
            this.navigationService.back();
          } else {
            this.router.navigate(['/signing-landing']);
          }
          return of(false);
        }

        // The user got here some other way and we don't know how to handle it, so redirect him to /invalid-link
        if (this.requiresRedirect(route.path)) {
          this.router.navigate(['/invalid-link'], {
            replaceUrl: true,
          });
          return of(false);
        }

        return of(true);
      })
    );
  }
}
