import { Component } from '@angular/core';
import { Store } from '@ngrx/store';

import { CancelSigningModalComponent } from 'src/app/features/layout/components';
import { ModalsActions } from 'src/app/features/modals';
import { RootStoreState } from 'src/app/store';

import { CommissionSelectionActions } from '../../store';

@Component({
  selector: 'app-certificate-expired',
  templateUrl: './certificate-expired.component.html',
  styleUrls: ['./certificate-expired.component.scss']
})
export class CertificateExpiredComponent{
  constructor(private readonly store: Store<RootStoreState.State>) {}

  updateCommission(): void {
    this.store.dispatch(
      CommissionSelectionActions.NavigateToUpdateCommissionsPage()
    );
  }

  cancelSigning(): void {
    this.store.dispatch(
      ModalsActions.SetStandaloneModalComponent({
        payload: {
          component: CancelSigningModalComponent,
          componentData: {
            fixedCategoryTypeCode: 'NotaryCommissionIssue'
          }
        },
      })
    );
  }
}
