import { ExceptionType } from './notification-exceptions.model';
import { NotificationOptions } from './notification-options.model';

export enum NotificationType {
  Warning = 'WARNING',
  Error = 'ERROR',
  Success = 'SUCCESS',
  Info = 'INFO',
}

export interface NotificationModel {
  text?: string;
  notificationType: NotificationType;
  exception?: Error;
  exceptionType?: ExceptionType;
  id: string;
  options?: NotificationOptions;
  toastId?: number;
  logInAppInsights?: boolean;
  showAddUserBtn?: boolean;
}
