import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-modal-content',
  templateUrl: './modal-content.component.html',
  styleUrls: ['./modal-content.component.scss'],
})
export class ModalContentComponent {
  @Input() body: string;
  @Input() linkLabel: string;
  @Input() linkText: string;
  @Input() linkRef: string;
}
