export interface SaveEndorsementSelectionRequestModel {
  deviceCode: string;
  packageUserGuid: string;
  signatureConsentAccepted: boolean;
  signatureFont: string;
  initialsFont: string;
  consentTextId : number;
  consentType: string;
  isPreSign: boolean;
}
