import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { MatRadioModule } from '@angular/material/radio';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { ConsentsModule } from 'src/app/features/consents';
import { SharedModule } from 'src/app/features/shared';

import { EndorsementSelectionService, CanvasValidationServiceFactory } from './services';
import {
  SignatureViewComponent,
  SignatureOptionsComponent,
  SignaturePadComponent,
  DrawSignatureComponent,
  DrawInitialsComponent,
} from './components';
import {
  SignatureConfirmationComponent,
  InitialsCreationComponent,
  SignatureCreationComponent,
} from './containers';
import {
  DrawSignatureStepComponent,
  DrawInitialsStepComponent,
  AcknowledgeUploadedSignatureComponent,
  SelectSignatureComponent,
  SelectInitialsComponent,
} from './steps';
import { EndorsementsCreationEffects, EndorsementsCreationReducers } from './store';
import { WizardModule } from '../wizard/wizard.module';

@NgModule({
  declarations: [
    SignatureOptionsComponent,
    InitialsCreationComponent,
    DrawInitialsComponent,
    SelectInitialsComponent,
    SignatureCreationComponent,
    SignatureConfirmationComponent,
    SignatureViewComponent,
    SignaturePadComponent,
    SelectSignatureComponent,
    DrawSignatureComponent,
    DrawInitialsStepComponent,
    DrawSignatureStepComponent,
    AcknowledgeUploadedSignatureComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    ConsentsModule,
    SharedModule,
    EffectsModule.forFeature([EndorsementsCreationEffects]),
    StoreModule.forFeature(
      EndorsementsCreationReducers.endorsementsCreationFeatureKey,
      EndorsementsCreationReducers.EndorsementsCreationReducer
    ),
    WizardModule,
    MatRadioModule,
  ],
  exports: [
    SignatureCreationComponent,
    InitialsCreationComponent,
    SignatureConfirmationComponent,
    SignaturePadComponent,
  ],
  providers: [EndorsementSelectionService, CanvasValidationServiceFactory],
})
export class EndorsementsCreationModule {}
