/*TODO: Not a real model, just trying to get the project to compile*/
import { ProductReason, QuestionSet, Status } from '../../models';

export interface State {
  status: Status;
  productReason: ProductReason;
  questionSet: QuestionSet;
  productType: string;
  currentUserNumberOfAttempts: number;
}

export const initialState: State = {
  status: null,
  productReason: null,
  questionSet: null,
  productType: null,
  currentUserNumberOfAttempts: null,
};
