import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { ClientEffects } from './store/effects';

import { ClientReducers } from './store/reducers';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(
      'client',
      ClientReducers.Reducers.clientReducer
    ),
    EffectsModule.forFeature([
      ClientEffects.ClientEffects,
    ]),
  ],
  providers: [],
})
export class ClientModule {}
