import { Action, createReducer, on } from '@ngrx/store';

import { EndorsementType } from '../../models';
import { EndorsementsActions } from '../actions';
import { EndorsementImagesState, EndorsementLocationsState, EndorsementsState, SystemEndorsementImagesState } from '../state';
import { uniqueEndorsementImagesAdapter } from '../state/UniqueEndorsementImages.state';

export const endorsementsFeatureKey = 'endorsements';

const reducer = createReducer(
  EndorsementsState.initialState,
  on(EndorsementsActions.SetEndorsementLocations, (state: EndorsementsState.State, { payload }) => {
    const userEndorsements = payload.filter(
      (documentEndorsementLocation) => documentEndorsementLocation.packageUserGuid !== null
    );

    return {
      ...state,
      EndorsementLocationsState: EndorsementLocationsState.endorsementLocationsAdapter.setAll(
        payload,
        state.EndorsementLocationsState
      ),
      lastAttemptedEndorsementId:
        userEndorsements.length > 0
          ? userEndorsements[userEndorsements.length - 1].documentEndorsementLocationId
          : 0,
      isLoaded: true,
    };
  }),
  on(EndorsementsActions.SetEndorsementImages, (state: EndorsementsState.State, { payload }) => {
    return {
      ...state,
      EndorsementImagesState: EndorsementImagesState.endorsementImagesAdapter.setAll(
        payload,
        state.EndorsementImagesState
      ),
    };
  }),
  on(
    EndorsementsActions.CreateUniqueEndorsementImagePlaceholders,
    (state: EndorsementsState.State, { payload }) => {
      return {
        ...state,
        UniqueEndorsementImagesState: uniqueEndorsementImagesAdapter.upsertMany(
          payload,
          state.UniqueEndorsementImagesState
        ),
      };
    }
  ),
  on(
    EndorsementsActions.UpdateUniqueEndorsementImage,
    (state: EndorsementsState.State, { payload }) => {
      return {
        ...state,
        UniqueEndorsementImagesState: uniqueEndorsementImagesAdapter.updateOne(
          {
            id: payload.documentEndorsementLocationId,
            changes: {
              endorsementImage: payload.endorsementImageString,
            },
          },
          state.UniqueEndorsementImagesState
        ),
      };
    }
  ),
  on(
    EndorsementsActions.SetUniqueEndorsementImages,
    (state: EndorsementsState.State, { payload }) => {
      return {
        ...state,
        UniqueEndorsementImagesState: uniqueEndorsementImagesAdapter.setAll(
          payload,
          state.UniqueEndorsementImagesState
        ),
      };
    }
  ),
  on(
    EndorsementsActions.ClearUniqueEndorsementImage,
    (state: EndorsementsState.State, { payload }) => {
      return {
        ...state,
        UniqueEndorsementImagesState: uniqueEndorsementImagesAdapter.updateOne(
          {
            id: payload.documentEndorsementLocationId,
            changes: {
              endorsementImage: null,
            },
          },
          state.UniqueEndorsementImagesState
        ),
      };
    }
  ),
  on(EndorsementsActions.SetSystemEndorsementImages,
    (state: EndorsementsState.State, { payload }) => {
    return {
      ...state,
      SystemEndorsementImagesState: SystemEndorsementImagesState.systemEndorsementImagesAdapter.setAll(
        payload,
        state.SystemEndorsementImagesState
      ),
    };
  }),
  // TODO: User Story 50434: Remove Old Endorsement Application Logic
  on(
    EndorsementsActions.EndorsementAttemptAccepted,
    (state: EndorsementsState.State, { payload }) => {
      if (payload.endorsementType === EndorsementType.TEXTBOX) {
        return {
          ...state,
          EndorsementLocationsState:
            EndorsementLocationsState.endorsementLocationsAdapter.updateOne(
              {
                id: payload.endorsementLocationId,
                changes: {
                  attempted: !!payload.data,
                  endorsementValue: payload.data,
                },
              },
              state.EndorsementLocationsState
            ),
          lastAttemptedEndorsementId: payload.endorsementLocationId,
        };
      }

      return {
        ...state,
        EndorsementLocationsState: EndorsementLocationsState.endorsementLocationsAdapter.updateOne(
          {
            id: payload.endorsementLocationId,
            changes: {
              attempted:
                !state.EndorsementLocationsState.entities[payload.endorsementLocationId]?.attempted,
            },
          },
          state.EndorsementLocationsState
        ),
        lastAttemptedEndorsementId: payload.endorsementLocationId,
      };
    }
  ),
  // in theory we could also set statusCode: 'SIGNED' here
  on(
    EndorsementsActions.SignEndorsementCallCompleted,
    (state: EndorsementsState.State, { payload }) => {
      return {
        ...state,
        EndorsementLocationsState: EndorsementLocationsState.endorsementLocationsAdapter.updateOne(
          {
            id: payload.endorsementLocationId,
            changes:
              payload.endorsementType === EndorsementType.TEXTBOX
                ? { endorsementValue: payload.data }
                : {},
          },
          state.EndorsementLocationsState
        ),
        lastAttemptedEndorsementId: payload.endorsementLocationId,
      };
    }
  ),
  on(
    EndorsementsActions.EraseEndorsementCallCompleted,
    (state: EndorsementsState.State, { payload }) => {
      return {
        ...state,
        EndorsementLocationsState: EndorsementLocationsState.endorsementLocationsAdapter.updateOne(
          {
            id: payload.endorsementLocationId,
            changes:
              payload.endorsementType === EndorsementType.TEXTBOX ? { endorsementValue: null } : {},
          },
          state.EndorsementLocationsState
        ),
        lastAttemptedEndorsementId: payload.endorsementLocationId,
      };
    }
  ),
  // TODO: User Story 50434: Remove Old Endorsement Application Logic
  on(EndorsementsActions.SetEndorsementAttempted, (state: EndorsementsState.State, { payload }) => {
    return {
      ...state,
      EndorsementLocationsState: EndorsementLocationsState.endorsementLocationsAdapter.updateOne(
        {
          id: payload.endorsementLocationId,
          changes: {
            attempted: true,
          },
        },
        state.EndorsementLocationsState
      ),
    };
  }),
  on(EndorsementsActions.SetEndorsementSigned, (state: EndorsementsState.State, { payload }) => {
    return {
      ...state,
      EndorsementLocationsState: EndorsementLocationsState.endorsementLocationsAdapter.updateOne(
        {
          id: payload.endorsementLocationId,
          changes: {
            statusCode: 'SIGNED',
          },
        },
        state.EndorsementLocationsState
      ),
    };
  }),
  // TODO: User Story 50434: Remove Old Endorsement Application Logic
  on(EndorsementsActions.SetEndorsementConfirmed, (state: EndorsementsState.State, { payload }) => {
    return {
      ...state,
      EndorsementLocationsState: EndorsementLocationsState.endorsementLocationsAdapter.updateOne(
        {
          id: payload.endorsementLocationId,
          changes: {
            statusCode: 'SIGNED',
            attempted: false,
          },
        },
        state.EndorsementLocationsState
      ),
    };
  }),
  // TODO: User Story 50434: Remove Old Endorsement Application Logic
  on(
    EndorsementsActions.RollbackEndorsementAttempt,
    (state: EndorsementsState.State, { payload }) => {
      return {
        ...state,
        EndorsementLocationsState: EndorsementLocationsState.endorsementLocationsAdapter.updateOne(
          {
            id: payload.endorsementLocationId,
            changes: {
              attempted: false,
            },
          },
          state.EndorsementLocationsState
        ),
      };
    }
  ),
  on(EndorsementsActions.RollbackEndorsement, (state: EndorsementsState.State, { payload }) => {
    return {
      ...state,
      EndorsementLocationsState: EndorsementLocationsState.endorsementLocationsAdapter.updateOne(
        {
          id: payload.endorsementLocationId,
          changes: {
            statusCode: 'SIGNABLE',
          },
        },
        state.EndorsementLocationsState
      ),
    };
  }),
  on(
    EndorsementsActions.UpdateENoteEndorsementCoordinates,
    (state: EndorsementsState.State, { payload }) => {
      return {
        ...state,
        EndorsementLocationsState: EndorsementLocationsState.endorsementLocationsAdapter.updateMany(
          payload,
          state.EndorsementLocationsState
        ),
      };
    }
  ),
  on(
    EndorsementsActions.SetUpdatedSignableOnDeviceStatus,
    (state: EndorsementsState.State, { payload }) => {
      const changes = payload.endorsementLocations.map((endorsementLocation) => {
        return {
          id: endorsementLocation.documentEndorsementLocationId,
          changes: {
            isSignableOnDevice: payload.enable,
          },
        };
      });

      return {
        ...state,
        EndorsementLocationsState: EndorsementLocationsState.endorsementLocationsAdapter.updateMany(
          changes,
          state.EndorsementLocationsState
        ),
      };
    }
  ),
  on(
    EndorsementsActions.RefreshEndorsementsUpdateSignableOnDeviceStatusByPackageUserGuids,
    (state: EndorsementsState.State, { payload }) => {
      const changes = payload.endorsementLocations.map((endorsementLocation) => {
        return {
          id: endorsementLocation.documentEndorsementLocationId,
          changes: {
            isSignableOnDevice: payload.devicePackageUserGuids.includes(
              endorsementLocation.packageUserGuid?.toLowerCase()
            )
              ? true
              : false,
          },
        };
      });

      return {
        ...state,
        EndorsementLocationsState: EndorsementLocationsState.endorsementLocationsAdapter.updateMany(
          changes,
          state.EndorsementLocationsState
        ),
      };
    }
  ),
  on(EndorsementsActions.UpdateReadOnlyFlag, (state: EndorsementsState.State, { payload }) => {
    return {
      ...state,
      isReadOnly: payload.isReadOnly,
    };
  }),
  // TODO: User Story 50434: Remove Old Endorsement Application Logic
  on(
    EndorsementsActions.SetAttemptingEndorsement,
    (state: EndorsementsState.State, { payload }) => {
      return {
        ...state,
        isAttemptingEndorsement: payload.isAttempting,
      };
    }
  ),
  // TODO: User Story 50434: Remove Old Endorsement Application Logic
  // this is actually new but we don't need it if we are not going to block on endorsement application
  // on(
  //   EndorsementsActions.SetProcessingEndorsement,
  //   (state: EndorsementsState.State, { payload }) => {
  //     return {
  //       ...state,
  //       isAttemptingEndorsement: payload.isProcessing,
  //     };
  //   }
  // ),
  on(EndorsementsActions.FetchSystemFieldStatus, (state: EndorsementsState.State) => {
    return {
      ...state,
      isLoading: true,
    };
  }),
  on(EndorsementsActions.FetchSystemFieldsStatus, (state: EndorsementsState.State) => {
    return {
      ...state,
      isLoading: true,
    };
  }),
  on(EndorsementsActions.SetSystemFieldStatus, (state: EndorsementsState.State, { payload }) => {
    return {
      ...state,
      areAllSystemFieldsApplied: payload.areApplied,
      didSystemFieldsFailToApply: payload.failedToApply,
      isLoading: false,
    };
  }),
  on(EndorsementsActions.FailedToApplySystemFields, (state: EndorsementsState.State) => {
    return {
      ...state,
      systemFieldApplicationFailures: state.systemFieldApplicationFailures + 1,
      lastSystemFieldApplicationAttempt: Date.now(),
    };
  }),
  on(EndorsementsActions.SetAllEndorsementsSigned, (state: EndorsementsState.State) => {
    return {
      ...state,
      areAllEndorsementsSigned: true,
    };
  })
);

export function endorsementReducer(state: EndorsementsState.State | undefined, action: Action) {
  return reducer(state, action);
}
