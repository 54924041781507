import { Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'app-spark-icon',
  templateUrl: './spark-icon.component.html',
  styleUrls: ['./spark-icon.component.scss'],
})
export class SparkIconComponent {
  @Input() icon: string;
  @Input() size: number;

  @HostBinding('style.width')
  get styleWidth() {
    return this.size + 'px';
  }
  @HostBinding('style.height')
  get styleHeight() {
    return this.size + 'px';
  }
}
