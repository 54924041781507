import {
  Component,
  Input
} from '@angular/core';

import { DEFAULT_APPLICATION_PHONE_NUMBER } from 'src/app/features/shared/constants';
import { PhonePipe } from 'src/app/features/shared/pipes';

@Component({
  selector: 'app-multi-factor-error',
  templateUrl: './multi-factor-challenge-error.component.html',
  styleUrls: ['./multi-factor-challenge-error.component.scss'],
})
export class MultiFactorChallengeErrorComponent {
  @Input() errorMessage: string;

  constructor(private readonly phonePipe: PhonePipe) {}

  get errorHtml() {
    return this.errorMessage?.replace(
      DEFAULT_APPLICATION_PHONE_NUMBER,
      `<a href="tel:${this.phonePipe.transform(DEFAULT_APPLICATION_PHONE_NUMBER)}">${DEFAULT_APPLICATION_PHONE_NUMBER}</a>`
    );
  }
}
