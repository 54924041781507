import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ChimeState } from 'src/app/features/video-conference/chime/store/state';

export const getChimeState = createFeatureSelector<ChimeState.State>('chime');

export const getAttendeeDetails = createSelector(
  getChimeState,
  (state: ChimeState.State) => state.attendee
);

export const getMeetingDetails = createSelector(
  getChimeState,
  (state: ChimeState.State) => state.meeting
);

export const getRecordingStatus = createSelector(
  getChimeState,
  (state: ChimeState.State) => state.recordingStatus
);
