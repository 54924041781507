import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';

import { of } from 'rxjs';
import { catchError, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';

import { CheckInActions } from 'src/app/features/check-in';
import { DeviceGroupActions, DeviceGroupSelectors } from 'src/app/features/device-group';
import { DeviceGroupService } from 'src/app/features/device-group/services';
import { DocumentsActions } from 'src/app/features/documents';
import { EndorsementsActions } from 'src/app/features/endorsements';
import { FeatureManagementService } from 'src/app/features/feature-management/services';
import { LobbyActions } from 'src/app/features/lobby';
import { ModalsActions } from 'src/app/features/modals';
import { MultiFactorActions } from 'src/app/features/multi-factor/store/actions';
import { NotificationsActions, NotificationType } from 'src/app/features/notifications';
import { ExceptionType } from 'src/app/features/notifications/models';
import { PackageUsersActions } from 'src/app/features/package-users';
import { PackagesSelectors } from 'src/app/features/packages';
import { PackageStatus } from 'src/app/features/packages/models';
import { RonService } from 'src/app/features/ron/services/ron.service';
import { RonActions } from 'src/app/features/ron/store/actions';
import { SignalRActions } from 'src/app/features/signal-r/store/actions';
import { SigningRoomActions } from 'src/app/features/signing-room/store';
import { RootStoreState } from 'src/app/store';
import { v4 as uuid } from 'uuid';

@Injectable()
export class RonEffects {
  startCheckInForSuccessfulMultiFactorAttempt$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MultiFactorActions.MultiFactorAttemptSuccess),
      map(() => CheckInActions.RedirectToAvCheckIfRequired())
    )
  );

  startRejoin$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LobbyActions.StartRejoin),
      withLatestFrom(this.store.pipe(select(DeviceGroupSelectors.getDeviceCode))),
      switchMap(([action, deviceCode]) => {
        return this.deviceGroupService.rejoin(action.payload.packageUserGuid, deviceCode).pipe(
          switchMap(() => [
            DeviceGroupActions.SetParticipantRejoining({
              payload: {
                isParticipantRejoining: true,
                packageUserGuid: action.payload.packageUserGuid,
              },
            }),
            SignalRActions.AttemptSignalRHubConnection({
              payload: {
                packageUserGuid: action.payload.packageUserGuid,
              },
            }),
            PackageUsersActions.SetActivePackageUserGuid({
              payload: { activePackageUserGuid: action.payload.packageUserGuid },
            }),
          ]),
          catchError((err) =>
            of(
              NotificationsActions.AddNotification({
                payload: {
                  exception: err,
                  notificationType: NotificationType.Error,
                  id: uuid(),
                  text: 'Failed to rejoin user',
                  exceptionType: ExceptionType.CannotProceed,
                },
              })
            )
          )
        );
      })
    )
  );

  enterRejoinWaitingLobby$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(RonActions.EnterRejoinWaitingLobby),
        tap(() => {
          this.router.navigate(['/ron/rejoin-waiting-lobby'], {
            queryParamsHandling: 'preserve',
          });
        })
      ),
    { dispatch: false }
  );

  enterSigningRoom$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(RonActions.EnterSigningRoom),
        tap(() => {
          this.router.navigate(['/ron/signing-room'], {
            queryParamsHandling: 'preserve',
          });
        })
      ),
    { dispatch: false }
  );

  refreshEndorsements$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DeviceGroupActions.DeviceGroupUpdated),
      withLatestFrom(this.store.pipe(select(DeviceGroupSelectors.getUsersOnDevice))),
      map(([_, deviceUsers]) => {
        const devicePackageUserGuids = deviceUsers.map((deviceUser) =>
          deviceUser.packageUserGuid.toLowerCase()
        );

        return EndorsementsActions.RefreshEndorsementsSignableStatus({
          payload: { devicePackageUserGuids },
        });
      })
    )
  );

  $signingSessionStarted$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RonActions.SigningSessionStarted),
      switchMap(() => [
        DeviceGroupActions.GetCurrentDeviceUsers(),
        SigningRoomActions.SigningSessionJoined(),
        DocumentsActions.FetchSigningSessionDocuments({
          payload: { refetchSmartDocs: true },
        }),
        EndorsementsActions.FetchEndorsementImages(),
        EndorsementsActions.FetchSystemEndorsementImages(),
        ModalsActions.DisableGlobalSpinner(),
      ])
    )
  );

  configureListeners$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SignalRActions.SignalRRoomJoined),
        map(() => {
          this.ronService.configureListeners();
        })
      ),
    { dispatch: false }
  );

  getSessionStatuses$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RonActions.GetSessionStatuses),
      withLatestFrom(
        this.store.pipe(select(PackagesSelectors.getActivePackageGuid)),
        this.store.pipe(select(PackagesSelectors.getActivePackageStatus))
      ),
      switchMap(([_, packageGuid, packageStatus]) => {
        return this.ronService.getSessionStatus(packageGuid).pipe(
          switchMap((payload) => {
            const { isComplete, isSessionEnded, signingEndedWithError, signingEndSessionStarted } =
              payload;
            let actions: Array<any> = [
              RonActions.SetSessionStatuses({
                payload,
              }),
            ];
            const endSessionStartedButHasNotFinished =
              signingEndSessionStarted && !(isComplete || isSessionEnded);
            if (
              endSessionStartedButHasNotFinished &&
              !this.router.url.includes('/ron/session-ending')
            ) {
              actions = [
                ...actions,
                RonActions.NavigateToSessionEnding(),
                ModalsActions.ClearModalComponent(),
              ];
            }
            const isEndSessionSuccessful = isComplete && !signingEndedWithError;
            const isEndSessionFailure = isSessionEnded && signingEndedWithError;
            if (isEndSessionSuccessful) {
              actions = [
                ...actions,
                RonActions.SetEndSessionSuccessful(),
                ModalsActions.ClearModalComponent(),
              ];
            }
            if (isEndSessionFailure || packageStatus === PackageStatus.SIGNINGFAILED || packageStatus === PackageStatus.VAULTINGFAILED) {
              actions = [
                ...actions,
                RonActions.SetEndSessionFailure(),
                ModalsActions.ClearModalComponent(),
              ];
            }
            return actions;
          }),
          catchError((err) =>
            of(
              NotificationsActions.AddNotification({
                payload: {
                  exception: err,
                  notificationType: NotificationType.Error,
                  id: uuid(),
                  text: 'Failed to get Session End status aggregates',
                  exceptionType: ExceptionType.None,
                },
              })
            )
          )
        );
      })
    )
  );

  endSessionStarted$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(RonActions.NavigateToSessionEnding),
        tap((_) => {
          this.router.navigate(['/ron/session-ending'], {
            queryParamsHandling: 'preserve',
          });
        })
      ),
    { dispatch: false }
  );

  constructor(
    private readonly router: Router,
    private readonly actions$: Actions,
    private readonly store: Store<RootStoreState.State>,
    private readonly ronService: RonService,
    private readonly deviceGroupService: DeviceGroupService,
    private readonly featureManagementService: FeatureManagementService
  ) { }
}
