import { Injectable } from '@angular/core';
import { ProductType } from '../../models';
import { DefaultDeviceValidatorService } from './default-device-validator.service';
import { HybridDeviceValidatorService } from './hybrid-device-validator.service';
import { IDeviceValidatorService } from './IDeviceValidatorService';
import { RonDeviceValidatorService } from './ron-device-validator.service';

@Injectable({
  providedIn: 'root'
})
export class DeviceValidatorServiceFactory {

  constructor() { }

  getDeviceValidatorServiceForProduct(productType: ProductType) : IDeviceValidatorService {
    if(productType === ProductType.RemoteSigning) {
      return new RonDeviceValidatorService();
    }
    else if(productType === ProductType.Hybrid) {
      return new HybridDeviceValidatorService();
    }
    else {
      return new DefaultDeviceValidatorService();
    }
  }

}
