import { Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { ClientSelectors } from 'src/app/features/client';
import { ClientTheme } from 'src/app/features/client/models';

import { RootStoreState } from 'src/app/store';

@Component({
  selector: 'app-header-slim',
  templateUrl: './header-slim.component.html',
  styleUrls: ['./header-slim.component.scss'],
})
export class HeaderSlimComponent implements OnInit {
  clientTheme$: Observable<ClientTheme>;

  constructor(private readonly store: Store<RootStoreState.State>) {}

  ngOnInit(): void {
    this.clientTheme$ = this.store.pipe(select(ClientSelectors.getClientTheme));
  }
}
