<div class="enlarge-id">
  <div class="enlarge-id__body">
    <img
      *ngIf="isBase64"
      src="data:image/jpg;base64,{{ idImage }}"
      alt="id image"
      [style]="idImageStyle"
      data-private
    />
    <img *ngIf="!isBase64" src="{{ idImage }}" alt="id image" [style]="idImageStyle" />
  </div>
  <div class="enlarge-id__footer">
    <button class="btn btn--outline-success" (click)="close()" data-testid="modal-close">
      Close
    </button>
  </div>
</div>
