export enum BrowserFilterType {
  Chrome = 'Chrome',
  Edge = 'Edge',
}

export enum GadgetType {
  Camera = 'Camera',
  Microphone = 'Microphone',
  Speaker = 'Speaker',
}

export interface Brand {
  brand: string;
  version: string;
}

export interface GadgetAgent {
  browserType: string;
  browserVersion: string;
  deviceModel: string;
  deviceName: string;
  osType: string;
  osVersion: string;
  gadgetType: GadgetType;
}

export interface GadgetGuideRequest {
  gadgetAgent: GadgetAgent;
  response: GadgetGuideResponse;
  status: string;
  error: string;
}

export interface GadgetGuideResponse {
  title: string;
  introduction: string;
  steps: Step[];
  additionalInfo: AdditionalInfo[];
}

export interface Step {
  step: number;
  instruction: string;
}

export interface AdditionalInfo {
  text: string;
}

export interface GadgetGuide {
  [sha256: string]: GadgetGuideRequest;
}
