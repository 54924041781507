<app-confirmation-modal>
  <ng-container header>
    <h1>Signature Consent Agreement</h1>
  </ng-container>

  <ng-container subHeader>
    <p>Here's what you need to know about your signature consent agreement.</p>
  </ng-container>

  <ng-container content>
    <app-scrollable-text-box [contentMarkup]="(endorsementsConsent$ | async)?.presentHtml">
    </app-scrollable-text-box>
  </ng-container>

  <ng-container actionButton>
    <app-button type="tertiary" (click)="back()" [dataTestId]="'signature-consent-agreement-back'">
      Go Back
    </app-button>

    <app-button type="primary" (click)="accept()" [dataTestId]="'signature-consent-agreement-agree'">
      Agree
    </app-button>
  </ng-container>
</app-confirmation-modal>
