import { HttpRequest } from '@angular/common/http';
import {
  MsalInterceptorAuthRequest,
  MsalInterceptorConfiguration,
  MsalService,
} from '@azure/msal-angular';
import { InteractionType } from '@azure/msal-browser';

import { environment } from 'src/environments/environment';

import { MSAL_CONFIG } from './msal.config';

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>(
    environment.protectedResourceMap
  );
  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap,
    authRequest: (
      msalService: MsalService,
      httpReq: HttpRequest<unknown>,
      originalAuthRequest: MsalInterceptorAuthRequest
    ) => {
      // It matters which authority we use to request the token since we will get
      // back different claims in the token from different authorities. Use the authority
      // that was used to authenticate the user originally
      const account = originalAuthRequest.account as any;
      const accountAuthority =
        account?.idTokenClaims?.acr ?? account?.idTokenClaims?.tfp;

      if (!!!accountAuthority) {
        return originalAuthRequest
      }

      const authority = MSAL_CONFIG.auth.knownAuthorities.find((x) =>
        x.toLowerCase().includes(accountAuthority.toLowerCase())
      );
      return {
        ...originalAuthRequest,
        authority: authority ?? MSAL_CONFIG.auth.authority,
      };
    },
  };
}
