export interface UserAddressInformation {
    packageUserId: number;
    packageUserGuid: string;
    streetAddress1: string;
    streetAddress2?: string;
    city: string;
    stateCode: string;
    zipCode: string;
    zip4?: string;
    firstName: string;
    lastName: string;
}