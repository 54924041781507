<app-nav-bar-signing-session></app-nav-bar-signing-session>

<main>
  <router-outlet></router-outlet>
  <app-modal-container></app-modal-container>
  <app-loading-spinner></app-loading-spinner>
  <app-internet-check></app-internet-check>
  <app-audio-video-connection-monitor
    *ngIf="useAudioVideoConnectionMonitor"
  ></app-audio-video-connection-monitor>
</main>
<app-footer></app-footer>
