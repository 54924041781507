import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-wizard-step-wrapper',
  templateUrl: './wizard-step-wrapper.component.html',
  styleUrls: ['./wizard-step-wrapper.component.scss'],
  animations: [
    trigger('openClose', [
      state(
        'open',
        style({
          opacity: 1,
          visibility: 'visible',
        })
      ),
      state(
        'closed',
        style({
          height: 0,
          opacity: 0,
          visibility: 'hidden',
        })
      ),
      transition('closed => open', [animate('0.4s')]),
    ]),
  ],
})
export class WizardStepWrapperComponent {
  @Input() showStep: boolean;
  @Input() stepTitle: string;
  @Input() hideHeader = false;
  @Input() bannerText: string;
  constructor() {}
}
