<app-wizard-step-wrapper
  [showStep]="showStep"
  [stepTitle]="stepMetadata.stepName"
  [hideHeader]="isIdVerificationComplete$ | async"
  [formGroup]="formStep"
>
  <div
    *ngIf="isIdVerificationComplete$ | async; else inProgress"
    class="id-verification-complete step-container"
  >
    <div class="id-verification-complete__message">
      <img
        src="../../../../../assets/images/identity_success.svg"
        alt="ID Card with green checkmark"
      />
      <h3>Successfully Submitted</h3>
      <p>You may now proceed with the security questions.</p>
    </div>
    <app-button-container>
      <app-button [dataTestId]="'next-button-id-complete'" type="primary" (click)="goToNextStep()"
        >Next</app-button
      >
    </app-button-container>
  </div>
</app-wizard-step-wrapper>

<ng-template #inProgress>
  <div class="id-verification-in-progress step-container">
    <h3 class="form-step__subheading">
      {{ user?.firstName }}, your ID verification is still in progress.
    </h3>

    <div class="id-verification-in-progress__banner">
      <app-spark-icon icon="information" size="36"></app-spark-icon>
      <div>{{ bannerMessage }}</div>
    </div>

    <div class="button-container">
      <div class="form-step__text">
        Accidentally close the ID Verification process? Use the
        <span class="form-step__text--bold">Previous</span> button to return to the last step and
        restart it.
      </div>
      <app-button-container>
        <app-button
          [dataTestId]="'previous-button-id-in-progress'"
          type="tertiary"
          (click)="goToPreviousStep()"
        >
          Previous
        </app-button>
        <app-button
          [dataTestId]="'next-button-id-in-progress'"
          type="primary"
          *ngIf="shouldBypassRealId$ | async"
          (click)="completeFakeIdVerification()"
        >
          Next
        </app-button>
      </app-button-container>
    </div>
  </div>
</ng-template>
