<div
  [ngClass]="{
    middle: true,
    'middle--active': (requiredSignatureTotal$ | async) > 0
  }"
>
  <span class="middle__count" data-testid="signature-count">{{ requiredSignatureTotal$ | async }}</span>
  <span data-testid="signature-text"
    >{(requiredSignatureTotal$ | async), plural, =1 {Signature} other
    {Signatures}}</span
  >
</div>
