import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Store, select } from '@ngrx/store';

import { RootStoreState } from 'src/app/store';
import { EndorsementsSelectors } from '../../store';

@Component({
  selector: 'app-required-signature-count',
  templateUrl: './required-signature-count.component.html',
  styleUrls: ['./required-signature-count.component.scss'],
})
export class RequiredSignatureCountComponent implements OnInit {
  requiredSignatureTotal$: Observable<number>;
  constructor(private readonly store: Store<RootStoreState.State>) {}

  ngOnInit(): void {
    this.requiredSignatureTotal$ = this.store.pipe(
      select(EndorsementsSelectors.selectActiveDocumentRemainingSignatures)
    );
  }
}
