import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { PackageUsersSelectors } from 'src/app/features/package-users';

import { RootStoreState } from 'src/app/store';

@Component({
  selector: 'app-unsupported-device',
  templateUrl: './unsupported-device.component.html',
  styleUrls: ['./unsupported-device.component.scss'],
})
export class UnsupportedDeviceComponent implements OnInit {
  constructor(private readonly store: Store<RootStoreState.State>) {}

  isSigningAgent$: Observable<boolean>;

  ngOnInit(): void {
    this.isSigningAgent$ = this.store.select(PackageUsersSelectors.getIsSigningAgent);
  }
}
