import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { AccessLinkHttpResponse } from '../models';

@Injectable({
  providedIn: 'root',
})
export class AccessLinkService {
  constructor(private readonly http: HttpClient) { }

  getAccessLink(packageUserGuid: string, deviceCode: string): Observable<AccessLinkHttpResponse> {
    return this.http.get<AccessLinkHttpResponse>(
      `idVerification/accessLink/packageUserGuid/${packageUserGuid}/deviceCode/${deviceCode}`
    );
  }

  postSendAccessLinkSms(packageUserGuid: string, deviceCode: string, phoneNumberOverride: string) {
    return this.http.post('idVerification/accessLink/sms', {
      PackageUserGuid: packageUserGuid,
      DeviceCode: deviceCode,
      PhoneNumberOverride: phoneNumberOverride
    });
  }
}
