<img
  class="feedback-image"
  src="../../../assets/images/not-authorized.svg"
  alt=""
  data-testid="unauthorized-pic"
/>
<div class="message-container">
  <h1 class="message-container__header" data-testid="unauthorized-header">Not Authorized</h1>
  <p class="message-container__message" data-testid="unauthorized-text">
    You are not authorized to access this signing. If you believe you came to this page by accident,
    please try your link again.
  </p>
  <app-further-assistance [showDefaultClientInfo]="true"></app-further-assistance>
</div>
