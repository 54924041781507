import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import {
  MSAL_INSTANCE,
  MSAL_INTERCEPTOR_CONFIG,
  MsalBroadcastService,
  MsalModule,
  MsalService,
} from '@azure/msal-angular';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { MSALInstanceFactory, MSALInterceptorConfigFactory } from './config';
import { AuthenticationService } from './services';
import { MsalAuthEffects, MsalAuthReducers } from './store';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(
      'msalAuth',
      MsalAuthReducers.Reducers.msalAuthReducer
    ),
    MsalModule,
    EffectsModule.forFeature([MsalAuthEffects.MsalAuthEffects]),
  ],
  providers: [
    AuthenticationService,
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory,
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory,
    },
    MsalService,
    MsalBroadcastService,
  ],
})
export class MsalAuthModule {}
