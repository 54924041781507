<img
  class="feedback-image"
  src="../../../assets/images/unsupported-devices.svg"
  data-testid="unsupported-os"
  alt=""
/>
<div class="message-container">
  <h1 class="message-container__header">Uh Oh... Your device's OS version is not supported.</h1>
  <p class="message-container_message">Please try another device if available.</p>
  <app-further-assistance [showDefaultClientInfo]="true"></app-further-assistance>
</div>
