<div id="document-viewer" #documentviewer>
  <ng-container *ngIf="activePackageDocument">
    <app-smart-document-viewer
      *ngIf="activePackageDocument.isSmartDoc; else regularDoc"
      [packageDoc]="activePackageDocument"
      [topOffset]="topOffset"
      [bottomOffset]="bottomOffset"
      class="document-rendered-view"
    ></app-smart-document-viewer>
    <ng-template #regularDoc>
      <img
        class="document-rendered-view"
        data-private
        [src]="
          'data:image/png;base64,' +
          activePackageDocument.packageDocumentImageString
        "
        [ngStyle]="{
          'margin-top': topOffset ? topOffset.toString() + 'px' : '0px',
          'margin-bottom': bottomOffset ? bottomOffset.toString() + 'px' : '0px'
        }"
        alt=""
      />
    </ng-template>
  </ng-container>
</div>

<ng-content></ng-content>
