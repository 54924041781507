<div class="signature-pad" style="--aspect-ratio: 760/190" data-private>
  <canvas #signaturepad [attr.data-testid]="mode + '-canvas'"></canvas>
  <img
    class="signature-pad__background-image"
    src="../../../../../assets/images/signature-pad-line.png"
    alt=""
  />
</div>
<p class="signature-pad__error" *ngIf="showErrorMessages && signatureValidity?.isTouchingEdge">
  You went outside the lines, please only draw within the box.
</p>
<p
  class="signature-pad__error"
  *ngIf="showErrorMessages && signatureValidity && !signatureValidity.isComplex"
>
  It's too small! Try again, and use more of the available drawing area.
</p>
<div class="signature-pad__buttons">
  <app-button
    class="signature-pad__buttons__button"
    type="tertiary"
    size="small"
    (click)="cancelForInstance()"
    [dataTestId]="'draw-' + mode + '-cancel'"
  >
    Cancel
  </app-button>
  <app-button
    class="signature-pad__buttons__button"
    type="secondary"
    size="small"
    (click)="clearCanvas()"
    [dataTestId]="'draw-' + mode + '-clear'"
  >
    Reset
  </app-button>
  <app-button
    class="signature-pad__buttons__button"
    type="primary"
    size="small"
    (click)="setSignature()"
    [dataTestId]="'draw-' + mode + '-save'"
  >
    Save
  </app-button>
</div>
