import { createAction } from '@ngrx/store';

export const PreviewDocuments = createAction('[Consumer Portal] Preview Documents');

export const SigningRoomCheckIn = createAction(
  '[Consumer Portal] Signing Room Check In'
);

export const CompletedPackageDownload = createAction(
  '[Consumer Portal] Completed Document Download'
);

export const SigningDashboardOpened = createAction(
  '[Signing Dashboard] Opened'
);


