import { createFeatureSelector, createSelector } from '@ngrx/store';

import { PackageUser, PackageUsersState } from 'src/app/features/package-users';

import { CheckInTask } from '../../models';
import { WizardState } from '../state';

export const getPackageUserState = createFeatureSelector<PackageUsersState.State>('packageUsers');

export const getWizardState = createFeatureSelector<WizardState.State>('wizard');

export const hasActiveWizardUser = createSelector(
  getWizardState,
  (state: WizardState.State) => !!state.activeWizardUser
);

export const getActiveWizardUser = createSelector(
  [getPackageUserState, getWizardState],
  (userState: PackageUsersState.State, wizState: WizardState.State) =>
    wizState.activeWizardUser ?? userState.activePackageUser
);

export const getActiveWizardUserGuid = createSelector(
  getPackageUserState,
  getActiveWizardUser,
  (state: PackageUsersState.State, activeWizardUser: PackageUser) =>
    activeWizardUser?.packageUserGuid ?? state.activePackageUserGuid
);

export const getTasks = createSelector(
  getWizardState,
  (state: WizardState.State) => state.tasks ?? []
);

export const getAreAllTasksComplete = createSelector(getTasks, (tasks: CheckInTask[]) =>
  tasks.every((t) => !!t.isCompleted)
);

export const getCurrentTaskIndex = createSelector(
  getWizardState,
  (state: WizardState.State) => state.currentTaskIndex
);

export const getCurrentTask = createSelector(
  getWizardState,
  getCurrentTaskIndex,
  (state: WizardState.State, currentIndex: number) => state.tasks[currentIndex]
);

export const getCurrentTaskCode = createSelector(
  getWizardState,
  getCurrentTask,
  (state: WizardState.State, currentTask: CheckInTask) => currentTask?.taskType
);
