import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { select, Store } from '@ngrx/store';

import { combineLatest, Observable, pipe, Subject } from 'rxjs';
import { filter, map, takeUntil, tap } from 'rxjs/operators';

import { ClientActions, ClientSelectors } from 'src/app/features/client';
import { PackageUser, PackageUsersSelectors } from 'src/app/features/package-users';
import { RdsInputComponent } from 'src/app/features/shared/components/rds-input/rds-input.component';
import { QRCODE_REFRESH_TIME } from 'src/app/features/shared/constants';
import { AbstractWizardStepComponent, CheckInTaskCode, WizardStep } from 'src/app/features/wizard';
import { RootStoreState } from 'src/app/store';
import { environment } from 'src/environments/environment';

import { IdVerificationActions, IdVerificationSelectors } from '../../store';

@Component({
  selector: 'app-id-app-access',
  templateUrl: './id-app-access.component.html',
  styleUrls: ['./id-app-access.component.scss'],
  providers: [
    {
      provide: AbstractWizardStepComponent,
      useExisting: IdAppAccessComponent,
    },
  ],
})
export class IdAppAccessComponent extends AbstractWizardStepComponent implements OnInit, OnDestroy {
  @ViewChild(RdsInputComponent)
  phoneNumberComponent: RdsInputComponent;

  @Input() isMobile: boolean;
  @Input() user: PackageUser;

  phoneNumberErrorMessages: Record<string, string> = {
    required: 'Please enter your phone number',
    default: 'Please enter your phone number like: (xxx) xxx-xxxx',
  };

  phoneNumberOverride: string;
  defaultPhoneNumber: string;
  hasRedirected = false;
  shouldBypassRealId = false;
  accessLink: Observable<string>;
  hasAccessLinkSmsSentSuccessfully: boolean;
  hasAccessLinkSmsFailed: boolean;
  sendAccessLinkSmsButtonText = 'Send link';
  faqUrl = environment.clearsignFaqUrl;
  refreshQrCodeTimeout: ReturnType<typeof setTimeout>;
  isPhoneNumberValid: boolean;
  validationErrorMessage: string;

  private _stepHiddenNotifier$ = new Subject();

  constructor(private readonly store: Store<RootStoreState.State>) {
    super(store);
    this.stepMetadata = {
      stepName: '',
      checkInTaskCode: CheckInTaskCode.IdVerificationQrCode,
    } as WizardStep;
    this.formStep = new UntypedFormGroup({});
  }

  ngOnInit() {
    super.ngOnInit();
    this.stepMetadata.stepName = this.isMobile ? 'Accessing the App' : 'Open ID Scanner';
    this.fetchAccessLink();
    this.getClientIsRealId();
    this.getAccessLink();
    this.getHasAccessLinkSmsSentSuccessfully();
    this.setDefaultPhoneNumber();
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this._stepHiddenNotifier$.next(undefined);
    this._stepHiddenNotifier$.complete();
    clearTimeout(this.refreshQrCodeTimeout);
  }

  fetchAccessLink() {
    this.store.dispatch(IdVerificationActions.FetchAccessLink());
  }

  clearPhoneNumberIfDefault() {
    if (this.phoneNumberOverride === this.defaultPhoneNumber) {
      this.phoneNumberOverride = '';
    }
  }

  getClientIsRealId() {
    this.store.dispatch(ClientActions.GetShouldBypassRealId());
    this.store
      .pipe(
        select(ClientSelectors.shouldBypassRealId),
        takeUntil(this._stepHiddenNotifier$),
        tap((shouldBypassRealId) => {
          this.shouldBypassRealId = shouldBypassRealId;
        })
      )
      .subscribe();
  }

  getHasAccessLinkSmsSentSuccessfully() {
    this.store
      .pipe(
        select(IdVerificationSelectors.getHasAccessLinkSmsSentSuccessfully),
        takeUntil(this._stepHiddenNotifier$),
        pipe(
          filter((hasAccessLinkSmsSentSuccessfully) => hasAccessLinkSmsSentSuccessfully != null)
        ),
        tap((hasAccessLinkSmsSentSuccessfully) => {
          if (hasAccessLinkSmsSentSuccessfully) {
            this.hasAccessLinkSmsSentSuccessfully = true;
            this.sendAccessLinkSmsButtonText = 'Resend link';
          } else {
            this.hasAccessLinkSmsSentSuccessfully = false;
          }
        })
      )
      .subscribe();
  }

  setDefaultPhoneNumber() {
    this.store
      .select(PackageUsersSelectors.getPackageUser(this.user.packageUserGuid))
      .pipe(
        filter((user) => !!user?.phoneNumber),
        map((user) => user.phoneNumber),
        takeUntil(this._stepHiddenNotifier$),
        tap((unmaskedNumber) => {
          const prefix = '000000';
          this.defaultPhoneNumber = prefix + unmaskedNumber;
          this.phoneNumberOverride = this.defaultPhoneNumber;
        })
      )
      .subscribe();
  }

  getAccessLink() {
    this.accessLink = this.store.pipe(
      select(IdVerificationSelectors.getAccessLink),
      takeUntil(this._stepHiddenNotifier$),
      tap((link) => {
        if (!link) {
          return;
        }

        this.refreshQrCodeTimeout = setTimeout(() => {
          this.fetchAccessLink();
        }, QRCODE_REFRESH_TIME + 1000);
      })
    );
  }

  addAuditLog() {
    if (this.isMobile) {
      this.store.dispatch(IdVerificationActions.SaveAuditPhotoIdBegin());
    }
  }

  navigateTo(link: string) {
    const redirectUri = encodeURIComponent(window.location.href);
    window.location.href = `${link}&redirectUri=${redirectUri}`;
  }

  sendAccessLinkSms() {
    if (this.validateInput()) {
      const phoneNumberToSMS =
        this.defaultPhoneNumber === this.phoneNumberOverride ? '' : this.phoneNumberOverride;
      this.store.dispatch(IdVerificationActions.SendAccessLinkSms(phoneNumberToSMS));
    }
  }

  validateInput(): boolean {
    const phoneInputModel = this.phoneNumberComponent?.phoneInputModel;
    this.validationErrorMessage = '';
    this.isPhoneNumberValid = true;

    if (!!phoneInputModel?.errors) {
      this.validationErrorMessage = this.getPhoneNumberValidationErrorMessage(
        phoneInputModel.errors
      );
      this.isPhoneNumberValid = false;
    }
    return this.isPhoneNumberValid;
  }

  getPhoneNumberValidationErrorMessage(errors): string {
    const errorKeys = Object.keys(errors);
    return this.phoneNumberErrorMessages[errorKeys[0]] ?? this.phoneNumberErrorMessages['default'];
  }

  public goToNextStep(payload?: any): void {
    if (this.shouldBypassRealId) {
      super.goToNextStep();
    }
  }

  public onStepVisible(): void {
    this.addAuditLog();
    this.fetchAccessLink();
    this._stepHiddenNotifier$ = new Subject();
    this.getAccessLink();
    this.hasRedirected = false;
    this.store.dispatch(IdVerificationActions.IdVerificationQrCodeShown());

    this.isSessionStartedOrComplete$
      .pipe(
        takeUntil(this._stepHiddenNotifier$),
        filter((isSessionStartedOrComplete) => isSessionStartedOrComplete && !this.hasRedirected),
        tap(() => {
          // resetting isSessionStarted so if they use the 'Previous' button on the next
          // step to see the QR code/link again, they do not automatically get redirected
          // away until they re-visit that link
          this.hasRedirected = true;
          this.store.dispatch(
            IdVerificationActions.ResetSessionStarted({
              payload: { packageUserGuid: this.user.packageUserGuid },
            })
          );
          super.goToNextStep();
        })
      )
      .subscribe();
  }

  public onStepHidden(): void {
    this._stepHiddenNotifier$.next(undefined);
  }

  private get isSessionStartedOrComplete$(): Observable<boolean> {
    return combineLatest([
      this.store.select(IdVerificationSelectors.getIsSessionStarted(this.user.packageUserGuid)),
      this.store.select(IdVerificationSelectors.getIsComplete(this.user.packageUserGuid)),
    ]).pipe(map(([isStarted, isComplete]) => isStarted || isComplete));
  }
}
