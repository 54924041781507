import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';

import { PackageUser } from 'src/app/features/package-users';
import { SignatureModel } from 'src/app/features/rules/models';
import { RootStoreState } from 'src/app/store';

import { ActiveEndorsementSelection, EndorsementSelection } from '../../models';
import { EndorsementsCreationActions, EndorsementsCreationSelectors } from '../../store';

@Component({
  selector: 'app-select-initials',
  templateUrl: './select-initials.component.html',
  styleUrls: ['./select-initials.component.scss'],
})
export class SelectInitialsComponent implements OnInit, OnDestroy {
  @Input() user: PackageUser;
  @Output() stepComplete = new EventEmitter();
  @Output() previousStepClicked = new EventEmitter();

  formStep: UntypedFormGroup;
  initialsOptions$: Observable<Array<EndorsementSelection>>;
  selectedInitials$: Observable<ActiveEndorsementSelection>;
  selectedInitialsSubscription: Subscription;
  initialsHaveChanged: boolean;
  signatureTypeEligibility: Observable<SignatureModel>;

  constructor(private readonly store: Store<RootStoreState.State>) {
    this.formStep = new UntypedFormGroup({
      initialsSelected: new UntypedFormControl('', [Validators.requiredTrue]),
    });
  }

  ngOnInit() {
    this.initialsHaveChanged = false;
    this.initialsOptions$ = this.store.pipe(
      select(EndorsementsCreationSelectors.getInitialsPreviews)
    );
    this.selectedInitials$ = this.store.pipe(
      select(EndorsementsCreationSelectors.getActiveInitialsIndex),
      tap((selecedInitials) => {
        if (selecedInitials.activeIndex !== null) {
          this.formStep.controls.initialsSelected.setValue(true);
        }
      })
    );

    this.store.dispatch(EndorsementsCreationActions.FetchInitialsPreviews());
  }

  ngOnDestroy() {
    this.selectedInitialsSubscription?.unsubscribe();
  }

  updateActiveInitials(index: number) {
    this.formStep.controls.initialsSelected.setValue(true);
    this.store.dispatch(EndorsementsCreationActions.SetActiveInitialsByIndex({ payload: index }));
    this.initialsHaveChanged = true;
  }

  selectInitials() {
    if (this.initialsHaveChanged) {
      this.initialsHaveChanged = false;
      this.store.dispatch(EndorsementsCreationActions.InitialsSelected());
    }
  }

  public goToNextStep(payload?: any): void {
    if (!this.formStep.valid) {
      this.formStep.setErrors({ error: 'select an initial' });
      return;
    }

    this.selectInitials();
    this.stepComplete.emit();
  }

  onPreviousClick() {
    this.previousStepClicked.emit();
  }
}
