import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { DeviceGroupEffects } from './store';
import { DeviceGroupReducers } from './store/reducers';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(
      'deviceGroup',
      DeviceGroupReducers.Reducers.deviceGroupReducer
    ),
    EffectsModule.forFeature([DeviceGroupEffects.DeviceGroupEffects]),
  ],
})
export class DeviceGroupModule {}
