<div class="callout">
  <div class="message-container">
    <div class="image-container">
      <img src="../../../../../assets/images/alarm.svg" alt="Alarm" />
    </div>
    <div class="text-container">
      <h1>Your Commission Is Expired</h1>
      <p>
        To update, click the “Update Your Commission” button below. After updating you will have to
        re-start this session. If you can’t update it at this time, please cancel the signing and
        reschedule.
      </p>
      <div class="button-container">
        <app-button
          type="warning-secondary"
          (click)="cancelSigning()"
          [dataTestId]="'expired-commission-cancel-signing'"
        >
          Cancel Signing
        </app-button>
        <app-button
          type="primary"
          (click)="updateCommission()"
          [dataTestId]="'expired-commission-update'"
        >
          Update Your Commission
        </app-button>
      </div>
    </div>
  </div>
</div>
