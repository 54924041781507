<app-nav-bar>
  <ul mobile class="nav-bar__mobile__items">
    <li class="nav-bar__mobile__item" data-testid="header-support-phoneNumber">
      <a target="_blank" href="tel:{{ this.nexsysSupportNumber }}" rel="noopener"
      >Support: {{ this.nexsysSupportNumber }}</a
      >
    </li>
    <li class="nav-bar__mobile__item" data-testid="header-privacy-policy">
      <a target="_blank" href="https://www.amrock.com/privacy-policy" rel="noopener"
      >Privacy Policy</a
      >
    </li>
    <li class="nav-bar__mobile__item" data-testid="header-terms-of-use">
      <a target="_blank" href="https://www.amrock.com/terms-of-use" rel="noopener">Terms of Use</a>
    </li>
    <li class="nav-bar__mobile__item">
      <button (click)="logout()" data-testid="presign-logout">Logout</button>
    </li>
    <li class="nav-bar__mobile__item" *ngIf="isWizard || isSigningRoom">
      <button
        style="display: flex; align-items: center"
        (click)="exitSigning()"
        data-testid="exit-signing-button"
      >
        <mat-icon>logout</mat-icon>
        <span style="margin-left: 5px">Exit Signing</span>
      </button>
    </li>
  </ul>

  <app-menu
    [isPrivacyPolicyButtonEnabled]="true"
    [isTermsOfUseButtonEnabled]="true"
    [isPreSignLogoutButtonEnabled]="true">
  </app-menu>
  <app-support-phone-number-button></app-support-phone-number-button>
  <button
    mat-button
    *ngIf="isWizard || isSigningRoom"
    (click)="exitSigning()"
    tabIndex="4"
    data-testid="exit-signing-button"
  >
    <mat-icon>logout</mat-icon>
    Exit Signing
  </button>
</app-nav-bar>
