import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { RootStoreState } from 'src/app/features/../store';
import { ModalsActions, ModalSize } from 'src/app/features/modals';
import { PackageUser, PackageUsersSelectors } from 'src/app/features/package-users';

import { CheckInActions } from '../../store';
import { AddParticipantModalComponent } from '../add-participant-modal';

@Component({
  selector: 'app-switch-devices-confirmation-modal',
  templateUrl: './switch-devices-confirmation-modal.component.html',
  styleUrls: ['./switch-devices-confirmation-modal.component.scss'],
})
export class SwitchDevicesConfirmationModalComponent implements OnInit, OnDestroy {
  static identifier = 'SwitchDevicesConfirmationModalComponent';
  @Input() packageUserGuid: string;
  @Input() isCheckInComplete: boolean;
  @Input() isInWaitingRoom = false;

  user$: Observable<PackageUser>;

  constructor(private readonly store: Store<RootStoreState.State>) {}

  ngOnInit(): void {
    this.user$ = this.store.select(PackageUsersSelectors.getPackageUser(this.packageUserGuid));
  }

  back() {
    this.store.dispatch(
      ModalsActions.ClearModalComponentIfOpen({
        payload: { component: SwitchDevicesConfirmationModalComponent },
      })
    );
  }

  accept() {
    this.store.dispatch(
      CheckInActions.ToggleUserCheckInSequence({
        payload: {
          packageUserGuid: this.packageUserGuid,
        },
      })
    );
    this.back();
    if (this.isInWaitingRoom) {
      this.store.dispatch(
        ModalsActions.SetStandaloneModalComponent({
          payload: {
            component: AddParticipantModalComponent,
            componentData: {
              isInWaitingRoom: true
            },
            modalSize: ModalSize.large
          },
        })
      );
    }
  }

  ngOnDestroy(): void {
    this.back();
  }
}
