import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';

import { of } from 'rxjs';
import { catchError, exhaustMap, switchMap } from 'rxjs/operators';
import { v4 as uuid } from 'uuid';

import { DeviceGroupSelectors } from 'src/app/features/device-group/store/selectors';
import { NotificationsActions, NotificationType } from 'src/app/features/notifications';
import { ExceptionType } from 'src/app/features/notifications/models';
import { PackageUsersActions, PackageUsersSelectors } from 'src/app/features/package-users';
import { PackagesSelectors } from 'src/app/features/packages';
import { RootStoreState } from 'src/app/store';

import { LobbyService } from '../../services';
import { LobbyActions } from '../actions';

@Injectable()
export class LobbyEffects {
  fetchLobbyDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LobbyActions.FetchLobbyDetails),
      concatLatestFrom(() => [
        this.store.pipe(select(PackagesSelectors.getActivePackageGuid)),
        this.store.pipe(select(PackageUsersSelectors.getPackageUsers)),
      ]),
      exhaustMap(([_, packageGuid, previousPackageUsers]) => {
        return this.lobbyService.getLobbyDetails(packageGuid).pipe(
          switchMap((payload) => {
            // If any user's details have updated since the last poll, we should
            // refetch the PackageUsers as well.
            const refetchUsers = payload?.some((pu) => {
              const previousUser = previousPackageUsers?.find(
                (prev) => prev.packageUserGuid === pu.packageUserGuid
              );
              return (
                !!previousUser &&
                (previousUser.firstName !== pu.firstName ||
                  previousUser.lastName !== pu.lastName ||
                  previousUser.signatureName !== pu.signatureName)
              );
            });

            return [
              LobbyActions.FetchLobbyDetailsSuccessful({ payload }),
              PackageUsersActions.FetchPackageUsers({
                payload: { ignoreIfLoaded: !refetchUsers },
              }),
            ];
          }),
          catchError((err) =>
            of(
              NotificationsActions.AddNotification({
                payload: {
                  exception: err,
                  notificationType: NotificationType.Error,
                  id: uuid(),
                  text: 'Failed to retrieve lobby details',
                  exceptionType: ExceptionType.CannotProceed,
                },
              })
            )
          )
        );
      })
    )
  );

  lobbyViewed$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LobbyActions.LobbyViewed),
      concatLatestFrom(() => [
        this.store.pipe(select(PackagesSelectors.getActivePackageGuid)),
        this.store.pipe(select(DeviceGroupSelectors.getDeviceCode)),
        this.store.pipe(select(PackageUsersSelectors.getActivePackageUserGuid)),
      ]),
      switchMap(([action, packageGuid, deviceCode, activePackageUserGuid]) => {
        return this.lobbyService
          .updateLobbyViewed(packageGuid, deviceCode, activePackageUserGuid)
          .pipe(
            switchMap(() => []),
            catchError((err) =>
              of(
                NotificationsActions.AddNotification({
                  payload: {
                    exception: err,
                    notificationType: NotificationType.Error,
                    id: uuid(),
                    text: 'Failed to start checkin for user',
                    exceptionType: ExceptionType.CannotProceed,
                  },
                })
              )
            )
          );
      })
    )
  );

  constructor(
    private readonly actions$: Actions,
    private readonly store: Store<RootStoreState.State>,
    private readonly lobbyService: LobbyService
  ) {}
}
