import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';

import { DocumentEndorsementLocation } from '../../models';

export function selectEndorsementLocationId(
  endorsementLocation: DocumentEndorsementLocation
): number {
  return endorsementLocation.documentEndorsementLocationId;
}

export const endorsementLocationsAdapter: EntityAdapter<DocumentEndorsementLocation> = createEntityAdapter<
  DocumentEndorsementLocation
>({
  selectId: selectEndorsementLocationId,
  sortComparer: false,
});
export interface EndorsementLocationsState
  extends EntityState<DocumentEndorsementLocation> {}

const {
  selectEntities,
  selectAll,
  selectIds,
  selectTotal,
} = endorsementLocationsAdapter.getSelectors();
export const selectEndorsementLocationEntities = selectEntities;
export const selectAllEndorsementLocations = selectAll;
export const selectEndorsementLocationIds = selectIds;
