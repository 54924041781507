import { createAction, props } from '@ngrx/store';

import { VideoArchiveIdsResponse, VideoSessionModel } from '../../models';

export const CreateVideoSession = createAction('[Video] Create Video Session');

export const CreateVideoSessionIfSigningAgent = createAction(
  '[Video] Create Video Session if SigningAgent'
);

export const CreateVideoSessionSuccess = createAction('[Video] Create Video Session Successful');

export const CreateVideoSessionFailed = createAction('[Video] Create Video Session Failed');

export const GetVideoSessionInfo = createAction('[Video] Get Video Session Info');

export const SetVideoSessionInfo = createAction(
  '[Video] Set Video Session Info',
  props<{ payload: VideoSessionModel }>()
);

export const ShowStartingRecordingModal = createAction('[Video] Show Starting Video Modal');

export const HideStartingRecordingModal = createAction('[Video] Hide Starting Video Modal');

export const ShowWaitingOnOtherParticipantsModal = createAction(
  '[Video] Show Waiting on Other Participants Modal'
);

export const HideWaitingOnOtherParticipantsModal = createAction(
  '[Video] Hide Waiting on Other Participants Modal'
);

export const StartArchive = createAction('[Video] Start Archive');

export const HandleStartArchiveFailed = createAction('[Video] Handle Start Archive Failed');

export const ShowRecordingFailedToStartModal = createAction(
  '[Video] Show Recording Failed to Start Modal'
);

export const StopArchive = createAction('[Video] Stop Archive');

export const StopVideo = createAction('[Video] Stop Video');

export const StopArchiveSuccess = createAction('[Video] Stop Archive Success');

export const SendVideoStreamConnected = createAction(
  '[Video] Send Video Stream Connected',
  props<{ payload: { videoStreamId: string } }>()
);

export const SendVideoStreamDestroyed = createAction(
  '[Video] Send Video Stream Destroyed',
  props<{ payload: { videoStreamId: string } }>()
);

export const SendVideoEnabled = createAction(
  '[Video] Send Video Enabled',
  props<{ payload: { videoStreamId: string } }>()
);

export const SetVideoConnectionIssue = createAction(
  '[Video] Set Video Connection Issue',
  props<{ payload: any }>()
);

export const SetVideoVisibilityIssue = createAction(
  '[Video] Set Video Connection Issue',
  props<{ payload: any }>()
);

export const SendVideoConnectionIssueSuccessful = createAction(
  '[Video] Send Video Connection Issue Succesful'
);

export const SendVideoVisibilityIssueSuccessful = createAction(
  '[Video] Send Video Connection Issue Succesful'
);

export const GetAreAllVideoStreamsConnected = createAction(
  '[Video] Get Are All Video Streams Connected'
);

export const SetAreAllVideoStreamsConnected = createAction(
  '[Video] Set Are All Video Streams Connected',
  props<{ payload: boolean }>()
);

export const SetIgnoreParticipantVideoStream = createAction(
  '[Video] Set Ignore Participant Video Stream',
  props<{ payload: boolean }>()
);

export const SetDisableAdditionalArchive = createAction('[Video] Set Disable Additional Archive');

export const ReceivedVideoStreamDisconnected = createAction(
  '[Video] Received Video Stream Disconnected'
);

export const ReceivedVideoStreamConnected = createAction('[Video] Received Video Stream Connected');

export const ReceivedVideoStreamEnabled = createAction('[Video] Received Video Stream Enabled');

export const FetchDownloadVideoArchiveIds = createAction(
  '[Video] Fetch Download Video Archive Ids'
);

export const FetchDownloadVideoArchiveIdsSuccessful = createAction(
  '[Video] Fetch Download Video Links Successful',
  props<{ payload: VideoArchiveIdsResponse }>()
);

export const FetchVideo = createAction(
  '[Video] Fetch Video',
  props<{ payload: { videoArchiveId: number } }>()
);

export const SetIsVideoDownloading = createAction(
  '[Video] Set Is Video Downloading',
  props<{ payload: { isDownloading: boolean; videoArchiveId: number } }>()
);

export const AuditDownloadVideo = createAction(
  '[Video] Audit Download Video',
  props<{ payload: { VideoArchiveId: number; VideoUri: string } }>()
);

export const AuditDownloadVideoSuccessful = createAction('[Video] Audit Download Video Successful');

export const GetVideoArchiveStatus = createAction('[Video] Get Video Archive Status');

export const GetVideoArchiveStatusSuccessful = createAction(
  '[Video] Get Video Archive Status Successful',
  props<{ payload: { status: boolean } }>()
);

export const GetMultiVideoStorageLocatorUrls = createAction(
  '[Video] Get Multi Video Storage Locator Urls'
);

export const SetMultiVideoStorageLocatorUrls = createAction(
  '[Video] Set Multi Video Storage Locator Urls',
  props<{
    payload: string[];
  }>()
);
