import { CommonModule } from '@angular/common';
import { Component, computed, effect, inject, OnDestroy, OnInit, Signal } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatCardModule } from '@angular/material/card';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSelectChange, MatSelectModule } from '@angular/material/select';

import { take, tap } from 'rxjs';

import { NavigatorMediaDeviceWrapperService } from 'src/app/features/audio-video-connection-monitor/services';
import { AudioVideoCheckService } from 'src/app/features/av-check/services/audio-video-check.service';
import { MicCheckService } from 'src/app/features/av-check/services/mic-check.service';
import { SpeakerCheckService } from 'src/app/features/av-check/services/speaker-check.service';
import { AvCheckGuideComponent } from 'src/app/features/av-check/shared/av-check-guide/av-check-guide.component';
import { GadgetGuideResponseComponent } from 'src/app/features/gadget-guide/components/gadget-guide-response/gadget-guide-response.component';
import { GadgetGuideService } from 'src/app/features/gadget-guide/services/gadget-guide.service';
import { GadgetGuide, GadgetGuideResponse, GadgetType } from 'src/app/features/gadget-guide/services/gadget-guide.types';
import { SharedModule } from 'src/app/features/shared';

@Component({
  selector: 'app-audio-select',
  standalone: true,
  imports: [
    AvCheckGuideComponent,
    CommonModule,
    FormsModule,
    MatCardModule,
    MatExpansionModule,
    MatIconModule,
    MatProgressBarModule,
    MatSelectModule,
    SharedModule,
    GadgetGuideResponseComponent
  ],
  providers: [GadgetGuideService],
  templateUrl: './audio-select.component.html',
  styleUrl: './audio-select.component.scss',
})
export class AudioSelectComponent implements OnInit, OnDestroy {
  audioVideoCheckService = inject(AudioVideoCheckService);
  gadgetGuideService = inject(GadgetGuideService);
  micCheckService = inject(MicCheckService);
  navigatorMediaDeviceWrapperService = inject(NavigatorMediaDeviceWrapperService);
  speakerCheckService = inject(SpeakerCheckService);

  showMicrophoneGuideDetails = false;
  showSpeakerGuideDetails = false;

  gadgetGuideServiceSpeakerResponse: Signal<GadgetGuideResponse> = computed(() => this.gadgetGuideService.getMarkupForGadgetGuide(
    this.gadgetGuideService.gadgetGuides().gadgetGuide,
    this.gadgetGuideService.speakerKey(),
    'response'
  ));

  gadgetGuideServiceMicrophoneResponse: Signal<GadgetGuideResponse> = computed(() => this.gadgetGuideService.getMarkupForGadgetGuide(
    this.gadgetGuideService.gadgetGuides().gadgetGuide,
    this.gadgetGuideService.microphoneKey(),
    'response'
  ));

  hasGadgetGuideForMicrophoneLoadingFailure = computed(() =>
    this.gadgetGuideService
      .getGadgetGuideByMicrophoneKey()()
      ?.status?.match(/^error:/)
  );
  isGadgetGuideForMicrophoneLoading = computed(() => {
    const status = this.gadgetGuideService.getGadgetGuideByMicrophoneKey()()?.status;

    return status === 'init' || status !== 'success';
  });

  hasGadgetGuideForSpeakerLoadingFailure = computed(() =>
    this.gadgetGuideService
      .getGadgetGuideBySpeakerKey()()
      ?.status?.match(/^error:/)
  );
  isGadgetGuideForSpeakerLoading = computed(() => {
    const status = this.gadgetGuideService.getGadgetGuideBySpeakerKey()()?.status;

    return status === 'init' || status !== 'success';
  });

  constructor() {
    effect(() => {
      if (
        !this.audioVideoCheckService.hasUserMediaAudioFailure() &&
        !!this.micCheckService.selectAudioInputDevices().length &&
        this.micCheckService.currentMicDevice()
      ) {
        try {
          this.navigatorMediaDeviceWrapperService
            .getUserMediaPermission(GadgetType.Microphone.toLowerCase())
            .pipe(
              take(1),
              tap((status: PermissionStatus) => {
                if (status.state === 'granted') {
                  this.micCheckService.startTracking(this.micCheckService.currentMicDevice());
                }
              })
            )
            .subscribe();
        } catch (e: unknown) {}
      }
    });
  }

  handleMicrophoneSelectionChange(event: MatSelectChange): void {
    const selectedLabel = event.value;
    const selectedDevice = this.micCheckService
      .selectAudioInputDevices()
      .find((device) => device.label === selectedLabel);
    if (selectedDevice) {
      this.micCheckService.selectMicrophone(selectedDevice);
    } else {
      this.micCheckService.clearMicrophone();
    }
  }

  micFailure: Signal<boolean> = computed(
    () =>
      this.audioVideoCheckService.hasUserMediaAudioFailure() ||
      this.micCheckService.micFailure() ||
      !this.micCheckService.selectAudioInputDevices().length
  );

  shouldDisableSpeakerTestButton: Signal<boolean> = computed(
    () => this.speakerCheckService.forceDisableTestSpeakerButton() || this.speakerFailure()
  );

  speakerFailure: Signal<boolean> = computed(() =>
    this.speakerCheckService.hasSpeakerFeature()
      ? !this.speakerCheckService.selectAudioOutputDevice() ||
        this.audioVideoCheckService.hasUserMediaAudioFailure() ||
        this.speakerCheckService.selectAudioOutputDevice().deviceId === '' ||
        !this.speakerCheckService.selectAudioOutputDevices().length
      : false
  );

  async ngOnInit(): Promise<void> {
    const gadgetGuideForMicrophone: GadgetGuide =
      await this.gadgetGuideService.initGadgetGuideRequest({ gadgetType: GadgetType.Microphone });

    const [key] = Object.keys(gadgetGuideForMicrophone);
    this.gadgetGuideService.microphoneKey.set(key);

    if (this.speakerCheckService.hasSpeakerFeature()) {
      const gadgetGuideForSpeaker: GadgetGuide =
        await this.gadgetGuideService.initGadgetGuideRequest({ gadgetType: GadgetType.Speaker });

      const [key] = Object.keys(gadgetGuideForSpeaker);
      this.gadgetGuideService.speakerKey.set(key);
    }
  }

  ngOnDestroy(): void {
    globalThis.cancelAnimationFrame(this.speakerCheckService.audioDetectionAnimationFrame);
  }
}
