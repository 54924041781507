import { HttpClient, HttpErrorResponse, HttpEvent, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import { Observable, throwError } from 'rxjs';
import { catchError, shareReplay } from 'rxjs/operators';

import { SignalRService } from 'src/app/features/signal-r';
import { delayedRetry } from 'src/app/operators';
import { RootStoreState } from 'src/app/store';

import { SignableDocuments } from '../models';
import { DocumentsActions } from '../store/actions';

@Injectable()
export class DocumentsService {
  private listenerConfigured = false;

  constructor(
    private readonly httpClient: HttpClient,
    private readonly signalRService: SignalRService,
    private readonly store: Store<RootStoreState.State>
  ) {}

  getPreSignDocuments(packageUserGuid: string): Observable<HttpEvent<{}>> {
    const req = new HttpRequest('GET', `docs/presign/packageusers/${packageUserGuid}`, {
      reportProgress: true,
    });

    if (!packageUserGuid || packageUserGuid.length === 0) {
      return throwError('Missing packageUserGuid');
    }

    return this.httpClient.request<SignableDocuments>(req).pipe(
      delayedRetry(2000),
      catchError((error: HttpErrorResponse) => throwError(error)),
      shareReplay()
    );
  }

  getPreSignSmartDocuments(packageUserGuid: string): Observable<SignableDocuments> {
    const url = `docs/presign/smartdocs/packageUsers/${packageUserGuid}`;

    if (!packageUserGuid || packageUserGuid.length === 0) {
      return throwError('Missing packageUserGuid');
    }

    return this.httpClient.get<SignableDocuments>(url).pipe(
      delayedRetry(2000),
      catchError((error: HttpErrorResponse) => throwError(error))
    );
  }

  getSigningSessionDocuments(
    packageUserGuid: string,
    deviceCode: string
  ): Observable<HttpEvent<{}>> {
    const req = new HttpRequest(
      'GET',
      `docs/signing/packageusers/${packageUserGuid}/${deviceCode}`,
      {
        reportProgress: true,
      }
    );

    return this.httpClient.request<SignableDocuments>(req).pipe(
      delayedRetry(2000),
      catchError((error: HttpErrorResponse) => throwError(error)),
      shareReplay()
    );
  }

  getSigningSessionSmartDocuments(packageUserGuid: string): Observable<SignableDocuments> {
    return this.httpClient
      .get<SignableDocuments>(`docs/signing/smartdocs/packageusers/${packageUserGuid}`)
      .pipe(
        delayedRetry(2000),
        catchError((error: HttpErrorResponse) => throwError(error))
      );
  }

  getAllSessionsDocumentsLoaded(packageUserGuid: string): Observable<boolean> {
    return this.httpClient
      .get<boolean>(`docs/signing/loaded/allSessions/packageUsers/${packageUserGuid}`)
      .pipe(
        delayedRetry(2000),
        catchError((error: HttpErrorResponse) => throwError(error))
      );
  }

  postDocumentsLoaded(packageUserGuid: string, deviceCode: string) {
    return this.httpClient.post(`docs/loaded/packageUsers/${packageUserGuid}`, {
      deviceCode,
    });
  }

  postDocumentViewed(packageUserGuid: string, deviceCode: string, documentId: number) {
    return this.httpClient.post('docs/viewed', {
      packageUserGuid,
      deviceCode,
      documentId,
    });
  }

  postDocumentNavigation(packageUserGuid: string, documentId: number, isPreSign: boolean) {
    return this.httpClient.post('docs/navigation', {
      packageUserGuid,
      documentId,
      isPreSign,
    });
  }

  verifyActiveSessionDocument() {
    return this.httpClient.get<number>(`docs/signing/active`);
  }

  downloadExecutedClosingPackage(packageGuid: string) {
    return this.httpClient.get(`packages/${packageGuid}/executedClosingPackage`, {
      responseType: 'blob',
    });
  }

  public configureListeners() {
    if (!this.listenerConfigured) {
      this.listenerConfigured = true;
      this.signalRService.hubConnection.on('ReceiveDocumentToView', (Data) => {
        this.store.dispatch(DocumentsActions.SetActiveDocument({ payload: Data.documentId }));
      });

      this.signalRService.hubConnection.on('ReceiveParticipantLoadingDocuments', () => {
        this.store.dispatch(
          DocumentsActions.SetAreAllSessionDocumentsLoaded({
            payload: false,
          })
        );
      });

      this.signalRService.hubConnection.on('ReceiveParticipantDocumentsLoaded', () => {
        this.store.dispatch(DocumentsActions.GetAreAllSessionsDocumentsLoaded());
      });
    }
  }
}
