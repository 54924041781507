/**
 * Enum containing transactionStatuses returned by LexisNexis.
 */
 export enum KBATransactionStatus {
  Passed = 'passed',
  Pending = 'pending',
  Failed1stAttempt = 'failed1stAttempt',
  Failed = 'failed',
  Expired1stAttempt = 'expired1stAttempt',
  Expired = 'expired',
  Error = 'error',
  Pause = 'pause'
}

 export enum KBATransactionReasonCode {
  Expired = 'transaction_expired',
  ExhaustedAttempts = 'failed_iauth_questions',
  IndividualNotFound = 'individual_not_found'
}
