import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { filter, take, tap } from 'rxjs/operators';

import { MultiFactorSelectors } from 'src/app/features/multi-factor/store/selectors';
import { PackageUser, PackageUsersSelectors } from 'src/app/features/package-users';
import { AbstractWizardStepComponent, CheckInTaskCode, WizardStep } from 'src/app/features/wizard';
import { RootStoreState } from 'src/app/store';
import { MultiFactorActions } from 'src/app/features/multi-factor/store/actions';

import { SignerDetailsSelectors } from '../../store/selectors';

@Component({
  selector: 'app-verify-signer-details',
  templateUrl: './verify-signer-details.component.html',
  styleUrls: ['./verify-signer-details.component.scss'],
  providers: [
    {
      provide: AbstractWizardStepComponent,
      useExisting: VerifySignerDetailsComponent,
    },
  ],
})
export class VerifySignerDetailsComponent
  extends AbstractWizardStepComponent
  implements OnDestroy, OnInit
{
  @Input() user: PackageUser;
  latestPackegeUser$: Observable<PackageUser>;
  multiFactorInProgress$: Observable<boolean>;
  multiFactorChallengeCompleteSubscription: Subscription;

  constructor(private readonly store: Store<RootStoreState.State>) {
    super(store);

    this.stepMetadata = {
      stepName: 'Verify Signer Details',
      checkInTaskCode: CheckInTaskCode.SignerDetailsVerification,
    } as WizardStep;

    this.formStep = new UntypedFormGroup({});
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.onStepHidden();
  }

  public onStepVisible(): void {
    this.latestPackegeUser$ = this.store.select(
      PackageUsersSelectors.getPackageUser(this.user.packageUserGuid)
    );
    this.multiFactorInProgress$ = this.store.select(MultiFactorSelectors.isChallengeInProgress);

    this.multiFactorChallengeCompleteSubscription = this.store
      .select(SignerDetailsSelectors.isMultifactorChallengeCompleted)
      .pipe(
        filter((isComplete) => !!isComplete),
        take(1),
        tap((_) => super.goToNextStep())
      )
      .subscribe();
  }

  public onStepHidden(): void {
    this.store.dispatch(MultiFactorActions.CancelMultiFactorChallenge());
    this.multiFactorChallengeCompleteSubscription?.unsubscribe();
  }
}
