import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';

import { ModalsActions } from 'src/app/features/modals';
import { PackageUser } from 'src/app/features/package-users';
import {
  CheckInTask,
  CheckInTaskCode,
  WizardActions,
  WizardSelectors,
} from 'src/app/features/wizard';
import { RootStoreState } from 'src/app/store';
import { CheckInActions } from '../../store';

@Component({
  selector: 'app-check-in-wizard',
  templateUrl: './check-in-wizard.component.html',
  styleUrls: ['./check-in-wizard.component.scss'],
})
export class CheckInWizardComponent implements OnInit, OnDestroy {
  @Output() wizardCompleted: EventEmitter<any> = new EventEmitter();

  public isWizardCompleted = false;
  public user$: Observable<PackageUser> | null = null;
  public userDisplayName$: Observable<string> | null = null;
  public isKbaQuestionsStep$: Observable<boolean>;

  isCompleteSubscription: Subscription;
  initialTasks: CheckInTask[];

  constructor(
    private readonly route: ActivatedRoute,
    private readonly store: Store<RootStoreState.State>
  ) {}

  ngOnInit(): void {
    this.initialTasks = this.route.snapshot.data.tasks;

    this.isCompleteSubscription = this.store
      .pipe(
        select(WizardSelectors.getAreAllTasksComplete),
        filter((isComplete) => !!isComplete && !this.isWizardCompleted),
        tap(() => {
          this.isWizardCompleted = true;
          this.store.dispatch(CheckInActions.CompleteCheckInWizard());
          this.wizardCompleted.emit();
        })
      )
      .subscribe();

    this.isKbaQuestionsStep$ = this.store.pipe(
      select(WizardSelectors.getCurrentTask),
      map((task) => task?.taskType === CheckInTaskCode.SecurityQuestions)
    );

    this.user$ = this.store.pipe(select(WizardSelectors.getActiveWizardUser));

    this.userDisplayName$ = this.user$.pipe(
      map((p) => (p.firstName && p.lastName ? `${p.firstName} ${p.lastName.substring(0, 1)}.` : ''))
    );

    this.store.dispatch(ModalsActions.EnableGlobalSpinner());
  }

  ngOnDestroy(): void {
    this.isCompleteSubscription?.unsubscribe();
    this.store.dispatch(WizardActions.ResetTasks());
  }

  hasTask(code: string): boolean {
    return this.initialTasks.some((t) => t.taskType === code && !t.isCompleted);
  }
}
