import { Component } from '@angular/core';
import { Store } from '@ngrx/store';

import { ModalsActions } from 'src/app/features/modals';
import { PackageUsersActions } from 'src/app/features/package-users';
import { SigningRoomActions } from 'src/app/features/signing-room/store';
import { RootStoreState } from 'src/app/store';

@Component({
  selector: 'app-exit-signing-confirmation',
  templateUrl: './exit-signing-confirmation.component.html',
  styleUrls: ['./exit-signing-confirmation.component.scss'],
})
export class ExitSigningConfirmationComponent {

  constructor(private readonly store: Store<RootStoreState.State>) {}

  continueSigning() {
    this.store.dispatch(ModalsActions.ClearModalComponent());
  }

  exitSigning() {
    this.store.dispatch(ModalsActions.ClearModalComponent());
    this.store.dispatch(SigningRoomActions.ExitSigningRoom());
    this.store.dispatch(PackageUsersActions.ExitPackageUserPreSignSession());
  }
}
