export interface State {
  selectedAudioDevice: MediaDeviceInfo;
  selectedVideoDevice: MediaDeviceInfo;
  isOnAudioVideoConfigurationPage: boolean;
  selectedAudioOutputDevice: MediaDeviceInfo;
}

export const initialState: State = {
  selectedAudioDevice: null,
  selectedVideoDevice: null,
  isOnAudioVideoConfigurationPage: false,
  selectedAudioOutputDevice: null
};
