import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Store } from '@ngrx/store';

import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { MsalAuthActions, MsalAuthSelectors } from 'src/app/features/msal-auth';
import { SIGNING_REDIRECT_STATE } from 'src/app/features/shared/constants';

import { RootStoreState } from '../store';

@Injectable({
  providedIn: 'root',
})
export class FlowSelectionGuard {
  constructor(
    private readonly router: Router,
    private readonly store: Store<RootStoreState.State>
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<UrlTree | boolean> {
    return combineLatest([
      this.store.select(MsalAuthSelectors.getIsLoading),
      this.store.select(MsalAuthSelectors.getUser),
      this.store.select(MsalAuthSelectors.getRedirectState),
    ]).pipe(
      map(([isAuthInProgress, user, redirectState]) => {
        if (!isAuthInProgress && !user) {
          // if there is no logged-in user, and no auth is in progress, redirect to login
          this.store.dispatch(MsalAuthActions.Login({ payload: { route: route.routeConfig } }));
          return false;
        }

        if (isAuthInProgress) {
          // this path gets hit on the way to the MSAL redirect URL; in that case, do nothing
          return true;
        }

        // if the user has manipulated the URL, send them back to where they should be
        return redirectState === SIGNING_REDIRECT_STATE
          ? this.router.createUrlTree(['signing-landing'])
          : this.router.createUrlTree(['consumer-portal']);
      })
    );
  }
}
