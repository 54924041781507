import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { DeviceDetectorService } from 'ngx-device-detector';
import { BehaviorSubject, interval, Observable, Subject, Subscription, throwError } from 'rxjs';
import {
  catchError,
  distinctUntilChanged,
  take,
  takeUntil,
  tap,
  withLatestFrom,
} from 'rxjs/operators';

import { NavigatorMediaDeviceWrapperService } from 'src/app/features/audio-video-connection-monitor/services';
import {
  AudioVideoConnectionMonitorActions,
  AudioVideoConnectionMonitorSelectors,
} from 'src/app/features/audio-video-connection-monitor/store';
import { AudioVideoBlockedErrorModalComponent } from 'src/app/features/av-check/components';
import { AudioVideoCheckActions } from 'src/app/features/av-check/store';
import { ModalsActions } from 'src/app/features/modals';
import { PackagesSelectors } from 'src/app/features/packages';
import { Devices, SoundMeter } from 'src/app/features/ron/models';
import { RootStoreState } from 'src/app/store';

@Component({
  selector: 'app-audio-video-modal',
  templateUrl: './audio-video-modal.component.html',
  styleUrls: ['./audio-video-modal.component.scss'],
})
export class AudioVideoModalComponent implements OnInit, OnDestroy {
  static identifier = 'AudioVideoModalComponent';
  @Input() shouldRefreshAfterSettingApplication = false;

  submitted = false;

  avCheckForm: UntypedFormGroup;
  deviceForm: UntypedFormGroup;

  devicesAreLoaded = false;

  audioContext: any;
  audioStream: any;
  public availableAudioVideoDevices: Devices;
  public isAudioInputDeviceDetected: boolean;
  public isVideoInputDeviceDetected: boolean;
  meterRefreshInterval: Subscription;
  meterValue = new BehaviorSubject<number>(0);
  selectedCamera: MediaDeviceInfo;
  selectedMicrophone: MediaDeviceInfo;
  soundMeter: any;
  @ViewChild('video') video;

  userSelectedCamera: MediaDeviceInfo;
  userSelectedMicrophone: MediaDeviceInfo;
  packageGuid$: Observable<string>;
  isOnAudioVideoConfigurationPage$: Observable<boolean>;

  private readonly destroySubject: Subject<boolean> = new Subject<boolean>();
  isAtBottom = false;

  constructor(
    private readonly deviceDetectorService: DeviceDetectorService,
    private readonly store: Store<RootStoreState.State>,
    private readonly navigatorMediaDeviceWrapperService: NavigatorMediaDeviceWrapperService
  ) {}

  ngOnDestroy(): void {
    this.destroySubject.next(undefined);
    this.destroySubject.complete();
  }

  ngOnInit() {
    if (this.shouldRefreshOnIosDevice()) {
      this.shouldRefreshAfterSettingApplication = true;
    }

    this.isVideoInputDeviceDetected = true;
    this.isAudioInputDeviceDetected = true;
    this.availableAudioVideoDevices = new Devices();

    this.store.dispatch(AudioVideoCheckActions.AudioVideoCheckStarted());
    this.store.dispatch(ModalsActions.ShowLoadingSpinner());
    this.packageGuid$ = this.store.select(PackagesSelectors.getActivePackageGuid);
    this.store
      .pipe(
        takeUntil(this.destroySubject),
        select(AudioVideoConnectionMonitorSelectors.getSelectedVideoDevice),
        tap((cameraDeviceInfo: MediaDeviceInfo) => {
          this.userSelectedCamera = cameraDeviceInfo;
        })
      )
      .subscribe();
    this.store
      .pipe(
        takeUntil(this.destroySubject),
        select(AudioVideoConnectionMonitorSelectors.getSelectedAudioDevice),
        tap((audioDeviceInfo: MediaDeviceInfo) => {
          this.userSelectedMicrophone = audioDeviceInfo;
        })
      )
      .subscribe();

    this.initForms();
    this.populateAudioVideoDevices();
  }

  initForms() {
    this.avCheckForm = new UntypedFormGroup({
      canSeeVideo: new UntypedFormControl(null, [Validators.requiredTrue]),
      canHearAudio: new UntypedFormControl(null, [Validators.requiredTrue]),
    });

    this.deviceForm = new UntypedFormGroup({
      audio: new UntypedFormControl(null),
      video: new UntypedFormControl(null),
    });
  }

  shouldRefreshOnIosDevice() {
    if (
      !this.deviceDetectorService ||
      this.deviceDetectorService.os.toLowerCase() === 'ios' ||
      ((this.deviceDetectorService.isTablet() || this.deviceDetectorService.isMobile()) &&
        this.deviceDetectorService.os.toLowerCase() === 'mac')
    ) {
      return true;
    } else {
      return false;
    }
  }

  populateAudioVideoDevices() {
    const mediaConstraints = {
      audio: true,
      video: true,
    };

    this.navigatorMediaDeviceWrapperService
      .getUserMedia(mediaConstraints)
      .pipe(
        tap(() => {
          this.getAllDevices();
        }),
        catchError((res) => {
          this.store.dispatch(ModalsActions.HideLoadingSpinner());
          if (res) {
            this.store.dispatch(
              ModalsActions.SetStandaloneModalComponent({
                payload: {
                  component: AudioVideoBlockedErrorModalComponent,
                  componentData: {},
                },
              })
            );
          }
          return throwError(res);
        }),
        take(1)
      )
      .subscribe();
  }

  getAllDevices(): void {
    this.navigatorMediaDeviceWrapperService
      .enumerateDevices()
      .pipe(
        tap((devices) => this.fetchVideoAudioDevices(devices)),
        takeUntil(this.destroySubject)
      )
      .subscribe();
  }

  fetchVideoAudioDevices(devices: MediaDeviceInfo[]): void {
    const selectAudioSources: MediaDeviceInfo[] = [];
    const selectVideoSources: MediaDeviceInfo[] = [];

    const audioSources = devices.filter((device) => device.kind === 'audioinput');

    audioSources.forEach((deviceInfo) => {
      selectAudioSources.push(deviceInfo);
    });

    this.availableAudioVideoDevices.audio = selectAudioSources;

    if (
      this.availableAudioVideoDevices?.audio &&
      this.availableAudioVideoDevices?.audio.length > 0
    ) {
      const defaultAudioDevice = this.availableAudioVideoDevices.audio[0];
      this.selectedMicrophone = defaultAudioDevice;
      this.selectDefaultMicrophoneDevice();
      this.updateSelectedAudio();
    } else {
      this.selectDefaultMicrophoneDevice();
    }

    const videoSources = devices.filter((device) => {
      return device.kind === 'videoinput';
    });

    videoSources.forEach((deviceInfo) => {
      selectVideoSources.push(deviceInfo);
    });

    this.availableAudioVideoDevices.video = selectVideoSources;

    if (
      this.availableAudioVideoDevices?.video !== null &&
      this.availableAudioVideoDevices?.video.length > 0
    ) {
      const defaultVideoDevice = this.availableAudioVideoDevices.video[0];
      this.selectedCamera = defaultVideoDevice;
      this.selectDefaultCameraDevice();
      this.updateSelectedVideo();
    } else {
      this.selectDefaultCameraDevice();
    }
  }

  updateSelectedAudio(): void {
    const currentMicrophoneDeviceId = this.deviceForm.get('audio')?.value;

    if (currentMicrophoneDeviceId) {
      this.selectedMicrophone = this.availableAudioVideoDevices.audio.find(
        (d) => d.deviceId === currentMicrophoneDeviceId
      );
    }

    this.setAudioStream();
  }

  setAudioStream(): void {
    if (this.meterRefreshInterval) {
      this.stopTrackingAudio();
    }

    this.setIntervalForAudioUpdates();
  }

  updateSelectedVideo(): void {
    const selectedCameraDeviceId = this.deviceForm.get('video')?.value;
    if (selectedCameraDeviceId) {
      this.selectedCamera = this.availableAudioVideoDevices.video.find(
        (d) => d.deviceId === selectedCameraDeviceId
      );
    }

    const mediaConstraints = {
      video: { deviceId: this.selectedCamera.deviceId },
    };
    this.navigatorMediaDeviceWrapperService
      .getUserMedia(mediaConstraints)
      .pipe(takeUntil(this.destroySubject))
      .subscribe(
        (data) => {
          this.setVideoStream(data);
        },
        (error) => {
          this.handleVideoRetrievalFailure();
        }
      );
  }

  setVideoStream(stream: MediaStream): void {
    this.setVideoControls(stream);
  }

  setVideoControls(stream: MediaStream): void {
    const video: HTMLVideoElement = this.video?.nativeElement;
    if (video) {
      video.muted = true;
      video.playsInline = true;
      video.controls = false;
      video.autoplay = true;
      video.srcObject = stream;
      video.width = 180;
    }
  }

  handleVideoRetrievalFailure(): void {
    alert('Uh Oh, failed to fetch video');
  }

  stopTrackingAudio() {
    this.meterRefreshInterval.unsubscribe();
    this.audioStream.getTracks().forEach((track) => track.stop());
    this.soundMeter.stop();
    this.meterValue.next(0);
  }

  setIntervalForAudioUpdates() {
    try {
      const isAudioContextStandardsCompliant =
        window.AudioContext !== undefined || window.AudioContext !== null;
      if (isAudioContextStandardsCompliant) {
        // Sets the standards compliant version of AudioContext
        this.audioContext = new AudioContext();
      } else {
        // Sets the old webkit version of AudioContext and repoints createScriptProcessor to the old 'createJavaScriptNode' name
        this.audioContext = (window as any).webkitAudioContext;
        if (!this.audioContext.prototype.hasOwnProperty('createScriptProcessor')) {
          this.audioContext.prototype.createScriptProcessor =
            this.audioContext.prototype.createJavaScriptNode;
        }
      }
    } catch (e) {
      alert('Web Audio API not supported.');
    }

    const mediaConstraints = {
      audio: { deviceId: this.selectedMicrophone.deviceId },
    };

    this.navigatorMediaDeviceWrapperService
      .getUserMedia(mediaConstraints)
      .pipe(
        tap((data) => this.handleAudioFetchSuccess(data)),
        takeUntil(this.destroySubject),
        catchError((error) => {
          throw Error(error);
        })
      )
      .subscribe();
  }

  handleAudioFetchSuccess(stream) {
    this.audioStream = stream;
    this.soundMeter = new SoundMeter(this.audioContext);
    this.soundMeter.connectToSource(stream, (e) => {
      if (e) {
        alert(e);
      }
    });

    this.meterRefreshInterval = interval(200)
      .pipe(takeUntil(this.destroySubject))
      .subscribe((n) => {
        this.meterValue.next(this.soundMeter.instant.toFixed(2));
      });
  }

  selectDefaultCameraDevice() {
    if (this.userSelectedCamera != null) {
      for (const videoDevice of this.availableAudioVideoDevices.audio) {
        if (videoDevice.deviceId === this.userSelectedCamera.deviceId) {
          this.deviceForm.patchValue({
            video: this.userSelectedCamera.deviceId,
          });
          this.devicesAreLoaded = true;
          this.store.dispatch(ModalsActions.HideLoadingSpinner());
          return;
        }
      }
    }
    this.deviceForm.patchValue({
      video: this.availableAudioVideoDevices.video[0].deviceId,
    });
    this.devicesAreLoaded = true;
    this.store.dispatch(ModalsActions.HideLoadingSpinner());
  }

  selectDefaultMicrophoneDevice() {
    if (this.userSelectedMicrophone != null) {
      for (const audioDevice of this.availableAudioVideoDevices.audio) {
        if (audioDevice.deviceId === this.userSelectedMicrophone.deviceId) {
          this.deviceForm.patchValue({
            audio: this.userSelectedMicrophone.deviceId,
          });
          this.devicesAreLoaded = true;
          this.store.dispatch(ModalsActions.HideLoadingSpinner());
          return;
        }
      }
    }
    this.deviceForm.patchValue({
      audio: this.availableAudioVideoDevices.audio[0].deviceId,
    });
    this.devicesAreLoaded = true;
    this.store.dispatch(ModalsActions.HideLoadingSpinner());
  }

  toggleFormControl(controlName: 'canSeeVideo' | 'canHearAudio') {
    const control = this.avCheckForm.get(controlName);
    if (control?.value === true) {
      control.setValue(false);
    } else {
      control.setValue(true);
    }
  }

  cancelAudioVideoSelections() {
    if (this.shouldRefreshAfterSettingApplication) {
      this.refreshApplicationPage();
    }
    this.store.dispatch(ModalsActions.ClearModalComponent());
  }

  // Conditionally listens for change if requested to refresh after using devices settings modal
  refreshApplicationPage() {
    this.store
      .pipe(
        takeUntil(this.destroySubject),
        distinctUntilChanged(),
        withLatestFrom(
          this.store.select(AudioVideoConnectionMonitorSelectors.getSelectedVideoDevice),
          this.store.select(AudioVideoConnectionMonitorSelectors.getSelectedVideoDevice)
        ),
        tap(() => {
          if (this.shouldRefreshAfterSettingApplication) {
            window.location.reload();
          }
        })
      )
      .subscribe();
  }

  applyAudioVideoSelections() {
    if (this.shouldRefreshAfterSettingApplication) {
      this.refreshApplicationPage();
    }
    this.store.dispatch(ModalsActions.ClearModalComponent());
    this.store.dispatch(
      AudioVideoConnectionMonitorActions.SetSelectedAudioVideoDevices({
        payload: {
          selectedAudioDevice: this.selectedMicrophone,
          selectedVideoDevice: this.selectedCamera,
          shouldRefreshAfterSettingApplication: this.shouldRefreshAfterSettingApplication,
        },
      })
    );
  }

  scrollDown(): void {
    const scrollRef = document.querySelector('.av_body');
    scrollRef.scroll({
      top: scrollRef.scrollHeight,
      left: 0,
      behavior: 'smooth',
    });
  }

  onScroll(event) {
    if (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight - 1) {
      this.isAtBottom = true;
    } else {
      this.isAtBottom = false;
    }
  }
}
