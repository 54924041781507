import { DOCUMENT } from '@angular/common';
import { Component, HostListener, Inject, OnDestroy, OnInit } from '@angular/core';
import {
  ActivatedRoute,
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router,
} from '@angular/router';
import { MsalBroadcastService } from '@azure/msal-angular';
import { select, Store } from '@ngrx/store';

import { combineLatest, Subject } from 'rxjs';
import { filter, map, mergeMap, take, takeUntil, tap } from 'rxjs/operators';

import { CLIENT_THEME, ClientSelectors, ClientTheme } from 'src/app/features/client';
import { DeviceGroupActions } from 'src/app/features/device-group';
import { EndorsementsActions } from 'src/app/features/endorsements';
import { LobbyActions } from 'src/app/features/lobby';
import { ModalsActions } from 'src/app/features/modals';
import { filterAuthError, MsalAuthActions, MsalAuthService } from 'src/app/features/msal-auth';
import { PackagesSelectors, ProductType } from 'src/app/features/packages';
import { PendoLocationService, PendoService } from 'src/app/features/pendo-adapter';
import { ApplicationInsightsService } from 'src/app/services/application-insights.service';
import { LogRocketService } from 'src/app/services/log-rocket/log-rocket.service';
import { RootStoreState } from 'src/app/store';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  template: '<router-outlet></router-outlet><app-loading-spinner></app-loading-spinner>',
})
export class AppComponent implements OnInit, OnDestroy {
  private readonly destroying$ = new Subject();

  get _destroying$() {
    return this.destroying$;
  }

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly applicationInsightsService: ApplicationInsightsService,
    private readonly logRocketService: LogRocketService,
    private readonly msalAuthService: MsalAuthService,
    private readonly msalBroadcastService: MsalBroadcastService,
    private readonly pendoService: PendoService,
    private readonly router: Router,
    private readonly store: Store<RootStoreState.State>,
    @Inject(DOCUMENT) private readonly _document: Document
  ) {}

  ngOnInit() {
    this.dispatchActionsToSetUpStoreForLandingPage();
    this.setClientHintsForStore();
    this.configureMsal();
    this.configureClientTheme();
    this.configureLogRocket();
    this.configurePendo();
    this.configureRouterEvents();
  }

  ngOnDestroy() {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }

  dispatchActionsToSetUpStoreForLandingPage(): void {
    combineLatest([
      this.store.select(PackagesSelectors.getActivePackageGuid),
      this.store.select(PackagesSelectors.getProductType),
    ])
      .pipe(
        filter(
          ([activePackageGuid, productType]) =>
            !!activePackageGuid &&
            (productType === ProductType.IPEN || productType === ProductType.Hybrid)
        ),
        take(1),
        tap(() => {
          this.store.dispatch(LobbyActions.FetchLobbyDetails());
          this.store.dispatch(EndorsementsActions.FetchSystemFieldsStatus());
        })
      )
      .subscribe();
  }

  setClientHintsForStore(): void {
    if (navigator.userAgentData) {
      navigator.userAgentData
        .getHighEntropyValues([
          'platform',
          'platformVersion',
          'architecture',
          'model',
          'uaFullVersion',
        ])
        .then((clientHints) => {
          this.store.dispatch(
            DeviceGroupActions.SetClientHintsForDevice({
              clientHints,
            })
          );
        });
    }
  }

  configureClientTheme(): void {
    const styleElement = this.createStyleElement();
    this.setClientTheme(CLIENT_THEME, styleElement);

    // If the Theme changes, overwrite the CSS variables in the <style> created above
    this.store
      .pipe(
        takeUntil(this._destroying$),
        select(ClientSelectors.getClientTheme),
        tap((clientTheme) => {
          this.setClientTheme(clientTheme, styleElement);
        })
      )
      .subscribe();
  }

  configureLogRocket(): void {
    this.logRocketService
      .isEnabled()
      .pipe(
        takeUntil(this._destroying$),
        filter((isEnabled) => !!isEnabled),
        tap(() => this.logRocketService.startLogRocket())
      )
      .subscribe();
  }

  configureMsal(): void {
    this.msalAuthService.initialize().pipe(takeUntil(this._destroying$)).subscribe();

    this.msalAuthService
      .handleRedirectObservable()
      .pipe(
        takeUntil(this._destroying$),
        filter((response) => !!response),
        tap((response) => {
          this.store.dispatch(
            MsalAuthActions.HandleLoginRedirect({
              payload: {
                authority: response.authority,
                user: response.account,
                redirectState: response.state,
              },
            })
          );
        })
      )
      .subscribe();

    this.msalBroadcastService.msalSubject$
      .pipe(
        takeUntil(this._destroying$),
        filterAuthError(),
        tap((error) => {
          this.store.dispatch(MsalAuthActions.HandleAuthError({ payload: error }));
        })
      )
      .subscribe();
  }

  configurePendo(): void {
    if (environment.pendo.isEnabled) {
      // Add the pendo snippet <script> tag to the document head
      const head = this._document.querySelector('head');
      head.appendChild(this.pendoService.getSnippet(environment.pendo.apiKey));

      this.pendoService.initializePendo();
    }
  }

  configureRouterEvents(): void {
    // ensure the global spinner is up during navigation sequences
    // and when we first load
    // note: this will be dismissed after your routed components
    // constructor, but before its lifecycle methods are called
    this.store.dispatch(ModalsActions.EnableGlobalSpinner());
    this.router.events
      .pipe(
        takeUntil(this._destroying$),
        tap((ev) => {
          if (ev instanceof NavigationStart) {
            this.store.dispatch(ModalsActions.EnableGlobalSpinner());
          } else if (ev instanceof NavigationEnd) {
            this.store.dispatch(ModalsActions.DisableGlobalSpinner());
            PendoLocationService.instance.identifyPage(ev.urlAfterRedirects.split('?')[0]);
          } else if (ev instanceof NavigationCancel || ev instanceof NavigationError) {
            this.store.dispatch(ModalsActions.DisableGlobalSpinner());
          }
        })
      )
      .subscribe();

    this.router.events
      .pipe(
        takeUntil(this._destroying$),
        filter((event) => event instanceof NavigationEnd),
        map(() => this.activatedRoute),
        map((route: ActivatedRoute) => {
          while (route.firstChild) route = route.firstChild;
          return route;
        }),
        filter((route) => route.outlet === 'primary'),
        mergeMap((route) => route.data),
        tap((data) => {
          this.applicationInsightsService.logPageView(data.title, this.router.url);
        })
      )
      .subscribe();
  }

  createStyleElement(): HTMLStyleElement {
    // Add a <style> element to <head> to hold the CSS variables used for client themeing,
    // and use the CLIENT_THEME initially
    const head = this._document.querySelector('head');
    const styleElement = this._document.createElement('style');
    head.appendChild(styleElement);
    return styleElement;
  }

  @HostListener('window:orientationchange', ['$event'])
  onOrientationChange($event: any) {
    // Reset the width and height of the device. This fixes issues with IPads when they change orientation.
    document.documentElement.style.width = 'initial';
    document.documentElement.style.height = '100%';
  }

  setClientTheme(clientTheme: ClientTheme, styleElement: HTMLStyleElement): void {
    if (clientTheme) {
      this.setDocumentTitle(this._document, clientTheme);
      this.setFavicon(this._document, clientTheme);

      const cssVariables = `--client-theme-primary-color: ${clientTheme.primaryColorHexCode};
      --client-theme-primary-color-hover-background: ${clientTheme.primaryColorHoverBgHexCode};
      --client-theme-primary-color-hover-text: ${clientTheme.primaryColorHoverTextHexCode};
      --client-theme-secondary-color: ${clientTheme.secondaryColorHexCode};
      --client-theme-secondary-color-hover-background: ${clientTheme.secondaryColorHoverBgHexCode};
      --client-theme-secondary-color-hover-text: ${clientTheme.secondaryColorHoverTextHexCode};
      `;

      styleElement.innerHTML = '';
      styleElement.appendChild(
        this._document.createTextNode(`:root {
        ${cssVariables}
      }`)
      );
    }
  }

  setDocumentTitle(doc: Document, clientTheme: ClientTheme) {
    if (clientTheme?.documentTitleText) {
      doc.title = clientTheme.documentTitleText;
    }
  }

  setFavicon(doc: Document, clientTheme: ClientTheme) {
    const iconLinkElement = doc.querySelector('link[rel="icon"]');

    if (iconLinkElement && clientTheme?.faviconUri) {
      iconLinkElement.setAttribute('href', clientTheme.faviconUri);
    }
  }
}
