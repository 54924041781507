import { createFeatureSelector, createSelector } from '@ngrx/store';
import { Status } from '../../models';

import { KnowledgeBasedAuthenticationState } from '../state';

export const getKnowledgeBasedAuthenticationState =
  createFeatureSelector<KnowledgeBasedAuthenticationState.State>('knowledgeBasedAuthentication');

export const getKbaStatus = createSelector(
  getKnowledgeBasedAuthenticationState,
  (state: KnowledgeBasedAuthenticationState.State) => state.status
);

export const getTransactionStatus = createSelector(
  getKbaStatus,
  (status: Status) => status?.transactionStatus
);

export const getProductType = createSelector(
  getKnowledgeBasedAuthenticationState,
  (state: KnowledgeBasedAuthenticationState.State) => state.productType
);

export const getQuestionSet = createSelector(
  getKnowledgeBasedAuthenticationState,
  (state: KnowledgeBasedAuthenticationState.State) => state.questionSet
);

export const getFailureReason = createSelector(
  getKnowledgeBasedAuthenticationState,
  (state: KnowledgeBasedAuthenticationState.State) => state.productReason?.code
);

export const getCurrentUserNumberOfAttempts = createSelector(
  getKnowledgeBasedAuthenticationState,
  (state: KnowledgeBasedAuthenticationState.State) => state.currentUserNumberOfAttempts
);
