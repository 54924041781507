import { Action, createReducer, on } from '@ngrx/store';
import { KnowledgeBasedAuthenticationActions } from '../actions';
import { KnowledgeBasedAuthenticationState } from '../state';

const reducer = createReducer(
  KnowledgeBasedAuthenticationState.initialState,
  on(
    KnowledgeBasedAuthenticationActions.SetKbaQuestions,
    (
      state: KnowledgeBasedAuthenticationState.State,
      { payload: { status, questionSet, productReason, productType } }
    ) => ({
      ...state,
      status,
      productReason,
      questionSet,
      productType,
    })
  ),
  on(
    KnowledgeBasedAuthenticationActions.ResetKbaState,
    (state: KnowledgeBasedAuthenticationState.State) =>
      KnowledgeBasedAuthenticationState.initialState
  ),
  on(
    KnowledgeBasedAuthenticationActions.SetCurrentUserNumberOfKbaAttempts,
    (state: KnowledgeBasedAuthenticationState.State, { payload }) => ({
      ...state,
      currentUserNumberOfAttempts: payload,
    })
  ),
  on(
    KnowledgeBasedAuthenticationActions.KbaPassed,
    KnowledgeBasedAuthenticationActions.KbaPending,
    (
      state: KnowledgeBasedAuthenticationState.State,
      { response }
    ): KnowledgeBasedAuthenticationState.State => ({
      ...state,
      status: response.status,
      productReason: response.productReason,
      questionSet: response.questionSet,
      productType: response.productType,
    })
  )
);

export function knowledgeBasedAuthenticationReducer(
  state: KnowledgeBasedAuthenticationState.State | undefined,
  action: Action
) {
  return reducer(state, action);
}
