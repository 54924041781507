<div data-testid="progress-bar">
  <div class="progress-bar__title">{{ title }}</div>
  <div class="progress-bar__text">{{ text }}</div>
  <div class="progress-bar__wrapper">
    <mat-progress-bar
      class="progress-bar__progress rkt-ProgressBar"
      color="primary"
      mode="{{ mode }}"
      aria-label="Loading Documents"
      value="{{ percentLoaded }}"
    ></mat-progress-bar>
  </div>
</div>
