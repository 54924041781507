import { AfterViewInit, Component, Input, OnDestroy } from '@angular/core';
import { DefaultProjectorFn, MemoizedSelector, select, Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { filter, takeUntil, tap } from 'rxjs/operators';
import { DocumentsActions, DocumentsSelectors } from 'src/app/features/documents';
import { ModalsActions } from 'src/app/features/modals';

import { RootStoreState } from 'src/app/store';

@Component({
  selector: 'app-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss'],
})
export class ProgressBarComponent implements AfterViewInit, OnDestroy {
  @Input() title: string;
  @Input() text: string;
  @Input() mode: string;
  @Input() percentLoadedSelector: MemoizedSelector<
    object,
    number,
    DefaultProjectorFn<number>
  >;

  notifier = new Subject();
  intervalDestroyNotifier = new Subject();
  percentLoaded = 0;
  clearTriggered = false;

  constructor(private readonly store: Store<RootStoreState.State>) {}

  ngAfterViewInit() {

    if (this.mode === 'determinate') {
      this.store
        .pipe(
          takeUntil(this.notifier),
          select(this.percentLoadedSelector),
          filter<number>(Boolean),
          tap((percentLoaded) => this.percentChangeHandler(percentLoaded))
        )
        .subscribe();
    } else {
      this.store
        .pipe(
          takeUntil(this.notifier),
          select(DocumentsSelectors.getAreAllSessionsDocumentsLoaded),
          tap(
            (hasAllDevicesLoaded) =>
              hasAllDevicesLoaded && !this.clearTriggered && this.hideProgressBar()
          )
        )
        .subscribe();
    }
  }

  percentChangeHandler(percentLoaded: number) {
    this.percentLoaded = percentLoaded;
    if (percentLoaded >= 100 && !this.clearTriggered) {
      this.hideProgressBar();
    }
  }

  ngOnDestroy() {
    this.notifier.next(undefined);
    this.notifier.complete();
  }

  hideProgressBar() {
    this.clearTriggered = true;
    this.store.dispatch(DocumentsActions.ProgressBarClosed());
    setTimeout(() => {
      this.store.dispatch(ModalsActions.ClearModalComponent());
    }, 1000);
  }
}
