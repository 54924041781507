import { Component, Input } from '@angular/core';

import { EndorsementBase } from '../../models';

@Component({
  selector: 'app-system-endorsement',
  templateUrl: './system-endorsement.component.html',
  styleUrls: ['./system-endorsement.component.scss'],
})
export class SystemEndorsementComponent extends EndorsementBase {
  constructor() {
    super();
  }

  @Input() altText: string;
}
