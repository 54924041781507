<div class="generic-confirm-modal">
  <div class="generic-confirm-modal__header">
    <h3 class="modal-title">Start video recording</h3>
  </div>
  <div class="generic-confirm-modal__body">
    <div>
      <p>
        We're starting your video, once everyone has connected we will start the signing session.
      </p>
      <p>Please be patient, this may take a moment.</p>
    </div>
  </div>
</div>
