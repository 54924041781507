import { PackageUser } from '../../models';

export interface State {
  activePackageUser: PackageUser;
  activePackageUserGuid: string;
  isSigningAgent: boolean;
  packageUsers: PackageUser[];
  activePackageUserDashboardUrl: string;
}

export const initialState: State = {
  activePackageUser: null,
  activePackageUserGuid: null,
  isSigningAgent: false,
  packageUsers: [],
  activePackageUserDashboardUrl: null,
};
