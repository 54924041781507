import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-close-icon',
  template: `
    <svg
      height="24px"
      viewBox="0 -960 960 960"
      width="24px"
      xmlns="http://www.w3.org/2000/svg"
      (click)="closed.emit()"
    >
      <path
        d="m256-200-56-56 224-224-224-224 56-56 224 224 224-224 56 56-224 224 224 224-56 56-224-224-224 224Z"
      />
    </svg>
  `,
  styleUrls: ['./close-icon.component.scss'],
})
export class CloseIconComponent {
  @Output() closed = new EventEmitter();
}
