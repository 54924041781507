import { createFeatureSelector, createSelector } from '@ngrx/store';

import { SigningRoomState } from '../state';

export const getSigningRoomState = createFeatureSelector<SigningRoomState.State>('signingRoom');

export const signingRoomSessionCompleted = createSelector(
  getSigningRoomState,
  (state: SigningRoomState.State) => state.sessionCompleted
);

export const reviewingDocuments = createSelector(
  getSigningRoomState,
  (state: SigningRoomState.State) => state.reviewingDocuments
);

export const showCongratulationsPage = createSelector(
  getSigningRoomState,
  (state: SigningRoomState.State) => state.showCongratulationsPage
);

export const sessionJoined = createSelector(
  getSigningRoomState,
  (state: SigningRoomState.State) => state.sessionJoined
);

export const signingRoomSessionActive = createSelector(
  getSigningRoomState,
  (state: SigningRoomState.State) =>
    !!state?.sessionJoined &&
    !(!!state?.sessionCompleted || !!state?.showCongratulationsPage)
);

export const GetHasSigningStarted = createSelector(
  getSigningRoomState,
  (state: SigningRoomState.State) => state.hasSigningStarted
);
