import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { SharedModule } from 'src/app/features/shared';

import {
  CancelledSigningComponent,
  CompletedSigningComponent,
  EarlySigningComponent,
  ExpiredSigningComponent,
  FurtherAssistanceComponent,
  GenericErrorComponent,
  InvalidLinkComponent,
  InvalidURLComponent,
  KbaFailComponent,
  ProcessingSigningComponent,
  SmartphonesUnsupportedComponent,
  UnauthorizedComponent,
  UnsupportedBrowserComponent,
  UnsupportedDeviceComponent,
  UnsupportedOperatingSystemComponent,
} from './components';
import { FeedbackComponent } from './containers';

@NgModule({
  declarations: [
    CancelledSigningComponent,
    CompletedSigningComponent,
    EarlySigningComponent,
    ExpiredSigningComponent,
    FeedbackComponent,
    FurtherAssistanceComponent,
    InvalidURLComponent,
    ProcessingSigningComponent,
    UnsupportedBrowserComponent,
    UnsupportedDeviceComponent,
    UnauthorizedComponent,
    InvalidLinkComponent,
    GenericErrorComponent,
    KbaFailComponent,
    UnsupportedOperatingSystemComponent,
    SmartphonesUnsupportedComponent,
  ],
  imports: [CommonModule, SharedModule],
})
export class FeedbackModule {}
