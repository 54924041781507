import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import * as LogRocket from 'logrocket';
import { combineLatest, Observable } from 'rxjs';
import { filter,  switchMap, take, tap } from 'rxjs/operators';

import { Feature } from 'src/app/features/feature-management/models';
import { FeatureManagementService } from 'src/app/features/feature-management/services';
import { MsalAuthSelectors } from 'src/app/features/msal-auth';
import { PackagesSelectors } from 'src/app/features/packages';
import { DeviceGroupSelectors } from 'src/app/features/device-group';
import { RootStoreState } from 'src/app/store';

@Injectable({
  providedIn: 'root',
})
export class LogRocketService {
  constructor(
    private readonly featureManagementService: FeatureManagementService,
    private readonly store: Store<RootStoreState.State>
  ) {}

  startLogRocket() {
    this.store
      .pipe(
        select(DeviceGroupSelectors.getDeviceCode),
        take(1),
        tap((deviceCode) => {
          this.init('asqz2e/nexsys-technologies');
          this.identify(deviceCode);
        })
      )
      .subscribe();
  }

  isEnabled(): Observable<boolean> {
    return combineLatest([
      this.store.pipe(select(PackagesSelectors.getPackage)),
      this.store.pipe(select(MsalAuthSelectors.getIsLoading)),
      this.store.pipe(select(DeviceGroupSelectors.getDeviceCode)),
    ]).pipe(
      filter(
        ([activePackage, isAuthInProgress, deviceCode]) =>
          !!activePackage && !isAuthInProgress && !!deviceCode
      ),
      take(1),
      switchMap(() => this.featureManagementService.getIsFeatureFlagEnabled(Feature.LogRocket))
    );
  }

  init(appId: string) {
    LogRocket.init(appId, {
      mergeIframes: true,
      network: {
        isEnabled: false,
      },
    });
  }

  identify(id: string) {
    LogRocket.identify(id);
  }
}
