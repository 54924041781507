import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { NgModel } from '@angular/forms';

@Component({
  selector: 'app-rds-input',
  templateUrl: './rds-input.component.html',
  styleUrls: ['./rds-input.component.scss'],
})
export class RdsInputComponent {
  @ViewChild('phoneInputModel')
  phoneInputModel: NgModel;

  @Input() inputModel: string;
  @Output() inputModelChange = new EventEmitter<string>();
}
