import { createFeatureSelector, createSelector } from '@ngrx/store';

import { IdVerificationState } from '../state';

export const getIdVerificationState =
  createFeatureSelector<IdVerificationState.State>('idVerification');

export const getAccessLink = createSelector(
  getIdVerificationState,
  (state: IdVerificationState.State) => state.accessLink
);

export const getHasAccessLinkSmsSentSuccessfully = createSelector(
  getIdVerificationState,
  (state: IdVerificationState.State) => state.hasAccessLinkSmsSentSuccessfully
);

export const getPackageUserGuid = createSelector(
  getIdVerificationState,
  (state: IdVerificationState.State) => state.packageUserGuid
);

export const getLastLinkTime = createSelector(
  getIdVerificationState,
  (state: IdVerificationState.State) => state.lastLinkTime
);

export const getIsComplete = (packageUserGuid: string) =>
  createSelector(
    getIdVerificationState,
    (state: IdVerificationState.State) => state.statusByUser[packageUserGuid]?.isComplete ?? false
  );

export const getIsSessionStarted = (packageUserGuid: string) =>
  createSelector(
    getIdVerificationState,
    (state: IdVerificationState.State) =>
      state.statusByUser[packageUserGuid]?.isSessionStarted ?? false
  );
