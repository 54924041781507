import {
  ActionReducer,
  MetaReducer
} from '@ngrx/store';

import { localStorageSync } from 'ngrx-store-localstorage';

export function sessionStorageSyncReducer(reducer: ActionReducer<any>): ActionReducer<any> {
  return localStorageSync({
    keys: [{ participantVerification: ['rejoinedUsers'] }],
    rehydrate: true,
    storage: sessionStorage,
  })(reducer);
}

export const metaReducers: MetaReducer[] = [sessionStorageSyncReducer];
