import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';

import { combineLatest, Observable, Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';

import { ModalsActions } from 'src/app/features/modals';
import { PackageUser, PackageUsersSelectors } from 'src/app/features/package-users';
import { getFirstNames, PackageUserWithDisplayName } from 'src/app/features/package-users/util';
import { RootStoreState } from 'src/app/store';

import { CheckInActions, CheckInSelectors } from '../../store';

@Component({
  selector: 'app-notary-modal',
  templateUrl: './notary-modal.component.html',
  styleUrls: ['./notary-modal.component.scss'],
})
export class NotaryModalComponent implements OnInit, OnDestroy {
  static identifier = 'NotaryModalComponent';

  @Input() imageSrc = '../../../../../assets/images/notary.svg';

  signingAgent$: Observable<PackageUser>;
  isMultiSigner: boolean;

  formattedNames: string;
  currentUser: PackageUserWithDisplayName;

  private userListSubscription: Subscription;

  constructor(private readonly store: Store<RootStoreState.State>) {}

  ngOnInit() {
    this.signingAgent$ = this.store.pipe(select(PackageUsersSelectors.getSigningAgent));

    this.userListSubscription = combineLatest([
      this.store.pipe(select(CheckInSelectors.getUserCheckInSequence)),
      this.store.pipe(select(PackageUsersSelectors.getNonSigningAgents)),
    ])
      .pipe(
        tap(([userGuids, nonSigningAgents]) => {
          const nonSigningAgentsWithDisplayName = getFirstNames(nonSigningAgents);
          const selectedUsers = userGuids.map((userGuid) =>
            nonSigningAgentsWithDisplayName.find((user) => user.packageUserGuid === userGuid)
          );

          this.currentUser = selectedUsers.find((u) => !u?.checkInStatus?.isCompleted);
          this.isMultiSigner = userGuids.length > 1;
          this.formattedNames = this.formatNames(selectedUsers);
        })
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.userListSubscription?.unsubscribe();
  }

  onContinue() {
    this.store.dispatch(CheckInActions.BeginCheckIn());
    this.onBack();
  }

  onBack() {
    this.store.dispatch(
      ModalsActions.ClearModalComponentIfOpen({
        payload: { component: NotaryModalComponent },
      })
    );
  }

  formatNames(users: PackageUserWithDisplayName[]) {
    if (!this.isMultiSigner) {
      return this.currentUser.displayName;
    }
    const userFirstNames = users.map((u) => u.displayName);
    const temp = userFirstNames.slice(0, -1).join(', ');
    return `${temp} & ${userFirstNames[userFirstNames.length - 1]}`;
  }
}
