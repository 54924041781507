<div class="audio-video-blocked-error-modal">
  <div class="audio-video-blocked-error-modal__header">
    <div class="title">Camera and/or microphone blocked</div>
  </div>
  <div class="audio-video-blocked-error-modal__body">
    <p>
      In order to proceed, this site needs to use your camera and microphone.
    </p>
    <div class="audio-video-blocked-error-modal__body--link-list">
      <p>
        <strong>For help correcting this, check out the links below:</strong>
      </p>
      <ul>
        <li>
          <a
            target="_blank"
            href="https://support.google.com/chrome/answer/2693767?hl=en&co=GENIE.Platform%3DDesktop&oco=1">Desktop/Laptop</a>
        </li>
        <li>
          <a
            target="_blank"
            href="https://support.google.com/chrome/answer/2693767?hl=en&co=GENIE.Platform%3DAndroid&oco=1">Android
          </a>
        </li>
        <li>
          <a
            target="_blank"
            href="https://support.google.com/chrome/answer/2693767?hl=en&co=GENIE.Platform%3DiOS&oco=1">iPad</a>
        </li>
      </ul>
    </div> 
    <p>
      You may need to <span (click)="refresh()" class="refresh">refresh</span> the page for this setting to take effect.
    </p>
    <div>
      <p>
        If this issue persists, please call us: <strong><a href="tel:{{phoneNumber | phone }}">{{ phoneNumber | phone }}</a></strong>
      </p>
    </div>
  </div>
</div>
