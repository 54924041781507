import { Action, createReducer, on } from '@ngrx/store';

import { DefaultModalSize } from '../../enums';
import { ModalsActions } from '../actions';
import { ModalsState } from '../state';
import { PackagesActions } from 'src/app/features/packages';

const reducer = createReducer(
  ModalsState.initialState,
  on(ModalsActions.SetModalComponent, (state: ModalsState.State, { payload }) => ({
    ...state,
    ...payload,
    modalSize: (payload.modalSize ||= DefaultModalSize),
    contentIsStandalone: false,
  })),
  on(ModalsActions.SetStandaloneModalComponent, (state: ModalsState.State, { payload }) => ({
    ...state,
    ...payload,
    modalSize: (payload.modalSize ||= DefaultModalSize),
    allowManualClose: false,
    contentIsStandalone: true,
    useBackgroundOverlay: payload.useBackgroundOverlay ?? true,
  })),
  on(ModalsActions.ClearModalComponent, (state: ModalsState.State) => ({
    ...state,
    component: null,
    componentData: null,
    shouldFade: false,
    modalTitle: null,
    modalSize: DefaultModalSize,
    allowManualClose: true,
    contentIsStandalone: false,
    useBackgroundOverlay: true,
  })),
  on(ModalsActions.ShowLoadingSpinner, (state: ModalsState.State) => ({
    ...state,
    showLoadingSpinner: true,
  })),
  on(ModalsActions.HideLoadingSpinner, (state: ModalsState.State) => ({
    ...state,
    showLoadingSpinner: false,
  })),
  on(ModalsActions.EnableGlobalSpinner, (state: ModalsState.State) => ({
    ...state,
    globalSpinner: true,
  })),
  on(ModalsActions.DisableGlobalSpinner, (state: ModalsState.State) => ({
    ...state,
    globalSpinner: false,
  })),
  on(PackagesActions.OptOutSuccess, (state: ModalsState.State) => ({
    ...state,
    component: null,
    componentData: null,
    shouldFade: false,
    modalTitle: null,
    modalSize: DefaultModalSize,
    allowManualClose: true,
    contentIsStandalone: false,
    useBackgroundOverlay: true,
    showLoadingSpinner: false,
  })),
  on(PackagesActions.OptOutFailure, (state: ModalsState.State) => ({
    ...state,
    showLoadingSpinner: false,
  })),
  on(ModalsActions.OptOut, (state: ModalsState.State) => ({
    ...state,
    showLoadingSpinner: true,
  }))
);

export function modalsReducer(state: ModalsState.State | undefined, action: Action) {
  return reducer(state, action);
}
