<dialog #dialog
  [ngClass]="{
    'modal-background': true,
    'modal-background--managed': !instanceIsStandalone,
    'modal-show': showModal,
    'modal-fade': instanceShouldFade,
    'modal-did-fade-in': hasFadedIn,
    'modal-no-overlay' : !useBackgroundOverlay
  }"
  cdkTrapFocus
  >
  <div
    [class]="['modal-dialog', modalSize ? 'modal--' + modalSize : '']"
    appModalDialog
  >
    <div class="modal-content">
      <div *ngIf="modalTitle$ | async" class="modal-header">
        <div class="modal-title" data-testid="modal-header">{{ modalTitle$ | async }}</div>
      </div>
      <div class="modal-body">
        <ng-template appModalContent></ng-template>
      </div>
      <app-button-container *ngIf="allowManualClose">
        <app-button type="primary" (click)="handleDialogClose()" [dataTestId]="'modal-close'">
          Close
        </app-button>
      </app-button-container>
    </div>
  </div>
</dialog>
