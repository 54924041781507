import { Component, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { ModalsActions } from 'src/app/features/modals';
import { PackageUser } from 'src/app/features/package-users/models';
import { RootStoreState } from 'src/app/store';
import { ParticipantVerificationActions } from '../../store/actions';

@Component({
  selector: 'app-credential-denial-confirmation-modal',
  templateUrl: './credential-denial-confirmation-modal.component.html',
  styleUrls: ['./credential-denial-confirmation-modal.component.scss'],
})
export class CredentialDenialConfirmationModalComponent {
  static identifier = 'CredentialDenialConfirmationModalComponent';
  @Input() participantBeingVerified: PackageUser;
  constructor(private readonly store: Store<RootStoreState.State>) {}

  confirmDenial() {
    this.store.dispatch(
      ParticipantVerificationActions.DenyParticipant({
        payload: this.participantBeingVerified.packageUserGuid,
      })
    );
    this.store.dispatch(ModalsActions.ClearModalComponent());
  }

  backToIdVerificationView() {
    this.store.dispatch(ModalsActions.ClearModalComponent());
  }
}
