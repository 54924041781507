import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { PackageUser } from 'src/app/features/package-users';

@Injectable()
export class CheckInStatusService {
  constructor(private readonly http: HttpClient) {}

  public getPackageCheckInStatus(packageGuid: string): Observable<PackageUser[]> {
    return this.http.get<PackageUser[]>(`tasks/checkin/status/${packageGuid}`);
  }

  public activeSessionCheckInCompletion() {
    return this.http.post(`tasks/activeSessionCheckInCompletion`, null);
  }
}
