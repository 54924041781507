import { Component, Input } from '@angular/core';
import { Store } from '@ngrx/store';

import { ModalsActions } from 'src/app/features/modals';
import { RootStoreState } from 'src/app/store';

@Component({
  selector: 'app-enlarge-id-modal',
  templateUrl: './enlarge-id-modal.component.html',
  styleUrls: ['./enlarge-id-modal.component.scss'],
})
export class EnlargeIdModalComponent {
  @Input() idImage: string;
  @Input() isBase64: boolean;
  @Input() width: number;

  constructor(private readonly store: Store<RootStoreState.State>) {}

  get idImageStyle() {
    return `width: ${this.width ? this.width : 100}%`;
  }

  close(): void {
    this.store.dispatch(ModalsActions.ClearModalComponent());
  }
}
