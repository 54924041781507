import { Action, createReducer, on } from '@ngrx/store';

import { DeviceGroupActions } from '../actions';
import { DeviceGroupState } from '../state';

const reducer = createReducer(
  DeviceGroupState.initialState,
  on(DeviceGroupActions.SetDeviceCode, (state: DeviceGroupState.State, { payload }) => {
    const deviceCode = payload.deviceCode;
    return {
      ...state,
      deviceCode,
      isValidForPackage: true,
    };
  }),
  on(DeviceGroupActions.GetDeviceCode, (state: DeviceGroupState.State) => ({
    ...state,
    isValidForPackage: false,
  })),
  on(
    DeviceGroupActions.GetCurrentDeviceUsersSuccessful,
    (state: DeviceGroupState.State, { payload }) => {
      return {
        ...state,
        usersOnDevice: payload.deviceUsers,
        deviceUsersLoaded: true,
      };
    }
  ),
  on(
    DeviceGroupActions.RemovePackageUserFromDevice,
    (state: DeviceGroupState.State, { payload }) => {
      return {
        ...state,
        usersOnDevice: state.usersOnDevice.filter(
          (user) => user.packageUserGuid !== payload.packageUserGuid
        ),
      };
    }
  ),
  on(DeviceGroupActions.SetParticipantRejoining, (state: DeviceGroupState.State, { payload }) => {
    const userAlreadyPresent = state.rejoiningPackageUserGuids?.includes(payload.packageUserGuid);
    const rejoinedPackageUsersGuid = userAlreadyPresent
      ? state.rejoiningPackageUserGuids
      : [...state.rejoiningPackageUserGuids, payload.packageUserGuid];

    return {
      ...state,
      isParticipantRejoining: payload.isParticipantRejoining,
      rejoiningPackageUserGuids: rejoinedPackageUsersGuid,
    };
  }),
  on(
    DeviceGroupActions.SetUnauthorizedDeviceCode,
    (state: DeviceGroupState.State, { deviceCode }) => ({
      ...state,
      unauthorizedDeviceCodes: [...state.unauthorizedDeviceCodes, deviceCode],
    })
  ),
  on(
    DeviceGroupActions.SetIsDeviceUsersLoading,
    (state: DeviceGroupState.State, { isLoading }) => ({
      ...state,
      isDeviceUsersLoading: isLoading,
    })
  ),
  on(
    DeviceGroupActions.SetIsPINOrMFAComplete,
    (state: DeviceGroupState.State, { isPinOrMfaComplete }) => ({
      ...state,
      isPinOrMfaComplete: isPinOrMfaComplete,
    })
  ),
  on(
    DeviceGroupActions.SetClientHintsForDevice,
    (state: DeviceGroupState.State, { clientHints }) => ({
      ...state,
      clientHints: clientHints,
    })
  )
);

export function deviceGroupReducer(state: DeviceGroupState.State | undefined, action: Action) {
  return reducer(state, action);
}
