import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { ModalsActions } from 'src/app/features/modals';
import { PackageUser } from 'src/app/features/package-users/models';
import { VideoActions } from 'src/app/features/video/store';
import { RootStoreState } from 'src/app/store';
import { ParticipantVerificationSelectors } from '../../store';
import { ParticipantVerificationActions } from '../../store/actions';

@Component({
  selector: 'app-credential-rejoin-denial-confirmation-modal',
  templateUrl: './credential-rejoin-denial-confirmation-modal.component.html',
  styleUrls: ['./credential-rejoin-denial-confirmation-modal.component.scss'],
})
export class CredentialRejoinDenialConfirmationModalComponent implements OnInit, OnDestroy {
  static identifier = 'CredentialRejoinDenialConfirmationModalComponent';
  @Input() participantBeingVerified: PackageUser;
  rejoinedUsers: { packageUserGuid: string; deviceCode: string }[];

  protected rejoinedParticipantsSubscription: Subscription;
  private hasHadRejoinedUser = false;

  constructor(private readonly store: Store<RootStoreState.State>) {}

  ngOnInit(): void {
    this.rejoinedParticipantsSubscription = this.store
      .select(ParticipantVerificationSelectors.getRejoinedPackageUsers)
      .subscribe((rejoinedUsers) => {
        this.rejoinedUsers = rejoinedUsers;

        if (rejoinedUsers?.length > 0) {
          this.hasHadRejoinedUser = true;
        }

        if (rejoinedUsers?.length === 0 && this.hasHadRejoinedUser === true) {
          this.store.dispatch(ModalsActions.ClearModalComponent());
        }
      });
  }

  confirmRejoinDenial() {
    this.store.dispatch(
      ParticipantVerificationActions.DenyRejoiningParticipant({
        deviceCode: this.participantBeingVerified.deviceCode,
        packageUserGuid: this.participantBeingVerified.packageUserGuid,
      })
    );

    setTimeout(() => {
      this.store.dispatch(VideoActions.SetIgnoreParticipantVideoStream({ payload: false }));
    }, 5000);
  }

  backToIdVerificationView() {
    this.store.dispatch(ModalsActions.ClearModalComponent());
  }

  ngOnDestroy() {
    this.rejoinedParticipantsSubscription.unsubscribe();
  }
}
