import { Component, forwardRef, Input } from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  UntypedFormGroup,
  ValidationErrors,
  Validator,
} from '@angular/forms';

@Component({
  selector: 'app-consent-check-box',
  templateUrl: './consent-check-box.component.html',
  styleUrls: ['./consent-check-box.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ConsentCheckBoxComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => ConsentCheckBoxComponent),
      multi: true,
    },
  ],
})
export class ConsentCheckBoxComponent implements ControlValueAccessor, Validator {
  @Input() formStep: UntypedFormGroup;
  @Input() consentText: string;
  @Input() consentErrorText: string;
  @Input() validateOnInputChange = false;

  checked: boolean;
  isDisabled: boolean;

  onChanged: (checked: boolean) => {};
  onTouched: () => {};
  onValidatorChange: () => void;

  constructor() {}
  validate(control: AbstractControl<any, any>): ValidationErrors {
    const isChecked = control.value;
    if (!isChecked) {
      return { required: true };
    }
  }
  registerOnValidatorChange?(fn: () => void): void {
    this.onValidatorChange = fn;
  }

  writeValue(checked: boolean): void {
    this.checked = checked;
  }

  registerOnChange(fn: any): void {
    this.onChanged = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  onCheckboxChange = (checked) => {
    this.onChanged(checked);
    this.onTouched();
  };

  get current() {
    return this.formStep.get('consentCheckbox');
  }

  controlHasErrors(): boolean {
    if (this.validateOnInputChange) {
      return !!this.current.errors && (this.current.dirty || this.current.touched);
    }
    return !!this.formStep.errors;
  }
}
