//#region imports
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { select, Store } from '@ngrx/store';

import { of } from 'rxjs';
import {
  catchError,
  concatMap,
  filter,
  map,
  mergeMap,
  switchMap,
  withLatestFrom,
} from 'rxjs/operators';

import { MsalAuthSelectors } from 'src/app/features/msal-auth';
import { NotificationsActions, NotificationType } from 'src/app/features/notifications';
import { ExceptionType } from 'src/app/features/notifications/models';
import { SignalRActions } from 'src/app/features/signal-r';
import { RootStoreState } from 'src/app/store';
import { v4 as uuid } from 'uuid';

import { PackageUsersService } from '../../services/package-users.service';
import { PackageUsersActions } from '../actions';
import { PackageUsersSelectors } from '../selectors';

//#endregion imports

@Injectable()
export class PackageUsersEffects {
  setActivePackageUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PackageUsersActions.SetActivePackageUserGuid),
      concatMap((action) =>
        of(action).pipe(
          withLatestFrom(this.store.pipe(select(MsalAuthSelectors.getIsUserVerified)))
        )
      ),
      switchMap(([_, isUserVerified]) => {
        if (isUserVerified) {
          return of(PackageUsersActions.GetActivePackageUser());
        }

        return [];
      })
    )
  );

  setActivePackageAndPackageUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PackageUsersActions.SetActivePackageAndUserGuids),
      switchMap((action) => {
        if (action.payload.activePackageUserGuid) {
          return of(PackageUsersActions.GetActivePackageUser());
        }
        return of(
          PackageUsersActions.SetActivePackageUser({
            payload: { packageUser: null },
          })
        );
      })
    )
  );

  getPackageUsers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PackageUsersActions.GetActivePackageUser),
      concatMap((action) =>
        of(action).pipe(
          withLatestFrom(this.store.pipe(select(PackageUsersSelectors.getActivePackageUserGuid)))
        )
      ),
      switchMap(([action, getActivePackageUserGuid]) => {
        return this.packageUsersService.getPackageUser(getActivePackageUserGuid).pipe(
          switchMap((packageUser) => [
            // Order matters as AttemptSignalRHubConnection is intercepted
            // by a class that looks for the active package user.
            PackageUsersActions.SetActivePackageUser({
              payload: { packageUser },
            }),
            SignalRActions.AttemptSignalRHubConnection({
              payload: { packageUserGuid: packageUser.packageUserGuid },
            }),
          ]),
          catchError((err) =>
            of(
              NotificationsActions.AddNotification({
                payload: {
                  exception: err,
                  notificationType: NotificationType.Error,
                  id: uuid(),
                  text: 'Failed to get Package Users',
                  exceptionType: ExceptionType.ReloadRetry,
                },
              })
            )
          )
        );
      })
    )
  );

  createPackageUserPreSignSession$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PackageUsersActions.CreatePackageUserPreSignSession),
      concatMap((action) =>
        of(action).pipe(
          withLatestFrom(this.store.pipe(select(PackageUsersSelectors.getActivePackageUserGuid)))
        )
      ),
      switchMap(([_, packageUserGuid]) =>
        this.packageUsersService
          .createPackageUserPreSignSession({
            packageUserGuid: packageUserGuid,
          })
          .pipe(
            switchMap((p) => []),
            catchError((err) =>
              of(
                NotificationsActions.AddNotification({
                  payload: {
                    exception: err,
                    notificationType: NotificationType.Error,
                    id: uuid(),
                    text: 'Failed to create package user presign session',
                    exceptionType: ExceptionType.Other,
                  },
                })
              )
            )
          )
      )
    )
  );

  exitPackageUserPreSignSession$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PackageUsersActions.ExitPackageUserPreSignSession),
      concatMap((action) =>
        of(action).pipe(
          withLatestFrom(this.store.pipe(select(PackageUsersSelectors.getActivePackageUserGuid)))
        )
      ),
      switchMap(([_, packageUserGuid]) =>
        this.packageUsersService
          .exitPackageUserPreSignSession({
            packageUserGuid: packageUserGuid,
          })
          .pipe(
            switchMap((p) => []),
            catchError((err) =>
              of(
                NotificationsActions.AddNotification({
                  payload: {
                    exception: err,
                    notificationType: NotificationType.Error,
                    id: uuid(),
                    text: 'Failed to exit package user presign session',
                    exceptionType: ExceptionType.Other,
                  },
                })
              )
            )
          )
      )
    )
  );

  completePackageUserPreSignSession$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PackageUsersActions.CompletePackageUserPreSignSession),
      concatMap((action) =>
        of(action).pipe(
          withLatestFrom(this.store.pipe(select(PackageUsersSelectors.getActivePackageUserGuid)))
        )
      ),
      switchMap(([_, packageUserGuid]) =>
        this.packageUsersService
          .completePackageUserPreSignSession({
            packageUserGuid: packageUserGuid,
          })
          .pipe(
            switchMap((p) => []),
            catchError((err) =>
              of(
                NotificationsActions.AddNotification({
                  payload: {
                    exception: err,
                    notificationType: NotificationType.Error,
                    id: uuid(),
                    text: 'Failed to complete package user presign session',
                    exceptionType: ExceptionType.Other,
                  },
                })
              )
            )
          )
      )
    )
  );

  fetchPackageUserCheckInStatuses$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(PackageUsersActions.FetchPackageUserCheckInStatuses),
      concatMap((action) =>
        of(action).pipe(withLatestFrom(this.store.select(PackageUsersSelectors.getPackageUsers)))
      ),
      mergeMap(([_, pus]) => {
        return pus.map((pu) =>
          PackageUsersActions.FetchPackageUserCheckInStatus({
            payload: { packageUserGuid: pu.packageUserGuid },
          })
        );
      })
    );
  });

  fetchPackageUserCheckInStatus$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(PackageUsersActions.FetchPackageUserCheckInStatus),
      filter((action) => !!action.payload.packageUserGuid),
      mergeMap((action) => {
        return this.packageUsersService
          .getPackageUserCheckInStatus(action.payload.packageUserGuid)
          .pipe(
            switchMap((result) => [
              PackageUsersActions.SetPackageUserCheckInStatus({
                payload: {
                  packageUserGuid: result.packageUserGuid,
                  checkInStatus: result.checkInStatus,
                },
              }),
            ]),
            catchError((err) =>
              of(
                NotificationsActions.AddNotification({
                  payload: {
                    exception: err,
                    notificationType: NotificationType.Error,
                    id: uuid(),
                    text: 'Failed to get Package User Check In Status',
                    exceptionType: ExceptionType.ReloadRetry,
                  },
                })
              )
            )
          );
      })
    );
  });

  getActivePackageUserDashboardUrl$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PackageUsersActions.FetchActivePackageUserDashboardUrl),
      switchMap((action) => {
        return this.packageUsersService.getActivePackageUserDashboardUrl().pipe(
          switchMap((dashboardUrl) => [
            PackageUsersActions.SetActivePackageUserDashboardUrl({
              payload: { dashboardUrl: dashboardUrl.consumerDashboardUrl },
            }),
          ]),
          catchError((err) =>
            of(
              NotificationsActions.AddNotification({
                payload: {
                  exception: err,
                  notificationType: NotificationType.Error,
                  id: uuid(),
                  text: 'Failed to get Consumer Dashboard URL',
                  exceptionType: ExceptionType.ReloadRetry,
                },
              })
            )
          )
        );
      })
    )
  );

  loadPackageUsers$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(PackageUsersActions.packageUserResolverLoaded),
      map((_) => PackageUsersActions.FetchPackageUsers({ payload: { ignoreIfLoaded: true } }))
    );
  });

  loadPackageUsersCheckInStatus$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(PackageUsersActions.packageUserResolverLoaded),
      map((_) => PackageUsersActions.FetchPackageUserCheckInStatuses())
    );
  });

  //Upon getting signalr message that NBTH/Witness is currently verifying identity, fetch package users to keep state upto date
  fetchPackageUsersForAdditionalDetails$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(PackageUsersActions.PackageUserCheckInStatusUpdated),
      concatLatestFrom((action) => [
        this.store.select(
          PackageUsersSelectors.selectHasNbthWitnessSubmittedAdditionalDetails(
            action.payload?.packageUserGuid
          )
        ),
      ]),
      filter(
        ([_, hasNbthWitnessSubmittedAdditionalDetails]) => hasNbthWitnessSubmittedAdditionalDetails
      ),
      map(([_, __]) =>
        PackageUsersActions.FetchPackageUsers({ payload: { ignoreIfLoaded: false } })
      )
    );
  });

  constructor(
    private readonly actions$: Actions,
    private readonly packageUsersService: PackageUsersService,
    private readonly store: Store<RootStoreState.State>
  ) {}
}
