import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
})
export class ButtonComponent {
  @Input() ariaLabel: string | null = null;
  @Input() type: string; //values: primary, secondary, tertiary, warning, warning-secondary
  @Input() dataTestId: string;
  @Input() size: string; //values: small
  @Input() isDisabled: boolean;

  @Output() buttonClick = new EventEmitter();

  get buttonClasses() {
    return ['button', 'button__' + this.type, this.size ? 'button__' + this.size : '']
      .filter((c) => c !== '')
      .join(' ');
  }

  onButtonClick() {
    this.buttonClick.emit();
  }
}
