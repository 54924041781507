import { createAction, props } from '@ngrx/store';

import { IdVerification, UpdateParticipantApprovalResponse } from '../../models';

export const ApproveParticipant = createAction(
  '[Participant Verification] Approve Participant',
  props<{ payload: string }>()
);

export const ApproveParticipantSuccess = createAction(
  '[Participant Verification] Approve Participant Success',
  props<{ payload: UpdateParticipantApprovalResponse }>()
);

export const ApproveRejoiningParticipant = createAction(
  '[Participant Verification] Approve Rejoining Participant',
  props<{ packageUserGuid: string; deviceCode: string }>()
);

export const RejoiningParticipantApproved = createAction(
  '[Participant Verification] Rejoining Participant Approved',
  props<{ packageUserGuid: string; deviceCode: string }>()
);

export const RejoiningParticipant = createAction(
  '[Participant Verification] Rejoining Participant',
  props<{ packageUserGuid: string; deviceCode: string }>()
);

export const DenyRejoiningParticipant = createAction(
  '[Participant Verification] Deny Rejoining Participant',
  props<{ packageUserGuid: string; deviceCode: string }>()
);

export const RejoiningParticipantDenied = createAction(
  '[Participant Verification] Rejoining Participant Denied',
  props<{ packageUserGuid: string; deviceCode: string }>()
);

export const DenyParticipant = createAction(
  '[Participant Verification] Deny Participant',
  props<{ payload: string }>()
);

export const GetApprovedParticipants = createAction(
  '[Participant Verification] Get Approved Participants'
);

export const GetApprovedParticipantsSuccess = createAction(
  '[Participant Verification] Get Approved Participants Success',
  props<{ payload: string[] }>()
);

export const DenyParticipantSuccess = createAction(
  '[Participant Verification] Deny Participant Success'
);

export const DenyRejoiningParticipantSuccess = createAction(
  '[Participant Verification] Deny Rejoining Participant Success',
  props<{ payload: UpdateParticipantApprovalResponse }>()
);

export const GetIdVerifications = createAction('[Participant Verification] Get Id Verifications');

export const SetIdVerifications = createAction(
  '[Participant Verification] Set Id Verifications for Package',
  props<{ payload: IdVerification[] }>()
);

export const SetAllSignersApproved = createAction(
  '[Participant Verification] Set All Signers Approved',
  props<{ payload: { allSignersApproved: boolean } }>()
);

export const GetAllSignersApproved = createAction(
  '[Participant Verification] Get All Signers Approved'
);

export const ShowUnableToProcessModal = createAction(
  '[Participant Verification] Show Unable To Process Modal'
);

export const DeniedRejoinedParticipant = createAction(
  '[Participant Verification] Denied Rejoined Participant',
  props<{ payload: { packageUserGuid: string } }>()
);

export const RemoveParticipantFromDevice = createAction(
  '[Participant Verification] Removes Participant from Device By Session Id',
  props<{ payload: { packageUserGuid: string, sessionId: string } }>()
);
