import { createFeatureSelector, createSelector } from '@ngrx/store';

import { DeviceGroupSelectors } from 'src/app/features/device-group';
import { DeviceUser } from 'src/app/features/device-group/models';
import { PackageUser, PackageUsersSelectors } from 'src/app/features/package-users';

import { NextStep } from '../../models';
import { CheckInState } from '../state';
import { EndorsementsSelectors } from 'src/app/features/endorsements';
import { selectIsCheckInComplete } from 'src/app/features/package-users/store/selectors/package-users.selectors';
import { getUsersOnDevice } from 'src/app/features/device-group/store/selectors/device-group.selectors';

export const getCheckInState = createFeatureSelector<CheckInState.State>('checkIn');

export const getUserCheckInSequence = createSelector(
  getCheckInState,
  PackageUsersSelectors.getPackageUsers,
  (state: CheckInState.State, packageUsers) =>
    state.userCheckInSequence.filter((userGuid) =>
      packageUsers.some((pu) => pu.packageUserGuid === userGuid)
    )
);

export const getUsersCompletedCheckIn = createSelector(
  getCheckInState,
  (state: CheckInState.State) => state.usersCompletedCheckIn
);

export const getLastCheckedInUser = createSelector(
  getUsersCompletedCheckIn,
  (usersCompletedCheckIn: string[]) => usersCompletedCheckIn[usersCompletedCheckIn.length - 1]
);

export const getNextCheckInUser = createSelector(
  getUserCheckInSequence,
  getUsersCompletedCheckIn,
  (usersInSequence: string[], usersCompletedCheckIn: string[]) =>
    usersInSequence.find((userGuid) => !usersCompletedCheckIn.includes(userGuid))
);

export const getNextStep = createSelector(
  getCheckInState,
  (state: CheckInState.State) => state.nextStep ?? NextStep.CheckIn
);

export const isCurrentDeviceCheckInComplete = createSelector(
  getNextCheckInUser,
  (nextUser: string) => !nextUser
);

export const getIsCheckInComplete = createSelector(
  PackageUsersSelectors.getPackageUsers,
  (packageUsers: PackageUser[]) => packageUsers?.every((pu) => pu.checkInStatus?.isCompleted)
);

export const isRejoining = createSelector(
  getIsCheckInComplete,
  DeviceGroupSelectors.getUsersOnDevice,
  (isCheckInComplete, usersOnDevice: DeviceUser[]) =>
    isCheckInComplete && usersOnDevice?.length === 0
);

export const getUsersViewedWaitingRoom = createSelector(
  getCheckInState,
  (state: CheckInState.State) => state.usersViewedWaitingRoom ?? []
);

export const getHasWaitingRoomBeenViewedByAllParticipants = createSelector(
  PackageUsersSelectors.getPackageUsers,
  getUsersViewedWaitingRoom,
  (users, usersViewedWaitingRoom) => users.length === usersViewedWaitingRoom.length
);

export const selectReadyToEnterSigningRoomParticipantsCount = createSelector(
  PackageUsersSelectors.getPackageUsers,
  getUsersViewedWaitingRoom,
  (packageUsers: PackageUser[], usersViewedWaitingRoom: string[]) =>
    packageUsers.filter(
      (pu) => pu.checkInStatus?.isCompleted && usersViewedWaitingRoom.includes(pu.packageUserGuid)
    ).length
);

export const selectReadyProgressPercentage = createSelector(
  PackageUsersSelectors.selectPackageUsersCount,
  selectReadyToEnterSigningRoomParticipantsCount,
  (packageUsersCount, readyCount) => (readyCount / packageUsersCount) * 100
);

export const selectIsRejoiningWaitingOnSA = createSelector(
  PackageUsersSelectors.selectHasOnlySigningAgentNotCheckedIn,
  DeviceGroupSelectors.getUsersOnDevice,
  (hasOnlySigningAgentNotCheckedIn, usersOnDevice: DeviceUser[]) =>
    hasOnlySigningAgentNotCheckedIn && usersOnDevice?.length === 0
);

export const selectCanEnterRemoteSigningRoom = createSelector(
  EndorsementsSelectors.areAllSystemEndorsementsApplied,
  PackageUsersSelectors.selectPackageUsersCount,
  selectReadyToEnterSigningRoomParticipantsCount,
  (areAllSystemEndorsementsApplied, packageUsersCount, readyCount) =>
    areAllSystemEndorsementsApplied && packageUsersCount === readyCount
);

export const selectUserCheckInSequenceContainsNotCheckedInUsers = createSelector(
  PackageUsersSelectors.selectNonSigningAgentsNotCheckedIn,
  getUserCheckInSequence,
  (nonSigningAgentsNotCheckedIn, userCheckInSequence) =>
    nonSigningAgentsNotCheckedIn.some((packageUser) =>
      userCheckInSequence.includes(packageUser.packageUserGuid)
    )
);

export const selectIsUserCheckInSequenceEmpty = createSelector(
  getUserCheckInSequence,
  (userCheckInSequence) => userCheckInSequence.length === 0
);

export const selectNonSigningAgentWelcomePageButton = createSelector(
  selectIsUserCheckInSequenceEmpty,
  selectUserCheckInSequenceContainsNotCheckedInUsers,
  selectIsCheckInComplete,
  getHasWaitingRoomBeenViewedByAllParticipants,
  getUsersOnDevice,
  (
    IsUserCheckInSequenceEmpty,
    userCheckInSequenceContainsNotCheckedInUsers,
    isCheckInComplete,
    hasWaitingRoomBeenViewedByAllParticipants,
    usersOnDevice
  ) => ({
    isBeginCheckInButtonVisible:
      userCheckInSequenceContainsNotCheckedInUsers ||
      (IsUserCheckInSequenceEmpty && !isCheckInComplete),
    isGoToWaitingRoomButtonVisible:
      !userCheckInSequenceContainsNotCheckedInUsers &&
      !IsUserCheckInSequenceEmpty &&
      !hasWaitingRoomBeenViewedByAllParticipants &&
      usersOnDevice.length > 0,
    isGoToSigningRoomButtonVisible:
      isCheckInComplete && hasWaitingRoomBeenViewedByAllParticipants && usersOnDevice.length > 0,
  })
);

export const selectNonSigningAgentWelcomePageRejoinButton = createSelector(
  isRejoining,
  (
    isRejoining,
  ) => ({
    isRejoinButtonVisible: isRejoining,
  })
);

export const selectWaitingRoomViewModel = createSelector(
  PackageUsersSelectors.getIsSigningAgent,
  PackageUsersSelectors.getSigningAgent,
  PackageUsersSelectors.selectAreNonSigningAgentsCheckedIn,
  PackageUsersSelectors.selectPackageUsersCount,
  selectReadyToEnterSigningRoomParticipantsCount,
  selectReadyProgressPercentage,
  (
    isSigningAgent,
    signingAgent,
    areNonSasCheckedIn,
    packageUsersCount,
    readyToEnterSigningRoomCount,
    readyProgressPercentage
  ) => ({
    isSigningAgent,
    signingAgent,
    isAddParticipantButtonDisabled: areNonSasCheckedIn,
    packageUsersCount: packageUsersCount,
    numberOfReadyParticipants: readyToEnterSigningRoomCount,
    progress: readyProgressPercentage,
  })
);
