import { Component } from '@angular/core';
import { createAction, props } from '@ngrx/store';

export const SetIsTabLeader = createAction(
  '[Duplicate Session Detector] Set Is Tab Leader',
  props<{ payload: { isLeader: boolean }}>()
);

export const SetIsTabActive = createAction(
  '[Duplicate Session Detector] Set Is Tab Active',
  props<{ payload: { isActive: boolean }}>()
);

export const SetTabCreated = createAction(
  '[Duplicate Session Detector] Set Tab Created',
  props<{ payload: { created: number }}>()
);

export const SetTabGuid = createAction(
  '[Duplicate Session Detector] Set Tab Guid',
  props<{ payload: { guid: string }}>()
);

export const SetIsEnabled = createAction(
  '[Duplicate Session Detector] Set Enabled',
  props<{ payload: { enabled: boolean }}>()
);