import { Guid } from 'guid-typescript';
import { DuplicateSessionModalComponent } from '../../duplicate-session-modal';

export interface State {
  isEnabled: boolean;
  isTabLeader: boolean;
  isTabActive: boolean;
  tabGuid: string;
  tabCreated: number;
  modalComponent: any;
}

export const initialState: State = {
  isEnabled: false,
  isTabLeader: false,
  isTabActive: true,
  tabGuid: Guid.create().toString(),
  tabCreated: Date.now().valueOf(),
  modalComponent: DuplicateSessionModalComponent
};