import { createFeatureSelector, createSelector } from '@ngrx/store';

import { MsalRoles } from '../../config';
import { IdTokenClaims } from '../../models';
import { MsalAuthState } from '../state';

export const getMsalState = createFeatureSelector<MsalAuthState.State>('msalAuth');

export const getUser = createSelector(getMsalState, (state: MsalAuthState.State) => state.user);

export const getError = createSelector(getMsalState, (state: MsalAuthState.State) => state.error);

export const getClientIDPCode = createSelector(
  getMsalState,
  (state: MsalAuthState.State) => state.idpCode
);

export const getRedirectState = createSelector(
  getMsalState,
  (state: MsalAuthState.State) => state.redirectState
);

export const getIdTokenClaims = createSelector(
  getUser,
  (user) => user?.idTokenClaims as IdTokenClaims
);

export const getUserGuid = createSelector(
  getIdTokenClaims,
  (claims) => claims?.extension_PackageUserGuids
);

export const getPackageGuid = createSelector(
  getIdTokenClaims,
  (claims) => claims?.extension_PackageGuid
);

export const getIsUserVerified = createSelector(
  getIdTokenClaims,
  (claims) => claims?.role === MsalRoles.Verified ?? false
);

export const getLastVerifiedUserGuid = createSelector(
  getMsalState,
  (state: MsalAuthState.State) => state.lastVerifiedUserGuid
);

export const getIsLoading = createSelector(
  getMsalState,
  (state: MsalAuthState.State) => state.loading
);

export const selectVerifiedUserGuids = createSelector(getMsalState, (state: MsalAuthState.State) => state.verifiedUserGuids);
