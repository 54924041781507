import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';

import { combineLatest, Observable } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';

import { DeviceGroupActions } from 'src/app/features/device-group';
import { PackageUserFormatNamePipe, PackageUsersSelectors } from 'src/app/features/package-users';
import { PackageUserWithDisplayName } from 'src/app/features/package-users/util';

import { NextStep } from '../../models';
import { CheckInActions, CheckInSelectors } from '../../store';

@Component({
  selector: 'app-signing-agent-welcome-page',
  templateUrl: './signing-agent-welcome-page.component.html',
  styleUrls: ['./signing-agent-welcome-page.component.scss'],
})
export class SigningAgentWelcomePageComponent implements OnInit {
  signingAgent$: Observable<PackageUserWithDisplayName>;
  isSaCheckedIn$: Observable<boolean>;
  isSaSelected$: Observable<boolean>;
  countNonSasCheckingIn$: Observable<number>;
  totalNonSas$: Observable<number>;

  constructor(
    private readonly formatNamePipe: PackageUserFormatNamePipe,
    private readonly store: Store
  ) {}

  ngOnInit(): void {
    this.signingAgent$ = this.store.select(PackageUsersSelectors.getSigningAgent).pipe(
      filter((signingAgent) => !!signingAgent),
      tap((signingAgent) => {
        this.store.dispatch(
          CheckInActions.SetUserCheckInSequence({ payload: [signingAgent.packageUserGuid] })
        );
      }),
      map((sa) => ({ ...sa, displayName: this.formatNamePipe.transform(sa) }))
    );

    this.countNonSasCheckingIn$ = this.store.select(
      PackageUsersSelectors.selectNonSigningAgentsCheckingInCount
    );

    this.totalNonSas$ = this.store.select(PackageUsersSelectors.selectNonSigningAgentsCount);

    this.isSaSelected$ = combineLatest([
      this.signingAgent$,
      this.store.select(CheckInSelectors.getUserCheckInSequence),
    ]).pipe(
      map(([signingAgent, users]) =>
        users?.some((userGuid) => userGuid === signingAgent.packageUserGuid)
      )
    );

    this.isSaCheckedIn$ = this.store.select(PackageUsersSelectors.selectIsSigningAgentCheckedIn);
  }

  beginCheckIn() {
    this.store.dispatch(CheckInActions.BeginCheckIn());
  }

  goToWaitingRoom() {
    this.store.dispatch(
      CheckInActions.SetNextStep({ payload: { nextStep: NextStep.WaitingRoom } })
    );
    this.store.dispatch(CheckInActions.RedirectToAvCheckIfRequired());
    this.store.dispatch(DeviceGroupActions.SaveNotaryToDeviceGroup());
  }
}
