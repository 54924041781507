import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { TypedAction } from '@ngrx/store/src/models';
import { of } from 'rxjs';
import {
  catchError,
  concatMap,
  exhaustMap,
  filter,
  map,
  switchMap,
  withLatestFrom,
} from 'rxjs/operators';

import { CommissionSelectionActions } from 'src/app/features/commissions/store/actions';
import { DeviceGroupSelectors } from 'src/app/features/device-group/store';
import { Feature } from 'src/app/features/feature-management/models';
import { FeatureManagementService } from 'src/app/features/feature-management/services';
import { ModalsActions } from 'src/app/features/modals';
import { NotificationsActions, NotificationType } from 'src/app/features/notifications';
import { ExceptionType } from 'src/app/features/notifications/models';
import {
  PackageUsersActions,
  PackageUsersSelectors,
  PackageUsersService,
} from 'src/app/features/package-users';
import { RonActions } from 'src/app/features/ron/store/actions';
import { SignalRActions } from 'src/app/features/signal-r';
import { SigningRoomActions } from 'src/app/features/signing-room/store/actions';
import { VideoActions } from 'src/app/features/video/store/actions';
import { WizardSelectors } from 'src/app/features/wizard/store/selectors';
import { RootStoreState } from 'src/app/store';
import { v4 as uuid } from 'uuid';

import {
  BeginSessionRequest,
  PackageOptOutRequest,
  PackageScheduleStatus,
  PackageStatus,
  ProductType,
} from '../../models';
import { PackagesService } from '../../services';
import { PackagesActions } from '../actions';
import { PackagesSelectors } from '../selectors';
import { Guid } from 'guid-typescript';

//#endregion imports

@Injectable()
export class PackagesEffects {
  msalAuthLoginSuccessful$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PackageUsersActions.SetActivePackageUserGuid),
      switchMap(() => of(PackagesActions.GetPackage({ ignoreIfLoaded: true })))
    )
  );

  setActivePackageAndPackageUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PackageUsersActions.SetActivePackageAndUserGuids),
      switchMap((action) =>
        of(
          PackagesActions.GetPackageByGuid({
            payload: { packageGuid: action.payload.activePackageGuid },
          })
        )
      )
    )
  );

  configureListeners$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SignalRActions.SignalRRoomJoined),
        map(() => {
          this.packagesService.configureListeners();
        })
      ),
    { dispatch: false }
  );

  verifyPackage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PackagesActions.ValidatePackage),
      concatLatestFrom(() => this.store.select(PackagesSelectors.getActivePackageGuid)),
      exhaustMap(([action, currentActivePackage]) => {
        return this.packagesService.getPackageValidation().pipe(
          switchMap((packageValidationResult) => {
            const queryParams = packageValidationResult.isUserSigningAgent
              ? {
                  package: packageValidationResult.packageGuid,
                  user: packageValidationResult.packageUserGuid,
                }
              : { package: packageValidationResult.packageGuid };
            let successfulOperation = false;
            const resultActions: Array<any> = [
              PackagesActions.ValidatePackageSuccessful({
                payload: packageValidationResult,
              }),
            ];

            const isClientHintsEnabled =
              this.featureManagementService.getIsFeatureEnabledWithCaching(Feature.ClientHints);

            // TODO Story 61780: Remove all the feature flags once the client hints are enabled for all users
            if (!packageValidationResult.isSupportedOnDevice && !isClientHintsEnabled) {
              resultActions.push(PackagesActions.RedirectUnsupportedDevice({ queryParams }));
            } else if (
              !packageValidationResult.isOperatingSystemSupported &&
              !isClientHintsEnabled
            ) {
              resultActions.push(
                PackagesActions.RedirectUnsupportedOperatingSystem({
                  queryParams,
                })
              );
            } else if (!packageValidationResult.isSupportedInBrowser && !isClientHintsEnabled) {
              resultActions.push(PackagesActions.RedirectUnsupportedBrowser({ queryParams }));
            } else if (
              packageValidationResult.scheduleStatus !== PackageScheduleStatus.CURRENT &&
              !isClientHintsEnabled
            ) {
              resultActions.push(
                PackagesActions.RedirectInvalidPackageScheduleStatus({
                  queryParams,
                })
              );
            } else if (!packageValidationResult.isValid) {
              resultActions.push(PackagesActions.RedirectInvalidUrl({ queryParams }));
            } else if (!packageValidationResult.isUserAuthorized) {
              resultActions.push(PackagesActions.RedirectUnAuthorized({ queryParams }));
            } else {
              resultActions.push(
                PackagesActions.SetIsPreSign({
                  payload: { isPreSign: false },
                })
              );
              resultActions.push(
                PackageUsersActions.SetActivePackageAndUserGuids({
                  payload: {
                    activePackageGuid: packageValidationResult.packageGuid,
                    activePackageUserGuid: packageValidationResult.packageUserGuid,
                  },
                })
              );
              successfulOperation = true;
            }

            if (packageValidationResult.packageGuid !== currentActivePackage) {
              resultActions.push(PackagesActions.PackageGuidChanged());
            }

            resultActions.push(
              PackagesActions.SetPackageIsValid({
                payload: { isValid: successfulOperation },
              })
            );

            return resultActions;
          }),
          catchError((error: HttpErrorResponse) => {
            const queryParams = {
              package: action.payload.packageGuid,
              user: action.payload.packageUserGuid,
            };

            if (this.isUnauthorizedOrForbidden(error)) {
              return [PackagesActions.RedirectUnAuthorized({ queryParams })];
            } else {
              return [
                PackagesActions.RedirectInvalidUrl({ queryParams }),
                NotificationsActions.AddNotification({
                  payload: {
                    exception: new Error(error.error),
                    notificationType: NotificationType.Error,
                    id: uuid(),
                    text: 'Failed to fetch package validation',
                    exceptionType: ExceptionType.Other,
                  },
                }),
              ];
            }
          })
        );
      })
    )
  );

  getPackages$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PackagesActions.GetPackage),
      concatMap((action) =>
        of(action).pipe(
          withLatestFrom(
            this.store.pipe(select(PackageUsersSelectors.getActivePackageUserGuid)),
            this.store.pipe(select(PackagesSelectors.getPackage))
          )
        )
      ),
      switchMap(([action, activePackageUserGuid, currentPackage]) => {
        if (action.payload?.ignoreIfLoaded && !!currentPackage) {
          return [];
        }
        return this.packagesService.getPackage(activePackageUserGuid).pipe(
          map((payload) => PackagesActions.SetPackage({ payload })),
          catchError((err) =>
            of(
              NotificationsActions.AddNotification({
                payload: {
                  exception: err,
                  notificationType: NotificationType.Error,
                  id: uuid(),
                  text: 'Failed to get Packages',
                  exceptionType: ExceptionType.ReloadRetry,
                },
              })
            )
          )
        );
      })
    )
  );

  getPackageByActivePackageGuid$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PackagesActions.GetPackageByActivePackageGuid),
      concatMap((action) =>
        of(action).pipe(
          withLatestFrom(
            this.store.pipe(select(PackagesSelectors.getActivePackageGuid)),
            this.store.pipe(select(PackagesSelectors.getPackage))
          )
        )
      ),
      switchMap(([action, activePackageGuid, activePackage]) => {
        if (action.payload?.ignoreIfLoaded && activePackage?.packageGuid === activePackageGuid) {
          return [];
        }
        return of(
          PackagesActions.GetPackageByGuid({
            payload: { packageGuid: activePackageGuid },
          })
        );
      })
    )
  );

  fetchPackageUsers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PackageUsersActions.FetchPackageUsers),
      concatMap((action) =>
        of(action).pipe(
          withLatestFrom(
            this.store.pipe(select(PackagesSelectors.getActivePackageGuid)),
            this.store.pipe(select(PackageUsersSelectors.getPackageUsers))
          )
        )
      ),
      switchMap(([action, activePackageGuid, packageUsers]) => {
        if (action.payload?.ignoreIfLoaded && packageUsers.length !== 0) {
          return [];
        }
        return this.packageUserService.getPackageUsers(activePackageGuid).pipe(
          switchMap((payload) => [
            PackageUsersActions.FetchPackageUsersSuccessful({
              payload: { packageUsers: payload },
            }),
            PackageUsersActions.FetchPackageUserCheckInStatuses(),
          ]),
          catchError((err) =>
            of(
              NotificationsActions.AddNotification({
                payload: {
                  exception: err,
                  notificationType: NotificationType.Error,
                  id: uuid(),
                  text: 'Failed to load package users',
                  exceptionType: ExceptionType.CannotProceed,
                },
              })
            )
          )
        );
      })
    )
  );

  getPackageByGuid$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PackagesActions.GetPackageByGuid),
      switchMap((action) => {
        return this.packagesService.getPackageByGuid(action.payload.packageGuid).pipe(
          switchMap((payload) => [PackagesActions.SetPackage({ payload })]),
          catchError((err) =>
            of(
              NotificationsActions.AddNotification({
                payload: {
                  exception: err,
                  notificationType: NotificationType.Error,
                  id: uuid(),
                  text: 'Failed to get Packages',
                  exceptionType: ExceptionType.ReloadRetry,
                },
              })
            )
          )
        );
      })
    )
  );

  optOutLoadingSpinner$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PackagesActions.OptOutPackage),
      map((action) => ModalsActions.ShowLoadingSpinner())
    )
  );

  optOutPackage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PackagesActions.OptOutPackage),
      concatMap((action) =>
        of(action).pipe(
          withLatestFrom(
            this.store.pipe(select(PackagesSelectors.getActivePackageGuid)),
            this.store.pipe(select(PackageUsersSelectors.getActivePackageUserGuid)),
            this.store.pipe(select(WizardSelectors.getActiveWizardUserGuid)),
            this.store.pipe(select(DeviceGroupSelectors.getDeviceCode)),
            this.store.pipe(select(PackagesSelectors.getProductType)),
            this.store.pipe(select(DeviceGroupSelectors.sharedDevice))
          )
        )
      ),
      switchMap(
        ([
          action,
          packageGuid,
          packageUserGuid,
          wizardUserGuid,
          deviceCode,
          productType,
          isSharedDevice,
        ]) =>
          this.packagesService
            .optOutPackage(
              packageGuid,
              wizardUserGuid,
              packageUserGuid,
              deviceCode,
              productType,
              isSharedDevice,
              action.payload.categoryTypeCode,
              action.payload.comments
            )
            .pipe(
              switchMap(() => [
                ModalsActions.HideLoadingSpinner(),
                VideoActions.StopVideo(),
                PackagesActions.RedirectOrderCancelled(),
                PackagesActions.SetCancelPackage(),
              ]),
              catchError((err) => [
                ModalsActions.HideLoadingSpinner(),
                NotificationsActions.AddNotification({
                  payload: {
                    exception: err,
                    notificationType: NotificationType.Error,
                    id: uuid(),
                    text: 'Failed to opt out Packages',
                    exceptionType: ExceptionType.CannotProceed,
                  },
                }),
              ])
            )
      )
    )
  );

  cancelLoadingSpinner$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PackagesActions.CancelPackage),
      map((action) => ModalsActions.ShowLoadingSpinner())
    )
  );

  cancelPackage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PackagesActions.CancelPackage),
      concatMap((action) =>
        of(action).pipe(
          withLatestFrom(this.store.pipe(select(PackagesSelectors.getActivePackageGuid)))
        )
      ),
      switchMap(([action, packageGuid]) =>
        this.packagesService.cancelPackage(packageGuid, action.payload.categoryTypeCode).pipe(
          switchMap(() => [
            ModalsActions.HideLoadingSpinner(),
            PackagesActions.RedirectOrderCancelled(),
            PackagesActions.SetCancelPackage(),
            VideoActions.StopVideo(),
          ]),
          catchError((err) => [
            ModalsActions.HideLoadingSpinner(),
            NotificationsActions.AddNotification({
              payload: {
                exception: err,
                notificationType: NotificationType.Error,
                id: uuid(),
                text: 'Failed to cancel Package',
                exceptionType: ExceptionType.CannotProceed,
              },
            }),
          ])
        )
      )
    )
  );

  fetchOptOutReasons$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PackagesActions.FetchOptOutReasons),
      switchMap((action) => {
        return this.packagesService.getOptOutReasons().pipe(
          switchMap((payload: any) => [PackagesActions.FetchOptOutReasonsSuccess({ payload })]),
          catchError(() =>
            of(
              NotificationsActions.AddNotification({
                payload: {
                  notificationType: NotificationType.Error,
                  id: uuid(),
                  text: 'Failed to get Opt Out Reasons',
                  exceptionType: ExceptionType.ReloadRetry,
                },
              })
            )
          )
        );
      })
    )
  );

  fetchPackageScheduledStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PackagesActions.FetchPackageScheduledStatus),
      withLatestFrom(
        this.store.pipe(select(PackagesSelectors.getActivePackageGuid)),
        this.store.pipe(select(PackageUsersSelectors.getActivePackageUserGuid))
      ),
      switchMap(([action, activePackageGuid, activePackageUserGuid]) =>
        this.packagesService
          .getPackageScheduledStatus(action.payload?.packageGuid ?? activePackageGuid)
          .pipe(
            switchMap((result) => [
              PackagesActions.FetchPackageScheduledStatusSuccess({
                payload: { statusCode: result.statusCode },
              }),
            ]),
            catchError((error: HttpErrorResponse) => {
              if (error.status === 401 || error.status === 403) {
                return [
                  PackagesActions.RedirectUnAuthorized({
                    queryParams: {
                      package: action.payload?.packageGuid ?? activePackageGuid,
                      user: action.payload?.user ?? activePackageUserGuid,
                    },
                  }),
                ];
              } else {
                return [
                  NotificationsActions.AddNotification({
                    payload: {
                      exception: new Error(error.error),
                      notificationType: NotificationType.Error,
                      id: uuid(),
                      text: 'Failed to fetch package schedule status',
                      exceptionType: ExceptionType.Other,
                    },
                  }),
                ];
              }
            })
          )
      )
    )
  );

  fetchPackageStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PackagesActions.GetPackageStatus),
      withLatestFrom(
        this.store.pipe(select(PackagesSelectors.getActivePackageGuid)),
        this.store.pipe(select(PackagesSelectors.getProductType))
      ),
      switchMap(([_, activePackageGuid, productType]) => {
        return this.packagesService.getPackageStatus(activePackageGuid).pipe(
          filter(({ statusCode }) => statusCode != null && statusCode in PackageStatus),
          switchMap(({ statusCode }) => {
            if (
              statusCode === PackageStatus.CANCELLED ||
              statusCode === PackageStatus.DELETED ||
              statusCode === PackageStatus.OPTOUT
            ) {
              return [
                PackagesActions.UpdatePackageStatus({
                  payload: { status: statusCode },
                }),
                PackagesActions.SetCancelPackage(),
                PackagesActions.RedirectOrderCancelled(),
              ];
            } else if (
              statusCode === PackageStatus.SESSIONCOMPLETE ||
              statusCode === PackageStatus.COMPLETE
            ) {
              return this.getActionsForSigningComplete(statusCode, productType);
            }
            return [
              PackagesActions.UpdatePackageStatus({
                payload: { status: statusCode },
              }),
            ];
          }),
          catchError((error: HttpErrorResponse) => {
            // skip logging for non-authed reqs, this is a long polling endpoints and runs before non-SAs are authed
            if (error && error.status === 403) {
              return [];
            }
            return [
              NotificationsActions.AddNotification({
                payload: {
                  exception: new Error(error.error),
                  notificationType: NotificationType.Error,
                  id: uuid(),
                  text: 'Failed to fetch package status',
                  exceptionType: ExceptionType.Other,
                },
              }),
            ];
          })
        );
      })
    )
  );

  private getActionsForSigningComplete(statusCode: PackageStatus, productType: ProductType) {
    const actions: TypedAction<any>[] = [
      PackagesActions.UpdatePackageStatus({
        payload: { status: statusCode },
      }),
      SigningRoomActions.SigningSessionCompleted(),
      PackagesActions.SetCompletePackage(),
    ];

    if (statusCode === PackageStatus.COMPLETE) {
      actions.push(PackagesActions.SetEndSessionSuccessful());
    }

    if (productType === ProductType.RemoteSigning) {
      actions.push(RonActions.NavigateToSessionEnding());
    }

    return actions;
  }

  beginSession$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PackagesActions.BeginSession),
      concatMap((action) =>
        of(action).pipe(
          withLatestFrom(this.store.pipe(select(PackagesSelectors.getActivePackageGuid)))
        )
      ),
      switchMap(([action, packageGuid]) =>
        this.packagesService
          .beginSession({
            packageGuid: packageGuid,
          } as BeginSessionRequest)
          .pipe(
            switchMap(() => []),
            catchError((err) =>
              of(
                NotificationsActions.AddNotification({
                  payload: {
                    exception: err,
                    notificationType: NotificationType.Error,
                    id: uuid(),
                    text: 'Failed to create Session',
                    exceptionType: ExceptionType.Other,
                  },
                })
              )
            )
          )
      )
    )
  );

  setEndSessionSuccessful = createEffect(() =>
    this.actions$.pipe(
      ofType(PackagesActions.SetEndSessionSuccessful),
      withLatestFrom(
        this.store.pipe(select(PackagesSelectors.getActivePackageGuid)),
        this.store.pipe(select(PackageUsersSelectors.getActivePackageUserGuid)),
        this.store.pipe(select(DeviceGroupSelectors.getDeviceCode))
      ),
      switchMap(([_, activePackageGuid, activePackageUserGuid, deviceCode]) =>
        this.packagesService
          .postSessionPackageCompleted(activePackageGuid, activePackageUserGuid, deviceCode)
          .pipe(
            switchMap(() => []),
            catchError((error: HttpErrorResponse) => {
              return [
                NotificationsActions.AddNotification({
                  payload: {
                    exception: new Error(error.error),
                    notificationType: NotificationType.Error,
                    id: uuid(),
                    text: 'Failed to post session package completed',
                    exceptionType: ExceptionType.Other,
                  },
                }),
              ];
            })
          )
      )
    )
  );

  broadCastSigningCancelled$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PackagesActions.BroadCastSigningCancelled),
      concatMap((action) =>
        of(action).pipe(
          withLatestFrom(
            this.store.pipe(
              select(DeviceGroupSelectors.isUserOnDevice(action.payload.packageUserGuid))
            )
          )
        )
      ),
      switchMap(([action, isUserOnDevice]) => {
        if (isUserOnDevice) {
          return [];
        }
        return [
          ModalsActions.ClearModalComponent(),
          VideoActions.StopVideo(),
          PackagesActions.SetCancelPackage(),
          PackagesActions.RedirectOrderCancelled(),
        ];
      })
    )
  );

  broadCastNotaryPasswordAttemptsExhausted$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PackagesActions.BroadCastNotaryPasswordAttemptsExhausted),
      concatMap((action) =>
        of(action).pipe(
          withLatestFrom(
            this.store.pipe(
              select(DeviceGroupSelectors.isUserOnDevice(action.payload.packageUserGuid))
            )
          )
        )
      ),
      switchMap(([action, isUserOnDevice]) => {
        if (isUserOnDevice) {
          return [];
        }
        return [
          ModalsActions.ClearModalComponent(),
          CommissionSelectionActions.ShowSACertificatePasswordLimitExceeded(),
        ];
      })
    )
  );

  getMonthlyClosings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PackagesActions.GetMonthlyClosings),
      switchMap((action) => {
        return this.packagesService.getMonthlyClosingsCount().pipe(
          switchMap(({ totalSuccessfulClosings }) => [
            PackagesActions.SetMonthlyClosings({
              payload: { totalMonthlyClosings: totalSuccessfulClosings },
            }),
          ]),
          catchError(() =>
            of(
              NotificationsActions.AddNotification({
                payload: {
                  notificationType: NotificationType.Error,
                  id: uuid(),
                  text: 'Failed to get total monthly closings',
                  exceptionType: ExceptionType.Other,
                },
              })
            )
          )
        );
      })
    )
  );

  optOut$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ModalsActions.OptOut),
      concatLatestFrom(() => [
        this.store.select(PackagesSelectors.getActivePackageGuid),
        this.store.select(PackageUsersSelectors.getActivePackageUserGuid),
        this.store.select(WizardSelectors.getActiveWizardUserGuid),
        this.store.select(DeviceGroupSelectors.getDeviceCode),
        this.store.select(PackagesSelectors.isRON),
        this.store.select(DeviceGroupSelectors.sharedDevice),
      ]),
      map(([action, packageGuid, packageUserGuid, wizardUserGuid, deviceCode, isRON, isSharedDevice]) => {
        const request: PackageOptOutRequest = {
          packageGuid: packageGuid,
          packageUserGuid: packageUserGuid ? packageUserGuid : wizardUserGuid,
          deviceCode: deviceCode,
          categoryTypeCode: action.categoryTypeCode,
          comments: action.comments,
        };

        if ((isSharedDevice || !request.packageUserGuid) && isRON) {
          return { ...request, packageUserGuid: Guid.EMPTY };
        }

        return request;
      }),
      switchMap((request) => {
        return this.packagesService.optOut(request).pipe(
          map(() => PackagesActions.OptOutSuccess()),
          catchError((error: Error) => of(PackagesActions.OptOutFailure({ error: error, text: "Failed to opt out" })))
        );
      })
    );
  });

  showCannotProceedNotification$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(PackagesActions.OptOutFailure),
      map((action) =>
        NotificationsActions.AddNotification({
          payload: {
            id: uuid(),
            exception: action.error,
            notificationType: NotificationType.Error,
            exceptionType: ExceptionType.CannotProceed,
            text: action.text,
          },
        })
      )
    );
  });

  constructor(
    private readonly actions$: Actions,
    private readonly packagesService: PackagesService,
    private readonly packageUserService: PackageUsersService,
    private readonly store: Store<RootStoreState.State>,
    private readonly featureManagementService: FeatureManagementService
  ) {}

  isUnauthorizedOrForbidden(error: HttpErrorResponse) {
    return error.status === 401 || error.status === 403;
  }
}
