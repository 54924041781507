export enum RecordingStatus {
  Unknown = "Unknown",
  Failed = "Failed",
  Initializing = "Initializing",
  InProgress = "InProgress",
  NotStarted = "NotStarted",
  Paused = "Paused",
  Stopped = "Stopped",
  Stopping = "Stopping",
}
