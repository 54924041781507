import { DOCUMENT } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { CancelSigningModalComponent } from 'src/app/features/layout/components/cancel-signing-modal';
import { ModalsActions } from 'src/app/features/modals';
import { DEFAULT_APPLICATION_PHONE_NUMBER } from 'src/app/features/shared/constants';
import { RootStoreState } from 'src/app/store';

@Component({
  selector: 'app-waiting-on-other-participants-modal',
  templateUrl: './waiting-on-other-participants-modal.component.html',
  styleUrls: ['./waiting-on-other-participants-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WaitingOnOtherParticipantsModalComponent implements OnInit {
  static identifier = 'WaitingOnOtherParticipantsModalComponent';
  public phoneNumber: string;

  constructor(
    private readonly store: Store<RootStoreState.State>,
    @Inject(DOCUMENT) private readonly document: Document
  ) {}
  ngOnInit(): void {
    this.phoneNumber = DEFAULT_APPLICATION_PHONE_NUMBER;
  }

  cancel() {
    this.store.dispatch(ModalsActions.ClearModalComponent());
    this.store.dispatch(
      ModalsActions.SetStandaloneModalComponent({
        payload: {
          component: CancelSigningModalComponent,
        },
      })
    );
  }

  refresh() {
    this.store.dispatch(ModalsActions.ClearModalComponent());
    this.document.defaultView.location.reload();
  }
}
