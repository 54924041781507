import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';

import { combineLatest, Observable } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';

import { ModalsActions, ModalSize } from 'src/app/features/modals';
import { PackageUsersSelectors } from 'src/app/features/package-users';
import { getFullNames, PackageUserWithDisplayName } from 'src/app/features/package-users/util';
import { RootStoreState } from 'src/app/store';

import { CheckInSelectors } from '../../store';
import { NotaryModalComponent } from '../notary-modal';

@Component({
  selector: 'app-add-participant-modal',
  templateUrl: './add-participant-modal.component.html',
  styleUrls: ['./add-participant-modal.component.scss'],
})
export class AddParticipantModalComponent implements OnInit, OnDestroy {
  static identifier = 'AddParticipantModalComponent';

  @Input() isInWaitingRoom = false;

  nonSigningAgentsEligibleForCheckIn$: Observable<PackageUserWithDisplayName[]>;
  shouldDisableButton$: Observable<boolean>;

  constructor(private readonly store: Store<RootStoreState.State>) {}

  ngOnInit(): void {
    this.nonSigningAgentsEligibleForCheckIn$ = this.store
      .select(PackageUsersSelectors.getNonSigningAgents)
      .pipe(
        map((nonSAs) => nonSAs.filter((nonSA) => !nonSA.checkInStatus.isCompleted)),
        filter((nonSAs) => !!nonSAs && nonSAs.length > 0),
        take(1),
        map((nonSAs) => getFullNames(nonSAs))
      );

    this.shouldDisableButton$ = combineLatest([
      this.nonSigningAgentsEligibleForCheckIn$.pipe(
        map((nonSAs) => nonSAs.map((nonSA) => nonSA.packageUserGuid))
      ),
      this.store.pipe(select(CheckInSelectors.getUserCheckInSequence)),
    ]).pipe(
      map(
        ([nonSAs, usersInCheckInSequence]) =>
          !nonSAs.some((nonSA) => usersInCheckInSequence.includes(nonSA))
      )
    );
  }

  back() {
    this.store.dispatch(ModalsActions.ClearModalComponent());
  }

  beginCheckIn() {
    this.store.dispatch(
      ModalsActions.SetStandaloneModalComponent({
        payload: {
          component: NotaryModalComponent,
          modalSize: ModalSize.fitContent,
        },
      })
    );
  }

  ngOnDestroy() {
    this.store.dispatch(
      ModalsActions.ClearModalComponentIfOpen({
        payload: { component: AddParticipantModalComponent },
      })
    );
  }
}
