import { createFeatureSelector, createSelector } from '@ngrx/store';

import { PackageScheduleStatus, ProductType } from '../../models';
import { PRODUCT_TYPE_ROUTE_MAP } from '../../packages.constants';
import { PackagesState } from '../state';

export const getPackagesState = createFeatureSelector<PackagesState.State>('packages');

export const getPackage = createSelector(
  getPackagesState,
  (state: PackagesState.State) => state.package
);

export const getActivePackageGuid = createSelector(
  getPackagesState,
  (state: PackagesState.State) => state.activePackageGuid
);

export const getActivePackageId = createSelector(
  getPackagesState,
  (state: PackagesState.State) => state.activePackageId
);

export const getActivePackageState = createSelector(
  getPackagesState,
  (state: PackagesState.State) => state.activePackageState
);

export const getActivePackageStatus = createSelector(
  getPackagesState,
  (state: PackagesState.State) => state.activePackageStatus
);

export const getTotalMonthlyClosings = createSelector(
  getPackagesState,
  (state: PackagesState.State) => state.totalMonthlyClosings
);

export const getProductType = createSelector(
  getPackagesState,
  (state: PackagesState.State) => state.productType
);

export const getProductRoute = createSelector(getProductType, (productType: ProductType) =>
  PRODUCT_TYPE_ROUTE_MAP.get(productType)
);

export const getPackageScheduleStatus = createSelector(
  getPackagesState,
  (state: PackagesState.State) => state.scheduleStatus
);

export const packageCompleteOrCancelled = createSelector(
  getPackagesState,
  (state: PackagesState.State) =>
    state.scheduleStatus === PackageScheduleStatus.COMPLETE ||
    state.scheduleStatus === PackageScheduleStatus.CANCELLED
);

export const isPackageValid = createSelector(
  getPackagesState,
  (state: PackagesState.State) => state.isValid
);

export const isPreSign = createSelector(
  getPackagesState,
  (state: PackagesState.State) => state.isPreSign
);

export const isRONOrKRON = createSelector(
  getPackagesState,
  (state: PackagesState.State) =>
    state?.productType === ProductType.RemoteSigning || state.productType === ProductType.KRON
);

export const isRON = createSelector(
  getPackagesState,
  (state: PackagesState.State) => state?.productType === ProductType.RemoteSigning
);

export const getOptOutReasons = createSelector(
  getPackagesState,
  (state: PackagesState.State) => state.optOutReasons
);

export const getClientId = createSelector(
  getPackagesState,
  (state: PackagesState.State) => state.package.clientId
);
