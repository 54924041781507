import { IdVerification } from '../../models';

export interface State {
  allSignersApproved: boolean;
  idVerifications: IdVerification[];
  approvedPackageUserGuids: string[];
  rejoinedUsers: {packageUserGuid: string, deviceCode: string}[];
  isAllSignersApprovedInfoLoaded: boolean;
}

export const initialState: State = {
  allSignersApproved: false,
  approvedPackageUserGuids: [],
  rejoinedUsers: [],
  idVerifications: null,
  isAllSignersApprovedInfoLoaded: false,
};
