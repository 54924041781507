import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Store, select } from '@ngrx/store';

import { RootStoreState } from 'src/app/store';
import { EndorsementsSelectors } from '../../store';

@Component({
  selector: 'app-required-stamp-count',
  templateUrl: './required-stamp-count.component.html',
  styleUrls: ['./required-stamp-count.component.scss'],
})
export class RequiredStampCountComponent implements OnInit {
  requiredStampTotal$: Observable<number>;
  constructor(private readonly store: Store<RootStoreState.State>) {}

  ngOnInit(): void {
    this.requiredStampTotal$ = this.store.pipe(
      select(EndorsementsSelectors.selectActiveDocumentRemainingStamp)
    );
  }
}
