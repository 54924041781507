import { Component, ElementRef, Input, ViewChild } from '@angular/core';

@Component({
  selector: 'app-collapsible',
  templateUrl: './collapsible.component.html',
  styleUrls: ['./collapsible.component.scss'],
})
export class CollapsibleComponent {
  @ViewChild('titleBlock') titleBlock: ElementRef;

  @Input() dataTestId: string;
  @Input() title: string;
  @Input() type: string;

  @Input() isOpen = false;

  get classes() {
    return [
      'collapsible',
      'collapsible--' + this.type,
      'collapsible--' + (this.isOpen ? 'open' : 'collapsed'),
    ].filter(c => c)
    .join(' ');
  }

  get collapsedIcon() {
    return this.type === 'parent' ? 'expand-more' : 'chevron-circle-down';
  }

  get openIcon() {
    return this.type === 'parent' ? 'expand-less' : 'chevron-circle-up';
  }
  get iconSize() {
    return this.type === 'parent' ? '16' : '30';
  }

  onToggle() {
    this.isOpen = !this.isOpen;
  }
}
