<span
  *ngIf="
    (endorsementStatus | async)?.completedActions <
    (endorsementStatus | async)?.totalActions
  "
  data-testid="endorsement-status"
  >Actions {{ (endorsementStatus | async)?.completedActions }} of
  {{ (endorsementStatus | async)?.totalActions }}</span
>
<span
  class="completed"
  *ngIf="
    (endorsementStatus | async)?.completedActions ===
      (endorsementStatus | async)?.totalActions &&
    (endorsementStatus | async)?.totalActions > 0
  "
  data-testid="endorsement-status-complete"
  >Complete

  <span class="completed__icon"><span class="fas fa-check fa-xs"></span></span>
</span>
<span *ngIf="(endorsementStatus | async)?.totalActions === 0" class="no-action-needed">No Actions Needed</span>
