import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  Output,
  ViewEncapsulation,
} from '@angular/core';

@Component({
  selector: 'app-scrollable-text-box',
  templateUrl: './scrollable-text-box.component.html',
  styleUrls: ['./scrollable-text-box.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ScrollableTextBoxComponent {
  @Input() contentMarkup: string;
  @Output() completed = new EventEmitter();

  @HostListener('scroll', ['$event'])
  onScroll(event: any) {
    if (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight) {
      this.completed.emit();
    }
  }

  @HostListener('click', ['$event'])
  clickin(event: any) {
    const fragment = event?.target?.hash;
    if (!!fragment) {
      event.preventDefault();
      const id = fragment.replace('#', '');
      document.getElementById(id).scrollIntoView({ behavior: 'smooth' });
    }
  }
}
