import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import {
  NavigationEnd,
  Router
} from '@angular/router';

@Injectable({ providedIn: 'root' })
export class NavigationService {
  private readonly history: string[] = [];

  constructor(private readonly router: Router, private readonly location: Location) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.history.push(event.urlAfterRedirects);
      }
    });
  }

  back(): void {
    this.history.pop();
    if (this.history.length > 0) {
      this.location.back();
    } else {
      this.router.navigateByUrl('/');
    }
  }

  peekHistory(): string {
    if (this.history.length === 0) {
      return null;
    }
    return this.history[this.history.length - 1];
  }
}
