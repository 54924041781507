import {
  Action,
  createReducer,
  on
} from '@ngrx/store';

import { UserTasksActions } from '../actions';
import { UserTasksState } from '../state';

const reducer = createReducer(
  UserTasksState.initialState,
  on(
    UserTasksActions.SetActiveUserTasks,
    (state: UserTasksState.State, { payload }) => ({
      ...state,
      activeUserTasks: payload,
    })
  ),
  on(
    UserTasksActions.ClearActiveUserTasks,
    (state: UserTasksState.State) => ({
      ...state,
      activeUserTasks: null,
    })
  )
);

export function userTasksReducer(
  state: UserTasksState.State | undefined,
  action: Action
) {
  return reducer(state, action);
}
