import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { filter, tap } from 'rxjs/operators';

import { PackageUser } from 'src/app/features/package-users';
import { RootStoreState } from 'src/app/store';

import { MultiFactorChallengeOptions } from '../../models';
import { MultiFactorActions } from '../../store/actions';
import { MultiFactorSelectors } from '../../store/selectors';

@Component({
  selector: 'app-multi-factor-challenge-options',
  templateUrl: './multi-factor-challenge-options.component.html',
  styleUrls: ['./multi-factor-challenge-options.component.scss'],
})
export class MultiFactorChallengeOptionsComponent implements OnInit, OnDestroy {
  @Input() public user: PackageUser;
  public errorMessage$: Observable<string>;

  public multiFactorChallengeOptions = MultiFactorChallengeOptions;
  public selectedMultiFactorChallenge: MultiFactorChallengeOptions =
    MultiFactorChallengeOptions.SMS;

  private selectedMultiFactorChallengeSubscription: Subscription;

  constructor(private readonly store: Store<RootStoreState.State>) {}

  ngOnInit(): void {
    this.selectedMultiFactorChallengeSubscription = this.store
      .pipe(
        select(MultiFactorSelectors.getCurrentlySelectedChallengeType),
        filter((selectedChallengeType) => !!selectedChallengeType),
        tap((selectedChallengeType) => (this.selectedMultiFactorChallenge = selectedChallengeType))
      )
      .subscribe();

    this.errorMessage$ = this.store.pipe(select(MultiFactorSelectors.getErrorMessage));
  }

  ngOnDestroy(): void {
    this.selectedMultiFactorChallengeSubscription?.unsubscribe();
  }

  selectMultiFactorChallenge(selectedMultiFactorChallenge: MultiFactorChallengeOptions) {
    this.selectedMultiFactorChallenge = selectedMultiFactorChallenge;
    this.store.dispatch(
      MultiFactorActions.MultiFactorChallengeSelected({
        payload: { selectedChallengeType: selectedMultiFactorChallenge },
      })
    );
  }

  saveSelection() {
    this.store.dispatch(
      MultiFactorActions.CreateMultiFactorChallenge({
        payload: {
          packageUserGuid: this.user.packageUserGuid,
        },
      })
    );
  }
}
