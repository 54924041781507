import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';

import { PackageUserEndorsementImages } from '../../models';

export function selectEndorsementImagePackageUserGuid(
  packageUserEndorsementImages: PackageUserEndorsementImages
): string {
  return packageUserEndorsementImages.packageUserGuid;
}

export const endorsementImagesAdapter: EntityAdapter<PackageUserEndorsementImages> = createEntityAdapter<
  PackageUserEndorsementImages
>({
  selectId: selectEndorsementImagePackageUserGuid,
  sortComparer: false,
});
export interface EndorsementImagesState
  extends EntityState<PackageUserEndorsementImages> {}

const {
  selectEntities,
  selectAll,
  selectIds,
  selectTotal,
} = endorsementImagesAdapter.getSelectors();
export const selectPackageUserEndorsementImageEntities = selectEntities;
export const selectAllPackageUserEndorsementImages = selectAll;
export const selectPackageUserEndorsementImageIds = selectIds;
