import { createAction, props } from '@ngrx/store';

export const LandingPageViewed = createAction('[Audit Logs] Landing Page Viewed');

export const PreSignDashboardViewed = createAction('[Audit Logs] PreSign Dashboard Viewed');

export const SaveReadyUpEvent = createAction(
  '[Audit Logs] Save READYUP Event',
  props<{ payload: { packageUserGuid: string } }>()
);

export const SaveSuccessful = createAction('[Audit Logs] Audit Log save successful');

export const SaveFailed = createAction('[Audit Logs] Audit Log save failed');
