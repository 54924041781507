import { Component, Input, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';

import { RootStoreState } from 'src/app/store';
import { SignatureType } from '../../models';
import { Observable } from 'rxjs';
import { EndorsementsCreationSelectors } from '../../store';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-signature-view',
  templateUrl: './signature-view.component.html',
  styleUrls: ['./signature-view.component.scss'],
})
export class SignatureViewComponent implements OnInit {
  @Input() signature: string;
  @Input() placeholderText = '';
  @Input() imageTypeProvided = false;
  @Input() dataTestId = '';

  isNotSelectedSignature$: Observable<boolean>;

  constructor(private readonly store: Store<RootStoreState.State>) {}

  ngOnInit() {
    this.isNotSelectedSignature$ = this.store.pipe(
      select(EndorsementsCreationSelectors.getSignatureType),
      map((signatureType) => signatureType !== SignatureType.Selected)
    );
  }
}
