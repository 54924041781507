import { CommissionModel, SignatureModel } from '../../models';

// The string value in Record<string, Rules> is either the defaultKeyName for the user role or
// the combination of property names that deviate from the default rules set for that userRole
// For example: stateCode-rulesType-countyFIPSCode
// More info can be found in the reducer of how this works in practice.
export interface State {
  SIGNINGAGENT: Record<string, SigningAgentRules>;
  BORROWER: Record<string, Rules>;
  NBTH: Record<string, Rules>;
  SELLER: Record<string, Rules>;
  SIGNER: Record<string, Rules>;
  WITNESS: Record<string, Rules>;
}

export const initialState: State = {
  SIGNINGAGENT: {},
  BORROWER: {},
  NBTH: {},
  SELLER: {},
  SIGNER: {},
  WITNESS: {},
};

export const defaultRulesKeyName = 'default';

export interface Rules {
  signatureModel: SignatureModel;
}

export interface SigningAgentRules extends Rules {
  commissionModel: CommissionModel;
}
