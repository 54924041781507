import { Directive, ElementRef, Input, OnInit } from '@angular/core';
import { tap } from 'rxjs/operators';

import { Feature } from '../models';
import { FeatureManagementService } from '../services';

@Directive({
  selector: '[appRemoveIfFeatureDisabled]'
})
export class RemoveIfFeatureDisableDirective implements OnInit {
  @Input('appRemoveIfFeatureDisabled') featureName: Feature;

  constructor(private readonly el: ElementRef,
    private readonly featureManagementService: FeatureManagementService) {
  }

  ngOnInit() {
    this.featureManagementService.getIsFeatureFlagEnabled(this.featureName)
    .pipe(
      tap((isEnabled)=> {
        if (!isEnabled){
          this.el.nativeElement.parentNode.removeChild(this.el.nativeElement);
        }
      })
    )
    .subscribe();
  }
}
