<div class="form-step__subheading">
  To confirm the information from the previous page, we will send you a security code. How would you
  like to receive your security code?
</div>

<form class="methods" *ngIf="!!this.user">
  <div class="input-container" *ngIf="this.user.phoneNumber !== null">
    <label class="container" data-testid="sms-option">
      <input
        type="radio"
        name="selectedChallengeType"
        (change)="selectMultiFactorChallenge($event.target.value)"
        [value]="multiFactorChallengeOptions.SMS"
        [checked]="selectedMultiFactorChallenge === multiFactorChallengeOptions.SMS"
      />
      <span> Text: (***) ***-{{ this.user.phoneNumber }} </span>
    </label>
  </div>
  <div class="input-container" *ngIf="this.user.phoneNumber !== null">
    <label class="container" data-testid="call-option">
      <input
        type="radio"
        name="selectedChallengeType"
        (change)="selectMultiFactorChallenge($event.target.value)"
        [value]="multiFactorChallengeOptions.PHONE"
        class="call-checkbox"
        [checked]="selectedMultiFactorChallenge === multiFactorChallengeOptions.PHONE"
      />
      <span> Call: (***) ***-{{ this.user.phoneNumber }} </span>
    </label>
  </div>
  <div class="input-container" *ngIf="this.user.emailAddress !== null">
    <label class="container" [title]="user.emailAddress" data-testid="email-option">
      <input
        type="radio"
        name="selectedChallengeType"
        (change)="selectMultiFactorChallenge($event.target.value)"
        [value]="multiFactorChallengeOptions.EMAIL"
        class="email-checkbox"
        [checked]="selectedMultiFactorChallenge === multiFactorChallengeOptions.EMAIL"
      />
      <span> Email: {{ this.user.emailAddress }} </span>
    </label>
  </div>

  <div class="error-message" *ngIf="errorMessage$ | async as errorMessage">
    {{ errorMessage }}
  </div>

  <app-button-container>
    <app-button
      type="primary"
      (click)="saveSelection()"
      [dataTestId]="'next-button-verifysignerdetails'"
    >
      Send
    </app-button>
  </app-button-container>
</form>
