import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { SignalRService } from 'src/app/features/signal-r';
import { SigningRoomActions } from 'src/app/features/signing-room/store';
import { RootStoreState } from 'src/app/store';

@Injectable()
export class SigningRoomService {
  private listenerConfigured = false;

  constructor(
    private readonly http: HttpClient,
    private readonly signalRService: SignalRService,
    private readonly store: Store<RootStoreState.State>
  ) {}

  public attemptCompletion(packageUserGuid: string, deviceCode: string) {
    return this.http.post('closingroom/', {
      packageUserGuid,
      deviceCode,
    });
  }

  public sessionJoined(packageUserGuid: string, deviceCode: string) {
    return this.http.post('signingRoom/sessionJoined', {
      packageUserGuid,
      deviceCode,
    });
  }

  // TODO: User Story 50434: Remove Old Endorsement Application Logic
  public attemptPackageCompletion(
    packageGuid: string,
    packageUserGuid: string,
    deviceCode: string
  ) {
    return this.http.post(`signingRoom/completion`, {
      packageGuid,
      deviceCode,
      packageUserGuid,
    });
  }

  public triggerSigningCompletion(
    packageGuid: string,
    packageUserGuid: string,
    deviceCode: string
  ) {
    return this.http.post(`signingRoom/sessionCompletion`, {
      packageGuid,
      deviceCode,
      packageUserGuid,
    });
  }

  public getSigningStarted() {
    return this.http.get<{ hasSigningStarted: boolean }>('signingRoom/signingStarted', {});
  }

  public configureListeners() {
    if (!this.listenerConfigured) {
      this.listenerConfigured = true;

      this.signalRService.hubConnection.on('ReceiveSigningStarted', () => {
        this.store.dispatch(
          SigningRoomActions.SetSigningStartedStatus({
            payload: { hasSigningStarted: true },
          })
        );
      });
    }
  }
}
