<div #subheader><ng-content select="[subheader]"></ng-content></div>
<div *ngIf="!subheader.children.length">
  <h2 data-testid="welcome-subheader">Let’s get you ready to notarize some documents!</h2>
</div>

<div #body><ng-content select="[body]"></ng-content></div>
<div *ngIf="!body.children.length">
  <p>
    This check-in will only take a few minutes to complete. Besides our Terms & Privacy Policy, you’ll be confirming
    your location and the commission to be used, after that you will finish up by adopting your signature.
  </p>
  <p data-testid="welcome-support">
    If you require any help during check-in or the signing session, you can call Support at:
    <a href="tel:{{supportTelephoneNumber | phone}}">{{supportTelephoneNumber | phone}}</a>. You will also be able to find the support number in the Menu at the top of the page any time during the session.
  </p>
</div>
