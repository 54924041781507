import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';
import { NavigationStart, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { filter, takeUntil, tap } from 'rxjs';

import {
  NotificationModel,
  NotificationType,
  NotificationsActions,
  NotificationsSelectors,
} from 'src/app/features/notifications';

import { DEFAULT_APPLICATION_PHONE_NUMBER } from 'src/app/features/shared/constants';
import { LoadingInterceptorTimeoutData } from 'src/app/interceptors/loading.interceptor';
import { windowToken } from 'src/app/window.token';

@Component({
  selector: 'app-loading-timeout.component',
  templateUrl: 'loading-timeout.component.html',
  styleUrls: ['loading-timeout.component.scss'],
})
export class LoadingTimeoutComponent implements OnInit, OnDestroy {
  public defaultApplicationPhoneNumber = DEFAULT_APPLICATION_PHONE_NUMBER;
  public loadingCounter = 0;
  public loadingInterval: ReturnType<typeof setInterval> = undefined;
  public refreshOffer = false;
  public refreshOfferInSeconds = 20;

  constructor(
    @Inject(DOCUMENT) private readonly doc: Document,
    @Inject(MAT_SNACK_BAR_DATA) private readonly snackBarData: LoadingInterceptorTimeoutData,
    @Inject(windowToken) private readonly win: Window,
    private readonly router: Router,
    private readonly snackBar: MatSnackBarRef<LoadingTimeoutComponent>,
    private readonly store: Store
  ) {
    this.handleRefreshOffer = this.handleRefreshOffer.bind(this);
    this.handleNotificationClosure = this.handleNotificationClosure.bind(this);
  }

  ngOnInit(): void {
    this.router.events
      .pipe(
        takeUntil(this.snackBar.afterDismissed()),
        filter((e) => e instanceof NavigationStart),
        tap(() => this.handleClose())
      )
      .subscribe();

    this.store
      .select(NotificationsSelectors.selectNotifications)
      .pipe(
        takeUntil(this.snackBar.afterDismissed()),
        tap((notifications) => {
          notifications.forEach(this.handleNotificationClosure);
        })
      )
      .subscribe();

    this.loadingInterval = setInterval(this.handleRefreshOffer, 1000);
    this.logInAppInsights(
      'LoadingInterceptor loading timeout threshold reached.',
      this.snackBarData
    );
  }

  closeNotifications(notifications: NotificationModel[]) {
    notifications.forEach(this.handleNotificationClosure);
  }

  handleNotificationClosure({ id }: { id: string }) {
    this.store.dispatch(
      NotificationsActions.ClearNotification({ payload: { notificationId: id } })
    );
  }

  handleClose(): void {
    clearInterval(this.loadingInterval);

    this.snackBar.dismissWithAction();
  }

  handleRefresh(): void {
    this.win.location.reload();
  }

  handleRefreshOffer(): void {
    this.loadingCounter = this.loadingCounter + 1;

    if (this.loadingCounter === this.refreshOfferInSeconds) {
      this.refreshOffer = true;

      this.doc.documentElement.style.setProperty(
        '--snackbar-container-color',
        'var(--app-loading-timeout-snackbar-container-color)'
      );

      this.doc.documentElement.style.setProperty(
        '--snackbar-container-border',
        'var(--app-loading-timeout-snackbar-container-border)'
      );

      this.doc.documentElement.style.setProperty(
        '--app-info-icon-fill',
        'var(--app-loading-timeout-snackbar-container-fill)'
      );

      this.logInAppInsights(
        'LoadingTimeoutComponent refresh offer threshold reached.',
        this.snackBarData
      );

      clearInterval(this.loadingInterval);
    }
  }

  logInAppInsights(
    intro: string,
    { apiBaseUrl, guidExpression, notificationId, requests }: LoadingInterceptorTimeoutData
  ): void {
    const isPlural = requests.length > 1;
    this.store.dispatch(
      NotificationsActions.AddNotification({
        payload: {
          notificationType: NotificationType.Warning,
          exception: Error(
            `${intro} Endpoint${isPlural ? 's' : ''} ${
              isPlural ? 'are' : 'is'
            } not completing before set timeout: ${requests
              .map((result) =>
                result.url.replace(RegExp(guidExpression, 'ig'), ':guid').replace(apiBaseUrl, '')
              )
              .join(', ')}`
          ),
          id: notificationId,
          logInAppInsights: true,
        },
      })
    );
  }

  ngOnDestroy(): void {
    this.doc.documentElement.style.removeProperty('--app-info-icon-fill');
    this.doc.documentElement.style.removeProperty('--snackbar-container-color');
    this.doc.documentElement.style.removeProperty('--snackbar-container-border');

    clearInterval(this.loadingInterval);
  }
}
