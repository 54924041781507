import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';

import { of } from 'rxjs';
import { catchError, exhaustMap, filter, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { v4 as uuid } from 'uuid';

import { ConsentsSelectors } from 'src/app/features/consents';
import { DeviceGroupSelectors } from 'src/app/features/device-group/store/selectors';
import { NotificationsActions, NotificationType } from 'src/app/features/notifications';
import { ExceptionType } from 'src/app/features/notifications/models';
import { PackagesSelectors } from 'src/app/features/packages';
import { CheckInTaskGroup, WizardActions, WizardSelectors } from 'src/app/features/wizard';
import { RootStoreState } from 'src/app/store';

import { SignatureCreationTaskConfiguration } from '../../models';
import { EndorsementSelectionService } from '../../services';
import { EndorsementsCreationActions } from '../actions';
import { EndorsementsCreationSelectors } from '../selectors';

@Injectable()
export class EndorsementsCreationEffects {
  getSignaturePreviews$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EndorsementsCreationActions.FetchSignaturePreviews),
      withLatestFrom(this.store.pipe(select(WizardSelectors.getActiveWizardUserGuid))),
      switchMap(([action, packageUserGuid]) => {
        return this.endorsementsService.getSignatureOptions(packageUserGuid).pipe(
          map((payload) => {
            if (payload) {
              return EndorsementsCreationActions.SetSignaturePreviews({
                payload,
              });
            }
            return EndorsementsCreationActions.NoOp();
          }),
          catchError((err) =>
            of(
              NotificationsActions.AddNotification({
                payload: {
                  exception: err,
                  notificationType: NotificationType.Error,
                  id: uuid(),
                  text: 'Failed to get signature previews.',
                  exceptionType: ExceptionType.ReloadRetry,
                },
              })
            )
          )
        );
      })
    )
  );

  getInitialsPreviews$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EndorsementsCreationActions.FetchInitialsPreviews),
      withLatestFrom(this.store.pipe(select(WizardSelectors.getActiveWizardUserGuid))),
      switchMap(([action, packageUserGuid]) => {
        return this.endorsementsService.getInitialsOptions(packageUserGuid).pipe(
          map((payload) => {
            if (payload.endorsements) {
              return EndorsementsCreationActions.SetInitialsPreviews({
                payload,
              });
            }
            return EndorsementsCreationActions.NoOp();
          }),
          catchError((err) =>
            of(
              NotificationsActions.AddNotification({
                payload: {
                  exception: err,
                  notificationType: NotificationType.Error,
                  id: uuid(),
                  text: 'Failed to get initials previews',
                  exceptionType: ExceptionType.ReloadRetry,
                },
              })
            )
          )
        );
      })
    )
  );

  confirmAndSaveEndorsement$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(EndorsementsCreationActions.ConfirmAndSaveEndorsements),
      withLatestFrom(
        this.store.pipe(select(EndorsementsCreationSelectors.getHasCreatedDrawnEndorsements))
      ),
      map(([action, getHasCreatedDrawnEndorsements]) => {
        if (getHasCreatedDrawnEndorsements) {
          return EndorsementsCreationActions.SaveDrawnEndorsements();
        }
        return EndorsementsCreationActions.SaveEndorsementSelections();
      })
    );
  });

  saveSelectedEndorsements$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EndorsementsCreationActions.SaveEndorsementSelections),
      withLatestFrom(
        this.store.pipe(select(WizardSelectors.getActiveWizardUserGuid)),
        this.store.pipe(select(EndorsementsCreationSelectors.getActiveSignatureImage)),
        this.store.pipe(select(EndorsementsCreationSelectors.getActiveInitialsImage)),
        this.store.pipe(select(ConsentsSelectors.getEndorsementsConsent)),
        this.store.pipe(select(DeviceGroupSelectors.getDeviceCode)),
        this.store.pipe(select(PackagesSelectors.isPreSign))
      ),
      exhaustMap(
        ([
          action,
          packageUserGuid,
          selectedSignature,
          selectedInitials,
          endorsementConsent,
          deviceCode,
          isPreSign,
        ]) => {
          return this.endorsementsService
            .saveEndorsementSelections({
              packageUserGuid,
              signatureFont: selectedSignature.fontName,
              initialsFont: selectedInitials.fontName,
              signatureConsentAccepted: true,
              consentTextId: endorsementConsent.consentTextId,
              consentType: endorsementConsent.consentType,
              isPreSign,
              deviceCode,
            })
            .pipe(
              map(() => EndorsementsCreationActions.EndorsementSelectionsSaveSucccessful()),
              catchError((err) =>
                of(
                  NotificationsActions.AddNotification({
                    payload: {
                      exception: err,
                      notificationType: NotificationType.Error,
                      id: uuid(),
                      text: 'Failed to save endorsement selections.',
                      exceptionType: ExceptionType.ReloadRetry,
                    },
                  })
                )
              )
            );
        }
      )
    )
  );

  saveDrawnEndorsements$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EndorsementsCreationActions.SaveDrawnEndorsements),
      withLatestFrom(
        this.store.pipe(select(WizardSelectors.getActiveWizardUserGuid)),
        this.store.pipe(select(ConsentsSelectors.getEndorsementsConsent)),
        this.store.pipe(select(DeviceGroupSelectors.getDeviceCode)),
        this.store.pipe(select(PackagesSelectors.isPreSign)),
        this.store.pipe(select(EndorsementsCreationSelectors.getDrawnInitialsSvgImage)),
        this.store.pipe(select(EndorsementsCreationSelectors.getDrawnSignatureSvgImage)),
        this.store.pipe(select(EndorsementsCreationSelectors.getActiveInitialsImage))
      ),
      switchMap(
        ([
          action,
          packageUserGuid,
          endorsementConsent,
          deviceCode,
          isPreSign,
          getDrawnInitialsSvgImage,
          getDrawnSignatureSvgImage,
          selectedInitials,
        ]) => {
          return this.endorsementsService
            .confirmSignatureFromCommissionAndSaveInitials({
              packageUserGuid,
              signatureConsentAccepted: true,
              consentTextId: endorsementConsent.consentTextId,
              consentType: endorsementConsent.consentType,
              initials: getDrawnInitialsSvgImage,
              initialsFont: selectedInitials?.fontName,
              signatureSvgImage: getDrawnSignatureSvgImage,
              isPreSign,
              deviceCode,
            })
            .pipe(
              map(() => EndorsementsCreationActions.EndorsementSelectionsSaveSucccessful()),
              catchError(() =>
                of(
                  NotificationsActions.AddNotification({
                    payload: {
                      notificationType: NotificationType.Error,
                      id: uuid(),
                      text: 'Failed to save endorsement selections.',
                      exceptionType: ExceptionType.ReloadRetry,
                    },
                  })
                )
              )
            );
        }
      )
    )
  );

  setSignatureTaskConfiguration$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WizardActions.FetchTasksSuccessful),
      map((action) =>
        action.payload.find((t) => t.taskGroup === CheckInTaskGroup.SignatureCreation)
      ),
      filter((signatureTask) => !!signatureTask),
      switchMap((signatureTask) => {
        const taskConfiguration =
          signatureTask.taskConfiguration as SignatureCreationTaskConfiguration;
        return [
          EndorsementsCreationActions.SetSignatureType({
            payload: taskConfiguration.signatureType,
          }),
          EndorsementsCreationActions.SetIsSignatureCreatedInPresign({
            payload: taskConfiguration.isCreatedInPresign,
          }),
        ];
      })
    )
  );

  constructor(
    private readonly actions$: Actions,
    private readonly store: Store<RootStoreState.State>,
    private readonly endorsementsService: EndorsementSelectionService
  ) {}
}
