interface IntervalTimerComplete {
  (): void;
}

export class IntervalTimer {
  private readonly _totalSeconds: number;
  private _remainingSeconds: number;
  private _validationTimerInterval: any;

  public getRemainingSeconds() : number {
    return this._remainingSeconds
  }

  constructor(totalSeconds: number) {
    this._totalSeconds = totalSeconds;
  }

  public start(intervalTimerComplete: IntervalTimerComplete) : void {
    this.cancel();
    this._validationTimerInterval = setInterval(() => {
      if (this._remainingSeconds > 0) {
        this._remainingSeconds--;
      } else {
        clearInterval(this._validationTimerInterval);
        intervalTimerComplete();
      }
    }, 1000);
  }

  public cancel(): void {
    this._remainingSeconds = this._totalSeconds;
    clearInterval(this._validationTimerInterval);
  }
}
