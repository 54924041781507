import { ComponentType } from '@angular/cdk/overlay';
import { createAction, props } from '@ngrx/store';

import { NotificationModel } from '../../models';

export const AddNotification = createAction(
  '[Notifications] Add Notification',
  props<{ payload: NotificationModel }>()
);

export const ClearNotification = createAction(
  '[Notifications] Clear Notification',
  props<{ payload: { notificationId: string } }>()
);

export const NotificationCleared = createAction(
  '[Notifications] Notification Cleared',
  props<{ payload: { notificationId: string } }>()
);

export const InsertNotification = createAction(
  '[Notifications] Handle Notification',
  props<{ payload: NotificationModel }>()
);

export const AddSnackBarNotification = createAction(
  '[Notifications] Add Snackbar Notification',
  props<{ payload: { component?: ComponentType<any>, title: string, body: string, duration?: number, panelClass?: string, data?: Object } }>()
);

