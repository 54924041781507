import { UrlSegment } from '@angular/router';
import { PRODUCT_TYPE_ROUTE_MAP } from 'src/app/features/packages/packages.constants';

import { FeedbackType } from 'src/app/features/shared/models/FeedbackType.enum';

export function FeedbackUrlMatcher(url) {
  if (
    url.length === 1 &&
    (url[0].path === FeedbackType.INVALIDURL ||
      url[0].path === FeedbackType.INVALIDLINK ||
      url[0].path === FeedbackType.UNAUTHORIZED ||
      url[0].path === FeedbackType.GENERICERROR ||
      url[0].path === FeedbackType.UNSUPPORTEDBROWSER ||
      url[0].path === FeedbackType.UNSUPPORTEDDEVICE ||
      url[0].path === FeedbackType.UNSUPPORTEDOPERATINGSYSTEM ||
      url[0].path === FeedbackType.CANCELLED)
  ) {
    return {
      consumed: url,
      posParams: {
        feedbackType: new UrlSegment(url[0].path, {}),
      },
    };
  } else if (
    url.length === 2 &&
    Array.from(Object.values(FeedbackType)).includes(url[1].path) &&
    Array.from(PRODUCT_TYPE_ROUTE_MAP.values()).includes(url[0].path)
  ) {
    return {
      consumed: url,
      posParams: {
        feedbackType: new UrlSegment(url[1].path, {}),
      },
    };
  }

  return null;
}
