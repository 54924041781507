import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { DeviceGroupSelectors } from 'src/app/features/device-group';
import { PackageUserRole } from 'src/app/features/package-users';
import { RootStoreState } from 'src/app/store';

import { DocumentsActions, DocumentsSelectors } from '../../store';

@Component({
  selector: 'app-download-executing-closing-package-button',
  templateUrl: './download-executing-closing-package-button.component.html',
  styleUrls: ['./download-executing-closing-package-button.component.scss'],
})
export class DownloadExecutingClosingPackageButtonComponent implements OnInit {
  isExecutingPackageDownloading$: Observable<boolean>;

  anyUserAllowedtoDownloadDocs$: Observable<boolean>;
  public DocumentDownloadRolesEnums = [
    PackageUserRole.SIGNER,
    PackageUserRole.BORROWER,
    PackageUserRole.SELLER,
  ];

  constructor(private readonly store: Store<RootStoreState.State>) {}

  ngOnInit(): void {
    this.isExecutingPackageDownloading$ = this.store.select(
      DocumentsSelectors.getIsExecutedClosingPackageDownloading
    );
    this.anyUserAllowedtoDownloadDocs$ = this.store
      .select(DeviceGroupSelectors.getUsersOnDevice)
      .pipe(
        map((usersOnDevice) =>
          usersOnDevice.some((user) =>
            this.DocumentDownloadRolesEnums.includes(user.userRoleCode as PackageUserRole)
          )
        )
      );
  }

  onDocumentDownloadClick(): void {
    this.store.dispatch(DocumentsActions.DownloadExecutedClosingPackage());
  }
}
