import { Component, Input } from '@angular/core';

import { KbaResult } from '../../models';

@Component({
  selector: 'app-kba-results',
  templateUrl: './kba-results.component.html',
  styleUrls: ['./kba-results.component.scss']
})
export class KbaResultsComponent {
  @Input() kbaResults: KbaResult;

  get kbaStatus(): string {
    return this.kbaResults.isSuccess ? "Passed" : "Failed";
  }
}
