import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { tap, takeUntil, filter } from 'rxjs/operators';
import { v4 as uuid } from 'uuid';

import { RootStoreState } from 'src/app/store';
import { AbstractWizardStepComponent, CheckInTaskCode, WizardStep } from 'src/app/features/wizard';
import { PackageUser } from 'src/app/features/package-users';
import { PendoLocationService } from 'src/app/features/pendo-adapter';
import { ClientActions, ClientSelectors } from 'src/app/features/client';

import { IdVerificationActions, IdVerificationSelectors } from '../../store';

@Component({
  selector: 'app-id-verification-in-progress',
  templateUrl: './id-verification-in-progress.component.html',
  styleUrls: ['./id-verification-in-progress.component.scss'],
  providers: [
    {
      provide: AbstractWizardStepComponent,
      useExisting: IdVerificationInProgressComponent,
    },
  ],
})
export class IdVerificationInProgressComponent
  extends AbstractWizardStepComponent
  implements OnInit, OnDestroy
{
  @Input() isMobile: boolean;
  @Input() user: PackageUser;

  isIdVerificationComplete$: Observable<boolean>;
  isIdVerificationComplete: boolean;
  shouldBypassRealId$: Observable<boolean>;
  private readonly _onDestroyNotifier$ = new Subject();

  constructor(private readonly store: Store<RootStoreState.State>) {
    super(store);
    this.stepMetadata = {
      stepName: 'Scanning Your ID',
      checkInTaskCode: CheckInTaskCode.IdVerification,
    } as WizardStep;
    this.formStep = new UntypedFormGroup({});
  }

  ngOnInit() {
    super.ngOnInit();
    this.store.dispatch(ClientActions.GetShouldBypassRealId());
    this.shouldBypassRealId$ = this.store.pipe(select(ClientSelectors.shouldBypassRealId));

    this.store.dispatch(IdVerificationActions.GetUserIdVerificationResultsStatus());
    this.isIdVerificationComplete$ = this.store.pipe(
      select(IdVerificationSelectors.getIsComplete(this.user.packageUserGuid))
    );

    this.isIdVerificationComplete$
      .pipe(
        takeUntil(this._onDestroyNotifier$),
        filter((isComplete) => isComplete),
        tap(() => {
          this.isIdVerificationComplete = true;
          PendoLocationService.instance.identifySubPage(
            `${this.stepMetadata.checkInTaskCode}Success`
          );
        })
      )
      .subscribe();
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this._onDestroyNotifier$.next(undefined);
    this._onDestroyNotifier$.complete();
  }

  public completeFakeIdVerification(): void {
    this.store.dispatch(IdVerificationActions.FakeIdIdVerificationComplete());
  }

  public goToNextStep(payload?: any): void {
    if (this.isIdVerificationComplete) {
      super.goToNextStep();
    }
  }

  get bannerMessage() {
    return this.isMobile
      ? 'Please return to the ID verification tab to complete this process.'
      : 'Please return to the ID verification process on your mobile device to complete this process.';
  }
}
