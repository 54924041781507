import { createAction, props } from '@ngrx/store';

import {
  DocumentEndorsementLocation,
  EndorsementApplication,
  EndorsementConfirmation,
  EndorsementProcessing,
  IncomingEndorsementAttempt,
  OutgoingEndorsementAttempt,
  OutgoingEraseSignedEndorsement,
  OutgoingSignEndorsement,
  PackageUserEndorsementImages,
  // TODO: User Story 50434: Remove Old Endorsement Application Logic
  SmartDocEndorsementCoordinateUpdate,
  SystemFieldStatus,
  UniqueEndorsementImage,
  SystemEndorsementImages,
} from '../../models';

export const ApplySystemFields = createAction('[Endorsements] Apply System Fields');

export const SetEndorsementLocations = createAction(
  '[Endorsements] Set Endorsements',
  props<{ payload: Array<DocumentEndorsementLocation> }>()
);

export const FetchEndorsementImages = createAction('[Endorsements] Fetch Endorsement Images');

export const FetchFreeTextEndorsementImages = createAction(
  '[Endorsements] Fetch Free Text Endorsement Images'
);

export const FetchSystemEndorsementImages = createAction(
  '[Endorsements] Fetch System Endorsement Images'
);

export const SetEndorsementImages = createAction(
  '[Endorsements] Set Endorsement Images',
  props<{ payload: Array<PackageUserEndorsementImages> }>()
);

export const SetUniqueEndorsementImages = createAction(
  '[Endorsements] Set Unique Endorsement Images',
  props<{ payload: Array<UniqueEndorsementImage> }>()
);

export const SetSystemEndorsementImages = createAction(
  '[Endorsements] Set System Endorsement Images',
  props<{ payload: Array<SystemEndorsementImages> }>()
);

export const CreateUniqueEndorsementImagePlaceholders = createAction(
  '[Endorsements] Set Unique Endorsement Image',
  props<{ payload: Array<UniqueEndorsementImage> }>()
);

export const UpdateUniqueEndorsementImage = createAction(
  '[Endorsements] Update Unique Endorsement Image',
  props<{
    payload: {
      endorsementImageString: string;
      documentEndorsementLocationId: number;
    };
  }>()
);

export const ClearUniqueEndorsementImage = createAction(
  '[Endorsements] Clear Unique Endorsement Image',
  props<{ payload: { documentEndorsementLocationId: number } }>()
);

export const EndorsementImageUpdated = createAction(
  '[Endorsements] Endorsement Image Updated',
  props<{
    payload: { packageUserGuid: string; endorsementId: number };
  }>()
);

export const EndorsementProcessingStartedEvent = createAction(
  '[Endorsements] Endorsement Processing Started',
  props<{ payload: EndorsementProcessing }>()
);

export const EndorsementProcessingFinishedEvent = createAction(
  '[Endorsements] Endorsement Processing Finished',
  props<{ payload: EndorsementProcessing }>()
);

export const SetProcessingEndorsement = createAction(
  '[Endorsements] Set Processing Endorsement',
  props<{
    payload: { isProcessing: boolean };
  }>()
);

export const ProcessSignEndorsement = createAction(
  '[Endorsements] Process Sign Endorsement',
  props<{ payload: OutgoingSignEndorsement }>()
);

export const ProcessEraseSignedEndorsement = createAction(
  '[Endorsements] Process Erase Signed Endorsement',
  props<{ payload: OutgoingEraseSignedEndorsement }>()
);

export const SignEndorsementCallCompleted = createAction(
  '[Endorsements] Sign Endorsement Call Completed',
  props<{ payload: OutgoingSignEndorsement }>()
);

export const EraseEndorsementCallCompleted = createAction(
  '[Endorsements] Sign Endorsement Call Completed',
  props<{ payload: OutgoingEraseSignedEndorsement }>()
);

export const SetEndorsementSigned = createAction(
  '[Endorsements] Set Endorsement Signed',
  props<{ payload: EndorsementConfirmation }>()
);

export const RollbackEndorsement = createAction(
  '[Endorsements] Rollback Endorsement',
  props<{
    payload: { endorsementLocationId: number };
  }>()
);

//TODO: find better names or try to use other actions
export const BroadcastRollbackEndorsement = createAction(
  '[Endorsements] Broadcast Rollback Endorsement',
  props<{
    payload: { endorsementLocationId: number; packageUserGuid: string };
  }>()
);

// TODO: User Story 50434: Remove Old Endorsement Application Logic
//TODO: find better names or try to use other actions - Revist "attempted" naming
export const BroadcastSetEndorsementAttempted = createAction(
  '[Endorsements] Broadcast Set Endorsement Attempted',
  props<{ payload: IncomingEndorsementAttempt }>()
);

// TODO: User Story 50434: Remove Old Endorsement Application Logic
export const SetUniqueEndorsementImage = createAction(
  '[Endorsements] Set Unique Endorsement Image',
  props<{ payload: UniqueEndorsementImage }>()
);

// TODO: User Story 50434: Remove Old Endorsement Application Logic
export const AttemptEndorsement = createAction(
  '[Endorsements] Attempt Endorsement',
  props<{ payload: OutgoingEndorsementAttempt }>()
);

// TODO: User Story 50434: Remove Old Endorsement Application Logic
export const EndorsementAttemptAccepted = createAction(
  '[Endorsements] Attempt Endorsement Accepted',
  props<{ payload: OutgoingEndorsementAttempt }>()
);

// TODO: User Story 50434: Remove Old Endorsement Application Logic
export const SetEndorsementAttempted = createAction(
  '[Endorsements] Set Endorsement Attempted',
  props<{ payload: IncomingEndorsementAttempt }>()
);

// TODO: User Story 50434: Remove Old Endorsement Application Logic
export const SetEndorsementConfirmed = createAction(
  '[Endorsements] Set Endorsement Confirmed',
  props<{ payload: EndorsementConfirmation }>()
);

// TODO: User Story 50434: Remove Old Endorsement Application Logic
export const RollbackEndorsementAttempt = createAction(
  '[Endorsements] Rollback Endorsement Attempt',
  props<{
    payload: { endorsementLocationId: number };
  }>()
);

// TODO: User Story 50434: Remove Old Endorsement Application Logic
export const SetAttemptingEndorsement = createAction(
  '[Endorsements] Set Attempting Endorsement',
  props<{
    payload: { isAttempting: boolean };
  }>()
);

export const UpdateENoteEndorsementCoordinates = createAction(
  '[Endorsements] Update eNote Endorsement Location Coordinates',
  props<{ payload: Array<SmartDocEndorsementCoordinateUpdate> }>()
);

export const UpdateReadOnlyFlag = createAction(
  '[Endorsements] Update readonly flag',
  props<{
    payload: { isReadOnly: boolean };
  }>()
);

// TODO: User Story 50434: Remove Old Endorsement Application Logic
//TODO: find better names or try to use other actions
export const BroadcastRollbackEndorsementAttempt = createAction(
  '[Endorsements] Broadcast Rollback Endorsement Attempt',
  props<{
    payload: { endorsementLocationId: number; packageUserGuid: string };
  }>()
);

// TODO: User Story 50434: Remove Old Endorsement Application Logic
export const EndorsementApplicationStarted = createAction(
  '[Endorsements] Endorsement Application Started',
  props<{ payload: EndorsementApplication }>()
);

// TODO: User Story 50434: Remove Old Endorsement Application Logic
export const EndorsementApplicationFinished = createAction(
  '[Endorsements] Endorsement Application Finished',
  props<{ payload: EndorsementApplication }>()
);

// TODO: User Story 50434: Remove Old Endorsement Application Logic
export const SendEndorsementApplicationCancelled = createAction(
  '[Endorsements] Send Endorsement Application Cancelled',
  props<{ payload: EndorsementApplication }>()
);

// TODO: USER STORY 61107: NEA System Field Code Clean-up
export const FetchSystemFieldStatus = createAction('[Endorsements] Fetch System Field Status');

export const FetchSystemFieldsStatus = createAction('[Endorsements] Fetch System Fields Status');

export const SetSystemFieldStatus = createAction(
  '[Endorsements] Set System Field Status',
  props<{ payload: Partial<SystemFieldStatus> }>()
);

export const FailedToApplySystemFields = createAction(
  '[Endorsements] Failed to Apply System Fields'
);

export const SetAllEndorsementsSigned = createAction(
  '[Endorsements] Set All Endorsements Signed',
  props<{ payload: boolean }>()
);

export const UpdateSignableOnDeviceStatusByPackageUserGuid = createAction(
  '[Endorsements] Set Device User Endorsements As Signable on Device',
  props<{ payload: { packageUserGuid: string; enable: boolean } }>()
);

export const SetUpdatedSignableOnDeviceStatus = createAction(
  '[Endorsements] Update Endorsement Locations as Signable On Device',
  props<{ payload: { endorsementLocations: DocumentEndorsementLocation[]; enable: boolean } }>()
);

export const UpdateSignableOnDeviceStatusForDeviceUsers = createAction(
  '[Endorsements] Set Endorsements As Signable on Device For All Device User'
);

export const RefreshEndorsementsSignableStatus = createAction(
  '[Endorsements] Refetch Endorsements',
  props<{ payload: { devicePackageUserGuids: string[] } }>()
);

export const RefreshEndorsementsUpdateSignableOnDeviceStatusByPackageUserGuids = createAction(
  '[Endorsements] Refetch Endorsements Update Endorsements As Signable On Device',
  props<{
    payload: {
      devicePackageUserGuids: string[];
      endorsementLocations: DocumentEndorsementLocation[];
    };
  }>()
);
