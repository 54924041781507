import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { NgxMaskModule } from 'ngx-mask';

import { FeatureManagementDirectiveModule } from 'src/app/features/feature-management/directives/feature-management-directive.module';

import {
  BrandingComponent,
  ButtonComponent,
  ButtonContainerComponent,
  CardComponent,
  CloseIconComponent,
  CollapsibleComponent,
  ConfirmationModalComponent,
  InfoBoxComponent,
  InfoIconComponent,
  GuideIconComponent,
  LinkButtonComponent,
  LoadingTimeoutComponent,
  NeedHelpComponent,
  NotificationsIconComponent,
  RdsInputComponent,
  SessionEndingProgressionComponent,
  SessionStatusSplashComponent,
  SparkIconComponent,
  StepperComponent,
  SupportComponent,
  SupportPhoneComponent,
} from './components';
import {
  DebounceClickDirective,
  DesktopOnlyDirective,
  IconTooltipDirective,
  MobileOnlyDirective,
} from './directives';
import { PhonePipe, SafeHtmlPipe, SafeResourceUrlPipe } from './pipes';

const MaterialModules = [
  MatButtonModule,
  MatMenuModule,
  MatIconModule,
  MatProgressSpinnerModule,
  MatSnackBarModule,
  MatCheckboxModule
];

const pipes = [PhonePipe, SafeHtmlPipe, SafeResourceUrlPipe];

const directives = [
  DebounceClickDirective,
  DesktopOnlyDirective,
  IconTooltipDirective,
  MobileOnlyDirective,
];

const components = [
  BrandingComponent,
  ButtonComponent,
  ButtonContainerComponent,
  CardComponent,
  CloseIconComponent,
  CollapsibleComponent,
  ConfirmationModalComponent,
  InfoBoxComponent,
  InfoIconComponent,
  GuideIconComponent,
  NeedHelpComponent,
  NotificationsIconComponent,
  LinkButtonComponent,
  LoadingTimeoutComponent,
  RdsInputComponent,
  SessionEndingProgressionComponent,
  SessionStatusSplashComponent,
  SparkIconComponent,
  SupportComponent,
  SupportPhoneComponent,
  StepperComponent
];

@NgModule({
  declarations: [...pipes, ...directives, ...components],
  imports: [
    CommonModule,
    FeatureManagementDirectiveModule,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    NgxMaskModule,
    ...MaterialModules,
  ],
  providers: [PhonePipe],
  exports: [
    ...pipes,
    ...directives,
    ...components,
    FeatureManagementDirectiveModule,
    MaterialModules,
  ],
})
export class SharedModule {}
