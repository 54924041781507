import { createFeatureSelector, createSelector } from '@ngrx/store';

import { PackageUser } from 'src/app/features/package-users';
import { getNonSigningAgents } from 'src/app/features/package-users/store/selectors/package-users.selectors';

import { IdImages, IdVerification } from '../../models';
import { ParticipantVerificationState } from '../state';

export const getParticipantVerificationState =
  createFeatureSelector<ParticipantVerificationState.State>('participantVerification');

export const getAllSignersApproved = createSelector(
  getParticipantVerificationState,
  (state: ParticipantVerificationState.State) => state.allSignersApproved
);

export const getApprovedPackageUsers = createSelector(
  getParticipantVerificationState,
  (state: ParticipantVerificationState.State) => state.approvedPackageUserGuids
);

export const getRejoinedPackageUsers = createSelector(
  getParticipantVerificationState,
  (state: ParticipantVerificationState.State) => state.rejoinedUsers
);

export const getIdVerifications = createSelector(
  getParticipantVerificationState,
  (state: ParticipantVerificationState.State) => {
    return state.idVerifications;
  }
);

export const getCurrentParticipantIdVerification = (packageUserGuid: string) =>
  createSelector(getIdVerifications, (idVerifications: IdVerification[]) => {
    return idVerifications.find((id) => id.packageUserGuid === packageUserGuid);
  });

export const getNextUnapprovedPackageUser = createSelector(
  getApprovedPackageUsers,
  getNonSigningAgents,
  (approvedUserGuids: string[], nonSigningAgentParticipants: PackageUser[]) =>
    nonSigningAgentParticipants.find((id) => !approvedUserGuids?.includes(id.packageUserGuid))
);

export const getNextUnapprovedPackageUserGuid = createSelector(
  getApprovedPackageUsers,
  getIdVerifications,
  getRejoinedPackageUsers,
  (approvedUserGuids: string[], idVerifications: IdVerification[], rejoinedUsers) => {
    if (rejoinedUsers.length > 0) {
      return rejoinedUsers[0].packageUserGuid;
    }
    return idVerifications.find((id) => !approvedUserGuids?.includes(id.packageUserGuid))
      ?.packageUserGuid;
  }
);

export const getNextRejoinedUser = createSelector(
  getRejoinedPackageUsers,
  (rejoinedPackageUsers) => {
    if (rejoinedPackageUsers.length === 0) return undefined;
    return rejoinedPackageUsers[0];
  }
);

export const getNextUnapprovedKronPackageUserGuid = createSelector(
  getApprovedPackageUsers,
  getNonSigningAgents,
  (approvedUserGuids: string[], nonSigningAgentParticipants: PackageUser[]) =>
    nonSigningAgentParticipants.find((id) => !approvedUserGuids?.includes(id.packageUserGuid))
      ?.packageUserGuid
);

export const getIdImagesForParticipant = createSelector(
  getParticipantVerificationState,
  (
    state: ParticipantVerificationState.State,
    props: { packageUserGuid: string; shouldBypassRealId: boolean }
  ) => {
    const participantIdVerificationInfo = state.idVerifications.find(
      (id) => id.packageUserGuid === props.packageUserGuid
    );

    if (props.shouldBypassRealId) {
      const defaultIdImages: IdImages = {
        front: '../../../../../assets/images/license-front.png',
        back: '../../../../../assets/images/license-back.png',
      };

      return defaultIdImages;
    } else {
      const frontImg =
        'data:image/jpeg;base64,' +
        participantIdVerificationInfo.latestUserAddressInformationIdVerificationResults.idImages
          .front;
      const backImg =
        'data:image/jpeg;base64,' +
        participantIdVerificationInfo.latestUserAddressInformationIdVerificationResults.idImages
          .back;

      return {
        front: frontImg,
        back: backImg,
      } as IdImages;
    }
  }
);

export const doesIdVerificationExist = createSelector(
  getIdVerifications,
  (idVerifications: IdVerification[]) => {
    if (idVerifications?.length > 0) {
      return true;
    } else {
      return false;
    }
  }
);

export const isAllSignersApprovedInfoLoaded = createSelector(
  getParticipantVerificationState,
  (state: ParticipantVerificationState.State) => state.isAllSignersApprovedInfoLoaded
);
