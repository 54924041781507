import {
  CommissionConsentContent,
  EndorsementsConsent,
  EndUserLicenseAgreementContent,
  KnownSignerConsent,
  PrivacyPolicyConsent,
  TermsAndConditionsConsent,
} from '../../models';

export interface State {
  privacy: PrivacyPolicyConsent;
  termsAndConditions: TermsAndConditionsConsent;
  knownSigner: KnownSignerConsent;
  endorsements: EndorsementsConsent;
  eula: EndUserLicenseAgreementContent;
  commission: CommissionConsentContent;
  isPrivacyPolicyConsentCheckBoxEnabled: boolean;
  isTermsOfUseConsentCheckBoxEnabled: boolean;
  isSignatureConsentAgreementCheckboxEnabled: boolean;
}

export const initialState: State = {
  privacy: null,
  termsAndConditions: null,
  endorsements: null,
  eula: null,
  commission: null,
  knownSigner: null,
  isPrivacyPolicyConsentCheckBoxEnabled: false,
  isTermsOfUseConsentCheckBoxEnabled: false,
  isSignatureConsentAgreementCheckboxEnabled: false,
};
