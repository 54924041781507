import { TaskType } from "./task-type.enum";

export interface UserTask<TTaskConfiguration> {
  name: string;
  description: string;
  linkText: string;
  taskType: TaskType;
  taskCode: string;
  taskConfiguration?: TTaskConfiguration
}

export interface BasicTaskConfiguration {
}

export interface JoinSigningTaskConfiguration {
  signingUri: string
}


