import { CdkStepper } from '@angular/cdk/stepper';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { select, Store } from '@ngrx/store';

import { Subject } from 'rxjs';
import {
  distinctUntilKeyChanged,
  filter,
  first,
  takeUntil,
  tap
} from 'rxjs/operators';

import { ModalsActions } from 'src/app/features/modals/store/actions';
import { AbstractWizardStepComponent, CheckInTaskCode, WizardStep } from 'src/app/features/wizard';
import { RootStoreState } from 'src/app/store';

import { UnableToVerifyModalComponent } from '../../components/unable-to-verify-modal';
import { QuestionSet } from '../../models';
import { KnowledgeBasedAuthenticationFormsService } from '../../services';
import {
  KnowledgeBasedAuthenticationActions,
  KnowledgeBasedAuthenticationSelectors,
} from '../../store';
import { PackageStatusPollingService } from 'src/app/features/packages';

@Component({
  selector: 'app-kba-questions',
  templateUrl: './kba-questions.component.html',
  styleUrls: ['./kba-questions.component.scss'],
  providers: [
    {
      provide: AbstractWizardStepComponent,
      useExisting: KbaQuestionsComponent,
    },
  ],
})
export class KbaQuestionsComponent
  extends AbstractWizardStepComponent
  implements OnInit, OnDestroy
{
  @ViewChild('cdkStepper')
  cdkStepper: CdkStepper;

  kbaQuestions: UntypedFormGroup;

  questionSet: QuestionSet;
  stepDestroyedNotifier = new Subject();

  bannerText = 'You have 2 minutes to submit all the questions';

  constructor(
    private readonly store: Store<RootStoreState.State>,
    private readonly packageStatusService: PackageStatusPollingService,
    private readonly knowledgeBasedAuthenticationFormsService: KnowledgeBasedAuthenticationFormsService
  ) {
    super(store);
    this.applyStepMetadata();
  }

  ngOnInit() {
    super.ngOnInit();

    this.store.dispatch(KnowledgeBasedAuthenticationActions.FetchCurrentUserNumberOfKbaAttempts());

    this.store
      .pipe(
        takeUntil(this.stepDestroyedNotifier),
        select(KnowledgeBasedAuthenticationSelectors.getQuestionSet),
        filter<QuestionSet>(Boolean),
        distinctUntilKeyChanged('questionSetId'),
        tap((questionSet: QuestionSet) => {
          this.configureQuestionSet(questionSet);
        })
      )
      .subscribe();
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.store.dispatch(KnowledgeBasedAuthenticationActions.ResetKbaState());
    this.stepDestroyedNotifier.next(undefined);
    this.stepDestroyedNotifier.complete();
  }

  onStepVisible() {
    this.packageStatusService.stop();
    this.store
      .pipe(
        select(KnowledgeBasedAuthenticationSelectors.getCurrentUserNumberOfAttempts),
        filter((numberOfAttempts) => numberOfAttempts !== null && numberOfAttempts !== undefined),
        tap((numberOfAttempts) => {
          if (numberOfAttempts === 1) {
            this.store.dispatch(
              ModalsActions.SetStandaloneModalComponent({
                payload: {
                  component: UnableToVerifyModalComponent,
                },
              })
            );
            return;
          }
          this.store.dispatch(KnowledgeBasedAuthenticationActions.StartKbaQuestions());
        }),
        first()
      )
      .subscribe();
  }

  configureQuestionSet(questionSet: QuestionSet) {
    this.questionSet = questionSet;
    this.kbaQuestions =
      this.knowledgeBasedAuthenticationFormsService.convertQuestionSetToFormControls(questionSet);

    if (this.cdkStepper) {
      this.cdkStepper.selectedIndex = 0;
    }
  }

  public answer(id: number) {
    if (this.kbaQuestions.controls[id].invalid) {
      this.kbaQuestions.controls[id].markAsDirty();
    } else {
      this.cdkStepper.next();
    }
  }

  public unansweredQuestions(): string {
    return Object.keys(this.kbaQuestions.controls)
      .map((key, index) => {
        if (
          this.kbaQuestions.controls[key].invalid &&
          index !== Object.keys(this.kbaQuestions.controls).length - 1
        ) {
          return index + 1;
        }

        return 0;
      })
      .filter((val) => val !== 0)
      .join(' ');
  }

  public goToNextStep(_?: any): void {
    this.kbaQuestions.markAllAsTouched();

    if (this.kbaQuestions.valid) {
      const answers = this.knowledgeBasedAuthenticationFormsService.convertFormGroupToAnswers(
        this.kbaQuestions,
        this.questionSet
      );
      this.store.dispatch(
        KnowledgeBasedAuthenticationActions.AnswerKbaQuestions({
          payload: answers,
        })
      );
    }
  }

  applyStepMetadata() {
    this.stepMetadata = {
      stepName: 'Security Questions',
      checkInTaskCode: CheckInTaskCode.SecurityQuestions,
    } as WizardStep;

    this.formStep = new UntypedFormGroup({});
  }
}
