import { PackageScheduleStatus, ProductType } from './';

export interface PackageValidationResult {
  packageGuid: string;
  packageUserGuid: string;
  isUserSigningAgent: boolean;
  isValid: boolean;
  isSupportedInBrowser: boolean;
  isSupportedOnDevice: boolean;
  isOperatingSystemSupported: boolean;
  isMobile: boolean;
  productType: ProductType;
  isUserAuthorized: boolean;
  scheduleStatus: PackageScheduleStatus;
}
