import { createFeatureSelector, createSelector } from '@ngrx/store';

import { SignalRState } from '../state';
import { HubConnectionState } from '../../models';

export const getSignalRState = createFeatureSelector<SignalRState.State>('signalR');

export const selectIsSignalRGroupJoined = createSelector(
  getSignalRState,
  (state: SignalRState.State) => state.groupJoined
);

export const selectIsSignalRHubDisconnected = createSelector(
  getSignalRState,
  (state: SignalRState.State) => state.hubConnectionState === HubConnectionState.Disconnected
);
