import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { Observable, Subject, Subscription } from 'rxjs';
import { distinctUntilChanged, filter, takeUntil, tap } from 'rxjs/operators';
import { v4 as uuid } from 'uuid';

import { PackageUser } from 'src/app/features/package-users';
import { SignatureModel } from 'src/app/features/rules/models';
import { RootStoreState } from 'src/app/store';
import { ModalsActions, ModalSize } from 'src/app/features/modals';

import {
  ActiveEndorsementSelection,
  DrawnEndorsement,
  EndorsementSelection,
  EndorsementSteps,
} from '../../models';
import { EndorsementsCreationSelectors } from '../../store';
import { DrawInitialsComponent } from '../../components';

@Component({
  selector: 'app-draw-initials-step',
  templateUrl: './draw-initials-step.component.html',
  styleUrls: ['./draw-initials-step.component.scss'],
})
export class DrawInitialsStepComponent implements OnInit, OnDestroy {
  @Input() user: PackageUser;
  @Output() previousStepClicked = new EventEmitter();
  @Output() stepComplete = new EventEmitter();

  formStep: UntypedFormGroup;
  destroyedNotifier = new Subject();
  signatureData: DrawnEndorsement;
  signatureOptions$: Observable<Array<EndorsementSelection>>;
  selectedSignatures$: Observable<ActiveEndorsementSelection>;
  selectedSignaturesSubscription: Subscription;
  signatureTypeEligibility: Observable<SignatureModel>;

  constructor(private readonly store: Store<RootStoreState.State>) {
    this.formStep = new UntypedFormGroup({
      drawnInitialsSaved: new UntypedFormControl('', [Validators.requiredTrue]),
    });
  }

  ngOnInit() {
    this.store
      .pipe(
        select(EndorsementsCreationSelectors.getDrawnInitials),
        takeUntil(this.destroyedNotifier),
        filter<DrawnEndorsement>(Boolean),
        distinctUntilChanged(),
        tap((signatureData) => {
          this.signatureData = signatureData;
          this.formStep.get('drawnInitialsSaved').setValue(true);
        })
      )
      .subscribe();
  }

  ngOnDestroy() {
    this.destroyedNotifier.next(undefined);
    this.destroyedNotifier.complete();
  }

  drawInitials() {
    this.store.dispatch(
      ModalsActions.SetModalComponent({
        payload: {
          component: DrawInitialsComponent,
          componentData: {
            user: this.user,
            existingSignatureData: this.signatureData,
          },
          modalTitle: 'Draw Your Initials',
          allowManualClose: false,
          modalSize: ModalSize.small,
        },
      })
    );
  }

  nextClick() {
    this.stepComplete.emit();
  }

  onPreviousClick() {
    this.previousStepClicked.emit();
  }
}
