import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subject, combineLatest, filter, takeUntil, tap, timer } from 'rxjs';
import { PackageUsersActions } from 'src/app/features/package-users';
import { LongPollingConfig } from 'src/app/features/shared/configs';
import { SignalRActions, SignalRSelectors } from 'src/app/features/signal-r';
import { RootStoreState } from 'src/app/store';

@Component({
  selector: 'app-fetch-package-users',
  standalone: true,
  imports: [CommonModule],
  template: ``,
})
export class FetchPackageUsersComponent implements OnInit, OnDestroy {
  private readonly onDestroyNotifier$ = new Subject();

  constructor(private readonly store: Store<RootStoreState.State>) {
    this.handleFetchPackageUsers = this.handleFetchPackageUsers.bind(this);
  }

  ngOnInit(): void {
    combineLatest([
      timer(0, LongPollingConfig['10Seconds'].interval),
      this.store.select(SignalRSelectors.selectIsSignalRGroupJoined),
    ])
      .pipe(takeUntil(this.onDestroyNotifier$))
      .subscribe(this.handleFetchPackageUsers);

    combineLatest([
      timer(0, LongPollingConfig['60Seconds'].interval),
      this.store.select(SignalRSelectors.selectIsSignalRHubDisconnected),
    ])
      .pipe(
        takeUntil(this.onDestroyNotifier$),
        filter(([_, isHubConnectionClosed]) => isHubConnectionClosed),
        tap(() => this.store.dispatch(SignalRActions.RestartHubConnection()))
      )
      .subscribe();
  }

  handleFetchPackageUsers([times, isSignalRSessionJoined]): void {
    if (times === 0 || isSignalRSessionJoined === false) {
      this.store.dispatch(
        PackageUsersActions.FetchPackageUsers({
          payload: { ignoreIfLoaded: times === 0 },
        })
      );
    }
  }

  ngOnDestroy(): void {
    this.onDestroyNotifier$.next(undefined);
    this.onDestroyNotifier$.complete();
  }
}
