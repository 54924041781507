import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ModalsState } from '../state';
import { getIsVideoSessionInfoLoaded } from 'src/app/features/video/store/selectors/video.selectors';
import { doesIdVerificationExist } from 'src/app/features/participant-verification/store/selectors/participant-verification.selector';
import { getProductType } from 'src/app/features/packages/store/selectors/packages.selectors';
import { ProductType } from 'src/app/features/packages';

export const getModalsState = createFeatureSelector<ModalsState.State>('modals');

export const getModalTitle = createSelector(
  getModalsState,
  (state) => state.modalTitle
);

export const allowManualClose = createSelector(
  getModalsState,
  (state) => state.allowManualClose
);

export const getModalComponent = createSelector(
  getModalsState,
  (state) => state.component
);

export const getModalComponentData = createSelector(
  getModalsState,
  (state) => state.componentData
);

export const getModalComponentIsStandAlone = createSelector(
  getModalsState,
  (state) => state.contentIsStandalone
);

export const getModalComponentShouldFade = createSelector(
  getModalsState,
  (state) => state.shouldFade
);

export const getModalSize = createSelector(
  getModalsState,
  (state) => state.modalSize
);

export const getModalUseBackgroundOverlay = createSelector(
  getModalsState,
  (state) => state.useBackgroundOverlay
);

export const getModalComponentWithData = createSelector(
  getModalComponent,
  getModalComponentData,
  getModalComponentIsStandAlone,
  getModalComponentShouldFade,
  allowManualClose,
  getModalSize,
  getModalUseBackgroundOverlay,
  (
    component,
    data,
    contentIsStandalone,
    shouldFade,
    manualCloseAllowed,
    modalSize,
    useBackgroundOverlay
  ) => {
    return {
      component,
      componentData: data,
      isStandalone: contentIsStandalone,
      shouldFade,
      allowManualClose: manualCloseAllowed,
      modalSize,
      useBackgroundOverlay
    };
  }
);

export const showLoadingSpinner = createSelector(
  getModalsState,
  (state) => state.showLoadingSpinner
);

export const globalSpinner = createSelector(
  getModalsState,
  (state) => state.globalSpinner
);

export const selectShouldStopArchiveOnOrderCancellation = createSelector(
  getIsVideoSessionInfoLoaded,
  doesIdVerificationExist,
  getProductType,
  (
    isVideoSessionInfoLoaded: boolean,
    isParticipantIdologyValid: boolean,
    productType: string
  ): boolean =>
    isParticipantIdologyValid || (productType === ProductType.KRON && isVideoSessionInfoLoaded)
);
