<app-wizard-step-wrapper
    [showStep]="showStep"
    [stepTitle]="stepMetadata.stepName"
    [formGroup]="formStep"
    [bannerText]="bannerText"
    [hideHeader]="true"
  >
    <h2 class="form-step__header" data-testid="check-in-header">
      Security Questions
    </h2>
    <h3 class="form-step__subheading">
      We use these to verify your identity. Do not refresh the page.
    </h3>
    <form class="kba-questions" *ngIf="kbaQuestions" [formGroup]="kbaQuestions" data-private>
      <app-stepper #cdkStepper tabindex="5" [ariaLabelText]="'questions'">
        <cdk-step *ngFor="let question of questionSet.questions; index as questionIndex">
          <ng-template cdkStepLabel>
            <span *ngIf="kbaQuestions.controls[question.questionId].invalid">{{
              questionIndex + 1
              }}</span>
            <svg
              *ngIf="kbaQuestions.controls[question.questionId].valid"
              width="15"
              height="12"
              viewBox="0 0 15 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M4.65833 8.81667L1.18333 5.34167L0 6.51667L4.65833 11.175L14.6583 1.175L13.4833 0L4.65833 8.81667Z"
                fill="white"
              />
            </svg>
          </ng-template>
          <fieldset class="question">
            <legend class="question__text">
              {{ questionIndex + 1 }}. {{ question.text.statement }}
            </legend>
            <p class="error-message"
               *ngIf="cdkStepper.isLastStep() && unansweredQuestions().trim().length"
            >
              You still have to answer previous {{unansweredQuestions().length > 1 ? "questions " : "question "}} {{ unansweredQuestions() }} before clicking Submit.
            </p>
            <p
              class="error-message"
              *ngIf="
                kbaQuestions.controls[question.questionId].invalid &&
                (kbaQuestions.controls[question.questionId].dirty ||
                  kbaQuestions.controls[question.questionId].touched)
              "
            >
              {{ "Please answer the question before clicking " + (cdkStepper.isLastStep() ? "Submit." : "Next.") }}
            </p>
            <div class="choices">
              <div class="choice" *ngFor="let choice of question.choices">
                <input
                  type="radio"
                  formControlName="{{ question.questionId }}"
                  value="{{ choice.choiceId }}"
                  id="{{ choice.choiceId }}"
                />
                <label for="{{ choice.choiceId }}">{{ choice.text.statement }}</label>
              </div>

              <p class="question__help-text" *ngIf="question.helpText?.statement">
                {{ question.helpText.statement }}
              </p>
            </div>
          </fieldset>
          <app-button-container>
            <app-button
              *ngIf="!cdkStepper.isFirstStep()"
              type="tertiary"
              (click)="cdkStepper.previous()"
              [dataTestId]="'back-kba-questions'"
            >Back
            </app-button
            >
            <app-button
              *ngIf="!cdkStepper.isLastStep()"
              type="primary"
              (click)="answer(question.questionId)"
              [dataTestId]="'next-kba-questions'"
            >Next
            </app-button
            >
            <app-button
              *ngIf="cdkStepper.isLastStep()"
              type="primary"
              [isDisabled]="!!unansweredQuestions()"
              (click)="goToNextStep()"
              [dataTestId]="'next-button-kba-questions'"
            >Submit
            </app-button
            >
          </app-button-container>
        </cdk-step>
      </app-stepper>
    </form>
  </app-wizard-step-wrapper>
