import { HttpClient } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';

import { fromEvent, interval, Observable, of, Subject } from 'rxjs';
import {
  catchError,
  debounceTime,
  filter,
  map,
  switchMap,
  takeUntil,
  takeWhile,
  tap,
  withLatestFrom,
} from 'rxjs/operators';

import {
  NotificationModel,
  NotificationsActions,
  NotificationType,
} from 'src/app/features/notifications';
import { ExceptionType } from 'src/app/features/notifications/models';
import {
  DEFAULT_NOTIFICATION_OPTIONS,
  INTERNET_CONNECTED_NOTIFICATION_OPTIONS,
  INTERNET_CONNECTED_USER_MESSAGE,
  INTERNET_DISCONNECTED_NOTIFICATION_OPTIONS,
  INTERNET_DISCONNECTED_USER_MESSAGE,
  INTERNET_RECONNECTING_NOTIFICATION_OPTIONS,
  INTERNET_RECONNECTING_USER_MESSAGE,
} from 'src/app/features/notifications/notifications.constants';
import { isRONOrKRON } from 'src/app/features/packages/store/selectors/packages.selectors';
import { RootStoreState } from 'src/app/store';
import { v4 as uuid } from 'uuid';

@Component({
  selector: 'app-internet-check',
  template: '',
})
export class InternetCheckComponent implements OnInit, OnDestroy {
  static requestTimeoutInMs = 5000;
  static toastTimeoutInMs = 4000;
  static pollingIntervalInMs = 10000;

  activeNotificationId: uuid;
  confirmedOnline = true;
  public confirmedOfflineEvents$: Observable<any>;
  public confirmedOnlineEvents$: Observable<any>;
  windowOfflineEvents$ = fromEvent(window, 'offline');
  windowOnlineEvents$ = fromEvent(window, 'online');
  private readonly onDestroyNotifier = new Subject();

  constructor(
    private readonly store: Store<RootStoreState.State>,
    private readonly httpClient: HttpClient
  ) {}

  ngOnInit(): void {
    this.confirmedOnlineEvents$ = this.windowOnlineEvents$.pipe(
      takeUntil(this.onDestroyNotifier),
      switchMap(() => this.checkConnectionSuccessful()),
      filter((connectionSuccess) => connectionSuccess),
      tap(() => this.showOnlineToast())
    );
    this.confirmedOnlineEvents$.subscribe();

    this.confirmedOfflineEvents$ = this.windowOfflineEvents$.pipe(
      takeUntil(this.onDestroyNotifier),
      switchMap(() => this.checkConnectionSuccessful()),
      withLatestFrom(this.store.select(isRONOrKRON)),
      filter(([connectionSuccess, isRonOrKron]) => !connectionSuccess && !isRonOrKron),
      tap(() => this.showOfflineToast()),
      debounceTime(InternetCheckComponent.toastTimeoutInMs),
      tap(() => this.showReconnectingToast()),
      switchMap(() => this.pollForConnection())
    );

    this.confirmedOfflineEvents$.subscribe();
  }

  ngOnDestroy(): void {
    this.onDestroyNotifier.next(undefined);
    this.onDestroyNotifier.complete();
  }

  checkConnectionSuccessful(): Observable<boolean> {
    const url = 'favicon.ico?_=' + Math.floor(1e9 * Math.random());
    return this.httpClient.get(url, { responseType: 'blob' }).pipe(
      map(() => true),
      catchError(() => of(false))
    );
  }

  pollForConnection(): Observable<any> {
    return interval(InternetCheckComponent.pollingIntervalInMs).pipe(
      takeWhile(() => !this.confirmedOnline),
      switchMap(() => this.checkConnectionSuccessful()),
      filter((connectionSuccess) => connectionSuccess),
      tap(() => this.showOnlineToast())
    );
  }

  setToastMessage(notification: NotificationModel): void {
    if (this.activeNotificationId) {
      this.store.dispatch(
        NotificationsActions.ClearNotification({
          payload: { notificationId: this.activeNotificationId },
        })
      );
    }

    this.activeNotificationId = notification.id;

    this.store.dispatch(
      NotificationsActions.AddNotification({
        payload: notification,
      })
    );
  }

  showOfflineToast(): void {
    this.confirmedOnline = false;
    this.setToastMessage({
      notificationType: NotificationType.Error,
      text: INTERNET_DISCONNECTED_USER_MESSAGE,
      id: uuid(),
      exceptionType: ExceptionType.DisplayMessage,
      options: {
        ...DEFAULT_NOTIFICATION_OPTIONS,
        ...INTERNET_DISCONNECTED_NOTIFICATION_OPTIONS,
      },
      logInAppInsights: true,
    });
  }

  showOnlineToast(): void {
    this.confirmedOnline = true;
    this.setToastMessage({
      notificationType: NotificationType.Success,
      text: INTERNET_CONNECTED_USER_MESSAGE,
      id: uuid(),
      exceptionType: ExceptionType.DisplayMessage,
      options: {
        ...DEFAULT_NOTIFICATION_OPTIONS,
        ...INTERNET_CONNECTED_NOTIFICATION_OPTIONS,
      },
      logInAppInsights: true,
    });
  }

  showReconnectingToast(): void {
    this.setToastMessage({
      notificationType: NotificationType.Warning,
      text: INTERNET_RECONNECTING_USER_MESSAGE,
      id: uuid(),
      exceptionType: ExceptionType.DisplayMessage,
      options: {
        ...DEFAULT_NOTIFICATION_OPTIONS,
        ...INTERNET_RECONNECTING_NOTIFICATION_OPTIONS,
      },
      logInAppInsights: true,
    });
  }
}
