import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { combineLatest, Observable } from 'rxjs';
import { distinctUntilChanged, map, startWith, tap } from 'rxjs/operators';
import { DocumentsSelectors } from 'src/app/features/documents';

import { RootStoreState } from 'src/app/store';

import { ModalsSelectors } from '../../store';

@Component({
  selector: 'app-loading-spinner',
  templateUrl: './loading-spinner.component.html',
  styleUrls: ['./loading-spinner.component.scss'],
})
export class LoadingSpinnerComponent implements OnInit {
  static identifier = 'LoadingSpinnerComponent';
  showLoadingSpinner$: Observable<boolean>;

  constructor(
    @Inject(DOCUMENT) private readonly document: Document,
    private readonly store: Store<RootStoreState.State>,
  ) {}

  ngOnInit(): void {
    this.showLoadingSpinner$ = combineLatest([
      this.store.pipe(
        select(ModalsSelectors.showLoadingSpinner),
        startWith(false),
      ),
      this.store.pipe(
        select(DocumentsSelectors.isProgressBarOpen),
        startWith(false),
      )
    ]).pipe(
      map(([showLoadingSpinner, isProgressBarOpen]) =>
        showLoadingSpinner && isProgressBarOpen
          ? false
          : showLoadingSpinner
      ),
      distinctUntilChanged(),
      tap((showLoadingSpinner) => {
        const redirectInformationComponent = this.document.querySelector(
          'app-root > app-redirect-information'
        );

        if (redirectInformationComponent) {
          redirectInformationComponent.setAttribute(
            'style',
            `display: ${showLoadingSpinner ? 'none' : 'block'}`
          );
        }
      })
    );
  }
}
