import { PlatformLocation } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ModalsActions } from 'src/app/features/modals';
import {
  PackageUser,
  PackageUserRole,
  PackageUsersActions,
  PackageUsersSelectors,
} from 'src/app/features/package-users';
import { Package, PackagesActions, PackagesSelectors } from 'src/app/features/packages';
import { RootStoreState } from 'src/app/store';

@Component({
  selector: 'app-signing-modal',
  templateUrl: './signing-details.component.html',
  styleUrls: ['./signing-details.component.scss'],
})
export class SigningDetailsComponent implements OnInit {
  static identifier = 'SigningDetailsComponent';
  package$: Observable<Package>;
  signingAgent$: Observable<PackageUser>;
  nonSigningAgentsExceptWitnesses$: Observable<Array<PackageUser>>;
  witnesses$: Observable<Array<PackageUser>>;
  areWitnessesPresent$: Observable<boolean>;

  isAtBottom = true;

  constructor(public location: PlatformLocation, private readonly store: Store<RootStoreState.State>) {}

  ngOnInit(): void {
    this.loadPackage();
    this.loadParticipants();
  }

  closeModal(): void {
    this.store.dispatch(ModalsActions.ClearModalComponent());
  }

  scrollDown(): void {
    const scrollRef = document.querySelector('.signing-details__body');
    scrollRef.scroll({
      top: scrollRef.scrollHeight,
      left: 0,
      behavior: 'smooth',
    });
  }

  onScroll(event) {
    if (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight - 1) {
      this.isAtBottom = true;
    } else {
      this.isAtBottom = false;
    }
  }

  private loadPackage() {
    this.store.dispatch(
      PackagesActions.GetPackageByActivePackageGuid({
        payload: { ignoreIfLoaded: true },
      })
    );
    this.package$ = this.store.select(PackagesSelectors.getPackage);
  }

  private loadParticipants() {
    this.store.dispatch(
      PackageUsersActions.FetchPackageUsers({
        payload: { ignoreIfLoaded: true },
      })
    );

    this.signingAgent$ = this.store.select(PackageUsersSelectors.getSigningAgent);

    this.nonSigningAgentsExceptWitnesses$ = this.store.select(PackageUsersSelectors.getNonSigningAgents).pipe(
      map((nonSigningAgents) => nonSigningAgents.filter((nsa) => nsa.userRoleCode !== PackageUserRole.WITNESS))
    );

    this.witnesses$ = this.store.select(PackageUsersSelectors.getNonSigningAgents).pipe(
      map((nonSigningAgents) => nonSigningAgents.filter((nsa) => nsa.userRoleCode === PackageUserRole.WITNESS)),
    );

    this.areWitnessesPresent$ = this.witnesses$.pipe(
      map((witnesses) => witnesses?.length > 0)
    );
  }
}
