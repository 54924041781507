import { RulesState } from '../store/state';

export const modifiedPropertiesHash = (modifiedPropertyNames: string[]) => {
  if (modifiedPropertyNames.length > 0) {
    const modifiedPropertiesReducer = (accumulator: string, currentValue: string) => {
      if (accumulator.length > 0) {
        return `${accumulator}-${currentValue}`;
      }
      return currentValue;
    };

    return modifiedPropertyNames.reduce(modifiedPropertiesReducer, '');
  }

  return RulesState.defaultRulesKeyName;
};
