import { IdVerificationSessionStatus } from '../../models';

export interface State {
  accessLink?: string;
  packageUserGuid: string;
  lastLinkTime: number;
  statusByUser: {
    [id: string]: IdVerificationSessionStatus;
  };
  hasAccessLinkSmsSentSuccessfully?: boolean;
}

export const initialState: State = {
  accessLink: null,
  packageUserGuid: '',
  lastLinkTime: 0,
  statusByUser: {},
  hasAccessLinkSmsSentSuccessfully: null,
};
