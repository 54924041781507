import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import { PackageUsersActions } from '../store';

@Injectable({ providedIn: 'root' })
export class PackageUsersResolver {
  constructor(private readonly store: Store) {}

  resolve() {
    this.store.dispatch(PackageUsersActions.packageUserResolverLoaded());
  }
}
