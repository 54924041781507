import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { CommissionsModule } from 'src/app/features/commissions/commissions.module';
import { ConsentsModule } from 'src/app/features/consents';
import { EndorsementsCreationModule } from 'src/app/features/endorsements-creation';
import { IdVerificationModule } from 'src/app/features/id-verification/id-verification.module';
import { KnowledgeBasedAuthenticationModule } from 'src/app/features/knowledge-based-authentication/knowledge-based-authentication.module';
import { PackageUsersModule } from 'src/app/features/package-users';
import { PackagesModule } from 'src/app/features/packages';
import { SharedModule } from 'src/app/features/shared';
import { SignerDetailsModule } from 'src/app/features/signer-details/signer-details.module';
import { ChimeModule } from 'src/app/features/video-conference/chime';
import { WizardModule } from 'src/app/features/wizard';
import { LayoutModule } from '../layout/layout.module';

import {
  AddParticipantModalComponent,
  FetchPackageUsersComponent,
  NonSigningAgentWelcomePageComponent,
  NotaryModalComponent,
  SigningAgentWelcomePageComponent,
  StepTrackerComponent,
  SwitchDevicesConfirmationModalComponent,
  WelcomeParticipantComponent,
} from './components';
import {
  CheckInCompleteComponent,
  CheckInWizardComponent,
  NonRemoteWelcomePageComponent,
  RemoteWelcomePageComponent,
  WaitingRoomComponent,
  WaitingRoomSnackBarComponent,
} from './containers';
import { AllUsersCheckedInGuard, IsCheckInActiveGuard } from './guards';
import { RemoteSidebarContentResolver } from './resolvers';
import { CheckInStatusService, WaitingRoomService } from './services';
import { CheckInEffects } from './store/effects';
import { CheckInReducers } from './store/reducers';

@NgModule({
  declarations: [
    CheckInCompleteComponent,
    CheckInWizardComponent,
    NonRemoteWelcomePageComponent,
    NotaryModalComponent,
    RemoteWelcomePageComponent,
    StepTrackerComponent,
    SwitchDevicesConfirmationModalComponent,
    AddParticipantModalComponent,
    WelcomeParticipantComponent,
    NonSigningAgentWelcomePageComponent,
    SigningAgentWelcomePageComponent,
    WaitingRoomComponent,
    WaitingRoomSnackBarComponent,
  ],
  imports: [
    CommonModule,
    CommissionsModule,
    ConsentsModule,
    EndorsementsCreationModule,
    IdVerificationModule,
    KnowledgeBasedAuthenticationModule,
    LayoutModule,
    PackageUsersModule,
    SharedModule,
    SignerDetailsModule,
    WizardModule,
    StoreModule.forFeature('checkIn', CheckInReducers.Reducers.checkInReducer),
    EffectsModule.forFeature([CheckInEffects]),
    PackagesModule,
    FetchPackageUsersComponent,
    ChimeModule,
  ],
  exports: [
    CheckInCompleteComponent,
    CheckInWizardComponent,
    NonRemoteWelcomePageComponent,
    RemoteWelcomePageComponent,
    WelcomeParticipantComponent,
    WaitingRoomComponent,
  ],
  providers: [
    AllUsersCheckedInGuard,
    CheckInStatusService,
    WaitingRoomService,
    IsCheckInActiveGuard,
    RemoteSidebarContentResolver,
  ],
})
export class CheckInModule {}
