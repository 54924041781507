import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { DefaultProjectorFn, MemoizedSelector, select, Store } from '@ngrx/store';

import { of } from 'rxjs';
import { catchError, concatMap, exhaustMap, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { CheckInSelectors } from 'src/app/features/check-in/store';

import { DeviceGroupActions, DeviceGroupSelectors } from 'src/app/features/device-group';
import { EndorsementsActions } from 'src/app/features/endorsements';
import { ModalsActions } from 'src/app/features/modals';
import { MsalAuthActions } from 'src/app/features/msal-auth';
import { NotificationsActions, NotificationType } from 'src/app/features/notifications';
import { ExceptionType } from 'src/app/features/notifications/models';
import { PackageUsersSelectors } from 'src/app/features/package-users';
import { PackagesActions, PackagesSelectors } from 'src/app/features/packages';
import { PRODUCT_TYPE_ROUTE_MAP } from 'src/app/features/packages/packages.constants';
import { SignalRActions } from 'src/app/features/signal-r';
import { VideoActions } from 'src/app/features/video/store';
import { RootStoreState } from 'src/app/store';
import { v4 as uuid } from 'uuid';

import { UnableToProcessModalComponent } from '../../components/unable-to-process-modal/unable-to-process-modal.component';
import { ParticipantVerificationService } from '../../services';
import { ParticipantVerificationActions } from '../actions';

@Injectable()
export class ParticipantVerificationEffects {
  public activePackageUserGuidSelector: MemoizedSelector<
    object,
    string,
    DefaultProjectorFn<string>
  >;
  failedToSaveApprovedParticipant = 'Failed to save approved participant';

  getIdVerifications$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ParticipantVerificationActions.GetIdVerifications),
      concatMap((action) =>
        of(action).pipe(
          withLatestFrom(this.store.pipe(select(PackagesSelectors.getActivePackageGuid)))
        )
      ),
      switchMap(([_, activePackageGuid]) => {
        return this.participantVerificationService.getIdVerifications(activePackageGuid).pipe(
          map((payload) => ParticipantVerificationActions.SetIdVerifications({ payload })),
          catchError((err) =>
            of(
              NotificationsActions.AddNotification({
                payload: {
                  exception: err,
                  notificationType: NotificationType.Error,
                  id: uuid(),
                  text: 'Failed to get IdVerifications',
                },
              })
            )
          )
        );
      })
    )
  );

  approveParticipant$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ParticipantVerificationActions.ApproveParticipant),
      concatMap((action) =>
        of(action).pipe(
          withLatestFrom(
            this.store.select(PackageUsersSelectors.getActivePackageUserGuid),
            this.store.select(PackagesSelectors.getActivePackageGuid),
            this.store.select(PackageUsersSelectors.getPackageUser(action.payload)),
            this.store.select(DeviceGroupSelectors.getDeviceCode)
          )
        )
      ),
      switchMap(([_, activePackageUserGuid, activePackageGuid, packageUser, deviceCode]) => {
        return this.participantVerificationService
          .approveParticipant({
            packageGuid: activePackageGuid,
            packageUserGuid: activePackageUserGuid,
            participantBeingVerifiedGuid: packageUser.packageUserGuid,
            deviceCode: deviceCode,
            eventAuditData: `UserName:${packageUser.firstName} ${packageUser.lastName}`,
          })
          .pipe(
            map((response) =>
              ParticipantVerificationActions.ApproveParticipantSuccess({ payload: response })
            ),
            catchError((err) =>
              of(
                NotificationsActions.AddNotification({
                  payload: {
                    exception: err,
                    notificationType: NotificationType.Error,
                    id: uuid(),
                    text: this.failedToSaveApprovedParticipant,
                  },
                })
              )
            )
          );
      })
    )
  );

  denyParticipant$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ParticipantVerificationActions.DenyParticipant),
      concatMap((action) =>
        of(action).pipe(
          withLatestFrom(
            this.store.select(PackageUsersSelectors.getActivePackageUserGuid),
            this.store.select(PackagesSelectors.getActivePackageGuid),
            this.store.select(PackageUsersSelectors.getPackageUser(action.payload)),
            this.store.pipe(select(DeviceGroupSelectors.getDeviceCode))
          )
        )
      ),
      switchMap(([_, activePackageUserGuid, activePackageGuid, packageUser, deviceCode]) => {
        return this.participantVerificationService
          .denyParticipant({
            packageGuid: activePackageGuid,
            packageUserGuid: activePackageUserGuid,
            deviceCode: deviceCode,
            eventAuditData: `UserName:${packageUser.firstName} ${packageUser.lastName}`,
          })
          .pipe(
            switchMap(() => [
              ParticipantVerificationActions.DenyParticipantSuccess(),
              PackagesActions.CancelPackage({
                payload: {
                  categoryTypeCode: 'IdVerificationDeny',
                },
              }),
            ]),
            catchError((err) => [
              ModalsActions.HideLoadingSpinner(),
              NotificationsActions.AddNotification({
                payload: {
                  exception: err,
                  notificationType: NotificationType.Error,
                  id: uuid(),
                  text: 'Failed to deny Participant and opt out Package',
                },
              }),
            ])
          );
      })
    )
  );

  denyRejoiningParticipant$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ParticipantVerificationActions.DenyRejoiningParticipant),
      concatMap((action) =>
        of(action).pipe(
          withLatestFrom(
            this.store.select(PackageUsersSelectors.getActivePackageUserGuid),
            this.store.select(PackagesSelectors.getActivePackageGuid),
            this.store.select(PackageUsersSelectors.getPackageUser(action.packageUserGuid))
          )
        )
      ),
      switchMap(([action, activePackageUserGuid, activePackageGuid, packageUser]) => {
        return this.participantVerificationService
          .denyRejoiningParticipant({
            packageGuid: activePackageGuid,
            packageUserGuid: activePackageUserGuid,
            participantBeingVerifiedGuid: action.packageUserGuid,
            deviceCode: action.deviceCode,
            eventAuditData: `UserName:${packageUser.firstName} ${packageUser.lastName}`,
          })
          .pipe(
            switchMap((response) => [
              ParticipantVerificationActions.DenyRejoiningParticipantSuccess({ payload: response }),
            ]),
            catchError((err) => [
              ModalsActions.HideLoadingSpinner(),
              NotificationsActions.AddNotification({
                payload: {
                  exception: err,
                  notificationType: NotificationType.Error,
                  id: uuid(),
                  text: 'Failed to deny rejoining participant',
                },
              }),
            ])
          );
      })
    )
  );

  approveRejoiningParticipant$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ParticipantVerificationActions.ApproveRejoiningParticipant),
      concatMap((action) =>
        of(action).pipe(
          withLatestFrom(
            this.store.select(PackageUsersSelectors.getActivePackageUserGuid),
            this.store.select(PackagesSelectors.getActivePackageGuid),
            this.store.select(PackageUsersSelectors.getPackageUser(action.packageUserGuid))
          )
        )
      ),
      switchMap(([action, activePackageUserGuid, activePackageGuid, packageUser]) => {
        return this.participantVerificationService
          .approveRejoiningParticipant({
            packageGuid: activePackageGuid,
            packageUserGuid: activePackageUserGuid,
            participantBeingVerifiedGuid: action.packageUserGuid,
            deviceCode: action.deviceCode,
            eventAuditData: `UserName:${packageUser.firstName} ${packageUser.lastName}`,
          })
          .pipe(
            map((response) =>
              ParticipantVerificationActions.ApproveParticipantSuccess({ payload: response })
            ),
            catchError((err) => [
              ModalsActions.HideLoadingSpinner(),
              NotificationsActions.AddNotification({
                payload: {
                  exception: err,
                  notificationType: NotificationType.Error,
                  id: uuid(),
                  text: 'Failed to approve rejoining participant',
                },
              }),
            ])
          );
      })
    )
  );

  rejoiningParticipantDenied$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ParticipantVerificationActions.RejoiningParticipantDenied),
      concatMap((action) =>
        of(action).pipe(
          withLatestFrom(
            this.store.pipe(select(PackageUsersSelectors.getActivePackageUserGuid)),
            this.store.pipe(select(DeviceGroupSelectors.getDeviceCode)),
            this.store.pipe(select(DeviceGroupSelectors.getUsersOnDevice))
          )
        )
      ),
      exhaustMap(([action, activePackageUserGuid, deviceCode, usersOnDevice]) => {
        if (
          (action.packageUserGuid.toLowerCase() === activePackageUserGuid.toLowerCase() &&
          action.deviceCode.toLowerCase() === deviceCode.toLowerCase()) ||
          usersOnDevice.length === 0
        ) {
          return [
            ParticipantVerificationActions.DeniedRejoinedParticipant({
              payload: { packageUserGuid: action.packageUserGuid },
            }),
          ];
        } else {
          return [
            EndorsementsActions.UpdateSignableOnDeviceStatusByPackageUserGuid({
                payload: { packageUserGuid: action.packageUserGuid, enable: false },
            }),
            DeviceGroupActions.RemovePackageUserFromDevice({
              payload: { packageUserGuid: action.packageUserGuid },
            }),
          ];          
        }

        return [];
      })
    )
  );

  rejoiningParticipantApproved$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ParticipantVerificationActions.RejoiningParticipantApproved),
      concatMap((action) =>
        of(action).pipe(
          withLatestFrom(
            this.store.pipe(select(DeviceGroupSelectors.getDeviceCode)),
            this.store.pipe(select(DeviceGroupSelectors.getUsersOnDevice)),
            this.store.pipe(select(PackageUsersSelectors.getIsSigningAgent))
          )
        )
      ),
      exhaustMap(([action, deviceCode, usersOnDevice, isSigningAgent]) => {
        const requestedActions = [];
        if (!isSigningAgent) {
          if (action.deviceCode.toLowerCase() === deviceCode.toLowerCase()) {
            requestedActions.push(
              MsalAuthActions.RefreshTokenForVerifiedUser({
                payload: {
                  packageUserGuid: action.packageUserGuid,
                },
              })
            );
          } else {
            if (usersOnDevice.length >= 1) {
              requestedActions.push(
                EndorsementsActions.UpdateSignableOnDeviceStatusByPackageUserGuid({
                  payload: { packageUserGuid: action.packageUserGuid, enable: false },
                })
              );
            } else {
              requestedActions.push(
                ParticipantVerificationActions.DeniedRejoinedParticipant({
                  payload: { packageUserGuid: action.packageUserGuid },
                })
              );
            }
          }
        }
        return requestedActions;
      })
    )
  );

  getApprovedParticipants$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ParticipantVerificationActions.GetApprovedParticipants),
      switchMap((action) =>
        this.participantVerificationService.getApprovedParticipants().pipe(
          switchMap((participants) => [
            ParticipantVerificationActions.GetApprovedParticipantsSuccess({
              payload: participants,
            }),
          ]),
          catchError((err) =>
            of(
              NotificationsActions.AddNotification({
                payload: {
                  exception: err,
                  notificationType: NotificationType.Error,
                  id: uuid(),
                  text: this.failedToSaveApprovedParticipant,
                },
              })
            )
          )
        )
      )
    )
  );

  getAllSignersApproved$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ParticipantVerificationActions.GetAllSignersApproved),
      withLatestFrom(this.store.pipe(select(PackageUsersSelectors.getActivePackageUserGuid))),
      switchMap(([_, activePackageUserGuid]) => {
        return this.participantVerificationService
          .getAreAllParticipantVerificationsComplete(activePackageUserGuid)
          .pipe(
            switchMap((areAllParticipantVerificationsComplete) => {
              return [
                ParticipantVerificationActions.SetAllSignersApproved({
                  payload: { allSignersApproved: areAllParticipantVerificationsComplete },
                }),
              ];
            }),
            catchError((err) =>
              of(
                NotificationsActions.AddNotification({
                  payload: {
                    notificationType: NotificationType.Error,
                    id: uuid(),
                    text: 'Failed to get AllSignersApproved',
                    exceptionType: ExceptionType.CannotProceed,
                    logInAppInsights: true,
                  },
                })
              )
            )
          );
      })
    )
  );

  configureListeners$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SignalRActions.SignalRRoomJoined),
        map(() => {
          this.participantVerificationService.configureListeners();
        })
      ),
    { dispatch: false }
  );

  showUnableToProcessModal$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ParticipantVerificationActions.ShowUnableToProcessModal),
      map((action) =>
        ModalsActions.SetStandaloneModalComponent({
          payload: {
            component: UnableToProcessModalComponent,
          },
        })
      )
    )
  );

  deniedRejoinedParticipant$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ParticipantVerificationActions.DeniedRejoinedParticipant),
      concatMap((action) =>
        of(action).pipe(withLatestFrom(this.store.pipe(select(DeviceGroupSelectors.getDeviceCode))))
      ),
      exhaustMap(([action, deviceCode]) => {
        return [
          DeviceGroupActions.SetUnauthorizedDeviceCode({ deviceCode }),
          PackagesActions.RedirectUnAuthorized(),
          VideoActions.StopVideo(),
          SignalRActions.LeaveSignalRRoom({
            payload: { packageUserGuid: action.payload.packageUserGuid },
          }),
          SignalRActions.StopSignalRConnection(),
        ];
      })
    )
  );

  removeParticipantFromDevice$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ParticipantVerificationActions.RemoveParticipantFromDevice),
      concatMap((action) =>
        of(action).pipe(
          withLatestFrom(
            this.store.pipe(select(DeviceGroupSelectors.getUsersOnDevice)),
            this.store.pipe(select(DeviceGroupSelectors.getDeviceCode)),
            this.store.pipe(select(CheckInSelectors.getIsCheckInComplete)),
            this.store.pipe(select(PackagesSelectors.getProductType))
          )
        )
      ),
      exhaustMap(([action, usersOnDevice, activeSessionId, isCheckInCompleted, productType]) => {
        const requestedActions = [];

        if (isCheckInCompleted) {
          return requestedActions;
        }

        if (
          usersOnDevice.some((user) => user.packageUserGuid === action.payload.packageUserGuid) &&
          activeSessionId !== action.payload.sessionId
        ) {
          requestedActions.push(
            DeviceGroupActions.RemovePackageUserFromDevice({
              payload: { packageUserGuid: action.payload.packageUserGuid },
            })
          );

          this.router.navigate([`/${PRODUCT_TYPE_ROUTE_MAP.get(productType)}/welcome`], {
            queryParamsHandling: 'preserve',
            replaceUrl: true,
          });

          requestedActions.push(
            NotificationsActions.AddNotification({
              payload: {
                exceptionType: ExceptionType.DisplayMessage,
                notificationType: NotificationType.Info,
                id: uuid(),
                text: 'The check-in process has moved to another device.',
                logInAppInsights: false,
                options: {
                  closeButton: true,
                  tapToDismiss: true,
                  timeOut: 5000,
                },
              },
            })
          );
        }

        return requestedActions;
      })
    )
  );

  constructor(
    private readonly actions$: Actions,
    private readonly participantVerificationService: ParticipantVerificationService,
    private readonly store: Store<RootStoreState.State>,
    private readonly router: Router,
  ) {
    this.activePackageUserGuidSelector = PackageUsersSelectors.getActivePackageUserGuid;
  }
}
