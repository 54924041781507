import { Injectable } from '@angular/core';
import { Route } from '@angular/router';
import { select, Store } from '@ngrx/store';

import { Observable, of } from 'rxjs';
import { filter, first, switchMap } from 'rxjs/operators';

import { PackagesSelectors } from '../features/packages';
import { RootStoreState } from '../store';

@Injectable({
  providedIn: 'root',
})
export class PackageGuidGuard {
  constructor(private readonly store: Store<RootStoreState.State>) {}

  canLoad(route: Route): Observable<boolean> {
    return this.store.pipe(
      select(PackagesSelectors.getActivePackageGuid),
      filter((guid) => !!guid),
      switchMap(() => of(true)),
      first()
    );
  }
}
