import {
  Component,
  OnInit,
  HostBinding,
  Input,
  OnDestroy,
} from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Subject } from 'rxjs';
import { tap } from 'rxjs/operators';

import { RootStoreState } from 'src/app/store';

import { DocumentEndorsementLocation } from '../../models';
import { EndorsementsSelectors } from '../../store';

@Component({
  selector: 'app-endorsement-flag',
  templateUrl: './endorsement-flag.component.html',
  styleUrls: ['./endorsement-flag.component.scss'],
})
export class EndorsementFlagComponent implements OnInit, OnDestroy {
  @Input() endorsementId: number;
  @Input() currentPackageUserId: string;
  @Input() documentScale: number;
  @HostBinding('style.top.px') public get yPosition(): number {
    return this.endorsementLocation
      ? this.endorsementLocation.yPosition * this.documentScale
      : null;
  }

  endorsementLocation: DocumentEndorsementLocation;
  notifier = new Subject();

  constructor(private readonly store: Store<RootStoreState.State>) {}
  ngOnInit(): void {
    this.store
      .pipe(
        select(EndorsementsSelectors.selectEndorsementLocationById, {
          endorsementLocationId: this.endorsementId,
        }),
        tap(
          (endorsementLocation) =>
            (this.endorsementLocation = endorsementLocation)
        )
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.notifier.next(undefined);
    this.notifier.complete();
  }
}
