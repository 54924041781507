import { Action, createReducer, on } from '@ngrx/store';
import { SignalRActions } from '../actions';

import { SignalRState } from '../state';
import { HubConnectionState } from '../../models';

const reducer = createReducer(
  SignalRState.initialState,
  on(
    SignalRActions.SignalRRoomJoined,
    (state: SignalRState.State): SignalRState.State => ({
      ...state,
      groupJoined: true,
    })
  ),
  on(
    SignalRActions.SignalRHubConnectionSuccessful,
    (state: SignalRState.State): SignalRState.State => ({
      ...state,
      hubConnectionState: HubConnectionState.Connected,
    })
  ),
  on(
    SignalRActions.SignalRConnectionClosed,
    (state: SignalRState.State): SignalRState.State => ({
      ...state,
      groupJoined: false,
      hubConnectionState: HubConnectionState.Disconnected,
    })
  )
);

export function signalRReducer(state: SignalRState.State | undefined, action: Action) {
  return reducer(state, action);
}
