<div class="{{ 'toast-notification toast-notification--' + toastPackage.toastType }}">
  <div class="toast-notification__icon">
    <span
      [ngClass]="{
        fas: true,
        'fa-exclamation-circle': toastPackage.toastType === 'error',
        'fa-exclamation-triangle': toastPackage.toastType === 'warning',
        'fa-bell': toastPackage.toastType === 'info',
        'fa-check-circle': toastPackage.toastType === 'success'
      }"
    ></span>
  </div>
  <div class="toast-notification__background">
    <div
      class="toast-notification__message"
      data-testid="toast-text"
      [innerHtml]="toastPackage.message"
    ></div>
    <div class="toast-notification__action-container">
      <button
        class="button button__reload"
        *ngIf="showReloadButton"
        (click)="reload()"
        data-testid="toast-reload"
      >
        Reload Page
      </button>
      <div class="loading-icon" *ngIf="showLoadingSpinner">
        <span class="fas fa-spin fa-circle-notch"></span>
      </div>
      <button
        class="button button__close"
        *ngIf="options.closeButton"
        (click)="removeToast()"
        data-testid="toast-close"
      >
        <span class="fa fa-2x fa-times"></span>
      </button>
    </div>
  </div>
</div>
