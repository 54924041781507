<div class="signing-details">
  <div class="signing-details__header">
    <h3 data-testid="modal-header">Order details</h3>
  </div>
  <div
    (scroll)="onScroll($event)"
    class="signing-details__body"
    *ngIf="package$ | async as package"
  >
    <div class="signing-details__item" data-testid="signing-modal-details">
      <div class="signing-details__item__label" data-testid="signing-modal-details-label">
        Property Address:
      </div>
      <div
        class="signing-details__item__value"
        data-testid="signing-modal-details-value"
        data-private
      >
        {{ package.streetAddress1 }}, {{ package.city }}, {{ package.stateCode }} {{ package.zip }}
      </div>
    </div>

    <div class="signing-details__item" data-testid="signing-modal-details">
      <div class="signing-details__item__label" data-testid="signing-modal-details-label">
        Signing Agent:
      </div>
      <div
        class="signing-details__item__value"
        data-testid="signing-modal-details-value"
        data-private
      >
        {{ signingAgent$ | async | formatPackageUserName }}
      </div>
    </div>

    <div class="signing-details__item" data-testid="signing-modal-details">
      <div class="signing-details__item__label" data-testid="signing-modal-details-label">
        Participants:
      </div>
      <div
        class="signing-details__item__value"
        data-testid="signing-modal-details-value"
        data-private
      >
        <div
          *ngFor="let participant of nonSigningAgentsExceptWitnesses$ | async"
          [title]="participant | formatPackageUserName"
        >
          {{ participant | formatPackageUserName }}
        </div>
      </div>
    </div>

    <div
      class="signing-details__item"
      data-testid="signing-modal-details"
      *ngIf="areWitnessesPresent$ | async"
    >
      <div class="signing-details__item__label" data-testid="signing-modal-details-label">
        Witnesses:
      </div>
      <div
        class="signing-details__item__value"
        data-testid="signing-modal-details-value"
        data-private
      >
        <div
          *ngFor="let witness of witnesses$ | async; let i = index"
          [title]="witness | formatPackageUserName: 'Witness ' + (i + 1)"
        >
          {{ witness | formatPackageUserName: 'Witness ' + (i + 1) }}
        </div>
      </div>
    </div>

    <div class="signing-details__item" data-testid="signing-modal-details">
      <div class="signing-details__item__label" data-testid="signing-modal-details-label">
        Loan #:
      </div>
      <div
        class="signing-details__item__value"
        data-testid="signing-modal-details-value"
        data-private
      >
        {{ package.clientLoanNumber }}
      </div>
    </div>
    <div class="signing-details__item" data-testid="signing-modal-details">
      <div class="signing-details__item__label" data-testid="signing-modal-details-label">
        Order #:
      </div>
      <div
        class="signing-details__item__value"
        data-testid="signing-modal-details-value"
        data-private
      >
        {{ package.packageId }}
      </div>
    </div>
    <div class="signing-details__item" data-testid="signing-modal-details">
      <div class="signing-details__item__label" data-testid="signing-modal-details-label">
        3rd Party ID #:
      </div>
      <div
        class="signing-details__item__value"
        data-testid="signing-modal-details-value"
        data-private
      >
        {{ package.vendorOrderNumber }}
      </div>
    </div>

    <app-button-container>
      <app-button type="primary" (click)="closeModal()" [dataTestId]="'modal-close-order-details'">
        Close
      </app-button>
    </app-button-container>
  </div>
  <div class="signing-details__down-button" *ngIf="!isAtBottom">
    <button type="button" class="btn btn--scroll-down-button" (click)="scrollDown()">
      <img src="../../../../../assets/images/arrow-down-circle.svg" alt="arrow down button" />
    </button>
  </div>
</div>
