<div>
  <div>
    <div class="checkin-group__header" data-testid="lobby-list-sa">
      <div class="checkin-group__title" data-testid="lobby-list-sa-header">Signing Agent</div>
      <div *ngIf="!isReadonly" class="checkin-group__message" data-testid="lobby-list-sa-text">
        Please check-in below.
      </div>
    </div>
    <app-lobby-participant
      [participant]="signingAgent"
      [isClickable]="!isReadonly && isUserSigningAgent"
      [isEnteringSigningSession]="isCheckinCompleted$ | async"
      (participantClick)="beginSigningAgentCheckin($event)"
    >
    </app-lobby-participant>
  </div>
  <div>
    <div class="checkin-group__header" data-testid="lobby-list-nsa">
      <span class="checkin-group__title" data-testid="lobby-list-nsa-header">Participants</span>
      <span *ngIf="!isReadonly" class="checkin-group__message" data-testid="lobby-list-nsa-text"
        >All participants must check-in below.</span
      >
    </div>
    <app-lobby-participant
      *ngFor="let participant of nonSigningAgentsExceptWitnesses"
      [participant]="participant"
      [isNewSession]="isNewSession"
      [isClickable]="isNonSigningAgentClickable$ | async"
      [isEnteringSigningSession]="isCheckinCompleted$ | async"
      (participantClick)="openParticipantPinModal($event)"
    ></app-lobby-participant>
  </div>
  <div *ngIf="this.witnesses?.length > 0">
    <div class="checkin-group__header" data-testid="lobby-list-witness">
      <span class="checkin-group__title" data-testid="lobby-list-witness-header">Witnesses</span>
      <span
        *ngIf="!isReadonly"
        class="checkin-group__message"
        data-testid="lobby-list-witness-text"
      >
        Witnesses check-in below. If your name is not listed, choose an option listed as
        <strong>Witness</strong> and <strong>Not Started</strong>.
      </span>
    </div>
    <app-lobby-participant
      *ngFor="let witness of witnesses; let i = index"
      [participant]="witness"
      [isNewSession]="isNewSession"
      [isClickable]="isNonSigningAgentClickable$ | async"
      [isEnteringSigningSession]="isCheckinCompleted$ | async"
      [placeholderName]="'Witness ' + (i + 1)"
      (participantClick)="openParticipantPinModal($event)"
    ></app-lobby-participant>
  </div>
</div>
