import { A11yModule } from '@angular/cdk/a11y';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { SharedModule } from 'src/app/features/shared';

import {
  GenericSpinnerComponent,
  LoadingSpinnerComponent,
  ModalContainerComponent,
} from './components';
import { ModalContentDirective } from './directives/modal-content.directive';
import { ModalDialogDirective } from './directives/modal-dialog.directive';
import { ModalContentComponent } from './modal-content/modal-content.component';
import { ModalsReducers } from './store';
import { ModalsEffects } from './store/effects';

@NgModule({
  declarations: [
    ModalContainerComponent,
    ModalContentDirective,
    ModalDialogDirective,
    GenericSpinnerComponent,
    LoadingSpinnerComponent,
    ModalContentComponent,
  ],
  imports: [
    A11yModule,
    CommonModule,
    SharedModule,
    StoreModule.forFeature('modals', ModalsReducers.modalsReducer),
    EffectsModule.forFeature([ModalsEffects.ModalsEffects]),
    MatProgressSpinnerModule,
  ],
  exports: [
    ModalContainerComponent,
    GenericSpinnerComponent,
    LoadingSpinnerComponent,
    ModalContentComponent,
  ],
  providers: [],
})
export class ModalsModule {}
