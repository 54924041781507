import { PlatformLocation } from '@angular/common';
import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { ClientSelectors } from 'src/app/features/client';
import {
  PackageUser,
  PackageUsersActions,
  PackageUsersSelectors,
} from 'src/app/features/package-users';
import { Package } from 'src/app/features/packages';
import { IdImages } from 'src/app/features/participant-verification/models';
import { ParticipantVerificationSelectors } from 'src/app/features/participant-verification/store';

import { RootStoreState } from 'src/app/store';

import { ModalsActions } from 'src/app/features/modals';
import { IdSides } from './idSide.enum';

@Component({
  selector: 'app-id-verify',
  templateUrl: './id-verify.component.html',
  styleUrls: ['./id-verify.component.scss'],
})
export class IdVerifyComponent implements OnInit, OnDestroy {
  static identifier = 'IdVerifyComponent';

  package$: Observable<Package>;
  participants$: Observable<Array<PackageUser>>;
  idImages$: Observable<IdImages>;

  selectedParticipant: PackageUser;
  showFrontImage: boolean;
  showBackImage: boolean;
  selectedRadioButton = '1';

  selectedParticipantSubscription: Subscription;

  imageWrapperRotation = 0;
  get imageWrapperStyle() {
    return `transform: rotate(${this.imageWrapperRotation}deg)`;
  }

  @ViewChild('idImage') idImage: ElementRef;
  @ViewChild('imageWrapper') imageWrapper: ElementRef;

  constructor(
    public location: PlatformLocation,
    private readonly store: Store<RootStoreState.State>
  ) {}

  ngOnInit(): void {
    this.loadParticipants();
  }

  ngOnDestroy() {
    this.selectedParticipantSubscription.unsubscribe();
  }

  closeModal(): void {
    this.store.dispatch(ModalsActions.ClearModalComponent());
  }

  private loadParticipants() {
    this.store.dispatch(
      PackageUsersActions.FetchPackageUsers({
        payload: { ignoreIfLoaded: true },
      })
    );
    this.participants$ = this.store.select(PackageUsersSelectors.getNonSigningAgents);
    this.selectedParticipantSubscription = this.participants$.subscribe((participants) => {
      this.selectedParticipant = participants[0];
      this.getIdImagesForParticipant();
    });
  }

  onParticipantChange(participant: PackageUser) {
    this.selectedParticipant = participant;
    this.getIdImagesForParticipant();
  }

  private getIdImagesForParticipant() {
    this.idImages$ = this.store.pipe(
      select(ClientSelectors.shouldBypassRealId),
      switchMap((shouldBypassRealId) =>
        this.store.pipe(
          select(ParticipantVerificationSelectors.getIdImagesForParticipant, {
            packageUserGuid: this.selectedParticipant.packageUserGuid,
            shouldBypassRealId,
          })
        )
      )
    );
    this.toggleIdImages(IdSides.FRONT);
  }

  viewIdImage() {
    if (this.selectedRadioButton === '1') {
      this.toggleIdImages(IdSides.FRONT);
    } else if (this.selectedRadioButton === '2') {
      this.toggleIdImages(IdSides.BACK);
    }
  }

  private toggleIdImages(idSide: string) {
    if (idSide === IdSides.FRONT) {
      this.showFrontImage = true;
      this.showBackImage = false;
    } else if (idSide === IdSides.BACK) {
      this.showFrontImage = false;
      this.showBackImage = true;
    }

    requestAnimationFrame(() => this.setImageWrapperWidth(this.imageWrapper.nativeElement, this.idImage.nativeElement));
  }

  onRotate(idImage: HTMLImageElement): void {
    this.imageWrapperRotation -= 90;

    this.setImageWrapperWidth(this.imageWrapper.nativeElement, idImage);
  }

  setImageWrapperWidth(imageWrapper: HTMLElement, { naturalHeight, naturalWidth }: HTMLImageElement): void {
    imageWrapper.style.width = naturalWidth > naturalHeight ? '100%' : '50%';
  }
}
