export enum VideoLayout {
  single,
  double,
  triple
}

// Layout relates to the nubmer of columns needed on desktop.
// Mobile layouts will either be one or two rows depending on the number of users
// with the number per row being 6 users
// Single: <4 users
// Double: 4-8 users
// Triple: 9+ users
