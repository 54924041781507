<app-wizard-step-wrapper
  [showStep]="showStep"
  [stepTitle]="stepMetadata.stepName"
  [formGroup]="formStep"
>
  <div class="form-step__subheading" data-testid="check-in-subheader">
    {{ subHeadingText }}
  </div>

  <h3 [class.title--error]="submissionFailure">
    Privacy Policy and Terms of Use{{ submissionFailure ? '*' : '' }}
  </h3>

  <div class="consent">
    <mat-checkbox
      class="consent__checkbox"
      data-testid="privacy-policy-checkbox"
      [formControl]="privacyPolicyConsentCheckbox"
      (change)="togglePrivacyPolicyConsentCheckbox()"
    >
      I agree to the
    </mat-checkbox>
    <a
      href="javascript:"
      class="consent__link"
      (click)="openPrivacyPolicyConsentModal()"
      data-testid="privacy-policy-modal"
    >
      Privacy Policy</a
    >
  </div>

  <div class="consent">
    <mat-checkbox
      class="consent__checkbox"
      data-testid="terms-of-use-checkbox"
      [formControl]="termsOfUseConsentCheckbox"
      (change)="toggleTermsOfUseConsentCheckbox()"
    >
      I agree to the
    </mat-checkbox>
    <a
      href="javascript:"
      class="consent__link"
      (click)="openTermsOfUseConsentModal()"
      data-testid="terms-of-use-modal"
      >Terms of Use</a
    >
  </div>

  <p class="consent-error-message" *ngIf="submissionFailure">
    Please check both boxes before continuing.
  </p>

  <p class="consent-text">{{ (termsAndConditionsConsent$ | async)?.consentText }}</p>

  <app-button-container>
    <app-button [dataTestId]="'decline-button'" type="tertiary" (click)="openDeclineModal()">
      Decline
    </app-button>
    <app-button [dataTestId]="'next-button-terms'" type="primary" (click)="goToNextStep()">
      Accept
    </app-button>
  </app-button-container>
</app-wizard-step-wrapper>
