<div
  class="card"
  [ngClass]="{
    'card-passed': kbaStatus === 'Passed',
    'card-failed': kbaStatus === 'Failed'
  }"
>
  <div class="analysis-header no-bottom-border">
    <div class="card-body">
      <img
        class="margin__right-20"
        *ngIf="kbaStatus === 'Passed'"
        src="../../../assets/images/check_circle.svg"
        alt="green check circle"
      />
      <img
        class="margin__right-20"
        *ngIf="kbaStatus === 'Failed'"
        src="../../../assets/images/cancel-circle.svg"
        alt="red cancel circle"
      />
      <div class="status-label">KBA Status</div>
    </div>
    <div class="status-result" data-private>
      <strong>{{ kbaStatus }}</strong>
    </div>
  </div>
</div>
