<div
  [ngClass]="{
    middle: true,
    'middle--active': (requiredTextTotal$ | async) > 0
  }"
>
  <span class="middle__count" data-testid="textbox-count">{{ requiredTextTotal$ | async }}</span>
  <span data-testid="textbox-text"
    >{(requiredTextTotal$ | async), plural, =1 {Textbox} other
    {Textboxes}}</span
  >
</div>
