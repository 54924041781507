export interface SignerDetailsStatus {
  isRequiredToProvideParticipantDetails: boolean;
  isRequiredToCompleteMfaChallenge: boolean;
  isMissingAddress: boolean;
  isMissingDateOfBirth: boolean;
  isMissingEmail: boolean;
  isMissingFirstName: boolean;
  isMissingLastName: boolean;
  isMissingPhoneNumber: boolean;
  isMissingSignatureName: boolean;
  isMissingSsn: boolean;
  isMissingVerificationType: boolean;
  packageUserGuid: string;
}

export interface SignerDetailsFormData {
  email: string;
  phoneNumber: string;
  dobYear: string;
  dobMonth: string;
  dobDay: string;
  ssn: string;
  city: string;
  stateCode: string;
  streetAddress1: string;
  streetAddress2: string;
  zipCode: string;
}

export interface SignerDetailsSubmission {
  email?: string;
  firstName?: string;
  lastName?: string;
  phoneNumber?: string;
  dateOfBirth?: string;
  ssn?: string;
  participantAddress?: {
    city: string;
    stateCode: string;
    streetAddress1: string;
    streetAddress2: string;
    zipCode: string;
  };
}

export type SignerDetailsSubmissionErrors = {
  error: string;
  errorCode: string;
  field: string;
}[];

export enum SignerDetailsField {
  Email = 'Email',
  PhoneNumber = 'PhoneNumber',
  DateOfBirth = 'DateOfBirth',
  SSN = 'SSN',
  Address1 = 'Address1',
  Address2 = 'Address2',
  City = 'City',
  State = 'State',
  ZipCode = 'ZipCode',
}

export enum OptionalParticipantField {
  Address = 'Address',
  DateOfBirth = 'DateOfBirth',
  EmailAddress = 'EmailAddress',
  FirstName = 'FirstName',
  LastName = 'LastName',
  PhoneNumber = 'PhoneNumber',
  SequenceNumber = 'SequenceNumber',
  SignatureName = 'SignatureName',
  Ssn = 'Ssn',
  VerificationType = 'VerificationType',
}

export interface FetchStatesResponse {
  stateLookups: {
    stateLookupId: number;
    stateName: string;
    stateCode: string;
  }[];
}

export type StateList = {
  code: string;
  name: string;
}[];

export interface SignerDetailsTaskConfiguration {
  missingFields: OptionalParticipantField[];
}
