import { Component, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';

import { ModalsActions } from 'src/app/features/modals';
import { RootStoreState } from 'src/app/store';

import { KnowledgeBasedAuthenticationActions } from '../../store/actions';

@Component({
  selector: 'app-unable-to-verify-modal',
  templateUrl: './unable-to-verify-modal.component.html',
  styleUrls: ['./unable-to-verify-modal.component.scss'],
})
export class UnableToVerifyModalComponent implements OnDestroy {
  static identifier = 'UnableToVerifyModalComponent';
  constructor(private readonly store: Store<RootStoreState.State>) {}

  onTryAgain() {
    this.store.dispatch(KnowledgeBasedAuthenticationActions.StartKbaQuestions());
    this.store.dispatch(
      ModalsActions.ClearModalComponentIfOpen({
        payload: { component: UnableToVerifyModalComponent },
      })
    );
  }

  ngOnDestroy() {
    this.store.dispatch(
      ModalsActions.ClearModalComponentIfOpen({
        payload: { component: UnableToVerifyModalComponent },
      })
    );
  }
}
