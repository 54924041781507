<mat-form-field appearance="fill" class="clearsign-form-field">
  <mat-label for="phoneNumber" class="clearsign-label">Phone Number</mat-label>
  <input
    matInput
    type="tel"
    id="phoneNumber"
    [(ngModel)]="inputModel"
    #phoneInputModel="ngModel"
    [hiddenInput]="phoneInputModel.pristine"
    mask="(XXX) XXX - 0000"
    (ngModelChange)="inputModelChange.emit(inputModel)"
    pattern="\d{10}$"
    required
    data-private
  />
</mat-form-field>
