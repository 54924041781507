<div class="av-container">
  <h1 class="center">Audio / Video Setup</h1>
  <div class="box audio-video">
    <div class="video-container">
      <div class="video-placeholder" [hidden]="isStreamCreated">
        <i class="fa fa-3x fa-spin fa-circle-notch"></i>
      </div>
      <video #video class="video-list" [hidden]="!isStreamCreated"></video>
    </div>
    <div class="av-selectors">
      <form [formGroup]="deviceForm">
        <div class="video-devices">
          <div class="flex-align-center">
            <div class="device-group-header-container">
              <i class="fa fa-video fa-lg" aria-hidden="true"></i>
              <h4>Video Devices</h4>
            </div>
            <div class="device-group-sub-header">Select your video device</div>
          </div>
          <div class="select" data-testid="av-check-video-select">
            <select
              aria-label="video device selection"
              id="video"
              formControlName="video"
              class="form-control"
              (change)="updateSelectedVideo()"
            >
              <option *ngIf="!devicesAreLoaded">
                <span *ngFor="let i of [].constructor(128)">&nbsp;</span>
              </option>
              <option
                *ngFor="let camera of availableAudioVideoDevices.video; index as i"
                [value]="camera.deviceId"
              >
                {{ camera.label }}
              </option>
            </select>
          </div>
        </div>
        <div class="audio-devices">
          <div class="flex-align-center">
            <div class="device-group-header-container">
              <i class="fa fa-microphone fa-lg" aria-hidden="true"></i>
              <h4>Audio Devices</h4>
            </div>
            <div class="device-group-sub-header">Select your audio device</div>
          </div>
          <div class="select" data-testid="av-check-audio-select">
            <select
              aria-label="audio device selection"
              id="audio"
              formControlName="audio"
              class="form-control"
              (change)="updateSelectedAudio()"
            >
              <option *ngIf="!devicesAreLoaded">
                <span *ngFor="let i of [].constructor(128)">&nbsp;</span>
              </option>
              <option
                *ngFor="let microphone of availableAudioVideoDevices.audio; let i = index"
                [value]="microphone.deviceId"
              >
                {{ microphone.label }}
              </option>
            </select>
          </div>
          <div id="meters">
            <div id="instant">
              <meter
                class="volume-meter"
                high="0.25"
                max="1"
                value="{{ meterValue | async }}"
              ></meter>
              <div class="value"></div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
  <div class="box explanation">
    <i class="fa fa-exclamation-triangle fa-2x" aria-hidden="true"></i>
    <h3 class="explanation-text">
      Please select your Audio and Video Devices from the options above. If one of your devices is
      not working, please check the connection and try again.
    </h3>
  </div>
  <h2 class="center centerNotice">Check the items below if the statements are true</h2>
  <form [formGroup]="avCheckForm">
    <div
      class="box checkbox"
      [ngClass]="{
        selected: avCheckForm.get('canSeeVideo')?.value === true,
        error: submitted && avCheckForm.invalid && avCheckForm.get('canSeeVideo')?.value !== true
      }"
      (click)="toggleFormControl('canSeeVideo')($event)"
      (keypress)="toggleFormControl('canSeeVideo')($event)"
      tabindex="0"
    >
      <div class="form-step__checkbox">
        <label class="form-step__checkbox-label">
          <input type="checkbox" formControlName="canSeeVideo" id="canSeeVideo" />
          <span (click)="toggleFormControl('canSeeVideo')" data-testid="av-video-checkbox"
            >I can see my video in the box above</span
          >
        </label>
      </div>
    </div>
    <div
      class="box checkbox"
      [ngClass]="{
        selected: avCheckForm.get('canHearAudio')?.value === true,
        error: submitted && avCheckForm.invalid && avCheckForm.get('canHearAudio')?.value !== true
      }"
      (click)="toggleFormControl('canHearAudio')($event)"
      (keypress)="toggleFormControl('canHearAudio')($event)"
      tabindex="0"
    >
      <div class="form-step__checkbox">
        <label class="form-step__checkbox-label">
          <input type="checkbox" formControlName="canHearAudio" id="canHearAudio" />
          <span (click)="toggleFormControl('canHearAudio')" data-testid="av-audio-checkbox"
            >The gray bar under Audio Devices moves when I talk or make noise</span
          >
        </label>
      </div>
    </div>
    <div class="error-message">
      <div *ngIf="submitted && avCheckForm.invalid">
        Please check the items above to confirm your audio / video devices are working correctly
      </div>
    </div>
  </form>

  <app-button-container>
    <app-button type="primary" [dataTestId]="'next-button-avcheck'" (click)="saveSettings()"
      >Next</app-button
    >
  </app-button-container>
</div>
