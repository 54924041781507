import { AccountInfo } from '@azure/msal-browser';

export interface State {
  user: AccountInfo | null;
  error: string | null;
  loading: boolean;
  loaded: boolean;
  idpCode: string;
  redirectState: string;
  lastVerifiedUserGuid: string;
  verifiedUserGuids: string[];
}

export const initialState: State = {
  user: null,
  error: null,
  loading: false,
  loaded: false,
  idpCode: null,
  redirectState: null,
  lastVerifiedUserGuid: null,
  verifiedUserGuids: []
};
