import { Pipe, PipeTransform } from '@angular/core';

import { PackageUser } from '../models';
import { getPackageUserFullName } from '../util';

@Pipe({
  name: 'formatPackageUserName',
})
export class PackageUserFormatNamePipe implements PipeTransform {
  transform(packageUser: PackageUser, placeholderName = '') {
    return getPackageUserFullName(packageUser, placeholderName);
  }
}
