import {
  Component,
  OnInit,
  HostBinding,
  Input,
  OnDestroy,
  ElementRef
} from '@angular/core';
import { Subscription, Observable } from 'rxjs';
import { Store, select } from '@ngrx/store';

import { RootStoreState } from 'src/app/store';

import {
  DocumentEndorsementLocation,
  UniqueEndorsementImageTypes,
  EndorsementImage,
  OutgoingEndorsementAttempt,
  UniqueEndorsementImage,
  EndorsementType,
  OutgoingSignEndorsement,
  OutgoingEraseSignedEndorsement,
  SystemEndorsementImage,
} from '../../models';
import { EndorsementsSelectors, EndorsementsActions } from '../../store';
import { PackageUserRole } from '../../../package-users';
import { FeatureManagementService } from 'src/app/features/feature-management/services/feature-management.service';
import { Feature } from 'src/app/features/feature-management/models';

@Component({
  selector: 'app-endorsement',
  templateUrl: './endorsement.component.html',
  styleUrls: ['./endorsment.component.scss'],
})
export class EndorsementComponent implements OnInit, OnDestroy {
  @Input() endorsementId: number;
  @Input() hideIfSigned: boolean;
  @Input() documentScale: number;
  @Input() isEndorsementDisabled: boolean;
  @Input() areAllEndorsementsSigned: boolean;
  @HostBinding('style.top.px') public get yPosition(): number {
    return this.endorsementLocation
      ? this.endorsementLocation.yPosition * this.documentScale
      : null;
  }
  @HostBinding('style.left.px') public get xPosition(): number {
    return this.endorsementLocation
      ? this.endorsementLocation.xPosition * this.documentScale
      : null;
  }
  @HostBinding('style.height.px') public get height(): number {
    return this.endorsementLocation
      ? this.endorsementLocation.height * this.documentScale
      : null;
  }
  @HostBinding('style.maxHeight.px') public get maxHeight(): number {
    return this.endorsementLocation
      ? this.endorsementLocation.endorsementTypeCode ===
        EndorsementType.CHECKBOX
        ? this.getCheckBoxMaxHeight(this.endorsementLocation.height) *
          this.documentScale
        : this.endorsementLocation.height * this.documentScale
      : null;
  }
  @HostBinding('style.width.px') public get width(): number {
    return this.endorsementLocation
      ? this.endorsementLocation.width * this.documentScale
      : null;
  }

  endorsementLocation: DocumentEndorsementLocation;
  endorsementLocationSubscription: Subscription;
  endorsementImage: Observable<EndorsementImage | UniqueEndorsementImage | SystemEndorsementImage>;
  isAttemptingEndorsement$: Observable<boolean>;

  refreshInterval;
  protected isNeaSystemFieldsEnabled: boolean;

  constructor(
    private readonly store: Store<RootStoreState.State>,
    private readonly elementRef: ElementRef,
    private readonly featureManagementService: FeatureManagementService
  ) {}

  ngOnInit(): void {
    this.isNeaSystemFieldsEnabled = this.featureManagementService.getIsFeatureEnabledWithCaching(Feature.NeaSystemFields);

    this.isAttemptingEndorsement$ = this.store
      .select(EndorsementsSelectors.isAttemptingEndorsement);

    this.endorsementLocationSubscription = this.store
      .pipe(
        select(EndorsementsSelectors.selectEndorsementLocationById, {
          endorsementLocationId: this.endorsementId,
        })
      )
      .subscribe({
        next: (endorsementLocation) => {
          this.endorsementLocation = endorsementLocation;

          this.refreshInterval = setInterval(this.onRefreshEndorsement.bind(this), 500);
        }
      });

    if (
      UniqueEndorsementImageTypes.includes(
        this.endorsementLocation.endorsementTypeCode
      )
    ) {
      this.endorsementImage = this.store.pipe(
        select(EndorsementsSelectors.selectUniqueEndorsementImage, {
          endorsementLocationId: this.endorsementLocation
            .documentEndorsementLocationId,
        })
      );
    } else if (this.isNeaSystemFieldsEnabled && this.endorsementLocation.userRoleCode === PackageUserRole.SYSTEM) {
      this.endorsementImage = this.store.pipe(
        select(EndorsementsSelectors.selectSystemEndorsementImage, {
          endorsementImageId: this.endorsementLocation.imageId,
        })
      );
    } else {
      this.endorsementImage = this.store.pipe(
        select(EndorsementsSelectors.selectEndorsementImage, {
          endorsementType: this.endorsementLocation?.endorsementTypeCode,
          packageUserGuid: this.endorsementLocation?.packageUserGuid,
        })
      );
    }
  }

  // Hack used to fix disappearing endorsements on long documents.
  onRefreshEndorsement() {
    // Can only refresh if the element is visible
    const position = this.elementRef.nativeElement.getBoundingClientRect();
    if (position.top < window.innerHeight && position.bottom >= 0) {
      // Making max-height slightly larger is not noticable to the user at all but causes the
      // browser to redraw the element.
      this.elementRef.nativeElement.style.maxHeight = (this.maxHeight + 1) + 'px';
      clearInterval(this.refreshInterval);
      this.refreshInterval = null;
    }
  }

  ngOnDestroy(): void {
    this.endorsementLocationSubscription.unsubscribe();
    clearInterval(this.refreshInterval);
  }

  // TODO: User Story 50434: Remove Old Endorsement Application Logic
  endorsementAttempted(event: OutgoingEndorsementAttempt): void {
    this.store.dispatch(
      EndorsementsActions.AttemptEndorsement({
        payload: event,
      })
    );
  }

  signEndorsement(event: OutgoingSignEndorsement): void {
    this.store.dispatch(
      EndorsementsActions.ProcessSignEndorsement({
        payload: event,
      })
    );
  }

  eraseSignedEndorsement(event: OutgoingEraseSignedEndorsement): void {
    this.store.dispatch(
      EndorsementsActions.ProcessEraseSignedEndorsement({
        payload: event,
      })
    );
  }

  getCheckBoxMaxHeight(height) {
    return height < 15 ? 15 : height;
  }
}
