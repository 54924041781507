<div class="add-user-to-this-session">
  <div class="generic-confirm-modal__header">
    <h3 data-testid="add-user-title" class="modal-title">Who's joining on this device?</h3>
  </div>
  <div class="generic-confirm-modal__body">
      <div class="participants">
        <app-add-participant
          *ngFor="let nonSa of nonSigningAgentsEligibleForAddSession$ | async"
          [participant]="nonSa"
          (participantClicked)="onParticipantClicked()"
        >
        </app-add-participant>
      </div>

   <div class="generic-confirm-modal__footer">
    <app-button-container>
      <app-button
        type="secondary"
        (click)="back()"
      >
        Back
      </app-button>
      <app-button
        appDebounceClick
        (debouncedClick)="acceptAddParticipant($event)"
        [type]="'primary'"
        [isDisabled]="!isAddUserButtonEnabled"

      >
      <span>Add Participant</span>
      </app-button>
    </app-button-container>
  </div>
</div>
  