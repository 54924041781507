<!--TODO: User Story 50434: Remove Old Endorsement Application Logic-->
<button
  [appRemoveIfCachedFeatureEnabled]="'NewEndorsementApplication'"
  class="initials-endorsement__button"
  *ngIf="
    endorsementLocation?.statusCode !== 'SIGNED' && !endorsementLocation?.attempted;
    else endorsementImageTemplate
  "
  (click)="endorsementClicked()"
  [hidden]="!endorsementLocation?.isSignableInCurrentSession"
  [tabindex]="disableFocus ? -1 : 0"
  [attr.data-testid]="
    'endorsement-initials-' + this.endorsementLocation?.documentEndorsementLocationId
  "
  [disabled]="isEndorsementDisabled"
>
  <span *ngIf="!isEndorsementDisabled" class="fas fa-pen-nib initials-endorsement__icon"></span>
</button>

<ng-template #endorsementImageTemplate>
  <div
    [appRemoveIfCachedFeatureEnabled]="'NewEndorsementApplication'"
    class="initials-endorsement__image-wrapper"
  >
    <img
      [ngClass]="{
        'initials-endorsement__image': true,
        'initials-endorsement__image--hidden': hideIfSigned
      }"
      [src]="'data:image/png;base64,' + endorsementImage?.endorsementImage"
      alt="initials"
      [attr.data-testid]="
        'endorsement-initials-completed-' + this.endorsementLocation?.documentEndorsementLocationId
      "
      data-private
    />
  </div>
</ng-template>
<!--END TODO-->

<button
  [appRemoveIfCachedFeatureDisabled]="'NewEndorsementApplication'"
  class="initials-endorsement__button"
  *ngIf="endorsementLocation?.statusCode !== 'SIGNED'; else endorsementImageTemplateNew"
  appDebounceClick
  (debouncedClick)="endorsementClicked()"
  [debounceTimer]="500"
  [hidden]="!endorsementLocation?.isSignableInCurrentSession"
  [tabindex]="disableFocus ? -1 : 0"
  [attr.data-testid]="
    'endorsement-initials-' + this.endorsementLocation?.documentEndorsementLocationId
  "
  [disabled]="shouldDisable"
>
  <span *ngIf="!shouldDisable" class="fas fa-pen-nib initials-endorsement__icon"></span>
</button>

<ng-template #endorsementImageTemplateNew>
  <div
    [appRemoveIfCachedFeatureDisabled]="'NewEndorsementApplication'"
    class="initials-endorsement__image-wrapper"
  >
    <img
      [ngClass]="{
        'initials-endorsement__image': true,
        'initials-endorsement__image--hidden': hideIfSigned
      }"
      [src]="'data:image/png;base64,' + endorsementImage?.endorsementImage"
      alt="initials"
      [attr.data-testid]="
        'endorsement-initials-completed-' + this.endorsementLocation?.documentEndorsementLocationId
      "
      data-private
    />
    <span
      *ngIf="endorsementLocation?.isSignableOnDevice && !shouldDisable"
      class="initials-endorsement__erase-icon fa-solid fa-circle-xmark"
      appDebounceClick
      (debouncedClick)="eraseEndorsementClicked()"
      [debounceTimer]="500"
    >
    </span>
  </div>
</ng-template>
