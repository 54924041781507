enum EndorsementType {
  SIGNATURE = 'SIGNATURE',
  INITIALS = 'INITIALS',
  NOTARYSTAMP = 'NOTARYSTAMP',
  DATE = 'DATE',
  CHECKBOX = 'CHECKBOX',
  PRINTEDNAME = 'PRINTEDNAME',
  SIGNINGAGENTNAME = 'SIGNINGAGENTNAME',
  STATE = 'STATE',
  SIGNINGAGENTCOUNTY = 'SIGNINGAGENTCOUNTY',
  YESNONADROPDOWN = 'YESNONADROPDOWN',
  YESNONASIGNATURE = 'YESNONASIGNATURE',
  SIGNINGAGENTCOMMEXPIRE = 'SIGNINGAGENTCOMMEXPIRE',
  SIGNINGAGENTCOMMNUMBER = 'SIGNINGAGENTCOMMNUMBER',
  SIGNINGAGENTCOMMSTATE = 'SIGNINGAGENTCOMMSTATE',
  SIGNINGAGENTCOMMCOUNTY = 'SIGNINGAGENTCOMMCOUNTY',
  TEXTBOX = 'TEXTBOX',
}

const UniqueEndorsementImageTypes = [EndorsementType.TEXTBOX];

export { EndorsementType, UniqueEndorsementImageTypes };
