import {
  Component,
  OnInit,
  Input,
  OnDestroy,
  ViewChild,
  ElementRef,
} from '@angular/core';
import { PlatformLocation } from '@angular/common';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { TypedAction } from '@ngrx/store/src/models';
import { Observable, Subject, Subscription } from 'rxjs';
import { filter, map, takeUntil, tap } from 'rxjs/operators';
import { v4 as uuid } from 'uuid';

import {
  CommissionSelectionActions,
  CommissionSelectionSelectors
} from 'src/app/features/commissions/store';
import { RootStoreState } from 'src/app/store';
import { ModalsActions } from 'src/app/features/modals/store/actions';
import {
  NotificationModel,
  NotificationsActions,
  NotificationType,
} from 'src/app/features/notifications';
import { ExceptionType } from 'src/app/features/notifications/models';
import {
  PackageUsersSelectors,
  PackageUser,
  PackageUsersActions,
} from 'src/app/features/package-users';
import { MAX_PIN_ATTEMPTS } from 'src/app/features/shared/constants';

import {
  PinValidationActionPayload,
  VerifyPasswordResponseModel,
} from '../../model';

@Component({
  selector: 'app-certificate-validation',
  templateUrl: './certificate-validation.component.html',
  styleUrls: ['./certificate-validation.component.scss'],
})
export class CertificateValidationComponent implements OnInit, OnDestroy {
  static identifier = 'CertificateValidationComponent';
  @Input() onPasswordVerified: (boolean) => void;
  @Input() inputPackageUserGuid: string;
  @Input() inputCommissionStateCode: string;
  @Input() continueAction: TypedAction<any>;
  @Input() continueActionPayload: PinValidationActionPayload;
  @Input() cancelAction: TypedAction<any>;
  @Input() cancelActionPayload: any;

  @ViewChild('input', { static: false })
  set input(element: ElementRef<HTMLInputElement>) {
    if (element) {
      element.nativeElement.focus();
    }
  }

  user$: Observable<PackageUser>;
  certificatePasswordValidationForm: UntypedFormGroup;
  errorMessage = '';
  validationMsg = '';
  isLoading = false;
  onDestroyNotifier = new Subject();
  subscriptions?: Subscription[];

  constructor(
    public location: PlatformLocation,
    private readonly store: Store<RootStoreState.State>
  ) {
    location.onPopState(() => {
      this.store.dispatch(ModalsActions.ClearModalComponent());
    });
  }

  ngOnInit(): void {
    this.subscriptions = [];

    this.certificatePasswordValidationForm = new UntypedFormGroup({
      passwordEntry: new UntypedFormControl(),
    });

    this.subscriptions.push(
      this.store
        .pipe(select(CommissionSelectionSelectors.getCertificatePasswordAttemptsRemaining))
        .pipe(
          filter((remaining) => !!remaining),
          map((remaining) => this.makeInvalidMessage(remaining))
        )
        .subscribe((msg) => (this.validationMsg = msg))
    );

    this.user$ = this.store.select(PackageUsersSelectors.getPackageUser(this.inputPackageUserGuid));

    this.store.dispatch(CommissionSelectionActions.CertificatePasswordVerificationReset());

    this.store.dispatch(
      PackageUsersActions.FetchPackageUsers({
        payload: { ignoreIfLoaded: true },
      })
    );

    this.subscriptions.push(
      this.store
        .pipe(
          takeUntil(this.onDestroyNotifier),
          select(CommissionSelectionSelectors.getCertificatePasswordVerification),
          tap((passwordVerification: VerifyPasswordResponseModel) => this.validateResults(passwordVerification))
        )
        .subscribe(),

      this.store
        .pipe(
          takeUntil(this.onDestroyNotifier),
          select(CommissionSelectionSelectors.getCertificatePasswordError),
          tap((data) => {
            if (data) {
              this.showErrorMessage(
                this.errorMessage,
                ExceptionType.DisplayMessage
              );
            }
          })
        )
        .subscribe()
    );
  }

  continue(): void {
    const passwordToVerify = this.certificatePasswordValidationForm.get('passwordEntry').value;

    if (!!passwordToVerify) {
      this.isLoading = true;
      if (this.continueAction) {
        this.continueActionPayload.pin = null;
        this.continueActionPayload.certificatePassword = passwordToVerify;
        this.store.dispatch({
          type: this.continueAction.type,
          payload: this.continueActionPayload,
        });
      } else {
        this.store.dispatch(
          CommissionSelectionActions.VerifyCertificatePassword({
            payload: {
              packageUserGuid: this.inputPackageUserGuid,
              certificatePassword: passwordToVerify,
              stateCode: this.inputCommissionStateCode,
            },
          })
        );
      }
    } else {
      this.validationMsg = 'Please enter your Password.';
    }
  }

  validateResults(passwordVerification: VerifyPasswordResponseModel) {
    this.isLoading = false;
    if (!passwordVerification) {
      return;
    }

    if (passwordVerification.isPasswordValid) {
      this.closeModal(true);
    } else if (!passwordVerification.canTryAgain) {
      this.closeModal(false);
      this.store.dispatch(CommissionSelectionActions.CertificatePasswordLimitExceeded());
    } else {
      this.certificatePasswordValidationForm.reset('passwordEntry');

      if (passwordVerification.canTryAgain) {
        const msg = this.makeInvalidMessage(
          passwordVerification.attemptsRemaining
        );
        this.showErrorMessage(msg, ExceptionType.Other);
      }
    }
  }

  showErrorMessage(errorMessage: string, exceptionType: ExceptionType) {
    this.isLoading = false;
    this.store.dispatch(
      NotificationsActions.AddNotification({
        payload: {
          text: 'Error: ' + errorMessage.trim(),
          notificationType: NotificationType.Error,
          exceptionType: exceptionType,
          id: uuid(),
          logInAppInsights: true,
        } as NotificationModel,
      })
    );
  }

  closeModal(response: boolean): void {
    if (this.onPasswordVerified) this.onPasswordVerified(response);

    this.store.dispatch(ModalsActions.ClearModalComponent());
  }

  continueOnEnterKey(event) {
    if (event.keyCode === 13 && !this.isLoading) {
      this.continue();
    }
  }

  makeInvalidMessage(remaining: number) {
    if (remaining >= MAX_PIN_ATTEMPTS) return '';
    const attemptsWord = remaining === 1 ? 'attempt' : 'attempts';
    return `Invalid Password. ${remaining} ${attemptsWord} remaining.`;
  }

  static openModal(store: Store<RootStoreState.State>, componentData: any) {
    store.dispatch(
      ModalsActions.SetStandaloneModalComponent({
        payload: {
          component: CertificateValidationComponent,
          componentData: componentData,
          shouldFade: true,
        },
      })
    );
  }

  public cancel() {
    if (this.cancelAction) {
      this.store.dispatch({
        type: this.cancelAction.type,
        payload: this.cancelActionPayload,
      });
    }
    this.closeModal(false);
  }

  ngOnDestroy(): void {
    this.onDestroyNotifier.next(undefined);
    this.onDestroyNotifier.complete();
    this.subscriptions?.forEach((sub) => sub.unsubscribe());
  }
}
