import {
  Component,
  Input,
  OnInit
} from '@angular/core';
import {
  select,
  Store
} from '@ngrx/store';
import { Observable } from 'rxjs';

import { ModalsActions } from 'src/app/features/modals';
import { PackageUser } from 'src/app/features/package-users';
import { IntervalTimer } from 'src/app/features/shared/util/interval-timer';
import { RootStoreState } from 'src/app/store';

import {
  MultiFactorChallengeOptions,
  MultiFactorCodeValidationStates
} from '../../models';
import { MultiFactorActions } from '../../store/actions';
import { MultiFactorSelectors } from '../../store/selectors';

@Component({
  selector: 'app-multi-factor-challenge-modal',
  templateUrl: './multi-factor-challenge-modal.component.html',
  styleUrls: ['./multi-factor-challenge-modal.component.scss'],
})
export class MultiFactorChallengeModalComponent implements OnInit {
  public codeValidationStates = MultiFactorCodeValidationStates;
  public multiFactorChallengeOptions = MultiFactorChallengeOptions;
  public errorMessage$: Observable<string>;
  @Input() packageUser: PackageUser;
  attemptsRemaining$: Observable<number>;
  challengeCompleteLoading$: Observable<boolean>;
  currentlySelectedChallengeType$: Observable<MultiFactorChallengeOptions>;
  challengeCode = '';
  intervalTimer: IntervalTimer;
  userCodeValidationState: MultiFactorCodeValidationStates = MultiFactorCodeValidationStates.hide;
  multiFactorInProgress$: Observable<boolean>;

  constructor(private readonly store: Store<RootStoreState.State>) {}

  ngOnInit(): void {
    this.multiFactorInProgress$ = this.store.pipe(select(MultiFactorSelectors.isChallengeInProgress));
    this.errorMessage$ = this.store.pipe(select(MultiFactorSelectors.getErrorMessage));
    this.intervalTimer = new IntervalTimer(30);
    this.attemptsRemaining$ = this.store.pipe(select(MultiFactorSelectors.getAttemptsRemaining));
    this.challengeCompleteLoading$ = this.store.pipe(
      select(MultiFactorSelectors.getCompleteMultiFactorChallengeLoading)
    );

    const defaultOption = this.packageUser?.phoneNumber
      ? MultiFactorChallengeOptions.SMS
      : MultiFactorChallengeOptions.EMAIL;
    this.updateSelectedChallengeOption(defaultOption);
    this.currentlySelectedChallengeType$ = this.store.pipe(
      select(MultiFactorSelectors.getCurrentlySelectedChallengeType)
    );
  }

  cancelMultiFactorChallenge() {
    this.store.dispatch(MultiFactorActions.CancelMultiFactorChallenge());
  }

  createMultiFactorChallenge() {
    this.store.dispatch(ModalsActions.ShowLoadingSpinner());
    this.store.dispatch(
      MultiFactorActions.CreateMultiFactorChallenge({
        payload: {
          packageUserGuid: this.packageUser?.packageUserGuid,
        },
      })
    );
    this.intervalTimer.start(
      () => (this.userCodeValidationState = MultiFactorCodeValidationStates.hide)
    );
  }

  completeMultiFactorChallenge() {
    this.store.dispatch(
      MultiFactorActions.CompleteMultiFactorChallenge({
        payload: { challengeCode: this.challengeCode },
      })
    );
  }

  resendMultiFactorChallenge() {
    this.handleValidationMessage();
    if (this.intervalTimer.getRemainingSeconds() <= 0) {
      this.store.dispatch(MultiFactorActions.ResendMultiFactorChallengeCode());
      this.intervalTimer.start(
        () => (this.userCodeValidationState = MultiFactorCodeValidationStates.hide)
      );
    }
  }

  handleValidationMessage() {
    if (this.intervalTimer.getRemainingSeconds() >= 1) {
      this.userCodeValidationState = MultiFactorCodeValidationStates.invalid;
    } else {
      this.userCodeValidationState = MultiFactorCodeValidationStates.valid;
    }
  }

  change(e) {
    this.updateSelectedChallengeOption(e.target.value);
  }

  updateSelectedChallengeOption(challengeOption: MultiFactorChallengeOptions) {
    this.store.dispatch(
      MultiFactorActions.MultiFactorChallengeSelected({
        payload: { selectedChallengeType: challengeOption },
      })
    );
  }
}
