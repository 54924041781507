import { PlatformLocation } from '@angular/common';
import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';

import { filter, takeUntil, takeWhile, tap } from 'rxjs/operators';

import { ModalsActions } from 'src/app/features/modals';
import { PackageUser } from 'src/app/features/package-users';

import { VerifyPinResponseModel } from '../../model';
import { PinValidationActions, PinValidationSelectors } from '../../store';
import { selectPinVerificationViewModel } from '../../store/selectors/pin-validation.selectors';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-pin-verification',
  templateUrl: './pin-verification.component.html',
  styleUrls: ['./pin-verification.component.scss'],
})
export class PinVerificationComponent implements OnInit, OnDestroy {
  @ViewChild('input', { static: false })
  set input(element: ElementRef<HTMLInputElement>) {
    if (element) {
      element.nativeElement.focus();
    }
  }

  @Input() onPinVerified: () => void;
  @Input() participant: PackageUser;

  readonly vm$ = this.store.select(selectPinVerificationViewModel);
  readonly pinVerification$ = this.store.select(PinValidationSelectors.selectPinVerification);

  pinValidationForm: UntypedFormGroup;
  onDestroyNotifier = new Subject();

  constructor(public location: PlatformLocation, private readonly store: Store) {
    location.onPopState(() => {
      this.store.dispatch(ModalsActions.ClearModalComponent());
    });
  }

  ngOnInit(): void {
    this.store.dispatch(PinValidationActions.PinVerificationOpened());

    this.pinValidationForm = new UntypedFormGroup({
      pinEntry: new UntypedFormControl(),
    });

    this.pinVerification$
      .pipe(
        takeUntil(this.onDestroyNotifier),
        filter((pinVerification: VerifyPinResponseModel) => !!pinVerification),
        tap((pinVerification: VerifyPinResponseModel) => {
          if (pinVerification.isPinValid) {
            this.onPinVerified();
          }
        }),
        takeWhile((pinVerification: VerifyPinResponseModel) => !pinVerification.isPinValid),
        tap(() => this.pinValidationForm.reset('pinEntry'))
      )
      .subscribe();
  }

  verifyPin(): void {
    const pin = this.pinValidationForm.get('pinEntry').value;

    if (!pin) {
      this.store.dispatch(PinValidationActions.EmptyPinInput());
      return;
    }

    this.store.dispatch(
      PinValidationActions.VerifyPin({
        payload: {
          packageUserGuid: this.participant.packageUserGuid,
          pin: pin,
        },
      })
    );
  }

  verifyPinOnEnterKey(event: KeyboardEvent) {
    if (event.code === 'Enter' || event.code === 'NumpadEnter') {
      this.verifyPin();
    }
  }

  cancel() {
    this.store.dispatch(ModalsActions.ClearModalComponent());
  }

  ngOnDestroy(): void {
    this.onDestroyNotifier.next(undefined);
    this.onDestroyNotifier.complete();
  }
}
