import { Action, createReducer, on } from '@ngrx/store';

import { StateDynamicCommissionStamps } from '../../models';
import { CommissionSelectionActions } from '../actions';
import { CommissionSelectionState } from '../state';

const reducer = createReducer(
  CommissionSelectionState.initialState,
  on(
    CommissionSelectionActions.GetCommissionsSuccess,
    (state: CommissionSelectionState.State, { payload }) => {
      return {
        ...state,
        commissions: payload,
      };
    }
  ),
  on(
    CommissionSelectionActions.GetCountiesSuccess,
    (state: CommissionSelectionState.State, { payload }) => {
      return {
        ...state,
        counties: payload,
      };
    }
  ),
  on(
    CommissionSelectionActions.SetSelectedCommissionByStateCode,
    (state: CommissionSelectionState.State, { stateCode }) => {
      return {
        ...state,
        selectedCommission: state.commissions.find(
          (commission) => commission.stateCode === stateCode
        ),
        selectedCounty: CommissionSelectionState.initialState.selectedCounty,
      };
    }
  ),
  on(
    CommissionSelectionActions.SetSelectedCounty,
    (state: CommissionSelectionState.State, { payload }) => {
      return { ...state, selectedCounty: payload.county };
    }
  ),
  on(
    CommissionSelectionActions.SetDynamicCommissionStampsRequired,
    (state: CommissionSelectionState.State, { payload }) => {
      const dynamicCommissions = { ...state.dynamicCommissions };

      const stateDynamicComisssions =
        dynamicCommissions[payload.stateCode] ??
        ({
          stateCode: payload.stateCode,
          dynamicStampsRequired: payload.dynamicStampsRequired,
          countyStamps: {},
        } as StateDynamicCommissionStamps);

      stateDynamicComisssions.dynamicStampsRequired =
        payload.dynamicStampsRequired;
      dynamicCommissions[payload.stateCode] = stateDynamicComisssions;
      return { ...state, dynamicCommissions };
    }
  ),
  on(
    CommissionSelectionActions.SetDynamicCommissionStamp,
    (state: CommissionSelectionState.State, { payload }) => {
      const dynamicCommissions = { ...state.dynamicCommissions };

      const stateDynamicComisssions =
        dynamicCommissions[payload.stateCode] ??
        ({
          stateCode: payload.stateCode,
          dynamicStampsRequired: null,
          countyStamps: {},
        } as StateDynamicCommissionStamps);

      stateDynamicComisssions.countyStamps[payload.stamp.county] =
        payload.stamp;
      dynamicCommissions[payload.stateCode] = stateDynamicComisssions;
      return { ...state, dynamicCommissions };
    }
  ),
  on(
    CommissionSelectionActions.ApplyCommissionToOrderSuccess,
    (state: CommissionSelectionState.State) => ({
      ...state,
      isSelectedCommissionConfirmed: true,
    })
  ),
  on(
    CommissionSelectionActions.SetSelectedCommission,
    (state: CommissionSelectionState.State, { payload }) => {
      return {
        ...state,
        selectedCommission: payload,
      };
    }
  ),
  on(
    CommissionSelectionActions.SetConfirmedCommission,
    (state: CommissionSelectionState.State, { payload }) => ({
      ...state,
      selectedCommission: payload,
      isSelectedCommissionConfirmed: true,
    })
  ),
  on(
    CommissionSelectionActions.SetCertificateCredentialsSaved,
    (state: CommissionSelectionState.State) => {
      return {
        ...state,
        areCertificateCredentialsSaved: true,
      };
    }
  ),
  on(
    CommissionSelectionActions.CertificatePasswordLimitExceeded,
    (state: CommissionSelectionState.State) => ({
      ...state,
      certificatePasswordLimitExceeded: true,
    })
  ),
  on(
    CommissionSelectionActions.CertificateExpired,
    (state: CommissionSelectionState.State) => ({
      ...state,
      isDigitalCertificateExpired: true,
    })
  ),
  on(
    CommissionSelectionActions.ShowSACertificatePasswordLimitExceeded,
    (state: CommissionSelectionState.State) => ({
      ...state,
      ShowSAPasswordLimitExceeded: true,
    })
  ),
  on(
    CommissionSelectionActions.CertificatePasswordVerificationPass,
    (state: CommissionSelectionState.State, { payload }) => ({
      ...state,
      certificatePasswordVerification: payload.passwordVerification,
      attemptsRemaining: payload.passwordVerification.attemptsRemaining,
    })
  ),
  on(
    CommissionSelectionActions.CertificatePasswordVerificationFail,
    (state: CommissionSelectionState.State, { payload }) => ({
      ...state,
      certificatePasswordVerification: payload.passwordVerification,
      attemptsRemaining: payload.passwordVerification.attemptsRemaining,
    })
  ),
  on(
    CommissionSelectionActions.CertificatePasswordVerificationReset,
    (state: CommissionSelectionState.State) => ({
      ...state,
      certificatePasswordVerification: CommissionSelectionState.initialState.certificatePasswordVerification,
      error: CommissionSelectionState.initialState.certificatePasswordError,
    })
  )
);

export function commissionSelectionReducer(
  state: CommissionSelectionState.State | undefined,
  action: Action
) {
  return reducer(state, action);
}
