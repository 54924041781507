import {
  Commission,
  County,
  StateDynamicCommissionStamps,
  VerifyCertificatePasswordResponseModel
} from '../../models';

export interface State {
  commissions: Commission[];
  dynamicCommissions: Record<string, StateDynamicCommissionStamps>;
  selectedCommission: Commission;
  isSelectedCommissionConfirmed: boolean;
  selectedCounty: string;
  counties: County[];
  areCertificateCredentialsSaved: boolean;
  certificatePasswordVerification: VerifyCertificatePasswordResponseModel;
  certificatePasswordLimitExceeded?: boolean;
  isDigitalCertificateExpired?: boolean;
  showSACertificatePasswordLimitExceeded?: boolean;
  certificatePasswordError?: any;
}

export const initialState: State = {
  commissions: null,
  selectedCommission: null,
  isSelectedCommissionConfirmed: false,
  dynamicCommissions: {},
  counties: null,
  selectedCounty: null,
  areCertificateCredentialsSaved: false,
  certificatePasswordVerification: null,
  certificatePasswordLimitExceeded: false,
  isDigitalCertificateExpired: false,
  showSACertificatePasswordLimitExceeded: false,
  certificatePasswordError: null
};
