import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Store } from '@ngrx/store';
import * as OT from '@opentok/client';

import { combineLatest, Subject } from 'rxjs';
import { filter, take, takeUntil, tap } from 'rxjs/operators';

import { AudioVideoConnectionMonitorSelectors } from 'src/app/features/audio-video-connection-monitor/store';
import { ModalsActions } from 'src/app/features/modals';
import { NotificationsActions, NotificationType } from 'src/app/features/notifications';
import { ExceptionType } from 'src/app/features/notifications/models';
import {
  DEFAULT_NOTIFICATION_OPTIONS,
  TOKBOX_RECONNECTED_NOTIFICATION_OPTIONS,
  TOKBOX_RECONNECTED_USER_MESSAGE,
} from 'src/app/features/notifications/notifications.constants';
import { ProductType } from 'src/app/features/packages/models';
import { PackagesSelectors } from 'src/app/features/packages/store/selectors';
import { ParticipantVerificationSelectors } from 'src/app/features/participant-verification/store/selectors';
import { ApplicationInsightsService } from 'src/app/services/application-insights.service';
import { RootStoreState } from 'src/app/store';
import { v4 as uuid } from 'uuid';

import { VideoIssueModals } from '../../enums';
import { OpentokService } from '../../services';
import { VideoActions, VideoSelectors } from '../../store';

@Component({
  selector: 'app-publisher',
  templateUrl: './publisher.component.html',
  styleUrls: ['./publisher.component.scss'],
})
export class PublisherComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('publisherDiv') publisherDiv: ElementRef;
  @Input() videoSession: OT.Session;

  activeCamera: MediaDeviceInfo;
  activeMicrophone: MediaDeviceInfo;
  isAdditionalArchiveEnabled: boolean;
  packageId: number;
  publisher: OT.Publisher;
  publishing: boolean;
  streamId: string;

  private readonly destroying$ = new Subject();

  constructor(
    private readonly applicationInsightsService: ApplicationInsightsService,
    private readonly opentokService: OpentokService,
    private readonly store: Store<RootStoreState.State>
  ) {
    this.publishing = false;
  }

  ngOnInit() {
    this.store
      .select(PackagesSelectors.getActivePackageId)
      .pipe(
        takeUntil(this.destroying$),
        tap((packageId) => {
          this.packageId = packageId;
        })
      )
      .subscribe();

    this.store
      .select(AudioVideoConnectionMonitorSelectors.getSelectedAudioDevice)
      .pipe(
        takeUntil(this.destroying$),
        filter<MediaDeviceInfo>(Boolean),
        tap((audioDevice: MediaDeviceInfo) => {
          this.activeMicrophone = audioDevice;

          if (this.publishing && this.publisher) {
            this.publisher.setAudioSource(audioDevice.deviceId);
          }
        })
      )
      .subscribe();

    this.store
      .select(AudioVideoConnectionMonitorSelectors.getSelectedVideoDevice)
      .pipe(
        takeUntil(this.destroying$),
        filter<MediaDeviceInfo>(Boolean),
        tap((videoDevice: MediaDeviceInfo) => {
          this.activeCamera = videoDevice;

          if (this.publishing && this.publisher) {
            this.publisher.setVideoSource(videoDevice.deviceId);
          }
        })
      )
      .subscribe();

    this.store
      .select(VideoSelectors.getIsAdditonalArchiveEnabled)
      .pipe(
        takeUntil(this.destroying$),
        tap((isAdditionalArchiveEnabled: boolean) => {
          this.isAdditionalArchiveEnabled = isAdditionalArchiveEnabled;
        })
      )
      .subscribe();
  }

  ngAfterViewInit() {
    const OT = this.opentokService.getOT();
    this.publisher = OT.initPublisher(
      this.publisherDiv.nativeElement,
      {
        insertMode: 'append',
        height: '100%',
        width: '100%',
        resolution: '640x360',
        frameRate: 15,
        style: {
          buttonDisplayMode: 'off',
        },
        audioSource: this.activeMicrophone?.deviceId,
        videoSource: this.activeCamera?.deviceId,
      },
      (err?: OT.OTError) => this.handleInitPublisherError(err)
    );

    this.configureSessionListeners();
    this.configurePublisherListeners();
  }

  getUuid(): string {
    return uuid();
  }

  handlePublisherStreamCreated({ stream }: { stream: OT.Stream }) {
    this.streamId = stream.streamId;

    this.store.dispatch(
      VideoActions.SendVideoStreamConnected({
        payload: { videoStreamId: this.streamId },
      })
    );
  }

  handleInitPublisherError(err: OT.OTError) {
    if (err) {
      this.store.dispatch(
        NotificationsActions.AddNotification({
          payload: {
            notificationType: NotificationType.Error,
            id: this.getUuid(),
            text: `TokBox error: Failed to initialize publisher ${err.name}`,
            exceptionType: ExceptionType.CannotProceed,
            logInAppInsights: true,
          },
        })
      );
    }
  }

  handleVideoSessionArchiveStopped(
    event: OT.Event<'archiveStopped', OT.Session> & {
      id: string;
      type: string;
    }
  ) {
    this.applicationInsightsService.logEvent('VIDEO_SESSION_ARCHIVE_STOPPED', {
      id: event.id,
      type: event.type,
      isAdditionalArchiveEnabled: this.isAdditionalArchiveEnabled,
      packageId: this.packageId,
    });

    if (this.isAdditionalArchiveEnabled) {
      this.store.dispatch(VideoActions.StartArchive());
    }
  }

  handleVideoSessionPublish(err?: OT.OTError) {
    if (err) {
      this.handleVideoSessionPublisherError(err);
      return;
    }

    this.publishing = true;

    combineLatest([
      this.store.select(PackagesSelectors.getProductType),
      this.store.select(ParticipantVerificationSelectors.doesIdVerificationExist),
      this.store.select(VideoSelectors.getVideoSessionKey)
    ])
      .pipe(
        filter(
          ([productType, isParticipantIdologyValid, videoSessionKey]) =>
            (isParticipantIdologyValid || productType === ProductType.KRON) &&
            !!videoSessionKey && videoSessionKey.trim() !== ''
        ),
        take(1),
        tap(() => {
          this.store.dispatch(VideoActions.StartArchive());
        })
      )
      .subscribe();
  }

  handleVideoSessionPublisherError(err: OT.OTError) {
    switch (err.name) {
      case 'OT_CONSTRAINTS_NOT_SATISFIED':
      case 'OverconstrainedError':
      case 'NotAllowedError':
        this.store.dispatch(
          VideoActions.SetVideoConnectionIssue({
            payload: {
              isConnected: false,
              isPublisher: true,
              modalType: VideoIssueModals.VideoProblemsWithDevice,
            },
          })
        );
        break;
      case 'OT_HARDWARE_UNAVAILABLE':
      case 'NotReadableError':
        this.store.dispatch(
          VideoActions.SetVideoConnectionIssue({
            payload: {
              isConnected: false,
              isPublisher: true,
              modalType: VideoIssueModals.DeviceInUse,
            },
          })
        );
        break;
      case 'OT_CHROME_MICROPHONE_ACQUISITION_ERROR':
        this.store.dispatch(
          VideoActions.SetVideoConnectionIssue({
            payload: {
              isConnected: false,
              isPublisher: true,
              modalType: VideoIssueModals.AudioVideoFailure,
            },
          })
        );
        break;
      case 'OT_TIMEOUT':
        this.store.dispatch(
          VideoActions.SetVideoConnectionIssue({
            payload: {
              isConnected: false,
              isPublisher: true,
              modalType: VideoIssueModals.RefreshNeeded,
            },
          })
        );
        break;
      default:
        this.store.dispatch(
          NotificationsActions.AddNotification({
            payload: {
              notificationType: NotificationType.Error,
              id: this.getUuid(),
              text: `TokBox error: Failed to publish due to ${err.name}`,
              exceptionType: ExceptionType.CannotProceed,
              logInAppInsights: true,
            },
          })
        );
    }
  }

  handleVideoSessionSessionConnected() {
    this.videoSession.publish(this.publisher, (err: OT.OTError) =>
      this.handleVideoSessionPublish(err)
    );
  }

  handleVideoSessionSessionReconnected() {
    this.store
    .select(VideoSelectors.getVideoStreamId)
    .pipe(
      take(1),
      filter((streamId) => !!streamId),
      tap((streamId) => {
        this.store.dispatch(
          VideoActions.SendVideoStreamConnected({
            payload: {
              videoStreamId: streamId,
            },
          })
        );
        this.store.dispatch(
          VideoActions.SetAreAllVideoStreamsConnected({
            payload: true,
          })
        );
        this.store.dispatch(ModalsActions.ClearModalComponent());
      })
    )
    .subscribe();

    this.store.dispatch(
      NotificationsActions.AddNotification({
        payload: {
          notificationType: NotificationType.Success,
          id: this.getUuid(),
          text: TOKBOX_RECONNECTED_USER_MESSAGE,
          exceptionType: ExceptionType.DisplayMessage,
          options: {
            ...DEFAULT_NOTIFICATION_OPTIONS,
            ...TOKBOX_RECONNECTED_NOTIFICATION_OPTIONS,
          },
        },
      })
    );
  }

  handleVideoSessionSessionReconnecting() {
    this.store.dispatch(
      VideoActions.SetVideoConnectionIssue({
        payload: {
          isConnected: false,
          isPublisher: true,
          modalType: VideoIssueModals.VideoSignalDegraded,
        },
      })
    );
  }

  configurePublisherListeners() {
    this.publisher.on('streamCreated', (e) => this.handlePublisherStreamCreated(e));
  }

  configureSessionListeners() {
    this.videoSession.on('archiveStopped', (e) => this.handleVideoSessionArchiveStopped(e));
    this.videoSession.on('sessionConnected', () => this.handleVideoSessionSessionConnected());
    this.videoSession.on('sessionReconnected', () => this.handleVideoSessionSessionReconnected());
    this.videoSession.on('sessionReconnecting', () => this.handleVideoSessionSessionReconnecting());
  }

  ngOnDestroy() {
    this.destroying$.next(undefined);
    this.destroying$.complete();
    this.publisher?.destroy();
  }
}
