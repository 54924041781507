import {
  Component,
  Input,
  ElementRef,
  ViewChild,
  OnChanges,
  SimpleChanges,
  AfterViewInit,
} from '@angular/core';
import * as QRCode from 'qrcode';

@Component({
  selector: 'app-qr-code-image',
  template: `<canvas #qrCode></canvas>`,
  styles: [],
})
export class QrCodeImageComponent implements OnChanges, AfterViewInit {
  @Input() size = 200;
  @Input() margin = 4;
  @Input() url: string;
  @ViewChild('qrCode') qrCode: ElementRef;

  constructor() {}

  ngOnChanges(changes: SimpleChanges): void {
    this.createQRCode();
  }

  ngAfterViewInit(): void {
    this.createQRCode();
  }

  createQRCode() {
    if (this.url && this.qrCode) {
      QRCode.toCanvas(this.qrCode.nativeElement, this.url, {
        errorCorrectionLevel: 'L',
        margin: this.margin,
        width: this.size,
      });
    }
  }
}
