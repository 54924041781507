import { ActionReducer, MetaReducer } from '@ngrx/store';
import { localStorageSync } from 'ngrx-store-localstorage';

export function localStorageSyncReducer(reducer: ActionReducer<any>): ActionReducer<any> {
  return localStorageSync({
    keys: [{ checkIn: ['userCheckInSequence', 'usersCompletedCheckIn', 'nextStep'] }],
    rehydrate: true,
    storage: localStorage,
  })(reducer);
}

export const metaReducers: MetaReducer[] = [localStorageSyncReducer];
