<div class="header" *ngIf="isSignerOrderInfo">
  <strong>Signer's Order Information</strong>
</div>
<div class="header" *ngIf="!isSignerOrderInfo">
  <strong>ID Analysis Information</strong>
</div>
<div class="card-body" data-private>
  <div>
    {{ addressInformation.firstName }} {{ addressInformation.lastName }}
  </div>
  <div>
    {{ streetAddress }}
  </div>
  <div>
    {{ addressInformation.city }}, {{addressInformation.stateCode }} {{ addressInformation.zipCode }}
  </div>
</div>
