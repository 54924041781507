<mat-radio-group
  [attr.aria-label]="
    'there are three cursive ' + ariaLabelSignatureType + ' styles, select any to continue'
  "
  [value]="signatureSelection?.activeIndex"
  (change)="onSelectionChanged($event)"
>
  <div
    class="signature-view-container rkt-RadioHuge"
    *ngFor="let signature of signatureOptions; let indexOfElement = index"
  >
    <mat-radio-button
      color="primary"
      [value]="indexOfElement"
      [class.signature-view]="true"
      [class.signature-view--selected]="activeSignature === indexOfElement"
      [aria-label]="ariaLabelSignatureType + ' option ' + (indexOfElement + 1)"
      [attr.data-testid]="'endorsement-option-' + indexOfElement"
      [attr.data-testselected]="
        activeSignature === indexOfElement ? 'signature-option-selected' : null
      "
    >
      <img
        [src]="'data:image/png;base64,' + signature.endorsementImage"
        class="signature-view__image"
        data-testid="signature-image"
        alt=""
        data-private
      />
    </mat-radio-button>
  </div>
</mat-radio-group>
