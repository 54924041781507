import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import {
  FormsModule,
  ReactiveFormsModule
} from '@angular/forms';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { ModalsModule } from 'src/app/features/modals/modals.module';
import { SharedModule } from 'src/app/features/shared';

import {
  CertificatePasswordLimitExceededComponent,
  CertificateValidationComponent,
  NonSAWarningCertificatePasswordExceededComponent,
  PinVerificationComponent
} from './components';
import { PinValidationEffects } from './store/effects';
import { PinValidationReducers } from './store/reducers';

@NgModule({
  declarations: [
    CertificatePasswordLimitExceededComponent,
    CertificateValidationComponent,
    NonSAWarningCertificatePasswordExceededComponent,
    PinVerificationComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    StoreModule.forFeature(
      'pinValidation',
      PinValidationReducers.PinValidationReducer.pinValidationReducer
    ),
    EffectsModule.forFeature([PinValidationEffects.PinValidationEffects]),
    ModalsModule,
    SharedModule,
  ],
  exports: [
    CertificatePasswordLimitExceededComponent,
    CertificateValidationComponent,
    NonSAWarningCertificatePasswordExceededComponent,
  ],
})
export class PINValidationModule {}
