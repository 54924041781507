import { Directive, ElementRef, Input, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import {
  BehaviorSubject,
  combineLatest,
  fromEvent,
  interval,
  Subject,
  Subscription,
  timer,
} from 'rxjs';
import {
  debounce,
  distinctUntilChanged,
  filter,
  switchMap,
  take,
  takeUntil,
  tap,
} from 'rxjs/operators';

import { RootStoreState } from 'src/app/store';
import { PackageUsersSelectors } from '../../package-users';
import { ParticipantVerificationSelectors } from '../../participant-verification/store';
import { LongPollingConfig } from '../../shared/configs/long-polling';
import { ViewerLocation } from '../models';
import {
  ViewportLocationStandardizationService,
  ViewportLocationSyncService,
} from '../services/viewport-location';
import { DocumentsActions, DocumentsSelectors } from '../store';

// All concepts of a local y position should be confined to this component.
// Any communication coming into this component or leaving it besides communication with the standardization service
//  should be in values in the context of the original document and it's size.
@Directive({
  selector: '[appViewportSync]',
  providers: [ViewportLocationStandardizationService, ViewportLocationSyncService],
})
export class ViewportSyncDirective implements OnInit, OnDestroy {
  private intervalSubscription: Subscription;
  constructor(
    private readonly el: ElementRef,
    private readonly viewportLocationSyncService: ViewportLocationSyncService,
    private readonly viewportLocationStandardizationService: ViewportLocationStandardizationService,
    private readonly store: Store<RootStoreState.State>
  ) {}
  @Input() focusZonePaddingPercentage = 25; // On each side. 25 means 50% of the element clientHeight is focused

  onDestoryNotifier = new Subject();
  scrollTrackingDisabledNotifier = new Subject();
  topOfFocusZone = new BehaviorSubject<number>(0);
  bottomOfFocusZone = new BehaviorSubject<number>(null);
  localYPosition = new BehaviorSubject<number>(0);

  ngOnInit() {
    // This has been brought from document-scoreboard.component to this places
    // Because the document-scoreboard.component is a common component
    // We dont need to sync presign participants in the pre sign room
    this.intervalSubscription = interval(LongPollingConfig['16Seconds'].interval)
      .pipe(
        tap(() => {
          this.store.dispatch(DocumentsActions.VerifyActiveDocument());
        })
      )
      .subscribe();

    this.store
      .pipe(
        takeUntil(this.onDestoryNotifier),
        select(DocumentsSelectors.GetAreParticipantDocumentsShown),
        filter((areParticipantDocumentsShown) => areParticipantDocumentsShown),
        take(1),
        tap((_) => {
          this.viewportLocationSyncService
            .initializeDocumentLocationSync()
            .pipe(
              takeUntil(this.onDestoryNotifier),
              filter<ViewerLocation>(Boolean),
              distinctUntilChanged((x, y) => x.yPosition === y.yPosition),
              filter((x) => !this.yCoordinateIsFocused(x.yPosition) || x.isBottom || x.isTop),
              tap((viewerLocation) =>
                this.externallyTriggeredViewerLocationChangeHandler(viewerLocation)
              )
            )
            .subscribe();

          this.store
            .pipe(
              takeUntil(this.onDestoryNotifier),
              select(DocumentsSelectors.getActivePackageDocumentId),
              filter<number>(Boolean),
              distinctUntilChanged(),
              tap(() => this.documentChangeHandler())
            )
            .subscribe();

          combineLatest([
            this.viewportLocationSyncService.listenersConfigured$,
            this.store.select(DocumentsSelectors.getAreAllSessionsDocumentsLoaded),
          ])
            .pipe(
              takeUntil(this.onDestoryNotifier),
              tap(([listenerConfigured, hasAllDevicesLoaded]) => {
                const localViewerPosition = this.getLocalViewerPositionData();
                if (!hasAllDevicesLoaded || !listenerConfigured) {
                  return;
                }
                if (!localViewerPosition.isTop) {
                  this.viewportLocationSyncService.broadcastViewerLocationUpdate(
                    localViewerPosition
                  );
                }
              })
            )
            .subscribe();

          this.enableLocalScrollTracking();
        })
      )
      .subscribe();
  }

  ngOnDestroy() {
    this.onDestoryNotifier.next(undefined);
    this.onDestoryNotifier.complete();
    this.scrollTrackingDisabledNotifier.next(undefined);
    this.scrollTrackingDisabledNotifier.complete();
    this.viewportLocationSyncService.completeDocumentLocationSync();
    this.intervalSubscription?.unsubscribe();
  }

  enableLocalScrollTracking() {
    fromEvent(this.el.nativeElement, 'scroll')
      .pipe(
        takeUntil(this.scrollTrackingDisabledNotifier),
        filter(() => Math.abs(this.el.nativeElement.scrollTop - this.localYPosition.value) > 5),
        debounce(() => timer(500)),
        switchMap(() => {
          return this.locallyTriggeredViewerLocationChangeHandler();
        })
      )
      .subscribe();
  }

  disableLocalScrollTracking() {
    this.scrollTrackingDisabledNotifier.next(undefined);
  }

  getLocalViewerPositionData() {
    const centeredLocalYPosition =
      this.el.nativeElement.scrollTop + this.el.nativeElement.clientHeight / 2;
    const centeredStandardizedYPosition =
      this.viewportLocationStandardizationService.convertToStandardizedYPosition(
        centeredLocalYPosition,
        0
      );

    return {
      yPosition: centeredStandardizedYPosition,
      isTop: this.el.nativeElement.scrollTop === 0,
      isBottom:
        this.el.nativeElement.scrollHeight -
          this.el.nativeElement.scrollTop -
          this.el.nativeElement.clientHeight <
        1,
    } as ViewerLocation;
  }

  locallyTriggeredViewerLocationChangeHandler() {
    return combineLatest([
      this.store.pipe(select(PackageUsersSelectors.getIsSigningAgent)),
      this.store.pipe(select(ParticipantVerificationSelectors.getAllSignersApproved)),
    ]).pipe(
      tap(([isSigningAgent, areAllSignersApproved]) => {
        if (isSigningAgent && !areAllSignersApproved) {
          return; //When on the participant verification page, we dont want to send any events
        }
        const viewerLocation = this.getLocalViewerPositionData();
        this.calculateFocusZones();
        this.viewportLocationSyncService.broadcastViewerLocationUpdate(viewerLocation);
        this.localYPosition.next(this.el.nativeElement.scrollTop);
      })
    );
  }

  externallyTriggeredViewerLocationChangeHandler(viewerLocation: ViewerLocation) {
    this.disableLocalScrollTracking();
    this.updateVisibleViewerLocation(viewerLocation);

    // Resolve null promise to allow DOM to update after the scroll (to avoid change detection issue)
    Promise.resolve(null).then(() => {
      this.calculateFocusZones();
      this.enableLocalScrollTracking();
    });
  }

  yCoordinateIsFocused(standardizedYCoordinate: number): boolean {
    const localYCoordinate =
      this.viewportLocationStandardizationService.convertToLocalYPosition(standardizedYCoordinate);
    return (
      this.topOfFocusZone.value <= localYCoordinate &&
      localYCoordinate <= this.bottomOfFocusZone.value
    );
  }

  calculateFocusZones(): void {
    const topOfFocus =
      this.el.nativeElement.scrollTop +
      this.el.nativeElement.clientHeight * (this.focusZonePaddingPercentage / 100);
    const bottomOfFocus =
      this.el.nativeElement.scrollTop +
      this.el.nativeElement.clientHeight -
      this.el.nativeElement.clientHeight * (this.focusZonePaddingPercentage / 100);

    this.topOfFocusZone.next(topOfFocus);
    this.bottomOfFocusZone.next(bottomOfFocus);
  }

  updateVisibleViewerLocation(standardizedViewerLocation: ViewerLocation) {
    if (standardizedViewerLocation.isTop) {
      this.localYPosition.next(0);
      this.el.nativeElement.scroll(0, 0);
      return;
    }

    if (standardizedViewerLocation.isBottom) {
      this.localYPosition.next(this.el.nativeElement.scrollHeight);
      this.el.nativeElement.scroll(0, this.el.nativeElement.scrollHeight);
      return;
    }

    const centeredLocalYPosition =
      this.viewportLocationStandardizationService.convertToLocalYPosition(
        standardizedViewerLocation.yPosition
      );

    const localScrollPosition = centeredLocalYPosition - this.el.nativeElement.clientHeight / 2;

    this.localYPosition.next(localScrollPosition);
    this.el.nativeElement.scroll(0, localScrollPosition);
  }

  documentChangeHandler() {
    this.disableLocalScrollTracking();

    this.localYPosition.next(0);
    this.el.nativeElement.scroll(0, 0);

    Promise.resolve(null).then(() => {
      this.calculateFocusZones();
      this.enableLocalScrollTracking();
    });
  }
}
