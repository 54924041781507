import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, filter, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { v4 as uuid } from 'uuid';

import { LobbyActions } from 'src/app/features/lobby';
import { MultiFactorActions } from 'src/app/features/multi-factor/store/actions';
import { NotificationsActions, NotificationType } from 'src/app/features/notifications';
import { ExceptionType } from 'src/app/features/notifications/models';
import { PackageUsersActions } from 'src/app/features/package-users';
import { CheckInTaskCode, WizardActions, WizardSelectors } from 'src/app/features/wizard';
import { RootStoreState } from 'src/app/store';

import { SignerDetailsStatus, SignerDetailsTaskConfiguration } from '../../models';
import { SignerDetailsService } from '../../services';
import { SignerDetailsActions } from '../actions';
import { SignerDetailsSelectors } from '../selectors';

@Injectable()
export class SignerDetailsEffects {
  setSignerDetailsTaskConfiguration$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WizardActions.FetchTasksSuccessful),
      map((tasks) => this.signerDetailsService.mapCheckInTasksToStatus(tasks.payload)),
      filter(
        (signerDetailsStatus) =>
          signerDetailsStatus.isRequiredToProvideParticipantDetails ||
          signerDetailsStatus.isRequiredToCompleteMfaChallenge
      ),
      concatLatestFrom(() => this.store.pipe(select(WizardSelectors.getActiveWizardUserGuid))),
      map(([signerDetailsStatus, packageUserGuid]) =>
        SignerDetailsActions.FetchSignerDetailsStatusSuccessful({
          payload: {
            ...signerDetailsStatus,
            packageUserGuid,
          },
        })
      )
    )
  );

  submitSignerDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SignerDetailsActions.SubmitSignerDetails),
      concatMap((action) =>
        of(action).pipe(
          withLatestFrom(this.store.pipe(select(WizardSelectors.getActiveWizardUserGuid)))
        )
      ),
      switchMap(([action, packageUserGuid]) => {
        return this.signerDetailsService.submitSignerDetails(packageUserGuid, action.payload).pipe(
          switchMap((payload) => [
            SignerDetailsActions.SubmitSignerDetailsSuccess(),
            PackageUsersActions.FetchPackageUsers({ payload: { ignoreIfLoaded: false } }),
          ]),
          catchError((err) => {
            // If this is true then the request seems to contain validation errors
            if (err.status === 400 && Array.isArray(err.error) && err.error[0].field) {
              return of(SignerDetailsActions.SubmitSignerDetailsError({ payload: err.error }));
            } else {
              return of(
                NotificationsActions.AddNotification({
                  payload: {
                    exception: err,
                    notificationType: NotificationType.Error,
                    id: uuid(),
                    text: 'Failed to submit signer details',
                    exceptionType: ExceptionType.CannotProceed,
                  },
                })
              );
            }
          })
        );
      })
    )
  );

  fetchStates$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SignerDetailsActions.FetchStates),
      switchMap((action) => {
        return this.signerDetailsService.getStates().pipe(
          map((payload) => SignerDetailsActions.FetchStatesSuccessful({ payload })),
          catchError((err) =>
            of(
              NotificationsActions.AddNotification({
                payload: {
                  exception: err,
                  notificationType: NotificationType.Error,
                  id: uuid(),
                  text: 'Failed to retrieve states list',
                  exceptionType: ExceptionType.CannotProceed,
                },
              })
            )
          )
        );
      })
    )
  );

  logAdditionalDetailsRequested$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SignerDetailsActions.LogAdditionalDetailsRequested),
        concatMap((action) =>
          of(action).pipe(
            withLatestFrom(this.store.pipe(select(WizardSelectors.getActiveWizardUserGuid)))
          )
        ),
        switchMap(([action, packageUserGuid]) => {
          return this.signerDetailsService.logAdditionalDetailsRequested(packageUserGuid).pipe(
            map(() => SignerDetailsActions.LogAdditionalDetailsRequestedSuccess()),
            catchError((err) =>
              of(
                NotificationsActions.AddNotification({
                  payload: {
                    exception: err,
                    notificationType: NotificationType.Error,
                    id: uuid(),
                    text: 'Failed to log Participant Details Requested',
                    exceptionType: ExceptionType.CannotProceed,
                  },
                })
              )
            )
          );
        })
      ),
    { dispatch: false }
  );

  multiFactorAttemptSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MultiFactorActions.MultiFactorAttemptSuccess),
      concatLatestFrom(() =>
        this.store.pipe(select(SignerDetailsSelectors.getSignerDetailsStatus))
      ),
      filter(
        ([action, signerDetailsStatus]) =>
          action.payload?.packageUserGuid?.toLowerCase() ===
          signerDetailsStatus?.packageUserGuid?.toLowerCase()
      ),
      map(() => SignerDetailsActions.MultifactorVerificationSuccess())
    )
  );

  constructor(
    private readonly actions$: Actions,
    private readonly store: Store<RootStoreState.State>,
    private readonly signerDetailsService: SignerDetailsService
  ) {}
}
