import { Injectable } from '@angular/core';
import {
  ApplicationInsights,
  IExceptionTelemetry,
  SeverityLevel
} from '@microsoft/applicationinsights-web';

import { NotificationType } from 'src/app/features/notifications';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ApplicationInsightsService {
  appInsights: ApplicationInsights;

  private readonly loggedErrors = new Set<Error>();
  private readonly loggedErrorsExpiryMs = 1000 * 30;
  assemblyName = 'Rock.Nexsys.Signing.UI';
  defaultProperties = {
    AssemblyName: this.assemblyName,
    Environment: environment.envName,
  };

  constructor() {
    this.appInsights = new ApplicationInsights({
      config: environment.appInsights,
    });
    this.appInsights.loadAppInsights();
    this.appInsights.addTelemetryInitializer(this.telemeteryInitiaizer);
  }

  telemeteryInitiaizer = (envelope) => {
    envelope.data.AssemblyName = this.assemblyName;
    envelope.data.Environment = environment.envName;
  };

  logPageView(name?: string, url?: string) {
    this.appInsights.trackPageView({
      name: name,
      uri: url,
      properties: { ...this.defaultProperties },
    });
  }

  logEvent(name: string, properties?: { [key: string]: any }) {
    this.appInsights.trackEvent({ name: name }, { ...properties, ...this.defaultProperties });
  }

  logMetric(name: string, average: number, properties?: { [key: string]: any }) {
    this.appInsights.trackMetric(
      { name: name, average: average },
      { ...properties, ...this.defaultProperties }
    );
  }

  logTrace(message: string, properties?: { [key: string]: any }) {
    this.appInsights.trackTrace({ message: message }, { ...properties, ...this.defaultProperties });
  }

  logException(error: Error, severityLevel: SeverityLevel, properties?: { [key: string]: any }) {
    this.trackException({
      exception: error,
      severityLevel: severityLevel,
      properties: { ...properties, ...this.defaultProperties },
    });
  }

  logMessage(message: string, notificationLevel: NotificationType) {
    this.trackException({
      exception: new Error(message),
      severityLevel: this.lookupSeverityType(notificationLevel),
      properties: this.defaultProperties,
    });
  }

  lookupSeverityType(notificationLevel: NotificationType): SeverityLevel {
    let severityLevel: SeverityLevel = SeverityLevel.Warning;

    if (
      notificationLevel === NotificationType.Info ||
      notificationLevel === NotificationType.Success
    ) {
      severityLevel = SeverityLevel.Information;
    }

    return severityLevel;
  }

  private trackException(data: IExceptionTelemetry): void {
    // if there is an Error instance, check to see if we've
    // already logged it, and, abort the send.  if it hasn't,
    // we need to cache it so subsequent calls won't get logged
    const err = data.exception as Error;
    if (err) {
      const cache = this.loggedErrors;
      if (cache.has(err)) {
        return;
      }
      cache.add(err);
    }
    this.appInsights.trackException(data);
    if (err) {
      this.scheduleClearLoggedError(err);
    }
  }

  private scheduleClearLoggedError(err: Error): void {
    setTimeout(() => {
      const cache = this.loggedErrors;
      if (err && cache.has(err)) {
        cache.delete(err);
      }
    }, this.loggedErrorsExpiryMs);
  }
}
