import { Component, OnInit } from '@angular/core';
import { DEFAULT_APPLICATION_PHONE_NUMBER } from 'src/app/features/shared/constants';

@Component({
  selector: 'app-signing-agent-welcome',
  templateUrl: './signing-agent-welcome.component.html',
  styleUrls: ['./signing-agent-welcome.component.scss']
})
export class SigningAgentWelcomeComponent implements OnInit {
  supportTelephoneNumber: string;

  constructor() {}

  ngOnInit(): void {
    this.supportTelephoneNumber = DEFAULT_APPLICATION_PHONE_NUMBER.replace(/[^\d]/g, '');
  }
}
