import { createAction, props } from '@ngrx/store';

import { PackageUser } from 'src/app/features/package-users';

import {
  CommissionConsentContent,
  EndorsementsConsent,
  EndUserLicenseAgreementContent,
  KnownSignerConsent,
  PrivacyPolicyConsent,
  TermsAndConditionsConsent,
} from '../../models';

export const FetchPrivacyPolicyConsent = createAction('[Consents] Fetch Privacy Policy Consent');

export const SetPrivacyPolicyConsent = createAction(
  '[Consents] Set PrivacyPolicy Consent',
  props<{ payload: PrivacyPolicyConsent }>()
);

export const FetchTermsAndConditionsConsent = createAction(
  '[Consents] Fetch Terms And Conditions Consent'
);

export const SetTermsAndConditionsConsent = createAction(
  '[Consents] Set Terms And Conditions Consent',
  props<{ payload: TermsAndConditionsConsent }>()
);

export const SetCommissionsConsentByState = createAction(
  '[Consents] Set Commissions By State',
  props<{ payload: CommissionConsentContent }>()
);

export const FetchCommissionsConsentByState = createAction(
  '[Consents] Fetch Commissions By State',
  props<{ payload: string }>()
);

export const FetchEndorsementsConsent = createAction('[Consents] Fetch Endorsements Consent');

export const SetEndorsementsConsent = createAction(
  '[Consents Set Endorsements Consent',
  props<{ payload: EndorsementsConsent }>()
);

export const FetchEULAContent = createAction('[Consents] Fetch EULA Content');

export const SetEULAContent = createAction(
  '[Consents] Set EULA Content',
  props<{ payload: EndUserLicenseAgreementContent }>()
);

export const AcceptEULA = createAction('[Consents] Accept EULA');

export const EULAAcceptedSuccessfully = createAction('[Consents] EULA Accepted Successfully');

export const FetchKnownSignerConsent = createAction('[Consents] Fetch Known Signer Consent');

export const SetKnownSignerConsent = createAction(
  '[Consents] Set Known Signer Consent',
  props<{ payload: KnownSignerConsent }>()
);

export const ConsentToKnownSigner = createAction(
  '[Consents] Consent To Personal Knowledge Of Signer',
  props<{ payload: PackageUser }>()
);

export const ConsentToKnownSignerSuccess = createAction(
  '[Consents] Consent To Personal Knowledge Of Signer success'
);

export const SetEnabledPrivacyPolicyConsentCheckBox = createAction(
  '[Consents] Set Enabled Privacy Policy Consent CheckBox'
);

export const SetDisabledPrivacyPolicyConsentCheckBox = createAction(
  '[Consents] Set Disabled Privacy Policy Consent CheckBox'
);

export const SetEnabledTermsOfUseConsentCheckBox = createAction(
  '[Consents] Set Enabled Terms Of Use Consent CheckBox'
);

export const SetDisabledSignatureConsentAgreementCheckbox = createAction(
  '[Consents] Set Disabled Signature Consent Agreement Checkbox'
);

export const SetEnabledSignatureConsentAgreementCheckbox = createAction(
  '[Consents] Set Enabled Signature Consent Agreement Checkbox'
);

export const SetDisabledTermsOfUseConsentCheckBox = createAction(
  '[Consents] Set Disabled Terms Of Use Consent CheckBox'
);


export const AcceptTermsOfService = createAction('[Consents] Accept Terms Of Service');

export const AcceptTermsOfServiceSuccess = createAction(
  '[Consents] Terms Of Service Accepted Successfully'
);

export const DeclineTermsOfService = createAction('[Consents] Decline Terms Of Service');
