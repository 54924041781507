import { Action, createReducer, on } from '@ngrx/store';
import { PackagesActions } from 'src/app/features/packages';

import { VideoActions } from '../actions';
import { VideoState } from '../state';

const reducer = createReducer(
  VideoState.initialState,
  on(VideoActions.CreateVideoSessionSuccess, (state: VideoState.State) => ({
    ...state,
    isVideoSessionCreated: true,
  })),
  on(VideoActions.SetVideoSessionInfo, (state: VideoState.State, { payload }) => ({
    ...state,
    videoSessionInfo: payload,
    isVideoSessionInfoLoaded: true,
  })),
  on(VideoActions.StopArchive, (state: VideoState.State) => ({
    ...state,
  })),
  on(VideoActions.SendVideoStreamDestroyed, (state: VideoState.State) => ({
    ...state,
    areAllVideoStreamsConnected: false,
  })),
  on(
    VideoActions.SendVideoStreamConnected,
    (state: VideoState.State, { payload }) =>
      ({
        ...state,
        videoStreamId: payload.videoStreamId,
        videoConnection: {
          isConnected: true,
          isPublisher: true,
        },
      } as VideoState.State)
  ),
  on(VideoActions.SetVideoConnectionIssue, (state: VideoState.State, { payload }) => ({
    ...state,
    videoConnection: payload,
    areAllVideoStreamsConnected: false,
  })),
  on(VideoActions.SetVideoVisibilityIssue, (state: VideoState.State, { payload }) => ({
    ...state,
    videoConnection: payload,
    areAllVideoStreamsConnected: false,
  })),
  on(VideoActions.SetAreAllVideoStreamsConnected, (state: VideoState.State, { payload }) => ({
    ...state,
    areAllVideoStreamsConnected: payload,
  })),
  on(VideoActions.StopVideo, (state: VideoState.State) => ({
    ...state,
    stopVideo: true,
  })),
  on(VideoActions.SetDisableAdditionalArchive, (state: VideoState.State) => ({
    ...state,
    isAdditonalArchiveEnabled: false,
  })),
  on(
    VideoActions.SendVideoEnabled,
    (state: VideoState.State) =>
      ({
        ...state,
        videoConnection: {
          isConnected: true,
          isPublisher: true,
        },
      } as VideoState.State)
  ),
  on(
    VideoActions.SetIgnoreParticipantVideoStream,
    (state: VideoState.State, { payload }) =>
      ({
        ...state,
        ignoreParticipantVideoStream: payload,
      } as VideoState.State)
  ),
  on(VideoActions.SetIsVideoDownloading, (state: VideoState.State, { payload }) => {
    const updatedVideoArhivesIds = state.downloadVideoArchiveIds.videoArchiveIds.map(
      (videoArchivesId) =>
        payload.videoArchiveId === videoArchivesId.videoArchiveId
          ? {
              ...videoArchivesId,
              isDownloading: payload.isDownloading,
            }
          : videoArchivesId
    );
    return {
      ...state,
      downloadVideoArchiveIds: { videoArchiveIds: updatedVideoArhivesIds },
    };
  }),
  on(VideoActions.FetchDownloadVideoArchiveIds, (state: VideoState.State) => ({
    ...state,
    downloadVideoArchiveIds: null,
  })),
  on(
    VideoActions.FetchDownloadVideoArchiveIdsSuccessful,
    (state: VideoState.State, { payload }) => ({
      ...state,
      downloadVideoArchiveIds: payload,
    })
  ),
  on(VideoActions.GetVideoArchiveStatusSuccessful, (state: VideoState.State, { payload }) => ({
    ...state,
    videoArchiveStatus: payload.status,
  })),
  on(PackagesActions.OptOutSuccess, (state: VideoState.State) => ({
    ...state,
    stopVideo: true,
    isAdditonalArchiveEnabled: false,
  })),
  on(VideoActions.SetMultiVideoStorageLocatorUrls, (state: VideoState.State, { payload }) => ({
    ...state,
    multiVideoStorageLocatorUrls: payload,
  })),
);

export function videoReducer(state: VideoState.State | undefined, action: Action) {
  return reducer(state, action);
}
