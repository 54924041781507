import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { DeviceUser } from '../models';
import { GetDeviceCodeResponse } from '../models/response/get-device-code.response.model';

@Injectable({
  providedIn: 'root',
})
export class DeviceGroupService {
  constructor(private readonly httpClient: HttpClient) {}

  saveToDeviceGroup(packageUserGuid: string, deviceCode: string) {
    return this.httpClient.post(`deviceGroups/${deviceCode}`, { packageUserGuid }).pipe(
      catchError((res) => {
        return throwError(res);
      })
    );
  }

  getDeviceUsers(packageGuid: string, deviceCode: string) {
    return this.httpClient
      .get<DeviceUser[]>(`deviceGroups/${deviceCode}/packages/${packageGuid}`)
      .pipe(
        catchError((res) => {
          return throwError(res);
        })
      );
  }

  getNewDeviceCode(isPresign: boolean) {
    return this.httpClient
      .get<GetDeviceCodeResponse>(`deviceGroups/deviceCode/isPresign/${isPresign}`)
      .pipe(
        catchError((res) => {
          return throwError(res);
        })
      );
  }

  getVerifiedDeviceCode(deviceCode: string) {
    return this.httpClient.get<GetDeviceCodeResponse>(`deviceGroups/deviceCode/${deviceCode}`).pipe(
      catchError((res) => {
        return throwError(res);
      })
    );
  }

  rejoin(packageUserGuid: string, deviceCode: string) {
    return this.httpClient.post(`deviceGroups/rejoin`, { packageUserGuid, deviceCode }).pipe(
      catchError((res) => {
        return throwError(res);
      })
    );
  }

  addUserToDevice(packageUserGuid: string, deviceCode: string) {
    return this.httpClient.post(`deviceGroups/rejoin`, { packageUserGuid, deviceCode }).pipe(
      catchError((res) => {
        return throwError(res);
      })
    );
  }
}
