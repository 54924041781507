import { ActionReducer, MetaReducer } from '@ngrx/store';

import { localStorageSync } from 'ngrx-store-localstorage';

export function sessionStorageSyncReducer(reducer: ActionReducer<any>): ActionReducer<any> {
  return localStorageSync({
    keys: [
      {
        deviceGroup: [
          'deviceCode',
          'unauthorizedDeviceCodes',
          'isParticipantRejoining',
          'rejoiningPackageUserGuids',
          'usersOnDevice',
          'isPinOrMfaComplete',
          'clientHints'
        ],
      },
    ],
    rehydrate: true,
    storage: localStorage,
  })(reducer);
}

export const metaReducers: MetaReducer[] = [sessionStorageSyncReducer];
