import { EndorsementType } from './endorsement-types';

export interface OutgoingSignEndorsement {
  endorsementLocationId: number;
  endorsementType: EndorsementType;
  data?: any;
  optional?: boolean;
  packageUserGuid: string;
}

export interface OutgoingEraseSignedEndorsement {
  endorsementLocationId: number;
  endorsementType: EndorsementType;
  optional?: boolean;
  packageUserGuid: string;
}

export interface IncomingSignedEndorsement {
  endorsementLocationId: number;
  packageUserGuid?: string;
}

export interface EndorsementProcessing {
  endorsementLocationId: number;
  packageUserGuid?: string;
}
