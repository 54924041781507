import { ClientTheme } from './models/client.model';

export const CLIENT_THEME: ClientTheme = {
  primaryColorHexCode: '',
  primaryColorHoverBgHexCode: '',
  primaryColorHoverTextHexCode: '',
  secondaryColorHexCode: '',
  secondaryColorHoverBgHexCode: '',
  secondaryColorHoverTextHexCode: '',
  logoSmallUri: '',
  logoLargeUri: '',
  faviconUri: '',
  documentTitleText: '',
  sessionEndTheme: null,
};
