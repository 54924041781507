import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';

import { Feature } from '../../models';
import { FeatureManagementService } from '../../services';

import { ConditionalDirectiveBase } from 'src/app/directives/conditional-directive-base';

@Directive({
  selector: '[appIfFeatureEnabled]',
})
export class IfFeatureEnabledDirective extends ConditionalDirectiveBase {
  @Input('appIfFeatureEnabled') featureName: Feature;

  constructor(
    private readonly featureManagementService: FeatureManagementService,
    private readonly templateRef: TemplateRef<any>,
    private readonly viewContainer: ViewContainerRef
  ) {
    super(templateRef, viewContainer);
  }

  shouldRenderElement(): boolean {
    return this.featureManagementService.getIsFeatureEnabledWithCaching(this.featureName);
  }
}
