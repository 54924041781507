import { Injectable } from '@angular/core';

import { Store } from '@ngrx/store';

import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { DeviceGroupSelectors } from '../features/device-group';
import { PackagesActions } from '../features/packages';
import { RootStoreState } from '../store';

@Injectable({
  providedIn: 'root',
})
export class RejoinGuard {
  constructor(private readonly store: Store<RootStoreState.State>) {}

  canActivate(): Observable<boolean> {
    return combineLatest([
      this.store.select(DeviceGroupSelectors.getDeviceCode),
      this.store.select(DeviceGroupSelectors.getUnauthorizedDeviceCodes),
    ]).pipe(
      map(([deviceCode, unauthorizedDeviceCode]) => {
        if (unauthorizedDeviceCode?.includes(deviceCode)) {
          this.store.dispatch(PackagesActions.RedirectUnAuthorized());

          return false;
        }

        return true;
      })
    );
  }
}
