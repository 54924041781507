import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Client } from '../models/client.model';

@Injectable()
export class ClientService {
  constructor(
    private readonly httpClient: HttpClient,
  ) {}
  
  getClientForPackage(packageGuid: string) {
    return this.httpClient.get<Client>(`packages/${packageGuid}/client`);
  }

  getClientForUser() {
    return this.httpClient.get<Client>(`client`);
  }
}
