<div class="audio-video-failure-modal-container">
  <div class="audio-video-failure-modal-container__header">
    <div class="sub-header">Audio and/or Video Failure</div>
  </div>
  <div class="audio-video-failure-modal-container__body">
    <p>There seems to be an issue with the audio and/or video.</p>
    <p>
      Please reach out to support before cancelling this session: 
      <a href="tel:{{ (client$ | async)?.supportPhoneNumber | phone }}">{{
          (client$ | async)?.supportPhoneNumber | phone
        }}</a>
    </p>
  </div>
  <div class="audio-video-failure-modal-container__footer">
    <button type="button" class="btn btn--outline-dark" (click)="this.cancelPackageOnUserRequest()">
      Cancel Session
    </button>
  </div>
</div>
