// The below exports are necessary to inject the Window object into the component constructor
// during AOT compilation so the AOT compiler can correctly resolve the WINDOW type

import { InjectionToken } from '@angular/core';

// https://angular.io/guide/aot-metadata-errors#could-not-resolve-type
export const windowToken = new InjectionToken('Window');
export function _windowFactory() {
  return window;
}
