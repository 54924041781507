import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { MatRadioChange } from '@angular/material/radio';

import { ActiveEndorsementSelection, EndorsementSelection } from '../../models';

@Component({
  selector: 'app-signature-options',
  templateUrl: './signature-options.component.html',
  styleUrls: ['./signature-options.component.scss'],
})
export class SignatureOptionsComponent implements OnChanges {
  @Input() ariaLabelSignatureType: string;
  @Input() signatureOptions: Array<EndorsementSelection>;
  @Input() signatureSelection: ActiveEndorsementSelection;
  @Output() optionSelected = new EventEmitter<number>();

  activeSignature: number;

  constructor() {}

  ngOnChanges(changes: SimpleChanges) {
    for (const propName in changes) {
      if (
        changes.hasOwnProperty(propName) &&
        propName === 'signatureSelection' &&
        changes.signatureSelection.currentValue
      ) {
        this.activeSignature = changes.signatureSelection.currentValue.activeIndex;
      }
    }
  }

  onSelectionChanged(change: MatRadioChange): void {
    this.activeSignature = change.value;
    this.optionSelected.emit(change.value);
  }
}
