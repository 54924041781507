import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { RootStoreState } from 'src/app/store';
import { CommissionSelectionService } from '../services/commission-selection.service';
import { CommissionValidityGuardBase } from './commission.validity.guard';

@Injectable({ providedIn: 'root' })
export class ValidCommissionGuard extends CommissionValidityGuardBase {
  constructor(
    commissionsService: CommissionSelectionService,
    router: Router,
    store: Store<RootStoreState.State>
  ) {
    super(commissionsService, router, store);
  }

  relativeDestinationRoute = '../welcome';

  validCommissionTriggersRedirect(hasValidCommission: boolean) {
    return hasValidCommission;
  }
}
