<div class="signing-complete">
  <div data-testid="signing-complete-congrats" class="signing-complete__header">Congrats!</div>
  <div data-testid="signing-complete-body-text" class="signing-complete__body">
    Your signing is now complete. You may exit the signing, or go back and continue to review these
    documents
  </div>
  <div class="signing-complete__footer">
    <app-button
      type="secondary"
      (click)="reviewDocumentsClick()"
      [dataTestId]="'signing-complete-review-doc'"
    >
      Go Back and Review Documents
    </app-button>
    <app-button type="primary" (click)="exitSigningClick()" [dataTestId]="'signing-complete-exit'">
      Exit Signing
    </app-button>
  </div>
</div>
