import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { tap } from 'rxjs/operators';

import { KronActions } from '../actions';

@Injectable()
export class KronNavigationEffects {
  enterSigningRoom$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(KronActions.EnterSigningRoom),
        tap(() => {
          this.router.navigate(['/kron/signing-room'], {
            queryParamsHandling: 'preserve',
          });
        })
      ),
    { dispatch: false }
  );

  endSessionStarted$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(KronActions.NavigateToSessionEnding),
        tap((_) => {
          this.router.navigate(['/kron/session-ending'], {
            queryParamsHandling: 'preserve',
          });
        })
      ),
    { dispatch: false }
  );

  constructor(
    private readonly router: Router,
    private readonly actions$: Actions,
  ) {}
}
