import { Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { combineLatest, Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import {
  PackageUserFirstNamePipe,
  PackageUserRole,
  PackageUsersSelectors,
} from 'src/app/features/package-users';

import { PackagesSelectors, ProductType } from 'src/app/features/packages';
import { RootStoreState } from 'src/app/store';

import { CheckInActions } from '../../store/actions';
import { CheckInSelectors } from '../../store/selectors';

@Component({
  selector: 'app-check-in-complete',
  templateUrl: './check-in-complete.component.html',
  styleUrls: ['./check-in-complete.component.scss'],
})
export class CheckInCompleteComponent implements OnInit {
  canSkipToWaitingRoom$: Observable<boolean>;
  message$: Observable<string>;
  isDeviceCheckInComplete$: Observable<boolean>;
  areOtherUsersEligibleForCheckIn$: Observable<boolean>;
  nextUserName$: Observable<string>;
  previousUserName$: Observable<string>;

  constructor(
    private readonly firstNamePipe: PackageUserFirstNamePipe,
    private readonly store: Store<RootStoreState.State>
  ) {}

  ngOnInit() {
    this.isDeviceCheckInComplete$ = this.store.pipe(
      select(CheckInSelectors.isCurrentDeviceCheckInComplete)
    );

    this.areOtherUsersEligibleForCheckIn$ = combineLatest([
      this.store.pipe(select(PackageUsersSelectors.getPackageUsers)),
      this.store.pipe(select(PackageUsersSelectors.getIsSigningAgent)),
      this.store.pipe(select(PackagesSelectors.isRONOrKRON)),
    ]).pipe(
      map(([users, isSigningAgent, isRonOrKron]) => {
        const usersEligibleToCheckIn = users.filter((u) => {
          const canCheckInAsSigningAgent = isSigningAgent;
          const canCheckInAsNonSigningAgent = (isSigningAgent && !isRonOrKron) || !isSigningAgent;
          return u.userRoleCode === PackageUserRole.SIGNINGAGENT
            ? canCheckInAsSigningAgent
            : canCheckInAsNonSigningAgent;
        });
        return usersEligibleToCheckIn.some((u) => !u.checkInStatus?.isCompleted);
      })
    );

    this.nextUserName$ = this.store.pipe(
      select(CheckInSelectors.getNextCheckInUser),
      switchMap((userGuid) => this.store.select(PackageUsersSelectors.getPackageUser(userGuid))),
      map((packageUser) => this.firstNamePipe.transform(packageUser, 'Witness'))
    );

    this.previousUserName$ = this.store.pipe(
      select(CheckInSelectors.getLastCheckedInUser),
      switchMap((userGuid) => this.store.select(PackageUsersSelectors.getPackageUser(userGuid))),
      map((packageUser) => this.firstNamePipe.transform(packageUser, ''))
    );

    this.canSkipToWaitingRoom$ = this.store.pipe(
      select(PackagesSelectors.getProductType),
      map((productType) => productType !== ProductType.Hybrid && productType !== ProductType.IPEN)
    );

    this.message$ = this.store.pipe(
      select(PackagesSelectors.getProductType),
      map((productType) => {
        if (productType === ProductType.RemoteSigning || productType === ProductType.KRON) {
          return (
            'you have completed the check-in process. You can now head to the waiting ' +
            'room where your signing party will join you once they complete their check-in.'
          );
        }

        return ' you have completed the check-in process. Click on the button below to go to the signing room';
      })
    );
  }

  beginCheckIn() {
    this.store.dispatch(CheckInActions.BeginCheckIn());
  }

  goToWaitingRoom() {
    this.store.dispatch(CheckInActions.RedirectToWaitingRoom());
  }

  returnToWelcomePage() {
    this.store.dispatch(CheckInActions.RedirectToWelcomePage());
  }
}
