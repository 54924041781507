<!--// TODO: User Story 50434: Remove Old Endorsement Application Logic - Delete (endorsementAttempted)="endorsementAttempted($event)"-->
<ng-container *ngIf="endorsementLocation" data-private>
  <ng-container [ngSwitch]="endorsementLocation.endorsementTypeCode">
    <app-signature-endorsement *ngSwitchCase="'SIGNATURE'"
                               [endorsementLocation]="endorsementLocation"
                               [endorsementImage]="endorsementImage | async"
                               [hideIfSigned]="hideIfSigned"
                               [disableFocus]="isAttemptingEndorsement$ | async"
                               [isEndorsementDisabled]="isEndorsementDisabled"
                               (endorsementAttempted)="endorsementAttempted($event)"
                               (signEndorsement)="signEndorsement($event)"
                               (eraseSignedEndorsement)="eraseSignedEndorsement($event)"
                               [areAllEndorsementsSigned]="areAllEndorsementsSigned"></app-signature-endorsement>
    <app-initials-endorsement *ngSwitchCase="'INITIALS'"
                              [endorsementLocation]="endorsementLocation"
                              [endorsementImage]="endorsementImage | async"
                              [hideIfSigned]="hideIfSigned"
                              [disableFocus]="isAttemptingEndorsement$ | async"
                              [isEndorsementDisabled]="isEndorsementDisabled"
                              (endorsementAttempted)="endorsementAttempted($event)"
                              (signEndorsement)="signEndorsement($event)"
                              (eraseSignedEndorsement)="eraseSignedEndorsement($event)"
                              [areAllEndorsementsSigned]="areAllEndorsementsSigned"></app-initials-endorsement>
    <app-stamp-endorsement *ngSwitchCase="'NOTARYSTAMP'"
                           [endorsementLocation]="endorsementLocation"
                           [endorsementImage]="endorsementImage | async"
                           [disableFocus]="isAttemptingEndorsement$ | async"
                           [isEndorsementDisabled]="isEndorsementDisabled"
                           (endorsementAttempted)="endorsementAttempted($event)"
                           (signEndorsement)="signEndorsement($event)"
                           [areAllEndorsementsSigned]="areAllEndorsementsSigned"></app-stamp-endorsement>
    <app-free-text-endorsement *ngSwitchCase="'TEXTBOX'"
                               [endorsementLocation]="endorsementLocation"
                               [endorsementImage]="endorsementImage | async"
                               [disableFocus]="isAttemptingEndorsement$ | async"
                               [isEndorsementDisabled]="isEndorsementDisabled"
                               (endorsementAttempted)="endorsementAttempted($event)"
                               (signEndorsement)="signEndorsement($event)"
                               (eraseSignedEndorsement)="eraseSignedEndorsement($event)"
                               [areAllEndorsementsSigned]="areAllEndorsementsSigned"></app-free-text-endorsement>
    <app-checkbox-endorsement *ngSwitchCase="'CHECKBOX'"
                              [endorsementLocation]="endorsementLocation"
                              [endorsementImage]="endorsementImage | async"
                              [disableFocus]="isAttemptingEndorsement$ | async"
                              [isEndorsementDisabled]="isEndorsementDisabled"
                              (endorsementAttempted)="endorsementAttempted($event)"
                              (signEndorsement)="signEndorsement($event)"
                              (eraseSignedEndorsement)="eraseSignedEndorsement($event)"
                              [areAllEndorsementsSigned]="areAllEndorsementsSigned"></app-checkbox-endorsement>
    <!--TODO: USER STORY 61107: NEA System Field Code Clean-up -->
    <!--delete the wrapping div and the neaSystemFieldsDisabled template"-->
    <div *ngIf="isNeaSystemFieldsEnabled; else neaSystemFieldsDisabled">
    <app-system-endorsement *ngSwitchCase="'STATE'"
                            [endorsementLocation]="endorsementLocation"
                            [endorsementImage]="endorsementImage | async"
                            [altText]="'State'"></app-system-endorsement>
    <app-system-endorsement *ngSwitchCase="'DATE'"
                            [endorsementLocation]="endorsementLocation"
                            [endorsementImage]="endorsementImage | async"
                            [altText]="'Date'"></app-system-endorsement>
    <app-system-endorsement *ngSwitchCase="'PRINTEDNAME'"
                            [endorsementLocation]="endorsementLocation"
                            [endorsementImage]="endorsementImage | async"
                            [altText]="'Printed name'"></app-system-endorsement>
    <app-system-endorsement *ngSwitchCase="'SIGNINGAGENTNAME'"
                            [endorsementLocation]="endorsementLocation"
                            [endorsementImage]="endorsementImage | async"
                            [altText]="'Signing agent name'"></app-system-endorsement>
    <app-system-endorsement *ngSwitchCase="'SIGNINGAGENTCOUNTY'"
                            [endorsementLocation]="endorsementLocation"
                            [endorsementImage]="endorsementImage | async"
                            [altText]="'Signing agent county'"></app-system-endorsement>
    <app-system-endorsement *ngSwitchCase="'SIGNINGAGENTCOMMEXPIRE'"
                            [endorsementLocation]="endorsementLocation"
                            [endorsementImage]="endorsementImage | async"
                            [altText]="'Signing agent commission expiration'"></app-system-endorsement>
    <app-system-endorsement *ngSwitchCase="'SIGNINGAGENTCOMMNUMBER'"
                            [endorsementLocation]="endorsementLocation"
                            [endorsementImage]="endorsementImage | async"
                            [altText]="'Signing agent commission number'"></app-system-endorsement>
    <app-system-endorsement *ngSwitchCase="'SIGNINGAGENTCOMMSTATE'"
                            [endorsementLocation]="endorsementLocation"
                            [endorsementImage]="endorsementImage | async"
                            [altText]="'Signing agent commission state'"></app-system-endorsement>
    <app-system-endorsement *ngSwitchCase="'SIGNINGAGENTCOMMCOUNTY'"
                            [endorsementLocation]="endorsementLocation"
                            [endorsementImage]="endorsementImage | async"
                            [altText]="'Signing agent commission county'"></app-system-endorsement>
    </div>
    <ng-template #neaSystemFieldsDisabled>
      <ng-template *ngSwitchCase="'STATE'"></ng-template>
      <ng-template *ngSwitchCase="'DATE'"></ng-template>
      <ng-template *ngSwitchCase="'PRINTEDNAME'"></ng-template>
      <ng-template *ngSwitchCase="'SIGNINGAGENTNAME'"></ng-template>
      <ng-template *ngSwitchCase="'SIGNINGAGENTCOUNTY'"></ng-template>
      <ng-template *ngSwitchCase="'SIGNINGAGENTCOMMEXPIRE'"></ng-template>
      <ng-template *ngSwitchCase="'SIGNINGAGENTCOMMNUMBER'"></ng-template>
      <ng-template *ngSwitchCase="'SIGNINGAGENTCOMMSTATE'"></ng-template>
      <ng-template *ngSwitchCase="'SIGNINGAGENTCOMMCOUNTY'"></ng-template>
    </ng-template>
    <app-unsupported-endorsement *ngSwitchDefault
                                 [endorsementLocation]="endorsementLocation">
    </app-unsupported-endorsement>
  </ng-container>
</ng-container>
