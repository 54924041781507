import { Component } from '@angular/core';

@Component({
  selector: 'app-guide-icon',
  template: `
    <svg width="17" height="20" viewBox="0 0 17 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.12771 3.59325C8.32973 7.26525 10.9406 10.1573 14.2554 11.4888C10.9406 12.8205 8.32973 15.7125 7.12771
           19.3845C5.92569 15.7125 3.31486 12.8205 0 11.4888C3.31486 10.1573 5.92569 7.26525 7.12771 3.59325ZM14.0649
           0C13.5699 1.51225 12.4947 2.703 11.1298 3.25125C12.495 3.7995 13.5701 4.9905 14.0649 6.50275C14.5599 4.9905
           15.6351 3.79975 17 3.25125C15.6349 2.70325 14.5599 1.51225 14.0649 0ZM13.9129 15.5747C13.5761 16.6038
           12.8444 17.4142 11.9155 17.7873C12.8446 18.1605 13.5761 18.9708 13.9129 20C14.2497 18.971 14.9814 18.1605
           15.9103 17.7873C14.9814 17.4142 14.2497 16.604 13.9129 15.5747Z"
        fill="var(--app-guide-icon-fill, #0051D1)"
      />
    </svg>
  `,
  styleUrls: ['./guide-icon.component.scss'],
})
export class GuideIconComponent {}
