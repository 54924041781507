import { Injectable } from '@angular/core';
import {
  Actions,
  createEffect,
  ofType
} from '@ngrx/effects';
import {
  select,
  Store
} from '@ngrx/store';
import { of } from 'rxjs';
import {
  concatMap,
  map,
  switchMap,
  withLatestFrom
} from 'rxjs/operators';

import {
  ModalsActions,
  ModalsSelectors
} from 'src/app/features/modals';
import { PackageUsersSelectors } from 'src/app/features/package-users';
import { RootStoreState } from 'src/app/store';

import { MultiFactorChallengeModalComponent } from '../../components';
import { MultiFactorErrorType } from '../../models';
import { MultiFactorActions } from '../actions';

@Injectable()
export class MultiFactorModalEffects {
  startMFAChallengeMenu$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MultiFactorActions.StartMultiFactorChallengeFromModalComponent),
      concatMap((action) =>
        of(action).pipe(
          withLatestFrom(
            this.store.select(PackageUsersSelectors.getPackageUser(action.payload.packageUserGuid))
          )
        )
      ),
      map(([_, packageUser]) =>
        ModalsActions.SetModalComponent({
          payload: {
            modalTitle: 'Verify your identity',
            component: MultiFactorChallengeModalComponent,
            componentData: {
              packageUser: packageUser,
            },
            allowManualClose: false,
          },
        })
      )
    )
  );

  multiFactorAttemptCompleteOrCancelled$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        MultiFactorActions.MultiFactorAttemptSuccess,
        MultiFactorActions.CancelMultiFactorChallenge
      ),
      map((action) =>
        ModalsActions.ClearModalComponentIfOpen({
          payload: {
            component: MultiFactorChallengeModalComponent,
          },
        })
      )
    )
  );

  multiFactorAttemptError$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MultiFactorActions.CompleteMultiFactorChallengeError),
      concatMap((action) =>
        of(action).pipe(
          withLatestFrom(
            this.store.pipe(select(ModalsSelectors.getModalComponent))
          )
        )
      ),
      switchMap(([action, modalComponent]) => {
        const mfaError = action.payload;
        if (modalComponent?.name === MultiFactorChallengeModalComponent.name
          && mfaError.errorType === MultiFactorErrorType.Unknown) {
          return [
            MultiFactorActions.CancelMultiFactorChallenge(),
          ];
        }

        return [];
      })
    )
  );

  constructor(
    private readonly actions$: Actions,
    private readonly store: Store<RootStoreState.State>
  ) {}
}
