import { Component, computed, inject, OnDestroy } from '@angular/core';

import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';

import { AudioVideoCheckService } from 'src/app/features/av-check';
import { MicCheckService } from 'src/app/features/av-check/services/mic-check.service';
import { SpeakerCheckService } from 'src/app/features/av-check/services/speaker-check.service';
import { SharedComponentService } from 'src/app/features/av-check/services/shared-component.service';

import {
  SelectMicrophone,
  SelectSpeaker,
  SelectVideo,
} from 'src/app/features/av-check/store/actions/audio-video-check.actions';

import { AudioVideoCheckSelectors } from 'src/app/features/av-check/store/selectors';
import { ModalsActions } from 'src/app/features/modals';

@Component({
  selector: 'app-audio-video-modal-v2',
  templateUrl: './audio-video-modal-v2.component.html',
  styleUrls: ['./audio-video-modal-v2.component.scss'],
})
export class AudioVideoModalV2Component implements OnDestroy {
  static identifier = 'AudioVideoModalV2Component';

  private readonly audioVideoCheckService = inject(AudioVideoCheckService);
  private readonly micCheckService = inject(MicCheckService);
  private readonly speakerCheckService = inject(SpeakerCheckService);
  private readonly store = inject(Store);

  private readonly destroySubject: Subject<boolean> = new Subject<boolean>();

  private readonly preSelectedAudioInput: MediaDeviceInfo = this.micCheckService.currentMicDevice();

  private readonly preSelectedAudioOutput: MediaDeviceInfo =
    this.speakerCheckService.selectAudioOutputDevice();

  private readonly preSelectedVideo: MediaDeviceInfo =
    this.audioVideoCheckService.selectVideoDevice();

  sharedComponentService = inject(SharedComponentService);

  ngOnDestroy(): void {
    this.destroySubject.next(undefined);
    this.destroySubject.complete();
  }

  cancelAudioVideoSelections() {
    this.store.dispatch(SelectSpeaker({ speaker: this.preSelectedAudioOutput }));
    this.store.dispatch(SelectMicrophone({ microphone: this.preSelectedAudioInput }));
    this.store.dispatch(SelectVideo({ video: this.preSelectedVideo }));

    this.store.dispatch(ModalsActions.ClearModalComponent());
  }

  continue() {
    this.store.dispatch(ModalsActions.ClearModalComponent());
  }
}
