<app-card backgroundStyle="brand" aria-label="Appointment time and address details about your signing session">
  <div class="signing-details">
    <div class="signing-details__date">
      <app-spark-icon icon="today" size="22"></app-spark-icon>
      <div>
        <p>{{ scheduledTime$ | async }}</p>
        <p class="signing-details__date__starts-in">{{ startsIn$ | async }}</p>
      </div>
    </div>
    <div *ngIf="showAddress$ | async" class="signing-details__address">
      <app-spark-icon icon="for_sale" size="22"></app-spark-icon>
      <p data-private>
        {{ propertyAddress$ | async }}
      </p>
    </div>
  </div>
</app-card>
