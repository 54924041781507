import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { ModalsActions } from 'src/app/features/modals';

import { DEFAULT_APPLICATION_PHONE_NUMBER } from 'src/app/features/shared/constants';
import { RootStoreState } from 'src/app/store';

@Component({
  selector: 'app-opt-out-modal',
  templateUrl: './opt-out-modal.component.html',
  styleUrls: ['./opt-out-modal.component.scss'],
})
export class OptOutModalComponent implements OnInit {
  static identifier = 'OptOutModalComponent';
  @Input() onSuccess: () => void;
  @Output() public continue = new EventEmitter<void>();

  public phoneNumber: string;

  constructor(private readonly store: Store<RootStoreState.State>) {}

  ngOnInit(): void {
    this.phoneNumber = DEFAULT_APPLICATION_PHONE_NUMBER;
  }

  closeClick() {
    this.store.dispatch(ModalsActions.ClearModalComponent());
  }

  static openModal(store: Store<RootStoreState.State>) {
    store.dispatch(ModalsActions.SetStandaloneModalComponent({
      payload:{
        component: OptOutModalComponent,
        shouldFade: true,
      }
    }));
  }
}
