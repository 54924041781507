<nav class="nav-bar" role="navigation">
  <app-branding #imageDropdown [(showDropdown)]="showMobileNav"></app-branding>

  <div *appMobileOnly [ngClass]="showMobileNav ? 'nav-bar__mobile--expanded' : 'nav-bar__mobile'">
    <ng-content select="[mobile]"></ng-content>
  </div>

  <div class="nav-bar__right-side">
    <ng-content></ng-content>
  </div>
</nav>
