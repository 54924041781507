import { createAction, props } from '@ngrx/store';

import { DeviceUser } from '../../models';

export const DeviceCodeChanged = createAction('[Device Group] Device Code Changed');

export const SetDeviceCode = createAction(
  '[Device Group] Set Device Code',
  props<{ payload: { deviceCode: string } }>()
);

export const GetDeviceCode = createAction('[Device Group] Get Device Code');

export const SaveToDeviceGroup = createAction(
  '[Device Group] Save To Device Group',
  props<{ payload: { packageUserGuid: string } }>()
);

export const SaveNotaryToDeviceGroup = createAction('[Device Group] Save Notary To Device Group');

export const SaveToDeviceGroupSuccessful = createAction(
  '[Device Group] Save To Device Group Successful',
  props<{ payload: { packageUserGuid: string } }>()
);

export const GetCurrentDeviceUsers = createAction('[Device Group] Get Current Device Users');

export const RemovePackageUserFromDevice = createAction(
  '[Device Group] Remove User From Device',
  props<{ payload: { packageUserGuid: string } }>()
);

export const GetCurrentDeviceUsersSuccessful = createAction(
  '[Device Group] Get Current Device Users Successful',
  props<{ payload: { deviceUsers: DeviceUser[] } }>()
);

export const DeviceCodeChangeSuccessful = createAction(
  '[Device Group] Device Code Change Successful',
  props<{ payload: { deviceCode: string; packageGuid: string } }>()
);

export const SetParticipantRejoining = createAction(
  '[Device Group] Set Participant Rejoining',
  props<{ payload: { isParticipantRejoining: boolean; packageUserGuid: string } }>()
);

export const AddParticipantToDeviceSuccessful = createAction(
  '[Device Group] Add Participant To Device Successful',
  props<{ payload: { packageUserGuid: string } }>()
);

export const SetUnauthorizedDeviceCode = createAction(
  '[Device Group] Set Unauthorized DeviceCode',
  props<{ deviceCode: string }>()
);

export const DeviceGroupUpdated = createAction('[Device Group] Device Group Updated');

export const SetIsDeviceUsersLoading = createAction(
  '[Device Group] Set Set Is Device Users Loading',
  props<{ isLoading: boolean }>()
);

export const SetIsPINOrMFAComplete = createAction(
  '[Device Group] Set Is PIN or MFA Complete',
  props<{ isPinOrMfaComplete: boolean }>()
);

export const GetCurrentDeviceUsersDelayed = createAction(
  '[Device Group] Get Current Device Users Delayed'
);

export const SetClientHintsForDevice = createAction(
  '[Device Group] Set Client Hints For Device',
  props<{ clientHints: UADataValues }>()
);
