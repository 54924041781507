import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { CancelSigningModalComponent } from 'src/app/features/layout/components/cancel-signing-modal';

import { ModalsActions } from 'src/app/features/modals/store';
import { RootStoreState } from 'src/app/store';

@Component({
  templateUrl: './unable-to-process-modal.component.html',
  styleUrls: ['./unable-to-process-modal.component.scss'],
})
export class UnableToProcessModalComponent {
  static identifier = 'UnableToProcessModalComponent';

  constructor(private readonly store: Store<RootStoreState.State>) {}

  cancelPackageOnUserRequest() {
    this.store.dispatch(ModalsActions.ClearModalComponent());
    this.store.dispatch(
      ModalsActions.SetStandaloneModalComponent({
        payload: {
          component: CancelSigningModalComponent,
          componentData: {
            fixedCategoryTypeCode: 'IdVerificationIssue',
          },
        },
      })
    );
  }
}
