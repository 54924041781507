import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import { Guid } from 'guid-typescript';
import { EMPTY, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { Client } from 'src/app/features/client';
import { BrowserClientSupportClientHintsRequestModel } from 'src/app/features/packages/models/browser-client-support-client-hints-request.model';
import { BrowserClientSupportOnBrowserLogRequestModel } from 'src/app/features/packages/models/browser-client-support-log-request.model';
import { BrowserClientSupportResult } from 'src/app/features/packages/models/browser-client-support-result.model';
import { SignalRService } from 'src/app/features/signal-r';
import { RootStoreState } from 'src/app/store';

import {
  BeginSessionRequest,
  MonthlyClosings,
  Package,
  PackageOptOutRequest,
  PackageScheduleStatusResult,
  PackageSessionCompleteRequest,
  PackageStatus,
  PackageValidationResult,
  ProductType,
} from '../models';
import { PackageStatusResult } from '../models/package-status-result.model';
import { PackagesActions } from '../store/actions';

@Injectable()
export class PackagesService {
  private listenerConfigured = false;
  constructor(
    private readonly httpClient: HttpClient,
    private readonly store: Store<RootStoreState.State>,
    private readonly signalRService: SignalRService
  ) {}

  configureListeners() {
    if (!this.listenerConfigured) {
      this.listenerConfigured = true;
      this.signalRService.hubConnection.on('PackageStatusChanged', (data) => {
        const statusCode: PackageStatus = data.StatusCode;

        if (
          statusCode === PackageStatus.CANCELLED ||
          statusCode === PackageStatus.DELETED ||
          statusCode === PackageStatus.OPTOUT
        ) {
          this.store.dispatch(PackagesActions.SetCancelPackage());
        } else if (
          statusCode === PackageStatus.COMPLETE ||
          statusCode === PackageStatus.SESSIONCOMPLETE
        ) {
          this.store.dispatch(PackagesActions.SetCompletePackage());
        }
      });

      this.signalRService.hubConnection.on('PackageOptedOut', (data) => {
        this.store.dispatch(
          PackagesActions.BroadCastSigningCancelled({
            payload: { packageUserGuid: data.packageUserGuid },
          })
        );
      });

      this.signalRService.hubConnection.on('PackageCancelled', (data) => {
        this.store.dispatch(
          PackagesActions.BroadCastSigningCancelled({
            payload: { packageUserGuid: data.packageUserGuid },
          })
        );
        this.store.dispatch(PackagesActions.SetCancelPackage());
        this.store.dispatch(PackagesActions.RedirectOrderCancelled());
      });

      this.signalRService.hubConnection.on('NotaryPasswordAttemptsExhaustedEvent', (Data) => {
        this.store.dispatch(
          PackagesActions.BroadCastNotaryPasswordAttemptsExhausted({
            payload: {
              packageUserGuid: Data.packageUserGuid,
            },
          })
        );
      });
    }
  }

  beginSession(request: BeginSessionRequest) {
    return this.httpClient.post(`packages/session`, request);
  }

  getPackage(packageUserGuid: string) {
    return this.httpClient.get<Package>(`packages/packageusers/${packageUserGuid}`);
  }

  getClientForPackage(packageGuid: string) {
    return this.httpClient.get<Client>(`packages/${packageGuid}/client`);
  }

  getPackageScheduledStatus(packageGuid: string) {
    return this.httpClient.get<PackageScheduleStatusResult>(
      `packages/${packageGuid}/scheduledStatus`
    );
  }

  getPackageStatus(packageGuid: string) {
    return this.httpClient.get<PackageStatusResult>(`packages/${packageGuid}/status`).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error != null) {
          return throwError(error);
        }
        return EMPTY;
      })
    );
  }

  getPackageByGuid(packageGuid: string) {
    return this.httpClient.get<Package>(`packages/${packageGuid}`);
  }

  getClientsSupportOnBrowserWithClientHints(packageGuid: string, packageUserGuid: string, clientHints: UADataValues) {
    const browserClientSupportClientHintsRequest: BrowserClientSupportClientHintsRequestModel = {
      packageGuid: packageGuid ?? Guid.EMPTY.toString(),
      packageUserGuid: packageUserGuid ?? Guid.EMPTY.toString(),
      clientHints: clientHints,
    }

    return this.httpClient.post<BrowserClientSupportResult>(
      `browserClientSupport/clientHints`, browserClientSupportClientHintsRequest
    );
  }

  getClientsSupportOnBrowserWithUserAgent() {
    return this.httpClient.get<BrowserClientSupportResult>(
      `browserClientSupport/userAgent`
    );
  }

  postClientsSupportOnBrowserLog(browserClientSupportOnBrowserLogRequestModel: BrowserClientSupportOnBrowserLogRequestModel) {
    return this.httpClient.post<void>(
      `browserClientSupport/log`, browserClientSupportOnBrowserLogRequestModel
    );
  }

  getPackageValidation() {
    return this.httpClient.get<PackageValidationResult>(
      `packages/validation`
    );
  }

  optOut(request: PackageOptOutRequest) {
    return this.httpClient.post(`packages/${request.packageGuid}/OptOutStatus`, request);
  }

  // Deprecated service method use optOut
  optOutPackage(
    packageGuid: string,
    wizardUserGuid: string,
    packageUserGuid: string,
    deviceCode: string,
    productType: ProductType,
    isSharedDevice: boolean,
    categoryTypeCode: string,
    comments: string
  ) {
    if (isSharedDevice && productType === ProductType.RemoteSigning) {
      const data: PackageOptOutRequest = {
        packageUserGuid: Guid.EMPTY,
        deviceCode: deviceCode,
        categoryTypeCode: categoryTypeCode,
        comments: comments,
      };
      return this.httpClient.post(`packages/${packageGuid}/OptOutStatus`, data);
    } else if (packageUserGuid === undefined && productType === ProductType.RemoteSigning) {
      const data: PackageOptOutRequest = {
        packageUserGuid: wizardUserGuid,
        deviceCode: deviceCode,
        categoryTypeCode: categoryTypeCode,
        comments: comments,
      };
      return this.httpClient.post(`packages/${packageGuid}/OptOutStatus`, data);
    } else {
      const data: PackageOptOutRequest = {
        packageUserGuid: packageUserGuid,
        deviceCode: deviceCode,
        categoryTypeCode: categoryTypeCode,
        comments: comments,
      };
      return this.httpClient.post(`packages/${packageGuid}/OptOutStatus`, data);
    }
  }

  cancelPackage(packageGuid: string, categoryTypeCode: string) {
    return this.httpClient.put(`packages/${packageGuid}/cancel/${categoryTypeCode}`, null);
  }

  getOptOutReasons() {
    return this.httpClient.get(`typelookups/PackageStatusDetail/CategoryTypeCode`);
  }

  getMonthlyClosingsCount() {
    return this.httpClient.get<MonthlyClosings>(`packages/monthlyClosingsCount`);
  }

  postSessionPackageCompleted(packageGuid: string, packageUserGuid: string, deviceCode: string) {
    const request: PackageSessionCompleteRequest = {
      deviceCode: deviceCode,
      packageUserGuid: packageUserGuid,
    };
    return this.httpClient.post(`packages/${packageGuid}/sessionPackageCompleted`, request);
  }
}
