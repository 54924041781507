<button
  *appMobileOnly
  class="mobile-logo"
  (click)="toggleDropdown()"
  data-testid="mobile-logo-dropdown-button"
>
  <img [src]="(this.clientTheme$ | async)?.logoSmallUri" alt="Clear Sign, Powered by Amrock Logo" tabindex="0"/>
  <app-spark-icon icon="{{ dropDownIcon }}" size="16"></app-spark-icon>
</button>
<img class="logo" [src]="(this.clientTheme$ | async)?.logoSmallUri" alt="Clear Sign, Powered by Amrock Logo" tabindex="0"/>
