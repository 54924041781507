import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { combineLatest, interval, Observable, Subject } from 'rxjs';
import { distinctUntilChanged, map, startWith, takeUntil, takeWhile, tap } from 'rxjs/operators';

import {
  PackageUserRole,
  PackageUsersActions,
  PackageUsersSelectors,
} from 'src/app/features/package-users';
import { PackageUserWithDisplayName } from 'src/app/features/package-users/util';
import { RootStoreState } from 'src/app/store';

import { CheckInActions, CheckInSelectors } from '../../store';

@Component({
  selector: 'app-welcome-participant',
  templateUrl: './welcome-participant.component.html',
  styleUrls: ['./welcome-participant.component.scss'],
})
export class WelcomeParticipantComponent implements OnDestroy, OnInit {
  @Input() participant: PackageUserWithDisplayName;
  @Output() participantClicked = new EventEmitter();
  @Input() isInWaitingRoom = false;

  imageSources: Record<string, string> = {
    [PackageUserRole.WITNESS]: 'assets/images/witness-eye.svg',
    [PackageUserRole.SIGNINGAGENT]: 'assets/images/notary-participant-image.svg',
    default: 'assets/images/feather-pen.svg',
  };
  imageDescriptions: Record<string, string> = {
    [PackageUserRole.WITNESS]: 'Image of eyeball',
    [PackageUserRole.SIGNINGAGENT]: 'Image of Notary Stamp',
    default: 'Image of feather pen',
  };

  imageSrc: string;
  imageAltText: string;
  isDisabled$: Observable<boolean>;
  isSelected$: Observable<boolean>;
  pollingIntervalInSeconds = 3;
  user$: Observable<PackageUserWithDisplayName>;

  private readonly onDestroyNotifier$ = new Subject();

  constructor(private readonly store: Store<RootStoreState.State>) {}

  ngOnInit(): void {
    const userRoleCode =
      this.participant.userRoleCode === PackageUserRole.WITNESS ||
      this.participant.userRoleCode === PackageUserRole.SIGNINGAGENT
        ? this.participant.userRoleCode
        : 'default';
    this.imageSrc = this.imageSources[userRoleCode];
    this.imageAltText = this.imageDescriptions[userRoleCode];

    this.user$ = this.store
      .select(PackageUsersSelectors.getPackageUser(this.participant.packageUserGuid))
      .pipe(map((pu) => ({ ...pu, displayName: this.participant.displayName })));

    this.isSelected$ = this.store.pipe(
      select(CheckInSelectors.getUserCheckInSequence),
      map((checkInSequence) => checkInSequence.includes(this.participant.packageUserGuid))
    );

    this.isDisabled$ = combineLatest([
      this.user$,
      this.store.select(CheckInSelectors.isRejoining),
      this.store.select(CheckInSelectors.getUserCheckInSequence),
    ]).pipe(
      map(([user, isRejoining, usersSelected]) => {
        if (isRejoining) {
          const hasNoSelectedUsers = usersSelected.length === 0;
          const isSelectedUser =
            usersSelected.length === 1 && usersSelected[0] === user?.packageUserGuid;
          return !(hasNoSelectedUsers || isSelectedUser);
        }
        return (
          user?.checkInStatus?.isCompleted && user.userRoleCode !== PackageUserRole.SIGNINGAGENT
        );
      })
    );

    if (this.participant.userRoleCode !== PackageUserRole.SIGNINGAGENT) {
      this.pollForCheckInStatusUpdates().subscribe();
    }
  }

  ngOnDestroy(): void {
    this.onDestroyNotifier$.next(undefined);
    this.onDestroyNotifier$.complete();
  }

  onSelectParticipant() {
    this.store.dispatch(
      CheckInActions.CheckForDeviceSwitch({
        payload: { 
          packageUserGuid: this.participant.packageUserGuid,
          isInWaitingRoom: this.isInWaitingRoom 
        },
      })
    );
  }

  pollForCheckInStatusUpdates(): Observable<[number, boolean]> {
    return combineLatest([
      interval(this.pollingIntervalInSeconds * 1000).pipe(startWith(0)),
      this.store
        .select(PackageUsersSelectors.getPackageUser(this.participant.packageUserGuid))
        .pipe(
          map((u) => u?.checkInStatus?.isCompleted),
          distinctUntilChanged()
        ),
    ]).pipe(
      takeUntil(this.onDestroyNotifier$),
      takeWhile(([_, isCheckInCompleted]) => !isCheckInCompleted),
      tap(() =>
        this.store.dispatch(
          PackageUsersActions.FetchPackageUserCheckInStatus({
            payload: {
              packageUserGuid: this.participant.packageUserGuid,
            },
          })
        )
      )
    );
  }
}
