<img
  class="feedback-image"
  src="../../../assets/images/clock.svg"
  alt=""
  data-testid="expired-pic"
/>
<div class="message-container">
  <h1 class="message-container__header" data-testid="expired-header">The Signing has expired</h1>
  <p class="message-container__message" data-testid="expired-text">
    This Signing has passed its scheduled date and needs to be rescheduled.
  </p>
  <app-further-assistance></app-further-assistance>
</div>
