import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';

import { interval, Subscription } from 'rxjs';

import { PackageUser } from 'src/app/features/package-users';

@Component({
  selector: 'app-lobby-participant',
  templateUrl: './lobby-participant.component.html',
  styleUrls: ['./lobby-participant.component.scss'],
})
export class LobbyParticipantComponent implements OnInit, OnDestroy {
  constructor() {}

  @Input() participant: PackageUser;
  @Input() isClickable = true;
  @Input() isEnteringSigningSession = false;
  @Input() isNewSession: boolean;
  @Input() placeholderName: string;
  @Output() participantClick: EventEmitter<PackageUser> = new EventEmitter<PackageUser>();

  protected timerUpdate: Subscription;

  ngOnInit(): void {
    this.setTimerUpdate();
  }

  get isCheckin(): boolean {
    return this.isClickable && !this.participant?.checkInStatus?.isCompleted;
  }

  get isRejoin(): boolean {
    return (
      this.isClickable &&
      this.participant?.checkInStatus?.isCompleted &&
      this.isNewSession &&
      this.isEnteringSigningSession
    );
  }

  get updatedElapsedMilliseconds() {
    if (!!this.participant?.checkInStatus) {
      return this.participant.checkInStatus.secondsSinceLastAction * 1000;
    }
    return 0;
  }

  ngOnDestroy(): void {
    this.timerUpdate?.unsubscribe();
  }

  handleParticipantClick() {
    this.participantClick.emit(this.participant);
  }

  protected setTimerUpdate(): void {
    this.timerUpdate = interval(1000).subscribe(() => {
      if (!!this.participant?.checkInStatus?.secondsSinceLastAction)
        this.participant.checkInStatus.secondsSinceLastAction++;
    });
  }
}
