import { Action, createReducer, on } from '@ngrx/store';

import { RonActions } from '../actions';
import { RonState } from '../state';

const reducer = createReducer(
  RonState.initialState,
  on(RonActions.SetEndSessionSuccessful, (state: RonState.State) => ({
    ...state,
    isEndSessionSuccessful: true,
  })),
  on(RonActions.SetEndSessionFailure, (state: RonState.State) => ({
    ...state,
    isEndSessionFailure: true,
  })),
  on(RonActions.SetSessionStatuses, (state: RonState.State, { payload }) => ({
    ...state,
    isComplete: payload.isComplete,
    isSessionEnded: payload.isSessionEnded,
  }))
);

export function ronReducer(state: RonState.State | undefined, action: Action) {
  return reducer(state, action);
}
