import { RecordingStatus } from 'src/app/features/video-conference/chime/enums/recording-status-modals.enum';
import { AttendeeResponse } from 'src/app/features/video-conference/chime/models/AttendeeResponse';
import { ListAttendeesResponse } from 'src/app/features/video-conference/chime/models/ListAttendeesResponse';
import { MeetingResponse } from 'src/app/features/video-conference/chime/models/MeetingResponse';

export interface State {
  meeting: MeetingResponse;
  attendee: AttendeeResponse;
  attendees: ListAttendeesResponse;
  recordingStatus: RecordingStatus;
}

export const initialState: State = {
  meeting: null,
  attendee: null,
  attendees: null,
  recordingStatus: null,
};
