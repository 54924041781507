import { createFeatureSelector, createSelector } from '@ngrx/store';
import { modifiedPropertiesHash } from '../../helpers';

import { RulesState } from '../state';

export const getRulesState = createFeatureSelector<RulesState.State>('rules');

export const getRulesForUserRole = (
  userRoleCode: string,
  deviatedProperties: string[] = []
) =>
  createSelector(getRulesState, (state: RulesState.State) => {
    const ruleSet = modifiedPropertiesHash(deviatedProperties);
    if (!state[userRoleCode] || !state[userRoleCode][ruleSet]) {
      return null;
    }
    return state[userRoleCode][ruleSet];
  });
