//#region imports
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { EMPTY, of } from 'rxjs';
import { catchError, concatMap, map, withLatestFrom } from 'rxjs/operators';

import { NotificationsActions, NotificationType } from 'src/app/features/notifications';
import { PackageUserRole, PackageUsersSelectors } from 'src/app/features/package-users';
import { PackagesSelectors } from 'src/app/features/packages';
import { RootStoreState } from 'src/app/store';
import { v4 as uuid } from 'uuid';

import { modifiedPropertiesHash } from '../../helpers';
import { defaultRulesRequest, RulesRequest } from '../../models';
import { RulesService } from '../../services';
import { RulesActions } from '../actions';
import { RulesSelectors } from '../selectors';
import { RulesState } from '../state';

//#endregion imports

@Injectable()
export class RulesEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly rulesService: RulesService,
    private readonly store: Store<RootStoreState.State>
  ) {}

  getRules$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RulesActions.GetRulesForUser), // Needs a type to trigger.
      withLatestFrom(
        this.store.pipe(select(PackageUsersSelectors.getActivePackageUser)),
        this.store.pipe(select(PackagesSelectors.getActivePackageId)),
        this.store.pipe(select(PackagesSelectors.getActivePackageState)),
        this.store.pipe(select(RulesSelectors.getRulesState)),
        this.store.pipe(select(PackagesSelectors.getProductType)),
      ),
      concatMap(
        ([action, activePackageUser, activePackageId, activePackageState, existingRules, productType]) => {
          const relevantPackageUser = !!action.payload.packageUser
            ? action.payload.packageUser
            : activePackageUser;

          const ruleSet = modifiedPropertiesHash(
            Object.keys(action.payload.modifiedProperties)
          );

          if (!!existingRules[relevantPackageUser.userRoleCode][ruleSet] &&
            this.evaulateIfRuleSetModified(existingRules, relevantPackageUser.userRoleCode,
              ruleSet, action.payload.modifiedProperties)) {
              return EMPTY;
          }

          const request: RulesRequest = {
            ...defaultRulesRequest, // Required Static properties
            // dynamic properties set by store
            id: activePackageId,
            stateCode: activePackageState,
            userRoleCode: relevantPackageUser.userRoleCode,
            productType: productType,
            ...action.payload.modifiedProperties, // Overwrite modified properties
          };
          return this.rulesService.GetRulesResult(request).pipe(
            map((result) =>
              RulesActions.SetRulesForUser({
                payload: {
                  result,
                  packageUser: relevantPackageUser,
                  modifiedProperties: action.payload.modifiedProperties,
                },
              })
            ),
            catchError((err) =>
              of(
                NotificationsActions.AddNotification({
                  payload: {
                    exception: err,
                    notificationType: NotificationType.Error,
                    id: uuid(),
                    text: 'Failed to get eligibility rules by state',
                  },
                })
              )
            )
          );
        }
      )
    )
  );

  evaulateIfRuleSetModified(existingRules: RulesState.State, packageUserRole: PackageUserRole, ruleSet: string, modifiedProperties: any): boolean {

    if (ruleSet === "stateCode") {
      return existingRules[packageUserRole][ruleSet].stateCode === modifiedProperties.stateCode;
    }

    return true;
  }
}
