import { NextStep } from '../../models/';

export interface State {
  userCheckInSequence: string[];
  usersCompletedCheckIn: string[];
  nextStep: NextStep;
  usersViewedWaitingRoom: string[];
}

export const initialState: State = {
  userCheckInSequence: [],
  usersCompletedCheckIn: [],
  nextStep: null,
  usersViewedWaitingRoom: [],
};
