import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { TypedAction } from '@ngrx/store/src/models';
import { of } from 'rxjs';
import { concatMap, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { DeviceGroupSelectors } from 'src/app/features/device-group/store/selectors';

import { CancelSigningModalComponent } from 'src/app/features/layout/components/cancel-signing-modal/cancel-signing-modal.component';
import { OptOutModalComponent } from 'src/app/features/layout/components/opt-out-modal/opt-out-modal.component';

import { PackageUsersSelectors } from 'src/app/features/package-users';

import { RootStoreState } from 'src/app/store';

import { ModalsSelectors } from '../selectors';
import { ModalsActions } from '../actions';
import { VideoActions } from 'src/app/features/video/store/actions';
import { PackagesActions } from 'src/app/features/packages';

@Injectable()
export class ModalsEffects {
  closeDialog$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ModalsActions.ClearModalComponent, ModalsActions.CloseDialog),
        tap(() => {
          requestAnimationFrame(() => {
            (document.querySelector('app-modal-container > dialog') as HTMLDialogElement)?.close();
          })
        })
      ),
    { dispatch: false }
  );

  closeModalIfOpen$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ModalsActions.ClearModalComponentIfOpen),
      concatMap((action) =>
        of(action).pipe(withLatestFrom(this.store.pipe(select(ModalsSelectors.getModalComponent))))
      ),
      switchMap(([action, modalComponent]: [
        { payload } & TypedAction<'[Modals] Clear Modal Component If Open'>,
        { identifier }
      ]) => {
        if (modalComponent?.identifier === action.payload.component?.identifier) {
          return [ModalsActions.ClearModalComponent()];
        }

        return [];
      })
    )
  );

  openCancelSigningModal$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ModalsActions.OpenCancelSigningModal),
      concatMap((action) =>
        of(action).pipe(
          withLatestFrom(
            this.store.pipe(select(PackageUsersSelectors.getIsActivePackageUserSigningAgent)),
            this.store.pipe(select(DeviceGroupSelectors.hasAuthenticatedNonSAUsers))
          )
        )
      ),
      switchMap(([_modalSize, isUserSigningAgent, hasUsers]) => {
        let isNonSAAllowedToCancel: boolean;
        if (hasUsers || sessionStorage.getItem('hasUsers') !== null) {
          isNonSAAllowedToCancel = true;
          // Need to find a better way of doing this, like fetching list of users in device group from BE instead of this
          sessionStorage.setItem('hasUsers', JSON.stringify(true));
        }
        if (!isUserSigningAgent && !isNonSAAllowedToCancel) {
          return [
            ModalsActions.SetStandaloneModalComponent({
              payload: {
                component: OptOutModalComponent,
              },
            }),
          ];
        }
        return [
          ModalsActions.SetStandaloneModalComponent({
            payload: {
              component: CancelSigningModalComponent,
              componentData: {
                fixedCategoryTypeCode: 'CommentsOnly'
              }
            },
          }),
        ];
      })
    )
  );

  cancelSigning$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ModalsActions.SigningCancelled),
      concatLatestFrom(() => this.store.select(ModalsSelectors.selectShouldStopArchiveOnOrderCancellation)),
      switchMap(([{ comments, categoryTypeCode }, selectShouldStopArchiveOnOrderCancellation]) => {
        const defaultActions = [
          VideoActions.StopVideo(),
          PackagesActions.OptOutPackage({ payload: { comments, categoryTypeCode } }),
          ModalsActions.ClearModalComponent(),
        ];

        const actions = selectShouldStopArchiveOnOrderCancellation
          ? [
              VideoActions.SetDisableAdditionalArchive(),
              VideoActions.StopArchive(),
              ...defaultActions,
            ]
          : defaultActions;

        return of(...actions);
      })
    );
  });

  constructor(
    private readonly actions$: Actions,
    private readonly store: Store<RootStoreState.State>
  ) {}
}
