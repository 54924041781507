import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';

import { of } from 'rxjs';
import { catchError, concatMap, filter, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';

import { NotificationsActions, NotificationType } from 'src/app/features/notifications';
import { ExceptionType } from 'src/app/features/notifications/models';
import {
  DEFAULT_NOTIFICATION_OPTIONS,
  HARDSTOP_SIGNALR_EXCEPTION_USER_MESSAGE,
  SIGNALR_EXCEPTION_HARDSTOP_NOTIFICATION_OPTIONS,
  SIGNALR_RECONNECTED_NOTIFICATION_OPTIONS,
  SIGNALR_RECONNECTED_USER_MESSAGE,
  SIGNALR_RECONNECTING_NOTIFICATION_OPTIONS,
  SIGNALR_RECONNECTING_USER_MESSAGE,
} from 'src/app/features/notifications/notifications.constants';
import { SigningRoomSelectors } from 'src/app/features/signing-room/store/selectors';
import { WizardSelectors } from 'src/app/features/wizard/store';
import { RootStoreState } from 'src/app/store';
import { v4 as uuid } from 'uuid';
import { PackageUsersActions, PackageUsersSelectors } from '../../../package-users';

import { SignalRService } from '../../services';
import { SignalRActions } from '../actions';
import { SignalRSelectors } from '../selectors';

@Injectable()
export class SignalREffects {
  connectToSigningRoom$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SignalRActions.AttemptSignalRHubConnection),
        map((action) => {
          this.signalRService.initializeHubConnection(action.payload.packageUserGuid);
        })
      ),
    { dispatch: false }
  );

  startSignalRHubConnection$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SignalRActions.StartSignalRHubConnection),
      switchMap((action) => {
        return this.signalRService.startSignalRHubConnection().pipe(
          map(() =>
            SignalRActions.SignalRHubConnectionSuccessful({
              payload: { packageUserGuid: action.payload.packageUserGuid },
            })
          ),
          catchError((error) =>
            of(
              NotificationsActions.AddNotification({
                payload: {
                  notificationType: NotificationType.Error,
                  id: uuid(),
                  text: `Failed to start SignalR Hub connection: ${error.message}`,
                  exceptionType: ExceptionType.Other,
                  logInAppInsights: true,
                },
              })
            )
          )
        );
      })
    )
  );

  connectionClosed$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SignalRActions.SignalRConnectionClosed),
      concatMap((action) =>
        of(action).pipe(
          withLatestFrom(this.store.pipe(select(SigningRoomSelectors.signingRoomSessionActive)))
        )
      ),
      filter(([action, isInSession]) => isInSession === true),
      switchMap(([action, isInSession]) => {
        return [
          NotificationsActions.AddNotification({
            payload: {
              notificationType: NotificationType.Error,
              id: uuid(),
              text: HARDSTOP_SIGNALR_EXCEPTION_USER_MESSAGE,
              exceptionType: ExceptionType.None,
              options: {
                ...DEFAULT_NOTIFICATION_OPTIONS,
                ...SIGNALR_EXCEPTION_HARDSTOP_NOTIFICATION_OPTIONS,
              },
            },
          }),
        ];
      })
    )
  );

  signalRConnectionReconnected$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SignalRActions.SignalRConnectionReconnected),
      withLatestFrom(this.store.select(SigningRoomSelectors.signingRoomSessionActive)),
      filter(([_, isInSigningRoom]) => isInSigningRoom !== undefined && isInSigningRoom !== null),
      map(([_, isInSigningRoom]) =>
        isInSigningRoom
          ? NotificationsActions.AddNotification({
              payload: {
                notificationType: NotificationType.Success,
                id: uuid(),
                text: SIGNALR_RECONNECTED_USER_MESSAGE,
                exceptionType: ExceptionType.DisplayMessage,
                options: {
                  ...DEFAULT_NOTIFICATION_OPTIONS,
                  ...SIGNALR_RECONNECTED_NOTIFICATION_OPTIONS,
                },
              },
            })
          : PackageUsersActions.FetchPackageUserCheckInStatuses()
      )
    )
  );

  attemptRoomConnectionAfterReconnected$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SignalRActions.SignalRConnectionReconnected),
      withLatestFrom(this.store.select(SigningRoomSelectors.signingRoomSessionActive)),
      filter(([_, isInSigningRoom]) => isInSigningRoom !== undefined && isInSigningRoom !== null),
      map(([action, _]) => {
        return SignalRActions.AttemptSignalRRoomConnection({
          payload: { packageUserGuid: action.payload.packageUserGuid },
        });
      })
    )
  );

  connectionReconnecting$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SignalRActions.SignalRConnectionReconnecting),
      concatMap((action) =>
        of(action).pipe(
          withLatestFrom(this.store.pipe(select(SigningRoomSelectors.signingRoomSessionActive)))
        )
      ),
      filter(([action, isInSession]) => isInSession === true),
      switchMap(([action, isInSession]) => {
        return [
          NotificationsActions.AddNotification({
            payload: {
              notificationType: NotificationType.Error,
              id: uuid(),
              text: SIGNALR_RECONNECTING_USER_MESSAGE,
              exceptionType: ExceptionType.None,
              options: {
                ...DEFAULT_NOTIFICATION_OPTIONS,
                ...SIGNALR_RECONNECTING_NOTIFICATION_OPTIONS,
              },
            },
          }),
        ];
      })
    )
  );

  joinSignalRGroup$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        SignalRActions.AttemptSignalRRoomConnection,
        SignalRActions.SignalRHubConnectionSuccessful
      ),
      switchMap((action) => {
        return this.signalRService.joinRoom(action.payload.packageUserGuid).pipe(
          switchMap((payload) => {
            return of(SignalRActions.SignalRRoomJoined());
          }),
          catchError((err) =>
            of(
              NotificationsActions.AddNotification({
                payload: {
                  exception: err,
                  notificationType: NotificationType.Error,
                  id: uuid(),
                  text: 'Failed to Join SignalR Group/Room: ' + err.message,
                  logInAppInsights: true,
                },
              })
            )
          )
        );
      })
    )
  );

  signalRConnectionStopped$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SignalRActions.StopSignalRConnection),
        concatMap((action) =>
          of(action).pipe(
            withLatestFrom(this.store.select(SignalRSelectors.selectIsSignalRGroupJoined))
          )
        ),
        filter(([_, isConnected]) => isConnected === true),
        tap(() => this.signalRService.stopConnection())
      ),
    { dispatch: false }
  );

  leaveSignalRGroup$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SignalRActions.LeaveSignalRRoom),
      switchMap((action) => {
        return this.signalRService.leaveRoom(action.payload.packageUserGuid).pipe(
          switchMap((payload) => {
            return of(SignalRActions.SignalRRoomLeft());
          }),
          catchError((err) =>
            of(
              NotificationsActions.AddNotification({
                payload: {
                  exception: err,
                  notificationType: NotificationType.Error,
                  id: uuid(),
                  text: 'Failed to Leave Signing Room',
                },
              })
            )
          )
        );
      })
    )
  );

  validateSignalRConnectionForWizardUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SignalRActions.ValidateSignalRConnectionForWizardUser),
      concatLatestFrom(() => [
        this.store.select(SignalRSelectors.selectIsSignalRGroupJoined),
        this.store.select(WizardSelectors.getActiveWizardUserGuid),
      ]),
      filter(([_, isSessionJoined, __]) => !isSessionJoined),
      map(([_, __, activeUserGuid]) =>
        SignalRActions.AttemptSignalRHubConnection({
          payload: {
            packageUserGuid: activeUserGuid,
          },
        })
      )
    )
  );

  restartHubConnection$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SignalRActions.RestartHubConnection),
      concatLatestFrom(() => [
        this.store.select(WizardSelectors.getActiveWizardUserGuid),
        this.store.select(PackageUsersSelectors.getIsSigningAgent),
        this.store.select(PackageUsersSelectors.getSigningAgent),
      ]),
      filter(
        ([_, activeWizardUserGuid, isSigningAgent, __]) => !!activeWizardUserGuid || isSigningAgent
      ),
      map(([_, activeWizardUserGuid, isSigningAgent, signingAgent]) => {
        const packageUserGuid =
          isSigningAgent && !!signingAgent ? signingAgent.packageUserGuid : activeWizardUserGuid;

        return SignalRActions.StartSignalRHubConnection({ payload: { packageUserGuid } });
      })
    )
  );

  constructor(
    private readonly actions$: Actions,
    private readonly signalRService: SignalRService,
    private readonly store: Store<RootStoreState.State>
  ) {}
}
