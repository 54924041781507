import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { select, Store } from '@ngrx/store';

import { combineLatest, interval, Observable, Subject, timer } from 'rxjs';
import {
    distinctUntilChanged, filter, map, switchMap, take, takeUntil, takeWhile, tap
} from 'rxjs/operators';

import { CheckInActions, CheckInSelectors } from 'src/app/features/check-in/store';
import { DeviceGroupSelectors } from 'src/app/features/device-group';
import { EndorsementsActions, EndorsementsSelectors } from 'src/app/features/endorsements';
import { Feature } from 'src/app/features/feature-management/models';
import { FeatureManagementService } from 'src/app/features/feature-management/services';
import { MsalAuthSelectors } from 'src/app/features/msal-auth';
import {
    PackageUser, PackageUserRole, PackageUsersSelectors
} from 'src/app/features/package-users';
import { PackagesActions, PackagesSelectors } from 'src/app/features/packages';
import { LongPollingConfig } from 'src/app/features/shared/configs';
import { WizardActions } from 'src/app/features/wizard';
import { RootStoreState } from 'src/app/store';

import { LobbyActions, LobbySelectors } from '../../store';

@Component({
  selector: 'app-lobby',
  templateUrl: './lobby.component.html',
  styleUrls: ['./lobby.component.scss'],
})
export class LobbyComponent implements OnInit, OnDestroy {
  @Input() pollingIntervalInSeconds: number = null;
  @Output() allUsersCheckedIn: EventEmitter<void> = new EventEmitter();
  @Output() userRejoining: EventEmitter<void> = new EventEmitter();
  @Output() userCheckInStarted: EventEmitter<void> = new EventEmitter();

  signingAgent$: Observable<PackageUser>;
  nonSigningAgentsExceptWitnesses$: Observable<Array<PackageUser>>;
  witnesses$: Observable<Array<PackageUser>>;

  protected isNeaSystemFieldsEnabled: boolean;

  private readonly onDestroyNotifier$ = new Subject();

  constructor(
    private readonly featureManagementService: FeatureManagementService,
    private readonly store: Store<RootStoreState.State>
  ) {}

  ngOnInit(): void {
    this.isNeaSystemFieldsEnabled = this.featureManagementService.getIsFeatureEnabledWithCaching(Feature.NeaSystemFields);

    this.signingAgent$ = this.store.select(LobbySelectors.getSigningAgent);
    this.nonSigningAgentsExceptWitnesses$ = this.store
      .select(LobbySelectors.getNonSigningAgents)
      .pipe(map((nsas) => nsas?.filter((nsa) => nsa.userRoleCode !== PackageUserRole.WITNESS)));
    this.witnesses$ = this.store
      .select(LobbySelectors.getNonSigningAgents)
      .pipe(map((nsas) => nsas?.filter((nsa) => nsa.userRoleCode === PackageUserRole.WITNESS)));

    this.startLobbyDetailsPolling();
    this.store.dispatch(LobbyActions.ClearCurrentParticipantCheckIn());

    this.store.dispatch(CheckInActions.WaitingRoomViewed());
    interval(LongPollingConfig['10Seconds'].interval)
      .pipe(
        takeUntil(this.onDestroyNotifier$),
        tap(() => this.store.dispatch(CheckInActions.FetchWaitingRoomViewedByParticipants()))
      )
      .subscribe();
    this.redirectToSigning().subscribe();

    this.store.dispatch(WizardActions.ClearActiveWizardUser());
    if (!this.isNeaSystemFieldsEnabled) {
      this.pollForSystemEndorsementStatusChangesOld().subscribe();
    } else {
      this.pollForSystemEndorsementStatusChanges().subscribe();
    }

    this.redirectOnPackageStatusChange().subscribe();
    this.startCheckInOnUserSelection().subscribe();
  }

  ngOnDestroy(): void {
    this.onDestroyNotifier$.next(undefined);
    this.onDestroyNotifier$.complete();
  }

  redirectToSigning(): Observable<[boolean, PackageUser[], string[]]> {
    return combineLatest([
      this.store.select(LobbySelectors.isCheckinCompleted),
      this.store.select(PackageUsersSelectors.getPackageUsers),
      this.store.select(CheckInSelectors.getUsersViewedWaitingRoom),
    ]).pipe(
      takeUntil(this.onDestroyNotifier$),
      filter(
        ([isCheckInCompleted, users, usersViewedWaitingRoom]) =>
          isCheckInCompleted && users.length === usersViewedWaitingRoom.length
      ),
      take(1),
      tap(() => this.checkInCompleteRedirect())
    );
  }

  startCheckInOnUserSelection(): Observable<PackageUser> {
    return this.store.select(LobbySelectors.getCurrentPackageUserLobby).pipe(
      takeUntil(this.onDestroyNotifier$),
      filter((packageUserLobby) => !!packageUserLobby),
      take(1),
      tap((participant) => this.startUserCheckIn(participant))
    );
  }

  redirectOnPackageStatusChange(): Observable<boolean> {
    return this.store.select(PackagesSelectors.packageCompleteOrCancelled).pipe(
      takeUntil(this.onDestroyNotifier$),
      filter((completeOrCancelled) => completeOrCancelled),
      tap(() => this.store.dispatch(PackagesActions.RedirectInvalidPackageScheduleStatus()))
    );
  }

  //TODO: USER STORY 61107: NEA System Field Code Clean-up
  pollForSystemEndorsementStatusChangesOld(): Observable<[boolean, boolean, string, PackageUser]> {
    return timer(0, LongPollingConfig['10Seconds'].interval).pipe(
      switchMap(() =>
        combineLatest([
          this.store.select(EndorsementsSelectors.areAllSystemEndorsementsApplied),
          this.store.select(EndorsementsSelectors.didSystemFieldsFailToApply),
          this.store.select(PackageUsersSelectors.getActivePackageUserGuid),
          this.signingAgent$.pipe(
            distinctUntilChanged(
              (prev, curr) => prev?.checkInStatus?.isCompleted === curr?.checkInStatus?.isCompleted
            )
          ),
        ])
      ),
      takeUntil(this.onDestroyNotifier$),
      takeWhile(
        ([areAllSystemEndorsementsApplied, didSystemFieldsFailToApply, _, __]) =>
          !areAllSystemEndorsementsApplied && !didSystemFieldsFailToApply
      ),
      filter(
        ([_, __, activePackageUserGuid, signingAgent]) =>
          !!activePackageUserGuid && signingAgent?.checkInStatus?.isCompleted
      ),
      tap(() => this.store.dispatch(EndorsementsActions.FetchSystemFieldStatus()))
    );
  }

  pollForSystemEndorsementStatusChanges(): Observable<[boolean, string, PackageUser]> {
    return timer(0, LongPollingConfig['10Seconds'].interval).pipe(
      switchMap(() =>
        combineLatest([
          this.store.select(EndorsementsSelectors.areAllSystemEndorsementsApplied),
          this.store.select(PackageUsersSelectors.getActivePackageUserGuid),
          this.signingAgent$.pipe(
            distinctUntilChanged(
              (prev, curr) => prev?.checkInStatus?.isCompleted === curr?.checkInStatus?.isCompleted
            )
          ),
        ])
      ),
      takeUntil(this.onDestroyNotifier$),
      takeWhile(
        ([areAllSystemEndorsementsApplied]) =>
          !areAllSystemEndorsementsApplied
      ),
      filter(
        ([_, activePackageUserGuid, signingAgent]) =>
          !!activePackageUserGuid && signingAgent?.checkInStatus?.isCompleted
      ),
      tap(() => this.store.dispatch(EndorsementsActions.FetchSystemFieldsStatus()))
    );
  }

  startLobbyDetailsPolling(): void {
    this.store.dispatch(LobbyActions.FetchLobbyDetails());

    if (!!this.pollingIntervalInSeconds && this.pollingIntervalInSeconds >= 0) {
      interval(this.pollingIntervalInSeconds * 1000)
        .pipe(
          takeUntil(this.onDestroyNotifier$),
          tap(() => this.store.dispatch(LobbyActions.FetchLobbyDetails()))
        )
        .subscribe();
    }
  }

  startUserCheckIn(participant: PackageUser): void {
    this.store.dispatch(
      WizardActions.SetActiveWizardUser({
        payload: {
          user: {
            firstName: participant.firstName,
            lastName: participant.lastName,
            packageUserGuid: participant.packageUserGuid,
            userRoleCode: participant.userRoleCode,
            packageId: 0,
            packageUserId: 0,
            signingAgentInfoId: 0,
            isMissingVerificationType: participant.isMissingVerificationType,
          },
        },
      })
    );
    this.userCheckInStarted?.emit();
  }

  checkInCompleteRedirect(): void {
    combineLatest([
      this.store.pipe(select(EndorsementsSelectors.areAllSystemEndorsementsApplied)),
      this.store.select(MsalAuthSelectors.getIsUserVerified),
      this.store.select(DeviceGroupSelectors.getIsParticipantRejoining),
    ])
      .pipe(
        filter(([areAllSystemEndorsementsApplied, isUserVerified, isParticipantRejoining]) => {
          return areAllSystemEndorsementsApplied && (isUserVerified || isParticipantRejoining);
        }),
        take(1),
        tap(([_, isUserVerified, isParticipantRejoining]) => {
          if (isUserVerified) {
            this.allUsersCheckedIn?.emit();
          } else if (isParticipantRejoining) {
            this.userRejoining?.emit();
          }
        })
      )
      .subscribe();
  }
}
