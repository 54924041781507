<div class="form-step__subheading">
  Check your {{ selectedMultiFactorChallengeDevice$ | async }} for your security code. After you
  submit your code you will continue the check-in process.
</div>
<form class="typeform">
  <label>
    <div>Security Code</div>
    <input
      [formControl]="securityCode"
      class="form-control"
      maxlength="6"
      inputmode="numeric"
      placeholder="Security code"
      data-testid="enter-security-code"
      [ngClass]="{ error: (attemptsRemaining$ | async) !== null }"
    />
  </label>
  <div class="error-valid-wrapper">
    <p class="error-hint" *ngIf="(attemptsRemaining$ | async) !== null">
      Invalid Security Code. {{ attemptsRemaining$ | async }} attempts remaining
    </p>
    <p
      class="error-hint"
      *ngIf="
        userCodeValidationState !== codeValidationStates.hide &&
        userCodeValidationState === codeValidationStates.invalid
      "
    >
      Please wait {{ this.intervalTimer?.getRemainingSeconds() }} seconds before requesting another
      code.
    </p>
    <p
      class="valid-hint"
      *ngIf="
        userCodeValidationState !== codeValidationStates.hide &&
        userCodeValidationState === codeValidationStates.valid
      "
    >
      Code sent successfully.
    </p>
  </div>

  <div class="buttons-container">
    <app-button
      class="verify-button"
      [dataTestId]="'verify-security-code'"
      type="primary"
      (click)="completeMultiFactorChallenge()"
    >
      Verify
    </app-button>
    <app-button
      class="resend-button"
      [dataTestId]="'resend-security-code'"
      type="secondary"
      (click)="resendMultiFactorChallenge()"
    >
      Re-Send Code
    </app-button>
    <app-button
      class="change-button"
      [dataTestId]="'change-security-method'"
      type="tertiary"
      (click)="resetMultiFactorChallengeSelection()"
    >
      Change How You Receive Code
    </app-button>
  </div>
</form>
