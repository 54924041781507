import { Action, createReducer, on } from '@ngrx/store';

import { EndorsementsCreationActions } from '../actions';
import { EndorsementsCreationState } from '../state';

export const endorsementsCreationFeatureKey = 'endorsementscreation';

const reducer = createReducer(
  EndorsementsCreationState.initialState,
  on(
    EndorsementsCreationActions.FetchInitialsPreviews,
    (state: EndorsementsCreationState.State) => ({
      ...state,
      selectionsSaved: false,
    })
  ),
  on(
    EndorsementsCreationActions.SetInitialsPreviews,
    (state: EndorsementsCreationState.State, { payload }) => {
      let index = state.initialsSelection.activeInitials;
      if (payload.selectedEndorsement) {
        index = payload.endorsements.findIndex(
          (e) => e.fontName === payload.selectedEndorsement.fontName
        );
      }

      return {
        ...state,
        initialsSelection: {
          ...state.initialsSelection,
          activeInitials: index,
          previews: payload.endorsements,
          currentSelectionIsStatic: !!payload.selectedEndorsement,
        },
      };
    }
  ),
  on(
    EndorsementsCreationActions.SetActiveInitialsByIndex,
    (state: EndorsementsCreationState.State, { payload }) => ({
      ...state,
      initialsSelection: {
        ...state.initialsSelection,
        activeInitials: payload,
      },
    })
  ),
  on(
    EndorsementsCreationActions.FetchSignaturePreviews,
    (state: EndorsementsCreationState.State) => ({
      ...state,
      selectionsSaved: false,
    })
  ),
  on(
    EndorsementsCreationActions.SetSignaturePreviews,
    (state: EndorsementsCreationState.State, { payload }) => {
      let index = null;
      if (payload.selectedEndorsement) {
        index = payload.endorsements.findIndex(
          (e) => e.fontName === payload.selectedEndorsement.fontName
        );
      }

      return {
        ...state,
        signatureSelection: {
          ...state.signatureSelection,
          activeSignature: index,
          previews: payload.endorsements,
          currentSelectionIsStatic: !!payload.selectedEndorsement,
        },
      };
    }
  ),
  on(
    EndorsementsCreationActions.SetActiveSignatureByIndex,
    (state: EndorsementsCreationState.State, { payload }) => ({
      ...state,
      signatureSelection: {
        ...state.signatureSelection,
        activeSignature: payload,
      },
      initialsSelection: {
        ...state.initialsSelection,
        activeInitials: state.initialsSelection.currentSelectionIsStatic
          ? state.initialsSelection.activeInitials
          : payload,
      },
    })
  ),
  on(
    EndorsementsCreationActions.EndorsementSelectionsSaveSucccessful,
    (state: EndorsementsCreationState.State) => ({
      ...state,
      selectionsSaved: true,
    })
  ),
  on(
    EndorsementsCreationActions.UpdateDrawnSignature,
    (state: EndorsementsCreationState.State, { payload }) => ({
      ...state,
      drawnSignature: payload,
    })
  ),
  on(
    EndorsementsCreationActions.SetUploadedSignatureImageFromCommissionSignature,
    (state: EndorsementsCreationState.State, { payload }) => ({
      ...state,
      hasUploadedSignature: true,
      uploadedSignatureImageBase64: payload,
    })
  ),
  on(
    EndorsementsCreationActions.SetSignatureType,
    (state: EndorsementsCreationState.State, { payload }) => ({
      ...state,
      signatureType: payload,
    })
  ),
  on(
    EndorsementsCreationActions.SetIsSignatureCreatedInPresign,
    (state: EndorsementsCreationState.State, { payload }) => ({
      ...state,
      isSignatureCreatedInPresign: payload,
    })
  ),
  on(
    EndorsementsCreationActions.UpdateDrawnInitials,
    (state: EndorsementsCreationState.State, { payload }) => ({
      ...state,
      drawnInitials: payload,
    })
  ),
  on(
    EndorsementsCreationActions.CompleteEndorsementsCreation,
    () => EndorsementsCreationState.initialState
  )
);

export function EndorsementsCreationReducer(
  state: EndorsementsCreationState.State | undefined,
  action: Action
) {
  return reducer(state, action);
}
