import { Component, Inject, OnInit } from '@angular/core';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { select, Store } from '@ngrx/store';
import { map, Observable, withLatestFrom } from 'rxjs';
import { DeviceGroupSelectors } from 'src/app/features/device-group';
import { Feature } from 'src/app/features/feature-management/models';
import { FeatureManagementService } from 'src/app/features/feature-management/services';
import { PackageUserRole, PackageUsersSelectors } from 'src/app/features/package-users';
import { SigningRoomActions } from 'src/app/features/signing-room/store/actions';

@Component({
  selector: 'app-av-disruption-app-action-blocking-snackbar-notification',
  templateUrl: './av-disruption-action-blocking-snackbar.component.html',
  styleUrls: ['./av-disruption-action-blocking-snackbar.component.scss'],
})
export class AvDisruptionActionBlockingSnackbarComponent implements OnInit {
  title: string;
  body: string;
  isAddUserToSessionEnabled$: Observable<boolean>;
  disruptedPackageUserGuids: string[];

  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: any, private readonly store: Store, private readonly featureManagementService: FeatureManagementService) {
    this.title = data.title;
    this.body = data.body;
    this.disruptedPackageUserGuids = data.data.disruptedPackageUserGuids;
  }

  ngOnInit(): void {
    if (this.featureManagementService.getIsFeatureEnabledWithCaching(Feature.AddUserToSession)) {
      this.isAddUserToSessionEnabled$ = this.store.pipe(
        withLatestFrom(
          this.store.pipe(select(PackageUsersSelectors.getActivePackageUser)),
          this.store.pipe(select(DeviceGroupSelectors.getUsersOnDevice)),
          this.store.pipe(select(PackageUsersSelectors.getNonSigningAgents))),
        map(([_, activePackgeUser, usersOnDevice, nonSAs]) => {
          return this.disruptedPackageUserGuids.map((disruptedPackageUserGuid) => {
              return activePackgeUser.userRoleCode !== PackageUserRole.SIGNINGAGENT && 
                !usersOnDevice.some((user) => user.packageUserGuid === disruptedPackageUserGuid) && 
                nonSAs.some((user) => user.packageUserGuid === disruptedPackageUserGuid);
          }).some((result) => result); 
        })
      );
    }
  }

  addUserToThisSession() {
    this.store.dispatch(SigningRoomActions.OpenAddUserToSessionModal());
  }
}
