import { Component } from '@angular/core';

@Component({
  selector: 'app-pre-sign-layout',
  templateUrl: './pre-sign-layout.component.html',
  styleUrls: ['./pre-sign-layout.component.scss'],
})
export class PreSignLayoutComponent {
  constructor(
  ) {}
}
