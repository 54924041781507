<div
  [ngClass]="{
    middle: true,
    center: true,
    'middle--active': (requiredStampTotal$ | async) > 0
  }"
>
  <span class="middle__count" data-testid="stamp-count">{{ requiredStampTotal$ | async }}</span>
  <span data-testid="stamp-text"
    >{(requiredStampTotal$ | async), plural, =1 {Stamp} other
    {Stamps}}</span
  >
</div>
