import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, filter, map, switchMap } from 'rxjs/operators';

import {
  ExceptionType,
  NotificationsActions,
  NotificationType,
} from 'src/app/features/notifications';
import { PackageUsersActions } from 'src/app/features/package-users';
import { RootStoreState } from 'src/app/store';
import { v4 as uuid } from 'uuid';

import { WizardService } from '../../services';
import { WizardActions } from '../actions';
import { WizardSelectors } from '../selectors';

@Injectable()
export class WizardEffects {
  updateWizardUserDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PackageUsersActions.FetchPackageUsersSuccessful),
      concatLatestFrom(() => [
        this.store.pipe(select(WizardSelectors.hasActiveWizardUser)),
        this.store.pipe(select(WizardSelectors.getActiveWizardUser)),
      ]),
      filter(([_, hasActiveWizardUser, __]) => hasActiveWizardUser),
      map(([action, _, previousWizardUser]) => {
        const updatedWizardUser = action.payload.packageUsers?.find(
          (pu) =>
            pu.packageUserGuid?.toLowerCase() === previousWizardUser.packageUserGuid?.toLowerCase()
        );
        return [updatedWizardUser, previousWizardUser];
      }),
      filter(
        ([updatedWizardUser, previousWizardUser]) =>
          !!updatedWizardUser &&
          (updatedWizardUser.firstName !== previousWizardUser.firstName ||
            updatedWizardUser.lastName !== previousWizardUser.lastName ||
            updatedWizardUser.signatureName !== previousWizardUser.signatureName)
      ),
      map(([updatedWizardUser, _]) =>
        WizardActions.UpdateActiveWizardUserDetails({
          payload: {
            user: {
              firstName: updatedWizardUser.firstName,
              lastName: updatedWizardUser.lastName,
              signatureName: updatedWizardUser.signatureName,
            },
          },
        })
      )
    )
  );

  fetchCheckInTasks$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WizardActions.FetchCheckInTasks),
      concatLatestFrom(() => this.store.pipe(select(WizardSelectors.getActiveWizardUserGuid))),
      switchMap(([_, activeUserGuid]) => {
        return this.wizardService.getCheckInTasks(activeUserGuid).pipe(
          map((tasks) =>
            WizardActions.FetchTasksSuccessful({
              payload: tasks,
            })
          ),
          catchError((err) =>
            of(
              NotificationsActions.AddNotification({
                payload: {
                  exception: err,
                  notificationType: NotificationType.Error,
                  id: uuid(),
                  text: 'Failed to load check-in tasks for user',
                  exceptionType: ExceptionType.CannotProceed,
                },
              })
            )
          )
        );
      })
    )
  );

  fetchPresignCheckInTasks$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WizardActions.FetchPresignCheckInTasks),
      concatLatestFrom(() => this.store.pipe(select(WizardSelectors.getActiveWizardUserGuid))),
      switchMap(([_, activeUserGuid]) => {
        return this.wizardService.getPresignCheckInTasks(activeUserGuid).pipe(
          map((tasks) =>
            WizardActions.FetchTasksSuccessful({
              payload: tasks,
            })
          ),
          catchError((err) =>
            of(
              NotificationsActions.AddNotification({
                payload: {
                  exception: err,
                  notificationType: NotificationType.Error,
                  id: uuid(),
                  text: 'Failed to load presign check-in tasks for user',
                  exceptionType: ExceptionType.CannotProceed,
                },
              })
            )
          )
        );
      })
    )
  );

  constructor(
    private readonly actions$: Actions,
    private readonly store: Store<RootStoreState.State>,
    private readonly wizardService: WizardService
  ) {}
}
