import { Injectable } from '@angular/core';
import { ActiveToast, ToastrService } from 'ngx-toastr';

import { ToastModalComponent } from '../components';
import { ExceptionType, NotificationModel } from '../models';
import {
  DEFAULT_NOTIFICATION_OPTIONS,
  EXCEPTION_HARDSTOP_NOTIFICATION_OPTIONS,
  HARDSTOP_EXCEPTION_USER_MESSAGE,
} from '../notifications.constants';

@Injectable()
export class ToastService {
  constructor(private readonly toastr: ToastrService) {}

  showNotification(
    notification: NotificationModel
  ): ActiveToast<ToastModalComponent> {
    const defaultOptions = DEFAULT_NOTIFICATION_OPTIONS;
    defaultOptions.notificationId = notification.id;
    let toastOptions = {
      ...defaultOptions,
      ...notification.options,
    };

    // if this is a hard stop notification, we need to override some options
    if (
      notification.exceptionType === ExceptionType.CannotProceed ||
      notification.exceptionType === ExceptionType.ReloadRetry
    ) {
      // Dispatch action to show toast with user friendly message
      notification.text = HARDSTOP_EXCEPTION_USER_MESSAGE;

      const hardStopOptions = EXCEPTION_HARDSTOP_NOTIFICATION_OPTIONS;
      hardStopOptions.notificationId = notification.id;
      toastOptions = {
        ...hardStopOptions,
        ...notification.options,
      };
    }

    return this.toastr.show(
      notification.text,
      '',
      toastOptions,
      notification.notificationType.toLowerCase()
    );
  }

  removeNotification(toastId: number) {
    this.toastr.remove(toastId);
  }
}
