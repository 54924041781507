export class PendoLocationService {
  currentPath: string;

  private static _instance: PendoLocationService;
  static get instance(): PendoLocationService {
    if (!PendoLocationService._instance) {
      PendoLocationService._instance = new PendoLocationService();
    }
    return PendoLocationService._instance;
  }

  identifyPage(pageId: string) {
    this.currentPath = pageId;
  }

  identifySubPage(subPageId: string) {
    this.identifyPage(`${this.getLocationPath()}/${subPageId}`);
  }

  // This function will be called by Pendo every 0.5s so it needs to be performant
  getPageUrl(): string {
    const pageIdentifier = !!this.currentPath ? this.currentPath : this.getLocationPath();
    return `${location.protocol}//${location.host}${pageIdentifier}`;
  }

  getLocationPath(): string {
    return location.hash.split('?')[0].replace('#', '');
  }
}
