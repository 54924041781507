import { Injectable } from '@angular/core';
import { IDeviceValidatorService } from './IDeviceValidatorService';

@Injectable({
  providedIn: 'root'
})
export class RonDeviceValidatorService implements IDeviceValidatorService {
  constructor() { }

  isBrowserSupported(userAgent: string): boolean {
    // https://developer.chrome.com/multidevice/user-agent
    // Chrome UA for iPhone and iPad is the same as the Mobile Safari user agent, with CriOS/<ChromeRevision> instead of "Chrome".
    return !!userAgent &&
      (userAgent.includes("Chrome") ||
        userAgent.includes("CriOS") ||
        userAgent.includes("Safari"));
  }
}
