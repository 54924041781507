import { Component, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Subject, Subscription } from 'rxjs';
import { filter, takeUntil, tap } from 'rxjs/operators';
import { EndorsementsSelectors } from 'src/app/features/endorsements';
import { EndorsementRemainingModalComponent } from 'src/app/features/signing-room/components/endorsement-remaining-modal/endorsement-remaining-modal.component';

import { RootStoreState } from 'src/app/store';
import { PackageDocument } from '../../models';

import { DocumentsActions, DocumentsSelectors } from '../../store';

@Component({
  selector: 'app-document-scoreboard',
  templateUrl: './document-scoreboard.component.html',
  styleUrls: ['./document-scoreboard.component.scss'],
})
export class DocumentScoreboardComponent implements OnInit, OnDestroy {
  isNextButtonEnabled: boolean;
  isPreviousButtonEnabled: boolean;
  isEndorsementRemaining: boolean;

  notifier = new Subject();
  continueSubscription: Subscription;

  constructor(private readonly store: Store<RootStoreState.State>) {}

  ngOnInit(): void {
    this.store
      .pipe(
        takeUntil(this.notifier),
        select(DocumentsSelectors.selectActivePackageDocument),
        filter<PackageDocument>(Boolean),
        tap((document) => this.determineButtonValidity(document))
      )
      .subscribe();

    this.store
      .pipe(
        takeUntil(this.notifier),
        select(EndorsementsSelectors.selectActiveDocumentRemainingRequiredActions),
        tap((count) => (this.isEndorsementRemaining = count !== 0))
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.notifier.next(undefined);
    this.notifier.complete();
    this.continueSubscription?.unsubscribe();
  }

  determineButtonValidity(document: PackageDocument) {
    this.isNextButtonEnabled = !!document.nextDocumentId;
    this.isPreviousButtonEnabled = !!document.previousDocumentId;
  }

  nextClick(): void {
    if (this.isEndorsementRemaining) {
      this.openEndorsementRemainingModal(DocumentsActions.NavigateToNextDocument);
      return;
    }

    this.store.dispatch(DocumentsActions.NavigateToNextDocument());
  }

  onPreviousClick(): void {
    if (this.isEndorsementRemaining) {
      this.openEndorsementRemainingModal(DocumentsActions.NavigateToPreviousDocument);
      return;
    }
    this.store.dispatch(DocumentsActions.NavigateToPreviousDocument());
  }

  openEndorsementRemainingModal(action) {
    EndorsementRemainingModalComponent.openModal(this.store, () => {
      this.store.dispatch(action());
    });
  }
}
