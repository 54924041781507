<div class="generic-confirm-modal">
  <div class="generic-confirm-modal__header">
    <h3 class="modal-title">Applying your endorsement...</h3>
  </div>
  <div class="generic-confirm-modal__body">
    <div>
      <p>Your endorsement is being applied to the document.</p>
      <p>This may take a moment.</p>
    </div>
  </div>
</div>
