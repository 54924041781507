import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { select, Store } from '@ngrx/store';

import { Observable } from 'rxjs';

import { ClientSelectors } from 'src/app/features/client';
import { ClientTheme } from 'src/app/features/client/models';
import { RootStoreState } from 'src/app/store';

@Component({
  selector: 'app-branding',
  templateUrl: './branding.component.html',
  styleUrls: ['./branding.component.scss'],
})
export class BrandingComponent implements OnInit {
  @Input() showDropdown: boolean;
  @Output() showDropdownChange = new EventEmitter<boolean>();

  clientTheme$: Observable<ClientTheme>;

  public get dropDownIcon() {
    return this.showDropdown ? 'expand-less' : 'expand-more';
  }

  constructor(private readonly store: Store<RootStoreState.State>) {}

  ngOnInit(): void {
    this.clientTheme$ = this.store.pipe(select(ClientSelectors.getClientTheme));
  }

  toggleDropdown() {
    this.showDropdown = !this.showDropdown;
    this.showDropdownChange.emit(this.showDropdown);
  }
}
