<ng-container>
  <ng-container *ngFor="let endorsement of activeEndorsementIds$ | async">
    <app-endorsement
      [endorsementId]="endorsement"
      [hideIfSigned]="shouldHideEndorsement(endorsement)"
      [documentScale]="activePackageDocument?.isSmartDoc ? 1 : documentScale"
      [isEndorsementDisabled]="shouldDisableEndorsement(endorsement)"
      [areAllEndorsementsSigned]="areAllEndorsementsSigned"
    ></app-endorsement>
    <app-endorsement-flag
      [endorsementId]="endorsement"
      [documentScale]="activePackageDocument?.isSmartDoc ? 1 : documentScale"
    ></app-endorsement-flag>
  </ng-container>
</ng-container>
