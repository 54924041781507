import {
  createReducer,
  on
} from '@ngrx/store';

import { MultiFactorActions } from '../actions';
import { MultiFactorState } from '../state';

export const multiFactorReducers = createReducer(
  MultiFactorState.initialState,
  on(
    MultiFactorActions.CancelMultiFactorChallenge, (state: MultiFactorState.State) => ({
      ...state,
      activeMultiFactorAttemptPackageUserGuid: null,
      challengeCreated: false,
      attemptsRemaining: null,
      error: null,
      errorMessage: null,
      completeMultiFactorChallengeLoading: false,
    })
  ),
  on(
    MultiFactorActions.RestartMultiFactorChallenge,
    (state: MultiFactorState.State, { payload }) => ({
      ...state,
      challengeCreated: false,
      errorMessage: payload.errorMessage,
      completeMultiFactorChallengeLoading: false,
    })
  ),
  on(
    MultiFactorActions.MultiFactorAttemptSuccess, (state: MultiFactorState.State) => ({
      ...state,
      currentlySelectedChallengeType: null,
      activeMultiFactorAttemptPackageUserGuid: null,
      challengeCreated: false,
      attemptsRemaining: null,
      error: null,
      completeMultiFactorChallengeLoading: false,
    })
  ),
  on(
    MultiFactorActions.CompleteMultiFactorChallenge, (state: MultiFactorState.State) => ({
      ...state,
      completeMultiFactorChallengeLoading: true,
    })
  ),
  on(
    MultiFactorActions.CreateMultiFactorChallenge, (state: MultiFactorState.State) => ({
      ...state,
      attemptsRemaining: null,
      errorMessage: null,
    })
  ),
  on(
    MultiFactorActions.MultiFactorChallengeCreated,
    (state: MultiFactorState.State, { payload }) => ({
      ...state,
      challengeCreated: true,
      activeMultiFactorAttemptPackageUserGuid: payload.packageUserGuid,
      challengeCreatedAt: new Date(),
    })
  ),
  on(
    MultiFactorActions.MultiFactorChallengeSelected,
    (state: MultiFactorState.State, { payload }) => ({
      ...state,
      currentlySelectedChallengeType: payload.selectedChallengeType,
    })
  ),
  on(
    MultiFactorActions.CreateMultiFactorChallengeError,
    (state: MultiFactorState.State, action) => ({
      ...state,
      error: action.payload,
      completeMultiFactorChallengeLoading: false,
    })
  ),
  on(
    MultiFactorActions.CompleteMultiFactorChallengeError,
    (state: MultiFactorState.State, action) => ({
      ...state,
      error: action.payload,
      completeMultiFactorChallengeLoading: false,
    })
  ),
  on(
    MultiFactorActions.MultiFactorAttemptInvalidPin,
    (state: MultiFactorState.State, { payload }) => ({
      ...state,
      challengeCreated: true,
      attemptsRemaining: payload.attemptsRemaining,
      completeMultiFactorChallengeLoading: false,
    })
  ),
  on(
    MultiFactorActions.MultiFactorChallengeFailed, (state: MultiFactorState.State) => ({
      ...state,
      completeMultiFactorChallengeLoading: false,
    })
  ),
  on(
    MultiFactorActions.ResendMultiFactorChallengeCode, (state: MultiFactorState.State) => ({
      ...state,
    })
  ),
  on(
    MultiFactorActions.CreateMultiFactorChallengeError, (state: MultiFactorState.State) => ({
      ...state,
      completeMultiFactorChallengeLoading: false,
    })
  ),
);
