<div class="guide">
  <div class="guide__content">
    <app-guide-icon
      alt="guide icon"
      role="img"
      title="Icon for guided instructions to enable access"
    />
    <section>
      <header>
        <span>Guide</span>
      </header>

      <ng-content></ng-content>
    </section>
  </div>
  <button *ngIf="showButton" (click)="toggleDetails()">
    @if (showDetails) { Show Less <mat-icon>keyboard_arrow_up </mat-icon> } @else { How to fix
    <mat-icon>expand_more </mat-icon>}
  </button>
</div>
