import { createAction, props } from '@ngrx/store';
import { Client } from '../../models/client.model';

export const SetClient = createAction('[Client] Set Client');
export const ClearClientInformation = createAction(
  '[Package] Clear Client Information'
);

export const LoadClientForUser = createAction(
  '[Package] Load Client for Package User'
);

export const GetShouldBypassRealId = createAction(
  '[Package] Get whether to Bypass Real Id'
);

export const SetShouldBypassRealId = createAction(
  '[Package] Set whether to Bypass Real Id',
  props<{ payload: { shouldBypassRealId: boolean } }>()
);

export const LoadClientForPackage = createAction(
  '[Package] Load Client for Package',
  props<{ payload: { packageGuid: string } }>()
);

export const SetClientForPackage = createAction(
  '[Package] Set Client for Package',
  props<{ payload: { client: Client } }>()
);
