import { Component } from '@angular/core';
import { DEFAULT_APPLICATION_PHONE_NUMBER } from 'src/app/features/shared/constants';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-nav-bar-session-ending',
  templateUrl: './nav-bar-session-ending.component.html',
  styleUrls: ['../nav-bar/nav-bar.component.scss'],
})
export class NavBarSessionEndingComponent {
  faqUrl = environment.clearsignFaqUrl;
  clearSignSupportNumber = DEFAULT_APPLICATION_PHONE_NUMBER;
}
