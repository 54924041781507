/**
 * Fix for Chrome IOS version 92 or so (2021)
 * Chrome IOS would zoom in when the user changes the orientation of the device. This function
 * should be run when the application starts in order to disable this behaviour. It does not
 * seem to interfere with the user wanting to zoom in manually. 
 */
export default (windowObj) => {
	// Only run on Chrome IOS
	const nav = windowObj.navigator;
	const ua = nav.userAgent;
	if (!( /iPhone|iPad|iPod/.test(nav.platform ) && /like Mac OS X/i.test(ua) && ua.indexOf( "AppleWebKit" ) > -1 )) {
		return;
	}

	const meta = document.querySelector( "meta[name=viewport]" );
  const initialContent = meta && meta.getAttribute( "content" );

	meta.setAttribute("content", initialContent + ",maximum-scale=1");
}