import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';

import { of } from 'rxjs';
import { catchError, concatMap, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { v4 as uuid } from 'uuid';

import { NotificationsActions, NotificationType } from 'src/app/features/notifications';
import { PackageUsersSelectors } from 'src/app/features/package-users';

import { UserTaskService } from '../../services';
import { UserTasksActions } from '../actions';
import { ConsumerPortalActions } from 'src/app/features/consumer-portal/store';

@Injectable()
export class UserTasksEffects {
  getActiveUserTasks$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserTasksActions.FetchActiveUserTasks, ConsumerPortalActions.SigningDashboardOpened),
      concatMap((action) =>
        of(action).pipe(
          withLatestFrom(this.store.select(PackageUsersSelectors.getActivePackageUserGuid))
        )
      ),
      switchMap(([_, packageUserGuid]) => {
        return this.userTasksService.getActiveUserTasks(packageUserGuid).pipe(
          map((payload) => UserTasksActions.SetActiveUserTasks({ payload })),
          catchError((err) =>
            of(
              NotificationsActions.AddNotification({
                payload: {
                  exception: err,
                  notificationType: NotificationType.Error,
                  id: uuid(),
                  text: 'Failed to get User Tasks',
                },
              })
            )
          )
        );
      })
    );
  });

  constructor(
    private readonly actions$: Actions,
    private readonly store: Store,
    private readonly userTasksService: UserTaskService
  ) {}
}
