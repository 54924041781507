import { Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { CheckInTask, WizardSelectors } from 'src/app/features/wizard';

import { RootStoreState } from 'src/app/store';

@Component({
  selector: 'app-step-tracker',
  templateUrl: './step-tracker.component.html',
  styleUrls: ['./step-tracker.component.scss'],
})
export class StepTrackerComponent implements OnInit {
  steps$: Observable<CheckInTask[]>;
  activeStepIndex$: Observable<number>;

  constructor(private readonly store: Store<RootStoreState.State>) {}

  ngOnInit(): void {
    this.steps$ = this.store.pipe(select(WizardSelectors.getTasks));
    this.activeStepIndex$ = this.store.pipe(select(WizardSelectors.getCurrentTaskIndex));
  }
}
