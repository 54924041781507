<div class="ron-duplicate-session-error-modal">
    <div class="ron-duplicate-session-error-modal__header">
      <div class="sub-header">Clear Sign is already open</div>
    </div>
    <div class="ron-duplicate-session-error-modal__body">
      <p>
        You currently have Clear Sign open in another tab.
        Close this page and return to the other tab with Clear Sign to continue.
      </p>
      <div>
        <p>
          Need help? Call us: <strong><a href="tel:{{phoneNumber}}">{{ phoneNumber}}</a></strong>
        </p>
      </div>
    </div>
  </div>