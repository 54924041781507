import { Injectable } from '@angular/core';
import { CanvasValidationService } from './CanvasValidationService';

@Injectable()
export class InitialsValidationService extends CanvasValidationService {
  defaultGutterWidth = 1;
  constructor() {
    super();
  }

  isDrawingContained(
    canvas: HTMLCanvasElement,
    gutterPixelWidth = this.defaultGutterWidth
  ): boolean {
    const areaHasPixelData = (imageData: ImageData) => {
      return imageData.data.some((color) => color !== 0);
    };

    const context = canvas.getContext('2d');
    const isTouchingAnEdge =
      areaHasPixelData(
        context.getImageData(0, 0, canvas.width, gutterPixelWidth)
      ) ||
      areaHasPixelData(
        context.getImageData(canvas.width, 0, -gutterPixelWidth, canvas.height)
      ) ||
      areaHasPixelData(
        context.getImageData(0, canvas.height, canvas.width, -gutterPixelWidth)
      ) ||
      areaHasPixelData(
        context.getImageData(0, 0, gutterPixelWidth, canvas.height)
      );

    return !isTouchingAnEdge;
  }

  isDrawingComplex(
    canvas: HTMLCanvasElement,
    gutterPixelWidth = this.defaultGutterWidth
  ): boolean {
    let alphaPixels = 0;
    const canvasWidth = canvas.width - gutterPixelWidth * 2;
    const canvasHeight = canvas.height - gutterPixelWidth * 2;
    const data = canvas
      .getContext('2d')
      .getImageData(
        gutterPixelWidth,
        gutterPixelWidth,
        canvasWidth,
        canvasHeight
      ).data;
    for (let i = 3; i < data.length; i += 4) {
      if (data[i] > 0) {
        alphaPixels++;
      }
    }
    const ratio = parseFloat(
      (alphaPixels / (canvasWidth * canvasHeight)).toFixed(3)
    );
    return ratio > 0.005;
  }
}
