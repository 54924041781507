import { Action, createReducer, on } from '@ngrx/store';

import { AudioVideoConnectionMonitorState } from '../state';
import { AudioVideoConnectionMonitorActions } from '../actions';
import { AudioVideoCheckActions } from 'src/app/features/av-check/store';

const reducer = createReducer(
  AudioVideoConnectionMonitorState.initialState,
  on(
    AudioVideoConnectionMonitorActions.SetSelectedAudioVideoDevices,
    (state: AudioVideoConnectionMonitorState.State, { payload }) => ({
      ...state,
      selectedAudioDevice: payload.selectedAudioDevice,
      selectedVideoDevice: payload.selectedVideoDevice,
      selectedAudioOutputDevice: payload?.selectedAudioOutputDevice,
    })
  ),
  on(
    AudioVideoConnectionMonitorActions.SetIsOnAudioVideoPage,
    (state: AudioVideoConnectionMonitorState.State, { payload }) => ({
      ...state,
      isOnAudioVideoConfigurationPage: payload.isOnAudioVideoConfigurationPage,
    })
  ),
  on(
    AudioVideoCheckActions.EnumerateDevicesSuccess,
    (state, { devices }): AudioVideoConnectionMonitorState.State => {
      const { selectedAudioDevice, selectedAudioOutputDevice, selectedVideoDevice } = state;

      return !selectedAudioDevice && !selectedAudioOutputDevice && !selectedVideoDevice
        ? {
            ...state,
            selectedAudioDevice: devices.filter((device) => device.kind === 'audioinput')[0],
            selectedAudioOutputDevice: devices.filter((device) => device.kind === 'audiooutput')[0],
            selectedVideoDevice: devices.filter((device) => device.kind === 'videoinput')[0],
          }
        : state;
    }
  ),
  on(
    AudioVideoCheckActions.SelectVideo,
    (state, { video }: { video: MediaDeviceInfo }): AudioVideoConnectionMonitorState.State => ({
      ...state,
      selectedVideoDevice: video,
    })
  ),
  on(
    AudioVideoCheckActions.ClearVideo,
    (state): AudioVideoConnectionMonitorState.State => ({
      ...state,
      selectedVideoDevice: null,
    })
  ),
  on(
    AudioVideoCheckActions.SelectMicrophone,
    (state, { microphone }): AudioVideoConnectionMonitorState.State => ({
      ...state,
      selectedAudioDevice: microphone,
    })
  ),
  on(
    AudioVideoCheckActions.ClearMicrophone,
    (state): AudioVideoConnectionMonitorState.State => ({
      ...state,
      selectedAudioDevice: null,
    })
  ),
  on(
    AudioVideoCheckActions.SelectSpeaker,
    (state, { speaker }): AudioVideoConnectionMonitorState.State => ({
      ...state,
      selectedAudioOutputDevice: speaker,
    })
  ),
  on(
    AudioVideoCheckActions.ClearSpeaker,
    (state): AudioVideoConnectionMonitorState.State => ({
      ...state,
      selectedAudioOutputDevice: null,
    })
  )
);

export function audioVideoConnectionMonitorReducer(
  state: AudioVideoConnectionMonitorState.State | undefined,
  action: Action
) {
  return reducer(state, action);
}
