import { createFeatureSelector, createSelector } from '@ngrx/store';

import { SignalRSelectors } from 'src/app/features/signal-r';
import { WizardSelectors } from 'src/app/features/wizard';

import { RonState } from '../state';

export const getRonState = createFeatureSelector<RonState.State>('ron');

export const getEndSessionSuccessful = createSelector(
  getRonState,
  (state: RonState.State) => state.isEndSessionSuccessful
);

export const getEndSessionFailure = createSelector(
  getRonState,
  (state: RonState.State) => state.isEndSessionFailure
);

export const selectActiveWizardUserIfGroupJoined = createSelector(
  SignalRSelectors.selectIsSignalRGroupJoined,
  WizardSelectors.getActiveWizardUser,
  (isSignalRGroupJoined, activeWizardUser) => {
    if (isSignalRGroupJoined && activeWizardUser) {
      return activeWizardUser;
    }

    return null;
  }
);
