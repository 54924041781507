import {
  Action,
  createReducer,
  on
} from '@ngrx/store';

import { KronActions } from '../actions';
import { KronState } from '../state';

const reducer = createReducer(
  KronState.initialState,
  on(KronActions.SetEndSessionSuccessful, (state: KronState.State) => ({
    ...state,
    isEndSessionSuccessful: true,
  })),
  on(KronActions.SetEndSessionFailure, (state: KronState.State) => ({
    ...state,
    isEndSessionFailure: true,
  })),
  on(KronActions.SetSessionStatuses, (state: KronState.State, { payload }) => ({
    ...state,
    isComplete: payload.isComplete,
    isSessionEnded: payload.isSessionEnded,
  }))
);

export function kronReducer(state: KronState.State | undefined, action: Action) {
  return reducer(state, action);
}
