import { ClientTheme } from '../../models/client.model';

export interface State {
  clientName: string;
  supportPhoneNumber: string;
  shouldBypassRealId: boolean;
  clientTheme: ClientTheme;
}

export const initialState: State = {
  clientName: null,
  supportPhoneNumber: null,
  shouldBypassRealId: false,
  clientTheme: null
};
