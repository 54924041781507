<div class="background">
  <div class="container">
    <div class="banner" [style.backgroundImage]="backgroundImageStyle">
      <img  data-testid="signing-complete-pic" [ngClass]="backgroundImageStyle ? 'doc-animation' : 'img'" [src]="foregroundImage" [alt]="altText" />
      <p data-testid="signing-complete-congratulations" class="large-font bold">{{title}}</p>
      <p data-testid="signing-complete-subheader" class="medium-font regular">{{message}}</p>
    </div>

    <div class="extra-content">
      <ng-content></ng-content>
    </div>

    <div *ngIf="supportTitle && user$ | async as user" class="support-section">
      <p data-testid="signing-complete-have-questions" class="bold large-medium-font text-row">{{ supportTitle }}</p>
      <app-support class="app-access-contact"></app-support>

      <div class="order-id-section">
        <div data-testid="signing-complete-package-id" class="section-row" data-private>
          <p class="bold medium-small-font">Order ID:&nbsp;</p>
          <p class="bold medium-small-font">{{ user?.packageId }}</p>
        </div>
      </div>
    </div>

    <p *ngIf="footerMessage" class="section-row">{{ footerMessage }}</p>
  </div>
</div>
