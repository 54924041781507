import { VideoArchiveIdsResponse, VideoConnectionModel, VideoSessionModel } from '../../models';

type streamId = string;

export interface State {
  videoSessionInfo: VideoSessionModel | null;
  isVideoSessionCreated: boolean | null;
  isVideoSessionInfoLoaded: boolean | null;
  videoStreamId: string;
  videoConnection: VideoConnectionModel | null;
  areAllVideoStreamsConnected: boolean;
  stopVideo: boolean;
  isAdditonalArchiveEnabled: boolean;
  ignoreParticipantVideoStream: boolean;
  downloadVideoArchiveIds: VideoArchiveIdsResponse;
  videoArchiveStatus: boolean;
  multiVideoStorageLocatorUrls: string[];
}

export const initialState: State = {
  videoSessionInfo: null,
  isVideoSessionCreated: false,
  isVideoSessionInfoLoaded: false,
  videoStreamId: null,
  videoConnection: null,
  areAllVideoStreamsConnected: false,
  stopVideo: false,
  isAdditonalArchiveEnabled: true,
  ignoreParticipantVideoStream: false,
  downloadVideoArchiveIds: null,
  videoArchiveStatus: false,
  multiVideoStorageLocatorUrls : null
};
