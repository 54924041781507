import { createFeatureSelector, createSelector } from '@ngrx/store';

import { PackageUser, PackageUserRole } from 'src/app/features/package-users/models';
import { getActivePackageUserGuid } from 'src/app/features/package-users/store/selectors/package-users.selectors';

import { LobbyState } from '../state';

export const getLobbyState = createFeatureSelector<LobbyState.State>('lobby');

export const getPackageStatus = createSelector(
  getLobbyState,
  (state: LobbyState.State) => state.packageStatus
);

export const getSigningAgent = createSelector(getLobbyState, (state: LobbyState.State) => {
  const participants = state.packageUsers?.filter(
    (participant) => participant.userRoleCode === PackageUserRole.SIGNINGAGENT
  );

  return !!participants?.length ? participants[0] : null;
});

export const getNonSigningAgents = createSelector(getLobbyState, (state: LobbyState.State) =>
  state.packageUsers?.filter(
    (participant) => participant.userRoleCode !== PackageUserRole.SIGNINGAGENT
  )
);

export const getLobbyParticipants = createSelector(
  getLobbyState,
  (state: LobbyState.State) => state.packageUsers
);

export const isCheckinCompleted = createSelector(getLobbyState, (state: LobbyState.State) =>
  state.packageUsers?.every((packageUser) => packageUser.checkInStatus.isCompleted)
);

export const getCurrentPackageUserLobby = createSelector(
  getLobbyState,
  (state: LobbyState.State) => {
    const participants = state.packageUsers?.filter(
      (participant) => participant.packageUserGuid === state.currentPackageUserLobbyGuid
    );
    return !!participants?.length ? participants[0] : null;
  }
);

export const getActivePackageUser = createSelector(
  getActivePackageUserGuid,
  getLobbyState,
  (activePackageUserGuid: string, state: LobbyState.State) => {
    const participants = state.packageUsers?.filter(
      (participant) =>
        participant.packageUserGuid?.toLocaleUpperCase() ===
        activePackageUserGuid?.toLocaleUpperCase()
    );
    return !!participants?.length ? participants[0] : null;
  }
);

export const getLobbyParticipant = createSelector(
  getLobbyParticipants,
  (lobbyParticipants: PackageUser[], props: { packageUserGuid: string }) =>
    lobbyParticipants?.find(
      (p) => p.packageUserGuid.toLowerCase() === props.packageUserGuid?.toLowerCase()
    )
);
