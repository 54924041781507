<a
  mat-button
  *ngIf="isFaqButtonEnabled"
  href="{{ faqUrl }}"
  target="_blank"
  rel="noopener"
  tabIndex="0"
  data-testid="header-faq"
>FAQs</a
>
