import { Action, createReducer, on } from '@ngrx/store';

import { ParticipantVerificationActions } from '../actions';
import { ParticipantVerificationState } from '../state';

const reducer = createReducer(
  ParticipantVerificationState.initialState,
  on(
    ParticipantVerificationActions.GetIdVerifications,
    (state: ParticipantVerificationState.State) => ({
      ...state,
    })
  ),
  on(
    ParticipantVerificationActions.SetIdVerifications,
    (state: ParticipantVerificationState.State, { payload }) => ({
      ...state,
      idVerifications: payload,
    })
  ),
  on(
    ParticipantVerificationActions.ApproveParticipant,
    (state: ParticipantVerificationState.State, { payload }) => ({
      ...state,
      approvedPackageUserGuids: [...state.approvedPackageUserGuids, payload],
    })
  ),
  on(
    ParticipantVerificationActions.RejoiningParticipant,
    (state: ParticipantVerificationState.State, { packageUserGuid, deviceCode }) => ({
      ...state,
      rejoinedUsers: [
        ...state.rejoinedUsers,
        { packageUserGuid: packageUserGuid, deviceCode: deviceCode },
      ],
    })
  ),
  on(
    //TODO: find a common name.
    ParticipantVerificationActions.DenyRejoiningParticipant,
    ParticipantVerificationActions.ApproveRejoiningParticipant,
    (state: ParticipantVerificationState.State, { packageUserGuid, deviceCode }) => ({
      ...state,
      rejoinedUsers: state.rejoinedUsers.filter(
        (rejoinedUser) =>
          rejoinedUser.packageUserGuid !== packageUserGuid && rejoinedUser.deviceCode !== deviceCode
      ),
    })
  ),
  on(
    ParticipantVerificationActions.DenyParticipant,
    (state: ParticipantVerificationState.State) => ({
      ...state,
    })
  ),
  on(
    ParticipantVerificationActions.GetApprovedParticipantsSuccess,
    (state: ParticipantVerificationState.State, { payload }) => ({
      ...state,
      approvedPackageUserGuids: payload,
    })
  ),
  on(
    ParticipantVerificationActions.SetAllSignersApproved,
    (state: ParticipantVerificationState.State, { payload }) => ({
      ...state,
      allSignersApproved: payload.allSignersApproved,
      isAllSignersApprovedInfoLoaded: true,
    })
  ),
  on(
    ParticipantVerificationActions.ApproveParticipantSuccess,
    ParticipantVerificationActions.DenyRejoiningParticipantSuccess,
    (state: ParticipantVerificationState.State, { payload }) => ({
      ...state,
      allSignersApproved: payload.areAllParticipantsApproved,
      approvedPackageUserGuids: payload.approvedParticipants,
    })
  )
);

export function participantVerificationReducer(
  state: ParticipantVerificationState.State | undefined,
  action: Action
) {
  return reducer(state, action);
}
