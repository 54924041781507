<div class="container">
  <h1>Your Digital Certificate is Expired</h1>

  <div class="callout">
    <img src="../../../../../assets/images/alarm.svg" class="alert" alt="" />
    <p>
      To update, click the “Update Your Digital Certificate” button below. After updating you will
      have to refresh this page. If you can’t update it at this time, please cancel the signing and
      reschedule.
    </p>
  </div>

  <app-button
    type="warning-secondary"
    (click)="cancelSigning()"
    [dataTestId]="'expired-cert-cancel-signing'"
  >
    Cancel Signing
  </app-button>
  <app-button type="primary" (click)="updateCommission()" [dataTestId]="'expired-cert-update'">
    Update Your Digital Certificate
  </app-button>
</div>
