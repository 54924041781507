import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, skip, Subject } from 'rxjs';
import { debounceTime, delay, filter, take, takeUntil, tap } from 'rxjs/operators';
import { Feature } from 'src/app/features/feature-management/models';
import { FeatureManagementService } from 'src/app/features/feature-management/services';

import { VideoArchiveIdsResponse } from 'src/app/features/video/models';
import { VideoActions, VideoSelectors } from 'src/app/features/video/store';

import { RootStoreState } from 'src/app/store';

@Component({
  selector: 'app-download-video-button',
  templateUrl: './download-video-button.component.html',
  styleUrls: ['./download-video-button.component.scss'],
})
export class DownloadVideoButtonComponent implements OnInit, OnDestroy {
  videoDownloadArchiveIds$: Observable<VideoArchiveIdsResponse>;
  videoArchiveDownloadStatus$: Observable<boolean>;

  isMultiVideoStorageLocatorEnabled: boolean;
  isMultiVideoStorageLocatorUrlsAvailable$: Observable<boolean>;
  isDownloadingMultiVideoStorageLocatorUrls: boolean[];
  onDestroyNotifier$ = new Subject<void>();

  constructor(
    private readonly store: Store<RootStoreState.State>,
    private readonly featureManagementService: FeatureManagementService
  ) {}

  ngOnDestroy(): void {
    this.onDestroyNotifier$.next(undefined);
    this.onDestroyNotifier$.complete();
  }

  ngOnInit(): void {
    this.isMultiVideoStorageLocatorEnabled =
      this.featureManagementService.getIsFeatureEnabledWithCaching(
        Feature.MultiVideoStorageLocator
      );

    if (this.isMultiVideoStorageLocatorEnabled) {
      this.store.dispatch(VideoActions.GetMultiVideoStorageLocatorUrls());
      this.isMultiVideoStorageLocatorUrlsAvailable$ = this.store.select(
        VideoSelectors.areMultiVideoStorageLocatorUrlsAvailable
      );
      this.store
        .select(VideoSelectors.getMultiVideoStorageLocatorUrls)
        .pipe(
          filter((urls) => !!urls),
          take(1),
          tap((urls) => {
            this.isDownloadingMultiVideoStorageLocatorUrls = new Array(urls.length).fill(false);
          })
        )
        .subscribe();
    } else {
      this.videoDownloadArchiveIds$ = this.store.select(VideoSelectors.getDownloadVideoArchiveIds);
      this.videoArchiveDownloadStatus$ = this.store.select(VideoSelectors.getVideoArchiveStatus);

      this.fetchDownloadUrls();
    }
  }

  onVideoButtonClick(videoArchiveId: number): void {
    this.store.dispatch(VideoActions.FetchVideo({ payload: { videoArchiveId } }));
  }

  onMultiVideoStorageLocatorButtonClick(index: number): void {
    this.isDownloadingMultiVideoStorageLocatorUrls[index] = true;
    this.store.dispatch(VideoActions.GetMultiVideoStorageLocatorUrls());
    this.store
      .select(VideoSelectors.getMultiVideoStorageLocatorUrls)
      .pipe(
        takeUntil(this.onDestroyNotifier$),
        skip(1),
        debounceTime(300),
        tap((urls) => {
          if (urls) {
            this.downloadFile(urls[index].toString());
            delay(2000);
            this.isDownloadingMultiVideoStorageLocatorUrls[index] = false;
          }
        })
      )
      .subscribe();
  }

  downloadFile(url: string) {
    const link = document.createElement('a');
    link.href = url;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  fetchDownloadUrls(): void {
    this.store.dispatch(VideoActions.GetVideoArchiveStatus());
    this.store.dispatch(VideoActions.FetchDownloadVideoArchiveIds());
  }
}
