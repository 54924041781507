import { Injectable } from '@angular/core';

import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, first } from 'rxjs/operators';

import { RootStoreState } from 'src/app/store';

import { CheckInTask } from '../models';
import { WizardActions } from '../store/actions';
import { WizardSelectors } from '../store/selectors';

@Injectable()
export class PresignCheckInTasksResolver {
  constructor(private readonly store: Store<RootStoreState.State>) {}

  resolve(): Observable<CheckInTask[]> {
    this.store.dispatch(WizardActions.FetchPresignCheckInTasks());

    return this.store.pipe(
      select(WizardSelectors.getTasks),
      filter((tasks) => tasks.length > 0),
      first()
    );
  }
}
