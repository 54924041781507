import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { PackageUser } from 'src/app/features/package-users';
import { AbstractWizardStepComponent, CheckInTaskCode, WizardStep } from 'src/app/features/wizard';
import { RootStoreState } from 'src/app/store';

import { EndorsementSteps, SignatureType } from '../../models';
import { EndorsementsCreationSelectors } from '../../store';

@Component({
  selector: 'app-initials-creation',
  templateUrl: './initials-creation.component.html',
  styleUrls: ['./initials-creation.component.scss'],
  providers: [
    {
      provide: AbstractWizardStepComponent,
      useExisting: InitialsCreationComponent,
    },
  ],
})
export class InitialsCreationComponent extends AbstractWizardStepComponent implements OnDestroy, OnInit {
  @Input() user: PackageUser;
  signatureType$: Observable<SignatureType>;
  stepTitle$: Observable<string>;

  readonly SignatureTypeEnum = SignatureType;

  constructor(private readonly store: Store<RootStoreState.State>) {
    super(store);

    this.stepMetadata = {
      stepName: 'Initials Creation',
      checkInTaskCode: CheckInTaskCode.InitialsCreation,
    } as WizardStep;

    this.formStep = new UntypedFormGroup({});
  }

  ngOnInit() {
    super.ngOnInit();
    this.signatureType$ = this.store.pipe(select(EndorsementsCreationSelectors.getSignatureType));

    this.stepTitle$ = this.signatureType$.pipe(
      map((signatureType) => {
        if (signatureType === SignatureType.Drawn) {
          return EndorsementSteps.DRAW_INITIALS;
        }
        return EndorsementSteps.SELECT_INITIALS;
      })
    );
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }
}
