export interface SaveEndorsementFromCommissionRequestModel {
  deviceCode: string;
  packageUserGuid: string;
  signatureConsentAccepted: boolean;
  consentTextId: number;
  consentType: string;
  isPreSign: boolean;
  signatureSvgImage: string;
  initials: string;
  initialsFont: string;
}
