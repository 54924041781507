import { HttpClient } from '@angular/common/http';
import { computed, inject, Injectable, signal, WritableSignal } from '@angular/core';

import { Store } from '@ngrx/store';
import { DeviceDetectorService } from 'ngx-device-detector';

import { DeviceGroupSelectors } from 'src/app/features/device-group/store/selectors';
import * as GadgetGuideActions from 'src/app/features/gadget-guide/store/actions/gadget-guide.actions';

import {
  selectGadgetGuideBySha256 as getGadgetGuide,
  selectGadgetGuide as getGadgetGuides,
} from 'src/app/features/gadget-guide/store/selectors/gadget-guide.selectors';

import {
  Brand,
  BrowserFilterType,
  GadgetAgent,
  GadgetGuide,
  GadgetGuideRequest,
  GadgetType,
} from './gadget-guide.types';

@Injectable({ providedIn: 'root' })
export class GadgetGuideService {
  private readonly deviceDetectorService = inject(DeviceDetectorService);
  private readonly http = inject(HttpClient);
  private readonly store = inject(Store);

  private readonly hasUserAgentData = (nav: Navigator) => 'userAgentData' in nav;

  private readonly clientHints = this.store.selectSignal(
    DeviceGroupSelectors.selectClientHintsForDevice
  );

  readonly gadgetGuides = this.store.selectSignal(getGadgetGuides);

  cameraKey: WritableSignal<string> = signal('');
  microphoneKey: WritableSignal<string> = signal('');
  speakerKey: WritableSignal<string> = signal('');

  getGadgetGuideByCameraKey = computed(() =>
    this.store.selectSignal(getGadgetGuide(this.cameraKey()))
  );

  getGadgetGuideByMicrophoneKey = computed(() =>
    this.store.selectSignal(getGadgetGuide(this.microphoneKey()))
  );

  getGadgetGuideBySpeakerKey = computed(() =>
    this.store.selectSignal(getGadgetGuide(this.speakerKey()))
  );

  filterClientHintsBrands(name, brands: Brand[]) {
    return brands.filter(({ brand }) => brand.toLowerCase().includes(name.toLowerCase()));
  }

  getBrowser(brands = []) {
    const [isChrome] = this.filterClientHintsBrands(BrowserFilterType.Chrome, brands);
    if (isChrome) {
      return { browserType: BrowserFilterType.Chrome, browserVersion: isChrome.version };
    }

    const [isEdge] = this.filterClientHintsBrands(BrowserFilterType.Edge, brands);
    if (isEdge) {
      return { browserType: BrowserFilterType.Edge, browserVersion: isEdge.version };
    }

    const { browser, browser_version } = this.deviceDetectorService;

    return {
      browserType: browser,
      browserVersion: browser_version,
    };
  }

  getMarkupForGadgetGuide(gadgetGuide: GadgetGuide, sha256: string, key: string) {
    let gadgetGuideRequest: GadgetGuideRequest;

    try {
      gadgetGuideRequest = gadgetGuide[sha256];
    } catch (e) {}

    if (gadgetGuideRequest?.response) {
      return (gadgetGuideRequest?.response)[key];
    }

    return '';
  }

  postGadgetGuideRequest(gadgetGuideRequest: GadgetAgent) {
    return this.http.post('audioVideoCheck/gadgetguide', gadgetGuideRequest);
  }

  async buildInitialRequestPayload(gadgetType: GadgetType, gThis: typeof globalThis) {
    const iPadProperties = {
      hasTouchPoints: gThis.navigator.maxTouchPoints > 0,
      hasOnTouchStart: 'ontouchstart' in gThis.window,
      hasIOSFeatures: 'standalone' in gThis.window.navigator,
      isNotPhone: gThis.window.innerWidth > 750,
    };
    
    const isIPad = 
      iPadProperties.isNotPhone &&
      iPadProperties.hasTouchPoints && 
      iPadProperties.hasOnTouchStart && 
      iPadProperties.hasIOSFeatures;

    // use client hints
    if (this.hasUserAgentData(gThis.navigator)) {
      const { brands, mobile, model, platform, platformVersion } = this.clientHints() ?? {};
      const { browserType, browserVersion } = this.getBrowser(brands);
      let gadgetAgent = {
        browserType,
        browserVersion,
        deviceModel: model,
        deviceName: mobile === true ? 'Mobile' : 'Desktop',
        osType: platform,
        osVersion: platformVersion,
        gadgetType,
      };

      const isMacLike = 
        gadgetAgent?.deviceModel?.toLowerCase().includes('mac') ||
        gadgetAgent?.osType?.toLowerCase().includes('mac') ||
        gadgetAgent?.osVersion?.toLowerCase().includes('mac');

      if (isMacLike && isIPad) {
        gadgetAgent = {
          browserType: 'Safari',
          browserVersion: '',
          deviceModel: 'iPad',
          deviceName: 'Desktop',
          osType: 'iOS',
          osVersion: 'unknown',
          gadgetType
        };
      }

      return {
        sha256: await this.getSHA256(JSON.stringify(gadgetAgent)),
        gadgetAgent,
      };
    }

    // use device detector service
    const { device, deviceType, os, os_version } = this.deviceDetectorService;
    const { browserType, browserVersion } = this.getBrowser();
    let gadgetAgent = {
      browserType: browserType,
      browserVersion: browserVersion,
      deviceModel: device,
      deviceName: deviceType === 'mobile' ? 'Mobile' : 'Desktop',
      osType: os,
      osVersion: os_version,
      gadgetType,
    };
    
    const isMacLike = 
      gadgetAgent.deviceModel.toLowerCase().includes('mac') ||
      gadgetAgent.osType.toLowerCase().includes('mac') ||
      gadgetAgent.osVersion.toLowerCase().includes('mac');

    if (isMacLike && isIPad) {
      gadgetAgent = {
        browserType: 'Safari',
        browserVersion: '',
        deviceModel: 'iPad',
        deviceName: 'Desktop',
        osType: 'iOS',
        osVersion: 'unknown',
        gadgetType
      };
    }

    return {
      sha256: await this.getSHA256(JSON.stringify(gadgetAgent)),
      gadgetAgent,
    };
  }

  async getSHA256(val: string): Promise<string> {
    const msgBuffer = new TextEncoder().encode(val);
    const hashBuffer = await globalThis.crypto.subtle.digest('SHA-256', msgBuffer);
    const hashArray = Array.from(new Uint8Array(hashBuffer));

    return hashArray.map((b) => b.toString(16).padStart(2, '0')).join('');
  }

  async initGadgetGuideRequest({ gadgetType }: { gadgetType: GadgetType }): Promise<GadgetGuide> {
    const { sha256, gadgetAgent } = await this.buildInitialRequestPayload(gadgetType, globalThis);
    const existingState = this.store.selectSignal(getGadgetGuide(sha256))();
    const gadgetGuideRequest: GadgetGuideRequest = {
      gadgetAgent,
      response: '',
      status: 'init',
      error: '',
      ...existingState,
    };

    const gadgetGuide: GadgetGuide = { [sha256]: gadgetGuideRequest };

    this.store.dispatch(GadgetGuideActions.GadgetGuideRequest({ payload: gadgetGuide }));

    return gadgetGuide;
  }
}
