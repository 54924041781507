<div class="form-step__subheading" data-private>
  {{ user?.firstName }} {{ user?.lastName }}, create your initials below.
</div>

<app-signature-view
  [signature]="signatureData?.svgImageString"
  [imageTypeProvided]="true"
  [placeholderText]="'Your Initials Will Appear Here'"
  [dataTestId]="'drawn-initials-preview'"
  data-private
></app-signature-view>

<app-button
  *ngIf="!!signatureData; else drawinitials"
  class="edit-initials-button"
  type="secondary"
  (click)="drawInitials()"
  [dataTestId]="'edit-initials-button'"
>
  Edit</app-button
>
<ng-template #drawinitials>
  <app-button
    class="draw-initials-button"
    type="primary"
    (click)="drawInitials()"
    [dataTestId]="'draw-initials-button'"
  >
    Draw Your Initials
  </app-button>
</ng-template>

<div [style.visibility]="formStep.errors ? 'visible' : 'hidden'" class="error-message">
  Please draw your initials to continue
</div>

<app-button-container>
  <app-button
    [dataTestId]="'previous-button-draw-initials'"
    type="tertiary"
    (click)="onPreviousClick()"
  >
    Previous
  </app-button>
  <app-button [dataTestId]="'next-button-draw-initials'" type="primary" (click)="nextClick()">
    Next
  </app-button>
</app-button-container>
