import { createFeatureSelector, createSelector } from '@ngrx/store';

import { ExceptionType } from '../../models/notification-exceptions.model';
import { NotificationsState } from '../state';

export const selectNotificationsState = createFeatureSelector<NotificationsState.NotificationsState>(
  'notifications'
);

export const selectNotifications = createSelector(
  selectNotificationsState,
  (state: NotificationsState.NotificationsState) => state.notifications
);

export const selectHardStopNotifications = createSelector(
  selectNotificationsState,
  (state: NotificationsState.NotificationsState) => {
    return state?.notifications?.filter(
      (notifications) =>
        notifications.exceptionType === ExceptionType.CannotProceed ||
        notifications.exceptionType === ExceptionType.ReloadRetry
    ) || [];
  }
);
