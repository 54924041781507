import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { select, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, filter, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { ConsumerPortalActions } from 'src/app/features/consumer-portal/store';
import { DeviceGroupSelectors } from 'src/app/features/device-group/store/selectors';

import { PackagesSelectors } from 'src/app/features/packages';
import { RootStoreState } from 'src/app/store';
import { AuditLogsSaveService } from '../../services';

import { AuditLogsActions } from '../actions';

@Injectable()
export class AuditLogsEffects {
  saveLandingPageEvent$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuditLogsActions.LandingPageViewed),
      withLatestFrom(
        this.store.pipe(select(PackagesSelectors.getActivePackageGuid)),
        this.store.pipe(select(DeviceGroupSelectors.getDeviceCode)),
        this.store.pipe(select(DeviceGroupSelectors.selectClientHintsForDevice))
      ),
      switchMap(([action, activePackageGuid, deviceCode, clientHints]) => {
        return this.auditLogSaveService
          .postLandingPage({
            PackageGuid: activePackageGuid,
            DeviceCode: deviceCode,
            ClientHints: clientHints,
          })
          .pipe(
            map(() => AuditLogsActions.SaveSuccessful()),
            catchError(() => of(AuditLogsActions.SaveFailed()))
          );
      })
    )
  );

  savePreSignDashboardEvent$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuditLogsActions.PreSignDashboardViewed, ConsumerPortalActions.SigningDashboardOpened),
      concatLatestFrom(() => [
        this.store.select(PackagesSelectors.getActivePackageGuid),
        this.store.select(DeviceGroupSelectors.getDeviceCode),
      ]),
      filter(() => sessionStorage.getItem('auditLogDashboardViewed') === null),
      filter(([_, __, deviceCode]) => !!deviceCode),
      switchMap(([_, activePackageGuid, deviceCode]) => {
        return this.auditLogSaveService
          .postPreSignDashboard({
            PackageGuid: activePackageGuid,
            DeviceCode: deviceCode,
          })
          .pipe(
            tap(() => sessionStorage.setItem('auditLogDashboardViewed', JSON.stringify(true))),
            map(() => AuditLogsActions.SaveSuccessful()),
            catchError(() => of(AuditLogsActions.SaveFailed()))
          );
      })
    );
  });

  saveReadyUpEvent$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuditLogsActions.SaveReadyUpEvent),
      withLatestFrom(
        this.store.pipe(select(PackagesSelectors.getActivePackageGuid)),
        this.store.pipe(select(DeviceGroupSelectors.getDeviceCode))
      ),
      switchMap(([action, activePackageGuid, deviceCode]) => {
        return this.auditLogSaveService
          .postReadyUp({
            PackageGuid: activePackageGuid,
            PackageUserGuid: action.payload.packageUserGuid,
            DeviceCode: deviceCode,
          })
          .pipe(
            map(() => AuditLogsActions.SaveSuccessful()),
            catchError(() => of(AuditLogsActions.SaveFailed()))
          );
      })
    )
  );

  constructor(
    private readonly actions$: Actions,
    private readonly auditLogSaveService: AuditLogsSaveService,
    private readonly store: Store<RootStoreState.State>
  ) {}
}
