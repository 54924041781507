import { Action, createReducer, on } from '@ngrx/store';

import { IdVerificationActions } from '../actions';
import { IdVerificationState } from '../state';

const reducer = createReducer(
  IdVerificationState.initialState,
  on(IdVerificationActions.SendAccessLinkSmsSuccessful, (state: IdVerificationState.State, { hasAccessLinkSmsSentSuccessfully }) => ({
    ...state,
    hasAccessLinkSmsSentSuccessfully: hasAccessLinkSmsSentSuccessfully,
  })),
  on(IdVerificationActions.SendAccessLinkSmsFailed, (state: IdVerificationState.State, { hasAccessLinkSmsSentSuccessfully }) => ({
    ...state,
    hasAccessLinkSmsSentSuccessfully: hasAccessLinkSmsSentSuccessfully,
  })),
  on(IdVerificationActions.SetAccessLink, (state: IdVerificationState.State, { payload }) => ({
    ...state,
    accessLink: payload.accessLink,
    packageUserGuid: payload.packageUserGuid,
    lastLinkTime: Date.now(),
    hasAccessLinkSmsSentSuccessfully: null
  })),
  on(
    IdVerificationActions.IdVerificationCompleted,
    (state: IdVerificationState.State, { payload }) => ({
      ...state,
      statusByUser: {
        ...state.statusByUser,
        [payload.packageUserGuid]: {
          ...state.statusByUser[payload.packageUserGuid],
          isComplete: true,
        },
      },
    })
  ),
  on(IdVerificationActions.SessionStarted, (state: IdVerificationState.State, { payload }) => ({
    ...state,
    statusByUser: {
      ...state.statusByUser,
      [payload.packageUserGuid]: {
        ...state.statusByUser[payload.packageUserGuid],
        isSessionStarted: true,
      },
    },
  })),
  on(
    IdVerificationActions.ResetSessionStarted,
    (state: IdVerificationState.State, { payload }) => ({
      ...state,
      statusByUser: {
        ...state.statusByUser,
        [payload.packageUserGuid]: {
          ...state.statusByUser[payload.packageUserGuid],
          isSessionStarted: false,
        },
      },
    })
  )
);

export function idVerificationReducer(
  state: IdVerificationState.State | undefined,
  action: Action
) {
  return reducer(state, action);
}

export function qrCodeReducer(state: IdVerificationState.State | undefined, action: Action) {
  return reducer(state, action);
}
