import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { combineLatest, Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { CheckInActions } from 'src/app/features/check-in/store/actions';
import { CheckInSelectors } from 'src/app/features/check-in/store/selectors';
import { DeviceGroupSelectors } from 'src/app/features/device-group/store/selectors';

import {
  PackageUserRole,
  PackageUsersSelectors,
} from 'src/app/features/package-users';
import { PackageUserWithDisplayName } from 'src/app/features/package-users/util';
import { RootStoreState } from 'src/app/store';


@Component({
  selector: 'app-add-participant',
  templateUrl: './add-participant.component.html',
  styleUrls: ['./add-participant.component.scss'],
})
export class AddParticipantComponent implements OnDestroy, OnInit {
  @Input() participant: PackageUserWithDisplayName;
  @Output() participantClicked = new EventEmitter();
  @Input() isInWaitingRoom = false;

  imageSources: Record<string, string> = {
    [PackageUserRole.WITNESS]: 'assets/images/witness-eye.svg',
    default: 'assets/images/feather-pen.svg',
  };
  imageDescriptions: Record<string, string> = {
    [PackageUserRole.WITNESS]: 'Image of eyeball',
    default: 'Image of feather pen',
  };

  imageSrc: string;
  imageAltText: string;
  isDisabled$: Observable<boolean>;
  isSelected$: Observable<boolean>;
  user$: Observable<PackageUserWithDisplayName>;

  readonly onDestroyNotifier$ = new Subject();

  constructor(private readonly store: Store<RootStoreState.State>) {}

  ngOnInit(): void {
    const userRoleCode =
      this.participant.userRoleCode === PackageUserRole.WITNESS 
        ? this.participant.userRoleCode
        : 'default';
    this.imageSrc = this.imageSources[userRoleCode];
    this.imageAltText = this.imageDescriptions[userRoleCode];

    this.user$ = this.store
      .select(PackageUsersSelectors.getPackageUser(this.participant.packageUserGuid))
      .pipe(map((pu) => ({ ...pu, displayName: this.participant.displayName })));

    this.isSelected$ = this.store.pipe(
      select(CheckInSelectors.getUserCheckInSequence),
      map((checkInSequence) => checkInSequence.includes(this.participant.packageUserGuid))
    );

    this.isDisabled$ = combineLatest([
      this.store.select(DeviceGroupSelectors.getUsersOnDevice),      
      this.store.select(CheckInSelectors.getUserCheckInSequence)
    ]).pipe(
      map(([usersOnDevice, checkInSequence]) => {
        const check: string[] = [];

        checkInSequence.forEach((user) => {
          if (!usersOnDevice.some((x) => x.packageUserGuid === user)) {
            check.push(user);
          }
        });

        if (check.includes(this.participant.packageUserGuid) || check.length === 0) {
          return false;
        } else {
          return true;
        }
      })
    );    
  }

  ngOnDestroy(): void {
    this.onDestroyNotifier$.next(undefined);
    this.onDestroyNotifier$.complete();
  }

  onSelectParticipant() {
    this.store.dispatch(
      CheckInActions.ToggleUserCheckInSequence({ 
        payload: { 
          packageUserGuid: this.participant.packageUserGuid
        } 
      })
    );

    this.participantClicked.emit();
  }

}
