import { Component, Input } from '@angular/core';
import { Store } from '@ngrx/store';

import { PackageUser } from 'src/app/features/package-users';
import { RootStoreState } from 'src/app/store';
import { ModalsActions } from 'src/app/features/modals';

import { EndorsementsCreationActions } from '../../store';
import { DrawnEndorsement } from '../../models';

@Component({
  selector: 'app-draw-signature',
  templateUrl: './draw-signature.component.html',
  styleUrls: ['./draw-signature.component.scss'],
})
export class DrawSignatureComponent {
  @Input() user: PackageUser;
  @Input() existingSignatureData: DrawnEndorsement;

  constructor(private readonly store: Store<RootStoreState.State>) {}

  saveSignature(drawnEndorsement: DrawnEndorsement) {
    this.store.dispatch(
      EndorsementsCreationActions.UpdateDrawnSignature({
        payload: drawnEndorsement,
      })
    );
    this.store.dispatch(ModalsActions.ClearModalComponent());
  }

  cancelSignature() {
    this.store.dispatch(ModalsActions.ClearModalComponent());
  }
}
