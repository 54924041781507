import { DrawnEndorsement, EndorsementSelection, SignatureType } from '../../models';

export interface State {
  signatureSelection: {
    previews: Array<EndorsementSelection>;
    activeSignature: number;
    currentSelectionIsStatic: boolean;
  };
  initialsSelection: {
    previews: Array<EndorsementSelection>;
    activeInitials: number;
    currentSelectionIsStatic: boolean;
  };
  drawnSignature: DrawnEndorsement;
  drawnInitials: DrawnEndorsement;
  hasUploadedSignature: boolean;
  uploadedSignatureImageBase64: string;
  selectionsSaved: boolean;
  signatureType: SignatureType;
  isSignatureCreatedInPresign: boolean;
}

export const initialState: State = {
  signatureSelection: {
    previews: null,
    activeSignature: null,
    currentSelectionIsStatic: false,
  },
  initialsSelection: {
    previews: null,
    activeInitials: null,
    currentSelectionIsStatic: false,
  },
  drawnSignature: null,
  drawnInitials: null,
  hasUploadedSignature: false,
  uploadedSignatureImageBase64: null,
  selectionsSaved: false,
  signatureType: SignatureType.Unknown,
  isSignatureCreatedInPresign: false,
};
