import { Injectable } from '@angular/core';
import { IDeviceValidatorService } from './IDeviceValidatorService';

@Injectable({
  providedIn: 'root'
})
export class DefaultDeviceValidatorService implements IDeviceValidatorService {

  constructor() { }

  isBrowserSupported(userAgent: string): boolean {
    return !!userAgent && (userAgent.includes("Chrome") ||
      userAgent.includes("Safari") ||
        userAgent.includes("Firefox"));
  }
}
