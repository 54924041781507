import { EndorsementType } from './endorsement-types';

export interface DocumentEndorsementLocation {
  documentEndorsementLocationId: number;
  endorsementTypeCode: EndorsementType;
  height?: number;
  isRequired: boolean;
  packageDocumentId: number;
  pageNumber: number;
  signatureIdRef: string;
  statusCode: string;
  width?: number;
  xPosition?: number;
  yPosition?: number;
  xProvided?: number;
  yProvided?: number;
  endorsementImage: string;
  packageUserGuid: string;
  packageUserId?: number;
  endorsementValue?: string;
  isSignableInCurrentSession?: boolean;
  isSignableOnDevice?: boolean;
  isDisabled?: boolean;
  userRoleCode: string;
  imageId?: number;
   // TODO: User Story 50434: Remove Old Endorsement Application Logic
  attempted?: boolean;
  // END TODO
}
