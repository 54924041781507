<img
  *ngIf="endorsementLocation?.endorsementImage && !displayEndorsementType"
  class="endorsement-image"
  [src]="'data:image/png;base64,' + endorsementLocation?.endorsementImage"
  alt="unsupported endorsement"
/>

<div *ngIf="displayEndorsementType">
  {{ endorsementLocation?.endorsementTypeCode }} ID:
  {{ endorsementLocation?.documentEndorsementLocationId }}
</div>
