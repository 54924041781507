import { Component, HostBinding, Input, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable, Subject, Subscription } from 'rxjs';
import { distinctUntilKeyChanged, filter, take, takeUntil, tap } from 'rxjs/operators';
import { DocumentsSelectors, PackageDocument, ViewerScale } from 'src/app/features/documents';
import { Feature } from 'src/app/features/feature-management/models';
import { FeatureManagementService } from 'src/app/features/feature-management/services';

import { RootStoreState } from 'src/app/store';
import { SmartDocEndorsementCoordinateUpdate } from '../../models';

import { EndorsementsActions, EndorsementsSelectors } from '../../store';

@Component({
  selector: 'app-endorsements-container',
  templateUrl: './endorsements-container.component.html',
  styleUrls: ['./endorsements-container.component.scss'],
})
export class EndorsementsContainerComponent implements OnInit, OnDestroy {
  @HostBinding('style.top.px') @Input() documentOffset = 0;
  @HostBinding('style.height') @Input() documentheight = `calc(100% - ${this.documentOffset})`;

  activeEndorsementIds$: Observable<Array<number>>;
  notifier = new Subject();
  areAllEndorsementsSignedSubscription: Subscription;

  activePackageDocument: PackageDocument;
  documentScale: number;
  calculatedDocuments: Set<number> = new Set();
  prePopulatedEndorsements: Set<number> = new Set();
  disabledEndorsementIds: Set<number> = new Set();
  areAllEndorsementsSigned: boolean;
  protected isExpereENotesSupported: boolean;

  constructor(
    private readonly store: Store<RootStoreState.State>,
    private readonly featureManagementService: FeatureManagementService
  ) {}

  ngOnInit(): void {
    this.isExpereENotesSupported = this.featureManagementService.getIsFeatureEnabledWithCaching(
      Feature.ExpereENoteSupport
    );

    this.activeEndorsementIds$ = this.store.pipe(
      select(EndorsementsSelectors.selectActiveEndorsementLocationIds)
    );

    this.store
      .pipe(
        takeUntil(this.notifier),
        select(DocumentsSelectors.getViewerScale),
        filter<ViewerScale>(Boolean),
        tap((viewerScale: ViewerScale) => {
          this.viewerScaleChangeHandler(viewerScale);
        })
      )
      .subscribe();

    this.store
      .pipe(
        takeUntil(this.notifier),
        select(DocumentsSelectors.selectActivePackageDocument),
        filter<PackageDocument>(Boolean),
        distinctUntilKeyChanged('packageDocumentId'),
        tap((packageDoc: PackageDocument) => this.documentChangeHandler(packageDoc))
      )
      .subscribe();

    this.store
      .pipe(
        takeUntil(this.notifier),
        select(EndorsementsSelectors.selectDisabledEndorsementIds),
        tap(
          (disabledEndorsementIds: number[]) =>
            (this.disabledEndorsementIds = new Set(disabledEndorsementIds))
        )
      )
      .subscribe();

    this.areAllEndorsementsSignedSubscription = this.store
      .pipe(select(EndorsementsSelectors.areAllEndorsementsSigned))
      .subscribe(
        (areAllEndorsementsSigned) => (this.areAllEndorsementsSigned = areAllEndorsementsSigned)
      );
  }

  ngOnDestroy() {
    this.notifier.next(undefined);
    this.notifier.complete();
    this.areAllEndorsementsSignedSubscription.unsubscribe();
  }

  documentChangeHandler(packageDocument: PackageDocument) {
    this.activePackageDocument = packageDocument;

    if (
      packageDocument?.isSmartDoc &&
      !this.calculatedDocuments.has(packageDocument.packageDocumentId)
    ) {
      setTimeout(() => {
        this.calculateSmartDocEndorsementLocations(packageDocument.packageDocumentId);
      }, 0);
    }
  }

  viewerScaleChangeHandler(viewerScale: ViewerScale) {
    this.documentScale = viewerScale.scale;
    this.calculatedDocuments.clear();

    if (this.activePackageDocument?.isSmartDoc) {
      this.calculateSmartDocEndorsementLocations(this.activePackageDocument.packageDocumentId);
    }
  }

  calculateSmartDocEndorsementLocations(packageDocumentId) {
    const shadowDomReference = document.querySelector('app-smart-document-viewer')?.shadowRoot;
    let activeIds: Array<number>;
    this.store
      .pipe(select(EndorsementsSelectors.selectActiveEndorsementLocationIds), take(1))
      .subscribe((e) => (activeIds = e));

    if (!shadowDomReference || !activeIds) {
      return;
    }

    if (activeIds.length === 0) {
      this.calculatedDocuments.add(packageDocumentId);
      return;
    }

    this.updateSmartDocEndorsementLocations(shadowDomReference, activeIds, packageDocumentId, true);
  }

  updateSmartDocEndorsementLocations(
    shadowDomReference: ShadowRoot,
    activeIds: Array<number>,
    packageDocumentId: number,
    createMissingPlaceholders: boolean
  ) {
    const locationUpdates = new Array<SmartDocEndorsementCoordinateUpdate>();
    const missingReferenceElements = {} as Record<number, Element>;

    activeIds.forEach((id) => {
      const element = shadowDomReference.querySelector(
        `div[smart-doc-v1-button][data-document-endorsement-id="${id}"]`
      );

      if (element) {
        const existingPlaceholder = element.querySelector('button');
        if (existingPlaceholder) {
          locationUpdates.push(this.buildLocationUpdates(id, existingPlaceholder, element));
        } else {
          missingReferenceElements[id] = element;
        }
      } else {
        this.prePopulatedEndorsements.add(id);
      }
    });

    const referenceElementIds = Object.keys(missingReferenceElements).map((e) => Number(e));
    const referenceElementsMissing = referenceElementIds.length > 0;

    if (createMissingPlaceholders && referenceElementsMissing) {
      this.createPlaceholders(missingReferenceElements);
      setTimeout(() => {
        this.updateSmartDocEndorsementLocations(
          shadowDomReference,
          referenceElementIds,
          packageDocumentId,
          false
        );
      }, 0);
    }

    if (!referenceElementsMissing) {
      this.calculatedDocuments.add(packageDocumentId);
    }

    if (locationUpdates.length > 0) {
      this.store.dispatch(
        EndorsementsActions.UpdateENoteEndorsementCoordinates({
          payload: locationUpdates,
        })
      );
    }
  }

  buildLocationUpdates(
    id: number,
    existingPlaceholder: HTMLButtonElement,
    element: Element
  ): SmartDocEndorsementCoordinateUpdate {
    return {
      id,
      changes: {
        xPosition: !this.isExpereENotesSupported
          ? existingPlaceholder.offsetLeft
          : this.getXPostionOffset(element, existingPlaceholder),
        yPosition: !this.isExpereENotesSupported
          ? existingPlaceholder.offsetTop - this.documentOffset
          : this.getYPostionOffset(element, existingPlaceholder),
        height: existingPlaceholder.offsetHeight,
        width: existingPlaceholder.offsetWidth,
      },
    } as SmartDocEndorsementCoordinateUpdate;
  }

  createPlaceholders(missingReferenceElements: Record<number, Element>) {
    const missingIds = Object.keys(missingReferenceElements);

    missingIds.forEach((id) => {
      const refElement: Element = missingReferenceElements[id];
      const node = document.createElement('button');
      node.classList.add('enote-placeholder');
      const textNode = document.createTextNode('Sign Here ');
      const iconNode = document.createElement('i');
      iconNode.classList.add('fas', 'fas-pen-nib', 'enote-placeholder__icon');

      node.appendChild(textNode);
      node.appendChild(iconNode);
      refElement.appendChild(node);
    });
  }

  shouldHideEndorsement(id: number) {
    if (!this.activePackageDocument?.isSmartDoc) {
      return false;
    }
    return this.prePopulatedEndorsements.has(id);
  }

  shouldDisableEndorsement(id: number) {
    return this.disabledEndorsementIds.has(id);
  }

  getXPostionOffset(element: any, existingPlaceholder: HTMLButtonElement): number {
    // eNote endorsements will never be within 100 pixels of top of the eNote
    const isPlaceHolderOnPage = existingPlaceholder.offsetTop - this.documentOffset > 100;

    // PlaceHolder y is laid out correctly on the Page: use original X value
    if (isPlaceHolderOnPage) {
      return existingPlaceholder.offsetLeft;
    }

    return this.getTotalInheritedXPosition(element);
  }

  getTotalInheritedXPosition(element: any): number {
    let xOffset = 0;

    while (!!element) {
      xOffset += element.offsetLeft;

      // keep the logic calculation of how to position endorsements within the class that displays our documents
      element =
        element.nodeName.toLowerCase() === 'app-document-viewer' ? null : element.offsetParent;
    }

    return xOffset;
  }

  getYPostionOffset(element: Element, existingPlaceholder: HTMLElement): number {
    const yOffset = existingPlaceholder.offsetTop - this.documentOffset;
    // eNote endorsements will never be within 100 pixels of top of the eNote
    const isPlaceHolderOnPage = yOffset > 100;

    if (isPlaceHolderOnPage) {
      return yOffset;
    }

    return this.getAdjustedTotalInheritedYPosition(element);
  }

  getAdjustedTotalInheritedYPosition(element: any): number {
    let yOffset = 0;

    while (!!element) {
      yOffset += element.offsetTop;

     // keep the logic calculation of how to position endorsements within the class that displays our documents
      element =
        element.nodeName.toLowerCase() === 'app-document-viewer' ? null : element.offsetParent;
    }

    return yOffset - this.documentOffset;
  }
}
