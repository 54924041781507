import { select, Store } from '@ngrx/store';
import { take } from 'rxjs/operators';

import { RootStoreState } from 'src/app/store';
import { ModalsSelectors } from 'src/app/features/modals';

import { SignatureValidationService } from './signature-validation.service';
import { InitialsValidationService } from './initials-validation.service';
import { CanvasValidationService } from './CanvasValidationService';
import { Injectable } from '@angular/core';

@Injectable()
export class CanvasValidationServiceFactory {
  constructor(private readonly store: Store<RootStoreState.State>) {}

  getCanvasValidationService(): CanvasValidationService {
    let activeComponentName: any;

    this.store
      .pipe(select(ModalsSelectors.getModalTitle), take(1))
      .subscribe((s) => (activeComponentName = s));

    if (activeComponentName.toLowerCase().includes('initials')) {
      return new InitialsValidationService();
    }
    return new SignatureValidationService();
  }
}
