import { EndorsementType } from './endorsement-types';

export interface EndorsementImage {
  endorsementTypeCode: EndorsementType;
  endorsementImageId: string;
  endorsementImage: string;
}

export interface UniqueEndorsementImage {
  endorsementLocationId: number;
  endorsementTypeCode: EndorsementType;
  endorsementImageId?: string;
  endorsementImage?: string;
}

export interface SystemEndorsementImage {
  endorsementTypeCode: EndorsementType;
  endorsementImageId: string;
  endorsementImage: string;
}

export interface PackageUserEndorsementImages {
  packageUserGuid: string;
  endorsementImages: Array<EndorsementImage>;
}

export interface PackageUserUniqueEndorsementImages {
  packageUserGuid: string;
  uniqueEndorsementImages: Array<UniqueEndorsementImage>;
}

export interface SystemEndorsementImages {
  endorsementImageId: number;
  systemEndorsementImages: SystemEndorsementImage;
}
