import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';

import { combineLatest, interval, Observable, Subject } from 'rxjs';
import { filter, startWith, take, takeUntil, tap } from 'rxjs/operators';

import { ModalsActions, ModalSize } from 'src/app/features/modals';
import { PackageUsersSelectors } from 'src/app/features/package-users';

import { LongPollingConfig } from 'src/app/features/shared/configs';
import { CheckInActions, CheckInSelectors } from '../../store';
import { NotaryModalComponent } from '../notary-modal';
import { ButtonAction } from './button-action.enum';

@Component({
  selector: 'app-non-signing-agent-welcome-page',
  templateUrl: './non-signing-agent-welcome-page.component.html',
  styleUrls: ['./non-signing-agent-welcome-page.component.scss'],
})
export class NonSigningAgentWelcomePageComponent implements OnInit, OnDestroy {
  readonly ButtonActionEnum = ButtonAction;
  buttonAction$: Observable<ButtonAction>;

  // using take(1) so we don't keep re-creating the participant components every time
  // the getNonSigningAgents selector emits a new value. Re-creating the components causes
  // the tab focus on the page to be lost.
  readonly nonSigningAgentsWithDisplayName$ = this.store
    .select(PackageUsersSelectors.selectNonSigningAgentsWithFullNames)
    .pipe(
      filter((nonSigningAgentsWithFullNames) => nonSigningAgentsWithFullNames.length > 0),
      take(1)
    );
  readonly isUserCheckInSequenceEmpty$ = this.store.select(
    CheckInSelectors.selectIsUserCheckInSequenceEmpty
  );
  readonly hasMultiplenonSigningAgents$ = this.store.select(
    PackageUsersSelectors.selectHasMultipleNonSigningAgents
  );
  readonly nonSaWelcomePageButtonVm$ = this.store.select(
    CheckInSelectors.selectNonSigningAgentWelcomePageButton
  );

  readonly nonSaWelcomePageRejoinButtonVm$ = this.store.select(
    CheckInSelectors.selectNonSigningAgentWelcomePageRejoinButton
  );

  pollingInterval = LongPollingConfig['6Seconds'].interval;

  private readonly onDestroyNotifier$ = new Subject();

  constructor(private readonly store: Store) {}

  ngOnInit(): void {
    this.store.dispatch(CheckInActions.NonSigningAgentWelcomePageOpened());

    // Automatically add the non-signing agent to the check-in sequence
    // if there is only one non signing agent and they have not started check-in
    this.store
      .select(PackageUsersSelectors.getNonSigningAgents)
      .pipe(
        filter((nonSAs) => nonSAs?.every((u) => !!u?.checkInStatus)),
        filter((nonSAs) => nonSAs.length === 1 && !nonSAs[0].checkInStatus.isStarted),
        tap((nonSAs) => {
          this.store.dispatch(
            CheckInActions.SetUserCheckInSequence({ payload: [nonSAs[0].packageUserGuid] })
          );
        }),
        take(1)
      )
      .subscribe();

    this.pollForMissingSAUpdatesOnPotentialRejoin().subscribe();
  }

  ngOnDestroy(): void {
    this.onDestroyNotifier$.next(undefined);
    this.onDestroyNotifier$.complete();
  }

  beginCheckIn() {
    this.store.dispatch(
      ModalsActions.SetStandaloneModalComponent({
        payload: {
          component: NotaryModalComponent,
          modalSize: ModalSize.fitContent,
        },
      })
    );
  }

  goToSigningRoom() {
    this.store.dispatch(CheckInActions.GoToSigningRoom());
  }

  goToWaitingRoom() {
    this.store.dispatch(CheckInActions.GoToWaitingRoom());
  }

  rejoin() {
    this.store.dispatch(CheckInActions.BeginRejoin());
  }

  pollForMissingSAUpdatesOnPotentialRejoin(): Observable<[number, boolean]> {
    return combineLatest([
      interval(this.pollingInterval).pipe(startWith(0)),
      this.store.select(CheckInSelectors.selectIsRejoiningWaitingOnSA),
    ]).pipe(
      takeUntil(this.onDestroyNotifier$),
      filter(([_, isWaitingForSA]) => isWaitingForSA),
      tap(() => {
        this.store.dispatch(CheckInActions.CheckAndUpdateIfSACompletedCheckIn());
      })
    );
  }
}
