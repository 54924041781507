<app-info-icon></app-info-icon>
<section>
  <header>
    <span>{{ title }}</span>
  </header>
  <div>
    <p>
      {{ body }}
      <span *ngIf='isAddUserToSessionEnabled$ | async'>
        You can also transfer them to your device if you're in the same room.
      </span>
    </p>
  </div>
  <p>
    If this is due to a technical problem on our end, please wait a moment while we attempt to resolve it. If the problem persists, please contact support:
    <app-support-phone></app-support-phone>
  </p>
  <div class="toast-notification__action-container">
    <app-button-container *ngIf='isAddUserToSessionEnabled$ | async'>
      <app-button
        (buttonClick)="addUserToThisSession()"
        data-testid="toast-add-user-to-session"
        type="secondary"
      >
      Transfer Participant(s) to this Device
      </app-button>
    </app-button-container>
  </div>
</section>
