import { Action, createReducer, on } from '@ngrx/store';
import { NotificationsModule } from '../../notifications.module';
import { NotificationsActions } from '../actions';
import { NotificationsState } from '../state';

const reducer = createReducer(
  NotificationsState.InitialNotificationsState,
  on(
    NotificationsActions.InsertNotification,
    (state: NotificationsState.NotificationsState, { payload }) => {
      const notifications = state.notifications.concat(payload);
      return {
        ...state,
        notifications,
      };
    }
  ),
  on(
    NotificationsActions.NotificationCleared,
    (state: NotificationsState.NotificationsState, { payload }) => {
      const notifications = state.notifications.filter(
        (notification) => notification.id !== payload.notificationId
      );
      return {
        ...state,
        notifications,
      };
    }
  )
);

export function notificationsReducer(
  state: NotificationsState.NotificationsState | undefined,
  action: Action
) {
  return reducer(state, action);
}
