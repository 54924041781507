import { Component } from '@angular/core';

@Component({
  selector: 'app-post-session-layout',
  templateUrl: './post-session-layout.component.html',
  styleUrls: ['./post-session-layout.component.scss'],
})
export class PostSessionLayoutComponent  {

  constructor() {}

}
