import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable()
export class CompletedDocumentService {
  constructor(private readonly httpClient: HttpClient) {}

  getCompletedDocument(PackageUserGuid: string) {
    return this.httpClient.get(`docs/complete/packageUsers/${PackageUserGuid}`, {
      responseType: 'blob',
    });
  }
}
