import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';

import { catchError } from 'rxjs/operators';
import { delayedRetry } from 'src/app/operators';

@Injectable()
export class PresignService {

  constructor(
    private readonly httpClient: HttpClient,
  ) {}

  public PostPreSigningParticipantStarted(packageUserGuid: string) {
    return this.PostPreSigningParticipantEvent(packageUserGuid, 'STARTED');
  }

  public PostPreSigningParticipantCompleted(packageUserGuid: string) {
    return this.PostPreSigningParticipantEvent(packageUserGuid, 'COMPLETED');
  }

  private PostPreSigningParticipantEvent(packageUserGuid: string, callbackTypeCode: string) {
    return this.httpClient
      .post(`eventNotification/presigningParticipant`, {
        packageUserGuid: packageUserGuid,
        callbackTypeCode: callbackTypeCode,
      })
      .pipe(
        delayedRetry(500, 3),
        catchError((res) => {
          return throwError(() => new Error(res));
        })
      );
  }
}
