import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import * as OT from '@opentok/client';

import { combineLatest } from 'rxjs';
import { catchError, take, tap } from 'rxjs/operators';

import { NotificationsActions, NotificationType } from 'src/app/features/notifications';
import { ExceptionType } from 'src/app/features/notifications/models';
import { RootStoreState } from 'src/app/store';
import { v4 as uuid } from 'uuid';

import { VideoLayout } from '../../enums';
import { OpentokService, VideoLayoutService } from '../../services';
import { VideoSelectors } from '../../store';
import { VideoActions } from '../../store/actions/index';

@Component({
  selector: 'app-video-list',
  templateUrl: './video-list.component.html',
  styleUrls: ['./video-list.component.scss'],
})
export class VideoListComponent implements OnInit, OnDestroy {
  activeLayout: VideoLayout;
  availableLayouts = VideoLayout;
  videoSession: OT.Session;
  streams: Array<OT.Stream> = [];

  constructor(
    private readonly videoLayoutService: VideoLayoutService,
    private readonly opentokService: OpentokService,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly store: Store<RootStoreState.State>
  ) {}

  ngOnInit() {
    this.handleVideoCountChange(this.streams.length);

    this.opentokService
      .initSession()
      .pipe(
        take(1),
        tap((session: OT.Session) => {
          this.videoSession = session;
          this.configureSessionListeners();
          this.connectToSession();
        }),
        catchError((err) => {
          throw err.message;
        })
      )
      .subscribe();
  }

  configureSessionListeners() {
    this.videoSession.on('streamCreated', (event) => {
      this.streams.push(event.stream);
      this.handleVideoCountChange(this.streams.length);
      this.changeDetectorRef.detectChanges();
    });

    this.videoSession.on('streamDestroyed', (event) => {
      combineLatest([
        this.store.select(VideoSelectors.getStopVideo),
        this.store.select(VideoSelectors.getIgnoreParticipantVideoStream),
      ])
        .pipe(
          tap(([stopVideo, ignoreParticipantVideoStream]) => {
            if (stopVideo || ignoreParticipantVideoStream) {
              return;
            } else {
              const streamId = event.stream.streamId;
              this.store.dispatch(VideoActions.SendVideoStreamDestroyed({ payload: { videoStreamId: streamId } }));
            }
          }),
          take(1)
        )
        .subscribe();

      const idx = this.streams.indexOf(event.stream);
      if (idx > -1) {
        this.streams.splice(idx, 1);
        this.changeDetectorRef.detectChanges();
      }
    });

    // need to dispatch action to notify that somone disconnected: Revisit
    this.videoSession.on('sessionDisconnected', (event) => {
      if (event.reason.toLowerCase() === 'networkdisconnected') {
        this.store.dispatch(
          NotificationsActions.AddNotification({
            payload: {
              notificationType: NotificationType.Error,
              id: uuid(),
              text: `TokBox connection lost due to internet connection.`,
              exceptionType: ExceptionType.CannotProceed,
              logInAppInsights: true,
            },
          })
        );
      }
    });
  }

  connectToSession() {
    this.opentokService.connect().catch((err) => {
      if (err.name === 'OT_NOT_CONNECTED') {
        this.store.dispatch(
          NotificationsActions.AddNotification({
            payload: {
              notificationType: NotificationType.Error,
              id: uuid(),
              text: `TokBox Session connect error: ${err.message}`,
              exceptionType: ExceptionType.CannotProceed,
              logInAppInsights: true,
            },
          })
        );
      }
      console.error(err);
    });
  }

  handleVideoCountChange(newVideoCount: number) {
    this.activeLayout = this.videoLayoutService.calculateLayout(newVideoCount + 1);
  }

  ngOnDestroy() {
    if (this.videoSession) {
      this.videoSession.disconnect();
      this.changeDetectorRef.detectChanges();
    }
  }
}
