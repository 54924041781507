import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';

import { combineLatest, Observable, Subscription } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { CheckInActions } from 'src/app/features/check-in';
import { CheckInSelectors } from 'src/app/features/check-in/store';
import { DeviceGroupActions, DeviceGroupSelectors } from 'src/app/features/device-group';
import { PackageUser, PackageUsersSelectors } from 'src/app/features/package-users';
import { PackagesSelectors } from 'src/app/features/packages';
import { RootStoreState } from 'src/app/store';

import { LobbyActions, LobbySelectors } from '../../store';

@Component({
  selector: 'app-lobby-participants-list',
  templateUrl: './lobby-participants-list.component.html',
  styleUrls: ['./lobby-participants-list.component.scss'],
})
export class LobbyParticipantsListComponent implements OnInit, OnDestroy {
  @Input() isReadonly = false;
  @Input() signingAgent: PackageUser;
  @Input() nonSigningAgentsExceptWitnesses: PackageUser[];
  @Input() witnesses: PackageUser[];

  isUserSigningAgent: boolean;
  isNewSession: boolean;
  getIsSigningAgentSubscription: Subscription;
  usersOnDeviceSubscription: Subscription;
  isCheckinCompleted$: Observable<boolean>;
  isNonSigningAgentClickable$: Observable<boolean>;

  constructor(private readonly store: Store<RootStoreState.State>) {}

  ngOnInit(): void {
    this.getIsSigningAgentSubscription = this.store
      .pipe(
        select(PackageUsersSelectors.getIsActivePackageUserSigningAgent),
        tap((isSigningAgent) => (this.isUserSigningAgent = isSigningAgent))
      )
      .subscribe();

    this.usersOnDeviceSubscription = this.store
      .pipe(select(DeviceGroupSelectors.getUsersOnDevice))
      .subscribe((usersOnDevice) => {
        this.isNewSession = usersOnDevice?.length === 0;
      });

    if (this.isReadonly) {
      this.isCheckinCompleted$ = combineLatest([
        this.store.select(LobbySelectors.isCheckinCompleted),
        this.store.select(PackageUsersSelectors.getPackageUsers),
        this.store.select(CheckInSelectors.getUsersViewedWaitingRoom),
      ]).pipe(map(([isCheckInCompleted, users, usersViewedWaitingRoom]) => isCheckInCompleted && users.length === usersViewedWaitingRoom.length))
    } else {
      this.isCheckinCompleted$ = this.store.select(LobbySelectors.isCheckinCompleted);
    }

    this.isNonSigningAgentClickable$ = combineLatest([
      this.store.pipe(select(PackagesSelectors.isRONOrKRON)),
      this.store.pipe(select(PackageUsersSelectors.getIsActivePackageUserSigningAgent)),
    ]).pipe(
      map(
        ([isRonOrKron, isSigningAgent]) =>
          !this.isReadonly && ((isRonOrKron && !isSigningAgent) || !isRonOrKron)
      )
    );
  }

  ngOnDestroy(): void {
    this.getIsSigningAgentSubscription.unsubscribe();
    this.usersOnDeviceSubscription.unsubscribe();
  }

  beginSigningAgentCheckin(participant: PackageUser) {
    if (this.isUserSigningAgent) {
      this.store.dispatch(
        DeviceGroupActions.SaveToDeviceGroup({
          payload: { packageUserGuid: participant.packageUserGuid },
        })
      );

      this.store.dispatch(
        LobbyActions.StartCheckIn({
          payload: { packageUserGuid: participant.packageUserGuid },
        })
      );
    }
  }

  openParticipantPinModal(participant: PackageUser) {
    this.store.dispatch(
      CheckInActions.PromptUserForAuthentication({
        payload: {
          participant,
        },
      })
    );
  }
}
