import { NgModule } from '@angular/core';

import { IfFeatureEnabledDirective } from './if-feature-enabled/if-feature-enabled.directive';
import { IfFeatureNotEnabledDirective } from './if-feature-not-enabled/if-feature-not-enabled.directive';
import { RemoveIfCachedFeatureDisableDirective } from './remove-if-cached-feature-disabled.directive';
import { RemoveIfCachedFeatureEnabledDirective } from './remove-if-cached-feature-enabled.directive';
import { RemoveIfFeatureDisableDirective } from './remove-if-feature-disabled.directive';
import { RemoveIfFeatureEnabledDirective } from './remove-if-feature-enabled.directive';

const directives = [
  IfFeatureEnabledDirective,
  IfFeatureNotEnabledDirective,
  RemoveIfCachedFeatureDisableDirective,
  RemoveIfCachedFeatureEnabledDirective,
  RemoveIfFeatureDisableDirective,
  RemoveIfFeatureEnabledDirective,
];

@NgModule({
  imports: [],
  declarations: [...directives],
  exports: [...directives],
})
export class FeatureManagementDirectiveModule {}
