<img
  class="feedback-image"
  src="../../../assets/images/mobile-question-mark.svg"
  data-testid="unsupported-browser"
  alt=""
/>
<div class="message-container">
  <h1 class="message-container__header">Browser not supported</h1>
  <div class="message-container__instructions">
    You'll need the latest version of <strong>one</strong> of these:
  </div>
  <div>
    <img
      class="browser-images"
      src="/assets/images/chrome-icon.png"
      alt="Clicking this will bring you to the Chrome download page."
    />
    <span>Chrome (recommended)</span>
    <a href="https://www.google.com/chrome/" rel="noopener" data-testid="unsupported-browser-chrome">Download</a>
  </div>
  <div>
    <img
      class="browser-images"
      src="/assets/images/safari-icon.png"
      alt="Clicking this will bring you to the Safari download page."
    />
    <span>Safari</span>
    <a href="https://support.apple.com/downloads/safari/" rel="noopener" data-testid="unsupported-browser-safari">Download</a>
  </div>
  <div>
    <img
      class="browser-images"
      src="/assets/images/edge-icon.png"
      alt="Clicking this will bring you to the Edge download page."
    />
    <span>Edge</span>
    <a href="https://www.microsoft.com/edge/" rel="noopener" data-testid="unsupported-browser-edge">Download</a>
  </div>
  <app-further-assistance [showDefaultClientInfo]="true"></app-further-assistance>
</div>
