import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { SignalRService } from 'src/app/features/signal-r';
import { RootStoreState } from 'src/app/store';

import {
  // TODO: User Story 50434: Remove Old Endorsement Application Logic
  EndorsementApplication,
  OutgoingEndorsementAttempt,
  // End TODO
  OutgoingEraseSignedEndorsement,
  OutgoingSignEndorsement,
  PackageUserEndorsementImages,
  SystemEndorsementImage,
  SystemEndorsementImages,
  SystemFieldStatus,
  UniqueEndorsementImage,
} from '../models';
import { EndorsementsActions } from '../store/actions';

@Injectable()
export class EndorsementsService {
  private listenerConfigured = false;

  constructor(
    private readonly http: HttpClient,
    private readonly store: Store<RootStoreState.State>,
    private readonly signalRService: SignalRService
  ) {}

  public getEndorsementImages(packageUserGuid: string) {
    return this.http.get<{
      packageEndorsements: Array<PackageUserEndorsementImages>;
    }>(`endorsements/packageUsers/${packageUserGuid}`);
  }

  public getUpdatedEndorsementImage(endorsementId: number, packageUserGuid: string) {
    return this.http.get(`endorsements/${endorsementId}/image/packageUsers/${packageUserGuid}`);
  }

  public getUniqueEndorsementImages() {
    return this.http.get<{
      packageEndorsementFreeTextImages: Array<UniqueEndorsementImage>;
    }>(`endorsements/freeTextImages`);
  }

  public getSystemEndorsementImages() {
    return this.http.get<{
      packageSystemEndorsements: Array<SystemEndorsementImages>;
    }>(`endorsements/systemImages`);
  }

  // TODO: User Story 50434: Remove Old Endorsement Application Logic
  public attemptEndorsement(
    endorsementAttempt: OutgoingEndorsementAttempt,
    deviceCode: string,
    isPresign: boolean
  ) {
    return this.http.post('endorsements/attempt', {
      documentEndorsementLocationId: endorsementAttempt.endorsementLocationId,
      packageUserGuid: endorsementAttempt.packageUserGuid,
      endorsementText: endorsementAttempt.data,
      optional: endorsementAttempt.optional,
      deviceCode,
      certificatePassword: endorsementAttempt.certificatePassword,
      isPresign,
    });
  }

  public signEndorsement(signedEndorsement: OutgoingSignEndorsement) {
    return this.http.post('endorsements/signed', {
      documentEndorsementLocationId: signedEndorsement.endorsementLocationId,
      packageUserGuid: signedEndorsement.packageUserGuid,
      endorsementText: signedEndorsement.data,
      optional: signedEndorsement.optional,
    });
  }

  public eraseEndorsement(erasedEndorsement: OutgoingEraseSignedEndorsement) {
    return this.http.put('endorsements/erased', {
      documentEndorsementLocationId: erasedEndorsement.endorsementLocationId,
      packageUserGuid: erasedEndorsement.packageUserGuid,
      optional: erasedEndorsement.optional,
    });
  }

  public applySystemEndorsements(packageUserGuid: string) {
    return this.http.post('endorsements/system', {
      packageUserGuid,
    });
  }

  public getSystemFieldStatus(): Observable<SystemFieldStatus> {
    return this.http.get<SystemFieldStatus>(`endorsements/system/status`);
  }

  public auditEndorsements() {
    return this.http.put(`endorsements/audit`, {});
  }

  public signSystemEndorsements() {
    return this.http.post(`endorsements/system/signing`, {});
  }

  public signSystemEndorsementsPresign() {
    return this.http.post(`endorsements/system/signing`, {});
  }

  // TODO: User Story 50434: Remove Old Endorsement Application Logic
  public sendEndorsementApplicationCancelled(endorsementApplication: EndorsementApplication) {
    return this.http.post('endorsements/endorsementApplicationCancelled', endorsementApplication);
  }

  // TODO: User Story 50434: Remove Old Endorsement Application Logic
  public configureListeners() {
    if (!this.listenerConfigured) {
      this.listenerConfigured = true;
      this.signalRService.hubConnection.on('BroadcastEndorsementAttemptEvent', (Data) => {
        this.store.dispatch(
          EndorsementsActions.BroadcastSetEndorsementAttempted({
            payload: {
              endorsementLocationId: Number(Data.documentEndorsementLocationId),
              packageUserGuid: Data.packageUserGuid,
            },
          })
        );
      });

      // TODO: User Story 50434: Remove Old Endorsement Application Logic
      this.signalRService.hubConnection.on('ConfirmEndorsementEvent', (Data) => {
        this.store.dispatch(
          EndorsementsActions.SetEndorsementConfirmed({
            payload: {
              endorsementLocationId: Number(Data.documentEndorsementLocationId),
              packageUserGuid: Data.packageUserGuid,
            },
          })
        );
      });

      this.signalRService.hubConnection.on('ConfirmSignEndorsementEvent', (Data) => {
        this.store.dispatch(
          EndorsementsActions.SetEndorsementSigned({
            payload: {
              endorsementLocationId: Number(Data.documentEndorsementLocationId),
              packageUserGuid: Data.packageUserGuid,
            },
          })
        );
      });

      // TODO: User Story 50434: Remove Old Endorsement Application Logic
      this.signalRService.hubConnection.on('EndorsementAttemptRollbackEvent', (Data) => {
        this.store.dispatch(
          EndorsementsActions.BroadcastRollbackEndorsementAttempt({
            payload: {
              endorsementLocationId: Number(Data.documentEndorsementLocationId),
              packageUserGuid: Data.packageUserGuid,
            },
          })
        );
      });

      this.signalRService.hubConnection.on('BroadcastRollbackEndorsementEvent', (Data) => {
        this.store.dispatch(
          EndorsementsActions.BroadcastRollbackEndorsement({
            payload: {
              endorsementLocationId: Number(Data.documentEndorsementLocationId),
              packageUserGuid: Data.packageUserGuid,
            },
          })
        );
      });

      this.signalRService.hubConnection.on('EndorsementImageUpdatedEvent', (Data) => {
        this.store.dispatch(
          EndorsementsActions.EndorsementImageUpdated({
            payload: {
              endorsementId: Data.documentEndorsementLocationId,
              packageUserGuid: Data.packageUserGuid,
            },
          })
        );
      });

      // TODO: User Story 50434: Remove Old Endorsement Application Logic
      this.signalRService.hubConnection.on('EndorsementApplicationStartedEvent', (Data) => {
        this.store.dispatch(
          EndorsementsActions.EndorsementApplicationStarted({
            payload: {
              endorsementLocationId: Data.documentEndorsementLocationId,
              packageUserGuid: Data.packageUserGuid,
            },
          })
        );
      });

      this.signalRService.hubConnection.on('EndorsementProcessingStartedEvent', (Data) => {
        this.store.dispatch(
          EndorsementsActions.EndorsementProcessingStartedEvent({
            payload: {
              endorsementLocationId: Data.documentEndorsementLocationId,
              packageUserGuid: Data.packageUserGuid,
            },
          })
        );
      });

      // TODO: User Story 50434: Remove Old Endorsement Application Logic
      this.signalRService.hubConnection.on('EndorsementApplicationFinishedEvent', (Data) => {
        this.store.dispatch(
          EndorsementsActions.EndorsementApplicationFinished({
            payload: {
              endorsementLocationId: Data.documentEndorsementLocationId,
              packageUserGuid: Data.packageUserGuid,
            },
          })
        );
      });

      this.signalRService.hubConnection.on('EndorsementProcessingFinishedEvent', (Data) => {
        this.store.dispatch(
          EndorsementsActions.EndorsementProcessingFinishedEvent({
            payload: {
              endorsementLocationId: Data.documentEndorsementLocationId,
              packageUserGuid: Data.packageUserGuid,
            },
          })
        );
      });

      this.signalRService.hubConnection.on('BroadcastSystemEndorsementsAppliedEvent', () => {
        this.store.dispatch(
          EndorsementsActions.SetSystemFieldStatus({
            payload: { areApplied: true, failedToApply: false },
          })
        );
      });

      this.signalRService.hubConnection.on('BroadcastAllEndorsementsSignedEvent', () => {
        this.store.dispatch(
          EndorsementsActions.SetAllEndorsementsSigned({
            payload: true,
          })
        );
      });
    }
  }
}
