import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { ModalsModule } from 'src/app/features/modals/modals.module';
import { SharedModule } from 'src/app/features/shared';

import { NonSigningAgentWelcomeComponent, SigningAgentWelcomeComponent } from './components';
import { CheckInTasksResolver, PresignCheckInTasksResolver } from './resolvers';
import { WizardService } from './services';
import { ConsentCheckBoxComponent } from './shared/consent-check-box/consent-check-box.component';
import { WizardStepWrapperComponent } from './shared/wizard-step-wrapper/wizard-step-wrapper.component';
import { WizardEffects, WizardReducers } from './store';
import { AbstractWizardStepComponent } from './wizard-flow';

@NgModule({
  declarations: [
    AbstractWizardStepComponent,
    NonSigningAgentWelcomeComponent,
    SigningAgentWelcomeComponent,
    ConsentCheckBoxComponent,
    WizardStepWrapperComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    ModalsModule,
    SharedModule,
    StoreModule.forFeature('wizard', WizardReducers.Reducers.wizardReducer),
    EffectsModule.forFeature([WizardEffects.WizardEffects]),
  ],
  exports: [
    NonSigningAgentWelcomeComponent,
    SigningAgentWelcomeComponent,
    ConsentCheckBoxComponent,
    WizardStepWrapperComponent,
  ],
  providers: [CheckInTasksResolver, PresignCheckInTasksResolver, WizardService],
})
export class WizardModule {}
