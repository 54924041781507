import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';

import { combineLatest, interval, Subject } from 'rxjs';
import { filter, take, takeUntil, tap } from 'rxjs/operators';
import { Feature } from 'src/app/features/feature-management/models';
import { FeatureManagementService } from 'src/app/features/feature-management/services';

import { ModalsSelectors } from 'src/app/features/modals';
import { ProductType } from 'src/app/features/packages/models';
import { PackagesSelectors } from 'src/app/features/packages/store/selectors';
import { ParticipantVerificationSelectors } from 'src/app/features/participant-verification/store/selectors';
import { WaitingOnOtherParticipantsModalComponent } from 'src/app/features/video/components/waiting-on-other-participants-modal/waiting-on-other-participants-modal.component';
import { RootStoreState } from 'src/app/store';

import { ModalsActions } from '../../../modals/store/actions';
import { VideoIssueModals } from '../../enums';
import { VideoConnectionModel } from '../../models';
import { VideoActions, VideoSelectors } from '../../store';
import { AudioVideoFailureModalComponent } from '../audio-video-failure-modal';
import { DeviceInUseModalComponent } from '../device-in-use-modal/device-in-use-modal.component';
import { RefreshNeededModalComponent } from '../refresh-needed-modal/refresh-needed-modal.component';
import { VideoProblemsWithDeviceModalComponent } from '../video-device-issues-modal';
import { VideoSignalDegradedModalComponent } from '../video-signal-degraded-modal/video-signal-degraded-modal.component';

@Component({
  selector: 'app-video-issue',
  template: '',
})
export class VideoIssueComponent implements OnInit, OnDestroy {
  notifier = new Subject();
  pollingInterval$ = interval(5000).pipe(
    tap(() => {
      this.store.dispatch(VideoActions.GetAreAllVideoStreamsConnected());

      combineLatest([
        this.store.select(VideoSelectors.getVideoConnection),
        this.store.select(VideoSelectors.getAreAllVideoStreamsConnected),
        this.store.select(VideoSelectors.getStopVideo),
        this.store.select(ModalsSelectors.getModalComponent),
        this.store.select(ParticipantVerificationSelectors.doesIdVerificationExist),
        this.store.select(PackagesSelectors.getProductType),
      ])
        .pipe(
          take(1),
          filter(
            ([videoConnection, areAllVideoStreamsConnected, stopVideo, modalComponent]) =>
              !!videoConnection && !areAllVideoStreamsConnected && !stopVideo && !modalComponent
          ),
          tap(
            ([
              videoConnection,
              _,
              __,
              ___,
              isParticipantVerificationPresent,
              productType,
            ]) => {
              const modalType = this.getModalType(videoConnection);
              if (
                modalType &&
                (isParticipantVerificationPresent || productType === ProductType.KRON)
              ) {
                this.store.dispatch(modalType);
              }
            }
          )
        )
        .subscribe();
    }),
    takeUntil(this.notifier)
  );

  constructor(
    private readonly store: Store<RootStoreState.State>,
    private readonly featureManagementService: FeatureManagementService
  ) {}

  ngOnInit() {
    this.pollingInterval$.subscribe();
  }

  getModalType(videoConnection: VideoConnectionModel) {
    const isSigningRoomActionBlockingEnabled =
      this.featureManagementService.getIsFeatureEnabledWithCaching(
        Feature.SigningRoomActionBlocking
      );

    if (!!videoConnection && !videoConnection.isConnected) {
      switch (videoConnection.modalType) {
        case VideoIssueModals.DeviceInUse: {
          return ModalsActions.SetStandaloneModalComponent({
            payload: {
              component: DeviceInUseModalComponent,
              componentData: { isPublishingError: videoConnection.isPublisher },
            },
          });
        }
        case VideoIssueModals.VideoSignalDegraded: {
          return ModalsActions.SetStandaloneModalComponent({
            payload: {
              component: VideoSignalDegradedModalComponent,
            },
          });
        }
        case VideoIssueModals.AudioVideoFailure: {
          return ModalsActions.SetStandaloneModalComponent({
            payload: {
              component: AudioVideoFailureModalComponent,
              componentData: { isPublishingError: videoConnection.isPublisher },
            },
          });
        }
        case VideoIssueModals.RefreshNeeded: {
          return ModalsActions.SetStandaloneModalComponent({
            payload: {
              component: RefreshNeededModalComponent,
              componentData: { isPublishingError: videoConnection.isPublisher },
            },
          });
        }
        default: {
          return ModalsActions.SetStandaloneModalComponent({
            payload: {
              component: VideoProblemsWithDeviceModalComponent,
              componentData: { isPublishingError: videoConnection.isPublisher },
            },
          });
        }
      }
      // TODO Story #62143 : Remove SigningRoomActionBlocking Feature flag code
    } else if (
      !!videoConnection &&
      videoConnection.isConnected &&
      !isSigningRoomActionBlockingEnabled
    ) {
      return ModalsActions.SetStandaloneModalComponent({
        payload: {
          component: WaitingOnOtherParticipantsModalComponent,
        },
      });
    }
    return undefined;
  }

  ngOnDestroy() {
    this.notifier.next(undefined);
    this.notifier.complete();
  }
}
