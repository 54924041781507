export interface State {
  isEndSessionSuccessful: boolean;
  isEndSessionFailure: boolean;
  isSessionEnded: boolean;
  isComplete: boolean;
  signingEndSessionStarted: boolean;
}

export const initialState: State = {
  isEndSessionSuccessful: false,
  isEndSessionFailure: false,
  isSessionEnded: false,
  isComplete: false,
  signingEndSessionStarted: false,
};
