<img
  class="feedback-image"
  src="../../../assets/images/support-cog.svg"
  alt=""
  data-testid="kba-fail-pic"
/>
<div class="message-container">
  <h1 class="message-container__header" data-testid="kba-fail-header">{{ title }}</h1>
  <p class="message-container__message" data-testid="kba-fail-text">
    {{ message }} For your security, your signing session has been canceled and you'll need to
    reschedule your closing.
  </p>
  <p class="message-container__message" data-testid="call-support-text">
    To reschedule your signing session and for more information please call support:
    <app-support-phone></app-support-phone>
  </p>
</div>
