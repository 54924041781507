<div class="signing-details" *appMobileOnly>
  <h2>Welcome to your signing</h2>
  <app-signing-details-card></app-signing-details-card>
</div>

<div class="container">
  <img class="container__image" src="assets/images/amrock-house.svg" alt="" />
  <h2 class="container__title">Time to check into your signing</h2>
  <p class="container__message">
    Your security is important to us, as such we have a quick check-in process that you'll be going
    through.
  </p>
  <div class="button-container">
    <app-button
      class="button-container__next"
      type="primary"
      (click)="nextClick.emit()"
      [dataTestId]="'next-button-landing'"
    >
      Let's Get Started
    </app-button>
  </div>
</div>
