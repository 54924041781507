import { PackageStatus } from 'src/app/features/packages/models';

import { PackageUser } from 'src/app/features/package-users';

export interface State {
  packageStatus: PackageStatus;
  packageUsers: Array<PackageUser>;
  currentPackageUserLobbyGuid: string;
}

export const initialState: State = {
  packageStatus: null,
  packageUsers: null,
  currentPackageUserLobbyGuid: null,
};
