import { Injectable } from '@angular/core';
import * as signalR from '@microsoft/signalr';
import { Store } from '@ngrx/store';

import { from } from 'rxjs';

import { environment } from 'src/environments/environment';

import { SignalRActions } from '../store/actions';

@Injectable({
  providedIn: 'root',
})
export class SignalRService {
  public signalR = signalR;
  public hubConnection: signalR.HubConnection;
  constructor(private readonly store: Store<any>) {}

  public initializeHubConnection(packageUserGuid) {
    if (this.hubConnection) {
      this.store.dispatch(
        SignalRActions.AttemptSignalRRoomConnection({
          payload: { packageUserGuid },
        })
      );
      return;
    }

    this.hubConnection = new this.signalR.HubConnectionBuilder()
      .withUrl(`${environment.apiBaseUrl}signingHub`)
      // Attempt to reconnect immediately and then again in 3s, 5s, 10s, 15s, 30s and then give up.
      .withAutomaticReconnect([0, 3000, 5000, 10000, 15000, 30000, null])
      .configureLogging(signalR.LogLevel.Error)
      .build();

    // When SignalR is attempting to reconnect.
    this.hubConnection.onreconnecting((err?: Error) => this.handleReconnecting(err));

    // When SignalR has reconnected.
    this.hubConnection.onreconnected((connectionId: string) =>
      this.handleReconnected(connectionId, packageUserGuid)
    );

    // When SignalR has lost connection and cannot recover.
    this.hubConnection.onclose((err?: Error) => this.handleClose(err));

    this.store.dispatch(SignalRActions.StartSignalRHubConnection({ payload: { packageUserGuid } }));
  }

  public startSignalRHubConnection() {
    return from(this.hubConnection.start());
  }

  public joinRoom(packageUserGuid: string) {
    return from(this.hubConnection.invoke('JoinRoom', packageUserGuid));
  }

  public leaveRoom(packageUserGuid: string) {
    return from(this.hubConnection.invoke('LeaveRoom', packageUserGuid));
  }

  public stopConnection() {
    this.hubConnection.stop();
  }

  public handleReconnecting(error) {
    let errorMsg = '';

    if (typeof error !== 'undefined') {
      errorMsg = error.message;
    }

    this.store.dispatch(
      SignalRActions.SignalRConnectionReconnecting({
        payload: { errorMessage: errorMsg },
      })
    );
  }

  public handleReconnected(connectionId: string, packageUserGuid: string) {
    this.store.dispatch(
      SignalRActions.SignalRConnectionReconnected({
        payload: { connectionId, packageUserGuid },
      })
    );
  }

  public handleClose(error) {
    let errorMsg = '';

    if (typeof error !== 'undefined') {
      errorMsg = error.message;
    }

    this.store.dispatch(
      SignalRActions.SignalRConnectionClosed({
        payload: { errorMessage: errorMsg },
      })
    );
  }
}
