import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { DuplicateSessionDetectorService } from 'src/app/features/duplicate-session-detector';

import { SigningSessionLayoutOptions } from './signing-session-layout-options.model';

@Component({
  selector: 'app-signing-session-layout',
  templateUrl: './signing-session-layout.component.html',
  styleUrls: ['./signing-session-layout.component.scss'],
})
export class SigningSessionLayoutComponent implements OnDestroy, OnInit {
  singleDeviceOptions$: Observable<SigningSessionLayoutOptions>;
  public useAudioVideoConnectionMonitor = false;

  constructor(
    private readonly route: ActivatedRoute,
    private readonly dupDetectorService: DuplicateSessionDetectorService
  ) {}

  ngOnInit() {
    this.useAudioVideoConnectionMonitor = this.shouldUseAudioVideoConnectionMonitor();

    this.singleDeviceOptions$ = this.route.data.pipe(
      map((data) => {
        if (!!data) {
          return data as SigningSessionLayoutOptions;
        }

        return {
          hideMenu: false,
        } as SigningSessionLayoutOptions;
      })
    );

    if (this.shouldUseDuplicateSessionDetector()) {
      this.dupDetectorService.enable();
    } else {
      this.dupDetectorService.disable();
    }
  }

  ngOnDestroy() {
    this.dupDetectorService.disable();
  }

  shouldUseAudioVideoConnectionMonitor(): boolean {
    return (
      this.route.snapshot?.data && !!this.route.snapshot.data['useAudioVideoConnectionMonitor']
    );
  }

  shouldUseDuplicateSessionDetector(): boolean {
    return this.route.snapshot?.data && !!this.route.snapshot.data['useDuplicateSessionDetector'];
  }

}
