import { createAction, props } from '@ngrx/store';

import {
  Commission,
  County,
  DynamicCommissionStamp,
  VerifyCertificatePasswordRequestModel,
  VerifyCertificatePasswordResponseModel
} from '../../models';

export const GetCommissions = createAction(
  '[Commissions] Retreiving Commissions'
);

export const SetSelectedCommission = createAction(
  '[Commissions] Set Selected Commission',
  props<{ payload: Commission }>()
);

// Confirmed means the commission has been applied to the order.
export const SetConfirmedCommission = createAction(
  '[Commission] Set Confirmed Commission',
  props<{ payload: Commission }>()
);

export const SetSelectedCommissionByStateCode = createAction(
  '[Commissions] Set Selected Commission by StateCode',
  props<{ stateCode: string }>()
);

export const GetCommissionsSuccess = createAction(
  '[Commissions] Successfully Retrieved Commissions',
  props<{ payload: Commission[] }>()
);

export const GetCountiesSuccess = createAction(
  '[Commissions] Successfully Retreived Counties by StateCode',
  props<{ payload: County[] }>()
);

export const ApplyCommissionToOrder = createAction(
  '[Commissions] Applying Commission to Order'
);

export const ApplyCommissionToOrderSuccess = createAction(
  '[Commissions] Applied the Commission to the Order Successfully'
);

export const SetSelectedCounty = createAction(
  '[Commissions] Set Selected County',
  props<{ payload: { county: string } }>()
);

export const SetDynamicCommissionStampsRequired = createAction(
  '[Commissions] Set Dynamic Commission Stamps Required',
  props<{ payload: { stateCode: string; dynamicStampsRequired: boolean } }>()
);

export const SetDynamicCommissionStamp = createAction(
  '[Commissions] Set Dynamic Commission Stamp',
  props<{ payload: { stateCode: string; stamp: DynamicCommissionStamp } }>()
);

export const FetchSelectedCommission = createAction(
  '[Commissions] Get Selected Commission'
);

export const NavigateToUpdateCommissionsPage = createAction(
  '[Commissions] Navigate to Update Commissions Page'
);

export const SaveCertificateDetails = createAction(
  '[Commissions] Saves Certificate Details',
  props<{ payload: { password: string; stateCode: string }; }>()
);

export const SetCertificateCredentialsSaved = createAction(
  '[Commissions] Set Certificate Credentials Saved'
);

export const CertificatePasswordVerificationReset = createAction('[Commissions] Certificate Password Validation Reset');

export const CertificatePasswordLimitExceeded = createAction(
  '[Commissions] Certificate Password Limit Exceeded'
);

export const CertificateExpired = createAction('[Commissions] Digital Certificate Expired');

export const ShowSACertificatePasswordLimitExceeded = createAction(
  '[Commissions] Show SA Certificate Password Limit Exceeded'
);

export const VerifyCertificatePassword = createAction(
  '[Commissions] Certificate Password Validation',
  props<{ payload: VerifyCertificatePasswordRequestModel }>()
);

export const CertificatePasswordVerificationPass = createAction(
  '[Commissions] Certificate Password Validation Passed',
  props<{
    payload: { passwordVerification: VerifyCertificatePasswordResponseModel; packageUserGuid: string };
  }>()
);

export const CertificatePasswordVerificationFail = createAction(
  '[Commissions] Certificate Password Validation Failed',
  props<{
    payload: { passwordVerification: VerifyCertificatePasswordResponseModel; packageUserGuid: string };
  }>()
);
