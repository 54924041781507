import { createAction, props } from '@ngrx/store';

import { PackageUser } from 'src/app/features/package-users';

import { CheckInTask, CheckInTaskCode } from '../../models';

export const SetActiveWizardUser = createAction(
  '[Wizard] Set Active Wizard User',
  props<{ payload: { user: PackageUser } }>()
);

export const UpdateActiveWizardUserDetails = createAction(
  '[Wizard] Update Active Wizard User Details',
  props<{ payload: { user: Partial<PackageUser> } }>()
);

export const ClearActiveWizardUser = createAction('[Wizard] Clear Active Wizard User');

export const FetchCheckInTasks = createAction('[Wizard] Fetch Check-in Tasks');

export const FetchPresignCheckInTasks = createAction('[Wizard] Fetch Pre-Sign Check-in Tasks');

export const FetchTasksSuccessful = createAction(
  '[Wizard] Fetch Tasks Successful',
  props<{ payload: CheckInTask[] }>()
);

export const RemoveTask = createAction(
  '[Wizard] Remove Task',
  props<{ taskCode: CheckInTaskCode }>()
);

export const ResetTasks = createAction('[Wizard] Reset Tasks');

export const CompleteCurrentTask = createAction('[Wizard] Complete Current Task');

export const GoToPreviousTask = createAction('[Wizard] Go To Previous Task');
