import { Action, createReducer, on } from '@ngrx/store';
import { WizardActions } from '../actions';
import { WizardState } from '../state';

const reducer = createReducer(
  WizardState.initialState,
  on(WizardActions.SetActiveWizardUser, (state: WizardState.State, { payload }) => ({
    ...state,
    activeWizardUser: payload.user,
  })),
  on(WizardActions.UpdateActiveWizardUserDetails, (state: WizardState.State, { payload }) => ({
    ...state,
    activeWizardUser: {
      ...state.activeWizardUser,
      ...payload.user,
    },
  })),
  on(WizardActions.ClearActiveWizardUser, (state: WizardState.State) => ({
    ...state,
    activeWizardUser: null,
    tasks: [],
  })),
  on(WizardActions.ResetTasks, (state: WizardState.State) => ({
    ...state,
    tasks: [],
  })),
  on(WizardActions.FetchTasksSuccessful, (state: WizardState.State, { payload }) => {
    const firstIncompleteTaskIndex = payload.findIndex((t) => !t.isCompleted);
    return {
      ...state,
      currentTaskIndex: firstIncompleteTaskIndex,
      tasks: payload,
    };
  }),
  on(WizardActions.RemoveTask, (state: WizardState.State, { taskCode }) => {
    return {
      ...state,
      tasks: state.tasks.filter((t) => t.taskType !== taskCode),
    };
  }),
  on(WizardActions.CompleteCurrentTask, (state: WizardState.State) => {
    const nextIncompleteTaskIndex = state.tasks.findIndex(
      (t, idx) => idx > state.currentTaskIndex && !t.isCompleted
    );
    return {
      ...state,
      currentTaskIndex: nextIncompleteTaskIndex,
      tasks: state.tasks.map((t, idx) =>
        idx === state.currentTaskIndex ? { ...t, isCompleted: true } : t
      ),
    };
  }),
  on(WizardActions.GoToPreviousTask, (state: WizardState.State) => {
    return {
      ...state,
      currentTaskIndex: Math.max(0, state.currentTaskIndex - 1),
    };
  })
);

export function wizardReducer(state: WizardState.State | undefined, action: Action) {
  return reducer(state, action);
}
