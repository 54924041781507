import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';

import { RootStoreState } from '../../../store';
import { ModalsActions } from '../../modals';
import { MsalAuthActions } from '../../msal-auth';
import { PackageUsersActions } from '../../package-users';
import { PresignActions } from '../../pre-sign/store';
import { getPathForUrl } from '../../shared';
import { DEFAULT_APPLICATION_PHONE_NUMBER } from '../../shared/constants';
import { ExitSigningConfirmationComponent } from '../components';

@Component({
  selector: 'app-nav-bar-pre-sign',
  templateUrl: './nav-bar-pre-sign.component.html',
  styleUrls: ['./nav-bar-pre-sign.component.scss'],
})
export class NavBarPreSignComponent implements OnInit {
  isDashboard: boolean;
  isWizard: boolean;
  isSigningRoom: boolean;
  nexsysSupportNumber = DEFAULT_APPLICATION_PHONE_NUMBER;

  constructor(
    private readonly route: ActivatedRoute,
    private readonly store: Store<RootStoreState.State>
  ) {}

  ngOnInit() {
    const routePath = getPathForUrl(this.route.snapshot.url.toString());
    this.isDashboard = routePath === '';
    this.isWizard = routePath.includes('wizard');
    this.isSigningRoom = routePath.includes('signing-room');
  }

  logout() {
    this.store.dispatch(MsalAuthActions.Logout());
  }

  exitSigning() {
    if (this.isWizard) {
      this.store.dispatch(PackageUsersActions.ExitPackageUserPreSignSession());
      this.store.dispatch(PresignActions.ExitSigning());
    } else {
      this.store.dispatch(
        ModalsActions.SetModalComponent({
          payload: {
            component: ExitSigningConfirmationComponent,
            modalTitle: 'Exit Pre-Signing',
            allowManualClose: false,
          },
        })
      );
    }
  }
}
