import { Component, OnInit } from '@angular/core';

import { FeatureManagementService } from 'src/app/features/feature-management/services';
import { Feature } from 'src/app/features/feature-management/models';

import { EndorsementBase } from '../../models';

@Component({
  selector: 'app-stamp-endorsement',
  templateUrl: './stamp-endorsement.component.html',
  styleUrls: ['./stamp-endorsement.component.scss'],
})
export class StampEndorsementComponent extends EndorsementBase implements OnInit {
  constructor(private readonly featureManagementService: FeatureManagementService) {
    super();
  }

  protected isNewEndorsementApplicationEnabled: boolean;

  ngOnInit(): void {
    this.isNewEndorsementApplicationEnabled = this.featureManagementService.getIsFeatureEnabledWithCaching(Feature.NewEndorsementApplication);
  }

  endorsementClicked(): void {
    if (this.isNewEndorsementApplicationEnabled) {
      this.signEndorsement.emit({
        endorsementLocationId: this.endorsementLocation.documentEndorsementLocationId,
        endorsementType: this.endorsementLocation.endorsementTypeCode,
        packageUserGuid: this.endorsementLocation.packageUserGuid,
      });
    } else {
      // TODO: User Story 50434: Remove Old Endorsement Application Logic
      this.endorsementAttempted.emit({
        endorsementLocationId: this.endorsementLocation
          .documentEndorsementLocationId,
        endorsementType: this.endorsementLocation.endorsementTypeCode,
        packageUserGuid: this.endorsementLocation.packageUserGuid,
      });
    }
  }
}
