<span *ngIf="(remainingRequiredEndorsementTotal$ | async) > 0"
      data-testid="req-endorsement-actions-remaining"
  >Actions Remaining in this Document: {{ remainingRequiredEndorsementTotal$ | async }}/{{ requiredEndorsementTotal$ | async }}</span
>

<span *ngIf="(remainingRequiredEndorsementTotal$ | async) === 0"
      data-testid="req-endorsement-complete"
>
  Document Complete
</span>
