import { DeviceGroupState } from 'src/app/features/device-group/store';
import { DocumentsState } from 'src/app/features/documents';
import { EndorsementsState } from 'src/app/features/endorsements';
import { MsalAuthState } from 'src/app/features/msal-auth';
import { PackageUsersState } from 'src/app/features/package-users';
import { PackagesState } from 'src/app/features/packages';
import { PinValidationState } from 'src/app/features/pin-validation/store/state';
import { ClientState } from '../features/client';
import { ModalsState } from '../features/modals/store';
import { NotificationsState } from '../features/notifications';
import { RulesState } from '../features/rules/store/state';

export interface State {
  deviceGroupState: DeviceGroupState.State;
  documents: DocumentsState.State;
  endorsements: EndorsementsState.State;
  msalAuth: MsalAuthState.State;
  packageUsers: PackageUsersState.State;
  packages: PackagesState.State;
  pinValidationState: PinValidationState.State;
  notifications: NotificationsState.NotificationsState;
  rules: RulesState.State;
  modals: ModalsState.State;
  client: ClientState.State;
}
