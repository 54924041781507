import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { combineLatest, of } from 'rxjs';
import { filter, first, map, switchMap } from 'rxjs/operators';

import { DeviceGroupActions, DeviceGroupSelectors } from 'src/app/features/device-group';
import { PackageUsersSelectors } from 'src/app/features/package-users';

import { MsalAuthActions, MsalAuthSelectors, MsalAuthState } from '../store';

@Injectable({
  providedIn: 'root',
})
export class MsalUserAuthGuard {
  constructor(
    private readonly store: Store<MsalAuthState.State>,
    private readonly router: Router
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    this.store.dispatch(DeviceGroupActions.GetCurrentDeviceUsers());

    return combineLatest([
      this.store.select(MsalAuthSelectors.getIsUserVerified),
      this.store.select(MsalAuthSelectors.getLastVerifiedUserGuid),
      this.store.select(DeviceGroupSelectors.getUsersOnDevice),
    ]).pipe(
      filter(([_, __, usersOnDevice]) => !!usersOnDevice),
      first(),
      switchMap(([isVerified, lastVerifiedUserGuid, usersOnDevice]) => {
        if (isVerified) {
          return of(true);
        }

        const lastVerifiedUserIsOnDevice = usersOnDevice.some(
          (user) => user.packageUserGuid === lastVerifiedUserGuid
        );
        if (lastVerifiedUserIsOnDevice) {
          this.store.dispatch(
            MsalAuthActions.RefreshTokenForVerifiedUser({
              payload: {
                packageUserGuid: lastVerifiedUserGuid,
                startPage: `/#${state.url}`,
              },
            })
          );

          return this.store.pipe(
            select(PackageUsersSelectors.getActivePackageUser),
            filter((packageUser) => !!packageUser),
            first(),
            map(() => true)
          );
        }

        return of(
          this.router.createUrlTree([''], {
            queryParams: { package: route.queryParams['package'], user: route.queryParams['user'] },
          })
        );
      })
    );
  }
}
