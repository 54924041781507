import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';

import { SharedModule } from 'src/app/features/shared';

@Component({
  selector: 'app-av-check-guide',
  standalone: true,
  imports: [CommonModule, MatIconModule, SharedModule],
  templateUrl: './av-check-guide.component.html',
  styleUrl: './av-check-guide.component.scss',
})
export class AvCheckGuideComponent {
  @Input() showButton: boolean;
  @Input() showDetails: boolean;
  @Output() showDetailsChange = new EventEmitter<boolean>();

  toggleDetails() {
    this.showDetails = !this.showDetails;
    this.showDetailsChange.emit(this.showDetails);
  }
}
