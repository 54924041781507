<app-wizard-step-wrapper
  [showStep]="showStep"
  [stepTitle]="stepTitle$ | async"
  [formGroup]="formStep"
>
  <ng-container *ngIf="showStep || stepHasBeenShown" [ngSwitch]="signatureType$ | async">
    <app-draw-initials-step
      *ngSwitchCase="SignatureTypeEnum.Drawn"
      [user]="user"
      (stepComplete)="goToNextStep()"
      (previousStepClicked)="goToPreviousStep()"
    ></app-draw-initials-step>

    <app-select-initials
      *ngSwitchDefault
      [user]="user"
      (stepComplete)="goToNextStep()"
      (previousStepClicked)="goToPreviousStep()"
    ></app-select-initials>
  </ng-container>
</app-wizard-step-wrapper>
