import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';

import { combineLatest, of } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';

import { ConsentsService } from 'src/app/features/consents';
import { PackageUserRole } from 'src/app/features/package-users';
import { PackageUsersSelectors } from 'src/app/features/package-users/store/selectors';

import { ModalsActions } from '../features/modals';
import { PackagesSelectors } from '../features/packages';
import { ProductType } from '../features/packages/models';
import { RootStoreState } from '../store';

@Injectable({
  providedIn: 'root',
})
export class EulaGuard {
  constructor(
    private readonly consentsService: ConsentsService,
    private readonly router: Router,
    private readonly store: Store<RootStoreState.State>
  ) {}

  canActivate() {
    return combineLatest([
      this.store.select(PackageUsersSelectors.getActivePackageUserGuid),
      this.store.select(PackageUsersSelectors.getActivePackageUser),
      this.store.select(PackagesSelectors.getActivePackageGuid),
      this.store.select(PackagesSelectors.getProductType),
    ]).pipe(
      filter(
        ([activePackageUserGuid, activeUser, activePackageGuid, productType]) =>
          !!!activePackageUserGuid || !!activeUser
      ),
      switchMap(([activePackageUserGuid, activeUser, activePackageGuid, productType]) => {
        if (
          activeUser?.userRoleCode !== PackageUserRole.SIGNINGAGENT &&
          productType !== ProductType.KRON
        ) {
          return of(true);
        }
        this.store.dispatch(ModalsActions.ShowLoadingSpinner());
        return this.consentsService.getHasAcceptedEula().pipe(
          map((accepted) => {
            if (accepted) {
              this.store.dispatch(ModalsActions.HideLoadingSpinner());
              return true;
            }
            this.router.navigate(['/eula'], {
              queryParams: {
                package: activePackageGuid,
                user: activeUser.packageUserGuid,
              },
              replaceUrl: true,
            });
            return false;
          })
        );
      })
    );
  }
}
