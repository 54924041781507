<app-wizard-step-wrapper [showStep]="showStep" [stepTitle]="stepMetadata.stepName" [formGroup]="formStep">
  <app-multi-factor-challenge-options
    [user]="this.latestPackegeUser$ | async"
    *ngIf="(this.multiFactorInProgress$ | async) === false"
  ></app-multi-factor-challenge-options>

  <app-multi-factor-challenge-security-code
    [user]="this.latestPackegeUser$ | async"
    *ngIf="this.multiFactorInProgress$ | async"
  >
  </app-multi-factor-challenge-security-code>
</app-wizard-step-wrapper>
