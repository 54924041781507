import { createAction, props } from '@ngrx/store';
import { BrowserClientSupportOnBrowserLogRequestModel } from 'src/app/features/packages/models/browser-client-support-log-request.model';

import {
  Package,
  PackageScheduleStatus,
  PackageStatus,
  PackageValidationResult,
} from '../../models';
import { TypeLookup } from '../../models/type-lookup.model';

export const SetPackage = createAction('[Package] Set Package', props<{ payload: Package }>());

export const GetPackage = createAction(
  '[Package] Get Package',
  (payload?: { ignoreIfLoaded?: boolean }) => ({ payload })
);

export const GetPackageStatus = createAction('[Package] Get Package Status');

export const UpdatePackageStatus = createAction(
  '[Package] Update Package Status',
  props<{ payload: { status: PackageStatus } }>()
);

export const FetchOptOutReasons = createAction('[Package] Fetch Opt Out Reasons');

export const FetchOptOutReasonsSuccess = createAction(
  '[Package] Fetch Opt Out Reasons Success',
  props<{ payload: { typeLookups: TypeLookup[] } }>()
);

export const OptOutPackage = createAction(
  '[Package] Opt Out Package',
  props<{ payload: { categoryTypeCode: string; comments?: string } }>()
);

export const GetMonthlyClosings = createAction('[Package] Get Monthly Closings');

export const SetMonthlyClosings = createAction(
  '[Package] Set Monthly Closings',
  props<{ payload: { totalMonthlyClosings: number } }>()
);

export const CancelPackage = createAction(
  '[Package] Cancel Package',
  props<{ payload: { categoryTypeCode: string } }>()
);

export const GetPackageByGuid = createAction(
  '[Package] Get Package by Package Guid',
  props<{ payload: { packageGuid: string } }>()
);

export const FetchPackageScheduledStatus = createAction(
  '[Package] Get Package Scheduled Status by Package Guid',
  (payload?: { packageGuid?: string; user?: string }) => ({ payload })
);

export const FetchPackageScheduledStatusSuccess = createAction(
  '[Package] Get Package Status by Package Guid Success',
  props<{ payload: { statusCode: PackageScheduleStatus } }>()
);

export const GetPackageByActivePackageGuid = createAction(
  '[Package] Get Package by Active Package Guid',
  props<{ payload?: { ignoreIfLoaded: boolean } }>()
);

export const BeginSession = createAction('[Package] Begin Session');

export const ClearScheduleStatus = createAction('[Package] Set Null Package Status');

export const ValidatePackage = createAction(
  '[Package] Validate Package',
  props<{ payload: { packageGuid?: string; packageUserGuid: string } }>()
);

export const ValidatePackageSuccessful = createAction(
  '[Package] Validate Package Successfully',
  props<{ payload: PackageValidationResult }>()
);

export const SetPackageIsValid = createAction(
  '[Package] Set Validity of Package',
  props<{ payload: { isValid: boolean } }>()
);

export const RedirectInvalidPackageScheduleStatus = createAction(
  '[Package] Schedule Status Invalid',
  (payload?: { queryParams?: any }) => ({ payload })
);

export const RedirectUnsupportedBrowser = createAction(
  '[Package] Not Supported in this Browser',
  (payload?: { queryParams?: any }) => ({ payload })
);

export const RedirectUnsupportedOperatingSystem = createAction(
  '[Package] Not Supported on this Operating System',
  (payload?: { queryParams?: any }) => ({ payload })
);

export const RedirectUnsupportedDevice = createAction(
  '[Package] Not Supported on this Device',
  (payload?: { queryParams?: any }) => ({ payload })
);

export const RedirectSmartphonesUnsupported = createAction(
  '[Package] Not Supported on Smartphone',
  (payload?: { queryParams?: any }) => ({ payload })
);

export const RedirectInvalidUrl = createAction(
  '[Package] Invalid Url',
  (payload?: { queryParams?: any }) => ({ payload })
);

export const RedirectInValidLink = createAction(
  '[Package] Invalid Url',
  (payload?: { queryParams?: any }) => ({ payload })
);

export const RedirectExpiredLink = createAction('[Package] Expired Link');

export const RedirectGenericError = createAction('[Package] Generic Error Link');

export const RedirectOrderCancelled = createAction(
  '[Package] Redirect to Cancelled Signing',
  (payload?: { queryParams?: any }) => ({ payload })
);

export const RedirectUnAuthorized = createAction(
  '[Package] Redirect to UnAuthorized',
  (payload?: { queryParams?: any }) => ({ payload })
);

export const RedirectKbaFailure = createAction(
  '[Package] Redirect to KBA Fail',
  (payload: { transactionReasonCode: string; isFailedUser?: boolean }) => ({ payload })
);

export const SetCancelPackage = createAction('[Package] Set Cancelled Package Status');

export const SetCompletePackage = createAction('[Package] Set Completed Package Status');

export const SetEndSessionSuccessful = createAction('[Package] Set End Session Successful');

export const SetIsPreSign = createAction(
  '[Package] Set Presign',
  props<{ payload: { isPreSign: boolean } }>()
);

export const BroadCastSigningCancelled = createAction(
  '[Package] BroadCast Signing Cancelled',
  props<{ payload: { packageUserGuid: string } }>()
);

export const BroadCastNotaryPasswordAttemptsExhausted = createAction(
  '[Package] BroadCast Notary Password Attempts Exhausted',
  props<{ payload: { packageUserGuid: string } }>()
);

export const PackageGuidChanged = createAction('[Package] Package Guid Changed');

export const CheckDeviceSupportWithClientHints = createAction(
  '[Package] Check Device Support with Client Hints',
  props<{
    payload: { packageGuid?: string; packageUserGuid: string; clientHints: UADataValues };
  }>()
);

export const CheckDeviceSupportWithUserAgent = createAction(
  '[Package] Check Device Support with User Agent',
  props<{
    payload: { packageGuid?: string; packageUserGuid: string;};
  }>()
);

export const LogDeviceSupportOnBrowser = createAction(
  '[Package] Log Device Support on Browser',
  props<{ payload: { browserClientSupportOnBrowserLogModel: BrowserClientSupportOnBrowserLogRequestModel} }>()
);

export const OptOutSuccess = createAction('[Packages API] Opt Out Success');

export const OptOutFailure = createAction('[Packages API] Opt Out Failure', props<{ error: Error, text: string }>());
