import { Action, createReducer, on } from '@ngrx/store';
import { SigningRoomActions } from '../actions';

import { SigningRoomState } from '../state';

const reducer = createReducer(
  SigningRoomState.initialState,
  on(
    SigningRoomActions.SigningSessionCompleted,
    (state: SigningRoomState.State) => ({
      ...state,
      sessionCompleted: true,
    })
  ),
  on(SigningRoomActions.ReviewDocuments, (state: SigningRoomState.State) => ({
    ...state,
    reviewingDocuments: true,
  })),
  on(
    SigningRoomActions.SetShowCongratulationsPage,
    (state: SigningRoomState.State, { payload }) => ({
      ...state,
      showCongratulationsPage: payload.showCongratulations,
    })
  ),
  on(
    SigningRoomActions.SigningSessionJoinedSuccessful,
    (state: SigningRoomState.State) => ({
      ...state,
      sessionJoined: true,
    })
  ),
  on(
    SigningRoomActions.SetSigningStartedStatus,
    (state: SigningRoomState.State, { payload }) => ({
      ...state,
      hasSigningStarted: payload.hasSigningStarted,
    })
  ),
);

export function signingRoomReducer(
  state: SigningRoomState.State | undefined,
  action: Action
) {
  return reducer(state, action);
}
