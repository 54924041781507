import { Component, Input, ViewEncapsulation } from '@angular/core';

import { PackageDocument } from '../../models';

@Component({
  selector: 'app-smart-document-viewer',
  templateUrl: './smart-document-viewer.component.html',
  styleUrls: ['./smart-document-viewer.component.scss'],
  encapsulation: ViewEncapsulation.ShadowDom,
})
export class SmartDocumentViewerComponent {
  @Input() packageDoc: PackageDocument;
  @Input() topOffset: number;
  @Input() bottomOffset: number;
  constructor() {}
}
