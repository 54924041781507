<div class="generic-confirm-modal" *ngIf="vm$ | async as vm">
  <div class="generic-confirm-modal__header">
    <h3 class="modal-title" data-testid="pin-title">Verify your identity</h3>
  </div>
  <div class="generic-confirm-modal__body" [formGroup]="pinValidationForm" *ngIf="participant">
    <div data-testid="pin-header" class="pin-message" data-private>
      {{ participant?.firstName }} {{ participant?.lastName }}, please enter your security code. If
      you have not received or created one, it is most likely the last 4 digits of your Social
      Security Number.
    </div>
    <div>
      <input
        #input
        type="password"
        class="form-control no-password-prompt"
        [class.form-error]="vm.message"
        placeholder="Enter your security code"
        formControlName="pinEntry"
        required
        autocomplete="one-time-code"
        (keydown)="verifyPinOnEnterKey($event)"
        data-testid="pin-entry"
        data-private
      />
    </div>
    <div class="error-msg" *ngIf="vm.message" data-testid="pin-error">
      {{ vm.message }}
    </div>
    <app-support-phone *ngIf="vm.showContactSupport"></app-support-phone>
  </div>
  <div class="generic-confirm-modal__footer">
    <app-button-container>
      <app-button
        type="tertiary"
        [isDisabled]="vm.showSpinner"
        [dataTestId]="'pin-cancel'"
        (click)="cancel()"
        >Cancel</app-button
      >
      <app-button
        type="primary"
        [isDisabled]="vm.showSpinner"
        [dataTestId]="'pin-continue'"
        (click)="verifyPin()"
        ><span *ngIf="vm.showSpinner === false">Continue</span>
        <span *ngIf="vm.showSpinner" class="fas fa-spin fa-circle-notch"></span
      ></app-button>
    </app-button-container>
  </div>
</div>
