import { createAction, props } from '@ngrx/store';

import { DocumentsLoadingStatus, SignableDocuments, ViewerScale } from '../../models';

export const SetDocuments = createAction(
  '[Documents] Set Documents',
  props<{ payload: SignableDocuments }>()
);

export const GetDocuments = createAction(
  '[Documents] Get Documents',
  props<{ payload?: { ignoreIfLoaded: boolean; refetchSmartDocs?: boolean } }>()
);

export const GetPreparedPresignDocuments = createAction(
  '[Documents] Gets Prepared Presign Documents',
  props<{ payload?: { ignoreIfLoaded: boolean; refetchSmartDocs?: boolean } }>()
);

export const FetchPreSignDocuments = createAction('[Documents] Fetch Pre-Sign Documents');

export const GetUpdatedSmartDocuments = createAction(
  '[Documents] Get Updated Pre-Sign Smart Documents'
);

export const SetUpdatedSmartDocuments = createAction(
  '[Documents] Set Updated Pre-Sign Smart Documents Source Image',
  props<{ payload: SignableDocuments }>()
);

export const FetchSigningSessionDocuments = createAction(
  '[Documents] Fetch Signing Session Documents',
  props<{ payload?: { refetchSmartDocs?: boolean } }>()
);

export const GetUpdatedSmartDocumentsForSigning = createAction(
  '[Documents] Get Updated Smart Documents for Signing'
);

export const SetUpdatedSmartDocumentsForSigning = createAction(
  '[Documents] Set Updated Smart Documents Source Image',
  props<{ payload: SignableDocuments }>()
);

export const SetActiveDocument = createAction(
  '[Documents] Set Active Document',
  props<{ payload: number }>()
);

export const NavigateToDocument = createAction(
  '[Documents] Navigate to Document',
  props<{ payload: number }>()
);

export const NavigateToNextDocument = createAction('[Documents] Navigate to Next Document');

export const NavigateToPreviousDocument = createAction('[Documents] Navigate to Previous Document');

export const UpdateDocumentLoadingStatus = createAction(
  '[Documents] Update DocumentLoading Status',
  props<{ payload: DocumentsLoadingStatus }>()
);

export const FetchPreviewDocuments = createAction('[Documents] Fetch Preview Documents');

export const FetchCompletedDocuments = createAction('[Documents] Fetch Completed Documents');

export const SetViewableDocumentSource = createAction(
  '[Documents] Set Viewable Document Source',
  props<{ payload: any }>()
);

export const LogDocumentViewed = createAction(
  '[Documents] Log Document Viewed',
  props<{ payload: number }>()
);

export const SetViewerWidth = createAction(
  '[Documents Viewer] Set Viewer Vidth',
  props<{ payload: ViewerScale }>()
);

export const ProgressBarOpened = createAction('[Documents Viewer] Progress Bar Opened');

export const ProgressBarClosed = createAction('[Documents Viewer] Progress Bar Closed');

export const DocumentsNoOp = createAction('[Documents] No Op');

export const SendDocumentsLoaded = createAction('[Documents] Send Documents Loaded');

export const GetAreAllSessionsDocumentsLoaded = createAction(
  '[Documents] Get Are All Sessions Documents Loaded'
);

export const SetAreAllSessionDocumentsLoaded = createAction(
  '[Documents] Set Are All Session Documents Loaded',
  props<{ payload: boolean }>()
);

export const VerifyActiveDocument = createAction('[Documents] Verify Active Document');

export const SetAreParticipantDocumentsShown = createAction(
  '[Documents] Set Paricipant Documents Are Shown',
  props<{ payload: boolean }>()
);

export const SetIsExecutedClosingPackageDownloading = createAction(
  '[Documents] Set Is Executed Closing Package Downloading',
  props<{ payload: { isExecutedClosingPackageDownloading: boolean } }>()
);

export const DownloadExecutedClosingPackage = createAction(
  '[Documents] Download Executed Closing Package'
);
