import {
  createFeatureSelector,
  createSelector
} from '@ngrx/store';

import { KronState } from '../state';

export const getKronState = createFeatureSelector<KronState.State>('kron');

export const getEndSessionSuccessful = createSelector(
  getKronState,
  (state: KronState.State) => state.isEndSessionSuccessful
);

export const getEndSessionFailure = createSelector(
  getKronState,
  (state: KronState.State) => state.isEndSessionFailure
);
