import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { select, Store } from '@ngrx/store';

import { Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';

import { SideBarContent } from 'src/app/features/layout/components/side-bar';
import { PackageUsersSelectors } from 'src/app/features/package-users';
import { RootStoreState } from 'src/app/store';

@Injectable()
export class RemoteSidebarContentResolver {
  constructor(private readonly store: Store<RootStoreState.State>) {}
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<SideBarContent> {
    return this.store.pipe(
      select(PackageUsersSelectors.getIsSigningAgent),
      first(),
      map((isSigningAgent) =>
        isSigningAgent ? SideBarContent.ParticipantList : SideBarContent.SigningsMonthly
      )
    );
  }
}
