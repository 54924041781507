import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

import { DocumentsLoadingStatus, PackageDocument, ViewerScale } from '../../models';

export interface State extends EntityState<PackageDocument> {
  activePackageDocumentId: number;
  loadingStatus: DocumentsLoadingStatus;
  viewableDocumentSource: any;
  viewerScale: ViewerScale;
  areAllSessionsDocumentsLoaded: boolean;
  areParticipantDocumentsShown: boolean;
  totalSignableDocuments: number;
  isExecutedClosingPackageDownloading: boolean;
}

export function selectPackageDocumentId(d: PackageDocument): number {
  return d.packageDocumentId;
}

export function sortBySequenceNumber(d1: PackageDocument, d2: PackageDocument): number {
  return d1.sequenceNumber - d2.sequenceNumber;
}

export const adapter: EntityAdapter<PackageDocument> = createEntityAdapter<PackageDocument>({
  selectId: selectPackageDocumentId,
  sortComparer: sortBySequenceNumber,
});

const { selectEntities, selectAll } = adapter.getSelectors();
export const selectPackageDocumentEntities = selectEntities;
export const selectAllPackageDocuments = selectAll;

export const initialState: State = adapter.getInitialState({
  documentEndorsementLocations: null,
  activePackageDocumentId: null,
  loadingStatus: {
    isLoading: false,
    isLoaded: false,
    loadedPercentage: 0,
    isProgressBarOpen: false,
  },
  viewableDocumentSource: null,
  viewerScale: {
    nextWidth: 816,
    scale: 1,
  },
  areAllSessionsDocumentsLoaded: false,
  areParticipantDocumentsShown: false,
  totalSignableDocuments: 0,
  isExecutedClosingPackageDownloading: false,
});
