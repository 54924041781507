<app-nav-bar-pre-sign></app-nav-bar-pre-sign>
<main>
  <app-side-bar></app-side-bar>
  <div class="router-content">
    <router-outlet></router-outlet>
  </div>
</main>
<app-modal-container></app-modal-container>
<app-loading-spinner></app-loading-spinner>
<app-footer></app-footer>
