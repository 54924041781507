import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import { Observable, filter, map } from 'rxjs';

import { PackageUsersSelectors } from 'src/app/features/package-users/store/selectors';

@Injectable()
export class AllUsersCheckedInGuard {
  constructor(private readonly store: Store) {}

  canActivate(): Observable<boolean> {
    return this.store.select(PackageUsersSelectors.getPackageUsers).pipe(
      filter((packageUsers) => !!packageUsers),
      filter((packageUsers) => packageUsers.every((user) => user.hasOwnProperty('checkInStatus'))),
      map((packageUsers) =>
        packageUsers.every((packageUser) => packageUser.checkInStatus.isCompleted)
      )
    );
  }
}
