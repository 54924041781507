<ng-container [ngSwitch]="componentToDisplay$ | async">
  <div *ngSwitchCase="'DigitalCertificateExpired'">
    <app-certificate-expired></app-certificate-expired>
  </div>

  <div *ngSwitchCase="'CertificatePasswordLimitExceeded'">
    <app-certificate-password-limit-exceeded></app-certificate-password-limit-exceeded>
  </div>

  <ng-container *ngSwitchCase="'CommissionSelection'">
    <app-wizard-step-wrapper
      [showStep]="showStep"
      [stepTitle]="stepMetadata.stepName"
      [formGroup]="formStep"
      #commissioncontainer
    >
      <div class="form-step__subheading" *ngIf="!!user">
        {{ user.firstName }}, select your commission, along with the county that you are currently
        located in.
      </div>

      <form>
        <label for="stateCodes" *ngIf="!isAutoSelectedCommission">Select your commission</label>
        <div class="select" *ngIf="!isAutoSelectedCommission">
          <select
            *ngIf="availableCommissions$ | async as availableCommissions"
            (change)="commissionSelected($event.target.value)"
            formControlName="stateCommission"
            data-testid="commission-select"
          >
            <option value="" disabled selected>Select Commission</option>
            <option
              *ngFor="let commission of availableCommissions; let i = index"
              value="{{ commission.stateCode }}"
            >
              {{ commission.stateCode }}
            </option>
          </select>
        </div>
        <label for="stateCodes" *ngIf="isAutoSelectedCommission">Your commission</label>
        <p *ngIf="isAutoSelectedCommission">State: {{ autoSelectedCommissionState }}</p>
        <div
          [style.display]="
            stateCommission.errors?.required && (stateCommission.dirty || stateCommission.touched)
              ? 'block'
              : 'none'
          "
          class="error-message"
        >
          Please select your commission.
        </div>
        <div
          [style.display]="
            stateCommission.errors?.expiredCommission &&
            (stateCommission.dirty || stateCommission.touched)
              ? 'block'
              : 'none'
          "
          class="error-message"
        >
          This commission is expired.
        </div>

        <ng-container *ngIf="isCommissionSelected && stateCommission.valid">
          <label for="counties">
            Select the county you are physically located in while performing the signing
          </label>
          <div class="select">
            <select
              formControlName="county"
              (change)="countySelected($event)"
              data-testid="county-select"
            >
              <option value="" disabled selected>Select County</option>
              <option
                *ngFor="let county of availableCounties$ | async"
                value="{{ county.countyName }}"
              >
                {{ county.countyName }}
              </option>
            </select>
          </div>
          <div
            [style.visibility]="
              counties.errors && (counties.dirty || counties.touched) ? 'visible' : 'hidden'
            "
            class="error-message"
          >
            Please select the county you are signing in.
          </div>
        </ng-container>

        <ng-container *ngIf="isCommissionSelected && stateCommission.valid">
          <div
            class="commission"
            *ngIf="selectedCommission$ | async as selectedCommission"
            data-private
          >
            <div class="commission__detail">
              <p class="commission__detail--heading">Name On Commission</p>
              {{ selectedCommission.name }}
            </div>
            <div class="commission__detail">
              <p class="commission__detail--heading">Commission Expiration</p>
              {{ selectedCommission.expiresOn | date: 'longDate' }}
            </div>
            <div class="commission__detail">
              <p class="commission__detail--heading">Commission County</p>
              <p>{{ selectedCommission.county }}</p>
            </div>
            <div class="commission__detail">
              <p class="commission__detail--heading">Commission Number</p>
              <p>{{ selectedCommission.number }}</p>
            </div>
            <div class="commission__detail">
              <p class="commission__detail--heading">Stamp On File</p>
              <img
                src="data:image/png;base64,{{ selectedCommission.stamp }}"
                alt="Notary stamp for selected commission"
              />
            </div>
          </div>

          <app-scrollable-text-box
            [contentMarkup]="(selectedCommissionConsent$ | async)?.presentText"
          >
          </app-scrollable-text-box>
          <p class="form-step__text">
            By checking “I Agree” below, I am affirming and agreeing to the requirements of the
            above Signing Agent Acknowledgment and that I am located in the above selected county at
            the time of performing this online electronic signing.
          </p>
          <app-consent-check-box
            class="checkBox"
            formControlName="consentCheckbox"
            [formStep]="formStep"
            [consentText]="consentText"
            [consentErrorText]="consentErrorText"
            [validateOnInputChange]="true"
          >
          </app-consent-check-box>
        </ng-container>
      </form>
      <app-expired-commission
        *ngIf="
          stateCommission.errors?.expiredCommission &&
          (stateCommission.dirty || stateCommission.touched)
        "
      ></app-expired-commission>

      <app-button-container>
        <app-button [dataTestId]="'next-button-commission'" type="primary" (click)="goToNextStep()">
          Next
        </app-button>
      </app-button-container>
    </app-wizard-step-wrapper>
  </ng-container>
</ng-container>
