import { Pipe, PipeTransform } from '@angular/core';

import { CheckInStatus } from '../models';

type checkInStatus = 'Check-in complete' | 'Checking In' | 'Waiting for participant to start';
@Pipe({ name: 'checkinStatus' })
export class PackageUserCheckInStatusPipe implements PipeTransform {
  transform(status: CheckInStatus): checkInStatus {
    if (status.isCompleted) {
      return 'Check-in complete';
    } else if (status.isStarted) {
      return 'Checking In';
    }

    return 'Waiting for participant to start';
  }
}
