import { Component, OnInit } from '@angular/core';
import { DEFAULT_APPLICATION_PHONE_NUMBER } from 'src/app/features/shared/constants/constants';

@Component({
  selector: 'app-duplicate-session-modal',
  templateUrl: './duplicate-session-modal.component.html',
  styleUrls: ['./duplicate-session-modal.component.scss'],
})
export class DuplicateSessionModalComponent implements OnInit {
    static identifier = 'DuplicateSessionModalComponent';
    public phoneNumber: string;

    constructor() {}

    ngOnInit(): void {
        this.phoneNumber = DEFAULT_APPLICATION_PHONE_NUMBER;
    }
}
