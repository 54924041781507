import { createAction, props } from '@ngrx/store';
import { RecordingStatus } from 'src/app/features/video-conference/chime/enums/recording-status-modals.enum';
import { AttendeeResponse } from 'src/app/features/video-conference/chime/models/AttendeeResponse';
import { MeetingResponse } from 'src/app/features/video-conference/chime/models/MeetingResponse';

export const SetupChime = createAction('[Chime] Setup Chime');

export const GetMeeting = createAction('[Chime] Get Meeting');

export const StopMeetingAndRecording = createAction('[Chime] Stop Meeting And Recording');

export const AddAttendee = createAction(
  '[Chime] Add Attendee',
  props<{ payload: { meetingId: string } }>()
);

export const SetAttendeeDetails = createAction(
  '[Chime] Set Attendee Details',
  props<{ payload: { attendee: AttendeeResponse } }>()
);

export const SetMeetingDetails = createAction(
  '[Chime] Set Meeting Details',
  props<{ payload: { meeting: MeetingResponse } }>()
);

export const StartRecording = createAction('[Chime] Start Recording');

export const GetRecordingStatus = createAction('[Chime] Get Recording Status');

export const SetRecordingStatus = createAction(
  '[Chime] Set Recording Status',
  props<{ payload: { recordingStatus: RecordingStatus } }>()
);

export const ShowRecordingFailedToStartModal = createAction(
  '[Chime] Show Recording Failed to Start Modal'
);
