<div class="generic-confirm-modal">
  <div class="generic-confirm-modal__header">
    <h3 class="modal-title">Attempts Exceeded</h3>
  </div>
  <div class="generic-confirm-modal__body">
    <div>
      You have exceeded the maximum number of password attempts. <br />
      <b>Please notify all participants that you need to cancel this signing.</b>
    </div>
    <div class="supportDetails" *ngIf="client$ | async as clientInfo">
      Call:
      <span class="phoneNumber">{{
        clientInfo.supportPhoneNumber | phone
      }}</span>
      for support
    </div>
  </div>
</div>
