<mat-expansion-panel
  class="mat-expansion-panel"
  data-testid="av-check-audio-select"
  [expanded]="true"
  hideToggle
>
  <mat-expansion-panel-header>
    <mat-panel-title data-testid="av-check-audio-title"
      ><mat-icon class="material-icons-outlined">mic</mat-icon>Audio</mat-panel-title
    >
    <mat-panel-description>
      <ng-container *ngTemplateOutlet="audioIconContainer" />
    </mat-panel-description>
  </mat-expansion-panel-header>

  <div class="mat-expansion-panel__body">
    <div class="custom-select">
      <mat-form-field subscriptSizing="dynamic">
        <mat-label data-testid="av-check-microphone-select-label">Select Microphone</mat-label>
        <mat-select
          [disabled]="!micCheckService.selectAudioInputDevices().length"
          [value]="micCheckService.currentMicDevice()?.label || ''"
          (selectionChange)="handleMicrophoneSelectionChange($event)"
        >
          <mat-option value="">
            No Microphone Selected
          </mat-option>
          @for (inputDevice of micCheckService.selectAudioInputDevices(); track inputDevice) {
          <mat-option
            [value]="inputDevice.label"
          >
            {{ inputDevice.label }}
          </mat-option>
          }
        </mat-select>
      </mat-form-field>
      <mat-progress-bar
        [ngClass]="micCheckService.micBarProgressClass()"
        [value]="micCheckService.micBarProgress()"
        class="rkt-ProgressBar disable-animations"
        mode="determinate"
      />
    </div>
    <ng-container *ngTemplateOutlet="micIconContainer" />
  </div>

  <span class="hint">Hint: Speak into your microphone and make sure the bar above turns green.</span>
  @if (micFailure()) {
  <app-av-check-guide
    [showButton]="audioVideoCheckService.isBrowserDetected"
    [(showDetails)]="showMicrophoneGuideDetails"
  >
    <p>
      Hi there! It looks like your microphone might be blocked by your browser. Let's get this
      sorted out.
    </p>

    @if (showMicrophoneGuideDetails) { @if (hasGadgetGuideForMicrophoneLoadingFailure()) {
    <p>
      We're having trouble accessing your microphone. Please make sure your microphone is connected
      and try again.
    </p>
    } @else { @if (isGadgetGuideForMicrophoneLoading()) {
    <div class="loading-container">
      <div class="clearSign-skeleton clearSign-skeleton--has-pulse clearSign-skeleton--text"></div>
    </div>
    } @else {
    <app-gadget-guide-response [gadgetGuide]="gadgetGuideServiceMicrophoneResponse()" />
    } } }
  </app-av-check-guide>
  } @if (speakerCheckService.hasSpeakerFeature()) {
  <div class="mat-expansion-panel__body">
    <div class="custom-select">
      <mat-form-field subscriptSizing="dynamic">
        <mat-label data-testid="av-check-speaker-select-label">Select Speaker</mat-label>
        <mat-select
          [disabled]="!speakerCheckService.selectAudioOutputDevices().length"
          [value]="speakerCheckService.selectAudioOutputDevice()?.label || ''"
        >
          <mat-option value="" (click)="speakerCheckService.clearSpeaker()">
            No Speaker Selected
          </mat-option>
          @for (outputDevice of speakerCheckService.selectAudioOutputDevices(); track outputDevice)
          {
          <mat-option
            [value]="outputDevice.label"
            (click)="speakerCheckService.selectSpeaker(outputDevice)"
          >
            {{ outputDevice.label }}
          </mat-option>
          }
        </mat-select>
      </mat-form-field>
      <mat-progress-bar
        [ngClass]="speakerCheckService.matProgressBarClass"
        [value]="speakerCheckService.audioLevel"
        class="rkt-ProgressBar disable-animations"
        mode="determinate"
      />
    </div>
    <ng-container *ngTemplateOutlet="speakerIconContainer" />
  </div>
  <span class="hint">
    Hint: Click on the “Test Speaker” button below and ensure that you can hear the sound.
  </span>
  @if (speakerFailure()) {
  <app-av-check-guide
    [showButton]="audioVideoCheckService.isBrowserDetected"
    [(showDetails)]="showSpeakerGuideDetails"
  >
    <p>
      Hi there! It looks like your speaker might be blocked by your browser. Let's get this sorted
      out.
    </p>

    @if (showSpeakerGuideDetails) { @if (hasGadgetGuideForSpeakerLoadingFailure()) {
    <p>
      We're having trouble accessing your speaker. Please make sure your speaker is connected and
      try again.
    </p>
    } @else { @if (isGadgetGuideForSpeakerLoading()) {
    <div class="loading-container">
      <div class="clearSign-skeleton clearSign-skeleton--has-pulse clearSign-skeleton--text"></div>
    </div>
    } @else {
    <app-gadget-guide-response
      [gadgetGuide]="gadgetGuideServiceSpeakerResponse()"
    ></app-gadget-guide-response>
    } } }
  </app-av-check-guide>
  }

  <ng-container mat-action-row>
    <app-button
      (click)="!shouldDisableSpeakerTestButton() && speakerCheckService.testSpeaker()"
      [isDisabled]="shouldDisableSpeakerTestButton()"
      class="speaker-test"
      type="secondary"
      [dataTestId]="'test-speaker-button-avcheck'"
    >
      Test Speaker
    </app-button>
  </ng-container>
  }
</mat-expansion-panel>

<ng-template #audioIconContainer>
  <div class="mat-expansion-panel__info-icon-container">
    @if (micFailure() || speakerFailure()) {
    <app-info-icon
      alt="Red circle exclamation mark error icon"
      role="img"
      title="Audio Selection Error"
    />
    } @else {
    <img
      src="./assets/images/check_circle_green.svg"
      alt="Green circle check"
      role="img"
      title="Audio Selection Success"
    />
    }
  </div>
</ng-template>

<ng-template #micIconContainer>
  <div class="mat-expansion-panel__info-icon-container">
    @if (micFailure()) {
    <app-info-icon
      alt="Red circle exclamation mark error icon"
      role="img"
      title="Microphone Selection Error"
    />
    } @else {
    <img
      src="./assets/images/check_circle_green.svg"
      alt="Green circle check"
      role="img"
      title="Microphone Selection Success"
    />
    }
  </div>
</ng-template>

<ng-template #speakerIconContainer>
  <div class="mat-expansion-panel__info-icon-container">
    @if (speakerFailure()) {
    <app-info-icon
      alt="Red circle exclamation mark error icon"
      role="img"
      title="Speaker Selection Error"
    />
    } @else {
    <img
      src="./assets/images/check_circle_green.svg"
      alt="Green circle check"
      role="img"
      title="Speaker Selection Success"
    />
    }
  </div>
</ng-template>
