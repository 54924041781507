<div class="kba-modal">
  <div class="kba-modal kba-modal__content-container">
    <div class="kba-modal__image">
      <img src="../../../../../assets/images/alarm.svg" alt="alarm" data-testid="times-up-modal-alarm-image" />
    </div>
    <div class="kba-modal__header">
      <h4 data-testid="times-up-modal-header">Times Up</h4>
    </div>
    <div class="kba-modal__body" data-testid="times-up-modal-text">
      <div>
        Your 2 minutes to answer the security questions is up. You have <b>1 more attempt left.</b>
      </div>
    </div>
  </div>
  <app-button-container>
    <app-button type="primary" (click)="onTryAgain()" [dataTestId]="'times-up-modal-try-again-button'">
      Try Again
    </app-button>
  </app-button-container>
</div>