<app-info-icon></app-info-icon>
<section>
  <header>
    <span>{{ title }}</span>
  </header>
  <div>
    <p>
      {{ body }}
    </p>
  </div>
  <p>
    If the problem persists, please contact support:
    <app-support-phone></app-support-phone>
  </p>
</section>
