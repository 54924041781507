import { Component, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { SigningRoomActions } from 'src/app/features/signing-room/store';
import { RootStoreState } from 'src/app/store';

@Component({
  selector: 'app-add-user-to-this-session-button[isAddUserButtonEnabled]',
  templateUrl: './add-user-to-this-session-button.component.html',
  styleUrls: ['../../nav-bar.component.scss'],
})
export class AddUserToThisSessionButtonComponent {

  @Input() isAddUserButtonEnabled: boolean;

  constructor(private readonly store: Store<RootStoreState.State>) {}
  
  addUserToThisSession() {
    this.store.dispatch(SigningRoomActions.OpenAddUserToSessionModal());
  }

}
