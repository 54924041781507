import { createFeatureSelector, createSelector } from '@ngrx/store';

import { DocumentsState } from '../state';

export const getDocumentsState = createFeatureSelector<DocumentsState.State>('documents');

export const getSignableDocuments = createSelector(
  getDocumentsState,
  DocumentsState.selectPackageDocumentEntities
);

export const getActivePackageDocumentId = createSelector(
  getDocumentsState,
  (state) => state.activePackageDocumentId
);

export const selectPackageDocuments = createSelector(
  getDocumentsState,
  DocumentsState.selectAllPackageDocuments
);

export const selectActivePackageDocument = createSelector(
  getSignableDocuments,
  getActivePackageDocumentId,
  (documentEntities, activeId) => {
    if (documentEntities && documentEntities[activeId]) {
      return documentEntities[activeId];
    }
    return null;
  }
);

export const getNextPackageDocumentId = createSelector(
  getSignableDocuments,
  getActivePackageDocumentId,
  (documentEntities, activeId) => {
    if (documentEntities && documentEntities[activeId]) {
      return documentEntities[activeId].nextDocumentId;
    }
    return null;
  }
);

export const getPreviousPackageDocumentId = createSelector(
  getSignableDocuments,
  getActivePackageDocumentId,
  (documentEntities, activeId) => {
    if (documentEntities && documentEntities[activeId]) {
      return documentEntities[activeId].previousDocumentId;
    }
    return null;
  }
);

export const getDocumentsLoadedStatus = createSelector(
  getDocumentsState,
  (state) => state.loadingStatus
);

export const selectDocumentsIsLoaded = createSelector(
  getDocumentsState,
  (state) => state.loadingStatus.isLoaded
);

export const getDocumentsLoadedPercentage = createSelector(
  getDocumentsLoadedStatus,
  (loadedStatus) => loadedStatus.loadedPercentage
);

export const isProgressBarOpen = createSelector(
  getDocumentsLoadedStatus,
  (loadedStatus) => !!loadedStatus.isProgressBarOpen
);

export const getViewableDocumentSource = createSelector(
  getDocumentsState,
  (state) => state.viewableDocumentSource
);

export const getViewerScale = createSelector(getDocumentsState, (state) => state.viewerScale);

export const getAreAllSessionsDocumentsLoaded = createSelector(
  getDocumentsState,
  (state) => state.areAllSessionsDocumentsLoaded
);

export const GetAreParticipantDocumentsShown = createSelector(
  getDocumentsState,
  (state) => state.areParticipantDocumentsShown
);

export const getTotalSignableDocuments = createSelector(
  getDocumentsState,
  (state) => state.totalSignableDocuments
);

export const getIsExecutedClosingPackageDownloading = createSelector(
  getDocumentsState,
  (state: DocumentsState.State) => state.isExecutedClosingPackageDownloading
);
