<ng-container>
  <div class="signing-details">
    <h1>Welcome to your signing!</h1>
    <app-signing-details-card *appMobileOnly></app-signing-details-card>
  </div>

  <app-signing-agent-welcome-page *ngIf="isSigningAgent$ | async"></app-signing-agent-welcome-page>

  <app-non-signing-agent-welcome-page *ngIf="(isSigningAgent$ | async) === false"></app-non-signing-agent-welcome-page>
</ng-container>
<app-package-status-monitor></app-package-status-monitor>
