<div class="kba-modal">
  <div class="kba-modal kba-modal__content-container">
    <div class="kba-modal__image">
      <img src="../../../../../assets/images/unable-to-verify.svg" alt="check list"
        data-testid="unable-to-verify-modal-checklist-image" />
    </div>
    <div class="kba-modal__header">
      <h4 data-testid="unable-to-verify-modal-header">Unable to verify</h4>
    </div>
    <div class="kba-modal__body" data-testid="unable-to-verify-modal-text">
      <div>
        You have answered some or all of the questions incorrectly. You have
        <b>1 more attempt left.</b>
      </div>
    </div>
  </div>
  <app-button-container>
    <app-button type="primary" (click)="onTryAgain()" [dataTestId]="'unable-to-verify-modal-try-again-button'">
      Try Again
    </app-button>
  </app-button-container>
</div>