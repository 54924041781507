<ng-container *ngIf="feedbackType">
  <ng-container [ngSwitch]="feedbackType">
    <app-cancelled-signing *ngSwitchCase="feedbackTypes.CANCELLED"></app-cancelled-signing>
    <app-unauthorized *ngSwitchCase="feedbackTypes.UNAUTHORIZED"></app-unauthorized>
    <app-completed-signing *ngSwitchCase="feedbackTypes.COMPLETED"></app-completed-signing>
    <app-early-signing *ngSwitchCase="feedbackTypes.EARLY"></app-early-signing>
    <app-expired-signing *ngSwitchCase="feedbackTypes.EXPIRED"></app-expired-signing>
    <app-invalid-url *ngSwitchCase="feedbackTypes.INVALIDURL"></app-invalid-url>
    <app-unsupported-browser
      *ngSwitchCase="feedbackTypes.UNSUPPORTEDBROWSER"
    ></app-unsupported-browser>
    <app-unsupported-operating-system *ngSwitchCase="feedbackTypes.UNSUPPORTEDOPERATINGSYSTEM">
    </app-unsupported-operating-system>
    <app-unsupported-device
      *ngSwitchCase="feedbackTypes.UNSUPPORTEDDEVICE"
    ></app-unsupported-device>
    <app-smartphones-unsupported
      *ngSwitchCase="feedbackTypes.SMARTPHONESUNSUPPORTED"
    ></app-smartphones-unsupported>
    <app-processing-signing *ngSwitchCase="feedbackTypes.PROCESSING"></app-processing-signing>
    <app-processing-signing *ngSwitchCase="feedbackTypes.ORDERNOTREADY"></app-processing-signing>
    <app-invalid-link *ngSwitchCase="feedbackTypes.INVALIDLINK"></app-invalid-link>
    <app-generic-error *ngSwitchCase="feedbackTypes.GENERICERROR"></app-generic-error>
    <app-kba-fail *ngSwitchCase="feedbackTypes.KBAFAIL"></app-kba-fail>
  </ng-container>
</ng-container>
