import { Action, createReducer, on } from '@ngrx/store';
import { ChimeActions } from 'src/app/features/video-conference/chime/store/actions';
import { ChimeState } from 'src/app/features/video-conference/chime/store/state';

const reducer = createReducer(
  ChimeState.initialState,
  on(ChimeActions.SetAttendeeDetails, (state: ChimeState.State, { payload }) => ({
    ...state,
    attendee: payload.attendee,
  })),
  on(ChimeActions.SetMeetingDetails, (state: ChimeState.State, { payload }) => ({
    ...state,
    meeting: payload.meeting,
  })),
  on(ChimeActions.SetRecordingStatus, (state: ChimeState.State, { payload }) => ({
    ...state,
    recordingStatus: payload.recordingStatus,
  }))
);

export function chimeReducer(state: ChimeState.State | undefined, action: Action) {
  return reducer(state, action);
}
