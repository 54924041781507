import { createAction, props } from '@ngrx/store';

import { SessionEndStatusResponse } from '../../models';

export const EnterSigningRoom = createAction('[Ron] Enter Signing Room');

export const EnterRejoinWaitingLobby = createAction('[Ron] Enter Rejoin Waiting Lobby');

export const SigningSessionStarted = createAction('[Ron] Signing Session Started');

export const SetEndSessionCompleted = createAction('[Ron] Set End Session Completed');

export const NavigateToSessionEnding = createAction('[Ron] Navigate to Session Ending');

export const SetEndSessionSuccessful = createAction('[Ron] Set End Session Successful');

export const SetEndSessionFailure = createAction('[Ron] Set End Session Failure');

export const GetSessionStatuses = createAction('[Ron] Get End Session Status Aggregates');

export const SetSessionStatuses = createAction(
  '[Ron] Set End Session Status Aggregates',
  props<{ payload: SessionEndStatusResponse }>()
);
