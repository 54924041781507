import { Action, createReducer, on } from '@ngrx/store';

import { PinValidationActions } from '../actions';
import { PinValidationState } from '../state';

const reducer = createReducer(
  PinValidationState.initialState,
  on(PinValidationActions.PinVerificationPass, (state: PinValidationState.State, { payload }) => ({
    ...state,
    pinVerification: payload.pinVerification,
    showSpinner: false,
  })),
  on(PinValidationActions.PinVerificationFail, (state: PinValidationState.State, { payload }) => ({
    ...state,
    pinVerification: payload.pinVerification,
    showSpinner: false,
  })),
  on(PinValidationActions.PinVerificationError, (state: PinValidationState.State) => ({
    ...state,
    showSpinner: false,
  })),
  on(PinValidationActions.PinVerificationOpened, (state): PinValidationState.State => ({
    ...state,
    pinVerification: PinValidationState.initialState.pinVerification,
    message: PinValidationState.initialState.message,
    showSpinner: PinValidationState.initialState.showSpinner,
    showContactSupport: PinValidationState.initialState.showContactSupport,
  })),
  on(PinValidationActions.VerifyPin, (state: PinValidationState.State) => ({
    ...state,
    showSpinner: true,
    message: '',
  })),
  on(
    PinValidationActions.SetPinVerificationMessage,
    (state: PinValidationState.State, { payload }) => ({
      ...state,
      message: payload.message,
    })
  ),
  on(PinValidationActions.ShowContactSupport, (state: PinValidationState.State) => ({
    ...state,
    showContactSupport: true,
  })),
  on(PinValidationActions.HideContactSupport, (state: PinValidationState.State) => ({
    ...state,
    showContactSupport: false,
  })),
  on(
    PinValidationActions.EmptyPinInput,
    (state): PinValidationState.State => ({
      ...state,
      showContactSupport: false,
      message: 'Please enter your password.',
    })
  )
);

export function pinValidationReducer(state: PinValidationState.State | undefined, action: Action) {
  return reducer(state, action);
}
