import { Component, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';

@Component({
  selector: 'app-action-blocking-snackbar-notification',
  templateUrl: './action-blocking-snackbar.component.html',
  styleUrls: ['./action-blocking-snackbar.component.scss'],
})
export class ActionBlockingSnackbarComponent {
  title: string;
  body: string;

  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: any) {
    this.title = data.title;
    this.body = data.body;
  }
}
