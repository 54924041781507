import { Component, Input } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-frequently-asked-questions-button[isFaqButtonEnabled]',
  templateUrl: './frequently-asked-questions-button.component.html',
  styleUrls: ['../../nav-bar.component.scss'],
})
export class FrequentlyAskedQuestionsButtonComponent {

  @Input() isFaqButtonEnabled: boolean;
  faqUrl = environment.clearsignFaqUrl;
}
