import { IdVerificationDisplayResults } from "./id-verification-display-results.model";
import { IdVerificationStatus } from "./id-verification-status-enum.model";
import { KbaResult } from "./kba-result.model";

export interface IdVerification {
    latestKbaResult: KbaResult
    latestUserAddressInformationIdVerificationResults: IdVerificationDisplayResults;
    packageUserId: number;
    packageUserGuid: string;
}

export const defaultIdVerification: IdVerification = {
    latestKbaResult: {
      isSuccess: false,
      failureReason: null
    },
    latestUserAddressInformationIdVerificationResults: {
      displayResults: [],
      userAddressInformationIdVerificationResult: {
        idVerificationResult: {
          analysisResults: [],
          firstName: null,
          lastName: null,
          idVerificationStatus: IdVerificationStatus.Undetermined,
          stateCode: null,
          streetAddress: null,
          city: null,
          zipCode: null
        },
        userAddressInformation: {
          packageUserId: 0,
          packageUserGuid: null,
          streetAddress1: null,
          streetAddress2: null,
          city: null,
          stateCode: null,
          zipCode: null,
          firstName: null,
          lastName: null
        }
      },
      frontAndBackImagesBaseUri: null,
      idImages: {
        front: null,
        back: null
      }
    },
    packageUserId: null,
    packageUserGuid: null
  }
