import { state, style, trigger } from '@angular/animations';
import { Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { select, Store } from '@ngrx/store';

import { Observable, Subject, Subscription } from 'rxjs';
import { filter, takeUntil, tap } from 'rxjs/operators';

import { DocumentsActions, DocumentsSelectors, PackageDocument } from 'src/app/features/documents';
import { EndorsementsSelectors } from 'src/app/features/features';
import { RootStoreState } from 'src/app/store';

import { EndorsementRemainingModalComponent } from '../endorsement-remaining-modal';
import { OptionsDirective } from './document-selector.directive';

@Component({
  selector: 'app-signing-room-document-selector',
  templateUrl: './document-selector.component.html',
  styleUrls: ['./document-selector.component.scss'],
  animations: [
    trigger('rotatedState', [
      state('false', style({ transform: 'rotate(0)' })),
      state('true', style({ transform: 'rotate(-180deg)' })),
    ]),
  ],
})
export class DocumentSelectorComponent implements OnInit, OnDestroy {
  @ViewChild(OptionsDirective, { read: ElementRef }) optionsListRef: ElementRef;

  packageDocuments$: Observable<Array<PackageDocument>>;
  activePackageDocument$: Observable<PackageDocument>;
  notifier = new Subject();
  isEndorsementRemaining: boolean;
  activeDocumentId: number;
  public showOptions = false;

  // DocumentChecked allows for tracking of when the document name div is clicked before the options show.
  // Due to the change detection loop, it otherwise would consider showOptions to be true when the elements had not been rendered yet.
  documentChecked = false;
  continueSubscription: Subscription;

  constructor(private readonly store: Store<RootStoreState.State>) {}

  ngOnInit(): void {
    this.packageDocuments$ = this.store.pipe(select(DocumentsSelectors.selectPackageDocuments));
    this.activePackageDocument$ = this.store.pipe(
      select(DocumentsSelectors.selectActivePackageDocument),
      filter<PackageDocument>(Boolean),
      tap((packageDocumnent) => (this.activeDocumentId = packageDocumnent.packageDocumentId))
    );
    this.store
      .pipe(
        takeUntil(this.notifier),
        select(EndorsementsSelectors.selectActiveDocumentRemainingRequiredActions),
        tap((count) => (this.isEndorsementRemaining = count !== 0))
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.notifier.next(undefined);
    this.notifier.complete();
    this.continueSubscription?.unsubscribe();
  }

  @HostListener('document:click', ['$event'])
  clickin(event: { target: any }) {
    if (
      this.showOptions &&
      this.documentChecked &&
      !this.optionsListRef.nativeElement.contains(event.target)
    ) {
      this.showOptions = false;
    } else {
      this.documentChecked = true;
    }
  }

  showOptionList() {
    if (!this.showOptions) {
      this.documentChecked = false;
    }
    this.showOptions = !this.showOptions;
  }

  displayDocument(documentId: number) {
    if (this.activeDocumentId !== documentId) {
      this.isEndorsementRemaining
        ? this.openEndorsementRemainingModal(documentId)
        : this.moveToDocument(documentId);
    } else {
      this.showOptions = false;
    }
  }

  openEndorsementRemainingModal(documentId: number) {
    EndorsementRemainingModalComponent.openModal(
      this.store,
      this.moveToDocument.bind(this, documentId)
    );
  }

  moveToDocument(documentId: number) {
    this.store.dispatch(DocumentsActions.NavigateToDocument({ payload: documentId }));
    this.showOptions = false;
  }
}
