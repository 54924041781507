import { Component, OnInit } from '@angular/core';
import { MatSnackBarRef } from '@angular/material/snack-bar';

@Component({
  selector: 'app-waiting-room-snack-bar',
  templateUrl: 'waiting-room-snack-bar.component.html',
  styleUrls: ['waiting-room-snack-bar.component.scss'],
})
export class WaitingRoomSnackBarComponent implements OnInit {
  interval: undefined | ReturnType<typeof setInterval>;
  remainingSeconds = 10;

  constructor(public snackBarRef: MatSnackBarRef<WaitingRoomSnackBarComponent>) {}

  ngOnInit(): void {
    this.interval = setInterval(() => this.handleTick(), 1000);
  }

  handleAction() {
    clearInterval(this.interval);

    this.snackBarRef.dismissWithAction();
  }

  handleTick() {
    this.remainingSeconds--;

    if (this.remainingSeconds === 0) {
      clearInterval(this.interval);
    }
  }
}
