<app-confirmation-modal>
  <ng-container header>
    <h1>Privacy Policy</h1>
  </ng-container>

  <ng-container subHeader>
    <p>Here's what you need to know about your privacy policy.</p>
  </ng-container>

  <ng-container content>
    <app-scrollable-text-box [contentMarkup]="(privacyPolicyConsent$ | async)?.presentHtml | safeHtml">
    </app-scrollable-text-box>
  </ng-container>

  <ng-container actionButton>
    <app-button type="tertiary" (click)="back()" [dataTestId]="'privacy-policy-back'">
      Go Back
    </app-button>

    <app-button type="primary" (click)="accept()" [dataTestId]="'privacy-policy-agree'">
      Agree
    </app-button>
  </ng-container>
</app-confirmation-modal>