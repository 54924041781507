<div class="endorsement-remaining">
  <div class="endorsement-remaining__header">
    <div class="title">An action was missed</div>
  </div>
  <div class="endorsement-remaining__body">
    <p>
      Looks like a signature or other action wasn't applied to this document. 
      If this was on purpose select "Continue" to move to the next document, otherwise select "Back" to return to the current document.
    </p>
  </div>

  <div class="endorsement-remaining__footer">
    <button class="btn btn--outline-dark" (click)="backClick()">Back</button>
    <button class="btn btn--warning" (click)="continueClick()">Continue</button>
  </div>
</div>
