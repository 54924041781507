import { Component } from '@angular/core';

@Component({
  selector: 'app-expired-signing',
  templateUrl: './expired-signing.component.html',
  styleUrls: ['./expired-signing.component.scss'],
})
export class ExpiredSigningComponent {
  constructor() {}
}
