import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';

import { Observable, of } from 'rxjs';

import { Client, defaultClientInfo } from 'src/app/features/client/models';
import { Feature } from 'src/app/features/feature-management/models';
import { FeatureManagementService } from 'src/app/features/feature-management/services';
import { CancelSigningModalComponent } from 'src/app/features/layout';
import { ModalsActions } from 'src/app/features/modals';
import { ChimeActions } from 'src/app/features/video-conference/chime';
import { RootStoreState } from 'src/app/store';

import { VideoOptOutReasons } from '../../enums';
import { VideoActions } from '../../store/actions';

@Component({
  selector: 'app-recording-could-not-start-modal',
  templateUrl: './recording-could-not-start-modal.component.html',
  styleUrls: ['./recording-could-not-start-modal.component.scss'],
})
export class RecordingCouldNotStartModalComponent implements OnInit {
  static identifier = 'RecordingCouldNotStartModalComponent';
  client$: Observable<Client>;

  constructor(
    private readonly store: Store<RootStoreState.State>,
    private readonly featureManagementService: FeatureManagementService
  ) {}

  ngOnInit(): void {
    this.client$ = of(defaultClientInfo);
  }

  retry() {
    this.store.dispatch(ModalsActions.ClearModalComponent());

    // TODO Story 63200: Remove ChimeVideo FeatureFlag
    if (this.featureManagementService.getIsFeatureEnabledWithCaching(Feature.ChimeVideo)) {
      this.store.dispatch(ChimeActions.StartRecording()); // Chime Recording
    } else {
      this.store.dispatch(VideoActions.StartArchive()); // Vonage Recording
    }
  }

  cancel() {
    this.store.dispatch(ModalsActions.ClearModalComponent());
    this.store.dispatch(
      ModalsActions.SetStandaloneModalComponent({
        payload: {
          component: CancelSigningModalComponent,
          componentData: {
            fixedCategoryTypeCode: VideoOptOutReasons.RecordingFailure,
          },
        },
      })
    );
  }
}
