<app-wizard-step-wrapper
  [showStep]="showStep"
  [stepTitle]="stepTitle$ | async"
  [formGroup]="formStep"
>
  <ng-container *ngIf="showStep || stepHasBeenShown" [ngSwitch]="signatureType$ | async">

    <app-draw-signature-step
      *ngSwitchCase="SignatureTypeEnum.Drawn"
      [user]="user"
      (stepComplete)="goToNextStep()"
    ></app-draw-signature-step>

    <app-select-signature
      *ngSwitchCase="SignatureTypeEnum.Selected"
      [user]="user"
      (stepComplete)="goToNextStep()"
    ></app-select-signature>

    <app-acknowledge-uploaded-signature
      *ngSwitchCase="SignatureTypeEnum.Uploaded"
      [user]="user"
      (stepComplete)="goToNextStep()"
    ></app-acknowledge-uploaded-signature>

  </ng-container>
</app-wizard-step-wrapper>
