import { Component } from '@angular/core';
import { DEFAULT_APPLICATION_PHONE_NUMBER } from 'src/app/features/shared/constants';

@Component({
  selector: 'app-support-phone-number-button',
  templateUrl: './support-phone-number-button.component.html',
  styleUrls: ['../../nav-bar.component.scss'],
})
export class SupportPhoneNumberButtonComponent {
  clearSignSupportNumber = DEFAULT_APPLICATION_PHONE_NUMBER;
}
