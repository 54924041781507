export enum FeedbackType {
  CANCELLED = 'cancelled',
  COMPLETED = 'completed',
  EARLY = 'early',
  EXPIRED = 'expired',
  INVALIDURL = 'invalid-url',
  UNSUPPORTEDBROWSER = 'unsupported-browser',
  UNSUPPORTEDDEVICE = 'unsupported-device',
  UNSUPPORTEDOPERATINGSYSTEM = 'unsupported-operating-system',
  UNAUTHORIZED = 'unauthorized',
  PROCESSING = 'processing',
  INVALIDLINK = 'invalid-link',
  GENERICERROR = 'error',
  KBAFAIL = 'kba-fail',
  SMARTPHONESUNSUPPORTED = 'smartphones-unsupported',
  ORDERNOTREADY = 'not-ready',
}
