import { Status } from './kba-status.model';
import { QuestionSet } from './questions.model';

export interface ProductReason {
  code: string;
  description: string;
}

export interface KbaResponse {
  status: Status;
  productType: string;
  productReason: ProductReason;
  questionSet: QuestionSet;
}
