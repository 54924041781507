import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { map, tap } from 'rxjs/operators';
import { ConsumerPortalActions } from 'src/app/features/consumer-portal/store/actions';
import { DeviceGroupActions } from 'src/app/features/device-group';
import { PackageUsersSelectors } from 'src/app/features/package-users';
import { RootStoreState } from 'src/app/store';

@Injectable()
export class ConsumerPortalEffects {
  signingRoomCheckIn$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ConsumerPortalActions.SigningRoomCheckIn),
        tap(() => {
          this.router.navigate(['/pre-sign/wizard']);
        })
      ),
    { dispatch: false }
  );

  previewDocuments$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ConsumerPortalActions.PreviewDocuments),
        tap(() => {
          this.router.navigate(['consumer-portal/preview-pdf']);
        })
      ),
    { dispatch: false }
  );

  completedPackageDownload$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ConsumerPortalActions.CompletedPackageDownload),
        tap(() => {
          this.router.navigate(['consumer-portal/download-completed-pdf']);
        })
      ),
    { dispatch: false }
  );

  saveToDeviceGroup$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ConsumerPortalActions.SigningRoomCheckIn),
      concatLatestFrom(() => [this.store.select(PackageUsersSelectors.getActivePackageUserGuid)]),
      map(([, activePackageUserGuid]) => {
        return DeviceGroupActions.SaveToDeviceGroup({
          payload: { packageUserGuid: activePackageUserGuid },
        });
      })
    );
  });

  constructor(
    private readonly actions$: Actions,
    private readonly store: Store<RootStoreState.State>,
    private readonly router: Router
  ) {}
}
