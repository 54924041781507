import { Component } from '@angular/core';
import { Store } from '@ngrx/store';

import { MsalAuthActions } from 'src/app/features/msal-auth';
import { RootStoreState } from 'src/app/store';

@Component({
  selector: 'app-processing-signing',
  templateUrl: './processing-signing.component.html',
  styleUrls: ['./processing-signing.component.scss'],
})
export class ProcessingSigningComponent {
  constructor(private readonly store: Store<RootStoreState.State>) {}

  reload() {
    this.store.dispatch(MsalAuthActions.RestartLogin());
  }
}
