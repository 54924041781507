<div class="card-container">
  <div class="card">
    <div class="header">
      <strong>Front of ID</strong>
    </div>
    <div class="id-photo-container" *ngIf="idImages && idImages.front">
      <div class="wrapper" [style]="frontRotationStyle">
        <img
          data-private
          class="id-image"
          src="data:image/jpg;base64,{{ idImages.front }}"
          alt="front ID image"
        />
      </div>
    </div>
    <div class="id-photo-container" *ngIf="!idImages || !idImages.front">
      <div class="wrapper" [style]="frontRotationStyle">
        <img class="id-image" src="assets/images/license-front.png" alt="front ID image" />
      </div>
    </div>
    <div class="buttons-container">
      <app-button class="expand-button" type="secondary" size="small" (click)="enlargeFrontImage()">
        <span class="fas fa-expand-alt"></span>
        <span>Expand Image</span>
      </app-button>
      <app-button class="rotate-button" type="secondary" size="small" (click)="onRotateFront()">
        <span class="fas fa-undo-alt"></span>
        <span>Rotate Image</span>
      </app-button>
    </div>
  </div>

  <div class="card">
    <div class="header">
      <strong>Back of ID</strong>
    </div>
    <div class="id-photo-container" *ngIf="idImages && idImages.back">
      <div class="wrapper" [style]="backRotationStyle">
        <img
          class="id-image"
          data-private
          src="data:image/jpg;base64,{{ idImages.back }}"
          alt="back ID image"
        />
      </div>
    </div>
    <div class="id-photo-container" *ngIf="!idImages || !idImages.back">
      <div class="wrapper" [style]="backRotationStyle">
        <img class="id-image" src="assets/images/license-back.png" alt="back ID image" />
      </div>
    </div>
    <div class="buttons-container">
      <app-button class="expand-button" type="secondary" size="small" (click)="enlargeBackImage()">
        <span class="fas fa-expand-alt"></span>
        <span>Expand Image</span>
      </app-button>
      <app-button type="secondary" size="small" (click)="onRotateBack()">
        <span class="fas fa-undo-alt"></span>
        <span>Rotate Image</span>
      </app-button>
    </div>
  </div>
</div>
