import { Injectable } from '@angular/core';

import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { WizardSelectors } from 'src/app/features/wizard';
import { RootStoreState } from 'src/app/store';

@Injectable()
export class IsCheckInActiveGuard {
  constructor(private readonly store: Store<RootStoreState.State>) {}

  canActivate(): Observable<boolean> {
    return this.store.pipe(select(WizardSelectors.hasActiveWizardUser));
  }
}
