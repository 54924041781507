<app-nav-bar>
  <ul mobile class="nav-bar__mobile__items">
    <li class="nav-bar__mobile__item" data-testid="header-faq">
      <a target="_blank" href="{{ faqUrl }}" rel="noopener">FAQs</a>
    </li>
    <li class="nav-bar__mobile__item" data-testid="header-support-phoneNumber">
      <a target="_blank" href="tel:{{ this.clearSignSupportNumber }}" rel="noopener"
      >Support: {{ this.clearSignSupportNumber }}</a
      >
    </li>
    <li class="nav-bar__mobile__item" data-testid="header-privacy-policy">
      <a target="_blank" href="https://www.amrock.com/privacy-policy" rel="noopener"
      >Privacy Policy</a
      >
    </li>
    <li class="nav-bar__mobile__item" data-testid="header-terms-of-use">
      <a target="_blank" href="https://www.amrock.com/terms-of-use" rel="noopener">Terms of Use</a>
    </li>
  </ul>
  
  <app-menu
    [isPrivacyPolicyButtonEnabled]="true"
    [isTermsOfUseButtonEnabled]="true"
  ></app-menu>
  <app-frequently-asked-questions-button [isFaqButtonEnabled]="true"></app-frequently-asked-questions-button>
  <app-support-phone-number-button></app-support-phone-number-button>
</app-nav-bar>
