import { createAction, props } from '@ngrx/store';

import { Answers, KbaResponse } from '../../models';

export const ViewInfoPage = createAction('[KBA] View KBA Information Page');

export const StartKbaQuestions = createAction('[KBA] Start KBA Questions');

export const SetKbaQuestions = createAction(
  '[KBA] Set KBA Questions',
  props<{ payload: KbaResponse }>()
);

export const AnswerKbaQuestions = createAction(
  '[KBA] Answer KBA Questions',
  props<{ payload: Answers }>()
);

export const ResetKbaState = createAction('[KBA] Reset State');

export const FetchCurrentUserNumberOfKbaAttempts = createAction(
  '[KBA] Fetch Current User Number Of Kba Attempts'
);

export const SetCurrentUserNumberOfKbaAttempts = createAction(
  '[KBA] Set Current User Number Of Kba Attempts',
  props<{ payload: number }>()
);


export const KbaAnswersSubmittedSuccess = createAction(
  '[KBA API] Answers Submitted Success',
  props<{ response: KbaResponse }>()
);

export const KbaAnswersSubmittedFailure = createAction(
  '[KBA API] Answers Submitted Failure',
  props<{ error: Error }>()
);

export const KbaPassed = createAction('[KBA API] Passed', props<{ response: KbaResponse,  }>());

export const KbaPending = createAction('[KBA API] Pending', props<{ response: KbaResponse }>());

export const KbaFailedAttempt = createAction('[KBA API] Failed 1st Attempt');

export const KbaExpiredAttempt = createAction('[KBA API] Expired 1st Attempt');

export const KbaExpired = createAction('[KBA API] Expired', props<{ code: string }>());

export const KbaFailed = createAction('[KBA API] Failed', props<{ code: string }>());
