import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { distinctUntilChanged, filter, takeUntil, tap } from 'rxjs/operators';

import { PackageUser } from 'src/app/features/package-users';
import { RootStoreState } from 'src/app/store';
import { ModalsActions, ModalSize } from 'src/app/features/modals';

import { DrawnEndorsement } from '../../models';
import { DrawSignatureComponent } from '../../components';
import { EndorsementsCreationSelectors } from '../../store';

@Component({
  selector: 'app-draw-signature-step',
  templateUrl: './draw-signature-step.component.html',
  styleUrls: ['./draw-signature-step.component.scss'],
})
export class DrawSignatureStepComponent implements OnInit, OnDestroy {
  @Input() user: PackageUser;
  @Output() stepComplete = new EventEmitter();
  signatureData: DrawnEndorsement;
  destroyedNotifier = new Subject();

  formStep: UntypedFormGroup;

  constructor(private readonly store: Store<RootStoreState.State>) {
    this.formStep = new UntypedFormGroup({
      drawnSignatureSaved: new UntypedFormControl('', [Validators.requiredTrue]),
    });
  }

  ngOnInit() {
    this.store
      .pipe(
        select(EndorsementsCreationSelectors.getDrawnSignature),
        takeUntil(this.destroyedNotifier),
        filter<DrawnEndorsement>(Boolean),
        distinctUntilChanged(),
        tap((signatureData) => {
          this.signatureData = signatureData;
          this.formStep.get('drawnSignatureSaved').setValue(true);
        })
      )
      .subscribe();
  }

  drawSignature() {
    this.store.dispatch(
      ModalsActions.SetModalComponent({
        payload: {
          component: DrawSignatureComponent,
          componentData: {
            user: this.user,
            existingSignatureData: this.signatureData,
          },
          modalTitle: 'Draw Your Signature',
          allowManualClose: false,
          modalSize: ModalSize.small,
        },
      })
    );
  }

  ngOnDestroy() {
    this.destroyedNotifier.next(undefined);
    this.destroyedNotifier.complete();
  }

  nextClick() {
    this.stepComplete.emit();
  }
}
