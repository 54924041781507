<div [ngClass]="{
    'video-list': true,
    'video-list--single': activeLayout === availableLayouts.single,
    'video-list--double': activeLayout === availableLayouts.double,
    'video-list--triple': activeLayout === availableLayouts.triple
  }"
     *ngIf="true"
     data-private
>
  <audio #localAudioElement id="localAudio" autoplay></audio>

  <div class="video-container" (mouseenter)="showRecText()"
       (mouseleave)="hideRecText()">
    <div *ngIf="(isRecordingActive$ | async) === true" class="red-dot"></div>
    <span class="rec-text"
          *ngIf="showRecordingText === true && (isRecordingActive$ | async) === true">Recording...</span>
    <video #localVideoElement class="video-feeds" autoplay>
    </video>
  </div>

  <div *ngFor="let tile of remoteVideoTiles;" class="video-container">
    <video class="video-feeds" id="video-{{tile}}" autoplay></video>
    <audio id="audio-{{tile}}" autoplay></audio>
  </div>
</div>
