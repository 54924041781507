import {
  Package,
  PackageScheduleStatus,
  PackageStatus,
  ProductType,
  TypeLookup,
} from '../../models';

export interface State {
  package: Package;
  activePackageGuid: string;
  activePackageId: number;
  activePackageState: string;
  scheduleStatus: PackageScheduleStatus;
  activePackageStatus: PackageStatus;
  productType?: ProductType;
  isValid?: boolean;
  isPreSign?: boolean;
  optOutReasons: TypeLookup[];
  totalMonthlyClosings: number | null;
}

export const initialState: State = {
  package: null,
  activePackageGuid: null,
  activePackageId: null,
  activePackageState: null,
  scheduleStatus: null,
  activePackageStatus: null,
  productType: null,
  isValid: null,
  isPreSign: true,
  optOutReasons: [],
  totalMonthlyClosings: null,
};
