<div class="lobby">
  <div class="lobby__heading">
    <h2 data-testid="lobby-header">
      Waiting Room
    </h2>
    <div class="lobby__subheading" data-testid="lobby-subheader">
      Once everyone is checked-in your signing will begin
    </div>
  </div>
  <app-lobby-participants-list
    [signingAgent]="signingAgent$ | async"
    [nonSigningAgentsExceptWitnesses]="nonSigningAgentsExceptWitnesses$ | async"
    [witnesses]="witnesses$ | async"
    [isReadonly]="true"
  >
  </app-lobby-participants-list>
</div>
