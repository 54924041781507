<button
  *ngIf="user$ | async; let user"
  [disabled]="isDisabled$ | async"
  [class.participant--active]="isSelected$ | async"
  class="participant"
  (click)="onSelectParticipant()"
>
  <img
    class="participant__image"
    [src]="imageSrc"
    [alt]="imageAltText"
    data-testid="participant-image"
  />
  <div class="participant__details">
    <h3 class="participant__name" data-private>{{ user.displayName }}</h3>
  </div>
</button>
