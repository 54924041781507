import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError } from 'rxjs/operators';

import {
  MultiFactorChallengeCompleteRequestModel,
  MultiFactorChallengeCompleteResponseModel,
  MultiFactorChallengeError,
  MultiFactorChallengeRequestModel,
  MultiFactorErrorType,
} from '../models';

@Injectable()
export class MultiFactorService {
  constructor(private readonly httpClient: HttpClient) {}

  multiFactorChallenge(multiFactorChallengeRequest: MultiFactorChallengeRequestModel) {
    return this.httpClient.post(`authentication/challenge`, multiFactorChallengeRequest).pipe(
      catchError((httpError: HttpErrorResponse) => {
        let errorType: MultiFactorErrorType = MultiFactorErrorType.Unknown;
        switch (httpError?.status) {
          case 400:
            errorType = MultiFactorErrorType.InvalidPhone;
            break;
          case 403:
            errorType = MultiFactorErrorType.InvalidSMS;
            break;
          case 404:
            errorType = MultiFactorErrorType.CreateChallenge404;
            break;
        }

        throw new MultiFactorChallengeError(httpError, errorType);
      })
    );
  }

  completeMFAChallenge(multiFactorChallengeRequest: MultiFactorChallengeCompleteRequestModel) {
    return this.httpClient
      .post<MultiFactorChallengeCompleteResponseModel>(
        `authentication/challenge/complete`,
        multiFactorChallengeRequest
      )
      .pipe(
        catchError((httpError: HttpErrorResponse) => {
          const errorType =
            httpError?.status === 404
              ? MultiFactorErrorType.CodeExpired
              : MultiFactorErrorType.Unknown;

          throw new MultiFactorChallengeError(httpError, errorType);
        })
      );
  }
}
