import {
  SignerDetailsStatus,
  SignerDetailsSubmissionErrors,
  StateList
} from '../../models';

export interface State {
  signerDetailsStatus: SignerDetailsStatus;
  states: StateList,
  areSignerDetailsSubmitted: boolean,
  validationErrors: SignerDetailsSubmissionErrors,
}

export const initialState: State = {
  signerDetailsStatus: null,
  states: [],
  areSignerDetailsSubmitted: false,
  validationErrors: [],
};
