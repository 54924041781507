<app-wizard-step-wrapper
  [showStep]="showStep"
  [stepTitle]="stepMetadata.stepName"
  [formGroup]="formStep"
>
  <div class="step-container id-app-access" *ngIf="!isMobile">
    <h3 class="form-step__subheading">
      <span data-private>{{ user?.firstName }}</span>, please open the Clear Sign ID scanner to scan your ID with your phone
      or tablet camera.
    </h3>

    <h3 id="options-count-heading">2 options to open the ID scanner:</h3>

    <div class="id-app-access-options">
      <div class="id-app-access-option">
        <div class="id-app-access-option__instructions">
          <h3 class="id-app-access-option__instructions__title">
            Option 1: Send link to your phone or tablet
          </h3>
          <p class="id-app-access-option__instructions__instruction">
            Alternatively, you can send a link to your phone number. Open the link to
            scan your ID.
          </p>
        </div>
        <div class="id-app-access-option__action">
          <app-rds-input
            [(inputModel)]="phoneNumberOverride"
            (click)="clearPhoneNumberIfDefault()"
          ></app-rds-input>
          <app-button
            style="margin-top: 6px"
            type="primary"
            (click)="sendAccessLinkSms()"
            [dataTestId]="'id-app-access-send-link'"
          >
            {{ sendAccessLinkSmsButtonText }}
          </app-button>
          <div *ngIf="!isPhoneNumberValid" class="error-message">
            {{ validationErrorMessage }}
          </div>
          <div id="sms-error-section">
            <p
              *ngIf="hasAccessLinkSmsSentSuccessfully && isPhoneNumberValid"
              style="color: green"
            >
              Link successfully sent
            </p>
            <p
              *ngIf="hasAccessLinkSmsSentSuccessfully === false && isPhoneNumberValid"
              style="color: red"
            >
              SMS could not be sent. Try another number or scan the QR code.
            </p>
          </div>
        </div>
        <div class="id-app-access-option__support app-access-help">
          <div class="app-access-help__header">
            <app-spark-icon icon="call-team-member" size="24"></app-spark-icon>
            <span class="id-app-access-option__support__title">Need help?</span>
          </div>
          <p>Visit <a href="{{ faqUrl }}" rel="noopener" target="_blank">FAQs</a> for tips or</p>
          <p data-testid="contact-support">
            Contact Support: <app-support-phone></app-support-phone>
          </p>
        </div>
      </div>

      <div class="id-app-access-option">
        <div class="id-app-access-option__instructions">
          <h3 class="id-app-access-option__instructions__title">Option 2: Scan QR code</h3>
        </div>
        <div class="id-app-access-option__action">
          <app-qr-code-image
            [url]="accessLink | async"
            size="240"
            margin="0"
            data-private
          ></app-qr-code-image>
        </div>
        <div class="id-app-access-option__instructions">
          <p class="id-app-access-option__instructions__instruction">
            Use your phone's camera to scan the QR code above.
          </p>
        </div>
        <app-collapsible type="parent" class="app-access-help" [dataTestId]="'scanning-tips'">
          <div slot="title">
            <span>Scanning tips:</span>
          </div>

          <app-collapsible
            title="How do I scan the QR Code?"
            type="child"
            [dataTestId]="'how-do-i-scan'"
          >
            <ol>
              <li>Open the camera on your phone.</li>
              <li>Select the rear facing camera.</li>
              <li>
                Aim your camera at the QR code until it appears on your phone screen. If your device
                recognizes the QR code a notification will appear.
              </li>
              <li>Tap the notification to open the link.</li>
            </ol>
          </app-collapsible>

          <app-collapsible
            title="I don't have a QR scanner"
            type="child"
            [dataTestId]="'i-dont-have-a-scanner'"
          >
            <ol>
              <li>
                Open the
                <a href="https://apps.apple.com/" target="_blank" rel="noopener">App Store</a> or
                <a href="https://play.google.com/store/apps" target="_blank" rel="noopener"
                  >Google Play Store</a
                >.
              </li>
              <li>Search for "QR scanner".</li>
              <li>Download and install a QR scanner from the top results.</li>
              <li>
                Open the new QR scanner app and follow the directions on how to scan the QR code.
              </li>
            </ol>

            <p>If there are no directions within the app try these steps:</p>
            <ol>
              <li>With the QR scanner app open the rear facing camera.</li>
              <li>
                Aim your camera at the QR code until it appears on your phone screen. If your device
                recognizes the QR code a notification will appear.
              </li>
              <li>Tap the notification to open the link.</li>
            </ol>
          </app-collapsible>

          <app-collapsible
            title="Nothing is scanning"
            type="child"
            [dataTestId]="'nothing-scanning'"
          >
            <p>
              There may be a delay when scanning with your camera - give it a moment while keeping
              the QR code in focus.
            </p>

            <p>If this continues try using a different QR scanner.</p>
          </app-collapsible>
        </app-collapsible>
      </div>
    </div>

    <app-button-container>
      <app-button
        [dataTestId]="'next-button-id-qr'"
        type="primary"
        *ngIf="shouldBypassRealId"
        (click)="goToNextStep()"
      >
        Next
      </app-button>
    </app-button-container>
  </div>

  <div class="app-access-link step-container" *ngIf="isMobile">
    <h3 class="form-step__subheading">
      <span data-private>{{ user?.firstName }}</span>, you're ready to start the ID verification process.
    </h3>

    <app-info-box imagePath="assets/images/license-front.png" alt="front ID image">
      <p>To complete this process you'll need your driver's license, or state ID.</p>
    </app-info-box>

    <app-button-container>
      <app-button
        [dataTestId]="'next-button-id-qr'"
        type="primary"
        *ngIf="shouldBypassRealId"
        (click)="goToNextStep()"
      >
        Next
      </app-button>
      <app-button
        data-testid="start-id-verification-button"
        type="primary"
        *ngIf="accessLink | async as link"
        (click)="navigateTo(link)"
      >
        Start ID Verification
      </app-button>
    </app-button-container>
  </div>
</app-wizard-step-wrapper>
