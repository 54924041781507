import { Component, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';

import { Observable } from 'rxjs';

import { RootStoreState } from 'src/app/store';

import { ModalsActions } from '../../../modals';
import { TermsAndConditionsConsent } from '../../models';
import { ConsentsActions, ConsentsSelectors } from '../../store';

@Component({
  selector: 'app-terms-of-use-consent-modal',
  templateUrl: './terms-of-use-consent-modal.component.html',
  styleUrls: ['./terms-of-use-consent-modal.component.scss'],
})
export class TermsOfUseConsentModalComponent implements OnInit, OnDestroy {
  static identifier = 'TermsOfUseConsentModalComponent';
  termsAndConditionsConsent$: Observable<TermsAndConditionsConsent>;

  constructor(private readonly store: Store<RootStoreState.State>) {}

  ngOnInit(): void {
    this.termsAndConditionsConsent$ = this.store.pipe(
      select(ConsentsSelectors.getTermsAndConditionsConsent)
    );
  }

  back() {
    this.store.dispatch(ModalsActions.ClearModalComponent());
  }

  accept() {
    this.store.dispatch(ConsentsActions.SetEnabledTermsOfUseConsentCheckBox());
    this.store.dispatch(ModalsActions.ClearModalComponent());
  }

  ngOnDestroy() {
    this.store.dispatch(
      ModalsActions.ClearModalComponentIfOpen({
        payload: { component: TermsOfUseConsentModalComponent },
      })
    );
  }
}
