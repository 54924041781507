import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { ConsentsModule } from 'src/app/features/consents';
import { SharedModule } from 'src/app/features/shared';
import { PINValidationModule } from '../pin-validation';

import { CommissionSelectionReducers, CommissionSelectionEffects } from './store';
import { CommissionSelectionComponent, ExpiredCommissionPageComponent } from './containers';
import { ExpiredCommissionComponent } from './components';
import { CertificateExpiredComponent } from './components/certificate-expired/certificate-expired.component';
import { CommissionSelectionService } from './services';
import { WizardModule } from '../wizard/wizard.module';

@NgModule({
  declarations: [
    CommissionSelectionComponent,
    ExpiredCommissionComponent,
    ExpiredCommissionPageComponent,
    CertificateExpiredComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    ReactiveFormsModule,
    ConsentsModule,
    PINValidationModule,
    StoreModule.forFeature('commissions', CommissionSelectionReducers.commissionSelectionReducer),
    EffectsModule.forFeature([CommissionSelectionEffects]),
    WizardModule,
  ],
  providers: [CommissionSelectionService],
  exports: [CommissionSelectionComponent, CertificateExpiredComponent],
})
export class CommissionsModule {}
