import { Action, createReducer, on } from '@ngrx/store';

import { DocumentEndorsementLocation } from 'src/app/features/endorsements';

import { PackageDocument } from '../../models';
import { DocumentsActions } from '../actions';
import { DocumentsState } from '../state';

const reducer = createReducer(
  DocumentsState.initialState,
  on(DocumentsActions.UpdateDocumentLoadingStatus, (state: DocumentsState.State, { payload }) => ({
    ...state,
    loadingStatus: payload,
  })),
  on(DocumentsActions.SetDocuments, (state: DocumentsState.State, { payload }) => {
    return DocumentsState.adapter.addMany(payload.signableDocuments, {
      ...state,
      loadingStatus: {
        loadedPercentage: 100,
        isLoading: false,
        isLoaded: true,
      },
      documentEndorsementLocations: payload.documentEndorsementLocations,
      totalSignableDocuments: totalSignableDocuments(
        payload.signableDocuments,
        payload.documentEndorsementLocations
      ),
    });
  }),
  on(DocumentsActions.ProgressBarOpened, (state: DocumentsState.State) => ({
    ...state,
    loadingStatus: {
      ...state.loadingStatus,
      isProgressBarOpen: true,
    },
  })),
  on(DocumentsActions.ProgressBarClosed, (state: DocumentsState.State) => ({
    ...state,
    loadingStatus: {
      ...state.loadingStatus,
      isProgressBarOpen: false,
    },
  })),
  on(DocumentsActions.SetUpdatedSmartDocuments, (state: DocumentsState.State, { payload }) => {
    const changes = payload.signableDocuments.map((doc) => {
      return {
        id: doc.packageDocumentId,
        changes: {
          packageDocumentImageString: doc.packageDocumentImageString,
        },
      };
    });

    return DocumentsState.adapter.updateMany(changes, state);
  }),
  on(
    DocumentsActions.SetUpdatedSmartDocumentsForSigning,
    (state: DocumentsState.State, { payload }) => {
      const changes = payload.signableDocuments.map((doc) => {
        return {
          id: doc.packageDocumentId,
          changes: {
            packageDocumentImageString: doc.packageDocumentImageString,
          },
        };
      });
      return DocumentsState.adapter.updateMany(changes, state);
    }
  ),
  on(DocumentsActions.SetActiveDocument, (state: DocumentsState.State, { payload }) => ({
    ...state,
    activePackageDocumentId: payload,
  })),
  // TODO: Story 33296 Delete or uncomment out based on testing, this may be necessary
  // to include to make hybrid feel snappy. The concern is there may be
  // a delay that is undesirable on a hybrid with document navigation.
  //on(
  //  DocumentsActions.NavigateToDocument,
  //  (state: DocumentsState.State, { payload }) => ({
  //    ...state,
  //    activePackageDocumentId: payload,
  //  })
  //),
  //on(
  //  DocumentsActions.NavigateToNextDocument,
  //  (state: DocumentsState.State) => ({
  //    ...state,
  //    activePackageDocumentId:
  //      state.entities[state.activePackageDocumentId].nextDocumentId,
  //  })
  //),
  //on(
  //  DocumentsActions.NavigateToPreviousDocument,
  //  (state: DocumentsState.State) => ({
  //    ...state,
  //    activePackageDocumentId:
  //      state.entities[state.activePackageDocumentId].previousDocumentId,
  //  })
  //),
  on(
    DocumentsActions.FetchPreviewDocuments || DocumentsActions.FetchCompletedDocuments,
    (state: DocumentsState.State) => ({
      ...state,
      viewableDocumentSource: null,
    })
  ),
  on(DocumentsActions.SetViewableDocumentSource, (state: DocumentsState.State, { payload }) => ({
    ...state,
    viewableDocumentSource: payload,
  })),
  on(DocumentsActions.SetViewerWidth, (state: DocumentsState.State, { payload }) => ({
    ...state,
    viewerScale: payload,
  })),
  on(
    DocumentsActions.SetAreAllSessionDocumentsLoaded,
    (state: DocumentsState.State, { payload }) => ({
      ...state,
      areAllSessionsDocumentsLoaded: payload,
    })
  ),
  on(
    DocumentsActions.SetAreParticipantDocumentsShown,
    (state: DocumentsState.State, { payload }) => ({
      ...state,
      areParticipantDocumentsShown: payload,
    })
  ),
  on(
    DocumentsActions.SetIsExecutedClosingPackageDownloading,
    (state: DocumentsState.State, { payload }) => ({
      ...state,
      isExecutedClosingPackageDownloading: payload.isExecutedClosingPackageDownloading,
    })
  )
);

function totalSignableDocuments(
  packageDocuments: PackageDocument[],
  endorsementLocations: DocumentEndorsementLocation[]
): number {
  return packageDocuments.filter((packageDocument) =>
    hasSignableEndorsements(packageDocument, endorsementLocations)
  ).length;
}

function hasSignableEndorsements(
  packageDocument: PackageDocument,
  endorsementLocations: DocumentEndorsementLocation[]
): boolean {
  return (
    endorsementLocations.filter(
      (endorsementLocation) =>
        packageDocument.endorsementLocationIds.includes(
          endorsementLocation.documentEndorsementLocationId
        ) && endorsementLocation.isSignableInCurrentSession
    ).length > 0
  );
}

export function documentsReducer(state: DocumentsState.State | undefined, action: Action) {
  return reducer(state, action);
}
