import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { NgxMaskModule } from 'ngx-mask';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatIconModule } from '@angular/material/icon';

import { MultiFactorModule } from 'src/app/features/multi-factor';
import { SharedModule } from 'src/app/features/shared';
import { WizardModule } from '../wizard/wizard.module';

import { SignerDetailsStepComponent, VerifySignerDetailsComponent } from './containers';
import { SignerDetailsService } from './services';
import { SignerDetailsEffects } from './store/effects';
import { SignerDetailsReducers } from './store/reducers';

@NgModule({
  declarations: [SignerDetailsStepComponent, VerifySignerDetailsComponent],
  imports: [
    CommonModule,
    SharedModule,
    ReactiveFormsModule,
    MultiFactorModule,
    StoreModule.forFeature('signerDetails', SignerDetailsReducers.signerDetailsReducers),
    EffectsModule.forFeature([SignerDetailsEffects.SignerDetailsEffects]),
    NgxMaskModule.forRoot(),
    DragDropModule,
    MatIconModule,
    MatTooltipModule,
    WizardModule,
  ],
  providers: [SignerDetailsService],
  exports: [SignerDetailsStepComponent, VerifySignerDetailsComponent],
})
export class SignerDetailsModule {}
