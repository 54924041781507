<div class="side-bar">
  <h5 data-sidebar-heading>About This Signing</h5>

  <div class="side-bar__signing-details" data-sidebar-signing-details>
    <app-signing-details-card></app-signing-details-card>
  </div>

  <div class="side-bar__dynamic-content">
    <app-signings-monthly-card
      *ngIf="dynamicContent === SideBarContentEnum.SigningsMonthly"
    ></app-signings-monthly-card>

    <app-participant-list
      class="side-bar__participant-list"
      *ngIf="dynamicContent === SideBarContentEnum.ParticipantList"
    >
    </app-participant-list>
  </div>
</div>
