import { computed, inject, Injectable, Signal, signal, WritableSignal } from '@angular/core';

import { Store } from '@ngrx/store';
import { take, tap } from 'rxjs';

import { NavigatorMediaDeviceWrapperService } from 'src/app/features/audio-video-connection-monitor/services';
import { AudioVideoConnectionMonitorSelectors } from 'src/app/features/audio-video-connection-monitor/store';
import { AudioVideoCheckActions } from 'src/app/features/av-check/store';
import * as AudioVideoCheckSelectors from 'src/app/features/av-check/store/selectors/audio-video-check.selectors';

@Injectable({
  providedIn: 'root',
})
export class MicCheckService {
  private readonly navigatorMediaDeviceWrapperService = inject(NavigatorMediaDeviceWrapperService);
  private readonly store = inject(Store);

  audioContext: AudioContext;
  analyser: AnalyserNode;
  microphone: MediaStreamAudioSourceNode;
  micVisual: number;

  micLevel: WritableSignal<number> = signal(0);
  micBarProgressClass: Signal<string> = computed(() =>
    this.micLevel() < 30 ? 'mat-mdc-progress-bar-low-level' : 'mat-mdc-progress-bar-high-level'
  );

  currentMicDevice: Signal<MediaDeviceInfo> = this.store.selectSignal(
    AudioVideoConnectionMonitorSelectors.getSelectedAudioDevice
  );

  micBarProgress: Signal<number> = computed(() =>
    this.micFailure() ? 0 : this.micLevel()
  );

  micFailure: Signal<boolean> = computed(() => {
    const currentDevice = this.currentMicDevice();
    return currentDevice === null || currentDevice === undefined || currentDevice.deviceId === '';
  });

  selectAudioInputDevices: Signal<MediaDeviceInfo[]> = this.store.selectSignal(
    AudioVideoCheckSelectors.selectAudioInputDevices
  );

  clearMicrophone() {
    this.stopTracking();
    this.store.dispatch(AudioVideoCheckActions.ClearMicrophone());
  }

  selectMicrophone(microphone: MediaDeviceInfo) {
    this.store.dispatch(AudioVideoCheckActions.SelectMicrophone({ microphone }));
    this.startTracking(this.currentMicDevice());
  }

  async startTracking(selectedMic: MediaDeviceInfo) {
    if (selectedMic === null) {
      return;
    }

    try {
      this.navigatorMediaDeviceWrapperService
        .getUserMedia({ audio: { deviceId: selectedMic.deviceId } })
        .pipe(
          take(1),
          tap((stream) => {
            this.audioContext = new AudioContext();
            this.analyser = this.audioContext.createAnalyser();
            this.microphone = this.audioContext.createMediaStreamSource(stream);
            this.microphone.connect(this.analyser);

            this.updateLevel();
          })
        )
        .subscribe();
    } catch (e: unknown) {}
  }

  stopTracking() {
    this.micLevel.set(0);

    cancelAnimationFrame(this.micVisual);

    if (this.audioContext !== null && this.audioContext.state !== 'closed') {
      this.audioContext.close();
    }
  }

  updateLevel() {
    const dataArray = new Uint8Array(this.analyser.frequencyBinCount);
    this.analyser.getByteFrequencyData(dataArray);

    const averageLevel = dataArray.reduce((acc, val) => acc + val, 0) / dataArray.length;

    this.micLevel.set(Math.round((Math.log(averageLevel + 1) / Math.log(256)) * 100));

    this.micVisual = requestAnimationFrame(() => this.updateLevel());
  }
}
