import { CheckInStatus } from './CheckInStatus.model';
import { PackageUserRole } from './package-user-role.enum';

export interface PackageUser {
  firstName: string;
  lastName: string;
  signatureName?: string;
  prefix?: string;
  suffix?: string;
  packageUserGuid: string;
  packageUserId: number;
  packageId: number;
  signingAgentInfoId: number;
  userRoleCode: PackageUserRole;
  emailAddress?: string;
  phoneNumber?: string;
  isMfaRequired?: boolean;
  isMissingVerificationType?: boolean;
  pinAttempts?: number;
  deviceCode?: string;
  checkInStatus?: CheckInStatus;
}
