import { CommonModule, HashLocationStrategy, LocationStrategy } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { ErrorHandler, NgModule, Provider } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MsalInterceptor } from '@azure/msal-angular';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';

import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DevelopmentModule } from './features/_development/development.module';
import { AuditLogsModule } from './features/audit-logs/audit-logs.module';
import { CheckInModule } from './features/check-in';
import { ClientModule } from './features/client';
import { CommissionsModule } from './features/commissions/commissions.module';
import { ConsentsModule } from './features/consents';
import { DeviceGroupModule } from './features/device-group';
import { DocumentsModule } from './features/documents/documents.module';
import { DuplicateSessionDetectorModule } from './features/duplicate-session-detector/duplicate-session-detector.module';
import { EndorsementsModule } from './features/endorsements/endorsements.module';
import { FeatureManagementDirectiveModule } from './features/feature-management/directives/feature-management-directive.module';
import { FeedbackModule } from './features/feedback/feedback.module';
import { IdVerificationModule } from './features/id-verification/id-verification.module';
import { KnowledgeBasedAuthenticationModule } from './features/knowledge-based-authentication/knowledge-based-authentication.module';
import { LayoutModule } from './features/layout/layout.module';
import { LobbyModule } from './features/lobby/lobby.module';
import { ModalsModule } from './features/modals/modals.module';
import { RedirectInformationComponent } from './features/msal-auth';
import { NotificationsModule } from './features/notifications/notifications.module';
import { PackageUsersModule } from './features/package-users';
import { PackagesModule } from './features/packages';
import { ParticipantVerificationModule } from './features/participant-verification/participant-verification.module';
import { PendoAdapterModule } from './features/pendo-adapter/pendo-adapter.module';
import { PINValidationModule } from './features/pin-validation';
import { RulesModule } from './features/rules/rules.module';
import { SignalRModule } from './features/signal-r/signal-r.module';
import { WizardModule } from './features/wizard';
import { BaseUrlInterceptor } from './interceptors/base-url.interceptor';
import { LoadingInterceptor } from './interceptors/loading.interceptor';
import { ErrorHandlerService } from './services/error-handler.service';
import { NavigationService } from './services/navigation.service';
import {RootStoreModule} from './store';

const angularModules = [
  BrowserModule,
  HttpClientModule,
  CommonModule,
  ReactiveFormsModule,
  BrowserAnimationsModule,
];

const rootAppModules = [AppRoutingModule, RootStoreModule];

const featureModules = [
  AuditLogsModule,
  CheckInModule,
  ClientModule,
  CommissionsModule,
  ConsentsModule,
  DeviceGroupModule,
  DocumentsModule,
  EndorsementsModule,
  FeedbackModule,
  IdVerificationModule,
  KnowledgeBasedAuthenticationModule,
  LayoutModule,
  LobbyModule,
  NotificationsModule,
  ModalsModule,
  PackagesModule,
  PackageUsersModule,
  ParticipantVerificationModule,
  PendoAdapterModule,
  PINValidationModule,
  SignalRModule,
  RulesModule,
  WizardModule,
  FeatureManagementDirectiveModule,
  DuplicateSessionDetectorModule
];

const providers: Provider[] = [
  {
    provide: HTTP_INTERCEPTORS,
    useClass: BaseUrlInterceptor,
    multi: true,
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: LoadingInterceptor,
    multi: true,
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: MsalInterceptor,
    multi: true,
  },
  {
    provide: LocationStrategy,
    useClass: HashLocationStrategy,
  },
  {
    provide: ErrorHandler,
    useClass: ErrorHandlerService,
  },
];

const developmentModules = [];
if (!environment.production) {
  developmentModules.push(
    StoreDevtoolsModule.instrument({
      actionsBlocklist: ['[Duplicate Session Detector] Set Is Tab Active'],
    })
  );
  developmentModules.push(DevelopmentModule);
}

@NgModule({
  declarations: [AppComponent, RedirectInformationComponent],
  imports: [...angularModules, ...rootAppModules, ...featureModules, ...developmentModules],
  providers,
  bootstrap: [AppComponent],
})
export class AppModule {
  // NavigationService is injected below to listen for route changes and maintain a history of routes.
  // Injecting it into App.Module makes it available to child modules even if it's not injected
  // into their components, so do not remove.
  constructor(navigationService: NavigationService) {}
}
