import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';

import { of } from 'rxjs';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';

import { DeviceGroupActions } from 'src/app/features/device-group';
import { DocumentsActions } from 'src/app/features/documents';
import { EndorsementsActions } from 'src/app/features/endorsements';
import { ModalsActions } from 'src/app/features/modals';
import { NotificationsActions, NotificationType } from 'src/app/features/notifications';
import { ExceptionType } from 'src/app/features/notifications/models';
import { PackagesSelectors } from 'src/app/features/packages';
import { PackageStatus } from 'src/app/features/packages/models';
import { SignalRActions } from 'src/app/features/signal-r/store/actions';
import { SigningRoomActions } from 'src/app/features/signing-room/store';
import { RootStoreState } from 'src/app/store';
import { v4 as uuid } from 'uuid';

import { KronService } from '../../services';
import { KronActions } from '../actions';

@Injectable()
export class KronEffects {
  signingSessionStarted$ = createEffect(() =>
    this.actions$.pipe(
      ofType(KronActions.SigningSessionStarted),
      switchMap(() => [
        DeviceGroupActions.GetCurrentDeviceUsers(),
        SigningRoomActions.SigningSessionJoined(),
        DocumentsActions.FetchSigningSessionDocuments({
          payload: { refetchSmartDocs: true },
        }),
        EndorsementsActions.FetchEndorsementImages(),
        EndorsementsActions.FetchSystemEndorsementImages(),
        ModalsActions.DisableGlobalSpinner(),
      ])
    )
  );

  configureListeners$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SignalRActions.SignalRRoomJoined),
        map(() => {
          this.kronService.configureListeners();
        })
      ),
    { dispatch: false }
  );

  getSessionStatuses$ = createEffect(() =>
    this.actions$.pipe(
      ofType(KronActions.GetSessionStatuses),
      withLatestFrom(
        this.store.pipe(select(PackagesSelectors.getActivePackageGuid)),
        this.store.pipe(select(PackagesSelectors.getActivePackageStatus))
      ),
      switchMap(([_, packageGuid, packageStatus]) => {
        return this.kronService.getSessionStatus(packageGuid).pipe(
          switchMap((payload) => {
            const { isComplete, isSessionEnded, signingEndedWithError, signingEndSessionStarted } =
              payload;
            let actions: Array<any> = [
              KronActions.SetSessionStatuses({
                payload,
              }),
            ];
            const endSessionStartedButHasNotFinished =
              signingEndSessionStarted && !(isComplete || isSessionEnded);
            if (
              endSessionStartedButHasNotFinished &&
              !this.router.url.includes('/kron/session-ending')
            ) {
              actions = [
                ...actions,
                KronActions.NavigateToSessionEnding(),
                ModalsActions.ClearModalComponent(),
              ];
            }
            const isEndSessionSuccessful = isComplete && !signingEndedWithError;
            const isEndSessionFailure = isSessionEnded && signingEndedWithError;
            if (isEndSessionSuccessful) {
              actions = [
                ...actions,
                KronActions.SetEndSessionSuccessful(),
                ModalsActions.ClearModalComponent(),
              ];
            }
            if (isEndSessionFailure || packageStatus === PackageStatus.SIGNINGFAILED || packageStatus === PackageStatus.VAULTINGFAILED) {
              actions = [
                ...actions,
                KronActions.SetEndSessionFailure(),
                ModalsActions.ClearModalComponent(),
              ];
            }
            return actions;
          }),
          catchError((err) =>
            of(
              NotificationsActions.AddNotification({
                payload: {
                  exception: err,
                  notificationType: NotificationType.Error,
                  id: uuid(),
                  text: 'Failed to get Session End status aggregates',
                  exceptionType: ExceptionType.None,
                },
              })
            )
          )
        );
      })
    )
  );

  constructor(
    private readonly actions$: Actions,
    private readonly kronService: KronService,
    private readonly router: Router,
    private readonly store: Store<RootStoreState.State>
  ) {}
}
