import { Inject, Injectable } from '@angular/core';
import { Route } from '@angular/router';
import { select, Store } from '@ngrx/store';

import { Observable, of } from 'rxjs';
import { filter, first, switchMap } from 'rxjs/operators';

import { PackagesActions, PackagesSelectors } from '../features/packages';
import { ProductType } from '../features/packages/models';
import { DeviceValidatorServiceFactory } from '../features/packages/services/device-validator/device-validator-service.factory';
import { RootStoreState } from '../store';
import { windowToken } from '../window.token';

@Injectable({
  providedIn: 'root',
})
export class PortalDeviceValidatorGuard {
  constructor(
    private readonly deviceValidatorServiceFactory: DeviceValidatorServiceFactory,
    private readonly store: Store<RootStoreState.State>,
    @Inject(windowToken) private readonly windowRef: Window
  ) {}

  canLoad(route: Route): Observable<boolean> {
    // Wait until the package is loaded before checking the browser
    // the effect that handles PackagesActions.RedirectUnsupportedBrowser needs the packageGuid, so make sure it's available by waiting for it.
    return this.store.pipe(
      select(PackagesSelectors.getPackage),
      filter((p) => !!p),
      switchMap((p) => {
        // Presign is going to use the rules setup for RON browser support
        const deviceValidatorService = this.deviceValidatorServiceFactory.getDeviceValidatorServiceForProduct(
          ProductType.RemoteSigning
        );
        const isBrowserSupported = deviceValidatorService.isBrowserSupported(
          this.windowRef.navigator.userAgent
        );

        if (!isBrowserSupported) {
          const queryParams = {
            package: p.packageGuid,
          };

          this.store.dispatch(
            PackagesActions.RedirectUnsupportedBrowser({
              queryParams,
            })
          );
        }
        return of(isBrowserSupported);
      }),
      first()
    );
  }
}
