import { IConfiguration, IConfig } from '@microsoft/applicationinsights-web';

export const ApplicationInsightConfig: IConfiguration & IConfig = {
  autoTrackPageVisitTime: true,
  disableDataLossAnalysis: false,
  disableFetchTracking: false,
  enableAjaxErrorStatusText: true,
  enableRequestHeaderTracking: true,
  enableResponseHeaderTracking: true,
  enableUnhandledPromiseRejectionTracking: true,
  loggingLevelTelemetry: 2,
};
