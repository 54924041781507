import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';

import { KbaFailureReason } from 'src/app/features/knowledge-based-authentication/models';
import { SignalRActions } from 'src/app/features/signal-r/store';
import { RootStoreState } from 'src/app/store';

@Component({
  selector: 'app-kba-fail',
  templateUrl: './kba-fail.component.html',
  styleUrls: ['./kba-fail.component.scss'],
})
export class KbaFailComponent implements OnInit {
  title: string;
  message: string;

  constructor(private readonly store: Store<RootStoreState.State>) {}

  ngOnInit(): void {
    this.store.dispatch(SignalRActions.StopSignalRConnection());

    const failureReason = history.state.data.transactionReasonCode;
    const isFailedUser = history.state.data.isFailedUser;

    this.title = this.getTitle(failureReason, isFailedUser);
    this.message = this.getMessage(failureReason, isFailedUser);
  }

  getTitle(failureReason: string, isFailedUser: boolean): string {
    if (!isFailedUser) {
      return 'Unable to verify all participants. Signing session is canceled';
    }

    if (failureReason === KbaFailureReason.TRANSACTIONEXPIRED) {
      return 'Oh no! You ran out of time.';
    }

    return 'Unable to verify participant. Signing session is canceled';
  }

  getMessage(failureReason: string, isFailedUser: boolean): string {
    if (!isFailedUser) {
      return 'One of the participants did not pass identification verification.';
    }

    if (failureReason === KbaFailureReason.INDIVIDUALNOTFOUND) {
      return 'We are unable to generate security questions for one or more participants at this time.';
    }

    if (failureReason === KbaFailureReason.TRANSACTIONEXPIRED) {
      return 'Your 2 minutes to answer the security questions is up and you have no more attempts left.';
    }

    return 'You did not pass the security question verification.';
  }
}
