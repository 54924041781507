import { Component, ElementRef, HostListener, ViewChild } from '@angular/core';

@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss'],
})
export class NavBarComponent {
  @ViewChild('imageDropdown', { read: ElementRef }) imageDropdownRef: ElementRef<HTMLElement>;
  showMobileNav = false;

  @HostListener('document:click', ['$event'])
  documentClick(event: { target: any }) {
    if (this.showMobileNav && !this.imageDropdownRef?.nativeElement?.contains(event.target)) {
      this.showMobileNav = false;
    }
  }
}
