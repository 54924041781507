<app-wizard-step-wrapper
  [showStep]="showStep"
  [stepTitle]="stepMetadata.stepName"
  [formGroup]="formStep"
>
  <div class="step-container">
    <h3 class="form-step__subheading" *ngIf="!!user">
      <span data-private>{{ user?.firstName }}</span>, gather the items below before you start this process
    </h3>
    <div class="info-collection">
      <app-info-box
        imagePath="assets/images/state_id.svg"
        lowerTitle="Driver's License or State ID"
        altText="two people carrying giant sized identification card"
      >
        <p>You'll be scanning one of these with your mobile device to verify your identity.</p>
      </app-info-box>

      <app-info-box
        imagePath="assets/images/mobile_device.svg"
        lowerTitle="Smart Phone or Tablet"
        altText="person pointing at giant sized smart phone"
      >
        <p>
          You'll need an up to date <strong>tablet</strong> or <strong>smart phone</strong> to scan
          your ID. You can scan a QR code or a link can be sent by SMS.
        </p>
      </app-info-box>
    </div>

    <app-button-container>
      <app-button [dataTestId]="'next-button-id-intro'" type="primary" (click)="goToNextStep()">
        Next
      </app-button>
    </app-button-container>
  </div>
</app-wizard-step-wrapper>
