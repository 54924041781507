import { createFeatureSelector, createSelector } from '@ngrx/store';

import { EndorsementType } from 'src/app/features/endorsements/models';
import { getRulesForUserRole } from 'src/app/features/rules/store/selectors/rules.selectors';

import { DrawnEndorsement, EndorsementSelection, SignatureType } from '../../models';
import { EndorsementImageCreationPreview } from '../../models/EndorsementImageCreationPreview.model';
import { EndorsementsCreationReducers } from '../reducers';
import { EndorsementsCreationState } from '../state';

export const getEndorsementSelectionState = createFeatureSelector<EndorsementsCreationState.State>(
  EndorsementsCreationReducers.endorsementsCreationFeatureKey
);

export const getSignaturePreviews = createSelector(
  getEndorsementSelectionState,
  (state: EndorsementsCreationState.State) => state.signatureSelection.previews
);

export const getActiveSignatureId = createSelector(
  getEndorsementSelectionState,
  (state: EndorsementsCreationState.State) => state.signatureSelection.activeSignature
);

export const getInitialsPreviews = createSelector(
  getEndorsementSelectionState,
  (state: EndorsementsCreationState.State) => state.initialsSelection.previews
);

export const getActiveInitialsId = createSelector(
  getEndorsementSelectionState,
  (state: EndorsementsCreationState.State) => state.initialsSelection.activeInitials
);

export const getActiveInitialsIndex = createSelector(
  getEndorsementSelectionState,
  (state: EndorsementsCreationState.State) => ({
    activeIndex: state.initialsSelection.activeInitials,
    staticSelection: state.initialsSelection.currentSelectionIsStatic,
  })
);

export const getActiveSignatureIndex = createSelector(
  getEndorsementSelectionState,
  (state: EndorsementsCreationState.State) => ({
    activeIndex: state.signatureSelection.activeSignature,
    staticSelection: state.signatureSelection.currentSelectionIsStatic,
  })
);

export const getActiveSignatureImage = createSelector(
  getSignaturePreviews,
  getActiveSignatureId,
  (previews, activeSignature) => (previews ? previews[activeSignature] : null)
);

export const getActiveInitialsImage = createSelector(
  getInitialsPreviews,
  getActiveInitialsIndex,
  (previews, activeInitials) => (previews ? previews[activeInitials.activeIndex] : null)
);

export const getAreSelectionsSaved = createSelector(
  getEndorsementSelectionState,
  (state: EndorsementsCreationState.State) => state.selectionsSaved ?? false
);

export const getDrawnSignature = createSelector(
  getEndorsementSelectionState,
  (state: EndorsementsCreationState.State) => state.drawnSignature
);

export const getDrawnInitials = createSelector(
  getEndorsementSelectionState,
  (state: EndorsementsCreationState.State) => state.drawnInitials
);

export const getDrawnSignatureSvgImage = createSelector(
  getEndorsementSelectionState,
  (state: EndorsementsCreationState.State) => {
    if (state.drawnSignature?.svgImageString) {
      return state.drawnSignature?.svgImageString;
    }
    return null;
  }
);

export const getDrawnInitialsSvgImage = createSelector(
  getEndorsementSelectionState,
  (state: EndorsementsCreationState.State) => state.drawnInitials?.svgImageString
);

export const getActiveSignatureImageConfirmationPreview = createSelector(
  getActiveSignatureImage,
  (activeSignatureImage: EndorsementSelection): EndorsementImageCreationPreview => {
    if (!activeSignatureImage) {
      return null;
    }
    return {
      endorsementType: EndorsementType.SIGNATURE,
      imageString: activeSignatureImage?.endorsementImage,
      imageTypePrefix: 'data:image/png;base64',
      imageStringSuffix: null,
      imageStringHasPrefix: false,
    };
  }
);

export const getActiveInitialsImageConfirmationPreview = createSelector(
  getActiveInitialsImage,
  (activeInitialsImage: EndorsementSelection): EndorsementImageCreationPreview => {
    if (!activeInitialsImage) {
      return null;
    }
    return {
      endorsementType: EndorsementType.INITIALS,
      imageString: activeInitialsImage?.endorsementImage,
      imageTypePrefix: 'data:image/png;base64',
      imageStringSuffix: null,
      imageStringHasPrefix: false,
    };
  }
);

export const getDrawnSignatureConfirmationPreview = createSelector(
  getDrawnSignature,
  (drawnSignature: DrawnEndorsement): EndorsementImageCreationPreview => {
    if (!drawnSignature) {
      return null;
    }

    return {
      endorsementType: EndorsementType.SIGNATURE,
      imageString: drawnSignature?.svgImageString,
      imageTypePrefix: null,
      imageStringSuffix: null,
      imageStringHasPrefix: true,
    };
  }
);

export const getDrawnInitialsConfirmationPreview = createSelector(
  getDrawnInitials,
  (drawnInitials: DrawnEndorsement): EndorsementImageCreationPreview => {
    if (!drawnInitials) {
      return null;
    }

    return {
      endorsementType: EndorsementType.INITIALS,
      imageString: drawnInitials?.svgImageString,
      imageTypePrefix: null,
      imageStringSuffix: null,
      imageStringHasPrefix: true,
    };
  }
);

export const getHasUploadedSignature = createSelector(
  getEndorsementSelectionState,
  (state: EndorsementsCreationState.State) => state.hasUploadedSignature
);

export const getUploadedSignatureImage = createSelector(
  getEndorsementSelectionState,
  (state: EndorsementsCreationState.State) => state.uploadedSignatureImageBase64
);

export const getHasCreatedDrawnEndorsements = createSelector(
  getDrawnInitials,
  getDrawnSignature,
  getHasUploadedSignature,
  (
    drawnInitials: DrawnEndorsement,
    drawnSignature: DrawnEndorsement,
    hasUploadedSignature: boolean
  ) => {
    if (drawnSignature?.svgImageString || drawnInitials?.svgImageString || hasUploadedSignature) {
      return true;
    } else {
      return false;
    }
  }
);

export const getUploadedSignatureImagePreview = createSelector(
  getUploadedSignatureImage,
  (signatureImage: string): EndorsementImageCreationPreview => {
    if (!signatureImage) {
      return null;
    }
    return {
      endorsementType: EndorsementType.SIGNATURE,
      imageString: signatureImage,
      imageTypePrefix: 'data:image/png;base64,',
      imageStringSuffix: signatureImage,
      imageStringHasPrefix: true,
    };
  }
);

export const getSignatureType = createSelector(
  getEndorsementSelectionState,
  (state: EndorsementsCreationState.State) => state.signatureType
);

export const getIsSignatureCreatedInPresign = createSelector(
  getEndorsementSelectionState,
  (state: EndorsementsCreationState.State) => state.isSignatureCreatedInPresign
);

export const getCreatedSignatureEndorsementImage = createSelector(
  getSignatureType,
  getDrawnSignatureConfirmationPreview,
  getActiveSignatureImageConfirmationPreview,
  getUploadedSignatureImagePreview,
  (signatureType, drawnSignaturePreview, typedSignaturePreview, uploadedSignaturePreview) => {
    if (signatureType === SignatureType.Drawn && drawnSignaturePreview) {
      return drawnSignaturePreview;
    }
    if (signatureType === SignatureType.Selected && typedSignaturePreview) {
      return typedSignaturePreview;
    }
    if (signatureType === SignatureType.Uploaded && uploadedSignaturePreview) {
      uploadedSignaturePreview.imageString =
        uploadedSignaturePreview.imageTypePrefix + uploadedSignaturePreview.imageStringSuffix;
      return uploadedSignaturePreview;
    }
    return null;
  }
);

export const getCreatedInitialsEndorsementImage = createSelector(
  getSignatureType,
  getDrawnInitialsConfirmationPreview,
  getActiveInitialsImageConfirmationPreview,
  (signatureType, drawnInitialsPreview, typedInitialsPreview) => {
    if (signatureType === SignatureType.Drawn && drawnInitialsPreview) {
      return drawnInitialsPreview;
    }
    if (
      (signatureType === SignatureType.Selected || signatureType === SignatureType.Uploaded) &&
      typedInitialsPreview
    ) {
      return typedInitialsPreview;
    }
    return null;
  }
);
