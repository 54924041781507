import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { v4 as uuid } from 'uuid';

import { PresignService } from 'src/app/features/pre-sign/services/presign.service';
import { PackageUsersSelectors } from 'src/app/features/package-users';
import { SigningRoomActions } from 'src/app/features/signing-room/store/actions';
import { RootStoreState } from 'src/app/store';
import { EndorsementsService } from 'src/app/features/endorsements/services/endorsements.service';
import { ExceptionType } from 'src/app/features/notifications/models';
import { NotificationsActions, NotificationType } from 'src/app/features/notifications';

import { PresignActions } from '../actions/';
import { DocumentsActions } from '../../../documents/store/actions';
import { EndorsementsActions } from '../../../endorsements/store/actions';
import { ModalsActions } from '../../../modals/store/actions';

@Injectable()
export class PresignEffects {
  wizardCompleted$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(PresignActions.WizardCompleted),
        tap(() => this.router.navigate(['/pre-sign/signing-room']))
      ),
    { dispatch: false }
  );

  navigateToDashboard$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SigningRoomActions.ExitSigningRoom, PresignActions.ExitSigning),
        tap(() => this.router.navigate(['/consumer-portal']))
      ),
    { dispatch: false }
  );

  presignParticipantStarted$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PresignActions.PresignParticipantStarted),
      concatMap((action) =>
        of(action).pipe(
          withLatestFrom(this.store.pipe(select(PackageUsersSelectors.getActivePackageUserGuid)))
        )
      ),
      switchMap(([_, packageUserGuid]) => {
        return this.preSignService
          .PostPreSigningParticipantStarted(packageUserGuid)
          .pipe(switchMap(() => []));
      })
    )
  );

  presignParticipantCompleted$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PresignActions.PresignParticipantCompleted),
      concatMap((action) =>
        of(action).pipe(
          withLatestFrom(this.store.pipe(select(PackageUsersSelectors.getActivePackageUserGuid)))
        )
      ),
      switchMap(([_, packageUserGuid]) => {
        return this.preSignService
          .PostPreSigningParticipantCompleted(packageUserGuid)
          .pipe(switchMap(() => []));
      })
    )
  );

  preparePresigningSessionToStart$ = createEffect(() =>
  this.actions$.pipe(
    ofType(PresignActions.PreparePresigningSessionToStart),
    switchMap(() =>
      this.endorsementsService.signSystemEndorsementsPresign().pipe(
        switchMap(() =>  {
        return [
          DocumentsActions.GetDocuments({
          payload: { ignoreIfLoaded: true, refetchSmartDocs: true },
        }),
        EndorsementsActions.FetchEndorsementImages(),
        EndorsementsActions.FetchSystemEndorsementImages(),
        ModalsActions.DisableGlobalSpinner()]
      }),
        catchError((err) =>
          of(
            NotificationsActions.AddNotification({
              payload: {
                exception: err,
                notificationType: NotificationType.Error,
                id: uuid(),
                text: 'Failed to prepare presigning session to start',
                exceptionType: ExceptionType.CannotProceed,
              },
            })
          )
        )
      )
    )
  )
);

  constructor(
    private readonly actions$: Actions,
    private readonly store: Store<RootStoreState.State>,
    private readonly router: Router,
    private readonly endorsementsService: EndorsementsService,
    private readonly preSignService: PresignService
  ) {}
}
