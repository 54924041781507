import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { CheckInTask } from '../models';

@Injectable()
export class WizardService {
  constructor(private readonly http: HttpClient) {}

  public getCheckInTasks(packageUserGuid: string) {
    return this.http.get<CheckInTask[]>(`tasks/checkin/${packageUserGuid}`);
  }

  public getPresignCheckInTasks(packageUserGuid: string) {
    return this.http.get<CheckInTask[]>(`tasks/checkin/presign/${packageUserGuid}`);
  }
}
