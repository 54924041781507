import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { PackagesActions, PackagesSelectors } from 'src/app/features/packages';
import { PackageScheduleStatus } from 'src/app/features/packages/models';
import { FeedbackType } from 'src/app/features/shared/models';
import { RootStoreState } from 'src/app/store';
import { MsalAuthService } from '../../msal-auth';

@Injectable({
  providedIn: 'root',
})
export class FeedbackGuard {
  userGuid: string;
  packageGuid: string;
  feedbackType: FeedbackType;
  feedbackStatusMap = new Map<PackageScheduleStatus, FeedbackType>([
    [PackageScheduleStatus.CANCELLED, FeedbackType.CANCELLED],
    [PackageScheduleStatus.COMPLETE, FeedbackType.COMPLETED],
    [PackageScheduleStatus.EXPIRED, FeedbackType.EXPIRED],
    [PackageScheduleStatus.EARLY, FeedbackType.EARLY],
    [PackageScheduleStatus.PROCESSING, FeedbackType.PROCESSING],
  ]);

  constructor(
    private readonly router: Router,
    private readonly store: Store<RootStoreState.State>,
    private readonly msalService: MsalAuthService
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | boolean {
    this.feedbackType = next.params['feedbackType'] ?? FeedbackType.INVALIDURL;

    if (this.shouldHandleStatus()) {
      this.userGuid = next.queryParams['user'];
      this.packageGuid = next.queryParams['package'];

      this.store.dispatch(PackagesActions.ClearScheduleStatus());

      this.store.dispatch(
        PackagesActions.FetchPackageScheduledStatus({
          packageGuid: this.packageGuid,
          user: this.userGuid,
        })
      );

      return this.store.pipe(
        select(PackagesSelectors.getPackageScheduleStatus),
        filter((status) => !!status),
        map((status) => this.validateStatus(status))
      );
    }

    return true;
  }

  validateStatus(status: PackageScheduleStatus): any {
    if (status === PackageScheduleStatus.CURRENT) {
      if (
        this.feedbackType !== FeedbackType.EARLY &&
        this.feedbackType !== FeedbackType.PROCESSING
      ) {
        this.router.navigate([''], {
          queryParams: this.getQueryParams(),
          replaceUrl: true,
        });

        return false;
      } else {
        this.msalService.loginRetry();
        return false;
      }
    } else if (this.feedbackStatusMap.get(status) !== this.feedbackType) {
      this.store.dispatch(
        PackagesActions.RedirectInvalidPackageScheduleStatus({
          queryParams: this.getQueryParams(),
        })
      );

      return false;
    }

    return true;
  }

  shouldHandleStatus(): boolean {
    for (const [, value] of this.feedbackStatusMap) {
      if (value === this.feedbackType) {
        return true;
      }
    }
    return false;
  }

  getQueryParams(): any {
    return !!this.userGuid
      ? {
          package: this.packageGuid,
          user: this.userGuid,
        }
      : {
          package: this.packageGuid,
        };
  }
}
