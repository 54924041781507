<div class="participant-list">
  <h5 class="participant-list__title" data-sidebar-heading>
    {{ (packageUsers$ | async).length.toString() + ' Participants' }}
  </h5>
  <div appParticipantListFluidMaxHeight class="participant-list__content" tabindex="4">
    <app-card
      *ngFor="let packageUser of packageUsers$ | async"
      backgroundStyle="{{ isMobile ? null : 'secondary' }}"
      class="participant-card"
    >
      <div class="participant-card__role">{{ packageUser.userRoleCode | role }}</div>
      <div *ngIf="packageUser.checkInStatus">
        <div class="participant-card__name" data-private>
          {{ packageUser.firstName }} {{ packageUser.lastName }}
        </div>
        <div class="participant-card__status">{{ packageUser.checkInStatus | checkinStatus }}</div>
        <div class="participant-card__currentStatus">
          {{ isReady(packageUser) ? 'Ready' : packageUser.checkInStatus.currentStatus | currentStatus }}
          <app-participant-list-timer
            *ngIf="(isSigningAgent$ | async) === true && packageUser.userRoleCode !== signingAgentRoleCode"
            [prepend]="' for '"
            [seconds]="packageUser.checkInStatus.secondsSinceLastAction"
            [status]="packageUser.checkInStatus.currentStatus"
          ></app-participant-list-timer>
        </div>
        <mat-progress-bar
          aria-label="Participant Check In Progress"
          class="rkt-ProgressBar"
          color="primary"
          mode="determinate"
          [value]="packageUser.checkInStatus.percentComplete"
        >
        </mat-progress-bar>
      </div>
    </app-card>
  </div>
</div>
