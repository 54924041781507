export interface State {
  connecting: boolean;
  connected: boolean;
  connectionError: string;
  sessionCompleted: boolean;
  showCongratulationsPage: boolean;
  reviewingDocuments: boolean;
  sessionJoined: boolean;
  hasSigningStarted: boolean;
}

export const initialState: State = {
  connecting: false,
  connected: false,
  connectionError: null,
  sessionCompleted: false,
  showCongratulationsPage: false,
  reviewingDocuments: false,
  sessionJoined: false,
  hasSigningStarted: false,
};
