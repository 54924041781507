import { Component, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';

import { ModalsActions } from 'src/app/features/modals';
import { RootStoreState } from 'src/app/store';

import { KnowledgeBasedAuthenticationActions } from '../../store/actions';

@Component({
  selector: 'app-times-up-modal',
  templateUrl: './times-up-modal.component.html',
  styleUrls: ['./times-up-modal.component.scss'],
})
export class TimesUpModalComponent implements OnDestroy {
  static identifier = 'TimesUpModalComponent';
  constructor(private readonly store: Store<RootStoreState.State>) {}

  onTryAgain() {
    this.store.dispatch(KnowledgeBasedAuthenticationActions.StartKbaQuestions());
    this.store.dispatch(
      ModalsActions.ClearModalComponentIfOpen({
        payload: { component: TimesUpModalComponent },
      })
    );
  }

  ngOnDestroy() {
    this.store.dispatch(
      ModalsActions.ClearModalComponentIfOpen({
        payload: { component: TimesUpModalComponent },
      })
    );
  }
}
