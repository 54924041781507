<ng-template [ngIf]="!isMultiVideoStorageLocatorEnabled">
  <div class="flex-row-new" *ngIf="videoDownloadArchiveIds$ | async as videoDownloadArchiveIds">
    <div class="flex-items-new">
      <ng-template [ngIf]="videoDownloadArchiveIds.videoArchiveIds?.length > 1">
        <ng-template
          ngFor
          let-item
          [ngForOf]="videoDownloadArchiveIds.videoArchiveIds"
          let-i="index"
        >
          <button
            class="download-video button-new button--full-width"
            disabled
            *ngIf="item.isDownloading === true; else downloadMultipleVideos"
          >
            <span class="fas fa-spin fa-circle-notch"></span>
            <span> Downloading - Part {{ i + 1 }}</span>
          </button>
          <ng-template #downloadMultipleVideos>
            <button
              class="download-video button button--full-width"
              (click)="onVideoButtonClick(item.videoArchiveId)"
              [disabled]="(videoArchiveDownloadStatus$ | async) === false"
            >
              <span class="fas fa-download"></span>
              <span class="regular bold small-font"> Download - Part {{ i + 1 }}</span>
            </button>
          </ng-template>
        </ng-template>
      </ng-template>

      <ng-template [ngIf]="videoDownloadArchiveIds.videoArchiveIds?.length === 1">
        <ng-template ngFor let-item [ngForOf]="videoDownloadArchiveIds.videoArchiveIds">
          <button
            class="download-video button-new button--full-width"
            disabled
            *ngIf="item.isDownloading; else downloadVideos"
          >
            <span class="fas fa-spin fa-circle-notch"></span>
            <span class="regular bold small-font"> Downloading</span>
          </button>

          <ng-template #downloadVideos>
            <button
              class="download-video button-new button--full-width"
              (click)="onVideoButtonClick(item.videoArchiveId)"
              [disabled]="(videoArchiveDownloadStatus$ | async) === false"
            >
              <span class="fas fa-download"></span>
              <span class="regular bold small-font"> Download Session Video</span>
            </button>
          </ng-template>
        </ng-template>
      </ng-template>
    </div>
  </div>
</ng-template>

<ng-template [ngIf]="isMultiVideoStorageLocatorEnabled">
  <div class="flex-row-new">
    <div class="flex-items-new" *ngIf="(isMultiVideoStorageLocatorUrlsAvailable$ | async)">
      <ng-template [ngIf]="isDownloadingMultiVideoStorageLocatorUrls?.length > 1">
        <ng-template
          ngFor
          [ngForOf]="isDownloadingMultiVideoStorageLocatorUrls"
          let-i="index"
        >
          <button
            class="download-video button button--full-width"
            disabled
            *ngIf="isDownloadingMultiVideoStorageLocatorUrls[i]; else downloadMultipleVideos"
          >
            <span class="fas fa-spin fa-circle-notch"></span>
            <span class="regular bold small-font"> Downloading - Part {{ i + 1 }}</span>
          </button>
          <ng-template #downloadMultipleVideos>
            <button
              class="download-video button button--full-width"
              (click)="onMultiVideoStorageLocatorButtonClick(i)"
              [disabled]="(isMultiVideoStorageLocatorUrlsAvailable$ | async) === false"
            >
              <span class="fas fa-download"></span>
              <span class="regular bold small-font"> Download - Part {{ i + 1 }}</span>
            </button>
          </ng-template>
        </ng-template>
      </ng-template>

      <ng-template [ngIf]="isDownloadingMultiVideoStorageLocatorUrls.length === 1">
        <button
          class="download-video button-new button--full-width"
          disabled
          *ngIf="isDownloadingMultiVideoStorageLocatorUrls[0]; else downloadVideos"
        >
          <span class="fas fa-spin fa-circle-notch"></span>
          <span class="regular bold small-font"> Downloading</span>
        </button>
        <ng-template #downloadVideos>
          <button
            class="download-video button-new button--full-width"
            (click)="onMultiVideoStorageLocatorButtonClick(0)"
            [disabled]="(isMultiVideoStorageLocatorUrlsAvailable$ | async) === false"
          >
            <span class="fas fa-download"></span>
            <span class="regular bold small-font"> Download Session Video</span>
          </button>
        </ng-template>
      </ng-template>
    </div>
  </div>
</ng-template>
