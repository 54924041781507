<div [class]="classes">
  <div class="collapsible-header" (click)="onToggle()" [attr.data-testid]="'collapsible-header-' + dataTestId">
    <div class="collapsible-title">
      <ng-content #titleBlock select="[slot=title]"></ng-content>
      <div *ngIf="!titleBlock">{{this.title}}</div>
    </div>
    <app-spark-icon class="collapsible-header-collapsed-icon" [icon]="collapsedIcon" [size]="iconSize"></app-spark-icon>
    <app-spark-icon class="collapsible-header-open-icon" [icon]="openIcon" [size]="iconSize"></app-spark-icon>
  </div>
  <div class="collapsible-content">
    <ng-content></ng-content>
  </div>
</div>