import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PackageUsersModule } from 'src/app/features/package-users';
import { PackagesModule } from 'src/app/features/packages';

declare global {
  interface Window {
    pendo: any;
  }
}

@NgModule({
  imports: [
    CommonModule,
    PackagesModule,
    PackageUsersModule,
  ],
})
export class PendoAdapterModule { }
