import { Component, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { filter, takeUntil, tap } from 'rxjs/operators';

import { RootStoreState } from 'src/app//store';

import { PackageStatusPollingService } from '../../services';
import { PackagesActions, PackagesSelectors } from '../../store';

@Component({
  selector: 'app-package-status-monitor',
  template: '',
})
export class PackageStatusMonitorComponent implements OnInit, OnDestroy {
  readonly onDestroyNotifier$ = new Subject();

  constructor(
    private readonly store: Store<RootStoreState.State>,
    private readonly packageStatusService: PackageStatusPollingService
  ) {}

  ngOnInit(): void {
    this.packageStatusService.start();
    this.store
      .pipe(
        select(PackagesSelectors.packageCompleteOrCancelled),
        takeUntil(this.onDestroyNotifier$),
        filter((completeOrCancelled) => completeOrCancelled),
        tap(() => this.store.dispatch(PackagesActions.RedirectInvalidPackageScheduleStatus()))
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.packageStatusService.stop();
    this.onDestroyNotifier$.next(undefined);
    this.onDestroyNotifier$.complete();
  }
}
