<div class="recording-could-not-start">
  <div class="recording-could-not-start__header">
    <div class="sub-header">Could not start recording</div>
  </div>
  <div class="recording-could-not-start__body">
    <p>
      We were unable to start the video recording for this session. If retrying does not start the
      video recording, you'll have to cancel this signing.
    </p>
    <p>
      Need help? Call us: <a href="tel:{{ (client$ | async)?.supportPhoneNumber | phone }}">{{ (client$ | async)?.supportPhoneNumber | phone }}</a>
    </p>
  </div>

  <div class="recording-could-not-start__footer">
    <button class="btn btn--cancel" (click)="cancel()">Cancel Session</button>
    <button class="btn btn--retry" (click)="retry()">Retry</button>
  </div>
</div>
