import { Component } from '@angular/core';

@Component({
  selector: 'app-starting-recording-modal',
  templateUrl: './starting-recording-modal.component.html',
  styleUrls: ['./starting-recording-modal.component.scss'],
})
export class StartingRecordingModalComponent {
  static identifier = 'StartingRecordingModalComponent';
}
