import { createAction, props } from '@ngrx/store';

import { DrawnEndorsement, EndorsementSelections, SignatureType } from '../../models';

export const SetSignatureType = createAction(
  '[Endorsements Creation] Set Signature Type',
  props<{ payload: SignatureType }>()
);

export const SetIsSignatureCreatedInPresign = createAction(
  '[Endorsements Creation] Set Is Signature Created In Presign',
  props<{ payload: boolean }>()
);

export const SetSignaturePreviews = createAction(
  '[Endorsements Creation] Set Signature Previews',
  props<{ payload: EndorsementSelections }>()
);

export const FetchSignaturePreviews = createAction(
  '[Endorsements Creation] Fetch Signature Previews'
);

export const SetActiveSignatureByIndex = createAction(
  '[Endorsements Creation] Set Active Signature By Index',
  props<{ payload: number }>()
);

export const SetUploadedSignatureImageFromCommissionSignature = createAction(
  '[Endorsements Creation] Set Uploaded Signature Image',
  props<{ payload: string }>()
);

export const SignatureSelected = createAction('[Endorsements Creation] Signature Selected');

export const SetInitialsPreviews = createAction(
  '[Endorsements Creation] Set Initials Previews',
  props<{ payload: EndorsementSelections }>()
);

export const FetchInitialsPreviews = createAction(
  '[Endorsements Creation] Fetch Initials Previews'
);

export const SetActiveInitialsByIndex = createAction(
  '[Endorsements Creation] Set Active Initials By Index',
  props<{ payload: number }>()
);

export const InitialsSelected = createAction('[Endorsements Creation] Initials Selected');

export const SaveEndorsementSelections = createAction(
  '[Endorsements Creation] Save Endorsements Creations'
);

export const EndorsementSelectionsSaveSucccessful = createAction(
  '[Endorsements Creation] Endorsements Creation Save Successful'
);

export const UpdateDrawnSignature = createAction(
  '[Endorsements Creation] Update Drawn Signature',
  props<{ payload: DrawnEndorsement }>()
);

export const UpdateDrawnInitials = createAction(
  '[Endorsements Creation] Update Drawn Initials',
  props<{ payload: DrawnEndorsement }>()
);

export const ConfirmAndSaveEndorsements = createAction(
  '[Endorsements Creation] Confirm and Save Endorsements'
);

export const SaveDrawnEndorsements = createAction(
  '[Endorsements Creation] Confirm Signature And Save Initials Creation'
);

export const CompleteEndorsementsCreation = createAction('[Endorsements Creation] Reset State');

export const NoOp = createAction('[Endorsements Creation] NoOp');
