import { createAction } from '@ngrx/store';

const action = 'Presign Consents';

export const AcceptTermsOfService = createAction(`[${action}] Accept Terms Of Service`);

export const FetchPrivacyPolicyConsent = createAction(`[${action}] Fetch Privacy Policy Consent`);

export const FetchTermsAndConditionsConsent = createAction(
  `[${action}] Fetch Terms And Conditions Consent`
);

export const FetchEndorsementsConsent = createAction(`[${action}] Fetch Endorsements Consent`);

export const DeclineTermsOfService = createAction(`[${action}] Decline Terms Of Service`);
