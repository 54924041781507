<app-wizard-step-wrapper
  [showStep]="showStep"
  [stepTitle]="stepMetadata.stepName"
  [formGroup]="formStep"
>
  <div class="form-step__subheading">
    We need a few pieces of information to verify your identity
  </div>

  <div class="form-step__banner">
    <app-spark-icon size="36" icon="information"></app-spark-icon>
    <div>
      Please ensure the information you are providing is error-free before leaving this page.
    </div>
  </div>

  <h2>Signer Details</h2>

  <div class="form-section" *ngIf="signerDetailsStatus">
    <div class="form-row">
      <div class="form-column" [ngClass]="{ 'no-error': !signerDetailsStatus.isMissingFirstName }">
        <label class="form-field">
          <div>First Name</div>
          <input
            type="text"
            class="full-style"
            [ngClass]="{ error: errors.firstName }"
            [formControl]="firstName"
            maxlength="128"
            data-testid="first-name"
            placeholder="First Name"
            (blur)="autoSetSignature()"
            data-private
          />
          <div class="error-hint-wrapper">
            <div *ngIf="errors.firstName" #errorHint class="error-hint">{{ errors.firstName }}</div>
          </div>
        </label>
      </div>

      <div class="form-column" [ngClass]="{ 'no-error': !signerDetailsStatus.isMissingLastName }">
        <label class="form-field">
          <div>Last Name</div>
          <input
            type="text"
            class="full-style"
            [ngClass]="{ error: errors.lastName }"
            [formControl]="lastName"
            maxlength="128"
            data-testid="last-name"
            placeholder="Last Name"
            (blur)="autoSetSignature()"
            data-private
          />
          <div class="error-hint-wrapper">
            <div *ngIf="errors.lastName" #errorHint class="error-hint">{{ errors.lastName }}</div>
          </div>
        </label>
      </div>
    </div>

    <div class="form-row" *ngIf="signerDetailsStatus.isMissingSignatureName">
      <div class="form-column form-column--full">
        <label class="form-field">
          <div class="form-field-label">
            <span>The name to use for your signature</span>
            <mat-icon
              matTooltipPosition="above"
              matTooltipClass="rkt-Tooltip"
              matTooltip="This is the name that will be used to create your signature and will be shown when you sign a document."
              fontSet="material-icons-outlined"
              fontIcon="help_outline"
              appIconTooltip
              aria-label=""
            ></mat-icon>
          </div>
          <input
            type="text"
            class="full-style"
            maxlength="256"
            data-testid="signature-name"
            placeholder="Example: John Smith"
            data-private
            [value]="signature"
            disabled
          />
          <div class="error-hint-wrapper"></div>
        </label>
      </div>
    </div>

    <div class="form-row">
      <div class="form-column" *ngIf="signerDetailsStatus && signerDetailsStatus.isMissingEmail">
        <label class="form-field">
          <div>Email Address</div>
          <input
            type="text"
            [formControl]="emailAddress"
            [ngClass]="{ error: errors.emailAddress }"
            class="full-style"
            autocomplete="email"
            inputmode="email"
            data-testid="email-address"
            placeholder="youremail@domain.com"
            maxlength="128"
            data-private
          />
          <div class="error-hint-wrapper">
            <div *ngIf="errors.emailAddress" #errorHint class="error-hint">
              {{ errors.emailAddress }}
            </div>
          </div>
        </label>
      </div>

      <div
        class="form-column"
        *ngIf="signerDetailsStatus && signerDetailsStatus.isMissingPhoneNumber"
      >
        <label class="form-field">
          <div>Phone Number</div>
          <input
            type="text"
            [formControl]="phoneNumber"
            [ngClass]="{ error: errors.phoneNumber }"
            class="full-style"
            autocomplete="tel"
            inputmode="tel"
            mask="(000) 000-0000"
            data-testid="phone-number"
            placeholder="( xxx ) xxx - xxxx"
            data-private
          />
          <div class="error-hint-wrapper">
            <div *ngIf="errors.phoneNumber" #errorHint class="error-hint">
              {{ errors.phoneNumber }}
            </div>
          </div>
        </label>
      </div>
    </div>

    <div class="form-row">
      <div
        class="form-column"
        *ngIf="signerDetailsStatus && signerDetailsStatus.isMissingDateOfBirth"
      >
        <div class="form-section-label">What is your date of birth?</div>
        <div class="form-fields dob-fields">
          <label class="form-field dob-month-field">
            <div>Month</div>
            <input
              type="text"
              [formControl]="dobMonth"
              [ngClass]="{ error: errors.dateOfBirth }"
              class="short"
              autocomplete="bday-month"
              inputmode="numeric"
              data-testid="birth-month"
              placeholder="MM"
              width="75"
              maxlength="2"
              data-private
            />
          </label>
          <label class="form-field dob-day-field">
            <div>Day</div>
            <input
              type="text"
              [formControl]="dobDay"
              [ngClass]="{ error: errors.dateOfBirth }"
              class="short"
              autocomplete="bday-day"
              inputmode="numeric"
              data-testid="birth-day"
              placeholder="DD"
              maxlength="2"
              data-private
            />
          </label>
          <label class="form-field dob-year-field">
            <div>Year</div>
            <input
              type="text"
              [formControl]="dobYear"
              [ngClass]="{ error: errors.dateOfBirth }"
              autocomplete="bday-year"
              inputmode="numeric"
              mask="0000"
              data-testid="birth-year"
              placeholder="YYYY"
              data-private
            />
          </label>
        </div>
        <div class="error-hint-wrapper">
          <div *ngIf="errors.dateOfBirth" #errorHint class="error-hint">
            {{ errors.dateOfBirth }}
          </div>
        </div>
      </div>

      <div class="form-column" *ngIf="signerDetailsStatus && signerDetailsStatus.isMissingSsn">
        <div class="form-section-label">What is the last 4 of your SSN?</div>
        <div class="form-fields">
          <label class="form-field">
            <div>Social Security Number</div>
            <div class="ssn-hint-container">
              <span class="ssn-hint"
                ><span>✻</span> <span>✻</span> <span>✻</span> <span class="special-dash">⁃</span>
                <span>✻</span> <span>✻</span> <span class="special-dash">⁃</span></span
              >
              <input
                type="text"
                [formControl]="ssn"
                [ngClass]="{ error: errors.ssn }"
                class="short with-asterisk"
                inputmode="numeric"
                mask="0000"
                data-testid="ssn"
                placeholder="✻✻✻✻"
                data-private
              />
            </div>
          </label>
        </div>
        <div class="error-hint-wrapper">
          <div *ngIf="errors.ssn" #errorHint class="error-hint">{{ errors.ssn }}</div>
        </div>
      </div>
    </div>
  </div>

  <h2 *ngIf="signerDetailsStatus && signerDetailsStatus.isMissingAddress">Current Address</h2>

  <div class="form-section" *ngIf="signerDetailsStatus && signerDetailsStatus.isMissingAddress">
    <div class="form-row">
      <div class="form-column">
        <label class="form-field">
          <div>Street Address</div>
          <input
            type="text"
            class="full-style"
            [ngClass]="{ error: errors.address1 }"
            [formControl]="address1"
            autocomplete="address-line1"
            data-testid="street-address"
            placeholder="Street Address"
            maxlength="100"
            data-private
          />
          <div class="error-hint-wrapper">
            <div *ngIf="errors.address1" #errorHint class="error-hint">{{ errors.address1 }}</div>
          </div>
        </label>
      </div>

      <div class="form-column">
        <label class="form-field">
          <div>Apt, Suite, etc. <span class="optional">(optional)</span></div>
          <input
            type="text"
            class="full-style"
            [ngClass]="{ error: errors.address2 }"
            [formControl]="address2"
            autocomplete="address-line2"
            maxlength="50"
            data-private
          />
          <div class="error-hint-wrapper">
            <div *ngIf="errors.address2" #errorHint class="error-hint">{{ errors.address2 }}</div>
          </div>
        </label>
      </div>
    </div>

    <div class="form-row">
      <div class="form-column">
        <label class="form-field">
          <div>City</div>
          <input
            type="text"
            class="full-style"
            [ngClass]="{ error: errors.city }"
            [formControl]="city"
            autocomplete="address-level2"
            data-testid="city-name"
            placeholder="City Name"
            maxlength="50"
            data-private
          />
          <div class="error-hint-wrapper">
            <div *ngIf="errors.city" #errorHint class="error-hint">{{ errors.city }}</div>
          </div>
        </label>
      </div>

      <div class="form-column">
        <div class="form-fields">
          <label class="form-field">
            <div>State</div>
            <select
              [formControl]="state"
              class="state-field"
              data-testid="state-name"
              [ngClass]="{ error: errors.state }"
              placeholder="State"
              data-private
            >
              <option value=""></option>
              <option *ngFor="let state of states$ | async" [value]="state.code">
                {{ state.name }}
              </option>
            </select>
            <div class="error-hint-wrapper">
              <div *ngIf="errors.state" #errorHint class="error-hint">{{ errors.state }}</div>
            </div>
          </label>
          <label class="form-field">
            <div>Zip Code</div>
            <input
              type="text"
              [formControl]="zipCode"
              class="medium zip-code-field"
              [ngClass]="{ error: errors.zipCode }"
              autocomplete="postal-code"
              inputmode="numeric"
              data-testid="zip-code"
              placeholder="12345"
              mask="00000"
              data-private
            />
            <div class="error-hint-wrapper">
              <div *ngIf="errors.zipCode" #errorHint class="error-hint">{{ errors.zipCode }}</div>
            </div>
          </label>
        </div>
      </div>
    </div>
  </div>

  <app-button-container>
    <app-button
      type="primary"
      (click)="goToNextStep()"
      [dataTestId]="'next-button-additionaldetails'"
    >
      Next
    </app-button>
  </app-button-container>
</app-wizard-step-wrapper>
