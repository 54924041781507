import {
  MultiFactorChallengeError,
  MultiFactorChallengeOptions
} from '../../models';

export interface State {
  currentlySelectedChallengeType: MultiFactorChallengeOptions;
  activeMultiFactorAttemptPackageUserGuid: string;
  challengeCreated: boolean;
  challengeFailed: boolean;
  attemptsRemaining?: number;
  error: MultiFactorChallengeError;
  errorMessage?: string;
  completeMultiFactorChallengeLoading: boolean;
  challengeCreatedAt?: Date;
}

export const initialState: State = {
  currentlySelectedChallengeType: null,
  activeMultiFactorAttemptPackageUserGuid: null,
  challengeCreated: false,
  challengeFailed: false,
  attemptsRemaining: null,
  error: null,
  errorMessage: null,
  completeMultiFactorChallengeLoading: false,
  challengeCreatedAt: null
};
