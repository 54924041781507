<div class="generic-confirm-modal">
  <div class="generic-confirm-modal__header">
    <h3 class="modal-title">Please wait...</h3>
  </div>
  <div class="generic-confirm-modal__body">
    <div>
      There's been a problem with your order. Your notary will need to cancel
      this session.
    </div>
    <div class="supportDetails" *ngIf="client$ | async as clientInfo">
      If you need further assistance, please call
      {{ clientInfo.clientName }} at:
      <span class="phoneNumber">{{
        clientInfo.supportPhoneNumber | phone
      }}</span>
    </div>
  </div>
</div>
