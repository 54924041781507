import { createAction, props } from '@ngrx/store';

export const IdVerificationCompleted = createAction(
  '[IdVerification] Id Verification Completed',
  props<{ payload: { packageUserGuid: string } }>()
);

export const SessionStarted = createAction(
  '[IdVerification] Id Verification Session Started',
  props<{ payload: { packageUserGuid: string } }>()
);

export const ResetSessionStarted = createAction(
  '[IdVerification] Reset Session Started',
  props<{ payload: { packageUserGuid: string } }>()
);

export const GetUserIdVerificationResultsStatus = createAction(
  '[IdVerification] Fetch Verification Results Status'
);

export const IdVerificationQrCodeShown = createAction(
  '[IdVerification] ID Verification QR code shown'
);

export const SendAccessLinkSms = createAction(
  '[IdVerification] Send Access Link Sms',
  (phoneNumberOverride = '') => ({ phoneNumberOverride })
);

export const SendAccessLinkSmsSuccessful = createAction(
  '[IdVerification] Send Access Link Sms Successful',
  (hasAccessLinkSmsSentSuccessfully = true) => ({ hasAccessLinkSmsSentSuccessfully })
);

export const SendAccessLinkSmsFailed = createAction(
  '[IdVerification] Send Access Link Sms Failed',
  (hasAccessLinkSmsSentSuccessfully = false) => ({ hasAccessLinkSmsSentSuccessfully })
);

export const FetchAccessLink = createAction('[IdVerification] Fetch Access Link');

export const UseOldAccessLink = createAction('[IdVerification] UseOldAccessLink');

export const SetAccessLink = createAction(
  '[IdVerification] Set Access Link',
  props<{ payload: { accessLink: string; packageUserGuid: string } }>()
);

export const FakeIdIdVerificationComplete = createAction(
  '[IdVerification] Fake ID IdVerification Complete'
);

export const SaveAuditPhotoIdBegin = createAction('[IdVerification] Audit Log Save Photo Id Begin');
