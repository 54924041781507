import { Component } from '@angular/core';

@Component({
  selector: 'app-invalid-url',
  templateUrl: './invalid-url.component.html',
  styleUrls: ['./invalid-url.component.scss'],
})
export class InvalidURLComponent {
  constructor() {}
}
