import { Route } from '@angular/router';
import { AuthenticationResult, AuthError } from '@azure/msal-browser';
import { createAction, props } from '@ngrx/store';

export const Login = createAction('[MsalAuth] Login', props<{ payload: { route: Route } }>());

export const RestartLogin = createAction('[MsalAuth] Restart Login');

export const LoginSuccessful = createAction(
  '[MsalAuth] Login Succesful',
  props<{ payload: { user?: any } }>()
);

export const HandleLoginRedirect = createAction(
  '[MsalAuth] Handle Login Redirect',
  props<{ payload: { user?: any; redirectState?: string; authority?: string } }>()
);

export const Logout = createAction('[MsalAuth] Logout');

export const SetLoadingStatus = createAction(
  '[MsalAuth] Set Loading Status',
  props<{ payload: { isLoading: boolean } }>()
);

export const SetClientIDPCode = createAction(
  '[MsalAuth] Set Client IDP Code',
  props<{ payload: { idpCode: string } }>()
);

export const RefreshTokenForVerifiedUser = createAction(
  '[MsalAuth] Refresh Token for Verified User',
  props<{ payload: { packageUserGuid: string; startPage?: string } }>()
);

export const UpdateLastVerifiedUserGuid = createAction(
  '[MsalAuth] Updates guid for last Verified User',
  props<{ payload: { packageUserGuid: string } }>()
);

export const FlowLogin = createAction(
  '[MsalAuth] Flow Login',
  props<{ payload: { msalFlow: string; idTokenHint: string } }>()
);

export const ResetPassword = createAction('[MsalAuth] Reset Password');

export const HandleAuthError = createAction(
  '[MsalAuth] Handle Auth Error',
  props<{ payload: AuthError }>()
);

export const KBAPassed = createAction('[MsalAuth] KBA Passed', props<{ packageUserGuid: string }>());
