<ng-template [ngIf]="(anyUserAllowedtoDownloadDocs$ | async)">

    <div class="flex-items-new">
      <button
        class="download-documents button button-new app-button"
        disabled
        *ngIf="isExecutingPackageDownloading$ | async; else downloadDocuments"
      >
        <span class="fas fa-spin fa-circle-notch"></span>
        <span> Downloading</span>
      </button>
      <ng-template #downloadDocuments>
        <button
          class="download-documents button button-new app-button"
          (click)="onDocumentDownloadClick()"
        >
          <span class="fas fa-download"></span>
          <span> Download Documents</span>
        </button>
      </ng-template>
    </div>
</ng-template>
