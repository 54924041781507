import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { SignalRActions } from 'src/app/features/signal-r/store';

import { RootStoreState } from 'src/app/store';

@Component({
  selector: 'app-cancelled-signing',
  templateUrl: './cancelled-signing.component.html',
  styleUrls: ['./cancelled-signing.component.scss'],
})
export class CancelledSigningComponent implements OnInit {
  constructor(private readonly store: Store<RootStoreState.State>) {}

  ngOnInit(): void {
    this.store.dispatch(SignalRActions.StopSignalRConnection());
  }
}
