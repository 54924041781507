<app-confirmation-modal>
  <ng-container subHeader>
    <p>Who's joining on this device?</p>
  </ng-container>

  <ng-container content>
    Select everyone that will be added to this device.
    <div class="participants">
      <app-welcome-participant
        *ngFor="let nonSa of nonSigningAgentsEligibleForCheckIn$ | async"
        [participant]="nonSa"
        [isInWaitingRoom]="this.isInWaitingRoom"
      >
      </app-welcome-participant>
    </div>
  </ng-container>

  <ng-container actionButton>
    <app-button type="secondary" (click)="back()" [dataTestId]="'add-participant-back'">
      Go Back
    </app-button>

    <app-button
      type="primary"
      (buttonClick)="beginCheckIn()"
      [ariaLabel]="
          (shouldDisableButton$ | async)
            ? 'button disabled if all participants are checking in'
            : null"
      [dataTestId]="'add-participant-begin'"
      [isDisabled]="shouldDisableButton$ | async"
    >
      Begin Check-In
    </app-button>
  </ng-container>
</app-confirmation-modal>
