import { CommonModule } from '@angular/common';
import { EffectsModule } from '@ngrx/effects';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { StoreModule } from '@ngrx/store';

import { FeatureManagementDirectiveModule } from 'src/app/features/feature-management/directives/feature-management-directive.module';
import { SharedModule } from 'src/app/features/shared';

import { ApplyYourSignatureModalComponent } from './components/apply-your-signature-modal/apply-your-signature-modal.component';
import { EndorsementsService } from './services';
import * as fromEndorsements from './store/reducers/endorsements.reducer';
import {
  EndorsementComponent,
  SignatureEndorsementComponent,
  InitialsEndorsementComponent,
  StampEndorsementComponent,
  UnsupportedEndorsementComponent,
  FreeTextEndorsementComponent,
  RequiredEndorsementCountComponent,
  RequiredSignatureCountComponent,
  RequiredCheckboxCountComponent,
  RequiredTextCountComponent,
  CheckboxEndorsementComponent,
  DocumentEndorsementStatusComponent,
  EndorsementFlagComponent,
  AwaitingParticipantsModalComponent,
  SystemEndorsementComponent,
} from './components';
import { EndorsementsContainerComponent } from './containers';
import { EndorsementsFeatureEffects } from './store/effects';
import { RequiredStampCountComponent } from './components/required-stamp-count/required-stamp-count.component';

@NgModule({
  declarations: [
    EndorsementComponent,
    EndorsementsContainerComponent,
    SignatureEndorsementComponent,
    InitialsEndorsementComponent,
    StampEndorsementComponent,
    UnsupportedEndorsementComponent,
    FreeTextEndorsementComponent,
    RequiredEndorsementCountComponent,
    RequiredSignatureCountComponent,
    RequiredCheckboxCountComponent,
    RequiredStampCountComponent,
    RequiredTextCountComponent,
    CheckboxEndorsementComponent,
    DocumentEndorsementStatusComponent,
    EndorsementFlagComponent,
    AwaitingParticipantsModalComponent,
    ApplyYourSignatureModalComponent,
    SystemEndorsementComponent,
  ],
  imports: [
    SharedModule,
    CommonModule,
    FormsModule,
    FeatureManagementDirectiveModule,
    EffectsModule.forFeature(EndorsementsFeatureEffects),
    StoreModule.forFeature(
      fromEndorsements.endorsementsFeatureKey,
      fromEndorsements.endorsementReducer
    ),
  ],
  exports: [
    EndorsementsContainerComponent,
    RequiredEndorsementCountComponent,
    RequiredSignatureCountComponent,
    RequiredCheckboxCountComponent,
    RequiredTextCountComponent,
    RequiredStampCountComponent,
    DocumentEndorsementStatusComponent,
  ],
  providers: [EndorsementsService],
})
export class EndorsementsModule {}
