import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { PackageUsersModule } from 'src/app/features/package-users';
import { PackagesModule } from 'src/app/features/packages';

import { PINValidationModule } from 'src/app/features/pin-validation';
import { SharedModule } from 'src/app/features/shared';

import { LobbyParticipantComponent, LobbyParticipantsListComponent } from './components';
import { LandingPageComponent, LobbyComponent } from './containers';
import { LobbyEffects, LobbyReducers } from './store';

@NgModule({
  declarations: [
    LandingPageComponent,
    LobbyParticipantComponent,
    LobbyParticipantsListComponent,
    LobbyComponent,
  ],
  exports: [LandingPageComponent, LobbyComponent],
  imports: [
    CommonModule,
    PackagesModule,
    PINValidationModule,
    PackageUsersModule,
    SharedModule,
    StoreModule.forFeature('lobby', LobbyReducers.lobbyReducer),
    EffectsModule.forFeature([LobbyEffects.LobbyEffects]),
  ],
  providers: [],
})
export class LobbyModule {}
