import { ApplicationInsightConfig } from './configurations/application-insights.config';
import { Environment } from './type/environment.type';

const b2cClientId = "#{b2cClientId}#";
const protectedResourceMap: [string, string[]][] = [['#{apiBaseUrl}#*', [b2cClientId]],];

export const environment: Environment = {
  envName: "#{environment}#",
  production: false,
  redirect_url: "#{redirect_url}#",
  client_id: b2cClientId,
  authority: "#{authority}#",
  consentScopes: [ b2cClientId ],
  protectedResourceMap: protectedResourceMap,
  apiBaseUrl: "#{apiBaseUrl}#",
  signalRBaseUrl: "#{signalRBaseUrl}#",
  functionApiKey: "#{functionApiKey}#",
  postLogoutRedirectUri: "#{postLogoutRedirectUri}#",
  passwordResetAuthority: "#{passwordResetAuthority}#",
  secureLinkLoginAuthority: "#{secureLinkLoginAuthority}#",
  accountInvitationAuthority: "#{accountInvitationAuthority}#",
  signingAgentAuthority: "#{signingAgentAuthority}#",
  nonSigningAgentAuthority: "#{nonSigningAgentAuthority}#",
  nonSigningAgentSignUpSignInAuthority: "#{nonSigningAgentSignUpSignInAuthority}#",
  expiredTokenUrl: "#{expiredTokenUrl}#",
  clearsignPortalUrl: "#{clearsignPortalUrl}#",
  clearsignFaqUrl: "#{clearsignFaqUrl}#",
  appInsights: {
    ...ApplicationInsightConfig,
    instrumentationKey: "#{instrumentationKey}#",
    connectionString: "#{connectionString}#",
  },
  pendo: {
    isEnabled: Boolean("#{pendoIsEnabled}#"),
    apiKey: "#{pendoApiKey}#"
  }
};
