import { IndividualConfig } from 'ngx-toastr';
import { DEFAULT_APPLICATION_PHONE_NUMBER } from '../shared/constants';
import { ToastModalComponent } from './components';
import { NotificationOptions } from './models';

export const DEFAULT_NOTIFICATION_OPTIONS: NotificationOptions = {
  closeButton: true,
  loadingIcon: false,
  reloadButton: false,
  tapToDismiss: false,
  timeOut: 0,
};

export const EXCEPTION_HARDSTOP_NOTIFICATION_OPTIONS: NotificationOptions = {
  closeButton: true,
  loadingIcon: false,
  reloadButton: true,
  tapToDismiss: false,
  timeOut: 0,
};

export const DEFAULT_TOASTR_OPTIONS: Partial<IndividualConfig> = {
  extendedTimeOut: 0,
  positionClass: 'toast-top-center',
  enableHtml: true,
  toastClass: 'custom-toast',
  toastComponent: ToastModalComponent,
};

export const HARDSTOP_EXCEPTION_USER_MESSAGE =
  '<p>An error has occurred, please retry. If this error continues please reload the page or contact Support at: <a href="tel:' +
  DEFAULT_APPLICATION_PHONE_NUMBER +
  '">' +
  DEFAULT_APPLICATION_PHONE_NUMBER +
  '</a>.</p>';

export const HARDSTOP_SIGNALR_EXCEPTION_USER_MESSAGE =
  '<p>Error: We could not sync you with other participants, please reload the page. If this persists contact Support at: <a href="tel:' +
  DEFAULT_APPLICATION_PHONE_NUMBER +
  '">' +
  DEFAULT_APPLICATION_PHONE_NUMBER +
  '</a>.</p>';
export const SIGNALR_EXCEPTION_HARDSTOP_NOTIFICATION_OPTIONS: NotificationOptions = {
  closeButton: false,
  reloadButton: true,
};

export const SIGNALR_RECONNECTED_USER_MESSAGE =
  'Success! You are now synced up with the other participants and are able to continue.';

export const SIGNALR_RECONNECTED_NOTIFICATION_OPTIONS: NotificationOptions = {
  closeButton: true,
  tapToDismiss: true,
  timeOut: 3000,
};

export const TOKBOX_RECONNECTED_USER_MESSAGE =
  'Success! Video has been re-enabled for one or more participants.';

export const TOKBOX_RECONNECTED_NOTIFICATION_OPTIONS: NotificationOptions = {
  closeButton: true,
  tapToDismiss: true,
  timeOut: 3000,
};

export const SIGNALR_RECONNECTING_USER_MESSAGE =
  'Error: You have dropped out of sync with the other participants. Please wait a moment while we try to reconnect you, or you may refresh the page.';

export const SIGNALR_RECONNECTING_NOTIFICATION_OPTIONS: NotificationOptions = {
  closeButton: true,
  reloadButton: true,
};

export const INTERNET_CONNECTED_USER_MESSAGE = 'You are now connected to the Internet.';

export const INTERNET_CONNECTED_NOTIFICATION_OPTIONS: NotificationOptions = {
  closeButton: true,
  tapToDismiss: true,
  timeOut: 4000,
};

export const INTERNET_DISCONNECTED_USER_MESSAGE = 'Your device lost its Internet connection.';

export const INTERNET_DISCONNECTED_NOTIFICATION_OPTIONS: NotificationOptions = {
  closeButton: true,
  tapToDismiss: true,
  timeOut: 4000,
};

export const INTERNET_RECONNECTING_USER_MESSAGE =
  'Internet connection lost. Trying to reconnect...';

export const INTERNET_RECONNECTING_NOTIFICATION_OPTIONS: NotificationOptions = {
  closeButton: false,
  loadingIcon: true,
};
