import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';

import { AbstractWizardStepComponent, CheckInTaskCode, WizardStep } from 'src/app/features/wizard';
import { ApplicationInsightsService } from 'src/app/services/application-insights.service';
import { RootStoreState } from 'src/app/store';

import { KnowledgeBasedAuthenticationActions } from '../../store';

@Component({
  selector: 'app-kba-information',
  templateUrl: './kba-information.component.html',
  styleUrls: ['./kba-information.component.scss'],
  providers: [
    {
      provide: AbstractWizardStepComponent,
      useExisting: KbaInformationComponent,
    },
  ],
})
export class KbaInformationComponent
  extends AbstractWizardStepComponent
  implements OnDestroy, OnInit
{
  constructor(
    private readonly applicationInsightsService: ApplicationInsightsService,
    private readonly store: Store,
    private readonly router: Router
  ) {
    super(store);
    this.stepMetadata = {
      stepName: 'Instructions',
      checkInTaskCode: CheckInTaskCode.SecurityQuestionInstructions,
    } as WizardStep;

    this.formStep = new UntypedFormGroup({});
  }

  ngOnInit() {
    super.ngOnInit();
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  onStepVisible() {
    this.store.dispatch(KnowledgeBasedAuthenticationActions.ViewInfoPage());
    this.applicationInsightsService.logPageView(
      'LexisNexis KBA Instructions Page',
      this.router.url
    );
  }
}
