import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Observable } from 'rxjs';

import { PackageUser } from 'src/app/features/package-users';

@Component({
  selector: 'app-session-status-splash',
  templateUrl: './session-status-splash.component.html',
  styleUrls: ['./session-status-splash.component.scss'],
})
export class SessionStatusSplashComponent implements OnChanges {
  @Input() user$: Observable<PackageUser>;
  @Input() title: string;
  @Input() message: string;
  @Input() supportTitle: string;
  @Input() footerMessage: string;
  @Input() foregroundImage: string;
  @Input() altText: string;
  @Input() backgroundImage: string;

  public backgroundImageStyle: string;

  ngOnChanges(changes: SimpleChanges) {
    if (changes.backgroundImage?.currentValue) {
      this.backgroundImageStyle = `url('${changes.backgroundImage.currentValue}')`;
    }
  }
}
