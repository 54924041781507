<app-leave-site-warning></app-leave-site-warning>
<div class="eula-container" *ngIf="user$ | async as user">
  <h1 class="large-title" data-private>Welcome, {{ user.firstName }} {{ user.lastName }}!</h1>
  <h2 class="sub-title">
    Before we get this signing started, please read and accept our license agreement...
  </h2>

  <div
    class="consent-box"
    *ngIf="eulaFileContent$ | async as eulaFileContent"
    [innerHTML]="eulaFileContent"
  ></div>
  <app-button-container>
    <app-button type="secondary" (click)="onDecline()" [dataTestId]="'eula-decline'">
      Decline
    </app-button>
    <app-button type="primary" (click)="onAccept()" [dataTestId]="'eula-accept'"
      >I Agree</app-button
    >
  </app-button-container>
  <br />
</div>
