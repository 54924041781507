<div>
  <div class="form-step__checkbox">
    <label class="form-step__checkbox-label" data-testid="consent-agree-checkbox">
      <input
        type="checkbox"
        [checked]="checked"
        (change)="onCheckboxChange($event.target.checked)"
        (blur)="onTouched()"
      />
      <span [ngClass]="{ error: formStep.errors }" data-testid="consent-agree-text">
        {{ consentText }}
      </span>
    </label>
  </div>
  <div
    [style.visibility]="controlHasErrors() ? 'visible' : 'hidden'"
    class="error-message"
    data-testid="consent-error"
  >
    {{ consentErrorText }}
  </div>
</div>
