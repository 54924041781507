import { Component } from '@angular/core';

@Component({
  selector: 'app-generic-spinner',
  templateUrl: './generic-spinner.component.html',
  styleUrls: ['./generic-spinner.component.scss'],
})
export class GenericSpinnerComponent {
  static identifier = 'GenericSpinnerComponent';
}
