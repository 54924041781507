<div class="confirmation-modal-container">
  <div class="confirmation-modal-container__header">
    <div>
      <ng-content select="[header]"></ng-content>
    </div>
    <div class="sub-header">
      <ng-content select="[subHeader]"></ng-content>
    </div>
  </div>
  <div class="confirmation-modal-container__body">
     <ng-content select="[content]"></ng-content>
  </div>
  <div class="confirmation-modal-container__footer two-buttons">
    <ng-content select="[actionButton]"></ng-content>
  </div>
</div>
