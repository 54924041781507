import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { DeviceDetectorService } from 'ngx-device-detector';
import { combineLatest, interval, merge, Observable, of, Subject } from 'rxjs';
import {
  catchError,
  filter,
  map,
  mergeMap,
  startWith,
  switchMap,
  takeUntil,
  tap,
  withLatestFrom,
} from 'rxjs/operators';

import { LobbySelectors } from 'src/app/features/lobby';
import { ModalsActions, ModalsSelectors } from 'src/app/features/modals';
import { SetStandaloneModalComponent } from 'src/app/features/modals/store/actions/modals.actions';
import { PackageUsersSelectors } from 'src/app/features/package-users';
import { PackagesSelectors } from 'src/app/features/packages';
import { SigningRoomSelectors } from 'src/app/features/signing-room/store';
import { VideoProblemsWithDeviceModalComponent } from 'src/app/features/video/components';
import { VideoActions } from 'src/app/features/video/store';
import { WizardSelectors } from 'src/app/features/wizard/store/selectors';
import { ApplicationInsightsService } from 'src/app/services/application-insights.service';
import { RootStoreState } from 'src/app/store';

import { NavigatorMediaDeviceWrapperService } from '../../services/navigator-media-device-wrapper.service';
import { AudioVideoConnectionMonitorSelectors } from '../../store';

@Component({
  selector: 'app-audio-video-connection-monitor',
  template: '',
})
export class AudioVideoConnectionMonitorComponent implements OnInit, OnDestroy {
  private selectedAudioDevice$: Observable<MediaDeviceInfo>;
  private selectedVideoDevice$: Observable<MediaDeviceInfo>;
  private onDeviceChange$: Observable<Event>;
  private packageUserGuid$: Observable<string>;
  private packageGuid$: Observable<string>;

  private readonly destroySubject: Subject<boolean> = new Subject<boolean>();
  static identifier = 'AudioVideoConnectionMonitorComponent';
  static pollingIntervalInMs = 10000;
  private hasError = false;
  private hasLoggedAudioVideoConnected = false;

  constructor(
    private readonly navigatorMediaDeviceWrapperService: NavigatorMediaDeviceWrapperService,
    private readonly store: Store<RootStoreState.State>,
    private readonly applicationInsightsService: ApplicationInsightsService,
    private readonly deviceDetectorService: DeviceDetectorService,
    private readonly router: Router
  ) {}

  ngOnDestroy(): void {
    this.destroySubject.next(undefined);
    this.destroySubject.complete();
  }

  ngOnInit(): void {
    if (!this.isDeviceSupported()) {
      return;
    }

    this.selectedAudioDevice$ = this.store.select(
      AudioVideoConnectionMonitorSelectors.getSelectedAudioDevice
    );
    this.selectedVideoDevice$ = this.store.select(
      AudioVideoConnectionMonitorSelectors.getSelectedVideoDevice
    );
    this.packageUserGuid$ = this.store.select(PackageUsersSelectors.getActivePackageUserGuid);
    this.packageGuid$ = this.store.select(PackagesSelectors.getActivePackageGuid);

    this.store
      .select(PackagesSelectors.packageCompleteOrCancelled)
      .pipe(takeUntil(this.destroySubject))
      .subscribe((packageCompleteOrCancelled) => {
        if (packageCompleteOrCancelled) {
          this.store.dispatch(ModalsActions.ClearModalComponent());
        }
      });

    // When the onDeviceChange event emits, enumerate all the most recent connected devices
    // Not all browsers support the ondevicechange event.  If a browser doesn't support ondevicechange,
    // fallback to long-polling
    if (this.navigatorMediaDeviceWrapperService.browserSupportsDeviceChange()) {
      this.onDeviceChange$ = this.navigatorMediaDeviceWrapperService
        .onDeviceChange()
        .pipe(takeUntil(this.destroySubject));
    } else {
      this.onDeviceChange$ = interval(
        AudioVideoConnectionMonitorComponent.pollingIntervalInMs
      ).pipe(
        switchMap((e) => of(new Event('devicechange'))),
        takeUntil(this.destroySubject)
      );
    }

    // This prompts the user for permissions to access their camera and mic
    const mediaStream$ = this.navigatorMediaDeviceWrapperService
      .getUserMedia({ audio: true, video: true })
      .pipe(
        withLatestFrom(this.selectedAudioDevice$, this.selectedVideoDevice$),
        // This obtains the MediaStream from their camera and mic after the user grants permissions
        switchMap(([mediaStream, selectedAudioDevice, selectedVideoDevice]) =>
          this.navigatorMediaDeviceWrapperService.getUserMedia({
            audio: { deviceId: selectedAudioDevice.deviceId },
            video: { deviceId: selectedVideoDevice.deviceId },
          })
        ),
        mergeMap((mediaStream) => {
          // This will listen for permission changes on the camera and mic by subscribing to the Track ended events
          const audioEnded =
            this.navigatorMediaDeviceWrapperService.getOnMediaStreamTrackEnded<Event>(
              mediaStream.getAudioTracks()[0]
            );
          const videoEnded =
            this.navigatorMediaDeviceWrapperService.getOnMediaStreamTrackEnded<Event>(
              mediaStream.getVideoTracks()[0]
            );
          // Start off the observable stream by returning the current media stream, and emit the media stream everytime the track end event emits
          return merge(audioEnded, videoEnded).pipe(
            switchMap((e) => of(mediaStream)),
            startWith(mediaStream),
            takeUntil(this.destroySubject)
          );
        }),
        // Any time there is an error, emit an empty media stream
        // If the browser doesn't have permissions,when enumerateDevices() runs,it will return an emptydevice list
        catchError((err) => of(new MediaStream()))
      );

    // combine the selected devices w/ the device list to check if the selected
    // devices are no longer in the device list
    combineLatest([
      this.selectedAudioDevice$,
      this.selectedVideoDevice$,
      this.packageUserGuid$,
      this.packageGuid$,
      this.onDeviceChange$.pipe(startWith(new Event('Initialize'))),
      mediaStream$,
    ])
      .pipe(
        mergeMap(([selectedAudioDevice, selectedVideoDevice, packageUserGuid, packageGuid]) =>
          this.navigatorMediaDeviceWrapperService.enumerateDevices().pipe(
            map((attachedDevices) => ({
              selectedAudioDevice,
              selectedVideoDevice,
              packageUserGuid,
              packageGuid,
              attachedDevices: attachedDevices,
            }))
          )
        ),
        map((devices) => {
          const audioAvailable = devices.attachedDevices.some(
            (d) =>
              d.kind === 'audioinput' &&
              d.label === devices.selectedAudioDevice.label &&
              d.deviceId === devices.selectedAudioDevice.deviceId
          );
          const videoAvailable = devices.attachedDevices.some(
            (d) =>
              d.kind === 'videoinput' &&
              d.label === devices.selectedVideoDevice.label &&
              d.deviceId === devices.selectedVideoDevice.deviceId
          );
          return { ...devices, audioAvailable, videoAvailable };
        }),
        takeUntil(this.destroySubject)
      )
      .subscribe((result) => {
        if (!this.hasError && (!result.audioAvailable || !result.videoAvailable)) {
          this.hasError = true;
          this.logToApplicationInsights(
            'AV_DEVICE_DISCONNECTED',
            result.selectedAudioDevice,
            result.selectedVideoDevice,
            result.packageUserGuid,
            result.packageGuid
          );
          this.dispatchModal();
        } else if (!this.hasLoggedAudioVideoConnected) {
          this.hasLoggedAudioVideoConnected = true;
          this.logToApplicationInsights(
            'AV_DEVICE_CONNECTED',
            result.selectedAudioDevice,
            result.selectedVideoDevice,
            result.packageUserGuid,
            result.packageGuid
          );
        }
      });
  }

  dispatchModal() {
    combineLatest([
      this.store.pipe(select(LobbySelectors.getActivePackageUser)),
      this.store.pipe(select(SigningRoomSelectors.sessionJoined)),
      this.store.pipe(select(WizardSelectors.hasActiveWizardUser)),
      this.store.select(ModalsSelectors.getModalComponent),
      this.store.select(PackagesSelectors.packageCompleteOrCancelled),
    ])
      .pipe(
        filter(([_, __, ___, modalComponent, ____]) => !modalComponent),
        tap(
          ([
            packageUser,
            sessionJoined,
            hasActiveWizardUser,
            modalComponent,
            packageCompleteOrCancelled,
          ]) => {
            const isCheckInStarted = packageUser?.checkInStatus.isStarted || hasActiveWizardUser;
            if (isCheckInStarted && !sessionJoined && !packageCompleteOrCancelled) {
              this.store.dispatch(
                SetStandaloneModalComponent({
                  payload: {
                    component: VideoProblemsWithDeviceModalComponent,
                    componentData: { isPublishingError: false },
                  },
                })
              );
            } else if (sessionJoined) {
              this.store.dispatch(
                VideoActions.SetVideoConnectionIssue({
                  payload: {
                    isConnected: false,
                    isPublisher: false,
                  },
                })
              );
            } else if (!isCheckInStarted && !sessionJoined && !modalComponent) {
              this.store.dispatch(
                ModalsActions.SetStandaloneModalComponent({
                  payload: {
                    component: VideoProblemsWithDeviceModalComponent,
                    componentData: { hideCancelButton: true },
                  },
                })
              );
            }
          }
        )
      )
      .subscribe();
  }

  logToApplicationInsights(
    eventName,
    selectedAudioDevice,
    selectedVideoDevice,
    packageUserGuid,
    packageGuid
  ) {
    this.applicationInsightsService.logEvent(eventName, {
      route: this.router.url,
      audioDevice: selectedAudioDevice.label,
      videoDevice: selectedVideoDevice.label,
      packageUserGuid: packageUserGuid,
      packageGuid: packageGuid,
    });
  }

  /*
  This disables the AV connection monitor due to a bug where the AV connection monitor
  will break on refresh when re-connecting.
  This occurs on any device running iOS/iPadOS (macOS works fine).
  Some iPads come up as iOS, some devices come up as tablets with the "Mac" operating system.
  Also fixes the bug on Android devices where other opened tabs could not share the same device permissions.
  */
  isDeviceSupported() {
    const deviceInfo = this.deviceDetectorService.getDeviceInfo();

    // If the device monitor returns null for any reason, prevent Audio Video connection monitor from running
    // Or any iOS devices that might not register as a phone or tablet.
    if (!deviceInfo || deviceInfo.os === 'iOS') {
      return false;
    }

    const isMobile = this.deviceDetectorService.isMobile() || this.deviceDetectorService.isTablet();
    return !isMobile;
  }
}
