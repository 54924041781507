import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { SigningRoomActions } from 'src/app/features/signing-room/store';
import { RootStoreState } from 'src/app/store';

@Component({
  selector: 'app-completed-signing',
  templateUrl: './completed-signing.component.html',
  styleUrls: ['./completed-signing.component.scss'],
})
export class CompletedSigningComponent implements OnInit {
  constructor(private readonly store: Store<RootStoreState.State>) {}

  ngOnInit(): void {
    this.store.dispatch(
      SigningRoomActions.SetShowCongratulationsPage({
        payload: { showCongratulations: false },
      })
    );
  }
}
