<app-fetch-package-users></app-fetch-package-users>
<div class="whose-here">
  <div class="participants" *ngIf="(signingAgent$ | async) as signingAgent">
    <app-welcome-participant [participant]="signingAgent"></app-welcome-participant>
  </div>
  <p class="tip">
    <b>Status:</b> {{ countNonSasCheckingIn$ | async }} out of {{ totalNonSas$ | async }} signers
    have arrived and began checking in.
  </p>

  <div class="button-container">
    <app-button
      *ngIf="(isSaCheckedIn$ | async) === false; else goToWaitingRoomButton"
      (buttonClick)="beginCheckIn()"
      [type]="'primary'"
      [ariaLabel]="
        (isSaSelected$ | async) ? null : 'button disabled until participant selection is made'
      "
      [isDisabled]="(isSaSelected$ | async) === false"
      [dataTestId]="'begin-check-in-button'"
      >Begin Check In</app-button
    >
  </div>
</div>

<ng-template #goToWaitingRoomButton>
  <app-button
    (buttonClick)="goToWaitingRoom()"
    [type]="'primary'"
    [ariaLabel]="
      (isSaSelected$ | async) ? null : 'button disabled until participant selection is made'
    "
    [isDisabled]="(isSaSelected$ | async) === false"
    [dataTestId]="'go-to-waiting-room-button'"
    >Go to Waiting Room</app-button
  >
</ng-template>
