export interface LongPollingConfigSchema {
  [x: string]: {
    interval: number;
  };
}

export const LongPollingConfig: LongPollingConfigSchema = {
  ['6Seconds']: {
    interval: 6000,
  },
  ['10Seconds']: {
    interval: 10000,
  },
  ['16Seconds']: {
    interval: 16000,
  },
  ['30Seconds']: {
    interval: 30000,
  },
  ['60Seconds']: {
    interval: 60000,
  },
};
