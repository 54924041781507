import {
  createFeatureSelector,
  createSelector
} from '@ngrx/store';

import { MultiFactorState } from '../state';

export const getMultiFactorState = createFeatureSelector<MultiFactorState.State>(
  'multiFactor'
);

export const getActiveMultiFactorAttemptPackageUserGuid = createSelector(
  getMultiFactorState,
  (state: MultiFactorState.State) => state.activeMultiFactorAttemptPackageUserGuid
);

export const getCurrentlySelectedChallengeType = createSelector(
  getMultiFactorState,
  (state: MultiFactorState.State) => state.currentlySelectedChallengeType
);

export const getAttemptsRemaining = createSelector(
  getMultiFactorState,
  (state: MultiFactorState.State) => state.attemptsRemaining
);

export const isChallengeInProgress = createSelector(
  getMultiFactorState,
  (state: MultiFactorState.State) => state.challengeCreated
);

export const getChallengeCreatedAtDate = createSelector(
  getMultiFactorState,
  (state: MultiFactorState.State) => state.challengeCreatedAt
);

export const getCompleteMultiFactorChallengeLoading = createSelector(
  getMultiFactorState,
  (state: MultiFactorState.State) => state.completeMultiFactorChallengeLoading
);

export const getErrorMessage = createSelector(
  getMultiFactorState,
  (state: MultiFactorState.State) => state.errorMessage
);
