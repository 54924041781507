<div class="document-scoreboard">
  <button
    [ngClass]="{
        'document-scoreboard__end-button': true,
        'document-scoreboard__previous-button': true,
        'document-scoreboard__end-button--disabled': !isPreviousButtonEnabled
      }"
    (click)="onPreviousClick()" [disabled]="!isPreviousButtonEnabled"
    data-testid="scoreboard-previous"
  >
    Previous
  </button>
  <div class="document-scoreboard__content">
    <ng-content></ng-content>
  </div>
  <button
    [ngClass]="{
        'document-scoreboard__end-button': true,
        'document-scoreboard__next-button': true,
        'document-scoreboard__end-button--disabled': !isNextButtonEnabled
      }" (click)="nextClick()" [disabled]="!isNextButtonEnabled"
    data-testid="scoreboard-next"
  >
    Next
  </button>
</div>
