import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import SignaturePad, { PointGroup } from 'signature_pad';

import { debounce } from 'src/app/features/shared/util';

import { DrawnEndorsement, SignatureValidity } from '../../models';
import { CanvasValidationService, CanvasValidationServiceFactory } from '../../services';

@Component({
  selector: 'app-signature-pad',
  templateUrl: './signature-pad.component.html',
  styleUrls: ['./signature-pad.component.scss'],
})
export class SignaturePadComponent implements OnInit, OnDestroy {
  @Input() mode: string;
  @Input() showErrorMessages: boolean;
  @Input() validateComplexity = true;
  @Input() existingSignature: DrawnEndorsement;
  @Output() signatureSet = new EventEmitter<DrawnEndorsement>();
  @Output() cancel = new EventEmitter<null>();
  @ViewChild('signaturepad', { static: true })

  _canvas!: ElementRef<HTMLCanvasElement>;

  signaturePad: SignaturePad; // SignaturePad
  resizeListener: any; // ResizeObserver
  previousWidth: number;
  previousHeight: number;
  previousPixelRatio: number;

  public svgText: string;
  signatureValidity: SignatureValidity;
  private readonly signatureValidationService: CanvasValidationService;

  constructor(private readonly signatureValidationServiceFactory: CanvasValidationServiceFactory) {
    this.signatureValidationService =
      this.signatureValidationServiceFactory.getCanvasValidationService();
  }

  ngOnInit(): void {
    this.previousWidth = this.canvas.offsetWidth;
    this.previousHeight = this.canvas.offsetHeight;

    this.signaturePad = new SignaturePad(this.canvas);
    this.resizeCanvas();

    // @ts-ignore
    this.resizeListener = new ResizeObserver(
      debounce(() => {
        this.resizeCanvas();
      }, 500)
    ); // Ignore can be removed after Typescript version upgrade.
    this.resizeListener.observe(this.canvas);

    if (!!this.existingSignature) {
      this.applySignature(this.existingSignature);
    }
  }

  ngOnDestroy(): void {
    this.resizeListener.disconnect();
  }

  setSignature(): void {
    this.signatureValidity = this.isDrawingValid();

    if (this.signatureValidity.isValid) {
      this.svgText = this.toSVG();
      this.signatureSet.emit({
        svgImageString: this.svgText,
        signatureData: this.signaturePad.toData(),
      });
    }
  }

  isDrawingValid(): SignatureValidity {
    const isDrawingContained = this.signatureValidationService.isDrawingContained(this.canvas);
    const isDrawingComplex = this.validateComplexity
      ? this.signatureValidationService.isDrawingComplex(this.canvas)
      : !this.signaturePad.isEmpty();
    return {
      isComplex: isDrawingComplex,
      isTouchingEdge: !isDrawingContained,
      isValid: isDrawingContained && isDrawingComplex,
    };
  }

  resizeCanvas(): void {
    let signatureData;
    if (!this.signaturePad.isEmpty()) {
      signatureData = this.signaturePad.toData();
    }

    const pixelRatio = this.devicePixelRatio;

    this.canvas.width = this.canvas.offsetWidth * pixelRatio;
    this.canvas.height = this.canvas.offsetHeight * pixelRatio;
    this.canvas.getContext('2d').scale(pixelRatio, pixelRatio);
    this.clearCanvas(); // otherwise isEmpty() might return incorrect value
    this.calculateLineWidth(this.canvas.offsetWidth);

    if (signatureData) {
      const xRatio = this.canvas.offsetWidth / this.previousWidth;
      const yRatio = this.canvas.offsetHeight / this.previousHeight;
      signatureData = this.scalePoints(signatureData, xRatio, yRatio);
      this.signaturePad.fromData(signatureData);
    }

    this.previousHeight = this.canvas.offsetHeight;
    this.previousWidth = this.canvas.offsetWidth;
    this.previousPixelRatio = pixelRatio;
  }

  scalePoints(signatureData: Array<PointGroup>, xRatio: number, yRatio: number): any {
    return signatureData.map((lineData) => {
      return {
        ...lineData,
        points: lineData.points.map((pointData) => {
          return {
            ...pointData,
            x: pointData.x * xRatio,
            y: pointData.y * yRatio,
          };
        }),
      };
    });
  }

  calculateLineWidth(componentWidth: number): void {
    if (componentWidth < 700) {
      this.setLineWidth(1.75, 2.5);
      return;
    }

    if (componentWidth < 900) {
      this.setLineWidth(3, 4);
      return;
    }

    if (componentWidth < 1440) {
      this.setLineWidth(4, 5);
    }

    this.setLineWidth(5, 7);
  }

  setLineWidth(minWidth: number, maxWidth: number): void {
    this.signaturePad.minWidth = minWidth;
    this.signaturePad.maxWidth = maxWidth;
  }

  toSVG(): string {
    //ensure the canvas is sized for the correct Device Pixel Ratio before saving
    if (this.devicePixelRatio !== this.previousPixelRatio) {
      this.resizeCanvas();
    }
    this.svgText = this.signaturePad.toDataURL('image/svg+xml');
    return this.svgText;
  }

  clearCanvas(): void {
    this.signaturePad.clear();
    this.signatureValidity = null;
  }

  applySignature(signature: DrawnEndorsement) {
    this.signaturePad.fromData(signature.signatureData);
  }

  cancelForInstance(): void {
    this.cancel.emit();
  }

  get canvas(): HTMLCanvasElement {
    return this._canvas.nativeElement;
  }

  get devicePixelRatio(): number {
    return Math.max(window.devicePixelRatio || 1, 1);
  }
}
