import { AfterViewInit, Component, ElementRef, Input, OnDestroy, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import * as OT from '@opentok/client';
import { ModalsActions } from 'src/app/features/modals/store';

import { NotificationsActions, NotificationType } from 'src/app/features/notifications';
import { ExceptionType } from 'src/app/features/notifications/models';
import { RootStoreState } from 'src/app/store';

import { v4 as uuid } from 'uuid';
import { VideoIssueModals } from '../../enums/video-issue-modals.enum';

import { VideoActions } from '../../store';

@Component({
  selector: 'app-subscriber',
  templateUrl: './subscriber.component.html',
  styleUrls: ['./subscriber.component.scss'],
})
export class SubscriberComponent implements AfterViewInit, OnDestroy {
  @ViewChild('subscriberDiv') subscriberDiv: ElementRef;
  @Input() videoSession: OT.Session;
  @Input() stream: OT.Stream;
  subscriber: OT.Subscriber;

  constructor(private readonly store: Store<RootStoreState.State>) {}

  ngAfterViewInit() {
    this.subscriber = this.videoSession.subscribe(
      this.stream,
      this.subscriberDiv.nativeElement,
      {
        insertMode: 'append',
        height: '100%',
        width: '100%',
      },
      (err) => {
        if (err) {
          this.store.dispatch(
            NotificationsActions.AddNotification({
              payload: {
                notificationType: NotificationType.Error,
                id: uuid(),
                text: `TokBox Subscribe error: ${err.message}`,
                exceptionType: ExceptionType.CannotProceed,
                logInAppInsights: true,
              },
            })
          );
        }
      }
    );
    this.configureSubscriberListeners();
  }

  ngOnDestroy() {
    this.removeSubscriberListeners();
  }

  configureSubscriberListeners() {
    this.subscriber.on('videoDisableWarning', (videoDisableWarningEvent) => {
      this.store.dispatch(
        NotificationsActions.AddNotification({
          payload: {
            notificationType: NotificationType.Warning,
            id: uuid(),
            text: `Warning: Video quality is degrading and may be disabled for one or more participants. Please check your network connection.`,
            exceptionType: ExceptionType.None,
            logInAppInsights: true,
          },
        })
      );
    });

    this.subscriber.on('videoDisableWarningLifted', (videoDisableWarningEvent) => {
      this.store.dispatch(
        NotificationsActions.AddNotification({
          payload: {
            notificationType: NotificationType.Info,
            id: uuid(),
            text: `Info: Video quality is no longer degrading for one or more participants.`,
            exceptionType: ExceptionType.None,
            logInAppInsights: true,
          },
        })
      );
    });
    // https://tokbox.com/developer/sdks/js/reference/Subscriber.html#event:videoDisabled
    this.subscriber.on('videoDisabled', (videoDisableEvent) => {
      if (videoDisableEvent.reason === 'publishVideo') {
        this.store.dispatch(VideoActions.ShowWaitingOnOtherParticipantsModal());
      } else {
        this.store.dispatch(
          VideoActions.SetVideoVisibilityIssue({
            payload: {
              isConnected: false,
              isPublisher: false,
              modalType: VideoIssueModals.VideoSignalDegraded,
            },
          })
        );
      }
      this.store.dispatch(
        NotificationsActions.AddNotification({
          payload: {
            notificationType: NotificationType.Warning,
            id: uuid(),
            text: `TokBox error: Video has been disabled for stream id ${videoDisableEvent.target.stream.streamId} with reason: ${videoDisableEvent.reason}`,
            exceptionType: ExceptionType.CannotProceed,
            logInAppInsights: true,
          },
        })
      );
    });

    this.subscriber.on('videoEnabled', (videoEnableEvent) => {
      const videoStreamId = videoEnableEvent.target.stream.streamId;
      this.store.dispatch(VideoActions.SendVideoEnabled({ payload: { videoStreamId: videoStreamId } }));
      this.store.dispatch(ModalsActions.ClearModalComponent());
      this.store.dispatch(
        NotificationsActions.AddNotification({
          payload: {
            notificationType: NotificationType.Success,
            id: uuid(),
            text: `TokBox info: Video has been re-enabled for stream id ${videoEnableEvent.target.stream.streamId}.`,
            exceptionType: ExceptionType.None,
            logInAppInsights: true,
          },
        })
      );
    });
  }

  removeSubscriberListeners() {
    this.subscriber.off('videoDisableWarning');
    this.subscriber.off('videoDisableWarningLifted');
    this.subscriber.off('videoDisabled');
    this.subscriber.off('videoEnabled');
  }
}
