<div id="certificatePasswordModal" class="generic-confirm-modal">
  <div class="generic-confirm-modal__header">
    <h3 class="modal-title">Digital certificate</h3>
  </div>
  <div class="generic-confirm-modal__body" [formGroup]="certificatePasswordValidationForm">
    <div class="description-message">
      Enter the password for your digital certificate. Your password will be stored automatically so
      you won't have to enter it again in the future.
    </div>

    <div ng-class="has - error">
      <input
        #input
        type="password"
        class="form-control no-password-prompt"
        [class.form-error]="!!validationMsg"
        placeholder="Enter your certificate password"
        formControlName="passwordEntry"
        required
        autocomplete="one-time-code"
        (keydown)="continueOnEnterKey($event)"
        data-testid="cerificate-password"
      />
    </div>
    <div class="errormsg" *ngIf="!!validationMsg">
      {{ validationMsg }}
    </div>
  </div>
  <div class="generic-confirm-modal__footer">
    <app-button-container>
      <app-button
        type="tertiary"
        [isDisabled]="isLoading"
        (click)="cancel()"
        [dataTestId]="'cancel-certificate-password'"
      >
        Cancel
      </app-button>
      <app-button
        type="primary"
        [isDisabled]="isLoading"
        (click)="continue()"
        [dataTestId]="'continue-certificate-password'"
      >
        <span *ngIf="!isLoading">Continue</span>
        <span *ngIf="isLoading" class="fas fa-spin fa-circle-notch"></span>
      </app-button>
    </app-button-container>
  </div>
</div>
