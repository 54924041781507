import { CommonModule } from '@angular/common';
import { Component, computed, inject, OnInit, Signal } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';

import { AudioVideoCheckService } from 'src/app/features/av-check/services/audio-video-check.service';
import { AvCheckGuideComponent } from 'src/app/features/av-check/shared/av-check-guide/av-check-guide.component';
import { GadgetGuideResponseComponent } from 'src/app/features/gadget-guide/components/gadget-guide-response/gadget-guide-response.component';
import { GadgetGuideService } from 'src/app/features/gadget-guide/services/gadget-guide.service';
import { GadgetGuide, GadgetGuideResponse, GadgetType } from 'src/app/features/gadget-guide/services/gadget-guide.types';
import { SharedModule } from 'src/app/features/shared';

@Component({
  selector: 'app-video-selection',
  standalone: true,
  imports: [
    AvCheckGuideComponent,
    CommonModule,
    FormsModule,
    MatExpansionModule,
    MatIconModule,
    MatSelectModule,
    SharedModule,
    GadgetGuideResponseComponent
  ],
  providers: [GadgetGuideService],
  templateUrl: './video-selection.component.html',
  styleUrl: './video-selection.component.scss',
})
export class VideoSelectionComponent implements OnInit {
  audioVideoCheckService = inject(AudioVideoCheckService);
  gadgetGuideService = inject(GadgetGuideService);

  showVideoGuideDetails = false;

  gadgetGuideServiceCameraResponse: Signal<GadgetGuideResponse> = computed(() => this.gadgetGuideService.getMarkupForGadgetGuide(
    this.gadgetGuideService.gadgetGuides().gadgetGuide,
    this.gadgetGuideService.cameraKey(),
    'response'
  ));

  isLoading = computed(() => {
    const gadgetGuide = this.gadgetGuideService.getGadgetGuideByCameraKey();
    const status = gadgetGuide()?.status;

    return status === 'init' || status !== 'success';
  });
  hasLoadingFailure = computed(() => {
    const gadgetGuide = this.gadgetGuideService.getGadgetGuideByCameraKey();

    return gadgetGuide()?.status?.match(/^error:/);
  });

  async ngOnInit(): Promise<void> {
    const gadgetGuideForCamera: GadgetGuide = await this.gadgetGuideService.initGadgetGuideRequest({
      gadgetType: GadgetType.Camera,
    });

    const [key] = Object.keys(gadgetGuideForCamera);
    this.gadgetGuideService.cameraKey.set(key);
  }
}
