export enum CheckInTaskCode {
  CommissionSelection = 'CommissionSelection',
  SignerDetails = 'SignerDetails',
  SignerDetailsVerification = 'SignerDetailsVerification',
  IdVerificationInstructions = 'IdVerificationInstructions',
  IdVerificationQrCode = 'IdVerificationQrCode',
  IdVerification = 'IdVerification',
  SecurityQuestionInstructions = 'SecurityQuestionInstructions',
  SecurityQuestions = 'SecurityQuestions',
  SignatureCreation = 'SignatureCreation',
  InitialsCreation = 'InitialsCreation',
  SignatureConfirmation = 'SignatureConfirmation',
  TermsOfService = 'TermsOfService',
}
