import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { delayedRetry } from 'src/app/operators';
import { ApplicationInsightsService } from 'src/app/services/application-insights.service';

import { Feature, FeatureFlag } from '../models';

@Injectable({ providedIn: 'root' })
export class FeatureManagementService {
  private _featureFlags: Map<Feature, boolean> = new Map<Feature, boolean>();

  constructor(
    private readonly http: HttpClient,
    private readonly applicationInsightsService: ApplicationInsightsService
  ) {}

  getIsFeatureFlagEnabled(featureName: Feature): Observable<boolean> {
    return this.http.get<FeatureFlag>(`featureMangagement/${featureName}`).pipe(
      map((data) => {
        return data.isEnabled;
      }),
      delayedRetry(1000, 2),
      catchError((err) => {
        return of(false);
      })
    );
  }

  getIsFeatureEnabledWithCaching(featureName: Feature): boolean {
    if (this._featureFlags.size > 0 && this._featureFlags.has(featureName)) {
      return this._featureFlags.get(featureName);
    }

    this.getFeatureFlags().subscribe(
      (data: Map<Feature, boolean>) => {
        this._featureFlags = data;

        if (!this._featureFlags.has(featureName)) {
          this.applicationInsightsService.logEvent('FEATURE_MANAGEMENT_GET_ENABLED', {
            featureName: featureName,
          });
          return false;
        }

        return data.get(featureName);
      },
      (err) => {
        return of(false);
      }
    );
  }

  getFeatureFlags(): Observable<Map<Feature, boolean>> {
    return this.http.get<FeatureFlag[]>(`featureMangagement`).pipe(
      map((featureFlagArray) => {
        const featureMap = new Map<Feature, boolean>();
        featureFlagArray.forEach(function (flag) {
          featureMap.set(flag.name, flag.isEnabled);
        });
        this._featureFlags = featureMap;
        return featureMap;
      }),
      delayedRetry(1000, 2),
      catchError((err) => {
        this._featureFlags = new Map<Feature, boolean>();
        return of(this._featureFlags);
      })
    );
  }
}
