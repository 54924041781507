<button
  mat-button
  #menuTrigger="matMenuTrigger"
  [matMenuTriggerFor]="menu"
  aria-label="button with a menu"
  data-testid="header-menu"
  tabindex="0"
>
    <span class="menu-button">
      Menu
      <mat-icon>{{ menuTrigger.menuOpen ? 'expand_less' : 'expand_more' }}</mat-icon>
    </span>
</button>
<mat-menu #menu="matMenu" class="header-menu">
  <button
    *ngIf="isDeviceSettingsButtonEnabled"
    (click)="openChangeAudioVideoModal()"
    mat-menu-item
    data-testid="header-device-settings"
    class="header-menu-item"
  >
    Device Settings
  </button>
  <button
    *ngIf="isOrderDetailsButtonEnabled"
    (click)="openDetailsModal()"
    mat-menu-item
    data-testid="header-order-details"
    class="header-menu-item"
  >
    Order Details
  </button>
  <button
    *ngIf="isIdVerifyButtonEnabled"
    (click)="openIdVerifyModal()"
    mat-menu-item
    data-testid="header-id-verify"
    class="header-menu-item"
  >
    ID Verify
  </button>
  <a
    *ngIf="isPrivacyPolicyButtonEnabled"
    mat-menu-item
    class="header-menu-item"
    href="https://www.amrock.com/privacy-policy"
    target="_blank"
    data-testid="header-privacy-policy"
    rel="noopener"
  >Privacy Policy</a
  >
  <a
    *ngIf="isTermsOfUseButtonEnabled"
    mat-menu-item
    class="header-menu-item"
    href="https://www.amrock.com/terms-of-use"
    target="_blank"
    data-testid="header-terms-of-use"
    rel="noopener"
  >Terms Of Use</a
  >
  <button
    *ngIf="isCancelSigningButtonEnabled"
    mat-menu-item
    (click)="openCancelSigningModal()"
    class="header-menu-item"
    data-testid="header-cancel-signing"
  >
    Cancel Signing
  </button>
  <button *ngIf="isPreSignLogoutButtonEnabled" mat-menu-item class="header-menu-item" (click)="dispatchPreSignLogout()"
          data-testid="presign-logout">
    Logout
  </button>
</mat-menu>
