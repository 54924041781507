import {
  createAction,
  props
} from '@ngrx/store';

import { UserTask } from '../../models';

export const ClearActiveUserTasks = createAction('[User Tasks] Clear Active User Tasks');

export const FetchActiveUserTasks = createAction('[User Tasks] Fetch Active User Tasks');

export const SetActiveUserTasks = createAction(
  '[User Tasks] Set Active User Tasks',
  props<{ payload: Array<UserTask<any>> }>()
);
