<div class="check-in-wizard" *ngIf="!isWizardCompleted"
     [class.check-in-wizard-kba-step]="isKbaQuestionsStep$ | async">
  <h1 class="check-in-wizard__title">
    Check-in: <span data-private>{{ userDisplayName$ | async }}</span>
  </h1>
  <app-step-tracker></app-step-tracker>
  <app-terms-of-service *ngIf="hasTask('TermsOfService')" [user]="user$ | async">
  </app-terms-of-service>
  <app-commission-selection
    *ngIf="hasTask('CommissionSelection')"
    [user]="user$ | async"
  ></app-commission-selection>
  <app-signer-details
    *ngIf="hasTask('SignerDetails')"
    [user]="user$ | async"
  ></app-signer-details>
  <app-verify-signer-details
    *ngIf="hasTask('SignerDetailsVerification')"
    [user]="user$ | async"
  ></app-verify-signer-details>
  <app-id-verification-step-group
    *ngIf="hasTask('IdVerification')"
    [initialTasks]="initialTasks"
    [user]="user$ | async"
  ></app-id-verification-step-group>
  <app-kba-information
    *ngIf="hasTask('SecurityQuestionInstructions')"
  ></app-kba-information>
  <app-kba-questions
    *ngIf="hasTask('SecurityQuestions')"
  ></app-kba-questions>
  <app-signature-creation
    *ngIf="hasTask('SignatureCreation')"
    [user]="user$ | async"
  ></app-signature-creation>
  <app-initials-creation
    *ngIf="hasTask('InitialsCreation')"
    [user]="user$ | async"
  ></app-initials-creation>
  <app-signature-confirmation
    *ngIf="hasTask('SignatureConfirmation')"
    [user]="user$ | async"
  ></app-signature-confirmation>
</div>
