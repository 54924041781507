import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import {
  ExceptionType,
  NotificationModel,
  NotificationType,
} from 'src/app/features/notifications/models';

import { NotificationsActions } from 'src/app/features/notifications/store/actions';
import { PackageUsersActions } from 'src/app/features/package-users';
import { PackageUser } from 'src/app/features/package-users/models';
import { PackageUsersSelectors } from 'src/app/features/package-users/store/selectors';
import { RootStoreState } from 'src/app/store';
import { v4 as uuid } from 'uuid';

import { ConsentsActions, ConsentsSelectors } from '../../store';

@Component({
  selector: 'app-eula',
  templateUrl: './eula.component.html',
  styleUrls: ['./eula.component.scss'],
})
export class EulaComponent implements OnInit, OnDestroy {
  static readonly TypeCode = 'EULATerms';

  eulaFileContent$: Observable<string>;
  isAcceptedSubscription: Subscription;
  declineMessage: string;
  user$: Observable<PackageUser>;

  notificationId: string;

  constructor(
    private readonly router: Router,
    private readonly store: Store<RootStoreState.State>,
  ) {}

  ngOnInit() {

    this.eulaFileContent$ = this.store.pipe(
      select(ConsentsSelectors.getEULAContent),
      map((eula) => eula?.presentationHtml)
    );

    this.store.dispatch(ConsentsActions.FetchEULAContent());
    this.user$ = this.store.select(PackageUsersSelectors.getActivePackageUser);

    // in the event that the EULA is loaded without a packageUserGuid,
    // manually issue a request to get the remaining needed items for the ui
    this.user$.pipe(
      filter(usr => !usr),
      take(1),
    ).subscribe(
      () => this.store.dispatch(PackageUsersActions.GetActivePackageUser())
    );
  }

  ngOnDestroy(): void {
    this.isAcceptedSubscription?.unsubscribe();
  }

  onAccept(): void {
    this.store.dispatch(ConsentsActions.AcceptEULA());

    this.isAcceptedSubscription = this.store
      .pipe(select(ConsentsSelectors.getEULAIsAccepted))
      .subscribe((result) => {
        this.clearToast();
        if (result) {
          this.router.navigate(['/'], { queryParamsHandling: 'preserve' });
        }
      });
  }

  onDecline(): void {
    this.clearToast();
    this.notificationId = uuid();
    this.declineMessage =
      'You must agree to the license agreement to continue.';
    this.store.dispatch(
      NotificationsActions.AddNotification({
        payload: {
          text: `${this.declineMessage}`.trim(),
          notificationType: NotificationType.Warning,
          id: this.notificationId,
          logInAppInsights: true,
          exceptionType: ExceptionType.DisplayMessage,
        } as NotificationModel,
      })
    );
  }

  clearToast() {
    if (this.notificationId) {
      this.store.dispatch(
        NotificationsActions.ClearNotification({
          payload: { notificationId: this.notificationId },
        })
      );
    }
    this.notificationId = undefined;
  }
}
