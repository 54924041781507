import { DOCUMENT } from '@angular/common';
import {
  Component,
  ComponentFactoryResolver,
  ElementRef,
  HostListener,
  inject,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable, Subject, Subscription } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';

import { RootStoreState } from 'src/app/store';
import { ModalContentDirective, ModalDialogDirective } from '../../directives';
import { DefaultModalSize, ModalSize } from '../../enums';

import { ModalsActions, ModalsSelectors } from '../../store';

@Component({
  selector: 'app-modal-container',
  templateUrl: './modal-container.component.html',
  styleUrls: ['./modal-container.component.scss'],
})
export class ModalContainerComponent implements OnInit, OnDestroy {
  @ViewChild(ModalContentDirective, { static: true })
  modalContentHost: ModalContentDirective;
  @ViewChild(ModalDialogDirective, { static: true, read: ElementRef })
  modalDialog: ElementRef;
  @ViewChild('dialog') dialog: ElementRef;

  private readonly document = inject(DOCUMENT);
  public modalSubscription: Subscription;
  public modalTitle$: Observable<string>;
  public showModal: boolean;
  hasFadedIn = false;
  allowManualClose = false;
  instanceIsStandalone = false;
  instanceShouldFade = false;
  useBackgroundOverlay: boolean;
  modalSize: ModalSize = DefaultModalSize;
  notifier = new Subject();

  constructor(
    private readonly componentFactoryResolver: ComponentFactoryResolver,
    private readonly store: Store<RootStoreState.State>
  ) {
    this.handleDialogKeyDown = this.handleDialogKeyDown.bind(this);
    this.handleDialogClose = this.handleDialogClose.bind(this);
  }

  ngOnInit(): void {
    this.store
      .pipe(
        takeUntil(this.notifier),
        select(ModalsSelectors.getModalComponentWithData),
        tap((data) => this.processModalDataChange(data))
      )
      .subscribe();

    this.modalTitle$ = this.store.pipe(select(ModalsSelectors.getModalTitle));
  }

  ngOnDestroy(): void {
    this.showModal = false;
    this.notifier.next(undefined);
    this.notifier.complete();
    this.document.removeEventListener('keydown', this.handleDialogKeyDown);
  }

  @HostListener('click', ['$event'])
  clickin(event: { target: any }) {
    if (
      this.allowManualClose &&
      !this.modalDialog.nativeElement.contains(event.target)
    ) {
      this.clearComponent();
    }
  }

  @HostListener('animationstart', ['$event'])
  onAnimationStart(event: AnimationEvent) {
    if (event.animationName === 'modal-fade-in') {
      this.hasFadedIn = true;
    }
  }

  @HostListener('animationend', ['$event'])
  onAnimationEnd(event: AnimationEvent) {
    if (event.animationName === 'modal-fade-out') {
      this.hasFadedIn = false;
      this.removeContent();
    }
  }

  processModalDataChange(data: any) {
    if (!data || !data.component) {
      this.clearComponent();
      return;
    }
    this.instanceIsStandalone = data.isStandalone;
    this.instanceShouldFade = data.shouldFade;
    this.allowManualClose = data.allowManualClose;
    this.modalSize = data.modalSize;
    this.useBackgroundOverlay = data.useBackgroundOverlay ?? true;
    this.loadComponent(data.component, data.componentData);
  }

  handleDialog(): void {
    requestAnimationFrame(() => this.dialog.nativeElement?.show());

    this.document.addEventListener('keydown', this.handleDialogKeyDown);
  }

  handleDialogClose(): void {
    requestAnimationFrame(() => this.dialog.nativeElement?.close());

    this.store.dispatch(ModalsActions.ClearModalComponent());
  }

  handleDialogKeyDown(e: KeyboardEvent): void {
    if (e.key === 'Escape') {
      if (this.allowManualClose) {
        this.handleDialogClose();

        return;
      }

      e.preventDefault();
    }
  }

  loadComponent(componentType: any, data: any) {
    this.handleDialog();
    this.showModal = true;

    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(
      componentType
    );

    const viewContainerRef = this.modalContentHost.viewContainerRef;
    viewContainerRef.clear();
    const componentRef = viewContainerRef.createComponent(componentFactory);

    if (data) {
      const dataProperties = Object.keys(data);

      dataProperties.forEach((key) => {
        (componentRef.instance as any)[key] = data[key];
      });
    }
  }

  clearComponent() {
    this.showModal = false;

    if (!this.instanceShouldFade) this.removeContent();
  }

  removeContent() {
    if (this.modalContentHost) {
      const viewContainerRef = this.modalContentHost.viewContainerRef;
      viewContainerRef.clear();
    }
  }
}
