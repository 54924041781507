import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';

import { SystemEndorsementImages } from '../../models';

export function selectEndorsementImageId(
  systemEndorsementImages: SystemEndorsementImages
): number {
  return systemEndorsementImages.endorsementImageId;
}

export const systemEndorsementImagesAdapter: EntityAdapter<SystemEndorsementImages> = createEntityAdapter<
  SystemEndorsementImages
>({
  selectId: selectEndorsementImageId,
  sortComparer: false,
});

export interface SystemEndorsementImagesState
  extends EntityState<SystemEndorsementImages> {}

const {
  selectEntities,
  selectAll,
  selectIds,
  selectTotal,
} = systemEndorsementImagesAdapter.getSelectors();
export const selectSystemEndorsementEntities = selectEntities;
export const selectAllSystemEndorsementImages = selectAll;
export const selectSystemEndorsementImageIds = selectIds;
