import { Component, EventEmitter, Input, Output } from '@angular/core';

import { Store } from '@ngrx/store';
import { ModalsActions } from 'src/app/features/modals';

import { RootStoreState } from 'src/app/store';

@Component({
  selector: 'app-documents-complete',
  templateUrl: './documents-complete.component.html',
  styleUrls: ['./documents-complete.component.scss'],
})
export class DocumentsCompleteComponent {
  static identifier = 'DocumentsCompleteComponent';
  @Input() onSuccess: () => void;
  @Output() public completeSession = new EventEmitter<void>();

  continueClick() {
   this.completeSession.emit();
  }

  static openModal(store: Store<RootStoreState.State>) {
    store.dispatch(ModalsActions.SetStandaloneModalComponent({
      payload:{
        component: DocumentsCompleteComponent,
        shouldFade: true,
      }
    }));
  }
}
