import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { CheckInTask, CheckInTaskCode } from 'src/app/features/wizard';

import {
  SignerDetailsStatus,
  SignerDetailsSubmission,
  StateList,
  FetchStatesResponse,
  OptionalParticipantField,
  SignerDetailsTaskConfiguration,
} from '../models/signer-details.model';

@Injectable()
export class SignerDetailsService {
  constructor(private readonly httpClient: HttpClient) {}

  mapCheckInTasksToStatus(tasks: CheckInTask[]): SignerDetailsStatus {
    const signerDetailsTask = tasks.find((t) => t.taskType === CheckInTaskCode.SignerDetails);
    const verificationTask = tasks.find(
      (t) => t.taskType === CheckInTaskCode.SignerDetailsVerification
    );

    const isRequiredToProvideParticipantDetails =
      !!signerDetailsTask && !signerDetailsTask.isCompleted;
    const isRequiredToCompleteMfaChallenge = !!verificationTask && !verificationTask.isCompleted;

    let signerDetailsStatus = {
      isRequiredToProvideParticipantDetails,
      isRequiredToCompleteMfaChallenge,
    } as SignerDetailsStatus;

    if (isRequiredToProvideParticipantDetails) {
      const taskConfiguration =
        signerDetailsTask.taskConfiguration as SignerDetailsTaskConfiguration;
      signerDetailsStatus = {
        ...signerDetailsStatus,
        ...this.mapMissingFieldsToStatus(taskConfiguration.missingFields),
      };
    }
    return signerDetailsStatus;
  }

  mapMissingFieldsToStatus(missingFields: OptionalParticipantField[]): SignerDetailsStatus {
    return {
      isMissingAddress: missingFields?.some((f) => f === OptionalParticipantField.Address),
      isMissingDateOfBirth: missingFields?.some((f) => f === OptionalParticipantField.DateOfBirth),
      isMissingEmail: missingFields?.some((f) => f === OptionalParticipantField.EmailAddress),
      isMissingFirstName: missingFields?.some((f) => f === OptionalParticipantField.FirstName),
      isMissingLastName: missingFields?.some((f) => f === OptionalParticipantField.LastName),
      isMissingSignatureName: missingFields?.some(
        (f) => f === OptionalParticipantField.SignatureName
      ),
      isMissingPhoneNumber: missingFields?.some((f) => f === OptionalParticipantField.PhoneNumber),
      isMissingSsn: missingFields?.some((f) => f === OptionalParticipantField.Ssn),
      isMissingVerificationType: missingFields?.some(
        (f) => f === OptionalParticipantField.VerificationType
      ),
    } as SignerDetailsStatus;
  }

  submitSignerDetails(packageUserGuid: string, signerDetails: SignerDetailsSubmission) {
    return this.httpClient.patch(`packageUsers/${packageUserGuid}`, signerDetails);
  }

  getStates(): Observable<StateList> {
    return this.httpClient
      .get<FetchStatesResponse>('states')
      .pipe(
        map((payload) =>
          payload.stateLookups.map((state) => ({ code: state.stateCode, name: state.stateName }))
        )
      );
  }

  logAdditionalDetailsRequested(packageUserGuid: string) {
    return this.httpClient.post(`packageUsers/${packageUserGuid}/additionalDetailsRequested`, {});
  }
}
