import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';

import { AuditLogsActions } from 'src/app/features/audit-logs';
import { PackagesActions } from 'src/app/features/packages';
import { RootStoreState } from 'src/app/store';

import { CheckInActions } from '../../store/actions';

@Component({
  selector: 'app-non-remote-welcome-page',
  templateUrl: './non-remote-welcome-page.component.html',
  styleUrls: ['./non-remote-welcome-page.component.scss'],
})
export class NonRemoteWelcomePageComponent implements OnInit {
  @Input() subHeading: string;
  @Input() areAllParticipantsCheckedIn: boolean;

  constructor(private readonly store: Store<RootStoreState.State>) {}

  ngOnInit(): void {
    this.store.dispatch(AuditLogsActions.LandingPageViewed());
    this.store.dispatch(PackagesActions.BeginSession());
    this.store.dispatch(CheckInActions.SyncUserCheckInCompleteWithPackageUsers());
  }

  beginCheckIn() {
    this.store.dispatch(CheckInActions.BeginCheckInForAllUsers());
  }

  enter() {
    this.store.dispatch(CheckInActions.NavigateAwayFromWelcomePage());
  }
}
