import { Component } from '@angular/core';
import { Store } from '@ngrx/store';

import { ModalsActions } from 'src/app/features/modals';
import { PackageUsersActions } from 'src/app/features/package-users';
import { SignalRActions } from 'src/app/features/signal-r/store';
import { RootStoreState } from 'src/app/store';

import { SigningRoomActions } from '../../store/actions';

@Component({
  selector: 'app-signing-completed',
  templateUrl: './signing-completed.component.html',
  styleUrls: ['./signing-completed.component.scss'],
})
export class SigningCompletedComponent {
  constructor(private readonly store: Store<RootStoreState.State>) {}

  completeSigning() {
    this.store.dispatch(PackageUsersActions.CompletePackageUserPreSignSession());
    this.store.dispatch(ModalsActions.ClearModalComponent());
    this.store.dispatch(SigningRoomActions.ExitSigningRoom());
    this.store.dispatch(SignalRActions.StopSignalRConnection());
  }
}
