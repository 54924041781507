import { Component } from '@angular/core';

@Component({
  selector: 'app-pinch-to-zoom',
  templateUrl: './pinch-to-zoom.component.html',
  styleUrls: ['./pinch-to-zoom.component.scss']
})
export class PinchToZoomComponent {

  constructor() { }
}
