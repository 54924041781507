<div
  class="signature-view"
  [class.signature-view--large]="isNotSelectedSignature$ | async"
  [attr.data-testid]="dataTestId"
>
  <ng-container *ngIf="signature; else placeholdertext">
    <img
      *ngIf="imageTypeProvided"
      [src]="signature | safeResourceUrl"
      class="signature-view__image"
      data-testid="signature-image"
      alt=""
      data-private
    />
    <img
      *ngIf="!imageTypeProvided"
      [src]="'data:image/png;base64,' + signature"
      class="signature-view__image"
      data-testid="signature-image"
      alt=""
      data-private
    />
  </ng-container>
  <ng-template #placeholdertext>
    <span class="signature-view__placeholder-text">{{ placeholderText }}</span>
  </ng-template>
</div>
