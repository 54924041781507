<span [style]="'width: ' + size + 'px; height: ' + size + 'px'">

  <svg *ngIf="icon === 'call-team-member'" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M17.2114 7.74857C17.1892 5.21277 15.3494 3.05901 12.8483 2.64077C10.3471 2.22254 7.90669 3.66059 7.0607 6.05121C6.2147 8.44183 7.20736 11.0948 9.41487 12.3429C4.23457 13.5041 0.55155 18.1014 0.548584 23.4103H1.57716C1.58597 18.074 5.65135 13.6196 10.9646 13.1246C11.3076 13.073 11.6533 13.0409 12 13.0286C14.894 13.0022 17.2229 10.6426 17.2114 7.74857ZM12 12.0069C10.2712 12.0375 8.69544 11.0196 8.0125 9.43109C7.32957 7.84255 7.67504 5.99875 8.88674 4.7652C10.0984 3.53165 11.9358 3.15329 13.5363 3.80773C15.1367 4.46217 16.1826 6.01945 16.1829 7.74857C16.1944 10.0757 14.327 11.9768 12 12.0069ZM23.4857 23.4103H22.4572C22.4578 19.3199 20.0346 15.6179 16.2857 13.9817C15.6213 14.441 14.8874 14.7906 14.112 15.0171C13.9796 15.4358 13.5851 15.7154 13.1462 15.7016C12.7074 15.6878 12.3312 15.3839 12.2254 14.9578C12.1196 14.5316 12.31 14.0871 12.6914 13.8696C13.0728 13.6522 13.5523 13.7148 13.8652 14.0229C16.9501 13.0663 18.8804 10.0072 18.4157 6.81088C17.951 3.61461 15.2297 1.2317 12 1.19314C8.40115 1.2158 5.50102 4.14969 5.52001 7.74857H4.49144C4.40879 5.01562 5.8196 2.45415 8.17359 1.06325C10.5276 -0.327643 13.4519 -0.327643 15.8059 1.06325C18.1599 2.45415 19.5707 5.01562 19.488 7.74857C19.4913 9.81854 18.6492 11.8001 17.1566 13.2343C21.0358 15.1401 23.4914 19.0882 23.4857 23.4103Z" />
  </svg>

  <svg *ngIf="icon === 'chevron-circle-down'" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M16 0.941177C7.68323 0.941177 0.941162 7.68324 0.941162 16C0.941162 24.3168 7.68323 31.0588 16 31.0588C24.3167 31.0588 31.0588 24.3168 31.0588 16C31.0588 7.68324 24.3167 0.941177 16 0.941177ZM16 29.6471C8.46292 29.6471 2.35293 23.5371 2.35293 16C2.35293 8.46294 8.46292 2.35294 16 2.35294C23.537 2.35294 29.647 8.46294 29.647 16C29.647 23.5371 23.537 29.6471 16 29.6471ZM23.6518 12.0094L24.6494 13.0071L16 21.6471L7.3694 12.9882L8.36704 11.9906L16 19.6329L23.6518 12.0094Z" />
  </svg>

  <svg *ngIf="icon === 'chevron-circle-up'" viewBox="0 0 36 36" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M18.1976 2.94118C9.88086 2.94118 3.13879 9.68324 3.13879 18C3.13879 26.3168 9.88086 33.0588 18.1976 33.0588C26.5144 33.0588 33.2564 26.3168 33.2564 18C33.2564 9.68324 26.5144 2.94118 18.1976 2.94118ZM18.1976 31.6471C12.6762 31.6547 7.6942 28.3345 5.57597 23.2356C3.45773 18.1366 4.62066 12.2637 8.52221 8.35675C12.4238 4.44981 18.2951 3.27877 23.3969 5.38997C28.4988 7.50117 31.8258 12.4786 31.8259 18C31.8486 21.6296 30.4155 25.1169 27.8472 27.6817C25.2788 30.2466 21.7896 31.6748 18.16 31.6471H18.1976ZM18.1882 12.3529L26.8094 20.9929L25.8117 22.0094L18.1882 14.3671L10.5364 21.9906L9.52938 20.9929L18.1882 12.3529Z" />
  </svg>

  <svg *ngIf="icon === 'chevron-down'" viewBox="0 0 32 18" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M15.9812 17.0918L0.442383 1.51529L1.44003 0.517639L15.9812 15.0965L30.56 0.555286L31.5577 1.55293L15.9812 17.0918Z" />
  </svg>

  <svg *ngIf="icon === 'chevron-up'" viewBox="0 0 32 18" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M30.7387 17.4824L16.1882 2.90353L1.61874 17.4447L0.621094 16.4471L16.1976 0.908234L31.7364 16.4847L30.7387 17.4824Z" />
  </svg>

  <svg *ngIf="icon === 'information'" viewBox="0 0 36 36" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M16.88 14.23H19.13V27.23H16.88V14.23ZM18.01 12.23C18.7178 12.23 19.3559 11.8036 19.6268 11.1497C19.8977 10.4958 19.7479 9.74306 19.2474 9.24256C18.7469 8.74207 17.9942 8.59234 17.3403 8.86321C16.6864 9.13408 16.26 9.77219 16.26 10.48C16.2465 10.951 16.4236 11.4075 16.7511 11.7463C17.0785 12.0851 17.5288 12.2775 18 12.28L18.01 12.23ZM2 18C2 9.16344 9.16344 2 18 2C26.8366 2 34 9.16344 34 18C34 26.8366 26.8366 34 18 34C9.16344 34 2 26.8366 2 18ZM3.5 18C3.5 26.0081 9.99187 32.5 18 32.5C26.0081 32.5 32.5 26.0081 32.5 18C32.5 9.99187 26.0081 3.5 18 3.5C14.1487 3.48127 10.4496 5.00293 7.72628 7.72628C5.00293 10.4496 3.48127 14.1487 3.5 18Z" />
  </svg>

  <svg *ngIf="icon === 'question'" viewBox="0 0 28 28" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M2 14C2 20.6274 7.37258 26 14 26C20.6274 26 26 20.6274 26 14C26 10.8174 24.7357 7.76515 22.4853 5.51472C20.2348 3.26428 17.1826 2 14 2C7.37258 2 2 7.37258 2 14ZM14 3.125C20.0061 3.125 24.875 7.9939 24.875 14C24.889 16.8885 23.7478 19.6628 21.7053 21.7053C19.6628 23.7478 16.8885 24.889 14 24.875C7.9939 24.875 3.125 20.0061 3.125 14C3.125 7.9939 7.9939 3.125 14 3.125ZM14 22.7975C13.4678 22.7975 12.9883 22.476 12.786 21.9837C12.5838 21.4915 12.6989 20.9258 13.0774 20.5516C13.4558 20.1774 14.0228 20.0689 14.5128 20.2768C15.0027 20.4846 15.3186 20.9678 15.3125 21.5C15.3043 22.219 14.7191 22.7975 14 22.7975ZM14.5925 17.915C14.5475 16.4525 14.7425 15.6875 16.4975 14.27C18.0875 13.0325 19.175 11.9225 19.175 9.77C19.175 7.25 16.9625 5.51 14.27 5.51C11.5775 5.51 9.6425 6.845 8.825 9.695L10.475 10.145C11.1125 8.1125 12.29 7.145 14.225 7.145C16.16 7.145 17.3975 8.18 17.3975 9.815C17.3975 11.45 16.5725 12.29 15.0725 13.5275C12.8911 15.3248 12.9786 16.4498 13.0817 17.7755C13.0853 17.8217 13.089 17.8682 13.0925 17.915H14.5925Z" />
  </svg>

  <svg *ngIf="icon === 'today'" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" role="img">
    <title>Date and Time Icon</title>
    <path d="M19 4H18V2H16V4H8V2H6V4H5C3.89 4 3 4.9 3 6V20C3 21.1 3.89 22 5 22H19C20.1 22 21 21.1 21 20V6C21 4.9 20.1 4 19 4ZM19 20H5V10H19V20ZM19 8H5V6H19V8ZM7 12H12V17H7V12Z" fill="#1C1B1A"/>
  </svg>

  <svg *ngIf="icon === 'for_sale'" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" role="img">
    <title>Address Icon</title>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M13 9.48629L10 12.8954V17H16V12.8954L13 9.48629ZM12 15V13.6501L13 12.5137L14 13.6501V15H12Z" fill="#1C1B1A"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M3 1H1V23H3L3 5H6.5V6.75H4.5V20L21.5 20V6.75H19.5V5H23V3L3 3V1ZM17.5 6.75H8.5V5H17.5V6.75ZM6.5 8.75V18H19.5V8.75L6.5 8.75Z" fill="#1C1B1A"/>
  </svg>

  <svg *ngIf="icon === 'expand-more'" width="12" height="8" viewBox="0 0 12 8" xmlns="http://www.w3.org/2000/svg">
    <path d="M10.59 0.589844L6 5.16984L1.41 0.589844L0 1.99984L6 7.99984L12 1.99984L10.59 0.589844Z" fill-rule="evenodd" clip-rule="evenodd" />
  </svg>

   <svg *ngIf="icon === 'expand-less'" width="12" height="8" viewBox="0 0 12 8"  xmlns="http://www.w3.org/2000/svg">
    <path d="M6 0L0 6L1.41 7.41L6 2.83L10.59 7.41L12 6L6 0Z" fill-rule="evenodd" clip-rule="evenodd" />
   </svg>

</span>
