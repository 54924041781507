import { Component, OnDestroy, OnInit } from '@angular/core';

import { MatSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';

import { combineLatest, interval, Subject, timer } from 'rxjs';
import { delay, filter, switchMap, takeUntil, takeWhile, tap } from 'rxjs/operators';

import { EndorsementsActions, EndorsementsSelectors } from 'src/app/features/endorsements';
import { Feature } from 'src/app/features/feature-management/models';
import { FeatureManagementService } from 'src/app/features/feature-management/services';
import { ModalsActions, ModalSize } from 'src/app/features/modals';
import { PackageUsersSelectors } from 'src/app/features/package-users';
import { LongPollingConfig } from 'src/app/features/shared/configs';

import { AddParticipantModalComponent } from '../../components';
import { CheckInActions, CheckInSelectors } from '../../store';
import { selectWaitingRoomViewModel } from '../../store/selectors/check-in.selectors';

import { WaitingRoomSnackBarComponent } from './waiting-room-snack-bar/waiting-room-snack-bar.component';

@UntilDestroy()
@Component({
  selector: 'app-waiting-room',
  templateUrl: './waiting-room.component.html',
  styleUrls: ['./waiting-room.component.scss'],
})
export class WaitingRoomComponent implements OnInit, OnDestroy {
  readonly vm$ = this.store.select(selectWaitingRoomViewModel);

  public snackBarRef: MatSnackBarRef<WaitingRoomSnackBarComponent>;
  protected isNeaSystemFieldsEnabled: boolean;

  private readonly onDestroyNotifier$ = new Subject();

  constructor(
    private readonly snackBar: MatSnackBar,
    private readonly store: Store,
    private readonly featureManagementService: FeatureManagementService
  ) {}

  ngOnInit(): void {
    this.isNeaSystemFieldsEnabled = this.featureManagementService.getIsFeatureEnabledWithCaching(
      Feature.NeaSystemFields
    );

    this.store.dispatch(CheckInActions.WaitingRoomViewed());

    if (!this.isNeaSystemFieldsEnabled) {
      timer(0, LongPollingConfig['10Seconds'].interval)
        .pipe(
          switchMap(() =>
            combineLatest([
              this.store.select(EndorsementsSelectors.areAllSystemEndorsementsApplied),
              this.store.select(EndorsementsSelectors.didSystemFieldsFailToApply),
              this.store.select(PackageUsersSelectors.getActivePackageUserGuid),
              this.store.select(PackageUsersSelectors.getSigningAgent),
            ])
          ),
          takeWhile(
            ([areAllSystemEndorsementsApplied, didSystemFieldsFailToApply, _, __]) =>
              !areAllSystemEndorsementsApplied && !didSystemFieldsFailToApply
          ),
          filter(
            ([_, __, activePackageUserGuid, signingAgent]) =>
              !!activePackageUserGuid && signingAgent?.checkInStatus?.isCompleted
          ),
          tap(() => this.store.dispatch(EndorsementsActions.FetchSystemFieldStatus()))
        )
        .subscribe();
    } else {
      timer(0, LongPollingConfig['10Seconds'].interval)
        .pipe(
          switchMap(() =>
            combineLatest([
              this.store.select(EndorsementsSelectors.areAllSystemEndorsementsApplied),
              this.store.select(PackageUsersSelectors.getActivePackageUserGuid),
              this.store.select(PackageUsersSelectors.getSigningAgent),
            ])
          ),
          takeWhile(([areAllSystemEndorsementsApplied]) => !areAllSystemEndorsementsApplied),
          filter(
            ([_, activePackageUserGuid, signingAgent]) =>
              !!activePackageUserGuid && signingAgent?.checkInStatus?.isCompleted
          ),
          tap(() => this.store.dispatch(EndorsementsActions.FetchSystemFieldsStatus()))
        )
        .subscribe();
    }

    interval(LongPollingConfig['10Seconds'].interval)
      .pipe(
        takeUntil(this.onDestroyNotifier$),
        tap(() => this.store.dispatch(CheckInActions.FetchWaitingRoomViewedByParticipants()))
      )
      .subscribe();

    this.store
      .select(CheckInSelectors.selectCanEnterRemoteSigningRoom)
      .pipe(
        takeUntil(this.onDestroyNotifier$),
        filter((canEnterRemoteSigningRoom) => canEnterRemoteSigningRoom),
        tap(() => {
          this.showSnackBar();
        }),
        delay(10000),
        tap(() => this.store.dispatch(CheckInActions.EnterSigningRoom()))
      )
      .subscribe();
  }

  openAddParticipantModal() {
    this.store.dispatch(
      ModalsActions.SetStandaloneModalComponent({
        payload: {
          component: AddParticipantModalComponent,
          modalSize: ModalSize.large,
          componentData: {
            isInWaitingRoom: true
          }
        },
      })
    );
  }

  showSnackBar(): void {
    this.snackBarRef = this.snackBar.openFromComponent(WaitingRoomSnackBarComponent, {
      duration: 10000,
      horizontalPosition: 'center',
      verticalPosition: 'top',
    });

    this.snackBarRef
      .afterDismissed()
      .pipe(takeUntil(this.onDestroyNotifier$))
      .subscribe(() => {
        this.store.dispatch(CheckInActions.EnterSigningRoom());
      });
  }

  ngOnDestroy(): void {
    this.onDestroyNotifier$.next(undefined);
    this.onDestroyNotifier$.complete();

    this.snackBar.dismiss();
  }
}
