import { Statement } from './statement.model';

export interface QuestionSet {
  questionSetId: number;
  questions: Question[];
}

export interface Question {
  questionId: number;
  key: number;
  type: string;
  text: Statement;
  helpText: Statement;
  choices: Choice[];
}

export interface Choice {
  choiceId: number;
  text: Statement;
}


export interface Answers {
  questionSetId: number;
  questions: AnsweredQuestion[];
}

export interface AnsweredQuestion {
  questionId: number;
  choices: SelectedChoice[];
}

export interface SelectedChoice {
  choice: number;
}
