import { DEFAULT_APPLICATION_PHONE_NUMBER } from 'src/app/features/shared/constants';

import { MultiFactorErrorType } from './multi-factor-error-type.enum';

export const MultiFactorErrorUserMessage: Record<MultiFactorErrorType, string> = {
  [MultiFactorErrorType.CodeExpired]: 'Your code has expired, please try again.',
  [MultiFactorErrorType.CreateChallenge404]: "We couldn't send your code, please try again.",
  [MultiFactorErrorType.InvalidPhone]: "We're having trouble with the number listed, use email instead.",
  [MultiFactorErrorType.InvalidSMS]: 'Your number can not receive texts, select a different method.',
  [MultiFactorErrorType.Unknown]: `Something went wrong, please contact support at ${DEFAULT_APPLICATION_PHONE_NUMBER}.`,
};

export const MultiFactorErrorLogMessage: Record<MultiFactorErrorType, string> = {
  [MultiFactorErrorType.CodeExpired]: 'MFA challenge failure: code has expired',
  [MultiFactorErrorType.CreateChallenge404]: 'MFA challenge: failure unable to send',
  [MultiFactorErrorType.InvalidPhone]: 'MFA challenge failure: invalid phone number',
  [MultiFactorErrorType.InvalidSMS]: "MFA challenge failure: phone number can't receive texts",
  [MultiFactorErrorType.Unknown]: 'MFA challenge failure',
};
