import { createAction } from '@ngrx/store';

export const WizardCompleted = createAction('[Pre-Sign] Wizard Completed');

export const PresignParticipantStarted = createAction('[Pre-Sign] Participant Started');

export const PresignParticipantCompleted = createAction('[Pre-Sign] Participant Completed');

export const PreparePresigningSessionToStart = createAction('[Pre-Sign] Prepare Pre-signing Session To Start');

export const ExitSigning = createAction(
  '[Pre-Sign] Exit Signing'
);

export const PreSignConfigureListeners = createAction(
  '[Pre-Sign] Configuring Listeners'
);
