import { Pipe, PipeTransform } from '@angular/core';

import { PackageUserRole } from '../models';
import { getPackageUserRole } from "../util/get-package-user-role";

@Pipe({ name: 'role' })
export class PackageUserRolePipe implements PipeTransform {
  transform(role: PackageUserRole): string {
    return getPackageUserRole(role);
  }
}
