import { Component } from '@angular/core';
import { Store } from '@ngrx/store';

import { ModalsActions } from 'src/app/features/modals';
import { RootStoreState } from 'src/app/store';

import { SigningRoomActions } from '../../store/actions';

@Component({
  selector: 'app-signing-completed-modal',
  templateUrl: './signing-completed-modal.component.html',
  styleUrls: ['./signing-completed-modal.component.scss'],
})
export class SigningCompletedModalComponent {
  static identifier = 'SigningCompletedModalComponent';
  constructor(private readonly store: Store<RootStoreState.State>) {}

  exitSigningClick() {
    this.store.dispatch(SigningRoomActions.ExitSigningRoom());
    this.store.dispatch(ModalsActions.ClearModalComponent());
  }

  reviewDocumentsClick() {
    this.store.dispatch(SigningRoomActions.ReviewDocuments());
    this.store.dispatch(ModalsActions.ClearModalComponent());
  }
}
