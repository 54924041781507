import { Component, Input } from '@angular/core';

import { Store } from '@ngrx/store';

import { IdVerifyComponent, SigningDetailsComponent } from 'src/app/features/layout/components';

import { AudioVideoModalSwitchComponent } from 'src/app/features/layout/components/audio-video-modal-switch/audio-video-modal-switch.component';
import { ModalsActions, ModalSize } from 'src/app/features/modals';
import { MsalAuthActions } from 'src/app/features/msal-auth';
import { RootStoreState } from 'src/app/store';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['../../nav-bar.component.scss'],
})
export class MenuComponent {
  @Input() isDeviceSettingsButtonEnabled: boolean;
  @Input() isOrderDetailsButtonEnabled: boolean;
  @Input() isIdVerifyButtonEnabled: boolean;
  @Input() isPrivacyPolicyButtonEnabled: boolean;
  @Input() isTermsOfUseButtonEnabled: boolean;
  @Input() isCancelSigningButtonEnabled: boolean;
  @Input() isPreSignLogoutButtonEnabled: boolean;

  faqUrl = environment.clearsignFaqUrl;

  constructor(private readonly store: Store<RootStoreState.State>) {}

  openCancelSigningModal() {
    this.store.dispatch(ModalsActions.OpenCancelSigningModal());
  }

  openChangeAudioVideoModal() {
    this.store.dispatch(
      ModalsActions.SetStandaloneModalComponent({
        payload: {
          component: AudioVideoModalSwitchComponent,
          modalSize: ModalSize.large,
        },
      })
    );
  }

  openDetailsModal() {
    this.store.dispatch(
      ModalsActions.SetStandaloneModalComponent({
        payload: {
          component: SigningDetailsComponent,
        },
      })
    );
  }

  openIdVerifyModal() {
    this.store.dispatch(
      ModalsActions.SetStandaloneModalComponent({
        payload: {
          component: IdVerifyComponent,
          modalSize: ModalSize.large,
        },
      })
    );
  }

  dispatchPreSignLogout() {
    this.store.dispatch(MsalAuthActions.Logout());
  }
}
