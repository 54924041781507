import { Component, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';

import { ModalsActions } from 'src/app/features/modals';
import { RootStoreState } from 'src/app/store';

@Component({
  selector: 'app-redirect-information',
  templateUrl: './redirect-information.component.html',
})
export class RedirectInformationComponent implements OnDestroy {
  constructor(private readonly store: Store<RootStoreState.State>) {
    this.store.dispatch(ModalsActions.ShowLoadingSpinner());
  }

  ngOnDestroy(): void {
    this.store.dispatch(ModalsActions.HideLoadingSpinner());
  }
}
