import { PackageUser } from 'src/app/features/package-users';

import { CheckInTask } from '../../models';

export interface State {
  activeWizardUser: PackageUser;
  currentTaskIndex: number;
  tasks: CheckInTask[];
}

export const initialState: State = {
  activeWizardUser: null,
  currentTaskIndex: 0,
  tasks: [],
};
