<div class="help-message">
  <div class="help-message__body" data-testid="modal-text">
    <p>
      <span class="help-message__body">Call us </span>
      <a href="tel:{{ nexsysSupportNumber | phone }}">{{ nexsysSupportNumber | phone }}</a>
    </p>
  </div>

  <div class="help-message__footer">
    <button class="btn btn--outline-success" (click)="close()" data-testid="modal-close">Close</button>
  </div>
</div>
