<div class="form-step__subheading" data-testid="check-in-subheader" data-private>
  {{ user?.firstName }} {{ user?.lastName }},
  {{ (selectedSignatures$ | async)?.staticSelection ? 'confirm' : 'select' }}
  your signature style below.
</div>
<app-signature-options
  [signatureOptions]="signatureOptions$ | async"
  [signatureSelection]="selectedSignatures$ | async"
  [ariaLabelSignatureType]="'signature'"
  (optionSelected)="updateActiveSignature($event)"
></app-signature-options>
<div *ngIf="formStep.errors" class="error-message">Please select a signature to continue</div>
<app-button-container>
  <app-button [dataTestId]="'next-button-select-signature'" type="primary" (click)="goToNextStep()">
    Accept Signature
  </app-button>
</app-button-container>
