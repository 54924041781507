<app-id-instructions
  *ngIf="!isMobile && hasTask('IdVerificationInstructions')"
  [user]="user"
></app-id-instructions>
<app-id-app-access
*ngIf="hasTask('IdVerificationQrCode')"
  [isMobile]="isMobile"
  [user]="user"
></app-id-app-access>
<app-id-verification-in-progress
  *ngIf="hasTask('IdVerification')"
  [isMobile]="isMobile"
  [user]="user"
></app-id-verification-in-progress>
