import { createAction, props } from '@ngrx/store';

import { PackageUser } from 'src/app/features/package-users';
import { NextStep } from '../../models';

export const ActiveSessionCheckInCompletion = createAction(
  '[Check-in] Active Session Check in Completion'
);

export const BeginCheckIn = createAction('[Check-in] Begin Check-in');

export const BeginCheckInForAllUsers = createAction('[Check-in] Begin Check-in for all users');

export const BeginRejoin = createAction('[Check-in] Begin Rejoin');

export const CheckForDeviceSwitch = createAction(
  '[Check-in] Check for Device Switch',
  props<{ payload: { packageUserGuid: string, isInWaitingRoom?: boolean } }>()
);

export const CompleteCheckInWizard = createAction('[Check-in] Complete Check-in Wizard');

export const ContinueCheckIn = createAction('[Check-in] Continue Check-in');

export const ContinueRejoin = createAction('[Check-in] Continue Rejoin');

export const ContinueCheckInOrRejoin = createAction('[Check-in] Continue Check-in or Rejoin');

export const EnterSigningRoom = createAction('[Check-in] Enter Signing Room');

export const PromptUserForAuthentication = createAction(
  '[Check-in] Prompt User For Authentication',
  props<{ payload: { participant: PackageUser } }>()
);

export const RedirectToAudioVideoCheck = createAction('[Check-in] Redirect to Audio Video Check');

export const RedirectToAvCheckIfRequired = createAction(
  '[Check-in] Redirect to AV check if required'
);

export const RedirectToCheckInWizard = createAction('[Check-in] Redirect to Check-in Wizard');

export const RedirectToProductRoute = createAction(
  '[Check-in] Redirect to ProductRoute',
  props<{ payload: { route: string } }>()
);

export const RedirectToSigningRoom = createAction('[Check-in] Redirect to Signing Room');

export const RedirectToWaitingRoom = createAction('[Check-in] Redirect to Waiting Room');

export const RedirectToWelcomePage = createAction('[Check-in] Redirect to Welcome Page');

export const ResetState = createAction('[Check-in] Reset State');

export const SetNextStep = createAction(
  '[Check-in] Set Next Step',
  props<{ payload: { nextStep: NextStep } }>()
);

export const SetUserCheckInSequence = createAction(
  '[Check-in] Set User Check-in Sequence',
  props<{ payload: string[] }>()
);

export const SetUserCheckInComplete = createAction(
  '[Check-in] Set User Check-in Complete',
  props<{ payload: { packageUserGuid: string } }>()
);

export const ToggleUserCheckInSequence = createAction(
  '[Check-in] Toggle User Check-in Sequence',
  props<{ payload: { packageUserGuid: string } }>()
);

export const WaitingRoomViewed = createAction('[Check-in] Waiting Room Viewed');

export const WaitingRoomViewedSuccess = createAction('[WR API] Viewed Success');

export const WaitingRoomViewedFailure = createAction('[WR API] Viewed Failure', props<{ error: Error }>());

export const FetchWaitingRoomViewedByParticipants = createAction(
  '[Check-in] Fetch Waiting Room Viewed By Participants'
);

export const SetWaitingRoomViewedByParticipants = createAction(
  '[Check-in] Set Waiting Room Viewed By Participants',
  props<{ payload: string[] }>()
);

export const SyncUserCheckInCompleteWithPackageUsers = createAction(
  '[Check-in] Sync User Check-in Complete With PackageUsers'
);

export const CheckAndUpdateIfSACompletedCheckIn = createAction(
  '[Check-in] Check And Update If SA Completed Check-in'
);

export const NavigateAwayFromWelcomePage = createAction(
  '[Check-in] Navigate Away From Welcome Page'
);

export const NonSigningAgentWelcomePageOpened = createAction(
  '[Non Signing Agent Welcome Page] Opened'
);

export const GoToWaitingRoom = createAction(
  '[Non Signing Agent Welcome Page] Go To Waiting Room'
);

export const GoToSigningRoom = createAction(
  '[Non Signing Agent Welcome Page] Go To Signing Room'
);
