import { Action, createReducer, on } from '@ngrx/store';

import { NextStep } from '../../models';
import { CheckInActions } from '../actions';
import { CheckInState } from '../state';
import { DeviceGroupActions } from 'src/app/features/device-group';

const reducer = createReducer(
  CheckInState.initialState,
  on(CheckInActions.BeginCheckIn, (state: CheckInState.State) => ({
    ...state,
    nextStep: NextStep.CheckIn,
  })),
  on(CheckInActions.BeginRejoin, (state: CheckInState.State) => ({
    ...state,
    nextStep: NextStep.RejoinWaitingRoom,
  })),
  on(CheckInActions.SetNextStep, (state: CheckInState.State, { payload }) => ({
    ...state,
    nextStep: payload.nextStep,
  })),
  on(CheckInActions.SetUserCheckInSequence, (state: CheckInState.State, { payload }) => ({
    ...state,
    userCheckInSequence: payload,
  })),
  on(CheckInActions.ToggleUserCheckInSequence, (state: CheckInState.State, { payload }) => {
    let updatedSequence: string[];
    const index = state.userCheckInSequence.indexOf(payload.packageUserGuid);
    if (index > -1) {
      updatedSequence = state.userCheckInSequence.filter(
        (guid) => guid !== payload.packageUserGuid
      );
    } else {
      updatedSequence = [...state.userCheckInSequence, payload.packageUserGuid];
    }
    return {
      ...state,
      userCheckInSequence: updatedSequence,
    };
  }),
  on(CheckInActions.SetUserCheckInComplete, (state: CheckInState.State, { payload }) => ({
    ...state,
    usersCompletedCheckIn: state.usersCompletedCheckIn.includes(payload.packageUserGuid)
      ? state.usersCompletedCheckIn
      : [...state.usersCompletedCheckIn, payload.packageUserGuid],
  })),
  on(CheckInActions.ResetState, (state: CheckInState.State) => CheckInState.initialState),
  on(
    CheckInActions.SetWaitingRoomViewedByParticipants,
    (state: CheckInState.State, { payload }) => ({
      ...state,
      usersViewedWaitingRoom: payload,
    })
  ),
  on(
    CheckInActions.WaitingRoomViewedSuccess,
    (state): CheckInState.State => ({
      ...state,
      usersViewedWaitingRoom: state.usersCompletedCheckIn,
    })
  ),
  on(
    DeviceGroupActions.RemovePackageUserFromDevice,
    (state, { payload }): CheckInState.State => ({
      ...state,
      userCheckInSequence: state.userCheckInSequence.filter(
        (packageUserId) => packageUserId !== payload.packageUserGuid
      ),
    })
  ),
  on(
    CheckInActions.GoToWaitingRoom,
    (state): CheckInState.State => ({
      ...state,
      nextStep: NextStep.WaitingRoom,
    })
  ),
  on(
    CheckInActions.GoToSigningRoom,
    (state): CheckInState.State => ({
      ...state,
      nextStep: NextStep.SigningRoom,
    })
  )
);

export function checkInReducer(state: CheckInState.State | undefined, action: Action) {
  return reducer(state, action);
}
