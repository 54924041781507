export const DEFAULT_APPLICATION_PHONE_NUMBER = '(844) 913-1386';
export const NEXSYS_IDP_CODE = 'Nexsys';
export const SIGNING_REDIRECT_STATE = 'signing-redirect';
export const CONSUMER_PORTAL_REDIRECT_STATE = 'consumerPortal-redirect';
export const SECURE_TOKEN_STORAGE_KEY = 'lastSessionSecureTokenHint';
export const MAX_PIN_ATTEMPTS = 5;
export const CURRENT_AUTHORITY_TYPE_KEY = 'currentAuthorityType';
export const INITIAL_ROUTE_PATH_SESSION_KEY = 'initial-route-path';
export const EMAIL_REGEX = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()\.,;\s@\"]+\.{0,1})+([^<>()\.,;:\s@\"]{2,}|[\d\.]+))$/;
export const EXTENDED_ASCII_REGEX = /^[\x00-\xFF]*$/;
export const QRCODE_REFRESH_TIME = 15 * 60 * 1000; // 15 minutes
