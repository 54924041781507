import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { TypedAction } from '@ngrx/store/src/models';

import { EMPTY, Observable, of } from 'rxjs';
import { concatMap, map, switchMap, withLatestFrom } from 'rxjs/operators';

import { DeviceGroupSelectors } from 'src/app/features/device-group';
import { PackageUsersSelectors } from 'src/app/features/package-users';
import { PackagesSelectors } from 'src/app/features/packages';
import { ApplicationInsightsService } from 'src/app/services/application-insights.service';
import { RootStoreState } from 'src/app/store';

import { ExceptionType, NotificationModel, NotificationType } from '../../models';
import { ToastService } from '../../services';
import { NotificationsActions } from '../actions';
import { NotificationsSelectors } from '../selectors';

@Injectable()
export class NotificationsEffects {
  handleAndLogExceptions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NotificationsActions.AddNotification),
      concatMap((action) =>
        of(action).pipe(
          withLatestFrom(
            this.store.pipe(select(PackageUsersSelectors.getActivePackageUserGuid)),
            this.store.pipe(select(PackagesSelectors.getActivePackageGuid)),
            this.store.pipe(select(NotificationsSelectors.selectHardStopNotifications)),
            this.store.pipe(select(DeviceGroupSelectors.getDeviceCode))
          )
        )
      ),
      switchMap(([action, userGuid, packageGuid, activeHardStopNotifications, deviceCode]) => {
        const shouldDisplayToast =
          action.payload.exceptionType === ExceptionType.DisplayMessage ||
          (this.isHardStopToast(action.payload) && activeHardStopNotifications.length === 0);

        const shouldLogAppInsightsEvent = this.isSuccessLog(action.payload);

        const shouldLogAppInsightsException = this.hasException(action.payload);


        if (shouldLogAppInsightsEvent) {
          this.applicationInsightsService.logEvent(action.payload.text, {
            packageUserGuid: userGuid,
            packageGuid: packageGuid,
            deviceCode: deviceCode,
          });
        }

        if (shouldLogAppInsightsException) {
          const err = action.payload.exception || new Error(action.payload.text);
          this.applicationInsightsService.logException(err, SeverityLevel.Error, {
            packageUserGuid: userGuid,
            packageGuid: packageGuid,
            deviceCode: deviceCode,
          });
        }

        return shouldDisplayToast ? this.showToast(action.payload) : EMPTY;
      })
    )
  );

  isHardStopToast(notification: NotificationModel): boolean {
    return (
      notification.notificationType === NotificationType.Error &&
      (notification.exceptionType === ExceptionType.ReloadRetry ||
        notification.exceptionType === ExceptionType.CannotProceed)
    );
  }

  isSuccessLog(notification: NotificationModel): boolean {
    return (
      notification.notificationType === NotificationType.Success &&
      notification.exceptionType === ExceptionType.None &&
      notification.logInAppInsights
    );
  }

  hasException(notification: NotificationModel): boolean {
    return (
      notification.notificationType === NotificationType.Error ||
      notification.logInAppInsights ||
      !!notification.exception
    );
  }

  showToast(payload: NotificationModel): Observable<TypedAction<any>> {
    const activeToast = this.toastService.showNotification(payload);
    payload.toastId = activeToast.toastId;

    return of(
      NotificationsActions.InsertNotification({
        payload: payload,
      })
    );
  }

  removeToast$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NotificationsActions.ClearNotification),
      concatMap((action) =>
        of(action).pipe(
          withLatestFrom(this.store.pipe(select(NotificationsSelectors.selectNotifications)))
        )
      ),
      map(([action, notifications]) => {
        const notification = notifications.find((x) => x.id === action.payload.notificationId);

        if (notification?.toastId) {
          this.toastService.removeNotification(notification.toastId);
        }
        return NotificationsActions.NotificationCleared({
          payload: action.payload,
        });
      })
    )
  );

  addSnackBar$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NotificationsActions.AddSnackBarNotification),
      switchMap((action) => {
        this.snackBar.openFromComponent(action.payload.component, {
          data: {
            title: action.payload.title,
            body: action.payload.body,
            snackBar: this.snackBar,
            data: action.payload.data
          },
          duration: action.payload.duration ?? 10000,
          horizontalPosition: 'center',
          verticalPosition: 'top',
          panelClass: action.payload.panelClass ?? '',
        });

        return [];
      })
    )
  );

  constructor(
    private readonly actions$: Actions,
    private readonly store: Store<RootStoreState.State>,
    private readonly toastService: ToastService,
    private readonly applicationInsightsService: ApplicationInsightsService,
    private readonly snackBar: MatSnackBar
  ) {}
}
