import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { PackageUser } from 'src/app/features/package-users';
import { AbstractWizardStepComponent, CheckInTaskCode, WizardStep } from 'src/app/features/wizard';
import { RootStoreState } from 'src/app/store';

import { EndorsementSteps, SignatureType } from '../../models';
import { EndorsementsCreationSelectors } from '../../store';

@Component({
  selector: 'app-signature-creation',
  templateUrl: './signature-creation.component.html',
  styleUrls: ['./signature-creation.component.scss'],
  providers: [
    {
      provide: AbstractWizardStepComponent,
      useExisting: SignatureCreationComponent,
    },
  ],
})
export class SignatureCreationComponent extends AbstractWizardStepComponent implements OnDestroy, OnInit {
  @Input() user: PackageUser;
  signatureType$: Observable<SignatureType>;
  stepTitle$: Observable<string>;

  readonly SignatureTypeEnum = SignatureType;

  constructor(private readonly store: Store<RootStoreState.State>) {
    super(store);

    this.stepMetadata = {
      stepName: 'Signature Creation',
      checkInTaskCode: CheckInTaskCode.SignatureCreation,
    } as WizardStep;

    this.formStep = new UntypedFormGroup({});
  }

  ngOnInit() {
    super.ngOnInit();
    this.signatureType$ = this.store.pipe(select(EndorsementsCreationSelectors.getSignatureType));

    this.stepTitle$ = this.signatureType$.pipe(
      map((signatureType) => {
        if (signatureType === SignatureType.Drawn) {
          return EndorsementSteps.DRAW_SIGNATURE;
        }
        if (signatureType === SignatureType.Uploaded) {
          return EndorsementSteps.UPLOADED_SIGNATURE;
        }
        return EndorsementSteps.SELECT_SIGNATURE;
      })
    );
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }
}
