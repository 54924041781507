<div
  *ngIf="!!participant"
  class="participant"
  [ngClass]="{
    'participant--clickable': isClickable && !participant?.checkInStatus?.isCompleted
  }"
>
  <div class="participant__details">
    <div
      class="participant__name"
      [ngClass]="{
        'participant__name--checkedin': !!participant?.checkInStatus?.isCompleted
      }"
      [title]="participant | formatPackageUserName: placeholderName"
      data-testid="lobby-participant-name"
      data-private
    >
      {{ participant | formatPackageUserName: placeholderName }}
    </div>
    <div>
      <span class="participant__status">
        <span
          *ngIf="
            !!participant?.checkInStatus?.isStarted && !participant?.checkInStatus?.isCompleted;
            else noTimerTemplate
          "
          class="participant__activity--active"
        >
          {{ participant.checkInStatus.currentStatus }} for
          {{ updatedElapsedMilliseconds | date: 'mm:ss' }}
        </span>
        <ng-template #noTimerTemplate>
          <span class="participant__activity">{{
            isEnteringSigningSession
              ? 'Entering Signing Session'
              : participant.checkInStatus.currentStatus
          }}</span>
        </ng-template>
      </span>
    </div>
  </div>
  <div class="participant__actions">
    <app-button
      *ngIf="isCheckin || isRejoin"
      type="primary"
      [dataTestId]="'check-in'"
      (click)="handleParticipantClick()"
    >
      {{ isCheckin ? 'Check-In' : 'Rejoin' }}
    </app-button>
  </div>
</div>
