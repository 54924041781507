import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';

import { DocumentsSelectors, PackageDocument } from 'src/app/features/documents';
import { selectPackageDocuments } from 'src/app/features/documents/store/selectors/documents.selectors';
import { PackageUsersSelectors } from 'src/app/features/package-users/';

import { DocumentEndorsementLocation, EndorsementStatusCode, EndorsementType } from '../../models';
import filterDuplicatesFromCollection from '../helpers/filter-duplicates-from-collection';
import { EndorsementsReducers } from '../reducers';
import {
  EndorsementImagesState,
  EndorsementLocationsState,
  EndorsementsState,
  SystemEndorsementImagesState,
  UniqueEndorsementImagesState,
} from '../state';

export const selectEndorsementsState = createFeatureSelector<EndorsementsState.State>(
  EndorsementsReducers.endorsementsFeatureKey
);

export const isReadyOnly = createSelector(selectEndorsementsState, (state) => state.isReadOnly);

export const selectEndorsementLocationsState = createSelector(
  selectEndorsementsState,
  (state) => state?.EndorsementLocationsState
);

export const selectEndorsementLocationsIds = createSelector(
  selectEndorsementLocationsState,
  EndorsementLocationsState.selectEndorsementLocationIds
);

export const selectEndorsementLocations = createSelector(
  selectEndorsementLocationsState,
  EndorsementLocationsState.selectEndorsementLocationEntities
);

export const selectActiveEndorsementLocationIds = createSelector(
  selectEndorsementLocations,
  DocumentsSelectors.selectActivePackageDocument,
  (endorsementEntities, activePackageDocument) => {
    if (endorsementEntities && activePackageDocument) {
      return activePackageDocument.endorsementLocationIds;
    }
    return [];
  }
);

export const selectEndorsementLocationById = createSelector(
  selectEndorsementLocations,
  isReadyOnly,
  (endorsementEntities, isReadOnly, props) => {
    if (!endorsementEntities || !props) {
      return null;
    }

    const result = endorsementEntities[props.endorsementLocationId];

    if (isReadOnly) {
      return {
        ...result,
        isSignableInCurrentSession: false,
      };
    }

    return result;
  }
);

export const selectEndorsementImagesState = createSelector(
  selectEndorsementsState,
  (state) => state.EndorsementImagesState
);

export const selectEndorsementImages = createSelector(
  selectEndorsementImagesState,
  EndorsementImagesState.selectPackageUserEndorsementImageEntities
);

export const selectEndorsementImage = createSelector(
  selectEndorsementImages,
  (
    endorsementImageEntities,
    props: { endorsementType: EndorsementType; packageUserGuid: string }
  ) => {
    const packageUserGuid = props?.packageUserGuid?.toLowerCase();
    if (
      !props.endorsementType ||
      !props.packageUserGuid ||
      !endorsementImageEntities[packageUserGuid]
    ) {
      return null;
    }

    return endorsementImageEntities[packageUserGuid].endorsementImages.find(
      (endorsementImage) => endorsementImage.endorsementTypeCode === props.endorsementType
    );
  }
);

export const selectUniqueEndorsementImagesState = createSelector(
  selectEndorsementsState,
  (state) => state.UniqueEndorsementImagesState
);

export const selectUniqueEndorsementImages = createSelector(
  selectUniqueEndorsementImagesState,
  UniqueEndorsementImagesState.selectUniqueEndorsementEntities
);

export const selectUniqueEndorsementImage = createSelector(
  selectUniqueEndorsementImages,
  (entities, props: { endorsementLocationId: number }) => {
    const entity = entities[props.endorsementLocationId];
    if (entity && entity.endorsementImage) {
      return entity;
    }
    return null;
  }
);

export const selectSystemEndorsementImagesState = createSelector(
  selectEndorsementsState,
  (state) => state.SystemEndorsementImagesState
);

export const selectSystemEndorsementImages = createSelector(
  selectSystemEndorsementImagesState,
  SystemEndorsementImagesState.selectSystemEndorsementEntities
);

export const selectSystemEndorsementImage = createSelector(
  selectSystemEndorsementImages,
  (entities, props: { endorsementImageId: number }) => {
    const entity = entities[props.endorsementImageId];

    if (entity && entity.endorsementImage) {
      return entity.endorsementImage;
    }

    return null;
  }
);

export const selectActiveDocumentEndorsementLocations = createSelector(
  selectActiveEndorsementLocationIds,
  selectEndorsementLocations,
  (activeDocumentEndorsementIds, endorsementLocations) => {
    const activeEndorsementLocations = [];
    activeDocumentEndorsementIds.forEach((id) => {
      if (endorsementLocations[id]) {
        activeEndorsementLocations.push(endorsementLocations[id]);
      }
    });

    return activeEndorsementLocations;
  }
);

export const selectActiveDocumentNonNotaryStampEndorsementLocations = createSelector(
  selectActiveEndorsementLocationIds,
  selectEndorsementLocations,
  (activeDocumentEndorsementIds, endorsementLocations) => {
    const activeEndorsementLocations = [];
    activeDocumentEndorsementIds.forEach((id) => {
      if (
        endorsementLocations[id] &&
        endorsementLocations[id].endorsementTypeCode !== EndorsementType.NOTARYSTAMP
      ) {
        activeEndorsementLocations.push(endorsementLocations[id]);
      }
    });

    return activeEndorsementLocations;
  }
);

export const selectActiveDocumentRequiredEndorsements = createSelector(
  selectActiveDocumentEndorsementLocations,
  (activeDocumentEndorsementLocations) => {
    return activeDocumentEndorsementLocations.filter(
      (endorsementLocation) =>
        endorsementLocation.isRequired && endorsementLocation.isSignableInCurrentSession
    );
  }
);

export const selectActiveDocumentRequiredActions = createSelector(
  selectActiveDocumentRequiredEndorsements,
  (activeDocumentEndorsementLocations) => {
    return activeDocumentEndorsementLocations.length;
  }
);

export const selectActiveDocumentNonSigningAgentEndorsementLocations = createSelector(
  selectActiveDocumentEndorsementLocations,
  PackageUsersSelectors.getNonSigningAgentGuids,
  (activeDocumentEndorsements, nonSigningAgents) => {
    return activeDocumentEndorsements.filter((docEndorsements) => {
      return nonSigningAgents.includes(String(docEndorsements.packageUserGuid).toUpperCase());
    });
  }
);

export const selectActiveDocumentSigningAgentEndorsementLocations = createSelector(
  selectActiveDocumentEndorsementLocations,
  PackageUsersSelectors.getSigningAgent,
  (activeDocumentEndorsements, signingAgent) => {
    return activeDocumentEndorsements.filter(
      (docEndorsements) =>
        String(signingAgent.packageUserGuid).toUpperCase() ===
        String(docEndorsements.packageUserGuid).toUpperCase()
    );
  }
);

export const selectActiveDocumentRequiredNonSigningAgentEndorsementLocations = createSelector(
  selectActiveDocumentRequiredEndorsements,
  PackageUsersSelectors.getNonSigningAgentGuids,
  (activeDocumentRequiredEndorsements, nonSigningAgents) => {
    return activeDocumentRequiredEndorsements.filter((docEndorsements) => {
      return nonSigningAgents.includes(String(docEndorsements.packageUserGuid).toUpperCase());
    });
  }
);

export const selectActiveDocumentRequiredSigningAgentEndorsementLocations = createSelector(
  selectActiveDocumentRequiredEndorsements,
  PackageUsersSelectors.getSigningAgent,
  (activeDocumentRequiredEndorsements, signingAgent) => {
    return activeDocumentRequiredEndorsements.filter(
      (docEndorsements) =>
        String(signingAgent.packageUserGuid).toUpperCase() ===
        String(docEndorsements.packageUserGuid).toUpperCase()
    );
  }
);

export const selectActiveDocumentNotSignableOnDeviceEndorsementLocations = createSelector(
  selectActiveDocumentRequiredEndorsements,
  (activeDocumentRequiredEndorsements) => {
    return activeDocumentRequiredEndorsements.filter(
      (docEndorsement) => !docEndorsement.isSignableOnDevice
    );
  }
);

export const selectDisabledEndorsementIds = createSelector(
  selectActiveDocumentNonNotaryStampEndorsementLocations,
  selectActiveDocumentRequiredNonSigningAgentEndorsementLocations,
  selectActiveDocumentRequiredSigningAgentEndorsementLocations,
  selectActiveDocumentNotSignableOnDeviceEndorsementLocations,
  (
    activeDocumentNonNotaryStampEndorsementLocations,
    activeNonSigningAgentEndorsements,
    activeSigningAgentEndorsements,
    activeNotSignableOnDeviceEndorsements
  ) => {
    const signingAgentStampIds = activeSigningAgentEndorsements.filter(
      (activeSigningAgentEndorsement) =>
        activeSigningAgentEndorsement.endorsementTypeCode === EndorsementType.NOTARYSTAMP
    );
    const hasSigningAgentAppliedStamp =
      signingAgentStampIds.length > 0 &&
      signingAgentStampIds.some(
        (activeSigningAgentEndorsement) =>
          // TODO: User Story 50434: Remove Old Endorsement Application Logic "activeSAEndorsement.attempted ||"
          activeSigningAgentEndorsement.attempted ||
          activeSigningAgentEndorsement.statusCode === EndorsementStatusCode.SIGNED
      );
    if (hasSigningAgentAppliedStamp) {
      return filterDuplicatesFromCollection<number>(
        [
          ...activeDocumentNonNotaryStampEndorsementLocations,
          ...activeNotSignableOnDeviceEndorsements,
        ].map((activeEndorsement) => activeEndorsement.documentEndorsementLocationId)
      );
    }

    const hasAllNonSigningAgentEndorsmentsBeenSignedOrAttempted =
      activeNonSigningAgentEndorsements.every(
        (activeNonSigningAgentEndorsement) =>
          // TODO: User Story 50434: Remove Old Endorsement Application Logic "activeSAEndorsement.attempted ||"
          activeNonSigningAgentEndorsement.attempted ||
          activeNonSigningAgentEndorsement.statusCode === EndorsementStatusCode.SIGNED
      );
    if (!hasAllNonSigningAgentEndorsmentsBeenSignedOrAttempted) {
      return filterDuplicatesFromCollection<number>(
        [...activeSigningAgentEndorsements, ...activeNotSignableOnDeviceEndorsements].map(
          (activeSigningAgentEndorsement) =>
            activeSigningAgentEndorsement.documentEndorsementLocationId
        )
      );
    }
    const hasSigningAgentSignedOrAttemptedAllNonStampEndorsements = activeSigningAgentEndorsements
      .filter(
        (activeSigningAgentEndorsement) =>
          activeSigningAgentEndorsement.endorsementTypeCode !== EndorsementType.NOTARYSTAMP
      )
      .every(
        (activeSigningAgentEndorsement) =>
          // TODO: User Story 50434: Remove Old Endorsement Application Logic "activeSAEndorsement.attempted ||"
          activeSigningAgentEndorsement.attempted ||
          activeSigningAgentEndorsement.statusCode === EndorsementStatusCode.SIGNED
      );
    if (hasSigningAgentSignedOrAttemptedAllNonStampEndorsements) {
      return activeNotSignableOnDeviceEndorsements.map(
        (docEndorsment) => docEndorsment.documentEndorsementLocationId
      );
    }
    const notaryStampActiveEndorsments = activeSigningAgentEndorsements.filter(
      (activeSigningAgentEndorsement) =>
        activeSigningAgentEndorsement.endorsementTypeCode === EndorsementType.NOTARYSTAMP
    );
    return filterDuplicatesFromCollection<number>(
      [...notaryStampActiveEndorsments, ...activeNotSignableOnDeviceEndorsements].map(
        (activeSigningAgentEndorsement) =>
          activeSigningAgentEndorsement.documentEndorsementLocationId
      )
    );
  }
);

export const selectRequiredEndorsementCountsByPackageDocument = createSelector(
  selectEndorsementLocations,
  (
    entities: Dictionary<DocumentEndorsementLocation>,
    props: { packageDocument: PackageDocument }
  ) => {
    let completedActions = 0;
    let totalActions = 0;

    props.packageDocument.endorsementLocationIds.forEach((locationId) => {
      const endorsementEntity = entities[locationId];
      if (endorsementEntity.isSignableInCurrentSession) {
        if (endorsementEntity.isRequired) {
          totalActions++;
          // TODO: User Story 50434: Remove Old Endorsement Application Logic "endorsementEntity.attempted ||"
          if (endorsementEntity.attempted || endorsementEntity.statusCode === 'SIGNED') {
            completedActions++;
          }
        }
      }
    });

    return { completedActions, totalActions };
  }
);

export const getTotalCompletedDocuments = createSelector(
  selectEndorsementLocations,
  selectPackageDocuments,
  (entities: Dictionary<DocumentEndorsementLocation>, packageDocuments: PackageDocument[]) => {
    let completedDocuments = 0;

    packageDocuments.forEach((packageDocument) => {
      let totalActions = 0;
      let completedActions = 0;
      packageDocument.endorsementLocationIds.forEach((locationId) => {
        const endorsementEntity = entities[locationId];

        if (endorsementEntity?.isSignableInCurrentSession) {
          if (endorsementEntity?.isRequired) {
            totalActions++;
            if (endorsementEntity.attempted || endorsementEntity.statusCode === 'SIGNED') {
              completedActions++;
            }
          }
        }
      });

      if (completedActions === totalActions && totalActions !== 0) completedDocuments++;
    });

    return completedDocuments;
  }
);

export const selectActiveDocumentRemainingSignatures = createSelector(
  selectActiveDocumentEndorsementLocations,
  (activeDocumentEndorsementLocations) => {
    return activeDocumentEndorsementLocations.filter(
      (endorsementLocation: DocumentEndorsementLocation) =>
        endorsementLocation.isSignableInCurrentSession &&
        (endorsementLocation.endorsementTypeCode === EndorsementType.SIGNATURE ||
          endorsementLocation.endorsementTypeCode === EndorsementType.INITIALS) &&
        // TODO: User Story 50434: Remove Old Endorsement Application Logic "endorsementLocation.attempted &&"
        !endorsementLocation.attempted &&
        endorsementLocation.statusCode !== 'SIGNED' &&
        endorsementLocation.isRequired
    ).length;
  }
);

export const selectActiveDocumentRemainingCheckBoxes = createSelector(
  selectActiveDocumentEndorsementLocations,
  (activeDocumentEndorsementLocations) => {
    return activeDocumentEndorsementLocations.filter(
      (endorsementLocation: DocumentEndorsementLocation) =>
        endorsementLocation.isSignableInCurrentSession &&
        endorsementLocation.endorsementTypeCode === EndorsementType.CHECKBOX &&
        // TODO: User Story 50434: Remove Old Endorsement Application Logic "endorsementLocation.attempted &&"
        !endorsementLocation.attempted &&
        endorsementLocation.statusCode !== 'SIGNED' &&
        endorsementLocation.isRequired
    ).length;
  }
);

export const selectActiveDocumentRemainingTextBoxes = createSelector(
  selectActiveDocumentEndorsementLocations,
  (activeDocumentEndorsementLocations) => {
    return activeDocumentEndorsementLocations.filter(
      (endorsementLocation: DocumentEndorsementLocation) =>
        endorsementLocation.isSignableInCurrentSession &&
        endorsementLocation.endorsementTypeCode === EndorsementType.TEXTBOX &&
        // TODO: User Story 50434: Remove Old Endorsement Application Logic "endorsementLocation.attempted &&"
        !endorsementLocation.attempted &&
        endorsementLocation.statusCode !== 'SIGNED' &&
        endorsementLocation.isRequired
    ).length;
  }
);

export const selectActiveDocumentRemainingStamp = createSelector(
  selectActiveDocumentEndorsementLocations,
  (activeDocumentEndorsementLocations) => {
    return activeDocumentEndorsementLocations.filter(
      (endorsementLocation: DocumentEndorsementLocation) =>
        endorsementLocation.isSignableInCurrentSession &&
        endorsementLocation.endorsementTypeCode === EndorsementType.NOTARYSTAMP &&
        // TODO: User Story 50434: Remove Old Endorsement Application Logic "endorsementLocation.attempted &&"
        !endorsementLocation.attempted &&
        endorsementLocation.statusCode !== 'SIGNED' &&
        endorsementLocation.isRequired
    ).length;
  }
);

export const selectActiveDocumentRemainingRequiredActions = createSelector(
  selectActiveDocumentRemainingSignatures,
  selectActiveDocumentRemainingCheckBoxes,
  selectActiveDocumentRemainingTextBoxes,
  selectActiveDocumentRemainingStamp,
  (remainingSignatures, remainingCheckboxes, remainingTextBoxes, remainingStamp) => {
    return remainingSignatures + remainingCheckboxes + remainingTextBoxes + remainingStamp;
  }
);

export const selectActiveUserRequiredEndorsements = createSelector(
  selectEndorsementLocationsIds,
  selectEndorsementLocations,
  (ids, entities) => {
    const endorsements = [];
    ids.forEach((id) => {
      if (entities[id]?.isRequired && entities[id].isSignableInCurrentSession) {
        endorsements.push(entities[id]);
      }
    });
    return endorsements;
  }
);

export const selectEndorsementLocationsByPackageUserGuid = createSelector(
  selectEndorsementLocationsIds,
  selectEndorsementLocations,
  (endorsementLocationIds, endorsementLocationEntities, props: { packageUserGuid: string }) => {
    const userEndorsementLocations: DocumentEndorsementLocation[] = [];

    endorsementLocationIds.forEach((id) => {
      if (
        endorsementLocationEntities[id].packageUserGuid?.toLowerCase() ===
        props.packageUserGuid.toLowerCase()
      ) {
        userEndorsementLocations.push(endorsementLocationEntities[id]);
      }
    });

    return userEndorsementLocations;
  }
);

export const selectAllUserEndorsementLocations = createSelector(
  selectEndorsementLocationsIds,
  selectEndorsementLocations,
  (endorsementLocationIds, endorsementLocationEntities) => {
    const packageEndorsementLocations: DocumentEndorsementLocation[] = [];

    endorsementLocationIds.forEach((id) => {
      const endorsementLocation = endorsementLocationEntities[id];

      if (endorsementLocation.packageUserGuid !== null) {
        packageEndorsementLocations.push(endorsementLocation);
      }
    });

    return packageEndorsementLocations;
  }
);

export const selectActiveUserRequiredEndorsementsCount = createSelector(
  selectActiveUserRequiredEndorsements,
  (endorsements) => endorsements.length
);

export const selectCompletedActiveUserRequiredEndorsements = createSelector(
  selectActiveUserRequiredEndorsements,
  (endorsements: Array<DocumentEndorsementLocation>) => {
    let count = 0;

    // TODO: User Story 50434: Remove Old Endorsement Application Logic
    endorsements.forEach((endorsement) => {
      if (endorsement.endorsementTypeCode === EndorsementType.TEXTBOX) {
        if (endorsement.attempted || endorsement.statusCode === 'SIGNED') {
          count++;
        }
      } else if (endorsement.statusCode === 'SIGNED') {
        count++;
      }
    });

    // TODO: Uncomment when the above switch is deleted
    //endorsements.forEach((endorsement) => {
    //  if (endorsement.statusCode === 'SIGNED') {
    //    count++;
    //  }
    //});

    return count;
  }
);

export const areAllEndorsementsComplete = createSelector(
  selectActiveUserRequiredEndorsementsCount,
  selectCompletedActiveUserRequiredEndorsements,
  (totalCount, completedCount) => {
    if (totalCount > 0) {
      return completedCount >= totalCount;
    }
    return false;
  }
);

export const selectLastAttemptedEndorsement = createSelector(
  selectEndorsementsState,
  selectEndorsementLocationsState,
  (state, endorsementLocation) => {
    return endorsementLocation.entities[state.lastAttemptedEndorsementId];
  }
);

export const selectLastAttemptedEndorsementPackageUserGuid = createSelector(
  selectLastAttemptedEndorsement,
  (state) => {
    return state?.packageUserGuid ?? null;
  }
);

export const isAttemptingEndorsement = createSelector(
  selectEndorsementsState,
  (state: EndorsementsState.State) => state.isAttemptingEndorsement
);

export const isSignableOnDevice = (endorsementLocationId: number) =>
  createSelector(
    selectEndorsementLocations,
    (endorsementEntities) => endorsementEntities[endorsementLocationId]?.isSignableOnDevice ?? false
  );

export const areAllSystemEndorsementsApplied = createSelector(
  selectEndorsementsState,
  (state: EndorsementsState.State) => state.areAllSystemFieldsApplied
);

export const areAllEndorsementsSigned = createSelector(
  selectEndorsementsState,
  (state: EndorsementsState.State) => state.areAllEndorsementsSigned
);

export const didSystemFieldsFailToApply = createSelector(
  selectEndorsementsState,
  (state: EndorsementsState.State) => state?.didSystemFieldsFailToApply
);

export const systemFieldApplicationFailures = createSelector(
  selectEndorsementsState,
  (state: EndorsementsState.State) => state?.systemFieldApplicationFailures
);

export const secondsSinceLastSystemFieldApplicationAttempt = createSelector(
  selectEndorsementsState,
  (state: EndorsementsState.State) => {
    const lastAttempt = state?.lastSystemFieldApplicationAttempt;
    return !!lastAttempt ? Math.floor((Date.now() - lastAttempt) / 1000) : undefined;
  }
);

export const isLoaded = createSelector(selectEndorsementsState, (state) => state?.isLoaded);

export const isLoading = createSelector(selectEndorsementsState, (state) => state?.isLoading);
