<div class="generic-confirm-modal">
  <div class="generic-confirm-modal__header">
    <div class="modal-title">Cancel signing</div>
  </div>
  <div class="generic-confirm-modal__body">
    <div>
      <p>Please contact support for more instructions.</p>
      <p>
        Call us <a href="tel:{{ phoneNumber }}">{{ phoneNumber }}</a>
      </p>
    </div>
  </div>
  <div class="generic-confirm-modal__footer">
    <app-button-container>
      <app-button
        type="primary"
        [dataTestId]="'cancel-signing-unauth-close-button'"
        (click)="closeClick()"
      >
        Close
      </app-button>
    </app-button-container>
  </div>
</div>
