import { computed, inject, Injectable, signal, Signal, WritableSignal } from '@angular/core';

import { Store } from '@ngrx/store';

import { AudioVideoConnectionMonitorSelectors } from 'src/app/features/audio-video-connection-monitor/store';
import { AudioVideoCheckActions } from 'src/app/features/av-check/store';
import { AudioVideoCheckSelectors } from 'src/app/features/av-check/store/selectors';

export interface ExtendedAudioContext extends AudioContext {
  setSinkId(sinkId: string | { type: string }): Promise<void>;
  sinkId: string | { type: string };
}

@Injectable()
export class SpeakerCheckService {
  private readonly store = inject(Store);

  audioDetectionAnimationFrame = null;

  #sampleMax = 0;
  get sampleMax() {
    return this.#sampleMax ? this.#sampleMax : 1;
  }

  set sampleMax(value) {
    this.#sampleMax = value;
  }

  audioLevel = 0;
  currentMax = 0;

  audioURL = '/assets/audio/speaker-test.mp3';
  audioBuffer: AudioBuffer;
  matProgressBarClass = 'mat-mdc-progress-bar-low-level';
  speakerAudioContext: ExtendedAudioContext;
  speakerSource: AudioBufferSourceNode;

  forceDisableTestSpeakerButton: WritableSignal<boolean> = signal(false);
  hasAudioTestPassed: WritableSignal<boolean> = signal(null);
  hideUserMediaFailure: WritableSignal<boolean> = signal(false);
  isSourceConnected: WritableSignal<boolean> = signal(null);

  hasSpeakerFeature: Signal<boolean> = computed(() => 'setSinkId' in AudioContext.prototype);

  selectAudioOutputDevice: Signal<MediaDeviceInfo> = this.store.selectSignal(
    AudioVideoConnectionMonitorSelectors.getSelectedAudioOutputDevice
  );

  selectAudioOutputDevices: Signal<MediaDeviceInfo[]> = this.store.selectSignal(
    AudioVideoCheckSelectors.selectAudioOutputDevices
  );

  clearSpeaker() {
    this.store.dispatch(AudioVideoCheckActions.ClearSpeaker());
  }

  detectAudio(analyser: AnalyserNode): void {
    const dataArray = new Uint8Array(analyser.frequencyBinCount);
    analyser.getByteFrequencyData(dataArray);

    this.currentMax = dataArray.reduce((accumulator, currentValue) =>
      Math.max(accumulator, currentValue)
    );

    this.sampleMax = this.sampleMax > this.currentMax ? this.sampleMax : this.currentMax;
    this.audioLevel = (this.currentMax / this.sampleMax) * 100;

    if (this.audioLevel > 30) {
      if (!this.hasAudioTestPassed()) {
        this.hasAudioTestPassed.set(true);
      }

      this.matProgressBarClass = 'mat-mdc-progress-bar-high-level';
    } else {
      this.matProgressBarClass = 'mat-mdc-progress-bar-low-level';
    }

    if (this.audioDetectionAnimationFrame && this.audioLevel === 0 && this.hasAudioTestPassed()) {
      globalThis.cancelAnimationFrame(this.audioDetectionAnimationFrame);

      return;
    }

    this.audioDetectionAnimationFrame = globalThis.requestAnimationFrame(() =>
      this.detectAudio(analyser)
    );
  }

  handleSpeakerSourceEnd() {
    this.sampleMax = 0;
    this.isSourceConnected.set(false);
    this.hideUserMediaFailure.set(false);
    this.forceDisableTestSpeakerButton.set(false);

    this.speakerSource?.disconnect(this.speakerAudioContext.destination);

    globalThis.cancelAnimationFrame(this.audioDetectionAnimationFrame);
  }

  handleSpeakerSelectionChange(event: { value: MediaDeviceInfo }): void {
    this.hasAudioTestPassed.set(null);
    this.forceDisableTestSpeakerButton.set(false);
    this.audioLevel = 0;

    globalThis.cancelAnimationFrame(this.audioDetectionAnimationFrame);

    if (this.speakerSource) {
      this.speakerSource.stop(0);
    }

    this.selectSpeaker(event.value);
  }

  selectSpeaker(speaker: MediaDeviceInfo) {
    this.store.dispatch(AudioVideoCheckActions.SelectSpeaker({ speaker }));
  }

  async loadAudio(url: string): Promise<AudioBuffer> {
    const response = await fetch(url);
    const arrayBuffer = await response.arrayBuffer();

    return this.speakerAudioContext.decodeAudioData(arrayBuffer);
  }

  async testSpeaker(): Promise<void> {
    if (!this.speakerAudioContext) {
      this.speakerAudioContext = new AudioContext() as ExtendedAudioContext;
    }

    this.forceDisableTestSpeakerButton.set(true);
    this.hasAudioTestPassed.set(false);
    this.isSourceConnected.set(null);

    let audioOutputDevice = this.selectAudioOutputDevice();

    if (!audioOutputDevice) {
      await this.speakerAudioContext.setSinkId({ type: 'none' });

      return;
    }

    try {
      if (audioOutputDevice.deviceId.toLowerCase() === 'default') {
        const audioOutputDevices = this.selectAudioOutputDevices();

        [audioOutputDevice] = audioOutputDevices.filter(
          (device) =>
            device.deviceId.toLowerCase() !== 'default' &&
            device.groupId === audioOutputDevice.groupId
        );
      }

      await this.speakerAudioContext.setSinkId(audioOutputDevice.deviceId);
    } catch (e: unknown) {}

    const analyser = this.speakerAudioContext.createAnalyser();

    this.speakerSource = this.speakerAudioContext.createBufferSource();

    if (!this.audioBuffer) {
      this.audioBuffer = await this.loadAudio(this.audioURL);
    }

    this.speakerSource.buffer = this.audioBuffer;

    this.speakerSource.connect(this.speakerAudioContext.destination);
    this.speakerSource.connect(analyser);

    this.isSourceConnected.set(true);
    this.hideUserMediaFailure.set(true);

    this.speakerSource.onended = () => this.handleSpeakerSourceEnd();
    this.speakerSource.start(0);

    this.detectAudio(analyser);
  }
}
