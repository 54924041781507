import { DefaultModalSize, ModalSize } from '../../enums/modal-sizes.enum';

export interface State {
  component: any;
  componentData?: any;
  modalTitle?: string;
  modalSize?: ModalSize;
  allowManualClose?: boolean;
  contentIsStandalone: boolean;
  shouldFade: boolean;
  showLoadingSpinner?: boolean;
  globalSpinner?: boolean;
  useBackgroundOverlay?: boolean;
}

export const initialState: State = {
  component: null,
  componentData: null,
  modalTitle: null,
  modalSize: DefaultModalSize,
  allowManualClose: true,
  contentIsStandalone: false,
  shouldFade: false,
  showLoadingSpinner: false,
  globalSpinner: false,
  useBackgroundOverlay: true
};
