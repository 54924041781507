import { createFeatureSelector, createSelector } from '@ngrx/store';

import { CommissionSelectionState } from '../state';
import { Commission, DynamicCommissionStamp, StateDynamicCommissionStamps } from '../../models';

const getCommissionSelectionState =
  createFeatureSelector<CommissionSelectionState.State>('commissions');

export const getCommissions = createSelector(
  getCommissionSelectionState,
  (state: CommissionSelectionState.State) => {
    return state.commissions;
  }
);

export const getCounties = createSelector(
  getCommissionSelectionState,
  (state: CommissionSelectionState.State) => {
    return state.counties;
  }
);

const _getSelectedCommission = createSelector(
  getCommissionSelectionState,
  (state: CommissionSelectionState.State) => state.selectedCommission
);

export const getSelectedCounty = createSelector(
  getCommissionSelectionState,
  (state: CommissionSelectionState.State) => state.selectedCounty
);

export const getDynamicCommissions = createSelector(
  getCommissionSelectionState,
  (state) => state.dynamicCommissions
);

export const getSelectedCommissionIsConfirmed = createSelector(
  getCommissionSelectionState,
  (state: CommissionSelectionState.State) => state.isSelectedCommissionConfirmed
);

const dynamicStampRequired = createSelector(
  _getSelectedCommission,
  getDynamicCommissions,
  (
    selectedCommission: Commission,
    dynamicCommissions: Record<string, StateDynamicCommissionStamps>
  ) => {
    const commission = dynamicCommissions[selectedCommission?.stateCode?.toUpperCase()];
    return commission?.dynamicStampsRequired;
  }
);

export const getDynamicStamp = createSelector(
  getDynamicCommissions,
  _getSelectedCommission,
  getSelectedCounty,
  (
    dynamicCommissions: Record<string, StateDynamicCommissionStamps>,
    selectedCommission: Commission,
    selectedCounty: string
  ): DynamicCommissionStamp | null => {
    if (
      !selectedCommission ||
      !selectedCounty ||
      !dynamicCommissions[selectedCommission.stateCode]?.countyStamps[selectedCounty]
    ) {
      return null;
    }

    return dynamicCommissions[selectedCommission.stateCode].countyStamps[selectedCounty];
  }
);

// getSelectedCommissionDetails is meant for use of properties that do not change, like the name, expiration, etc
export const getSelectedCommissionDetails = createSelector(
  _getSelectedCommission,
  (selectedCommission) => {
    return selectedCommission;
  }
);

// getSelectedCommission is meant for getting the complete commission object, including accurate stamp
export const getSelectedCommission = createSelector(
  _getSelectedCommission,
  getDynamicStamp,
  dynamicStampRequired,
  (
    selectedCommission: Commission,
    dynamicStamp: DynamicCommissionStamp,
    dynamicRequired: boolean
  ) => {
    if (!selectedCommission) {
      return null;
    }

    if ((dynamicRequired || dynamicRequired === undefined) && !dynamicStamp) {
      return null;
    }

    return {
      ...selectedCommission,
      stamp: dynamicRequired ? dynamicStamp.image : selectedCommission.stamp,
    } as Commission;
  }
);

export const getConfirmedCommisisonDetails = createSelector(
  getSelectedCommissionDetails,
  getSelectedCommissionIsConfirmed,
  (selectedCommission: Commission, isConfirmed: boolean) => {
    if (isConfirmed) {
      return selectedCommission;
    }

    return null;
  }
);

export const getCertificateCredentialsSaved = createSelector(
  getCommissionSelectionState,
  (state: CommissionSelectionState.State) => state.areCertificateCredentialsSaved
);

export const isCertificatePasswordLimitExceeded = createSelector(
  getCommissionSelectionState,
  (state: CommissionSelectionState.State) => state.certificatePasswordLimitExceeded
);

export const isDigitalCertificateExpired = createSelector(
  getCommissionSelectionState,
  (state: CommissionSelectionState.State) => state.isDigitalCertificateExpired
);

export const ShowSACertificatePasswordLimitExceeded = createSelector(
  getCommissionSelectionState,
  (state: CommissionSelectionState.State) => state.showSACertificatePasswordLimitExceeded
);

export const getCertificatePasswordVerification = createSelector(
  getCommissionSelectionState,
  (state: CommissionSelectionState.State) => state.certificatePasswordVerification
);

export const getCertificatePasswordAttemptsRemaining = createSelector(
  getCommissionSelectionState,
  (state: CommissionSelectionState.State) =>
    state.certificatePasswordVerification?.attemptsRemaining
);

export const getCertificatePasswordError = createSelector(
  getCommissionSelectionState,
  (state: CommissionSelectionState.State) => state.certificatePasswordError
);
