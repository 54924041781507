import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { DuplicateSessionDetectorService } from './services';
import { DuplicateSessionDetectorReducers } from './store/reducers';

@NgModule({
  declarations: [
  ],
  imports: [
    CommonModule,
    StoreModule.forFeature(
      'duplicateSessionDetector',
      DuplicateSessionDetectorReducers.Reducers.duplicateSessionDetectorReducer
    )
  ],
  providers: [DuplicateSessionDetectorService]
})
export class DuplicateSessionDetectorModule { }
