import { createFeatureSelector, createSelector } from '@ngrx/store';

import { PinValidationState } from '../state';

export const selectPinValidationState =
  createFeatureSelector<PinValidationState.State>('pinValidation');

export const selectPinVerification = createSelector(
  selectPinValidationState,
  (state: PinValidationState.State) => state.pinVerification
);

export const selectShowSpinner = createSelector(
  selectPinValidationState,
  (state: PinValidationState.State) => state.showSpinner
);

export const selectMessage = createSelector(
  selectPinValidationState,
  (state: PinValidationState.State) => state.message
);

export const selectShowContactSupport = createSelector(
  selectPinValidationState,
  (state: PinValidationState.State) => state.showContactSupport
);

export const selectPinVerificationViewModel = createSelector(
  selectShowSpinner,
  selectMessage,
  selectShowContactSupport,
  (showSpinner, message, showContactSupport) => ({
    showSpinner,
    message,
    showContactSupport,
  })
);
