import { PackageUser, PackageUserRole } from '../models';
import { getPackageUserRole } from './get-package-user-role';

export function getPackageUserFullName(packageUser: PackageUser, placeholderName: string) {
  if (!!packageUser) {
    if (!!packageUser.signatureName) {
      return packageUser.signatureName;
    } else if (!!packageUser.firstName && !!packageUser.lastName) {
      const prefix = packageUser.prefix ?? '';
      const suffix = packageUser.suffix ?? '';

      return `${prefix} ${packageUser.firstName} ${packageUser.lastName} ${suffix}`.trim();
    } else {
      return placeholderName;
    }
  }

  return null;
}

export function getPackageUserFirstName(packageUser: PackageUser, placeholderName: string) {
  if (!!packageUser) {
    if (!!packageUser.firstName) {
      return packageUser.firstName;
    } else {
      return placeholderName;
    }
  }

  return null;
}

export function mapPackageUser(packageUsers: PackageUser[], mappingFunction: Function) {
  const userRoles = Object.values(PackageUserRole);
  const unNamedUsersCount: Record<string, number> = {};

  userRoles.forEach((role) => {
    unNamedUsersCount[role] = 0;
  });

  return packageUsers.map((user) => {
    if (mappingFunction(user, undefined) === undefined) {
      unNamedUsersCount[user.userRoleCode] += 1;
    }

    const displayName = mappingFunction(
      user,
      `${getPackageUserRole(user.userRoleCode)} ${unNamedUsersCount[user.userRoleCode]}`
    );

    return {
      ...user,
      displayName,
    };
  });
}

export function getFullNames(packageUsers: PackageUser[]): PackageUserWithDisplayName[] {
  return mapPackageUser(packageUsers, getPackageUserFullName);
}

export function getFirstNames(packageUsers: PackageUser[]): PackageUserWithDisplayName[] {
  return mapPackageUser(packageUsers, getPackageUserFirstName);
}

export interface PackageUserWithDisplayName extends PackageUser {
  displayName: string;
}
