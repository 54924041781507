import { Observable, of, throwError } from 'rxjs';
import { delay, mergeMap, retryWhen } from 'rxjs/operators';

export const getErrorMessage = (maxRetry: number) =>
  new Error(`Failed to load resource via XHR after ${maxRetry} attempts.`);

export const DEFAULT_MAX_RETRIES = 5;

export function delayedRetry(delayMs: number, maxRetry = DEFAULT_MAX_RETRIES) {
  let retries = maxRetry;

  return (src: Observable<any>) =>
    src.pipe(
      retryWhen((errors: Observable<any>) =>
        errors.pipe(
          delay(delayMs),
          mergeMap((error) => {
            retries--;
            return retries > 0 ? of(error) : throwError(getErrorMessage(maxRetry));
          })
        )
      )
    );
}
