import { HubConnectionState } from '../../models';

export interface State {
  groupJoined: boolean;
  hubConnectionState: HubConnectionState | null;
}

export const initialState: State = {
  groupJoined: false,
  hubConnectionState: null,
};
