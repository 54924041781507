import { HttpEvent, HttpEventType, HttpStatusCode } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';

import { of } from 'rxjs';
import {
  catchError,
  concatMap,
  exhaustMap,
  filter,
  map,
  mergeMap,
  switchMap,
  withLatestFrom,
} from 'rxjs/operators';

import { NotificationsActions, NotificationType } from 'src/app/features/notifications';
import { AvDisruptionActionBlockingResponse, ExceptionType } from 'src/app/features/notifications/models';
import { PackageUsersSelectors } from 'src/app/features/package-users';
import { PackagesSelectors } from 'src/app/features/packages/store/selectors';
import { RootStoreState } from 'src/app/store';
import { v4 as uuid } from 'uuid';

import { DeviceGroupSelectors } from '../../../device-group';
import { SignalRActions } from '../../../signal-r/store/actions';
import { CompletedDocumentService, DocumentsService, PreviewDocumentService } from '../../services';
import { DocumentsActions } from '../actions';
import { DocumentsSelectors } from '../selectors';
import { ActionBlockingSnackbarComponent, AvDisruptionActionBlockingSnackbarComponent } from 'src/app/features/notifications/components';

@Injectable()
export class DocumentEffects {
  private readonly failedToGetDocuments = 'Failed to get Documents';
  confirmDocumentsReady$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DocumentsActions.GetDocuments),
      withLatestFrom(this.store.select(DocumentsSelectors.getDocumentsLoadedStatus)),
      switchMap(([action, documentsLoadingStatus]) => {
        if (documentsLoadingStatus.isLoading) {
          return [];
        }

        if (documentsLoadingStatus.isLoaded) {
          if (action.payload?.refetchSmartDocs) {
            return of(DocumentsActions.GetUpdatedSmartDocuments());
          }
          if (action.payload?.ignoreIfLoaded) {
            return [];
          }
        }

        return of(DocumentsActions.FetchPreSignDocuments());
      })
    )
  );

  fetchPreSignDocuments$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DocumentsActions.FetchPreSignDocuments),
      concatMap((action) =>
        of(action).pipe(
          withLatestFrom(this.store.pipe(select(PackageUsersSelectors.getActivePackageUserGuid)))
        )
      ),
      exhaustMap(([_, packageUserGuid]) => {
        return this.documentsService.getPreSignDocuments(packageUserGuid).pipe(
          mergeMap((payload) =>
            this.getActionFromHttpEvent(payload, [DocumentsActions.SetDocuments])
          ),
          catchError((err) =>
            of(
              NotificationsActions.AddNotification({
                payload: {
                  exception: err,
                  notificationType: NotificationType.Error,
                  id: uuid(),
                  text: this.failedToGetDocuments,
                  exceptionType: ExceptionType.ReloadRetry,
                },
              })
            )
          )
        );
      })
    )
  );

  fetchPreSignSmartDocuments$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DocumentsActions.GetUpdatedSmartDocuments),
      concatMap((action) =>
        of(action).pipe(
          withLatestFrom(
            this.store.pipe(select(PackageUsersSelectors.getActivePackageUserGuid)),
            this.store.pipe(select(DocumentsSelectors.selectPackageDocuments))
          )
        )
      ),
      exhaustMap(([_, packageUserGuid, documents]) => {
        if (!documents.some((d) => d.isSmartDoc)) {
          return [];
        }

        return this.documentsService.getPreSignSmartDocuments(packageUserGuid).pipe(
          map((payload) => {
            return DocumentsActions.SetUpdatedSmartDocuments({ payload });
          }),
          catchError((err) =>
            of(
              NotificationsActions.AddNotification({
                payload: {
                  exception: err,
                  notificationType: NotificationType.Error,
                  id: uuid(),
                  text: this.failedToGetDocuments,
                  exceptionType: ExceptionType.ReloadRetry,
                },
              })
            )
          )
        );
      })
    )
  );

  fetchSigningSessionDocuments$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DocumentsActions.FetchSigningSessionDocuments),
      concatMap((action) =>
        of(action).pipe(
          withLatestFrom(
            this.store.pipe(select(PackageUsersSelectors.getActivePackageUserGuid)),
            this.store.pipe(select(DocumentsSelectors.getDocumentsLoadedStatus)),
            this.store.pipe(select(DeviceGroupSelectors.getDeviceCode))
          )
        )
      ),
      exhaustMap(([action, packageUserGuid, documentsLoadingStatus, deviceCode]) => {
        if (documentsLoadingStatus.isLoading || documentsLoadingStatus.isLoaded) {
          if (action.payload?.refetchSmartDocs) {
            return [DocumentsActions.GetUpdatedSmartDocumentsForSigning()];
          }
          return [];
        }

        return this.documentsService.getSigningSessionDocuments(packageUserGuid, deviceCode).pipe(
          mergeMap((payload) =>
            this.getActionFromHttpEvent(payload, [DocumentsActions.SetDocuments])
          ),
          catchError((err) =>
            of(
              NotificationsActions.AddNotification({
                payload: {
                  exception: err,
                  notificationType: NotificationType.Error,
                  id: uuid(),
                  text: this.failedToGetDocuments,
                  exceptionType: ExceptionType.ReloadRetry,
                },
              })
            )
          )
        );
      })
    )
  );

  getUpdatedSmartDocumentsForSigning$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DocumentsActions.GetUpdatedSmartDocumentsForSigning),
      concatMap((action) =>
        of(action).pipe(
          withLatestFrom(
            this.store.pipe(select(PackageUsersSelectors.getActivePackageUserGuid)),
            this.store.pipe(select(DocumentsSelectors.selectPackageDocuments))
          )
        )
      ),
      exhaustMap(([_, packageUserGuid, documents]) => {
        if (!documents.some((d) => d.isSmartDoc)) {
          return [];
        }

        return this.documentsService.getSigningSessionSmartDocuments(packageUserGuid).pipe(
          map((payload) => {
            return DocumentsActions.SetUpdatedSmartDocumentsForSigning({
              payload,
            });
          }),
          catchError((err) =>
            of(
              NotificationsActions.AddNotification({
                payload: {
                  exception: err,
                  notificationType: NotificationType.Error,
                  id: uuid(),
                  text: 'Failed to get Smart Documents',
                  exceptionType: ExceptionType.ReloadRetry,
                },
              })
            )
          )
        );
      })
    )
  );

  setActiveDocumentFromSetDocuments$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DocumentsActions.SetDocuments),
      map(({ payload }) => {
        if (payload.lastDocumentViewed === 0) {
          return DocumentsActions.SetActiveDocument({
            payload: payload.signableDocuments[0]?.packageDocumentId,
          });
        }
        return DocumentsActions.SetActiveDocument({
          payload: payload.lastDocumentViewed,
        });
      })
    )
  );

  fetchPreviewDocuments = createEffect(() =>
    this.actions$.pipe(
      ofType(DocumentsActions.FetchPreviewDocuments),
      concatMap((action) =>
        of(action).pipe(
          withLatestFrom(this.store.pipe(select(PackageUsersSelectors.getActivePackageUserGuid)))
        )
      ),
      exhaustMap(([_, packageUserGuid]) => {
        return this.previewDocumentsService.getPackagePreview(packageUserGuid).pipe(
          map((payload) => {
            return DocumentsActions.SetViewableDocumentSource({ payload });
          }),
          catchError((err) =>
            of(
              NotificationsActions.AddNotification({
                payload: {
                  exception: err,
                  notificationType: NotificationType.Error,
                  id: uuid(),
                  text: 'Failed to get Preview Documents',
                },
              })
            )
          )
        );
      })
    )
  );

  fetchCompletedDocuments = createEffect(() =>
    this.actions$.pipe(
      ofType(DocumentsActions.FetchCompletedDocuments),
      concatMap((action) =>
        of(action).pipe(
          withLatestFrom(this.store.pipe(select(PackageUsersSelectors.getActivePackageUserGuid)))
        )
      ),
      exhaustMap(([_, packageUserGuid]) => {
        return this.completedDocumentsService.getCompletedDocument(packageUserGuid).pipe(
          map((payload) => {
            return DocumentsActions.SetViewableDocumentSource({ payload });
          }),
          catchError((err) =>
            of(
              NotificationsActions.AddNotification({
                payload: {
                  exception: err,
                  notificationType: NotificationType.Error,
                  id: uuid(),
                  text: 'Failed to get Completed Documents',
                },
              })
            )
          )
        );
      })
    )
  );

  logDocumentViewed$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DocumentsActions.LogDocumentViewed),
      concatMap((action) =>
        of(action).pipe(
          withLatestFrom(
            this.store.pipe(select(PackageUsersSelectors.getActivePackageUserGuid)),
            this.store.pipe(select(DeviceGroupSelectors.getDeviceCode))
          )
        )
      ),
      switchMap(([action, packageUserGuid, deviceCode]) =>
        this.documentsService.postDocumentViewed(packageUserGuid, deviceCode, action.payload).pipe(
          switchMap(() => []),
          catchError((err) =>
            of(
              NotificationsActions.AddNotification({
                payload: {
                  exception: err,
                  notificationType: NotificationType.Error,
                  id: uuid(),
                  text: 'Failed to set active document',
                },
              })
            )
          )
        )
      )
    )
  );

  navigateToDocument$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DocumentsActions.NavigateToDocument),
      concatMap((action) =>
        of(action).pipe(
          withLatestFrom(
            this.store.pipe(select(PackageUsersSelectors.getActivePackageUserGuid)),
            this.store.pipe(select(PackagesSelectors.isPreSign))
          )
        )
      ),
      switchMap(([action, packageUserGuid, isPreSign]) =>
        this.documentsService
          .postDocumentNavigation(packageUserGuid, action.payload, isPreSign)
          .pipe(
            switchMap(() => []),
            catchError((err) => {
              if (err.status === HttpStatusCode.Locked) {
                return of(
                  NotificationsActions.AddSnackBarNotification({
                    payload: {
                      component: err.error instanceof AvDisruptionActionBlockingResponse ? 
                        AvDisruptionActionBlockingSnackbarComponent : ActionBlockingSnackbarComponent,
                      title: 'Cannot navigate to another document',
                      body: err.error.text,
                      data: err.error,
                      panelClass: 'mdc-snackbar-error',
                      duration: 12000,
                    },
                  })
                );
              } else {
                return of(
                  NotificationsActions.AddNotification({
                    payload: {
                      exception: err,
                      notificationType: NotificationType.Error,
                      id: uuid(),
                      text: 'Failed to post document navigation',
                    },
                  })
                );
              }
            })
          )
      )
    )
  );

  navigateToNextDocument$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DocumentsActions.NavigateToNextDocument),
      withLatestFrom(this.store.pipe(select(DocumentsSelectors.selectActivePackageDocument))),
      map(([_, activePackageDocument]) =>
        DocumentsActions.NavigateToDocument({
          payload: activePackageDocument.nextDocumentId,
        })
      )
    )
  );

  navigateToPreviousDocument$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DocumentsActions.NavigateToPreviousDocument),
      withLatestFrom(this.store.pipe(select(DocumentsSelectors.selectActivePackageDocument))),
      map(([_, activePackageDocument]) =>
        DocumentsActions.NavigateToDocument({
          payload: activePackageDocument.previousDocumentId,
        })
      )
    )
  );

  sendDocumentsLoaded$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DocumentsActions.SendDocumentsLoaded),
      concatMap((action) =>
        of(action).pipe(
          withLatestFrom(
            this.store.pipe(select(PackageUsersSelectors.getActivePackageUserGuid)),
            this.store.pipe(select(DeviceGroupSelectors.getDeviceCode))
          )
        )
      ),
      switchMap(([_, packageUserGuid, deviceCode]) => {
        return this.documentsService.postDocumentsLoaded(packageUserGuid, deviceCode).pipe(
          switchMap(() => []),
          catchError((err) =>
            of(
              NotificationsActions.AddNotification({
                payload: {
                  exception: err,
                  notificationType: NotificationType.Error,
                  id: uuid(),
                  text: 'Failed to send documents finished loading',
                },
              })
            )
          )
        );
      })
    )
  );

  getAreAllSessionsDocumentsLoaded$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DocumentsActions.GetAreAllSessionsDocumentsLoaded),
      withLatestFrom(this.store.pipe(select(PackageUsersSelectors.getActivePackageUserGuid))),
      filter(([_, packageUserGuid]) => !!packageUserGuid),
      switchMap(([_, packageUserGuid]) =>
        this.documentsService.getAllSessionsDocumentsLoaded(packageUserGuid).pipe(
          switchMap((data: boolean) => [
            DocumentsActions.SetAreAllSessionDocumentsLoaded({
              payload: data,
            }),
          ]),
          catchError((err) =>
            of(
              NotificationsActions.AddNotification({
                payload: {
                  exception: err,
                  notificationType: NotificationType.Error,
                  id: uuid(),
                  text: 'Failed get sessions documents loaded information',
                },
              })
            )
          )
        )
      )
    )
  );

  validateActiveSessionDocument$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DocumentsActions.VerifyActiveDocument),
      withLatestFrom(this.store.pipe(select(DocumentsSelectors.getActivePackageDocumentId))),
      switchMap(([_, activeDocumentId]) =>
        this.documentsService.verifyActiveSessionDocument().pipe(
          switchMap((sessionDocumentId: number) => {
            if (!sessionDocumentId || activeDocumentId === sessionDocumentId) {
              return [];
            }
            return [
              DocumentsActions.SetActiveDocument({
                payload: sessionDocumentId,
              }),
            ];
          }),
          catchError((err) =>
            of(
              NotificationsActions.AddNotification({
                payload: {
                  exception: err,
                  notificationType: NotificationType.Warning,
                  id: uuid(),
                  text: 'Failed verify active session document.',
                },
              })
            )
          )
        )
      )
    )
  );

  private getActionFromHttpEvent(event: HttpEvent<any>, actionReferences: Array<any>) {
    switch (event.type) {
      case HttpEventType.Sent: {
        return [
          DocumentsActions.UpdateDocumentLoadingStatus({
            payload: {
              isLoaded: false,
              isLoading: true,
              loadedPercentage: 0,
            },
          }),
        ];
      }
      case HttpEventType.DownloadProgress: {
        const loadedPercentage = event.total
          ? Math.min(Math.round((100 * event.loaded) / event.total), 100)
          : 50;
        return [
          DocumentsActions.UpdateDocumentLoadingStatus({
            payload: {
              loadedPercentage,
              isLoaded: false,
              isLoading: true,
            },
          }),
        ];
      }
      case HttpEventType.ResponseHeader: {
        return [DocumentsActions.DocumentsNoOp()];
      }
      case HttpEventType.Response: {
        if (event.status === 200) {
          let payload: any;
          if (event.body) {
            payload = event.body;
          }

          const returningActions = [
            DocumentsActions.UpdateDocumentLoadingStatus({
              payload: {
                isLoaded: true,
                isLoading: false,
                loadedPercentage: 100,
              },
            }),
            DocumentsActions.SendDocumentsLoaded(),
          ];

          actionReferences.forEach((actionReference) => {
            returningActions.push(actionReference({ payload }));
          });

          return returningActions;
        } else {
          return [
            DocumentsActions.UpdateDocumentLoadingStatus({
              payload: {
                isLoaded: true,
                isLoading: false,
                loadedPercentage: 100,
              },
            }),
            DocumentsActions.SendDocumentsLoaded(),
          ];
        }
      }
      default: {
        return [DocumentsActions.DocumentsNoOp()];
      }
    }
  }

  configureListeners$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SignalRActions.SignalRRoomJoined),
        map(() => {
          this.documentsService.configureListeners();
        })
      ),
    { dispatch: false }
  );

  setIsExecutedClosingPackageDownloading$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DocumentsActions.DownloadExecutedClosingPackage),
      map(() =>
        DocumentsActions.SetIsExecutedClosingPackageDownloading({
          payload: { isExecutedClosingPackageDownloading: true },
        })
      )
    )
  );

  fetchExecutedClosingPackage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DocumentsActions.DownloadExecutedClosingPackage),
      withLatestFrom(this.store.pipe(select(PackagesSelectors.getActivePackageGuid))),
      switchMap(([action, packageGuid]) => {
        return this.documentsService.downloadExecutedClosingPackage(packageGuid).pipe(
          switchMap((payload) => {
            const file = new Blob([payload], { type: 'application/pdf' });
            const downloadLink = document.createElement('a');
            downloadLink.href = window.URL.createObjectURL(file);
            downloadLink.setAttribute('download', 'Executed Closing Package');
            document.body.appendChild(downloadLink);
            downloadLink.click();
            return [
              DocumentsActions.SetIsExecutedClosingPackageDownloading({
                payload: { isExecutedClosingPackageDownloading: false },
              }),
            ];
          }),
          catchError((err) =>
            of(
              NotificationsActions.AddNotification({
                payload: {
                  exception: err,
                  notificationType: NotificationType.Error,
                  id: uuid(),
                  text: 'Failed to download Executed Closing Package',
                  exceptionType: ExceptionType.CannotProceed,
                },
              }),
              DocumentsActions.SetIsExecutedClosingPackageDownloading({
                payload: { isExecutedClosingPackageDownloading: false },
              })
            )
          )
        );
      })
    )
  );

  constructor(
    private readonly actions$: Actions,
    private readonly documentsService: DocumentsService,
    private readonly previewDocumentsService: PreviewDocumentService,
    private readonly completedDocumentsService: CompletedDocumentService,
    private readonly store: Store<RootStoreState.State>
  ) {}
}
