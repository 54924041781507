<app-fetch-package-users></app-fetch-package-users>
<div class="waiting-room" *ngIf="vm$ | async as vm">
  <div class="waiting-room__heading">
    <h3>Waiting Room</h3>
    <p *ngIf="vm.isSigningAgent === false" class="waiting-room__heading--message">
      Once everyone has entered the waiting room, you'll get a notification that your signing
      session is about to begin.
    </p>
  </div>
  <div class="waiting-room__content">
    <div *ngIf="vm.isSigningAgent" class="user">
      <div class="user__content">
        <p class="user__content--title">
          <svg class="icon" viewBox="0 -960 960 960" xmlns="http://www.w3.org/2000/svg">
            <g>
              <path
                class="icon__background"
                d="M 456,-199.99995 630,-540 H 510 v -220 l -180,340 h 126 z m 24,120 q -83,0 -156,-31.5 -73,-31.5 -127,-85.5 -54,-54 -85.5,-127 Q 80,-397 80,-480 q 0,-83 31.5,-156 31.5,-73 85.5,-127 54,-54 127,-85.5 73,-31.5 156,-31.5 83,0 156,31.5 73,31.5 126.99998,85.5 54,54 85.5,127 31.5,73 31.5,156 0,83 -31.5,156.00005 -31.5,73 -85.5,127 -53.99998,54 -126.99998,85.5 -73,31.5 -156,31.5 z"
              />
            </g>
            <g>
              <path
                class="icon__bolt-circle"
                d="M 456,-199.99998 630,-540 H 510 v -220 l -180,340 h 126 z m 24,120 q -83,0 -156,-31.5 -73,-31.5 -127,-85.5 -54,-54 -85.5,-127 Q 80,-397 80,-480 q 0,-83 31.5,-156 31.5,-73 85.5,-127 54,-54 127,-85.5 73,-31.5 156,-31.5 83,0 156,31.5 73,31.5 127,85.5 54,54 85.5,127 31.5,73 31.5,156 0,83 -31.5,156.00002 -31.5,73 -85.5,127 -54,54 -127,85.5 -73,31.5 -156,31.5 z m 0,-80 q 134,0 227,-93 93,-93 93,-227.00002 0,-134 -93,-227 -93,-93 -227,-93 -134,0 -227,93 -93,93 -93,227 0,134.00002 93,227.00002 93,93 227,93 z M 480,-480 Z"
              />
            </g>
          </svg>

          <strong>Before you begin</strong>
        </p>
        <span class="user__content--message">
          Welcome to the waiting room. As you await the arrival of the signing party, kindly take this
          opportunity to review and prepare your notary script. Once everyone has completed their
          check-in and entered the waiting room, you'll get a notification to join your signing
          session. Otherwise, your signing session will automatically begin after a 10 second
          countdown. We appreciate your participation and cooperation in making this a successful
          event.
        </span>
      </div>
    </div>

    <ng-container
      *ngIf="vm.isSigningAgent === false"
    >
      <div class="user">
        <app-card backgroundStyle="brand">
          <div class="user__heading">
            <img
              src="../../../../../assets/images/notary-participant-image.svg"
              class="user__heading--photo"
              alt="Image of notary"
            />
            <div class="user__description">
              <div class="user__description--title">Notary</div>
              <div class="user__description--name">
                <strong data-private>{{ vm.signingAgent?.firstName }} {{ vm.signingAgent?.lastName }}</strong>
              </div>
            </div>
          </div>
        </app-card>
        <div class="user__content">
          <p class="user__content--title">Message from your notary</p>
          <span class="user__content--message">
            Hello and welcome to our waiting room! My name is
            <span data-private>{{ vm.signingAgent?.firstName }}</span>, and I'll be
            your notary today. I'm here to assist you with any questions you may have and ensure that
            your closing process goes smoothly. Please feel free to relax and make yourself
            comfortable while we wait for the rest of the signing party to check-in. I look forward to
            meeting you!
          </span>
        </div>
      </div>

      <app-card class="add-participant" borderStyle="shadow">
        <h3>Add participant to this device</h3>
        <p>To add a participant in the same location as you on this device, click below.</p>
        <app-button
          [ariaLabel]="
          vm.isAddParticipantButtonDisabled
            ? 'button disabled if all participants are checking in'
            : 'add participant button'"
          [isDisabled]="vm.isAddParticipantButtonDisabled"
          (buttonClick)="openAddParticipantModal()"
          [dataTestId]="'add-participant-button'"
          type="secondary"> + Add Participant
        </app-button>
      </app-card>
    </ng-container>

    <div class="progress-wheel">
      <div class="progress-wheel__content">
        <span>Participants Ready</span>
        <strong>{{vm.numberOfReadyParticipants}}/{{vm.packageUsersCount}}</strong>
      </div>
      <mat-progress-spinner
        aria-label="Participant Ready Progress"
        class="progress-wheel__spinner"
        [mode]="'determinate'"
        [value]="vm.progress">
      </mat-progress-spinner>
      <mat-progress-spinner
        aria-hidden="true"
        class="progress-wheel__spinner--track"
        [mode]="'determinate'"
        [value]="100">
      </mat-progress-spinner>
    </div>

    <app-participant-list></app-participant-list>
  </div>
<app-package-status-monitor></app-package-status-monitor>
</div>
