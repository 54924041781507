import { DOCUMENT } from '@angular/common';
import { Component, Inject, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';

import { Observable, of } from 'rxjs';

import { Client, defaultClientInfo } from 'src/app/features/client/models';
import { CancelSigningModalComponent } from 'src/app/features/layout';
import { ModalsActions } from 'src/app/features/modals';
import { RootStoreState } from 'src/app/store';

import { VideoOptOutReasons } from '../../enums';

@Component({
  selector: 'app-refresh-needed-modal',
  templateUrl: './refresh-needed-modal.component.html',
  styleUrls: ['./refresh-needed-modal.component.scss'],
})
export class RefreshNeededModalComponent implements OnInit {
  static identifier = 'RefreshNeededModalComponent';
  client$: Observable<Client>;
  @Input() isPublishingError: boolean;

  constructor(
    private readonly store: Store<RootStoreState.State>,
    @Inject(DOCUMENT) private readonly document: Document
  ) {}

  ngOnInit(): void {
    this.client$ = of(defaultClientInfo);
  }

  cancelPackageOnUserRequest() {
    this.store.dispatch(ModalsActions.ClearModalComponent());
    this.store.dispatch(
      ModalsActions.SetStandaloneModalComponent({
        payload: {
          component: CancelSigningModalComponent,
          componentData: {
            fixedCategoryTypeCode: this.isPublishingError
              ? VideoOptOutReasons.PublishingError
              : VideoOptOutReasons.AudioVideoDeviceNotDetected,
          },
        },
      })
    );
  }

  refresh() {
    this.store.dispatch(ModalsActions.ClearModalComponent());
    this.document.defaultView.location.reload();
  }
}
