import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { ClientSelectors } from 'src/app/features/client';

import { Client, defaultClientInfo } from 'src/app/features/client/models';

import { RootStoreState } from 'src/app/store';

@Component({
  selector: 'app-further-assistance',
  templateUrl: './further-assistance.component.html',
  styleUrls: [ './further-assistance.component.scss']
})
export class FurtherAssistanceComponent implements OnInit {
  client$: Observable<Client>;
  @Input() showDefaultClientInfo: boolean;

  constructor(
    private readonly store: Store<RootStoreState.State>,
  ) { }

  ngOnInit(): void {
    if (this.showDefaultClientInfo === true) {
      this.client$ = of(defaultClientInfo);
    }
    else {
      this.client$ = this.store.select(ClientSelectors.getClientSupportDetails);
    }
  }
}
