import { Component, OnInit } from '@angular/core';

import { DEFAULT_APPLICATION_PHONE_NUMBER } from 'src/app/features/shared/constants';

@Component({
  selector: 'app-audio-video-blocked-error-modal',
  templateUrl: './audio-video-blocked-error-modal.component.html',
  styleUrls: ['./audio-video-blocked-error-modal.component.scss'],
})
export class AudioVideoBlockedErrorModalComponent implements OnInit {
  static identifier = 'AudioVideoBlockedErrorModalComponent';
  public phoneNumber: string;
  constructor() {}

  ngOnInit(): void {
    this.phoneNumber = DEFAULT_APPLICATION_PHONE_NUMBER;
  }

  refresh(): void {
    window.location.reload();
  }
}
