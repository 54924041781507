import { Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';

import { Observable } from 'rxjs';

import { PackagesActions, PackagesSelectors } from 'src/app/features/packages';
import { RootStoreState } from 'src/app/store';

@Component({
  selector: 'app-signings-monthly-card',
  templateUrl: './signings-monthly-card.component.html',
  styleUrls: ['./signings-monthly-card.component.scss'],
})
export class SigningsMonthlyCardComponent implements OnInit {
  totalMonthlyClosings$: Observable<number | null>;

  constructor(private readonly store: Store<RootStoreState.State>) {}

  ngOnInit(): void {
    this.store.dispatch(PackagesActions.GetMonthlyClosings());
    this.totalMonthlyClosings$ = this.store.pipe(select(PackagesSelectors.getTotalMonthlyClosings));
  }
}
