//#region imports
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { DefaultProjectorFn, MemoizedSelector, select, Store } from '@ngrx/store';

import { merge, of } from 'rxjs';
import {
  catchError,
  concatMap,
  filter,
  map,
  mapTo,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { v4 as uuid } from 'uuid';

import { ClientSelectors } from 'src/app/features/client';
import { DeviceGroupSelectors } from 'src/app/features/device-group/store/selectors';
import { NotificationsActions, NotificationType } from 'src/app/features/notifications';
import { ExceptionType } from 'src/app/features/notifications/models';
import { PackageUsersSelectors } from 'src/app/features/package-users';
import { PackagesSelectors } from 'src/app/features/packages';
import { QRCODE_REFRESH_TIME } from 'src/app/features/shared/constants';
import { SignalRActions } from 'src/app/features/signal-r';
import { WizardSelectors } from 'src/app/features/wizard/store/selectors';
import { ApplicationInsightsService } from 'src/app/services/application-insights.service';
import { RootStoreState } from 'src/app/store';

import { AccessLinkService, IdVerificationService } from '../../services';
import { IdVerificationActions } from '../actions';
import { IdVerificationSelectors } from '../selectors';

//#endregion imports

@Injectable()
export class IdVerificationEffects {
  public activePackageUserGuidSelector: MemoizedSelector<
    object,
    string,
    DefaultProjectorFn<string>
  >;
  public isPresign: boolean;

  savePhotoIdBeginEvent$ = createEffect(() =>
    this.actions$.pipe(
      ofType(IdVerificationActions.SaveAuditPhotoIdBegin),
      concatMap((action) =>
        of(action).pipe(
          withLatestFrom(
            this.store.pipe(select(WizardSelectors.getActiveWizardUserGuid)),
            this.store.pipe(select(PackagesSelectors.getActivePackageGuid)),
            this.store.pipe(select(DeviceGroupSelectors.getDeviceCode))
          )
        )
      ),
      switchMap(([action, packageUserGuid, activePackageGuid, deviceCode]) =>
        this.idVerificationService
          .postPhotoIdBegin({
            PackageGuid: activePackageGuid,
            PackageUserGuid: packageUserGuid,
            DeviceCode: deviceCode,
          })
          .pipe(
            switchMap(() => []),
            catchError((err) =>
              of(
                NotificationsActions.AddNotification({
                  payload: {
                    exception: err,
                    notificationType: NotificationType.Error,
                    id: uuid(),
                    text: 'Failed to log Photo ID Begin event',
                  },
                })
              )
            )
          )
      )
    )
  );

  logIdVerificationPageViews$ = createEffect(
    () =>
      merge(
        this.actions$.pipe(
          ofType(IdVerificationActions.SaveAuditPhotoIdBegin),
          mapTo('instructions')
        ),
        this.actions$.pipe(
          ofType(IdVerificationActions.IdVerificationQrCodeShown),
          mapTo('qr code')
        )
      ).pipe(
        tap((pageName) =>
          this.logging.logPageView(`ID Verification - ${pageName}`, this.router.url)
        )
      ),
    { dispatch: false }
  );

  sendAccessLinkSms$ = createEffect(() =>
    this.actions$.pipe(
      ofType(IdVerificationActions.SendAccessLinkSms),
      withLatestFrom(
        this.store.pipe(select(IdVerificationSelectors.getPackageUserGuid)),
        this.store.pipe(select(DeviceGroupSelectors.getDeviceCode))
      ),
      switchMap(
        ([action, packageUserGuid, deviceCode]) => {
          return this.accessLinkService
            .postSendAccessLinkSms(packageUserGuid, deviceCode, action.phoneNumberOverride)
            .pipe(
              map(() => IdVerificationActions.SendAccessLinkSmsSuccessful()),
              catchError(() => of(IdVerificationActions.SendAccessLinkSmsFailed()))
            );
        }
      )
    )
  );

  fetchAccessLink$ = createEffect(() =>
    this.actions$.pipe(
      ofType(IdVerificationActions.FetchAccessLink),
      concatMap((action) =>
        of(action).pipe(
          withLatestFrom(
            this.store.pipe(select(WizardSelectors.getActiveWizardUser)),
            this.store.pipe(select(DeviceGroupSelectors.getDeviceCode)),
            this.store.pipe(select(IdVerificationSelectors.getLastLinkTime)),
            this.store.pipe(select(IdVerificationSelectors.getPackageUserGuid)),
            this.store.pipe(select(PackagesSelectors.getClientId))
          )
        )
      ),
      switchMap(([_, packageUser, deviceCode, lastLinkTime, lastPackageUserGuid, clientId]) => {
        const packageUserGuid = packageUser.packageUserGuid;
        const firstName = packageUser.firstName;
        // Don't request a new access link unless it's expired or we've changed users
        if (
          Date.now() - lastLinkTime < QRCODE_REFRESH_TIME &&
          packageUserGuid === lastPackageUserGuid
        ) {
          return of(IdVerificationActions.UseOldAccessLink());
        }

        return this.idVerificationService.createSession(packageUserGuid, firstName, clientId).pipe(
          switchMap(() =>
            this.accessLinkService.getAccessLink(packageUserGuid, deviceCode).pipe(
              map((payload) =>
                IdVerificationActions.SetAccessLink({
                  payload: {
                    accessLink: payload.accessLink,
                    packageUserGuid,
                  },
                })
              ),
              catchError((err) =>
                of(
                  NotificationsActions.AddNotification({
                    payload: {
                      exception: err,
                      notificationType: NotificationType.Error,
                      id: uuid(),
                      text: 'Failed to get Access Link',
                      exceptionType: ExceptionType.CannotProceed,
                    },
                  })
                )
              )
            )
          ),
          catchError((err) =>
            of(
              NotificationsActions.AddNotification({
                payload: {
                  exception: err,
                  notificationType: NotificationType.Error,
                  id: uuid(),
                  text: 'Failed to create the id verification session',
                  exceptionType: ExceptionType.CannotProceed,
                },
              })
            )
          )
        );
      })
    )
  );

  configureListeners$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SignalRActions.SignalRRoomJoined),
        map(() => {
          this.idVerificationService.configureListeners();
        })
      ),
    { dispatch: false }
  );

  fakeIdIdVerificationComplete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(IdVerificationActions.FakeIdIdVerificationComplete),
      concatMap((action) =>
        of(action).pipe(
          withLatestFrom(
            this.store.pipe(select(WizardSelectors.getActiveWizardUserGuid)),
            this.store.pipe(select(ClientSelectors.shouldBypassRealId))
          )
        )
      ),
      filter(([_, __, shouldBypassRealId]) => shouldBypassRealId),
      switchMap(([_, packageUserGuid, __]) =>
        this.idVerificationService.postIdVerificationComplete(packageUserGuid).pipe(
          switchMap(() => [
            IdVerificationActions.IdVerificationCompleted({ payload: { packageUserGuid } }),
          ]),
          catchError((err) =>
            of(
              NotificationsActions.AddNotification({
                payload: {
                  exception: err,
                  notificationType: NotificationType.Error,
                  id: uuid(),
                  text: 'Failed to save Fake ID completion',
                  exceptionType: ExceptionType.CannotProceed,
                },
              })
            )
          )
        )
      )
    )
  );

  getUserIdVerificationResultsStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(IdVerificationActions.GetUserIdVerificationResultsStatus),
      concatMap((action) =>
        of(action).pipe(
          withLatestFrom(this.store.pipe(select(WizardSelectors.getActiveWizardUserGuid)))
        )
      ),
      switchMap(([action, activePackageUserGuid]) =>
        this.idVerificationService.getIdVerificationStatusResults(activePackageUserGuid).pipe(
          switchMap((results) => {
            if (results.identityVerificationComplete) {
              return [
                IdVerificationActions.IdVerificationCompleted({
                  payload: {
                    packageUserGuid: activePackageUserGuid,
                  },
                }),
              ];
            }
            return [];
          }),
          catchError((err) =>
            of(
              NotificationsActions.AddNotification({
                payload: {
                  text: 'Failed to retrieve ID Verification status',
                  notificationType: NotificationType.Warning,
                  exception: err,
                  id: uuid(),
                  logInAppInsights: true,
                },
              })
            )
          )
        )
      )
    )
  );

  constructor(
    private readonly actions$: Actions,
    private readonly accessLinkService: AccessLinkService,
    private readonly idVerificationService: IdVerificationService,
    private readonly store: Store<RootStoreState.State>,
    private readonly logging: ApplicationInsightsService,
    private readonly router: Router
  ) {
    this.activePackageUserGuidSelector = PackageUsersSelectors.getActivePackageUserGuid;
  }

  private generateLogFingerprint(
    packageUserGuid: string,
    packageGuid: string,
    deviceCode: string,
    verificationId?: number
  ) {
    const transactionMessage = verificationId ? ` transaction:${verificationId}` : '';
    return `user:${packageUserGuid} package:${packageGuid} device:${deviceCode}${transactionMessage}`;
  }
}
