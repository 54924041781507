import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { NotificationsModule } from 'src/app/features/notifications/notifications.module';
import { SharedModule } from 'src/app/features/shared';
import { SigningRoomReducers } from 'src/app/features/signing-room/store/reducers';
import { WizardModule } from 'src/app/features/wizard/wizard.module';
import {
  PrivacyPolicyConsentModalComponent,
  ScrollableTextBoxComponent,
  SignatureConsentAgreementModalComponent,
  TermsOfServiceDeclineModalComponent,
  TermsOfUseConsentModalComponent,
} from './components';
import { EulaComponent, TermsOfServiceComponent } from './containers';
import { ConsentsService } from './services';

import { ConsentsEffects, ConsentsReducers, PresignConsentsEffects } from './store';

const consentsComponents = [
  TermsOfServiceDeclineModalComponent,
  TermsOfUseConsentModalComponent,
  PrivacyPolicyConsentModalComponent,
  ScrollableTextBoxComponent,
  SignatureConsentAgreementModalComponent
];

const consentsContainerComponents = [TermsOfServiceComponent, EulaComponent];

const modules = [
  WizardModule,
  NotificationsModule,
  SharedModule,
  CommonModule,
  ReactiveFormsModule,
];

@NgModule({
  declarations: [...consentsComponents, ...consentsContainerComponents],
  providers: [ConsentsService],
  exports: [...consentsContainerComponents, ScrollableTextBoxComponent],
  imports: [
    ...modules,
    StoreModule.forFeature('consents', ConsentsReducers.consentsReducer),
    EffectsModule.forFeature([
      ConsentsEffects.ConsentsEffects,
      PresignConsentsEffects.PresignConsentsEffects,
    ]),
    StoreModule.forFeature('signingRoom', SigningRoomReducers.Reducers.signingRoomReducer),
  ],
})
export class ConsentsModule {}
