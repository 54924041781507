import { Injectable } from '@angular/core';
import { VideoLayout } from '../enums/video-layout.enum';

@Injectable()
export class VideoLayoutService {
  constructor() {}

  calculateLayout(numberOfVideos: number): number {
    if (numberOfVideos < 5) {
      return VideoLayout.single;
    }

    if (numberOfVideos < 9) {
      return VideoLayout.double;
    }

    return VideoLayout.triple;
  }

  layoutSupportsActiveTalker(layout: VideoLayout): boolean {
    return layout !== VideoLayout.single;
  }
}
