<div class="box-size">
  <h3 class="title">Audio & camera settings</h3>
  <div class="audio-video-settings-instructions">
    Please select your audio and video from the options below. If one of your devices is not
    working, please check the connection and try again.
  </div>
  <div (scroll)="onScroll($event)" class="av_body">
    <div class="audio-video-container">
      <div class="video-container" data-private>
        <div class="video-placeholder" [hidden]="devicesAreLoaded"></div>
        <video #video class="video-list" [hidden]="!devicesAreLoaded"></video>
      </div>
      <div class="av-selectors">
        <form [formGroup]="deviceForm">
          <div class="video-devices">
            <div class="flex-align-center">
              <div class="device-group-header-container">
                <h4>Camera</h4>
              </div>
            </div>
            <div class="select" data-testid="device-settings-video">
              <select
                id="video"
                formControlName="video"
                class="form-control"
                (change)="updateSelectedVideo()"
              >
                <option *ngIf="!devicesAreLoaded">
                  <span *ngFor="let i of [].constructor(128)">&nbsp;</span>
                </option>
                <option
                  *ngFor="let camera of availableAudioVideoDevices.video; index as i"
                  [value]="camera.deviceId"
                >
                  {{ camera.label }}
                </option>
              </select>
            </div>
          </div>
          <div class="audio-devices">
            <div class="flex-align-center">
              <div class="device-group-header-container">
                <h4>Speaker and Microphone</h4>
              </div>
            </div>
            <div class="select" data-testid="device-settings-audio">
              <select
                id="audio"
                formControlName="audio"
                class="form-control"
                (change)="updateSelectedAudio()"
              >
                <option *ngIf="!devicesAreLoaded">
                  <span *ngFor="let i of [].constructor(128)">&nbsp;</span>
                </option>
                <option
                  *ngFor="let microphone of availableAudioVideoDevices.audio; let i = index"
                  [value]="microphone.deviceId"
                >
                  {{ microphone.label }}
                </option>
              </select>
            </div>
            <div id="meters">
              <div class="flex-align-center">
                <div class="device-group-header-container">
                  <h4>Microphone Level</h4>
                </div>
              </div>
              <div id="instant">
                <meter
                  class="volume-meter"
                  high="0.25"
                  max="1"
                  value="{{ meterValue | async }}"
                ></meter>
                <div class="value"></div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
    <app-button-container>
      <app-button
        type="secondary"
        (click)="cancelAudioVideoSelections()"
        [dataTestId]="'device-settings-cancel'"
      >
        Cancel
      </app-button>
      <app-button
        type="primary"
        (click)="applyAudioVideoSelections()"
        [dataTestId]="'device-settings-apply'"
      >
        Apply Changes
      </app-button>
    </app-button-container>
  </div>
  <div class="down-button" *ngIf="!isAtBottom">
    <button type="button" class="btn btn--scroll-down-button" (click)="scrollDown()">
      <img src="../../../../../assets/images/arrow-down-circle.svg" alt="arrow down button" />
    </button>
  </div>
</div>
