import { CdkStepper } from '@angular/cdk/stepper';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-stepper',
  templateUrl: './stepper.component.html',
  styleUrls: ['./stepper.component.scss'],
  providers: [{ provide: CdkStepper, useExisting: StepperComponent }],
})
export class StepperComponent extends CdkStepper {
  @Input()
  activeClass = 'active';

  @Input()
  ariaLabelText = 'steps';

  isLastStep() {
    return (this.steps.length === this.selectedIndex + 1);
  }

  isFirstStep() {
    return this.selectedIndex === 0;
  }
}
