import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

@Injectable()
export class WaitingRoomService {
  constructor(private readonly http: HttpClient) {}

  public viewed(packageGuid: string, sessionId: string, packageUserGuid: string) {
    return this.http.post(`waitingRoom/packages/${packageGuid}/viewed`, {
      sessionId: sessionId,
      packageUserGuid: packageUserGuid,
    });
  }

  public viewedByParticipants(packageGuid: string): Observable<string[]> {
    return this.http.get<string[]>(`waitingRoom/packages/${packageGuid}/participants`);
  }
}
