import { Action, createReducer, on } from '@ngrx/store';

import { PackagesActions } from 'src/app/features/packages/store/actions';

import { PackageUsersActions } from '../actions';
import { PackageUsersState } from '../state';

const reducer = createReducer(
  PackageUsersState.initialState,
  on(PackageUsersActions.SetActivePackageUser, (state: PackageUsersState.State, { payload }) => ({
    ...state,
    activePackageUser: payload.packageUser,
  })),
  on(
    PackageUsersActions.SetActivePackageUserGuid,
    (state: PackageUsersState.State, { payload }) => ({
      ...state,
      activePackageUserGuid: payload.activePackageUserGuid,
    })
  ),
  on(
    PackageUsersActions.SetActivePackageAndUserGuids,
    (state: PackageUsersState.State, { payload }) => ({
      ...state,
      activePackageUserGuid: payload.activePackageUserGuid,
    })
  ),
  on(
    PackageUsersActions.FetchPackageUsersSuccessful,
    (state: PackageUsersState.State, { payload }) => ({
      ...state,
      packageUsers: payload.packageUsers,
    })
  ),
  on(PackageUsersActions.ResetPackageUsers, (state: PackageUsersState.State) => ({
    ...state,
    packageUsers: [],
  })),
  on(PackagesActions.ValidatePackageSuccessful, (state: PackageUsersState.State, { payload }) => ({
    ...state,
    isSigningAgent: payload.isUserSigningAgent,
  })),
  on(
    PackageUsersActions.SetPackageUserCheckInStatus,
    (state: PackageUsersState.State, { payload }) => ({
      ...state,
      packageUsers: state.packageUsers?.map((pu) =>
        pu.packageUserGuid === payload.packageUserGuid
          ? { ...pu, checkInStatus: payload.checkInStatus }
          : pu
      ),
    })
  ),
  on(
    PackageUsersActions.SetActivePackageUserDashboardUrl,
    (state: PackageUsersState.State, { payload }) => ({
      ...state,
      activePackageUserDashboardUrl: payload.dashboardUrl,
    })
  ),
  on(
    PackageUsersActions.PackageUserCheckInStatusUpdated,
    (state: PackageUsersState.State, { payload }) => ({
      ...state,
      packageUsers: state.packageUsers?.map((pu) =>
        pu.packageUserGuid === payload.packageUserGuid
          ? { ...pu, checkInStatus: payload.checkInStatus }
          : pu
      ),
    })
  )
);

export function packageUsersReducer(state: PackageUsersState.State | undefined, action: Action) {
  return reducer(state, action);
}
