import { Action, createReducer, on } from '@ngrx/store';
import { modifiedPropertiesHash } from '../../helpers';
import { RulesActions } from '../actions';

import { RulesState } from '../state';

const reducer = createReducer(
  RulesState.initialState,
  on(RulesActions.SetRulesForUser, (state: RulesState.State, { payload }) => {
    const updatedState = {
      ...state,
    };
    const ruleSet = modifiedPropertiesHash(
      Object.keys(payload.modifiedProperties)
    );

    updatedState[payload.packageUser.userRoleCode] = {
      ...updatedState[payload.packageUser.userRoleCode],
      [ruleSet]: payload.result
    };

    return updatedState;
  })
);

export function rulesReducer(
  state: RulesState.State | undefined,
  action: Action
) {
  return reducer(state, action);
}
