import { Component, Input } from '@angular/core';
import { Store } from '@ngrx/store';

import { ModalsActions } from 'src/app/features/modals';
import { RootStoreState } from 'src/app/store';

@Component({
  selector: 'app-help-modal',
  templateUrl: './help-modal.component.html',
  styleUrls: ['./help-modal.component.scss'],
})
export class HelpModalComponent {
  @Input() nexsysSupportNumber: string;
  constructor(private readonly store: Store<RootStoreState.State>) {}

  close() {
    this.store.dispatch(ModalsActions.ClearModalComponent());
  }
}
