<div class="refresh-needed-modal-container">
  <div class="refresh-needed-modal-container__header">
    <div class="title">Refresh needed</div>
  </div>
  <div class="refresh-needed-modal-container__body">
    <p>Your video connection has timed out, please refresh the page to try to reconnect.</p>
    <p>If this continues to be an issue, you may have to <span (click)="cancelPackageOnUserRequest()" class="cancel-text">cancel the session.</span></p>
    <p>
      Need help? Call us:
      <a href="tel:{{ (client$ | async)?.supportPhoneNumber | phone }}" class="phone-number">{{ (client$ | async)?.supportPhoneNumber | phone }}</a>
    </p>
  </div>
  <div class="refresh-needed-modal-container__footer">
    <button class="btn btn--refresh" (click)="refresh()">Refresh</button>
  </div>
</div>
