import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { delayedRetry } from 'src/app/operators';

import {
  CommissionConsentContent,
  ConfirmKnownSignerRequest,
  EndorsementsConsent,
  EndUserLicenseAgreementContent,
  EulaViewedRequest,
  KnownSignerConsent,
  PrivacyPolicyConsent,
  TermsAndConditionsConsent,
  TermsOfServiceRequest,
} from '../models';

@Injectable({
  providedIn: 'root'
})
export class ConsentsService {
  constructor(private readonly httpClient: HttpClient) {}

  getPrivacyConsent(packageUserGuid: string, deviceCode: string) {
    return this.httpClient
      .get<PrivacyPolicyConsent>(
        `consents/privacyPolicy/packageUsers/${packageUserGuid}/deviceCodes/${deviceCode}`
      )
      .pipe(
        delayedRetry(1000),
        catchError((res) => {
          return throwError(res);
        })
      );
  }

  getTermsAndConditionsConsent(packageUserGuid: string, deviceCode: string) {
    return this.httpClient
      .get<TermsAndConditionsConsent>(
        `consents/termsAndConditions/packageUsers/${packageUserGuid}/deviceCodes/${deviceCode}`
      )
      .pipe(
        delayedRetry(1000),
        catchError((res) => {
          return throwError(res);
        })
      );
  }

  consentToKnownSigner(acceptConsent: ConfirmKnownSignerRequest) {
    return this.httpClient.post(`consents/knownSigner/`, acceptConsent).pipe(
      delayedRetry(500, 3),
      catchError((res) => {
        return throwError(res);
      })
    );
  }

  getEndorsementsConsent(packageUserGuid: string, deviceCode: string) {
    return this.httpClient
      .get<EndorsementsConsent>(
        `consents/endorsements/packageUsers/${packageUserGuid}/deviceCodes/${deviceCode}`
      )
      .pipe(
        delayedRetry(1000),
        catchError((res) => {
          return throwError(res);
        })
      );
  }

  getEulaContent(): Observable<EndUserLicenseAgreementContent> {
    return this.httpClient.get<EndUserLicenseAgreementContent>(`consents/eula/content`).pipe(
      delayedRetry(500, 3),
      catchError((res) => {
        return throwError(res);
      })
    );
  }

  getKnownSignerConsent(
    packageUserGuid: string,
    deviceCode: string
  ): Observable<KnownSignerConsent> {
    return this.httpClient
      .get<KnownSignerConsent>(
        `consents/knownSigner/packageUsers/${packageUserGuid}/deviceCodes/${deviceCode}`
      )
      .pipe(
        delayedRetry(1000),
        catchError((res) => {
          return throwError(res);
        })
      );
  }

  getCommissionConsentByState(
    stateCode: string,
    deviceCode: string
  ): Observable<CommissionConsentContent> {
    return this.httpClient.get(`consents/commissions/${stateCode}/deviceCodes/${deviceCode}`).pipe(
      delayedRetry(500, 3),
      catchError((res) => {
        return throwError(res);
      })
    );
  }

  getHasAcceptedEula(): Observable<boolean> {
    return this.httpClient.get<boolean>('consents/eula/acceptance').pipe(
      delayedRetry(500, 3),
      catchError((res) => {
        return throwError(res);
      })
    );
  }

  postEulaResponse(eulaViewed: EulaViewedRequest) {
    return this.httpClient.post('consents/eula/viewed', eulaViewed).pipe(
      delayedRetry(500, 3),
      catchError((res) => {
        return throwError(res);
      })
    );
  }

  acceptTermsOfService(request: TermsOfServiceRequest) {
    return this.httpClient.post(`consents/accept/termsOfService`, request).pipe(
      delayedRetry(500, 3),
      catchError((res) => {
        return throwError(res);
      })
    );
  }

  declineTermsOfService(request: TermsOfServiceRequest) {
    return this.httpClient.post(`consents/decline/termsOfService`, request).pipe(
      delayedRetry(500, 3),
      catchError((res) => {
        return throwError(res);
      })
    );
  }
}
