<app-fetch-package-users></app-fetch-package-users>
<div
  class="check-in-complete"
  *ngIf="isDeviceCheckInComplete$ | async; else continueCheckInTemplate"
>
  <img
    class="check-in-complete__animation"
    src="assets/images/party-popper.gif"
    alt="party popper illustration"
  />
  <h1 class="check-in-complete__header">Check-In Complete!</h1>
  <p class="check-in-complete__message" data-private>
    {{ previousUserName$ | async }}, {{ message$ | async }}
  </p>
  <div class="check-in-complete__button-container">
    <app-button
      *ngIf="canSkipToWaitingRoom$ | async"
      (click)="goToWaitingRoom()"
      [type]="'primary'"
      [dataTestId]="'go-to-waiting-room'"
      >Go to Waiting Room</app-button
    >
    <app-button
      *ngIf="(canSkipToWaitingRoom$ | async) === false"
      (click)="goToWaitingRoom()"
      [type]="'primary'"
      [dataTestId]="'go-to-signing-room'"
      >Go to Signing Room</app-button
    >
    <app-button
      *ngIf="areOtherUsersEligibleForCheckIn$ | async"
      (click)="returnToWelcomePage()"
      [type]="'tertiary'"
      [dataTestId]="'check-in-another-person'"
      >Check in another person</app-button
    >
  </div>
</div>

<ng-template #continueCheckInTemplate>
  <div class="check-in-complete">
    <img
      class="check-in-complete__animation"
      src="assets/images/party-popper.gif"
      alt="party popper illustration"
    />
    <h1 class="check-in-complete__header" data-private>
      {{ previousUserName$ | async }}, your check-in is complete!
    </h1>
    <p class="check-in-complete__message" data-private>
      {{ nextUserName$ | async }}, you can now begin your check-in.
    </p>
    <div class="check-in-complete__button-container">
      <app-button
        (click)="beginCheckIn()"
        [type]="'primary'"
        [dataTestId]="'check-in-next-user'"
        data-private
        >Begin {{ nextUserName$ | async }}'s Check-In</app-button
      >
      <app-button
        *ngIf="canSkipToWaitingRoom$ | async"
        (click)="goToWaitingRoom()"
        [type]="'tertiary'"
        [dataTestId]="'skip-to-waiting-room'"
        >Skip to waiting room</app-button
      >
    </div>
  </div>
</ng-template>
