import { Directive, HostListener } from '@angular/core';
import { MatTooltip } from '@angular/material/tooltip';

@Directive({
  selector: '[appIconTooltip]',
})
export class IconTooltipDirective {

  constructor(private readonly tooltip: MatTooltip) {}

  @HostListener('click')
  clickEvent() {
    if (!this.tooltip) return;

    this.tooltip.toggle();
  }
}
