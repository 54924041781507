import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { v4 as uuid } from 'uuid';

import { CommissionSelectionSelectors } from 'src/app/features/commissions';
import { Commission } from 'src/app/features/commissions/models';
import { CommissionSelectionActions } from 'src/app/features/commissions/store';
import { PackageUser } from 'src/app/features/package-users';
import { RootStoreState } from 'src/app/store';

import { EndorsementSteps } from '../../models';
import { EndorsementsCreationActions } from '../../store';

@Component({
  selector: 'app-acknowledge-uploaded-signature',
  templateUrl: './acknowledge-uploaded-signature.component.html',
  styleUrls: ['./acknowledge-uploaded-signature.component.scss'],
})
export class AcknowledgeUploadedSignatureComponent implements OnInit, OnDestroy {
  @Input() user: PackageUser;
  @Output() stepComplete = new EventEmitter();

  formStep: UntypedFormGroup;
  commissionSubscription: Subscription;
  commission: Commission;

  constructor(private readonly store: Store<RootStoreState.State>) {
    this.formStep = new UntypedFormGroup({});
  }

  ngOnInit(): void {
    this.store.dispatch(CommissionSelectionActions.FetchSelectedCommission());
    this.commissionSubscription = this.store
      .select(CommissionSelectionSelectors.getSelectedCommissionDetails)
      .subscribe((commission) => {
        this.commission = commission;
      });
  }

  ngOnDestroy() {
    this.commissionSubscription.unsubscribe();
  }

  setSignatureAsUploaded() {
    this.store.dispatch(
      EndorsementsCreationActions.SetUploadedSignatureImageFromCommissionSignature({
        payload: this.commission.signature,
      })
    );
  }

  public goToNextStep(payload?: any) {
    this.setSignatureAsUploaded();
    this.stepComplete.emit();
  }
}
