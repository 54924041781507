import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { select, Store } from "@ngrx/store";
import { Observable } from "rxjs";
import { filter, first, map } from "rxjs/operators";
import { RootStoreState } from "../../../store";
import { DevicePackageValidation } from "../models";
import { DeviceGroupActions, DeviceGroupSelectors } from "../store";

@Injectable({ providedIn: 'root' })
export class DeviceCodeResolver {
  constructor(private readonly store: Store<RootStoreState.State>) { }

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<string> {

    this.store.dispatch(DeviceGroupActions.GetDeviceCode());

    return this.store.pipe(select(DeviceGroupSelectors.getVerifiedDeviceCode),
      filter<DevicePackageValidation>(d => !!d && !!d.deviceCode && d.isValidForPackage),
      first(),
      map(d => d.deviceCode));
  }
}
