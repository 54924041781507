import { createReducer, on } from '@ngrx/store';

import { SignerDetailsActions } from '../actions';
import { SignerDetailsState } from '../state';

export const signerDetailsReducers = createReducer(
  SignerDetailsState.initialState,
  on(
    SignerDetailsActions.FetchSignerDetailsStatusSuccessful,
    (state: SignerDetailsState.State, { payload }) => ({
      ...state,
      signerDetailsStatus: payload,
      validationErrors: [],
      areSignerDetailsSubmitted: false,
    })
  ),
  on(SignerDetailsActions.MultifactorVerificationSuccess, (state: SignerDetailsState.State) => ({
    ...state,
    signerDetailsStatus: {
      ...state.signerDetailsStatus,
      isRequiredToCompleteMfaChallenge: false,
    },
    validationErrors: [],
    areSignerDetailsSubmitted: false,
  })),
  on(SignerDetailsActions.SubmitSignerDetailsSuccess, (state: SignerDetailsState.State) => ({
    ...state,
    validationErrors: [],
    areSignerDetailsSubmitted: true,
  })),
  on(
    SignerDetailsActions.SubmitSignerDetailsError,
    (state: SignerDetailsState.State, { payload }) => ({
      ...state,
      validationErrors: payload,
    })
  ),
  on(SignerDetailsActions.FetchStates, (state: SignerDetailsState.State) => ({
    ...state,
  })),
  on(
    SignerDetailsActions.FetchStatesSuccessful,
    (state: SignerDetailsState.State, { payload }) => ({
      ...state,
      states: payload,
    })
  )
);
