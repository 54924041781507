import { Pipe, PipeTransform } from '@angular/core';

import { PackageUser } from '../models';

@Pipe({
  name: 'packageUserFirstName',
})
export class PackageUserFirstNamePipe implements PipeTransform {
  transform(packageUser: PackageUser, placeholderName = '') {
    return !!packageUser?.firstName ? packageUser.firstName : placeholderName;
  }
}
