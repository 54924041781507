import { DeviceUser } from '../../models';

export interface State {
  deviceCode: string;
  isValidForPackage: boolean;
  usersOnDevice: DeviceUser[];
  isParticipantRejoining: boolean;
  unauthorizedDeviceCodes: string[];
  rejoiningPackageUserGuids: string[];
  deviceUsersLoaded: boolean;
  isDeviceUsersLoading: boolean;
  isPinOrMfaComplete: boolean;
  clientHints: UADataValues;
}

export const initialState: State = {
  deviceCode: null,
  isValidForPackage: false,
  usersOnDevice: [],
  isParticipantRejoining: false,
  unauthorizedDeviceCodes: [],
  rejoiningPackageUserGuids: [],
  deviceUsersLoaded: true,
  isDeviceUsersLoading: false,
  isPinOrMfaComplete: false,
  clientHints: undefined,
};
