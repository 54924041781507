<img
  class="feedback-image"
  src="../../../assets/images/page-not-found.svg"
  alt=""
  data-testid="invalid-link-pic"
/>
<div class="message-container">
  <h1 class="message-container__header" data-testid="invalid-link-header">
    This link is not valid
  </h1>
  <p class="message-container__message" data-testid="invalid-link-text-1">
    It looks like the link you used to access this page is not valid. Check that you have correctly
    copied the entire url.
  </p>
  <p class="message-container__message" data-testid="invalid-link-text-2">
    If needed, please reach out to the link provider to ensure that you have the correct url.
  </p>
  <app-further-assistance [showDefaultClientInfo]="true"></app-further-assistance>
</div>
