//#region imports
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { SignalREffects, SignalRReducers } from './store';

//#endregion imports

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature('signalR', SignalRReducers.signalRReducer),
    EffectsModule.forFeature([SignalREffects.SignalREffects]),
  ],
  providers: [],
})
export class SignalRModule {}
