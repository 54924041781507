import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { Feature } from 'src/app/features/feature-management/models';
import { FeatureManagementService } from 'src/app/features/feature-management/services';

import { ModalsActions } from 'src/app/features/modals';
import { ChimeActions } from 'src/app/features/video-conference/chime';
import { VideoActions } from 'src/app/features/video/store/actions';
import { RootStoreState } from 'src/app/store';

import { SigningRoomActions } from '../../store/actions';

@Component({
  selector: 'app-complete-closing-modal',
  templateUrl: './complete-closing-modal.component.html',
  styleUrls: ['./complete-closing-modal.component.scss'],
})
export class CompleteClosingModalComponent implements OnInit {
  static identifier = 'CompleteClosingModalComponent';
  @Input() onSuccess: () => void;
  @Output() public continue = new EventEmitter<void>();

  protected isNewEndorsementApplicationEnabled: boolean;
  protected isChimeVideoEnabled: boolean;

  constructor(private readonly store: Store<RootStoreState.State>,
    private readonly featureManagementService: FeatureManagementService
    ) {}

  ngOnInit(): void {
    this.isNewEndorsementApplicationEnabled = this.featureManagementService.getIsFeatureEnabledWithCaching(Feature.NewEndorsementApplication);
    this.isChimeVideoEnabled = this.featureManagementService.getIsFeatureEnabledWithCaching(Feature.ChimeVideo);
  }

  completeSession() {
    this.store.dispatch(ModalsActions.ClearModalComponent());
    // TODO Story 63200: Remove ChimeVideo FeatureFlag
    if (this.isChimeVideoEnabled) {
      this.store.dispatch(ChimeActions.StopMeetingAndRecording());
    } else {
      this.store.dispatch(VideoActions.SetDisableAdditionalArchive());
      this.store.dispatch(VideoActions.StopArchive());
    }

    if (this.isNewEndorsementApplicationEnabled) {
      this.store.dispatch(SigningRoomActions.InitiateCompleteSigningRoomSession());
    } else {
      // TODO: User Story 50434: Remove Old Endorsement Application Logic
      this.store.dispatch(SigningRoomActions.AttemptRonSigningRoomPackageCompletion());
    }
  }

  backClick() {
    this.store.dispatch(ModalsActions.ClearModalComponent());
  }

  static openModal(store: Store<RootStoreState.State>) {
    store.dispatch(
      ModalsActions.SetStandaloneModalComponent({
        payload: {
          component: CompleteClosingModalComponent,
          shouldFade: true,
        },
      })
    );
  }
}
