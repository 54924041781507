import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { Client } from 'src/app/features/client/models';
import { RootStoreState } from 'src/app/store';
import { ClientSelectors } from 'src/app/features/client';

@Component({
  selector: 'app-certificate-password-limit-exceeded',
  templateUrl: './certificate-password-limit-exceeded.component.html',
  styleUrls: ['./certificate-password-limit-exceeded.component.scss'],
})
export class CertificatePasswordLimitExceededComponent implements OnInit {

  client$: Observable<Client>;

  constructor(private readonly store: Store<RootStoreState.State>) {}

  ngOnInit(): void {
    this.client$ = this.store.select(ClientSelectors.getClientSupportDetails);
  }
}
