<img
  class="feedback-image"
  src="../../../assets/images/hourglass.svg"
  alt=""
  data-testid="processing-pic"
/>
<div class="message-container">
  <h1 class="message-container__header" data-testid="processing-header">
    Your signing is still being prepared
  </h1>
  <p class="message-container__message" data-testid="processing-text">
    We’re still preparing your signing, please try again in a few minutes. To retry, use the reload
    button below.
  </p>
  <app-button [type]="'primary'" [dataTestId]="'processing-reload-button'" (click)="reload()"
    >Reload</app-button
  >
  <app-further-assistance [showDefaultClientInfo]="true"></app-further-assistance>
</div>
