import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { delayedRetry } from 'src/app/operators/delayed-retry.operator';

import { IdTokenResponse } from '../models';

@Injectable()
export class AuthenticationService {
  constructor(private readonly httpClient: HttpClient) {}

  public getVerifiedUserIdTokenHint(
    packageUserGuid: string,
    deviceCode: string
  ) {
    return this.httpClient
      .get<IdTokenResponse>(
        `authentication/packageUsers/${packageUserGuid}/devices/${deviceCode}/idToken`
      )
      .pipe(
        delayedRetry(1000, 2),
        catchError((res) => throwError(res))
      );
  }
}
