import { DOCUMENT } from '@angular/common';
import { Directive, ElementRef, HostListener, Inject, Renderer2 } from '@angular/core';

@Directive({
  selector: '[appParticipantListFluidMaxHeight]',
})
export class ParticipantListFluidMaxHeightDirective {
  constructor(
    @Inject(DOCUMENT) private readonly doc: Document,
    private readonly renderer: Renderer2,
    private readonly el: ElementRef
  ) {
    this.updateMaxHeight();
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.updateMaxHeight();
  }

  /**
   * Returns the offsetHeight including bottom/top margins of provided element
   */
  outerHeight(el: HTMLElement, doc: Document): number {
    return el
      ? ['bottom', 'top']
          .map((side) => parseInt(doc.defaultView.getComputedStyle(el)[`margin-${side}`]))
          .reduce((cur, acc) => cur + acc, el.offsetHeight)
      : 0;
  }

  /**
   * Returns the outerHeight of all elements selected from the provided nodelist
   */
  getOuterHeightOfElementsByNodeList(nodelistSelector: string, doc: Document): number {
    return Array.from(doc.querySelectorAll(nodelistSelector))
      .map((el: HTMLElement) => this.outerHeight(el, doc))
      .reduce((cur, acc) => cur + acc, 0);
  }

  handleHeightChange(doc: Document) {
    const main: HTMLElement = doc.querySelector('main');
    const signingDetails: HTMLElement = doc.querySelector('[data-sidebar-signing-details]');
    const oneRem = 16;

    const mainOuterHeight = this.outerHeight(main, doc);
    const signingDetailsOuterHeight = this.outerHeight(signingDetails, doc);
    const sidebarHeadingsOuterHeight = this.getOuterHeightOfElementsByNodeList(
      '[data-sidebar-heading]',
      doc
    );

    const maxHeight =
      mainOuterHeight -
      signingDetailsOuterHeight -
      sidebarHeadingsOuterHeight -
      oneRem;

    this.renderer.setStyle(this.el.nativeElement, 'max-height', `${maxHeight}px`);
  }

  updateMaxHeight(): void {
    requestAnimationFrame(() => {
      this.handleHeightChange(this.doc);
    });
  }
}
