import { Component, OnInit } from '@angular/core';

import { FeatureManagementService } from 'src/app/features/feature-management/services';
import { Feature } from '../../../feature-management/models';

import { EndorsementBase } from '../../models';

@Component({
  selector: 'app-checkbox-endorsement',
  templateUrl: './checkbox-endorsement.component.html',
  styleUrls: ['./checkbox-endorsement.component.scss'],
})
export class CheckboxEndorsementComponent extends EndorsementBase implements OnInit {
  constructor(
    private readonly featureManagementService: FeatureManagementService) {
    super();
  }

  protected isNewEndorsementApplicationEnabled: boolean;

  ngOnInit(): void {
    this.isNewEndorsementApplicationEnabled = this.featureManagementService.getIsFeatureEnabledWithCaching(Feature.NewEndorsementApplication);
  }

  get isChecked() {
    if (this.isNewEndorsementApplicationEnabled) {
      return (
        this.endorsementLocation?.statusCode === 'SIGNED'
      );
    } else {
      // TODO: User Story 50434: Remove Old Endorsement Application Logic
      return (
        this.endorsementLocation?.attempted ||
        this.endorsementLocation?.statusCode === 'SIGNED'
      );
    }
  }

  get notCheckable() {
    if (this.areAllEndorsementsSigned) {
      return true;
    }

    if (this.isNewEndorsementApplicationEnabled) {
      return this.notCheckableOnDevice;
    } else {
      // TODO: User Story 50434: Remove Old Endorsement Application Logic
      if (this.endorsementLocation?.isRequired) {
        return (
          this.endorsementLocation?.attempted ||
          this.endorsementLocation?.statusCode === 'SIGNED' ||
          !this.endorsementLocation?.isSignableInCurrentSession
        );
      }

      return (
        !this.endorsementLocation?.isSignableInCurrentSession ||
        this.endorsementLocation?.statusCode === 'SIGNED' ||
        (this.isChecked && this.notCheckableOnDevice)
      );
    }
  }

  get notCheckableOnDevice() {
    if (this.areAllEndorsementsSigned) {
      return true;
    }

    if (!this.isNewEndorsementApplicationEnabled && this.endorsementLocation?.isRequired) {
      return (
        !this.endorsementLocation?.isSignableOnDevice &&
        this.endorsementLocation?.statusCode !== 'SIGNED'
      );
    }

    return !this.endorsementLocation?.isSignableOnDevice || this.isEndorsementDisabled;
  }

  get visible() {
    if (this.isNewEndorsementApplicationEnabled) {
      return (
        !this.endorsementLocation?.isSignableInCurrentSession &&
        this.endorsementLocation?.statusCode !== 'SIGNED'
      );
    } else {
      // TODO: User Story 50434: Remove Old Endorsement Application Logic
      return (
        !this.endorsementLocation?.isSignableInCurrentSession &&
        !this.endorsementLocation?.attempted &&
        this.endorsementLocation?.statusCode !== 'SIGNED'
      );
    }
  }

  get shouldDisable() {
    return this.isEndorsementDisabled || this.notCheckable;
  }

  endorsementClicked($event: Event): void {
    $event.preventDefault();

    if (this.notCheckable || this.notCheckableOnDevice) {
      return;
    }

    if (this.isNewEndorsementApplicationEnabled) {
      if (this.endorsementLocation?.statusCode !== 'SIGNED') {
        this.signEndorsement.emit({
          endorsementLocationId: this.endorsementLocation.documentEndorsementLocationId,
          endorsementType: this.endorsementLocation.endorsementTypeCode,
          optional: !this.endorsementLocation.isRequired,
          packageUserGuid: this.endorsementLocation.packageUserGuid,
        });
      } else {
        this.eraseSignedEndorsement.emit({
          endorsementLocationId: this.endorsementLocation.documentEndorsementLocationId,
          endorsementType: this.endorsementLocation.endorsementTypeCode,
          optional: !this.endorsementLocation.isRequired,
          packageUserGuid: this.endorsementLocation.packageUserGuid,
        });
      }
    } else {
      // TODO: User Story 50434: Remove Old Endorsement Application Logic
      this.endorsementAttempted.emit({
        endorsementLocationId: this.endorsementLocation
          .documentEndorsementLocationId,
        endorsementType: this.endorsementLocation.endorsementTypeCode,
        optional: !this.endorsementLocation.isRequired,
        packageUserGuid: this.endorsementLocation.packageUserGuid,
      });
    }
  }
}
