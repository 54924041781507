//#region imports
import { Injectable } from '@angular/core';
import { LoadTokenOptions, SilentRequest } from '@azure/msal-browser';
import { AuthenticationScheme, ExternalTokenResponse } from '@azure/msal-common';
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';

import { of } from 'rxjs';
import { concatMap, filter, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';

import { DeviceGroupSelectors } from 'src/app/features/device-group';
import { PackageUsersActions, PackageUsersSelectors } from 'src/app/features/package-users';
import { PackagesActions } from 'src/app/features/packages/store/actions';
import { SIGNING_REDIRECT_STATE } from 'src/app/features/shared/constants';
import { ApplicationInsightsService } from 'src/app/services/application-insights.service';
import { RootStoreState } from 'src/app/store';
import { environment } from 'src/environments/environment';
import { IdTokenClaims, MsalFlow } from '../../models';
import { AuthenticationService, MsalAuthService } from '../../services';
import { MsalAuthActions } from '../actions';
import { MsalAuthSelectors } from '../selectors';

//#endregion imports

@Injectable()
export class MsalAuthEffects {
  msalAuthLogin$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(MsalAuthActions.Login),
        tap(() => this.msalAuthService.login())
      ),
    { dispatch: false }
  );

  msalAuthRestartLogin$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(MsalAuthActions.RestartLogin),
        tap(() => this.msalAuthService.loginRetry())
      ),
    { dispatch: false }
  );

  msalAuthFlowLogin$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(MsalAuthActions.FlowLogin),
        tap((action) => {
          if (action.payload.msalFlow === MsalFlow.Invite) {
            this.msalAuthService.firstTimeUserLogin(action.payload.idTokenHint);
          } else if (action.payload.msalFlow === MsalFlow.PinLogin) {
            this.msalAuthService.pinLogin(action.payload.idTokenHint);
          } else if (action.payload.msalFlow === MsalFlow.NonSigningAgentLogin) {
            this.msalAuthService.nonSigningAgentLogin(action.payload.idTokenHint);
          } else if (action.payload.msalFlow === MsalFlow.SigningAgentLogin) {
            this.msalAuthService.signingAgentLogin(action.payload.idTokenHint);
          } else if (action.payload.msalFlow === MsalFlow.NonSigningAgentAccountLogin) {
            this.msalAuthService.nonSigningAgentAccountLogin(action.payload.idTokenHint);
          }
        })
      ),
    { dispatch: false }
  );

  msalAuthLoginSuccessful$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MsalAuthActions.LoginSuccessful),
      concatMap((action) =>
        of(action).pipe(
          withLatestFrom(
            this.store.pipe(select(MsalAuthSelectors.getUser)),
            this.store.pipe(select(PackageUsersSelectors.getActivePackageUser)),
            this.store.pipe(select(PackageUsersSelectors.getActivePackageUserGuid))
          )
        )
      ),
      switchMap(([action, account, activePackageUser, activePackageUserGuid]) => {
        const claims = account.idTokenClaims as IdTokenClaims;
        const packageUserGuid = claims.extension_PackageUserGuids;

        if (
          packageUserGuid?.toLowerCase() === activePackageUserGuid?.toLowerCase() &&
          packageUserGuid?.toLowerCase() === activePackageUser?.packageUserGuid?.toLowerCase()
        ) {
          return [];
        }
        return [
          PackageUsersActions.SetActivePackageUserGuid({
            payload: { activePackageUserGuid: packageUserGuid },
          }),
        ];
      })
    )
  );

  msalAuthHandleRedirect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MsalAuthActions.HandleLoginRedirect),
      filter((action) => !this.msalAuthService.isForgotPasswordAuthority(action.payload.authority)),
      tap((action) => {
        this.msalAuthService.setActiveAccount(action.payload.user);
      }),
      map((action) => {
        const isPreSign = action.payload.redirectState !== SIGNING_REDIRECT_STATE;
        return PackagesActions.SetIsPreSign({ payload: { isPreSign } });
      })
    )
  );

  msalAuthHandleRedirectForgotPassword$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MsalAuthActions.HandleLoginRedirect),
      filter((action) => this.msalAuthService.isForgotPasswordAuthority(action.payload.authority)),
      map(() => MsalAuthActions.RestartLogin())
    )
  );

  msalAuthLogout$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(MsalAuthActions.Logout),
        tap(() => this.msalAuthService.logout())
      ),
    { dispatch: false }
  );

  refreshTokenForVerifiedUser$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(MsalAuthActions.RefreshTokenForVerifiedUser),
        concatMap((action) =>
          of(action).pipe(
            withLatestFrom(
              this.store.pipe(select(DeviceGroupSelectors.getDeviceCode)),
              this.store.pipe(select(MsalAuthSelectors.getUserGuid))
            )
          )
        ),
        switchMap(([action, deviceCode, currentUserGuid]) => {
          if (!!currentUserGuid) {
            return [MsalAuthActions.SetLoadingStatus({ payload: { isLoading: false } })];
          }

          return this.authService
            .getVerifiedUserIdTokenHint(action.payload.packageUserGuid, deviceCode)
            .pipe(
              tap((idTokenResponse) => {
                this.msalAuthService.reAuthorizeNonSigningAgent(
                  idTokenResponse.idTokenHint,
                  action.payload.packageUserGuid,
                  action.payload.startPage
                );
              })
            );
        })
      ),
    { dispatch: false }
  );

  msalAuthResetPassword$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(MsalAuthActions.ResetPassword),
        tap(() => this.msalAuthService.resetPassword())
      ),
    { dispatch: false }
  );

  msalAuthHandleAuthError$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MsalAuthActions.HandleAuthError),
      tap((action) =>
        this.applicationInsightsService.logException(action.payload, SeverityLevel.Error)
      ),
      switchMap((action) => {
        const authError = action.payload;

        if (this.msalAuthService.isAuthErrorTokenInvalid(authError)) {
          return [PackagesActions.RedirectInValidLink()];
        } else if (this.msalAuthService.isAuthErrorTokenExpired(authError)) {
          return [PackagesActions.RedirectExpiredLink()];
        } else if (this.msalAuthService.isAuthErrorForgottenPassword(authError)) {
          return [MsalAuthActions.ResetPassword()];
        } else if (this.msalAuthService.isAuthErrorCancelledMfa(authError)) {
          return [MsalAuthActions.RestartLogin()];
        } else if (this.msalAuthService.isErrorPopupFailure(authError)) {
          return [];
        }

        return [PackagesActions.RedirectGenericError()];
      })
    )
  );

  constructor(
    private readonly actions$: Actions,
    private readonly applicationInsightsService: ApplicationInsightsService,
    private readonly msalAuthService: MsalAuthService,
    private readonly authService: AuthenticationService,
    private readonly store: Store<RootStoreState.State>
  ) {}
}
