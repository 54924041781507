import {
  Component,
  Input,
  OnDestroy,
  OnInit
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import {
  select,
  Store
} from '@ngrx/store';
import {
  Observable,
  Subscription
} from 'rxjs';
import {
  map,
  tap
} from 'rxjs/operators';

import { PackageUser } from 'src/app/features/package-users';
import { IntervalTimer } from 'src/app/features/shared/util/interval-timer';
import { RootStoreState } from 'src/app/store';

import {
  MultiFactorChallengeOptions,
  MultiFactorCodeValidationStates
} from '../../models';
import { MultiFactorActions } from '../../store/actions';
import { MultiFactorSelectors } from '../../store/selectors';

@Component({
  selector: 'app-multi-factor-challenge-security-code',
  templateUrl: './multi-factor-challenge-security-code.component.html',
  styleUrls: ['./multi-factor-challenge-security-code.component.scss'],
})
export class MultiFactorChallengeSecurityCodeComponent implements OnInit, OnDestroy {
  @Input() public user: PackageUser;
  securityCode: UntypedFormControl;

  public codeValidationStates = MultiFactorCodeValidationStates;
  userCodeValidationState: MultiFactorCodeValidationStates = MultiFactorCodeValidationStates.hide;
  selectedMultiFactorChallengeDevice$: Observable<string>;
  attemptsRemaining$: Observable<number>;
  intervalTimer: IntervalTimer;
  wasAttempted = false;

  private challengeCreatedAtSubscription: Subscription;

  constructor(private readonly store: Store<RootStoreState.State>) {
    this.securityCode = new UntypedFormControl('');
  }

  ngOnInit() {
    this.selectedMultiFactorChallengeDevice$ = this.store.pipe(
      select(MultiFactorSelectors.getCurrentlySelectedChallengeType),
      map((mfaChallenge) =>
        mfaChallenge === MultiFactorChallengeOptions.EMAIL ? 'email' : 'phone'
      )
    );

    this.attemptsRemaining$ = this.store.pipe(select(MultiFactorSelectors.getAttemptsRemaining));

    this.intervalTimer = new IntervalTimer(30);
    this.challengeCreatedAtSubscription = this.store
      .pipe(
        select(MultiFactorSelectors.getChallengeCreatedAtDate),
        tap(() =>
          this.intervalTimer.start(
            () => this.userCodeValidationState = MultiFactorCodeValidationStates.hide
          )
        )
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.challengeCreatedAtSubscription?.unsubscribe();
    this.intervalTimer.cancel();
  }

  completeMultiFactorChallenge() {
    this.store.dispatch(
      MultiFactorActions.CompleteMultiFactorChallenge({
        payload: { challengeCode: this.securityCode.value },
      })
    );
  }

  resendMultiFactorChallenge() {
    this.handleValidationMessage();
    if (this.intervalTimer.getRemainingSeconds() <= 0) {
      this.store.dispatch(MultiFactorActions.ResendMultiFactorChallengeCode());
    }
  }

  resetMultiFactorChallengeSelection() {
    this.store.dispatch(MultiFactorActions.CancelMultiFactorChallenge());
  }

  private handleValidationMessage() {
    if (this.intervalTimer.getRemainingSeconds() >= 1) {
      this.userCodeValidationState = MultiFactorCodeValidationStates.invalid;
    } else {
      this.userCodeValidationState = MultiFactorCodeValidationStates.valid;
    }
  }
}
