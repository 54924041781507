<div class="waiting-on-other-participants-modal">
  <div class="waiting-on-other-participants-modal__header">
    <p>Waiting on other participants</p>
  </div>
  <div class="waiting-on-other-participants-modal__body">
    <p>Another participant is currently experiencing camera and/or microphone issues.</p>
    <p>
      Please be patient while they try to resolve the issue. You may need to
      <a (click)="refresh()">refresh</a> your page as well.
    </p>
    <p>Otherwise, you may need to <a (click)="cancel()">cancel the session.</a></p>
  </div>
  <div class="waiting-on-other-participants-modal__footer">
    Need help? Call <a href="tel:{{ phoneNumber }}">{{ phoneNumber }}</a>
  </div>
</div>
