<div class="generic-confirm-modal">
  <div class="generic-confirm-modal__body">
    <div>
      <p>All documents have been signed, you may now complete the session.</p>
    </div>
  </div>
  <div class="generic-confirm-modal__footer">
    <app-button type="primary" (click)="continueClick()" [dataTestId]="'complete-session-button'">
      <span>Complete Session</span>
    </app-button>
  </div>
</div>
