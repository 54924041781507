import { Component, OnChanges, SimpleChanges, OnInit, ElementRef, ViewChild } from '@angular/core';
import { isPropertyChangeValueNotNullOrUndefined } from 'src/app/features/shared/util';

import { FeatureManagementService } from 'src/app/features/feature-management/services';
import { Feature } from 'src/app/features/feature-management/models';

import { DocumentEndorsementLocation, EndorsementBase } from '../../models';

@Component({
  selector: 'app-free-text-endorsement',
  templateUrl: './free-text-endorsement.component.html',
  styleUrls: ['./free-text-endorsement.component.scss'],
})
export class FreeTextEndorsementComponent extends EndorsementBase implements OnInit, OnChanges {
  @ViewChild('freetextinput') inputElement: ElementRef;
  freeText = '';
  initialValue = '';
  isEditable = false;
  isEditing = false;
  emptyOnFocus: boolean;
  constructor(private readonly featureManagementService: FeatureManagementService) {
    super();
  }

  protected isNewEndorsementApplicationEnabled: boolean;

  ngOnInit() {
    this.isNewEndorsementApplicationEnabled =
      this.featureManagementService.getIsFeatureEnabledWithCaching(
        Feature.NewEndorsementApplication
      );
    this.updateEndorsementIsEditable();
    this.freeText = this.endorsementLocation?.endorsementValue ?? '';
    this.initialValue = this.endorsementLocation?.endorsementValue ?? '';
  }

  ngOnChanges(changes: SimpleChanges) {
    for (const propertyName in changes) {
      if (changes.hasOwnProperty(propertyName)) {
        switch (propertyName) {
          case 'endorsementLocation': {
            if (isPropertyChangeValueNotNullOrUndefined(propertyName, changes)) {
              this.updateEndorsementIsEditable();
              this.initialValue = this.endorsementLocation?.endorsementValue;
            }
            break;
          }
          case 'isEndorsementDisabled':
          case 'areAllEndorsementsSigned': {
            if (isPropertyChangeValueNotNullOrUndefined(propertyName, changes)) {
              this.isEditable = this.getIsEditable(this.endorsementLocation);
            }
            break;
          }
        }
      }
    }
  }

  updateEndorsementIsEditable(): void {
    this.isEditable = this.getIsEditable(this.endorsementLocation);
    this.isEditing = this.isNewEndorsementApplicationEnabled
      ? false
      : // TODO: User Story 50434: Remove Old Endorsement Application Logic
        this.isEditable && !this.endorsementLocation?.attempted;
  }

  editClicked(): void {
    this.isEditing = true;
    setTimeout(() => {
      this.inputElement?.nativeElement.focus();
    }, 0);
  }

  endorsementFocused(): void {
    this.emptyOnFocus = !this.freeText || this.freeText.trim().length === 0;
  }

  endorsementBlurred(): void {
    this.isEditing = false;
    // trim is needed to determine whether to sign or erase endorsement
    this.freeText = this.freeText.trim();

    if (
      (this.emptyOnFocus && this.freeText.trim().length === 0) ||
      this.freeText === this.initialValue
    ) {
      return;
    }

    if (this.isNewEndorsementApplicationEnabled) {
      if (!this.freeText && this.endorsementLocation?.statusCode === 'SIGNED') {
        this.eraseSignedEndorsement.emit({
          endorsementLocationId: this.endorsementLocation.documentEndorsementLocationId,
          endorsementType: this.endorsementLocation.endorsementTypeCode,
          packageUserGuid: this.endorsementLocation.packageUserGuid,
          optional: !this.endorsementLocation.isRequired,
        });
      } else {
        this.signEndorsement.emit({
          endorsementLocationId: this.endorsementLocation.documentEndorsementLocationId,
          endorsementType: this.endorsementLocation.endorsementTypeCode,
          data: this.freeText,
          packageUserGuid: this.endorsementLocation.packageUserGuid,
          optional: !this.endorsementLocation.isRequired,
        });
      }
    } else {
      // TODO: User Story 50434: Remove Old Endorsement Application Logic
      this.endorsementAttempted.emit({
        endorsementLocationId: this.endorsementLocation.documentEndorsementLocationId,
        data: this.freeText,
        endorsementType: this.endorsementLocation.endorsementTypeCode,
        packageUserGuid: this.endorsementLocation.packageUserGuid,
        optional: !this.endorsementLocation.isRequired,
      });
    }
  }

  private getIsEditable(endorsementLocation: DocumentEndorsementLocation): boolean {
    if (this.isNewEndorsementApplicationEnabled) {
      return (
        !this.areAllEndorsementsSigned &&
        endorsementLocation?.isSignableOnDevice &&
        !this.isEndorsementDisabled
      );
    } else {
      // TODO: User Story 50434: Remove Old Endorsement Application Logic)
      return (
        !this.areAllEndorsementsSigned &&
        endorsementLocation?.isSignableOnDevice &&
        endorsementLocation?.statusCode !== 'SIGNED' &&
        !this.isEndorsementDisabled
      );
    }
  }
}
