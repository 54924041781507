import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';

import { of } from 'rxjs';
import { catchError, map, mergeMap, switchMap, withLatestFrom } from 'rxjs/operators';
import { v4 as uuid } from 'uuid';

import { RootStoreState } from 'src/app/store';

import { NavigatorMediaDeviceWrapperService } from 'src/app/features/audio-video-connection-monitor/services';
import { AudioVideoConnectionMonitorSelectors } from 'src/app/features/audio-video-connection-monitor/store';

import { AudioVideoCheckService } from 'src/app/features/av-check/services';
import { AudioVideoCheckActions } from 'src/app/features/av-check/store/actions';
import { AudioVideoCheckSelectors } from 'src/app/features/av-check/store/selectors';

import { DeviceGroupSelectors } from '../../../device-group';
import { ModalsActions } from '../../../modals';
import { NotificationsActions, NotificationType } from '../../../notifications';
import { PackagesSelectors } from '../../../packages';

@Injectable()
export class AudioVideoCheckEffects {
  audioVideoCheckStarted$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AudioVideoCheckActions.AudioVideoCheckStarted),
      withLatestFrom(
        this.store.pipe(select(DeviceGroupSelectors.getDeviceCode)),
        this.store.pipe(select(PackagesSelectors.getActivePackageGuid))
      ),
      switchMap(([action, deviceCode, packageGuid]) => {
        return this.audioVideoCheckService.postStartedAudioVideoCheck(deviceCode, packageGuid).pipe(
          switchMap(() => [ModalsActions.HideLoadingSpinner()]),
          catchError((err) =>
            of(
              NotificationsActions.AddNotification({
                payload: {
                  exception: err,
                  notificationType: NotificationType.Error,
                  id: uuid(),
                  text: 'Failed to enter AV Check page',
                },
              }),
              ModalsActions.HideLoadingSpinner()
            )
          )
        );
      })
    )
  );

  saveSessionCapable$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AudioVideoCheckActions.SaveAuditSessionCapable),
      withLatestFrom(
        this.store.pipe(select(PackagesSelectors.getActivePackageGuid)),
        this.store.pipe(select(DeviceGroupSelectors.getDeviceCode))
      ),
      switchMap(([action, packageGuid, deviceCode]) => {
        return this.audioVideoCheckService
          .postSessionCapable({
            PackageGuid: packageGuid,
            DeviceCode: deviceCode,
          })
          .pipe(
            map(() => AudioVideoCheckActions.SaveSessionCapableSuccessful({ packageGuid: packageGuid })),
            catchError(() => of(AudioVideoCheckActions.SaveSessionCapableFailed()))
          );
      })
    )
  );

  setUserMediaStream$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AudioVideoCheckActions.SelectVideo),
      withLatestFrom(this.store.pipe(select(AudioVideoCheckSelectors.selectVideoInputDevices))),
      mergeMap(([{ video }, devices]: [{ video: MediaDeviceInfo }, MediaDeviceInfo[]]) =>
        this.navigatorMediaDeviceWrapperService
          .getUserMedia({
            video: { deviceId: video?.deviceId },
          })
          .pipe(
            map((mediaStream: MediaStream) =>
              devices.length
                ? AudioVideoCheckActions.SetMediaStream({ mediaStream })
                : AudioVideoCheckActions.GetUserMediaVideoSuccess()
            ),
            catchError((error) =>
              of(
                AudioVideoCheckActions.GetUserMediaVideoFailure({
                  error: new Error(`Video device not found! ${error?.message}`),
                })
              )
            )
          )
      )
    )
  );

  getUserMediaVideo$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AudioVideoCheckActions.GetUserMediaVideo),
      switchMap(() => {
        return this.navigatorMediaDeviceWrapperService
          .getUserMedia({ video: true })
          .pipe(
            map(() => AudioVideoCheckActions.GetUserMediaVideoSuccess()),
            catchError((error: Error) => of(AudioVideoCheckActions.GetUserMediaVideoFailure({ error: new Error(`Video device not found! ${error?.message}` ) })))
          );
      })
    );
  });

  getUserMediaAudio$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AudioVideoCheckActions.GetUserMediaAudio),
      switchMap(() => {
        return this.navigatorMediaDeviceWrapperService
          .getUserMedia({ audio: true })
          .pipe(
            map(() => AudioVideoCheckActions.GetUserMediaAudioSuccess()),
            catchError((error: Error) => of(AudioVideoCheckActions.GetUserMediaAudioFailure({ error: new Error(`Audio device not found! ${error?.message}` ) })))
          );
      })
    );
  });

  enumerateDevices$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AudioVideoCheckActions.GetUserMediaVideoSuccess, AudioVideoCheckActions.GetUserMediaAudioSuccess),
      switchMap(() =>
        this.navigatorMediaDeviceWrapperService
          .enumerateDevices()
          .pipe(map((devices) => AudioVideoCheckActions.EnumerateDevicesSuccess({ devices })))
      )
    );
  });

  enumerateDevicesSuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AudioVideoCheckActions.EnumerateDevicesSuccess),
      withLatestFrom(
        this.store.select(AudioVideoConnectionMonitorSelectors.getSelectedVideoDevice),
        this.store.select(AudioVideoConnectionMonitorSelectors.getSelectedAudioDevice),
        this.store.select(AudioVideoConnectionMonitorSelectors.getSelectedAudioOutputDevice),
      ),
      switchMap(
        ([{ devices }, selectedVideoDevice, selectedAudioDevice, selectedOutputDevice]: [
          { devices: MediaDeviceInfo[] },
          MediaDeviceInfo,
          MediaDeviceInfo,
          MediaDeviceInfo
        ]) => {
          const filterDevices = (kind: string) => devices.filter(device => device.kind === kind);
          const findSelectedDevice = (devices: MediaDeviceInfo[], selectedDevice: MediaDeviceInfo) =>
            devices.find(device => device.deviceId === selectedDevice?.deviceId);

          const videoInputDevices = filterDevices('videoinput');
          const audioInputDevices = filterDevices('audioinput');
          const audioOutputDevices = filterDevices('audiooutput');

          const selectedVideoInputDevice = findSelectedDevice(videoInputDevices, selectedVideoDevice);
          const selectedAudioInputDevice = findSelectedDevice(audioInputDevices, selectedAudioDevice);
          const selectedAudioOutputDevice = findSelectedDevice(audioOutputDevices, selectedOutputDevice);

          const video = selectedVideoInputDevice || videoInputDevices[0];
          const microphone = selectedAudioInputDevice || audioInputDevices[0];
          const speaker = selectedAudioOutputDevice || audioOutputDevices[0];

          return of(
            AudioVideoCheckActions.SelectVideo({ video }),
            AudioVideoCheckActions.SelectMicrophone({ microphone }),
            AudioVideoCheckActions.SelectSpeaker({ speaker })
          );
        }
      )
    );
  });

  constructor(
    private readonly actions$: Actions,
    private readonly audioVideoCheckService: AudioVideoCheckService,
    private readonly navigatorMediaDeviceWrapperService: NavigatorMediaDeviceWrapperService,
    private readonly store: Store<RootStoreState.State>
  ) {}
}
