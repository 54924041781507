<app-confirmation-modal>
  <ng-container subHeader>Are you sure you want to deny credentials?</ng-container>
  <ng-container content>Denying credentials will cancel the session.</ng-container>

  <ng-container actionButton>
    <button type="button" class="btn btn--outline-dark" (click)="this.backToIdVerificationView()">
      Go Back
    </button>
    <button type="button" class="btn btn--confirm" (click)="this.confirmDenial()">
      Deny and Cancel Session
    </button>
  </ng-container>
</app-confirmation-modal>
