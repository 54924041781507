<div class="generic-confirm-modal">
  <div class="generic-confirm-modal__header">
    <h3>Participant ID</h3>
  </div>
  <div class="generic-confirm-modal__body id-verify">
    <div class="main-container">
      <div class="left-column">
        <label for="participant">Choose a participant to view their ID.</label>
        <select
          class="left-column__dropdown"
          [ngModel]="selectedParticipant"
          name="participant"
          (ngModelChange)="onParticipantChange($event)"
        >
          <option
            *ngFor="let participant of participants$ | async"
            [ngValue]="participant"
            data-private
          >
            {{ participant.firstName }} {{ participant.lastName }}
          </option>
        </select>
        <label>View: </label>
        <mat-radio-group
          class="left-column__radio-button"
          [(value)]="selectedRadioButton"
          [(ngModel)]="selectedRadioButton"
          (ngModelChange)="viewIdImage()"
        >
          <mat-radio-button color="primary" value="1">Front</mat-radio-button>
          <mat-radio-button color="primary" value="2">Back</mat-radio-button>
        </mat-radio-group>
      </div>
      <div class="right-column" *ngIf="idImages$ | async as idImages">
        <div [style]="imageWrapperStyle" #imageWrapper class="right-column__image-wrapper">
          <img
            data-private
            *ngIf="showFrontImage"
            #idImage
            src="{{ idImages.front }}"
            class="right-column__id-image"
            alt=""
          />
          <img
            data-private
            *ngIf="showBackImage"
            #idImage
            src="{{ idImages.back }}"
            class="right-column__id-image"
            alt=""
          />
        </div>
        <div class="right-column__rotate-id-image">
          <app-button type="secondary" size="small" (click)="onRotate(idImage.nativeElement)">
            <span class="fas fa-undo-alt"></span>
            <span>Rotate Image</span>
          </app-button>
        </div>
      </div>
    </div>
    <app-button-container>
      <app-button type="primary" (click)="closeModal()" [dataTestId]="'modal-close-id-verify'"
        >Close</app-button
      >
    </app-button-container>
  </div>
</div>
