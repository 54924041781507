import {
  Component,
  ElementRef,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { distinctUntilChanged, filter, takeUntil, tap } from 'rxjs/operators';

import { RootStoreState } from 'src/app/store';
import { ViewerWidths } from '../../constants';
import { PackageDocument } from '../../models';

import { DocumentsActions, DocumentsSelectors } from '../../store';

// This component utilizes ResizeObserver which is not defined in the default typescirpt library.
// Github Issue #908 on microsoft/TypeScript-DOM-lib-generator
@Component({
  selector: 'app-document-viewer',
  templateUrl: './document-viewer.component.html',
  styleUrls: ['./document-viewer.component.scss'],
})
export class DocumentViewerComponent implements OnInit, OnDestroy {
  @Input() topOffset: number;
  @Input() bottomOffset: number;
  @Input() parentElement: ElementRef;
  @ViewChild('documentviewer', { static: true }) documentViewer!: ElementRef;

  activePackageDocument: PackageDocument;
  onDestoryNotifier = new Subject();

  // Necessary to ensure IPad has correct document size on first document load.
  clientWidthRetryHackLimit = 5;
  clientWidthRetryHackCounter = 0;

  constructor(private readonly store: Store<RootStoreState.State>) {}

  ngOnInit(): void {
    this.store
      .pipe(
        takeUntil(this.onDestoryNotifier),
        select(DocumentsSelectors.selectActivePackageDocument),
        filter<PackageDocument>(Boolean),
        distinctUntilChanged((x, y) => x?.packageDocumentId === y?.packageDocumentId),
        tap((doc) => this.documentChangeHandler(doc))
      )
      .subscribe();
    this.store.dispatch(
      DocumentsActions.SetAreParticipantDocumentsShown({
        payload: true,
      })
    );
  }

  documentChangeHandler(doc: PackageDocument) {
    this.activePackageDocument = doc;

    this.store.dispatch(
      DocumentsActions.LogDocumentViewed({
        payload: doc.packageDocumentId,
      })
    );

    this.parentElement?.nativeElement?.scrollTo(0, 0);
    window.scrollTo(0, 0);
    window.requestAnimationFrame(() => {
      this.resizeCallbackHandler();
    });
  }

  @HostListener('window:resize')
  resizeCallbackHandler() {
    const doc = this.documentViewer.nativeElement;
    const docWidth = doc.clientWidth;
    // Necessary to ensure IPad has correct document size on first document load.
    if (docWidth === 0 && this.clientWidthRetryHackCounter < this.clientWidthRetryHackLimit) {
      this.clientWidthRetryHackCounter++;
      setTimeout(this.resizeCallbackHandler.bind(this), 50);
      return;
    }

    this.clientWidthRetryHackCounter = 0;

    const scale = this.documentScale(docWidth);

    this.store.dispatch(
      DocumentsActions.SetViewerWidth({
        payload: { nextWidth: doc.clientWidth, scale },
      })
    );
  }

  documentScale(viewerWidth: number): number {
    return viewerWidth / ViewerWidths.pdfDocMaxWidth;
  }

  ngOnDestroy(): void {
    this.onDestoryNotifier.next(undefined);
    this.onDestoryNotifier.complete();
  }
}
