<div class="waiting-container">
  <div class="card">
      <img
        class="waiting-screen"
        src="../../../../../assets/images/waiting-screen.png"
        alt="waiting screen"
      />
    <p class="header">We're reviewing your information</p>
    <p class="body">
      The signing agent is looking over your identity verification results. When they are finished,
      your documents will be displayed.
    </p>
  </div>
</div>
