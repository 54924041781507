import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';

import { of } from 'rxjs';
import { catchError, concatMap, map, switchMap } from 'rxjs/operators';
import { v4 as uuid } from 'uuid';

import { ModalsActions, ModalSize } from 'src/app/features/modals';

import { PinVerificationComponent } from '../../components/pin-verification/pin-verification.component';
import { VerifyPinResponseModel } from '../../model';
import { PINValidationService } from '../../services';
import { PinValidationActions } from '../actions';
import {
  ExceptionType,
  NotificationsActions,
  NotificationType,
} from 'src/app/features/notifications';
import { PackageUsersActions } from 'src/app/features/package-users';

@Injectable()
export class PinValidationEffects {
  startPinChallenge$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(PinValidationActions.StartPinChallenge),
      concatMap((action) => of(action)),
      map((action) =>
        ModalsActions.SetStandaloneModalComponent({
          payload: {
            component: PinVerificationComponent,
            componentData: {
              participant: action.payload.participant,
              onPinVerified: () => {
                this.store.dispatch(ModalsActions.ClearModalComponent());
                this.store.dispatch(action.payload.onSuccessAction);
              },
            },
            shouldFade: true,
            modalSize: ModalSize.small,
          },
        })
      )
    );
  });

  pinVerification$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(PinValidationActions.VerifyPin),
      concatMap((action) => of(action)),
      switchMap((action) => {
        return this.pinValidationService
          .verification(action.payload.pin, action.payload.packageUserGuid)
          .pipe(
            map((response: VerifyPinResponseModel) =>
              response.isPinValid
                ? PinValidationActions.PinVerificationPass({
                    payload: {
                      pinVerification: response,
                      packageUserGuid: action.payload.packageUserGuid,
                    },
                  })
                : PinValidationActions.PinVerificationFail({
                    payload: {
                      pinVerification: response,
                      packageUserGuid: action.payload.packageUserGuid,
                    },
                  })
            ),
            catchError((error: Error) => of(PinValidationActions.PinVerificationError({ error })))
          );
      })
    );
  });

  pinVerificationFail$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(PinValidationActions.PinVerificationFail),
      switchMap((action) => {
        const remainingAttempts = action.payload.pinVerification.attemptsRemaining;

        if (remainingAttempts === 0) {
          return [
            PinValidationActions.ShowContactSupport(),
            PinValidationActions.SetPinVerificationMessage({
              payload: { message: 'Your account is locked, please call Clear Sign Support.' },
            }),
          ];
        }

        return [
          PinValidationActions.HideContactSupport(),
          PinValidationActions.SetPinVerificationMessage({
            payload: {
              message: `Invalid Password. ${remainingAttempts} ${
                remainingAttempts === 1 ? 'attempt' : 'attempts'
              } remaining.`,
            },
          }),
        ];
      })
    );
  });

  showPinVerificationErrorNotification$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(PinValidationActions.PinVerificationError),
      map((action) =>
        NotificationsActions.AddNotification({
          payload: {
            exception: action.error,
            text: 'Something went wrong when trying to verify your identity. Please try again.',
            notificationType: NotificationType.Error,
            exceptionType: ExceptionType.DisplayMessage,
            id: uuid(),
            logInAppInsights: true,
          },
        })
      )
    );
  });

  loadPackageUsersIfNotLoaded$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(PinValidationActions.PinVerificationOpened),
      map(() =>
        PackageUsersActions.FetchPackageUsers({
          payload: { ignoreIfLoaded: true },
        })
      )
    );
  });

  constructor(
    private readonly actions$: Actions,
    private readonly pinValidationService: PINValidationService,
    private readonly store: Store
  ) {}
}
