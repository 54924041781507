import { AuthError, EventMessage, EventType } from '@azure/msal-browser';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

export function filterAuthError(): (src: Observable<EventMessage>) => Observable<AuthError> {
  return (src: Observable<EventMessage>) =>
    src.pipe(
      filter(
        (msg: EventMessage) =>
          (msg.eventType === EventType.LOGIN_FAILURE ||
            msg.eventType === EventType.ACQUIRE_TOKEN_FAILURE) &&
          msg.error &&
          msg.error instanceof AuthError
      ),
      map((msg: EventMessage) => msg.error as AuthError)
    );
}

