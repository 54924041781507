import { HttpErrorResponse } from '@angular/common/http';

import { DEFAULT_APPLICATION_PHONE_NUMBER } from 'src/app/features/shared/constants';

import { MultiFactorErrorType } from './multi-factor-error-type.enum';
import { MultiFactorErrorLogMessage, MultiFactorErrorUserMessage } from './multi-factor.constants';

export class MultiFactorChallengeError extends Error {
  constructor(
    public readonly httpError: HttpErrorResponse,
    public readonly errorType: MultiFactorErrorType
  ) {
    super(httpError.message);
    Object.setPrototypeOf(this, MultiFactorChallengeError.prototype);
  }

  public get userFriendlyMessage() {
    return (
      MultiFactorErrorUserMessage[this.errorType] ??
      MultiFactorErrorUserMessage[MultiFactorErrorType.Unknown]
    );
  }

  public get errorLogText() {
    return (
      MultiFactorErrorLogMessage[this.errorType] ??
      MultiFactorErrorLogMessage[MultiFactorErrorType.Unknown]
    );
  }

  public get errorMessageFromResponse() {
    return typeof this.httpError.error === 'string' || this.httpError.error instanceof String
      ? this.httpError.error
      : `Something went wrong, please contact support at ${DEFAULT_APPLICATION_PHONE_NUMBER}.`;
  }
}
