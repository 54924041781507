<mat-expansion-panel
  class="mat-expansion-panel"
  data-testid="av-check-video-select"
  [expanded]="true"
  hideToggle
>
  <mat-expansion-panel-header>
    <mat-panel-title data-testid="av-check-video-title">
      <mat-icon class="material-icons-outlined">videocam</mat-icon> Camera
    </mat-panel-title>
    <mat-panel-description>
      <ng-container *ngTemplateOutlet="infoIconContainer" />
    </mat-panel-description>
  </mat-expansion-panel-header>
  <div class="mat-expansion-panel__body">
    <mat-form-field subscriptSizing="dynamic">
      <mat-label data-testid="av-check-camera-select-label">Select Camera</mat-label>
      <mat-select
        [value]="audioVideoCheckService.selectVideoDevice()?.label || ''"
        [disabled]="!audioVideoCheckService.videoInputDevices().length"
      >
        <mat-option value="" (click)="audioVideoCheckService.clearVideo()">
          No Camera Selected
        </mat-option>
        @for (inputDevice of audioVideoCheckService.videoInputDevices(); track inputDevice) {
        <mat-option
          [value]="inputDevice.label"
          (click)="audioVideoCheckService.selectVideo(inputDevice)"
        >
          {{ inputDevice.label }}
        </mat-option>
        }
      </mat-select>
    </mat-form-field>
    <ng-container *ngTemplateOutlet="infoIconContainer" />
  </div>
  @if (audioVideoCheckService.hasUserMediaVideoFailure() || !audioVideoCheckService.hasVideo()) {
  <app-av-check-guide
    [showButton]="audioVideoCheckService.isBrowserDetected"
    [(showDetails)]="showVideoGuideDetails"
  >
    <p>
      Hi there! It looks like your camera might be blocked by your browser. Let's get this sorted
      out.
    </p>

    @if (showVideoGuideDetails) { @if (hasLoadingFailure()) {
    <p>
      We're having trouble accessing your camera. Please make sure your camera is connected and try
      again.
    </p>
    } @else { @if (isLoading()) {
    <div class="loading-container">
      <div class="clearSign-skeleton clearSign-skeleton--has-pulse clearSign-skeleton--text"></div>
    </div>
    } @else {
    <app-gadget-guide-response
      [gadgetGuide]="gadgetGuideServiceCameraResponse()"
    ></app-gadget-guide-response>
    } } }
  </app-av-check-guide>
  }
</mat-expansion-panel>

<ng-template #infoIconContainer>
  <div class="mat-expansion-panel__info-icon-container">
    @if (audioVideoCheckService.hasVideo()) {
    <img
      src="./assets/images/check_circle_green.svg"
      alt="Green circle check"
      role="img"
      title="Camera Selection Success"
    />
    } @else {
    <app-info-icon
      alt="Red circle exclamation mark error icon"
      role="img"
      title="Camera Selection Error"
    />
    }
  </div>
</ng-template>
