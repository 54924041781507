// TODO: User Story 50434: Remove Old Endorsement Application Logic - delete file
import { EndorsementType } from './endorsement-types';

export interface OutgoingEndorsementAttempt {
  endorsementLocationId: number;
  endorsementType: EndorsementType;
  data?: any;
  optional?: boolean;
  packageUserGuid: string;
  certificatePassword?: string;
}

export interface IncomingEndorsementAttempt {
  endorsementLocationId: number;
  packageUserGuid?: string;
}

export interface EndorsementApplication {
  endorsementLocationId: number;
  packageUserGuid?: string;
}
