<!--TODO: User Story 50434: Remove Old Endorsement Application Logic-->
<button [appRemoveIfCachedFeatureEnabled]="'NewEndorsementApplication'"
        class="stamp-endorsement__button"
        *ngIf="
    endorsementLocation?.statusCode !== 'SIGNED' &&
      !endorsementLocation?.attempted;
    else endorsementImageTemplate
  "
        (click)="endorsementClicked()"
        [hidden]="!endorsementLocation?.isSignableInCurrentSession"
        [tabindex]="disableFocus ? -1 : 0"
        [disabled]="isEndorsementDisabled">

  <div *ngIf="!isEndorsementDisabled">Stamp<span class="fas fa-stamp stamp-endorsement__icon"></span></div>
</button>

<ng-template #endorsementImageTemplate>
  <div [appRemoveIfCachedFeatureEnabled]="'NewEndorsementApplication'">
    <img class="stamp-endorsement__image"
         [src]="'data:image/png;base64,' + endorsementImage?.endorsementImage"
         alt="notary stamp"
         data-private />
  </div>
</ng-template>
<!--END TODO-->

<button [appRemoveIfCachedFeatureDisabled]="'NewEndorsementApplication'"
        class="stamp-endorsement__button"
        *ngIf="
    endorsementLocation?.statusCode !== 'SIGNED'
    else endorsementImageTemplateNew
  "
        appDebounceClick (debouncedClick)="endorsementClicked()" [debounceTimer]="500"
        [hidden]="!endorsementLocation?.isSignableInCurrentSession"
        [tabindex]="disableFocus ? -1 : 0"
        [disabled]="isEndorsementDisabled">

  <div *ngIf="!isEndorsementDisabled">Stamp<span class="fas fa-stamp stamp-endorsement__icon"></span></div>
</button>

<ng-template #endorsementImageTemplateNew>
  <div [appRemoveIfCachedFeatureDisabled]="'NewEndorsementApplication'"
       class="stamp-endorsement__image-wrapper">
    <img class="stamp-endorsement__image"
         [src]="'data:image/png;base64,' + endorsementImage?.endorsementImage"
         alt="notary stamp"
         data-private />
  </div>
</ng-template>
