import { Injectable } from '@angular/core';
import { IDeviceValidatorService } from './IDeviceValidatorService';

@Injectable({
  providedIn: 'root'
})
export class HybridDeviceValidatorService implements IDeviceValidatorService {

  constructor() { }

  isBrowserSupported(userAgent: string): boolean {
    return !!userAgent;
  }
}
