<app-card borderStyle="line">
  <div
    class="signings-monthly"
    *ngIf="(totalMonthlyClosings$ | async) !== undefined; else showSkeleton"
  >
    <img class="signings-monthly__icon" src="../../../../../assets/images/lightbulb.svg" alt="" />
    <p class="signings-monthly__text">
      <b>{{ totalMonthlyClosings$ | async }} others</b> have completed an online notarization in the
      last month.
    </p>
  </div>
  <ng-template #showSkeleton>
    <div class="signings-monthly skeleton">
      <div class="skeleton__icon">
        <img src="../../../../../assets/images/cross.svg" alt="" />
      </div>
      <div class="skeleton__container skeleton">
        <div class="skeleton__text skeleton__text--line1"></div>
        <div class="skeleton__text skeleton__text--line2"></div>
      </div>
    </div>
  </ng-template>
</app-card>
