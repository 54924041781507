import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { SharedModule } from 'src/app/features/shared';

import {
  AddressInformationComponent,
  CredentialDenialConfirmationModalComponent,
  EnlargeIdModalComponent,
  IdImagesComponent,
  IdVerificationResultsComponent,
  KbaResultsComponent
} from './components';
import {
  ParticipantVerificationComponent,
  WaitingScreenComponent
} from './containers';
import { ParticipantVerificationService } from './services';
import {
  ParticipantVerificationEffects,
  ParticipantVerificationReducers
} from './store';
import { CredentialRejoinDenialConfirmationModalComponent } from './components/credential-rejoin-denial-confirmation-modal/credential-rejoin-denial-confirmation-modal.component';

@NgModule({
  declarations: [
    ParticipantVerificationComponent,
    IdImagesComponent,
    KbaResultsComponent,
    IdVerificationResultsComponent,
    AddressInformationComponent,
    WaitingScreenComponent,
    EnlargeIdModalComponent,
    CredentialDenialConfirmationModalComponent,
    CredentialRejoinDenialConfirmationModalComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    ReactiveFormsModule,
    StoreModule.forFeature(
      'participantVerification',
      ParticipantVerificationReducers.Reducers.participantVerificationReducer
    ),
    EffectsModule.forFeature([
      ParticipantVerificationEffects.ParticipantVerificationEffects,
    ]),
  ],
  providers: [ParticipantVerificationService],
  exports: [ParticipantVerificationComponent, WaitingScreenComponent],
})
export class ParticipantVerificationModule {}
