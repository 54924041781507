import { Input, Output, EventEmitter, Directive } from '@angular/core';
import { DocumentEndorsementLocation } from './document-endorsement-location.model';
import { EndorsementImage, SystemEndorsementImage, UniqueEndorsementImage } from './endorsement-image';
import { OutgoingEndorsementAttempt } from './endorsement-attempt.model';
import { OutgoingEraseSignedEndorsement, OutgoingSignEndorsement } from './endorsement-process.model';

@Directive()
export class EndorsementBase {
  @Input() endorsementLocation: DocumentEndorsementLocation;
  @Input() endorsementImage: EndorsementImage | UniqueEndorsementImage | SystemEndorsementImage;
  @Input() hideIfSigned: boolean;
  @Input() disableFocus: boolean;
  @Input() isEndorsementDisabled: boolean;
  @Input() areAllEndorsementsSigned: boolean;
  // TODO: User Story 50434: Remove Old Endorsement Application Logic
  @Output() endorsementAttempted: EventEmitter<
    OutgoingEndorsementAttempt
  > = new EventEmitter();
  @Output() signEndorsement: EventEmitter<
    OutgoingSignEndorsement
  > = new EventEmitter();
  @Output() eraseSignedEndorsement: EventEmitter<
    OutgoingEraseSignedEndorsement
  > = new EventEmitter();
}
