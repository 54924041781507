import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import { SignalRService } from 'src/app/features/signal-r';
import { RootStoreState } from 'src/app/store';
import { CheckInActions } from '../../check-in';

import { ModalsActions } from '../../modals';
import { PackageUsersActions } from '../../package-users';
import { VideoActions } from '../../video/store/actions';
import { SessionEndStatusResponse } from '../models';
import { RonActions } from '../store/actions';

@Injectable()
export class RonService {
  private listenerConfigured = false;

  constructor(
    private readonly http: HttpClient,
    private readonly signalRService: SignalRService,
    private readonly store: Store<RootStoreState.State>
  ) {}

  public getSessionStatus(packageGuid: string) {
    return this.http.get<SessionEndStatusResponse>(`signingRoom/packages/${packageGuid}/status`);
  }

  public configureListeners() {
    if (!this.listenerConfigured) {
      this.listenerConfigured = true;
      this.signalRService.hubConnection.on('ReceiveEndSessionStarted', () => {
        this.store.dispatch(ModalsActions.ClearModalComponent());
        this.store.dispatch(VideoActions.StopVideo());
        this.store.dispatch(RonActions.NavigateToSessionEnding());
      });

      this.signalRService.hubConnection.on('ReceiveEndSessionCompleted', () => {
        this.store.dispatch(RonActions.SetEndSessionCompleted());
      });

      this.signalRService.hubConnection.on('ReceiveEndSessionFailed', () => {
        this.store.dispatch(RonActions.SetEndSessionFailure());
      });

      this.signalRService.hubConnection.on('PackageUserCheckInStatusUpdated', (data) => {
        this.store.dispatch(
          PackageUsersActions.PackageUserCheckInStatusUpdated({
            payload: { packageUserGuid: data.packageUserGuid, checkInStatus: data.checkInStatus },
          })
        );
      });

      this.signalRService.hubConnection.on('WaitingRoomViewedEvent', () => {
        this.store.dispatch(CheckInActions.FetchWaitingRoomViewedByParticipants());
      });
    }
  }
}
