import { createAction, props } from '@ngrx/store';

import { SessionEndStatusResponse } from '../../models';

export const GetSessionStatuses = createAction('[Kron] Get End Session Status Aggregates');

export const NavigateSigningSessionComplete = createAction(
  '[Kron] Navigate to Signing Session Complete'
);

export const NavigateToSessionEnding = createAction('[Kron] Navigate to Session Ending');

export const SetEndSessionCompleted = createAction('[Kron] Set End Session Completed');

export const SetEndSessionFailure = createAction('[Kron] Set End Session Failure');

export const SetEndSessionSuccessful = createAction('[Kron] Set End Session Successful');

export const SetSessionStatuses = createAction(
  '[Kron] Set End Session Status Aggregates',
  props<{ payload: SessionEndStatusResponse }>()
);

export const SigningSessionStarted = createAction('[Kron] Signing Session Started');

export const EnterSigningRoom = createAction('[Kron] Enter Signing Room');
