<img
  class="feedback-image"
  src="../../../assets/images/signed-docs.svg"
  alt=""
  data-testid="completed-pic"
/>
<div class="message-container">
  <h1 class="message-container__header" data-testid="completed-header">
    The Signing has been completed
  </h1>
  <p class="message-container__message" data-testid="completed-text">
    This Signing has been completed and can no longer be accessed through this link.
  </p>
  <app-further-assistance [showDefaultClientInfo]="true"></app-further-assistance>
</div>
