import { Component, inject, Input } from '@angular/core';

import { Feature } from 'src/app/features/feature-management/models';
import { FeatureManagementService } from 'src/app/features/feature-management/services';

@Component({
  selector: 'app-audio-video-modal-switch',
  templateUrl: './audio-video-modal-switch.component.html',
})
export class AudioVideoModalSwitchComponent {
  static identifier = 'AudioVideoModalSwitchComponent';

  private readonly featureManagementService = inject(FeatureManagementService);

  @Input() shouldRefreshAfterSettingApplication = false;

  isNewAVCheckPage = this.featureManagementService.getIsFeatureEnabledWithCaching(
    Feature.AVCheckPageV2
  );
}
