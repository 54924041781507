import { Directive, ElementRef, Input, OnDestroy, OnInit } from '@angular/core';
import Popper, { Data, PopperOptions } from 'popper.js';

@Directive({ selector: '[appDocumentSelectorPopover]' })
export class DocumentSelectorPopoverDirective implements OnInit, OnDestroy {
    @Input() target: HTMLElement;

    private popper: Popper;
    private readonly defaultConfig: PopperOptions = {
        placement: 'bottom',
        positionFixed: true,
        removeOnDestroy: true,
        modifiers: {
            computeWidthStyle: {
                enabled: true,
                order: 851, // Should run after computeStyles
                fn: this.computeWidthStyle
            }
        },
    };

    constructor(private readonly el: ElementRef) {}

    ngOnInit(): void {
        const reference = this.el.nativeElement;
        this.popper = new Popper(reference, this.target, this.defaultConfig);
    }

    ngOnDestroy(): void {
        if (!this.popper) {
            return;
        }

        this.popper.destroy();
    }

    computeWidthStyle(data: Data, options: Object): Data {
        const reference = data.offsets.reference;
        const width = Math.round(reference.width - 2);
        const top = Math.round(reference.top + reference.height);

        data.styles.width = `${width}px`;
        data.styles.transform = `translate3d(${reference.left}px, ${top}px, 0)`;

        return data;
    }
}
