import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { AudioVideoConnectionMonitorModule } from 'src/app/features/audio-video-connection-monitor';
import { SharedModule } from 'src/app/features/shared';

import { NavigatorMediaDeviceWrapperService } from '../audio-video-connection-monitor/services';

import { AudioVideoBlockedErrorModalComponent, AudioVideoCheckComponent } from './components';
import { AudioVideoCheckV2Component } from './containers/audio-video-component-check-v2/audio-video-check-v2.component';

import { AudioVideoCheckService } from './services/audio-video-check.service';
import { MicCheckService } from './services/mic-check.service';
import { SpeakerCheckService } from './services/speaker-check.service';

import { AudioVideoCheckEffects } from './store';
import { AudioVideoCheckReducers } from './store/reducers';

@NgModule({
  declarations: [AudioVideoBlockedErrorModalComponent, AudioVideoCheckComponent],
  imports: [
    SharedModule,
    CommonModule,
    StoreModule.forFeature(
      'audioVideoCheck',
      AudioVideoCheckReducers.Reducers.audioVideoCheckReducer
    ),
    AudioVideoConnectionMonitorModule,
    ReactiveFormsModule,
    EffectsModule.forFeature([AudioVideoCheckEffects.AudioVideoCheckEffects]),
    AudioVideoCheckV2Component,
  ],
  providers: [
    AudioVideoCheckService,
    MicCheckService,
    NavigatorMediaDeviceWrapperService,
    SpeakerCheckService,
  ],
  exports: [AudioVideoCheckComponent, AudioVideoCheckV2Component],
})
export class AvCheckModule {}
