<div class="container" *ngIf="currentParticipant$ | async as currentParticipant">
  <div class="main-header" data-private>
    {{ currentParticipant.firstName }} {{ currentParticipant.lastName }} ID Verification Results
  </div>
  <app-id-images
    *ngIf="
      (idVerificationAndUserAddressResults$ | async)
        .latestUserAddressInformationIdVerificationResults as idVerifyResult
    "
    [idImages]="idVerifyResult.idImages"
  ></app-id-images>

  <div
    class="address-information-container"
    *ngIf="
      (idVerificationAndUserAddressResults$ | async)
        .latestUserAddressInformationIdVerificationResults as userAndIdVerifyResultAddressInfo
    "
  >
    <app-address-information
      class="card"
      [addressInformation]="
        userAndIdVerifyResultAddressInfo.userAddressInformationIdVerificationResult
          .userAddressInformation
      "
    ></app-address-information>
    <app-address-information
      class="card"
      [addressInformation]="
        userAndIdVerifyResultAddressInfo.userAddressInformationIdVerificationResult
          .idVerificationResult
      "
    ></app-address-information>
  </div>

  <app-id-verification-results
    class="id-verify-results"
    *ngIf="
      (idVerificationAndUserAddressResults$ | async)
        .latestUserAddressInformationIdVerificationResults
        .userAddressInformationIdVerificationResult.idVerificationResult as idVerifyResults
    "
    [idVerifyResults]="idVerifyResults"
  ></app-id-verification-results>

  <app-kba-results
    class="kba-status-container"
    *ngIf="(idVerificationAndUserAddressResults$ | async).latestKbaResult as kbaResults"
    [kbaResults]="kbaResults"
  ></app-kba-results>

  <div class="bottom-container">
    <div class="important-message">
      <div>
        <img src="../../../assets/images/blue-bell.svg" alt="blue information bell" />
      </div>
      <div class="important-message__body">
        <div class="important-message__header">Important</div>
        <br />
        <div>
          Please review the credential image(s) and all of the information provided above to either
          approve or deny the credential uploaded by the signer as sufficient for purposes of
          establishing the signer's identity.
        </div>
      </div>
    </div>
    <div class="button-container">
      <button
        *ngIf="isRejoiningParticipant$ | async"
        (click)="denyRejoiningParticipant()"
        class="button-container__deny"
      >
        Deny Rejoining Participant
      </button>
      <button
        *ngIf="(isRejoiningParticipant$ | async) === false"
        (click)="denyParticipant()"
        class="button-container__deny"
      >
        Deny Credentials
      </button>
      <button
        *ngIf="isRejoiningParticipant$ | async"
        (click)="approveRejoiningParticipant()"
        class="button-container__approve"
      >
        Approve Rejoining Participant
      </button>
      <button
        *ngIf="(isRejoiningParticipant$ | async) === false"
        (click)="approveParticipant()"
        class="button-container__approve"
      >
        Approve Credentials
      </button>
    </div>
  </div>
</div>
