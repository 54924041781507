import { Injectable } from "@angular/core";

import { Observable } from "rxjs";

import { FeatureManagementService } from "../services";
import { Feature } from "../models"

@Injectable({ providedIn: 'root' })
export class FeatureManagementResolver {
  constructor(private readonly featureManagementService: FeatureManagementService) { }

  resolve(): Observable<Map<Feature, boolean>> {
    return this.featureManagementService.getFeatureFlags();
  }
}
