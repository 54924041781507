import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { PackageUser } from 'src/app/features/package-users';

import { LobbyViewedRequest } from '../models';

@Injectable({
  providedIn: 'root',
})
export class LobbyService {
  constructor(private readonly http: HttpClient) {}

  public getLobbyDetails(packageGuid: string) {
    return this.http.get<PackageUser[]>(`tasks/checkin/status/${packageGuid}`);
  }

  public updateLobbyViewed(packageGuid: string, devicecode: string, packageUserGuid: string) {
    const lobbyViewedRequest: LobbyViewedRequest = {
      deviceCode: devicecode,
      packageUserGuid: packageUserGuid,
    };

    return this.http.put(`packages/${packageGuid}/lobbyViewed`, lobbyViewedRequest);
  }
}
