import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { filter, first, switchMap } from 'rxjs/operators';
import { RootStoreState } from 'src/app/store';
import { TaskCode } from '../../user-tasks/models';
import { UserTasksActions, UserTasksSelectors } from '../../user-tasks/store';

@Injectable({
  providedIn: 'root'
})
export class CanActivatePresignGuard {

  constructor(private readonly store: Store<RootStoreState.State>,
    private readonly router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {

    // Check if the user has the 'SigningRoomCheckIn' task type.
    // If he doesn't, he can't presign
    this.store.dispatch(UserTasksActions.ClearActiveUserTasks());
    this.store.dispatch(UserTasksActions.FetchActiveUserTasks());

    return this.store.pipe(
      select(UserTasksSelectors.getActiveUserTasks),
      filter(userTasks => !!userTasks),
      switchMap(userTasks => {

        if(!userTasks.find(task => task.taskCode === TaskCode.SigningRoomCheckIn)) {
          this.router.navigate(['/consumer-portal']);
          return of(false);
        }

        return of(true);
      }),
      first()
    );
  }
}
