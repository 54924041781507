import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { MsalAuthActions } from 'src/app/features/msal-auth';

import { NotificationsActions, NotificationType } from 'src/app/features/notifications';
import { ExceptionType } from 'src/app/features/notifications/models';
import { PackagesSelectors } from 'src/app/features/packages';
import { RootStoreState } from 'src/app/store';
import { v4 as uuid } from 'uuid';

import { ClientService } from '../../services';
import { ClientActions } from '../actions';
import { ClientSelectors } from '../selectors';

//#endregion imports

@Injectable()
export class ClientEffects {

  loadClientForPackage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ClientActions.LoadClientForPackage),
      switchMap((action) =>
        this.clientService.getClientForPackage(action.payload.packageGuid)
          .pipe(
            switchMap((client) => {
              return of(
                MsalAuthActions.SetClientIDPCode({
                  payload: { idpCode: client.idpCode },
                }),
                ClientActions.SetClientForPackage({
                  payload: { client },
                }),
              );
            }
            ),
            catchError((err) =>
              of(
                NotificationsActions.AddNotification({
                  payload: {
                    exception: err,
                    notificationType: NotificationType.Error,
                    id: uuid(),
                    text: 'Failed to Load Client Information',
                    exceptionType: ExceptionType.Other,
                  },
                }),
              )
            )
          )
      )
    )
  );

  loadClientForUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ClientActions.LoadClientForUser),
      switchMap((action) =>
        this.clientService
          .getClientForUser()
          .pipe(
            switchMap((client) => {
              return of(
                MsalAuthActions.SetClientIDPCode({
                  payload: { idpCode: client.idpCode },
                }),
                ClientActions.SetClientForPackage({
                  payload: { client },
                }),
              );
            }
            ),
            catchError((err) =>
              of(
                NotificationsActions.AddNotification({
                  payload: {
                    exception: err,
                    notificationType: NotificationType.Error,
                    id: uuid(),
                    text: 'Failed to Load Client Information',
                    exceptionType: ExceptionType.Other,
                  },
                }),
              )
            )
          )
      )
    )
  );

  getClientIsRealId$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ClientActions.GetShouldBypassRealId),
      concatMap((action) =>
        of(action).pipe(
          withLatestFrom(
            this.store.pipe(select(ClientSelectors.getClientModelForRealId)),
            this.store.pipe(select(PackagesSelectors.getActivePackageGuid))
          )
        )
      ),
      switchMap(([action, client, packageGuid]) => {
        if (client?.shouldBypassRealId) {
          return of(
            ClientActions.SetShouldBypassRealId({
              payload: { shouldBypassRealId: client.shouldBypassRealId },
            })
          );
        }
        return this.clientService.getClientForPackage(packageGuid).pipe(
          map((payload) =>
            ClientActions.LoadClientForPackage({
              payload: { packageGuid },
            })
          ),
          catchError((err) =>
            of(
              NotificationsActions.AddNotification({
                payload: {
                  exception: err,
                  notificationType: NotificationType.Error,
                  id: uuid(),
                  text: 'Failed to get Client',
                  exceptionType: ExceptionType.ReloadRetry,
                },
              })
            )
          )
        );
      })
    )
  );


  constructor(
    private readonly actions$: Actions,
    private readonly clientService: ClientService,
    private readonly store: Store<RootStoreState.State>,
  ) {}
}
