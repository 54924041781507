<div class="modal">
  <div *ngIf="isMultiSigner; else singleSigner">
    <div class="modal modal__header">
      <h3 class="modal__content modal__header__title" data-private>Hi {{ formattedNames }}!</h3>
      <span class="modal__content modal__header__subtitle" data-private
        >We let your notary know you're here. Let's begin {{ currentUser.displayName }}'s check-in
        first.</span
      >
    </div>

    <div class="modal modal__body">
      <img
        [src]="imageSrc"
        alt="Profile picture of notary"
        data-testid="modal-image"
        class="modal__content modal__body__image"
      />
      <div class="modal__content modal__body__text">Your notary</div>
      <div class="modal__content modal__body__name" data-private>
        {{ signingAgent$ | async | formatPackageUserName }}
      </div>
    </div>

    <app-button-container class="modal__button-container">
      <app-button
        type="tertiary"
        class="modal__button-container--tertiary"
        (click)="onBack()"
        [dataTestId]="'notary-modal-go-back-button'"
      >
        Go Back
      </app-button>

      <app-button
        type="primary"
        class="modal__button-container--primary"
        (click)="onContinue()"
        [dataTestId]="'notary-modal-continue-button'"
        data-private
      >
        Continue for {{ currentUser.displayName }}
      </app-button>
    </app-button-container>
  </div>

  <!-- Single signer -->
  <ng-template #singleSigner>
    <div class="modal modal__header">
      <h3 class="modal__content modal__header__title" data-private>Hi {{ formattedNames }}!</h3>
      <span class="modal__content modal__header__subtitle"
        >We let your notary know you're here.</span
      >
    </div>

    <div class="modal modal__body">
      <img
        [src]="imageSrc"
        alt="Profile picture of notary"
        data-testid="modal-image"
        class="modal__content modal__body__image"
      />
      <div class="modal__content modal__body__text">Your notary</div>
      <div class="modal__content modal__body__name" data-private>
        {{ signingAgent$ | async | formatPackageUserName }}
      </div>
    </div>

    <app-button-container class="modal__button-container">
      <app-button
        type="tertiary"
        class="modal__button-container--tertiary"
        (click)="onBack()"
        [dataTestId]="'notary-modal-go-back-button'"
        data-private
      >
        Go Back
      </app-button>

      <app-button
        type="primary"
        class="modal__button-container--primary"
        (click)="onContinue()"
        [dataTestId]="'notary-modal-continue-button'"
      >
        Continue
      </app-button>
    </app-button-container>
  </ng-template>
</div>
