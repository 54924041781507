<div
  [ngClass]="{
    'video-list': true,
    'video-list--single': activeLayout === availableLayouts.single,
    'video-list--double': activeLayout === availableLayouts.double,
    'video-list--triple': activeLayout === availableLayouts.triple
  }"
  *ngIf="videoSession"
  data-private
>
  <app-publisher [videoSession]="videoSession"></app-publisher>
  <app-subscriber
    *ngFor="let stream of streams"
    [stream]="stream"
    [videoSession]="videoSession"
  ></app-subscriber>
</div>
