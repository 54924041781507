<app-nav-bar-pre-session></app-nav-bar-pre-session>
<main role="main">
  <app-side-bar [dynamicContent]="sideBarDynamicContent"></app-side-bar>
  <div class="router-content">
    <router-outlet class="page-content" tabindex="0"></router-outlet>
  </div>
</main>
<app-modal-container></app-modal-container>
<app-loading-spinner></app-loading-spinner>
<app-internet-check></app-internet-check>
<app-audio-video-connection-monitor
  *ngIf="useAudioVideoConnectionMonitor"
></app-audio-video-connection-monitor>
<app-footer></app-footer>
