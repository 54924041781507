import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-session-ending-progression',
  templateUrl: './session-ending-progression.component.html',
  styleUrls: ['./session-ending-progression.component.scss']
})
export class SessionEndingProgressionComponent {
  @Input() animationUrl: string;
  @Input() altText: string;
  @Input() header: string;
  @Input() subHeader: string;
}
