<div class="cancel-signing">
  <div class="cancel-signing__header">
    <div data-testid="modal-header">Cancel signing verification</div>
  </div>
  <div class="cancel-signing__body" data-testid="modal-text">
    <div>
      Are you sure you want to cancel the signing?
    </div>

    <form [formGroup]="optOutForm">
      <div class="opt-out-form">
        <p class="error">
          <span
            *ngIf="comments.invalid && (comments.dirty || comments.touched)"
          >
            Please enter comments regarding cancelling this order
          </span>
          &nbsp;
        </p>
        <textarea
          *ngIf="fixedCategoryTypeCode === 'CommentsOnly'"
          formControlName="comments"
          maxlength="3000"
          placeholder="Comments:"
          data-testid="cancel-signing-comments"
        ></textarea>
      </div>
    </form>
  </div>

  <app-button-container>
    <app-button type="secondary" (click)="handleBackButton()" [dataTestId]="'modal-close-cancel-signing'">
      Back to Signing
    </app-button>
    <app-button type="warning" (click)="handleOptOut()" [dataTestId]="'modal-confirm-cancel-signing'">
      Yes, Cancel the Signing
    </app-button>
  </app-button-container>
</div>
