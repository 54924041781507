import { Inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import * as OT from '@opentok/client';

import { combineLatest, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { VideoSelectors } from 'src/app/features/video/store';
import { RootStoreState } from 'src/app/store';

import { windowToken } from 'src/app/window.token';

@Injectable({ providedIn: 'root' })
export class OpentokService {
  session: OT.Session;
  token: string;

  constructor(
    private readonly store: Store<RootStoreState.State>,
    @Inject(windowToken) private readonly windowRef: Window & { OT: typeof OT }
  ) {}

  getOT() {
    return this.windowRef.OT;
  }

  initSession(): Observable<OT.Session> {
    if (!!this.session) {
      return of(this.session);
    }

    return combineLatest([
      this.store.select(VideoSelectors.getVideoSessionKey),
      this.store.select(VideoSelectors.getApiKey),
      this.store.select(VideoSelectors.getToken),
    ]).pipe(
      map(([videoSessionKey, apiKey, token]) => {
        this.session = this.getOT().initSession(apiKey, videoSessionKey);
        this.token = token;
        return this.session;
      })
    );
  }

  connect() {
    return new Promise((resolve, reject) => {
      this.session.connect(this.token, (err) => {
        if (err) {
          reject(err);
        } else {
          resolve(this.session);
        }
      });
    });
  }
}
