<div
  [ngClass]="{
    middle: true,
    center: true,
    'middle--active': (requiredCheckboxTotal$ | async) > 0
  }"
>
  <span class="middle__count" data-testid="checkbox-count">{{ requiredCheckboxTotal$ | async }}</span>
  <span data-testid="checkbox-text"
    >{(requiredCheckboxTotal$ | async), plural, =1 {Checkbox} other
    {Checkboxes}}</span
  >
</div>
