import { Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { PackagesSelectors } from 'src/app/features/packages';
import { ProductType } from 'src/app/features/packages/models';
import { RootStoreState } from 'src/app/store';

@Component({
  selector: 'app-early-signing',
  templateUrl: './early-signing.component.html',
  styleUrls: ['./early-signing.component.scss'],
})
export class EarlySigningComponent implements OnInit {
  productSpecificMessage$: Observable<string>;

  constructor(private readonly store: Store<RootStoreState.State>) {}

  ngOnInit(): void {
    this.productSpecificMessage$ = this.store.pipe(
      select(PackagesSelectors.getProductType),
      map((productType) => this.selectProductSpecificMessage(productType))
    );
  }

  selectProductSpecificMessage(productType: ProductType): string {
    switch (productType) {
      case ProductType.RemoteSigning:
      case ProductType.KRON:
        return 'This Signing won’t be available until 30 minutes before its scheduled time.';
      default:
        return 'This Signing will be available on its scheduled date.';
    }
  }
}
