import { Component, input, OnInit } from '@angular/core';
import { AdditionalInfo, GadgetGuideResponse, Step } from '../../services/gadget-guide.types';

@Component({
  selector: 'app-gadget-guide-response',
  standalone: true,
  template: `
    <h1>{{ this.title }}</h1>
    <p>{{ this.introduction }}</p>
    <ol>
      @for (step of this.steps; track step) {
      <li>{{ step.instruction }}</li>
      }
    </ol>
    @for (info of this.additionalInfo; track info) {
    <p>{{ info.text }}</p>
    }
  `,
})
export class GadgetGuideResponseComponent implements OnInit {
  gadgetGuide = input.required<GadgetGuideResponse>();

  additionalInfo: AdditionalInfo[];
  introduction: string;
  steps: Step[];
  title: string;

  ngOnInit() {
    const guide = this.gadgetGuide();

    this.additionalInfo = guide.additionalInfo;
    this.introduction = guide.introduction;
    this.steps = guide.steps;
    this.title = guide.title;
  }
}
