import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { combineLatest, Subject } from 'rxjs';
import { distinctUntilChanged, filter, take, takeUntil, tap } from 'rxjs/operators';

import { DocumentsActions, DocumentsSelectors } from 'src/app/features/documents';
import { Feature } from 'src/app/features/feature-management/models';
import { FeatureManagementService } from 'src/app/features/feature-management/services';
import { ModalsActions } from 'src/app/features/modals';
import { PackagesSelectors } from 'src/app/features/packages';
import { ProductType } from 'src/app/features/packages/models';
import { RootStoreState } from 'src/app/store';
import { ProgressBarComponent } from '../progress-bar';

@Component({
  selector: 'app-signing-room-documents-loading',
  templateUrl: './documents-loading.component.html',
  styleUrls: ['./documents-loading.component.scss'],
})
export class DocumentsLoadingComponent implements OnInit, AfterViewInit, OnDestroy {
  stepDestroyedNotifier = new Subject();
  isSigningRoomActionBlockingEnabled: boolean;

  constructor(
    private readonly store: Store<RootStoreState.State>,
    private readonly featureManagementService: FeatureManagementService
  ) {}

  ngOnInit(): void {
    this.isSigningRoomActionBlockingEnabled =
      this.featureManagementService.getIsFeatureEnabledWithCaching(
        Feature.SigningRoomActionBlocking
      );
  }

  ngAfterViewInit() {
    if (!this.isSigningRoomActionBlockingEnabled) {
      this.store
        .select(DocumentsSelectors.getDocumentsLoadedStatus)
        .pipe(take(1))
        .subscribe((status) => {
          if (!status || status.isLoaded) {
            return;
          }
          this.store.dispatch(DocumentsActions.ProgressBarOpened());
          this.displayDocumentsLoadingModal();
        });

      combineLatest([
        this.store.pipe(select(PackagesSelectors.getProductType)),
        this.store.pipe(select(DocumentsSelectors.getDocumentsLoadedStatus)),
        this.store.pipe(select(DocumentsSelectors.getAreAllSessionsDocumentsLoaded)),
      ])
        .pipe(
          takeUntil(this.stepDestroyedNotifier),
          filter(([productType]) => productType === ProductType.RemoteSigning),
          filter(
            ([productType, documentsLoadingStatus, areAllSessionsDocumentsLoaded]) =>
              !areAllSessionsDocumentsLoaded
          ),
          distinctUntilChanged(),
          tap(([productType, documentsLoadingStatus, areAllSessionsDocumentsLoaded]) => {
            if (
              documentsLoadingStatus &&
              documentsLoadingStatus.isLoaded &&
              !areAllSessionsDocumentsLoaded
            ) {
              this.displayOtherParticipantsDocumentsLoadingModal();
            }
          })
        )
        .subscribe();
    }
  }

  displayDocumentsLoadingModal() {
    this.store.dispatch(
      ModalsActions.SetModalComponent({
        payload: {
          component: ProgressBarComponent,
          componentData: {
            percentLoadedSelector: DocumentsSelectors.getDocumentsLoadedPercentage,
            title: `We're loading your documents`,
            text: `Please wait a few minutes for your documents to finish downloading.`,
            mode: 'determinate',
          },
          allowManualClose: false,
        },
      })
    );
  }

  displayOtherParticipantsDocumentsLoadingModal() {
    this.store.dispatch(
      ModalsActions.SetModalComponent({
        payload: {
          component: ProgressBarComponent,
          componentData: {
            percentLoadedSelector: '100',
            title: 'Loading documents for others',
            text: `Your documents have downloaded. Please wait a few minutes for documents to finish downloading for all other participants.`,
            mode: 'indeterminate',
          },
          allowManualClose: false,
        },
      })
    );
  }

  ngOnDestroy() {
    this.stepDestroyedNotifier.next(undefined);
    this.stepDestroyedNotifier.complete();
  }
}
