import { ProductType } from '../../packages/models';

export interface RulesRequest {
  id: number;
  lender: Lender;
  partner: Partner;
  stateCode: string;
  countyFIPSCode: string;
  userRoleCode: string;
  rulesType: string;
  productType: ProductType;
}

// The properties in defaultRulesRequest is required for scalability/future proofing for the rules engine,
// even though wecurrently don't store this data in the Angular project or in the dotnet projects
export const defaultRulesRequest = {
  rulesType: 'state',
  countyFIPSCode: '',
  lender: {
    id: 1,
    name: 'lender',
  },
  partner: {
    id: 1,
    name: 'partner',
  },
};

interface Lender {
  id: number;
  name: string;
}

interface Partner {
  id: number;
  name: string;
}
