import { DOCUMENT } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { ModalsActions } from 'src/app/features/modals';
import { DEFAULT_APPLICATION_PHONE_NUMBER } from 'src/app/features/shared/constants';
import { RootStoreState } from 'src/app/store';

@Component({
  selector: 'app-video-signal-degraded-modal',
  templateUrl: './video-signal-degraded-modal.component.html',
  styleUrls: ['./video-signal-degraded-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VideoSignalDegradedModalComponent implements OnInit {
  static identifier = 'VideoSignalDegradedModalComponent';
  public DEFAULT_APPLICATION_PHONE_NUMBER: string;

  constructor(
    private readonly store: Store<RootStoreState.State>,
    @Inject(DOCUMENT) private readonly document: Document
  ) {}
  ngOnInit(): void {
    this.DEFAULT_APPLICATION_PHONE_NUMBER = DEFAULT_APPLICATION_PHONE_NUMBER;
  }

  refresh() {
    this.store.dispatch(ModalsActions.ClearModalComponent());
    this.document.defaultView.location.reload();
  }
}
