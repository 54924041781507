import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { SignalRService } from 'src/app/features/signal-r';
import { delayedRetry } from 'src/app/operators';
import { RootStoreState } from 'src/app/store';

import { DeviceGroupActions } from '../../device-group';
import { VideoActions } from '../../video/store';
import {
  IdVerification,
  ParticipantVerification,
  UpdateParticipantApprovalResponse,
} from '../models';
import { ParticipantVerificationActions } from '../store/actions';

@Injectable()
export class ParticipantVerificationService {
  private listenerConfigured = false;

  constructor(
    private readonly httpClient: HttpClient,
    private readonly store: Store<RootStoreState.State>,
    private readonly signalRService: SignalRService
  ) {}

  approveParticipant(
    participantVerification: ParticipantVerification
  ): Observable<UpdateParticipantApprovalResponse> {
    return this.httpClient
      .post<UpdateParticipantApprovalResponse>(
        `participantVerification/approval`,
        participantVerification
      )
      .pipe(
        delayedRetry(500, 3),
        catchError((res) => {
          return throwError(res);
        })
      );
  }

  denyParticipant(participantVerification: ParticipantVerification) {
    return this.httpClient.post(`participantVerification/denial`, participantVerification).pipe(
      delayedRetry(500, 3),
      catchError((res) => {
        return throwError(res);
      })
    );
  }

  denyRejoiningParticipant(
    participantVerification: ParticipantVerification
  ): Observable<UpdateParticipantApprovalResponse> {
    return this.httpClient
      .post<UpdateParticipantApprovalResponse>(
        `participantVerification/rejoin/denial`,
        participantVerification
      )
      .pipe(
        delayedRetry(500, 3),
        catchError((res) => {
          return throwError(res);
        })
      );
  }

  approveRejoiningParticipant(
    participantVerification: ParticipantVerification
  ): Observable<UpdateParticipantApprovalResponse> {
    return this.httpClient
      .post<UpdateParticipantApprovalResponse>(
        `participantVerification/rejoin/approval`,
        participantVerification
      )
      .pipe(
        delayedRetry(500, 3),
        catchError((res) => {
          return throwError(res);
        })
      );
  }

  getApprovedParticipants(): Observable<string[]> {
    return this.httpClient.get(`participantVerification/approval`).pipe(
      delayedRetry(500, 3),
      catchError((res) => {
        return throwError(res);
      })
    );
  }

  getIdVerifications(packageGuid: string) {
    return this.httpClient.get<IdVerification[]>(
      `participantVerification/getidverifications/${packageGuid}`
    );
  }

  getAreAllParticipantVerificationsComplete(packageUserGuid: string) {
    return this.httpClient
      .get<boolean>(
        `participantVerification/areAllParticipantVerificationsComplete/packageUsers/${packageUserGuid}`
      )
      .pipe(
        delayedRetry(500, 3),
        catchError((res) => throwError(res))
      );
  }

  public configureListeners() {
    if (!this.listenerConfigured) {
      this.listenerConfigured = true;
      this.signalRService.hubConnection.on('ReceiveAllSignersApproved', () => {
        this.store.dispatch(
          ParticipantVerificationActions.SetAllSignersApproved({
            payload: { allSignersApproved: true },
          })
        );
      });

      this.signalRService.hubConnection.on('ReceiveParticipantRejoinDenial', (data) => {
        this.store.dispatch(
          ParticipantVerificationActions.RejoiningParticipantDenied({
            packageUserGuid: data.packageUserGuid,
            deviceCode: data.sessionId,
          })
        );
      });

      this.signalRService.hubConnection.on('ReceiveParticipantRejoinApproval', (data) => {
        this.store.dispatch(
          ParticipantVerificationActions.RejoiningParticipantApproved({
            packageUserGuid: data.packageUserGuid,
            deviceCode: data.sessionId,
          })
        );
      });

      this.signalRService.hubConnection.on('ReceiveSessionUserAdd', (data) => {
        this.store.dispatch(
          ParticipantVerificationActions.RemoveParticipantFromDevice({
            payload: {
              packageUserGuid: data.packageUserGuid,
              sessionId: data.sessionId,
            },
          })
        );
      });

      this.signalRService.hubConnection.on('ReceiveParticipantVerificationUpdated', (data) => {
        this.store.dispatch(
          ParticipantVerificationActions.RejoiningParticipant({
            packageUserGuid: data.packageUserGuid,
            deviceCode: data.sessionId,
          })
        );

        this.store.dispatch(
          ParticipantVerificationActions.SetAllSignersApproved({
            payload: { allSignersApproved: false },
          })
        );

        this.store.dispatch(
          DeviceGroupActions.RemovePackageUserFromDevice({
            payload: { packageUserGuid: data.packageUserGuid },
          })
        );

        this.store.dispatch(VideoActions.SetIgnoreParticipantVideoStream({ payload: true }));
      });
    }
  }
}
