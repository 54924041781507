import { createAction, props } from '@ngrx/store';

import { CheckInStatus, PackageUser } from '../../models';

export const SetActivePackageUser = createAction(
  '[Package Users] Set Active Package User',
  props<{ payload: { packageUser: PackageUser } }>()
);

export const GetActivePackageUser = createAction('[Package User] Get Active Package User');

export const SetActivePackageUserGuid = createAction(
  '[Package Users] Set Active Package User Guid',
  props<{ payload: { activePackageUserGuid?: string } }>()
);

export const SetActivePackageAndUserGuids = createAction(
  '[Package Users] Set Active Package and User Guids',
  props<{
    payload: { activePackageGuid: string; activePackageUserGuid: string };
  }>()
);

export const CreatePackageUserPreSignSession = createAction(
  '[Package Users] Create Package User PreSign Session'
);

export const ExitPackageUserPreSignSession = createAction(
  '[Package Users] Exit Package User PreSign Session'
);

export const CompletePackageUserPreSignSession = createAction(
  '[Package Users] Complete Package User PreSign Session'
);

export const FetchPackageUsers = createAction(
  '[Package Users] Fetch Package Users',
  props<{ payload?: { ignoreIfLoaded: boolean } }>()
);

export const FetchPackageUsersSuccessful = createAction(
  '[Package Users] Fetch Package Users Successful',
  props<{ payload: { packageUsers: PackageUser[] } }>()
);

export const FetchPackageUserCheckInStatuses = createAction(
  '[Package Users] Fetch Package Users CheckIn Statuses'
);

export const FetchPackageUserCheckInStatus = createAction(
  '[Package Users] Fetch Package User CheckIn Status',
  props<{ payload: { packageUserGuid: string } }>()
);

export const SetPackageUserCheckInStatus = createAction(
  '[Package Users] Set PackageUserLobby CheckInStatus',
  props<{ payload: { packageUserGuid: string; checkInStatus: CheckInStatus } }>()
);

export const ResetPackageUsers = createAction('[Package Users] Reset Package Users');

export const FetchActivePackageUserDashboardUrl = createAction(
  '[Consumer Portal] Fetch Dashboard Url for Active Package User'
);

export const SetActivePackageUserDashboardUrl = createAction(
  '[Consumer Portal] Set Dashboard Url for Active Package User',
  props<{ payload: { dashboardUrl?: string } }>()
);

export const packageUserResolverLoaded = createAction(
  '[Package Users] Package Users Resolver Loaded'
);

export const PackageUserCheckInStatusUpdated = createAction(
  '[Package Users] Package User Check In Status Updated',
  props<{ payload: { packageUserGuid: string; checkInStatus: CheckInStatus } }>()
);
