import { Component } from '@angular/core';

@Component({
  selector: 'app-apply-your-signature-modal',
  templateUrl: './apply-your-signature-modal.component.html',
  styleUrls: ['./apply-your-signature-modal.component.scss'],
})
export class ApplyYourSignatureModalComponent {
  static identifier = 'ApplyYourSignatureModalComponent';
}
