<div class="form-step__subheading" data-testid="check-in-subheader" data-private>
  {{ user?.firstName }} {{ user?.lastName }},
  {{ (selectedInitials$ | async)?.staticSelection ? 'confirm' : 'select' }}
  your initials style below.
</div>
<app-signature-options
  [signatureOptions]="initialsOptions$ | async"
  [signatureSelection]="selectedInitials$ | async"
  [ariaLabelSignatureType]="'initials'"
  (optionSelected)="updateActiveInitials($event)"
></app-signature-options>
<div *ngIf="formStep.errors" class="error-message">Please select your initials to continue</div>
<app-button-container>
  <app-button
    [dataTestId]="'previous-button-select-initials'"
    type="tertiary"
    (click)="onPreviousClick()"
  >
    Back
  </app-button>
  <app-button [dataTestId]="'next-button-select-initials'" type="primary" (click)="goToNextStep()">
    Accept Initials
  </app-button>
</app-button-container>
