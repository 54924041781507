<app-confirmation-modal>
  <ng-container subHeader>
    <p>Switch devices?</p>
  </ng-container>

  <ng-container content>
    <span data-private>{{ user$ | async | formatPackageUserName }}</span> is already
    {{ isCheckInComplete ? 'checked in' : 'checking in' }} on another device. Did you want to
    transfer to this device?
  </ng-container>

  <ng-container actionButton>
    <app-button type="tertiary" (click)="back()" [dataTestId]="'switch-devices-back'">
      Go Back
    </app-button>

    <app-button type="primary" (click)="accept()" [dataTestId]="'switch-devices-agree'">
      Yes, continue{{ isCheckInComplete ? '' : ' checking in' }} on this device
    </app-button>
  </ng-container>
</app-confirmation-modal>
