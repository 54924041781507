import { Component, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';

import { Observable } from 'rxjs';

import { RootStoreState } from 'src/app/store';

import { ModalsActions } from '../../../modals';
import { PrivacyPolicyConsent } from '../../models';
import { ConsentsActions, ConsentsSelectors } from '../../store';

@Component({
  selector: 'app-privacy-policy-consent-modal',
  templateUrl: './privacy-policy-consent-modal.component.html',
  styleUrls: [],
})
export class PrivacyPolicyConsentModalComponent implements OnInit, OnDestroy {
  static identifier = 'PrivacyPolicyConsentModalComponent';
  privacyPolicyConsent$: Observable<PrivacyPolicyConsent>;

  constructor(private readonly store: Store<RootStoreState.State>) {}

  ngOnInit(): void {
    this.privacyPolicyConsent$ = this.store.pipe(select(ConsentsSelectors.getPrivacyPolicyConsent));
  }

  back() {
    this.store.dispatch(ModalsActions.ClearModalComponent());
  }

  accept() {
    this.store.dispatch(ConsentsActions.SetEnabledPrivacyPolicyConsentCheckBox());
    this.store.dispatch(ModalsActions.ClearModalComponent());
  }

  ngOnDestroy() {
    this.store.dispatch(
      ModalsActions.ClearModalComponentIfOpen({
        payload: { component: PrivacyPolicyConsentModalComponent },
      })
    );
  }
}
