<div class="form-step__subheading" data-private>
  {{ user?.firstName }} {{ user?.lastName }}, create your signature below.
</div>

<app-signature-view
  [signature]="signatureData?.svgImageString"
  [imageTypeProvided]="true"
  [placeholderText]="'Your Signature Will Appear Here'"
  [dataTestId]="'drawn-signature-preview'"
  data-private
></app-signature-view>

<app-button
  *ngIf="!!signatureData; else drawsignature"
  class="edit-signature-button"
  type="secondary"
  (click)="drawSignature()"
  [dataTestId]="'edit-signature-button'"
  >Edit</app-button
>
<ng-template #drawsignature>
  <app-button
    class="draw-signature-button"
    type="primary"
    (click)="drawSignature()"
    [dataTestId]="'draw-signature-button'"
    >Draw Your Signature</app-button
  >
</ng-template>

<div [style.visibility]="formStep.errors ? 'visible' : 'hidden'" class="error-message">
  Please draw a signature to continue
</div>

<app-button-container>
  <app-button [dataTestId]="'next-button-draw-signature'" type="primary" (click)="nextClick()">
    Next
  </app-button>
</app-button-container>
