<app-card [borderStyle]="'shadow'">
  <div *ngIf="!areAllParticipantsCheckedIn; else allParticipantsCheckedIn" class="card-content">
    <h1 class="card-content__heading">Welcome to your digital signing</h1>
    <p class="card-content__subtext">
      {{ subHeading }}
    </p>
    <app-button [type]="'primary'" [dataTestId]="'next-button-landing'" (click)="beginCheckIn()">
      Begin Check-in
    </app-button>
  </div>

  <ng-template #allParticipantsCheckedIn>
    <div class="card-content">
      <h1 class="card-content__heading">Welcome back to your digital signing</h1>
      <p class="card-content__subtext">
        When you're ready, click "Enter" below to enter signing room.
      </p>
      <app-button [type]="'primary'" [dataTestId]="'next-button-landing-enter'" (click)="enter()">
        Enter
      </app-button>
    </div>
  </ng-template>
</app-card>

<img
  class="welcome-page-image"
  data-testid="landing-pic"
  src="assets/images/welcome-home.svg"
  alt=""
/>
<app-package-status-monitor></app-package-status-monitor>
