import { Component, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';

import { Observable } from 'rxjs';

import { RootStoreState } from 'src/app/store';

import { ModalsActions } from '../../../modals';
import { EndorsementsConsent } from '../../models';
import { ConsentsActions, ConsentsSelectors } from '../../store';

@Component({
  selector: 'app-signature-consent-agreement-modal',
  templateUrl: './signature-consent-agreement-modal.component.html',
  styleUrls: ['./signature-consent-agreement-modal.component.scss'],
})
export class SignatureConsentAgreementModalComponent implements OnInit, OnDestroy {
  static identifier = 'SignatureConsentAgreementModalComponent';
  endorsementsConsent$: Observable<EndorsementsConsent>;
  constructor(private readonly store: Store<RootStoreState.State>) {}

  ngOnInit(): void {
    this.endorsementsConsent$ = this.store.pipe(select(ConsentsSelectors.getEndorsementsConsent));
  }

  back() {
    this.store.dispatch(ModalsActions.ClearModalComponent());
  }

  accept() {
    this.store.dispatch(ConsentsActions.SetEnabledSignatureConsentAgreementCheckbox());
    this.store.dispatch(ModalsActions.ClearModalComponent());
  }

  ngOnDestroy() {
    this.store.dispatch(
      ModalsActions.ClearModalComponentIfOpen({
        payload: { component: SignatureConsentAgreementModalComponent },
      })
    );
  }
}
