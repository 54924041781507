import { ProductType } from './';

export interface Package {
  packageId: number;
  actualCompletedDateTime: Date;
  city: string;
  clientId: number;
  clientLoanNumber: string;
  expirationDateTime: Date;
  packageGuid: string;
  productType: ProductType;
  scheduledDateTime: Date;
  signingStateCode: string;
  stateCode: string;
  statusCode: string;
  streetAddress1: string;
  streetAddress2: string;
  vendorOrderNumber: string;
  zip: string;
  zip4: string;
}
