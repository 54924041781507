import { createAction, props } from '@ngrx/store';
import { TypedAction } from '@ngrx/store/src/models';

import { VerifyPinRequestModel, VerifyPinResponseModel } from '../../model';
import { PackageUser } from 'src/app/features/package-users';

export const StartPinChallenge = createAction(
  '[Pin Validation] Start PIN Challenge',
  props<{
    payload: {
      participant: PackageUser;
      onSuccessAction: TypedAction<any>;
    };
  }>()
);

export const VerifyPin = createAction(
  '[Pin Validation] Pin Validation',
  props<{ payload: VerifyPinRequestModel }>()
);

export const PinVerificationPass = createAction(
  '[Pin Validation] Pin Validation Successful',
  props<{
    payload: { pinVerification: VerifyPinResponseModel; packageUserGuid: string };
  }>()
);

export const PinVerificationFail = createAction(
  '[Pin Validation] Pin Validation Failed',
  props<{
    payload: { pinVerification: VerifyPinResponseModel; packageUserGuid: string };
  }>()
);

export const PinVerificationError = createAction(
  '[Pin Validation] Pin Validation Error',
  props<{ error: Error }>()
);

export const CertificatePasswordLimitExceeded = createAction(
  '[Pin Validation] Certificate Password Limit Exceeded'
);

export const SetPinVerificationMessage = createAction(
  '[Pin Validation] Set Pin Verification Message',
  props<{ payload: { message: string } }>()
);

export const ShowContactSupport = createAction(
  '[Pin Validation] Show Pin Verification Contact Support'
);

export const HideContactSupport = createAction(
  '[Pin Validation] Hide Pin Verification Contact Support'
);

export const EmptyPinInput = createAction(
  '[Pin Verification] Empty Pin Input Submission Attempt'
);

export const PinVerificationOpened = createAction(
  '[Pin Verification] Opened'
);
