import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';

import { Observable } from 'rxjs';

import { AuditLogsActions } from 'src/app/features/audit-logs';
import { PackageUsersSelectors } from 'src/app/features/package-users';
import { PackagesActions } from 'src/app/features/packages';
import { RootStoreState } from 'src/app/store';

@Component({
  selector: 'app-remote-welcome-page',
  templateUrl: './remote-welcome-page.component.html',
  styleUrls: ['./remote-welcome-page.component.scss'],
})
export class RemoteWelcomePageComponent implements OnInit {
  isSigningAgent$: Observable<boolean>;

  constructor(private readonly store: Store<RootStoreState.State>) {}

  async ngOnInit(): Promise<void> {
    this.store.dispatch(AuditLogsActions.LandingPageViewed());
    this.store.dispatch(PackagesActions.BeginSession());

    this.isSigningAgent$ = this.store.select(PackageUsersSelectors.getIsSigningAgent);
  }
}
