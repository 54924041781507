import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';

import { PackageUser } from 'src/app/features/package-users';
import { AbstractWizardStepComponent, CheckInTaskCode, WizardStep } from 'src/app/features/wizard';
import { RootStoreState } from 'src/app/store';

import { IdVerificationActions } from '../../store/actions';
import { IdVerificationSelectors } from '../../store/selectors';

@Component({
  selector: 'app-id-instructions',
  templateUrl: './id-instructions.component.html',
  styleUrls: ['./id-instructions.component.scss'],
  providers: [
    {
      provide: AbstractWizardStepComponent,
      useExisting: IdInstructionsComponent,
    },
  ],
})
export class IdInstructionsComponent extends AbstractWizardStepComponent implements OnDestroy, OnInit {
  @Input() user: PackageUser;
  private readonly _stepHiddenNotifier$ = new Subject();

  constructor(private readonly store: Store<RootStoreState.State>) {
    super(store);

    this.stepMetadata = {
      stepName: "What you'll need",
      checkInTaskCode: CheckInTaskCode.IdVerificationInstructions,
    } as WizardStep;

    this.formStep = new UntypedFormGroup({});
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this._stepHiddenNotifier$.next(undefined);
  }

  addAuditLog() {
    this.store.dispatch(IdVerificationActions.SaveAuditPhotoIdBegin());
  }

  public onStepVisible(): void {
    this.addAuditLog();
    this.store.dispatch(IdVerificationActions.GetUserIdVerificationResultsStatus());
    this.store
      .pipe(
        takeUntil(this._stepHiddenNotifier$),
        select(IdVerificationSelectors.getIsComplete(this.user.packageUserGuid)),
        tap((complete) => {
          if (complete) {
            this.goToNextStep();
          }
        })
      )
      .subscribe();
  }

  public onStepHidden(): void {
    this._stepHiddenNotifier$.next(undefined);
  }
}
