import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuditLog } from '../models';

@Injectable({
  providedIn: 'root',
})
export class AuditLogsSaveService {

  packageGuid: string;
  userGuid: string;
  deviceCode: string;

  constructor(
    private readonly http: HttpClient,
  ) {}

  public postLandingPage(auditLog: AuditLog) {
    return this.http.post(`packages/${auditLog.PackageGuid}/landingPageViewed`, auditLog);
  }

  public postPreSignDashboard(auditLog: AuditLog) {
    return this.http.post(`packages/${auditLog.PackageGuid}/preSignDashboardViewed`, auditLog);
  }

  public postReadyUp(auditLog: AuditLog) {
    return this.http.post('auditLogs/readyUp', auditLog);
  }

  public postUnsupportedBrowser(auditLog: AuditLog) {
    return this.http.post('auditLogs/unsupportedBrowser', auditLog);
  }
}
