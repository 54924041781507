import { PackageUserRole } from '../models';

export function getPackageUserRole(role: PackageUserRole) {
  const roles: Record<PackageUserRole, string> = {
    BORROWER: 'Borrower',
    SYSTEM: 'System',
    SIGNINGAGENT: 'Notary',
    NBTH: 'Non-Borrowing Title Holder',
    SELLER: 'Seller',
    SIGNER: 'Signer',
    WITNESS: 'Witness',
  };

  return roles[role];
}
