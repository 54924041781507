import { VerifyPinResponseModel } from '../../model';

export interface State {
  pinVerification: VerifyPinResponseModel;
  message: string;
  showSpinner: boolean;
  showContactSupport: boolean;
}

export const initialState: State = {
  pinVerification: null,
  message: '',
  showSpinner: false,
  showContactSupport: false,
};
