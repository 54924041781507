import {
    createFeatureSelector,
    createSelector
  } from '@ngrx/store';
  
import { DuplicateSessionDetectorState } from '../state';

export const getDuplicateSessionDetectorState = createFeatureSelector<DuplicateSessionDetectorState.State>('duplicateSessionDetector');

export const getIsTabLeader = createSelector(
    getDuplicateSessionDetectorState,
    (state: DuplicateSessionDetectorState.State) => state.isTabLeader
);

export const getIsTabActive = createSelector(
    getDuplicateSessionDetectorState,
    (state: DuplicateSessionDetectorState.State) => state.isTabActive
);

export const getTabGuid = createSelector(
    getDuplicateSessionDetectorState,
    (state: DuplicateSessionDetectorState.State) => state.tabGuid
);

export const getTabCreated = createSelector(
    getDuplicateSessionDetectorState,
    (state: DuplicateSessionDetectorState.State) => state.tabCreated
);

export const getIsEnabled = createSelector(
    getDuplicateSessionDetectorState,
    (state: DuplicateSessionDetectorState.State) => state.isEnabled
);