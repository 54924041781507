import { Inject, Injectable, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';

import { combineLatest, Observable, Subject, Subscription } from 'rxjs';
import { distinctUntilChanged, filter, map, startWith, takeUntil } from 'rxjs/operators';

import { ClientSelectors } from 'src/app/features/client/store/selectors';
import { DeviceGroupSelectors } from 'src/app/features/device-group';
import { PackageUser, PackageUsersSelectors } from 'src/app/features/package-users';
import { Package, PackagesSelectors } from 'src/app/features/packages';
import { ProductType } from 'src/app/features/packages/models';
import { WizardSelectors } from 'src/app/features/wizard';
import { RootStoreState } from 'src/app/store';
import { windowToken } from 'src/app/window.token';
import { environment } from 'src/environments/environment';

import { PendoLocationService } from './pendo-location.service';
import { PendoVisitorAccount } from '../models/pendo-visitor-account.model';
import { snippet } from '../pendo-adapter.constants';

@Injectable({
  providedIn: 'root',
})
export class PendoService implements OnDestroy {
  private isInitialized = false;
  private readonly destroyNotifier: Subject<boolean> = new Subject();
  private readonly destroyNotifier$: Observable<boolean> = this.destroyNotifier.asObservable();
  initializeSubscription: Subscription;
  packageUser$: Observable<PackageUser>;
  package$: Observable<Package>;
  clientName$: Observable<string>;
  deviceCode$: Observable<string>;
  productType$: Observable<ProductType>;

  constructor(
    private readonly store: Store<RootStoreState.State>,
    @Inject(windowToken) private readonly windowRef: Window
  ) {}

  ngOnDestroy(): void {
    this.destroyNotifier.next(true);
  }

  getSnippet(apiKey: string): HTMLScriptElement {
    const scriptElement = document.createElement('script');
    scriptElement.innerHTML = snippet.replace('pendo-api-key', apiKey);
    return scriptElement;
  }

  initializePendo(): void {
    // When the environment.envName has a value of 'root', it's the local environment, so replace the label
    // with 'local' for the purposes of pendo events
    const environmentName = environment.envName === 'root' ? 'local' : environment.envName;

    // Get the package user from the store.  If the package user isn't avaiable, like where the user is checking in
    // during a RON, use the wizard user.
    // Only emit if one of those isn't falsey, and if the properties we care about for Pendo are changing.
    const initialPackageUserValue = null;
    this.packageUser$ = combineLatest([
      this.store.select(PackageUsersSelectors.getActivePackageUser),
      this.store.select(WizardSelectors.getActiveWizardUser),
    ]).pipe(
      map(([packageUser, wizardUser]) => (!!packageUser ? packageUser : wizardUser)),
      filter((packageUser) => !!packageUser),
      distinctUntilChanged((prev, curr) => {
        return (
          prev?.packageUserGuid === curr?.packageUserGuid &&
          prev?.firstName === curr?.firstName &&
          prev?.lastName === curr?.lastName &&
          prev?.emailAddress === curr?.emailAddress &&
          prev?.userRoleCode === curr?.userRoleCode
        );
      }),
      startWith(initialPackageUserValue)
    );

    // Get the package from the store
    // Only emit if the package is truthy, and if the properties we care about for pendo have changed
    this.package$ = this.store.select(PackagesSelectors.getPackage).pipe(
      filter((p) => !!p),
      distinctUntilChanged(
        (prev, curr) =>
          prev?.packageGuid === curr?.packageGuid &&
          prev?.clientId === curr?.clientId &&
          prev?.productType === curr?.productType &&
          prev?.packageId === curr?.packageId
      )
    );

    // Get the client name from the store
    // Only emit if the client name is not the default nexsys client name, and if the client name has changed.
    this.clientName$ = this.store.select(ClientSelectors.getClientName).pipe(
      filter((c) => !!c),
      distinctUntilChanged()
    );

    // Get the device code from the store
    // Only emit when the device code changes
    this.deviceCode$ = this.store
      .select(DeviceGroupSelectors.getDeviceCode)
      .pipe(distinctUntilChanged());

    this.initializeSubscription = combineLatest([
      this.packageUser$,
      this.package$,
      this.clientName$,
      this.deviceCode$,
    ])
      .pipe(
        map(([packageUser, activePackage, clientName, deviceCode]): PendoVisitorAccount => {
          return {
            visitor: {
              id: deviceCode,
              packageGuid: activePackage.packageGuid,
              productType: activePackage.productType,
              packageUserGuid: packageUser?.packageUserGuid,
              firstName: packageUser?.firstName,
              lastName: packageUser?.lastName,
              orderNumber: activePackage.packageId,
              userRoleCode: packageUser?.userRoleCode,
            },
            account: {
              id: `${clientName} (${environmentName})`,
              clientId: activePackage.clientId,
              clientName: clientName,
              environmentName,
            },
          };
        }),
        takeUntil(this.destroyNotifier$)
      )
      .subscribe((pendoVisitorAccount) => {
        // If this is the first time the observable emits, then call pendo.initialize
        // Otherwise, call pendo.identify
        if (!this.isInitialized) {
          this.windowRef.pendo.initialize({
            ...pendoVisitorAccount,
            location: {
              setUrl: function () {
                // This function will be called by Pendo every 0.5s
                return PendoLocationService.instance.getPageUrl();
              },
            },
          });
          this.isInitialized = true;
        } else {
          this.windowRef.pendo.identify(pendoVisitorAccount);
        }
      });
  }
}
