import { Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';

import { Observable } from 'rxjs';

import { EndorsementsSelectors } from 'src/app/features/endorsements';
import { RootStoreState } from 'src/app/store';

import { DocumentsSelectors } from '../../store';

@Component({
  selector: 'app-document-title',
  templateUrl: './document-title.component.html',
  styleUrls: ['./document-title.component.scss'],
})
export class DocumentTitleComponent implements OnInit {
  public totalSignableDocuments$: Observable<number>;
  public totalCompletedDocuments$: Observable<number>;

  constructor(private readonly store: Store<RootStoreState.State>) {}

  ngOnInit(): void {
    this.totalSignableDocuments$ = this.store.pipe(
      select(DocumentsSelectors.getTotalSignableDocuments)
    );

    this.totalCompletedDocuments$ = this.store.pipe(
      select(EndorsementsSelectors.getTotalCompletedDocuments)
    );
  }
}
