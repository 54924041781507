import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { select, Store } from '@ngrx/store';

import { Observable, Subject } from 'rxjs';
import { filter, map, take, takeUntil, tap } from 'rxjs/operators';

import { ConsentsActions, ConsentsSelectors, EndorsementsConsent } from 'src/app/features/consents';
import { PackageUser } from 'src/app/features/package-users';
import { AbstractWizardStepComponent, CheckInTaskCode, WizardStep } from 'src/app/features/wizard';
import { RootStoreState } from 'src/app/store';

import { SignatureConsentAgreementModalComponent } from '../../../consents/components';
import { PresignConsentsActions } from '../../../consents/store/actions';
import { ModalsActions, ModalSize } from '../../../modals';
import { EndorsementSteps } from '../../models';
import { EndorsementImageCreationPreview } from '../../models/EndorsementImageCreationPreview.model';
import { EndorsementsCreationActions, EndorsementsCreationSelectors } from '../../store';

@Component({
  selector: 'app-signature-confirmation',
  templateUrl: './signature-confirmation.component.html',
  styleUrls: ['./signature-confirmation.component.scss'],
  providers: [
    {
      provide: AbstractWizardStepComponent,
      useExisting: SignatureConfirmationComponent,
    },
  ],
})
export class SignatureConfirmationComponent
  extends AbstractWizardStepComponent
  implements OnInit, OnDestroy
{
  @Input() user: PackageUser;
  @Input() isPresignComponent = false;

  signatureConsentAgreementCheckbox: UntypedFormControl;
  submissionFailure: boolean;

  allowGoToPreviousStep$: Observable<boolean>;
  endorsementConsentText$: Observable<EndorsementsConsent>;
  signatureMessage$: Observable<string>;
  signatureImagePreview$: Observable<EndorsementImageCreationPreview>;
  initialsImagePreview$: Observable<EndorsementImageCreationPreview>;

  onDestroyNotifier = new Subject();

  constructor(private readonly store: Store<RootStoreState.State>) {
    super(store);

    this.stepMetadata = {
      stepName: EndorsementSteps.CONFIRM_SIGNATURE,
      checkInTaskCode: CheckInTaskCode.SignatureConfirmation,
    } as WizardStep;

    this.signatureConsentAgreementCheckbox = new UntypedFormControl(false, [
      Validators.requiredTrue,
    ]);
    this.formStep = new UntypedFormGroup({
      signatureConsentAgreementCheckbox: this.signatureConsentAgreementCheckbox,
    });
  }

  ngOnInit() {
    super.ngOnInit();

    if (this.isPresignComponent) {
      this.store.dispatch(PresignConsentsActions.FetchEndorsementsConsent());
    } else {
      this.store.dispatch(ConsentsActions.FetchEndorsementsConsent());
    }

    this.endorsementConsentText$ = this.store.pipe(
      select(ConsentsSelectors.getEndorsementsConsent)
    );

    this.store.pipe(
      select(ConsentsSelectors.getIsSignatureConsentAgreementCheckboxEnabled),
      takeUntil(this.onDestroyNotifier),
      tap((isConsentCheckBoxEnabled) => {
        this.signatureConsentAgreementCheckbox.setValue(isConsentCheckBoxEnabled);
        this.resetFormStepErrorMessage();
      })
    ).subscribe();

    this.store
      .pipe(
        select(EndorsementsCreationSelectors.getAreSelectionsSaved),
        takeUntil(this.onDestroyNotifier),
        filter((areSaved) => !!areSaved),
        take(1),
        tap(() => super.goToNextStep())
      )
      .subscribe();

    this.allowGoToPreviousStep$ = this.store.pipe(
      select(EndorsementsCreationSelectors.getIsSignatureCreatedInPresign),
      map((isCreatedInPresign) => !isCreatedInPresign)
    );

    this.signatureMessage$ = this.store.pipe(
      select(EndorsementsCreationSelectors.getIsSignatureCreatedInPresign),
      map((isCreatedInPresign) =>
        isCreatedInPresign
          ? 'your signature has been carried over from your previous session, and will appear as shown below on any documents signed during this session.'
          : 'your signature will appear as shown below on any documents signed during this session.'
      )
    );

    this.signatureImagePreview$ = this.store.pipe(
      select(EndorsementsCreationSelectors.getCreatedSignatureEndorsementImage)
    );
    this.initialsImagePreview$ = this.store.pipe(
      select(EndorsementsCreationSelectors.getCreatedInitialsEndorsementImage)
    );

    // If the signature/initials were created in Presign, the previews would not have been
    // loaded by earlier steps since they're omitted. Need to load them here in that case.
    this.store
      .pipe(
        select(EndorsementsCreationSelectors.getIsSignatureCreatedInPresign),
        takeUntil(this.onDestroyNotifier),
        filter((isCreatedInPresign) => isCreatedInPresign),
        take(1),
        tap(() => {
          this.store.dispatch(EndorsementsCreationActions.FetchInitialsPreviews());
          this.store.dispatch(EndorsementsCreationActions.FetchSignaturePreviews());
        })
      )
      .subscribe();
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.onDestroyNotifier.next(undefined);
    this.onDestroyNotifier.complete();
  }

  resetFormStepErrorMessage() {
    if (this.submissionFailure && this.formStep.valid) {
      this.submissionFailure = false;
    }
  }

  toggleSignatureConsentAgreementCheckbox() {
    if (this.signatureConsentAgreementCheckbox.invalid) {
      this.store.dispatch(ConsentsActions.SetDisabledSignatureConsentAgreementCheckbox());
    } else {
      this.store.dispatch(ConsentsActions.SetEnabledSignatureConsentAgreementCheckbox());
    }
  }

  openSignatureConsentAgreementModal() {
    this.store.dispatch(
      ModalsActions.SetStandaloneModalComponent({
        payload: {
          component: SignatureConsentAgreementModalComponent,
          modalSize: ModalSize.large,
        },
      })
    );
  }

  public validateStep() {
    this.submissionFailure = this.formStep.invalid;
  }

  public goToNextStep(_?: any): void {
    this.validateStep();
    if (this.formStep.valid) {
      this.store.dispatch(EndorsementsCreationActions.ConfirmAndSaveEndorsements());
    }
  }
}
