import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Guid } from 'guid-typescript';
import { combineLatest, Observable } from 'rxjs';
import { first, mergeMap, switchMap } from 'rxjs/operators';
import { MsalAuthSelectors } from 'src/app/features/msal-auth';
import { IdTokenClaims } from 'src/app/features/msal-auth/models';
import { environment } from 'src/environments/environment';
import { DeviceGroupSelectors } from '../features/device-group';
import { PackageUsersSelectors } from '../features/package-users';
import { PackagesSelectors } from '../features/packages';
import { RootStoreState } from '../store';

@Injectable()
export class BaseUrlInterceptor implements HttpInterceptor {
  ignoreUrlStartsWith: string[] = ['favicon.ico', '/assets/material-icons'];

  constructor(private readonly store: Store<RootStoreState.State>) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    if (this.ignoreUrlStartsWith.some((url) => request.url.startsWith(url))) {
      return next.handle(request);
    }

    if (request.url.startsWith('signalr')) {
      return combineLatest([
        this.store.pipe(select(PackageUsersSelectors.getPackageUserState)),
        this.store.pipe(select(PackagesSelectors.getActivePackageGuid)),
      ]).pipe(
        first(),
        switchMap(([packageUser, packageGuid]) =>
          next.handle(
            request.clone({
              url: `${environment.apiBaseUrl}${request.url}`,
              headers: request.headers
                .set(
                  `x-ms-client-principal-id`,
                  packageUser.activePackageUser.packageUserId.toString()
                )
                .set(`package-guid`, `${packageGuid}`)
                .set(`auth-settings`, `user-bearer`),
            })
          )
        )
      );
    }

    return combineLatest([
      this.store.pipe(select(PackageUsersSelectors.getActivePackageUserGuid)),
      this.store.pipe(select(PackagesSelectors.getActivePackageGuid)),
      this.store.pipe(select(DeviceGroupSelectors.getDeviceCode)),
      this.store.pipe(select(PackagesSelectors.getActivePackageId)),
      this.store.pipe(select(MsalAuthSelectors.getUser)),
    ]).pipe(
      first(),
      mergeMap(([packageUserGuid, packageGuid, deviceCode, packageId, user]) => {
        const idTokenClaims = user?.idTokenClaims as IdTokenClaims;

        return next.handle(
          request.clone({
            url: `${environment.apiBaseUrl}${request.url}`,
            headers: request.headers
              .set(`Ocp-Apim-Subscription-Key`, `${environment.functionApiKey}`)
              .set(`auth-settings`, `user-bearer`)
              .set(`package-guid`, `${packageGuid ?? idTokenClaims?.extension_PackageGuid}`)
              .set(`package-user-guid`, `${packageUserGuid}`)
              .set(`device-code`, `${deviceCode ?? Guid.EMPTY}`)
              .set(`package-id`, `${packageId}`),
          })
        )}
      )
    );
  }
}
