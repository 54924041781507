import { Injectable, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { interval, Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';

import { LongPollingConfig } from 'src/app/features/shared/configs/long-polling';
import { RootStoreState } from 'src/app/store';

import { PackagesActions } from '../store/actions';

@Injectable()
export class PackageStatusPollingService implements OnDestroy {
  readonly interval$ = interval(LongPollingConfig['16Seconds'].interval).pipe(
    tap(() => {
      this.store.dispatch(PackagesActions.GetPackageStatus());
    })
  );
  subscription: Subscription;
  constructor(private readonly store: Store<RootStoreState.State>) {}

  ngOnDestroy(): void {
    this.stop();
  }

  start() {
    this.subscription = this.interval$.subscribe();
  }

  stop() {
    this.subscription?.unsubscribe();
  }
}
