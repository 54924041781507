<img
  class="feedback-image"
  src="../../../assets/images/support-cog.svg"
  alt=""
  data-testid="cancelled-pic"
/>
<div class="message-container">
  <h1 class="message-container__header" data-testid="cancelled-header">
    Signing session is canceled
  </h1>
  <p class="message-container__message" data-testid="call-support-text">
    To reschedule your signing session and for more information please call support:
    <app-support-phone></app-support-phone>
  </p>
</div>
