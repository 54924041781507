import { DEFAULT_APPLICATION_PHONE_NUMBER } from '../../shared/constants';

export interface Client {
  clientName: string;
  supportPhoneNumber: string;
  idpCode?: string;
  shouldBypassRealId: boolean;
  isDefault?: boolean;
  clientTheme?: ClientTheme;
}

export interface ClientTheme {
  primaryColorHexCode: string;
  primaryColorHoverBgHexCode: string;
  primaryColorHoverTextHexCode: string;
  secondaryColorHexCode: string;
  secondaryColorHoverBgHexCode: string;
  secondaryColorHoverTextHexCode: string;
  logoSmallUri: string;
  logoLargeUri: string;
  faviconUri: string;
  documentTitleText: string;
  sessionEndTheme: SessionEndTheme
}

export interface SessionEndTheme {
  closingPackageName: string;
  signerProviderText: string;
  hasNotaryDashboardButton: boolean;
  notaryDashboardButtonText: string;
  notaryDashboardUrl: string;
  documentProviderText: string;
  hasGotoDocumentsButton: boolean;
  hasMortgagePaymentsSection: boolean;
  rocketDocumentDashboardUrl: string;
  rocketLoanSummaryUrl: string
}

export const defaultClientInfo: Client = {
  clientName: 'Support',
  supportPhoneNumber: DEFAULT_APPLICATION_PHONE_NUMBER,
  isDefault: true,
  shouldBypassRealId: false
};
