import { Injectable } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { Store } from '@ngrx/store';

import { combineLatest, Observable } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';
import { Feature } from 'src/app/features/feature-management/models';
import { FeatureManagementService } from 'src/app/features/feature-management/services';

import { MsalAuthSelectors, MsalAuthService } from 'src/app/features/msal-auth';
import { PackageUsersSelectors } from 'src/app/features/package-users';
import { PackagesActions, PackagesSelectors } from 'src/app/features/packages';
import { ProductType } from 'src/app/features/packages/models';
import { PRODUCT_TYPE_ROUTE_MAP } from 'src/app/features/packages/packages.constants';
import { WizardActions } from 'src/app/features/wizard';

import { IdTokenClaims } from '../features/msal-auth/models';
import { RootStoreState } from '../store';

@Injectable({
  providedIn: 'root',
})
export class RouteSelectionGuard {
  constructor(
    private readonly router: Router,
    private readonly store: Store<RootStoreState.State>,
    private readonly msalAuthService: MsalAuthService,
    // TODO Story 61780: Remove all the feature flags once the client hints are enabled for all users
    private readonly featureManagementService: FeatureManagementService
  ) {}

  canActivate(): Observable<UrlTree> | UrlTree {
    this.store.dispatch(WizardActions.ClearActiveWizardUser());

    return combineLatest([this.store.select(MsalAuthSelectors.getUser)]).pipe(
      filter((user) => !!user && !!this.msalAuthService.user),
      switchMap(([user]) => {
        const idToken = user.idTokenClaims as IdTokenClaims;
        const packageGuid = idToken.extension_PackageGuid;
        const userGuid = idToken.extension_PackageUserGuids;

        const result = combineLatest([
          this.store.select(PackagesSelectors.isPackageValid),
          this.store.select(PackagesSelectors.getProductType),
          this.store.select(PackageUsersSelectors.getIsSigningAgent),
        ]).pipe(
          filter(([isValid]) => isValid),
          map(([isValid, productType, isSigningAgent]) => {
            const userGuidQueryString = isSigningAgent ? userGuid : null;
            return this.getProductSpecificUrl(productType, userGuidQueryString, packageGuid);
          })
        );

        // TODO Story 61780: Remove all the feature flags once the client hints are enabled for all users
        this.featureManagementService
          .getIsFeatureFlagEnabled(Feature.ClientHints)
          .subscribe((areClientHintsEnabled) => {
            if (this.getAreClientHintsSupportedOnBrowser() && areClientHintsEnabled) {
              this.getClientHints().then((clientHints) => {
                if (this.getAreClientHintsValid(clientHints)) {
                  this.validatePackageWithClientHints(packageGuid, userGuid, clientHints);
                } else {
                  this.validatePackageWithUserAgent(packageGuid, userGuid);
                }

                this.store.dispatch(
                  PackagesActions.ValidatePackage({
                    payload: {
                      packageGuid: packageGuid,
                      packageUserGuid: userGuid,
                    },
                  })
                );
              });
            } else {
              if (areClientHintsEnabled) {
                this.validatePackageWithUserAgent(packageGuid, userGuid);
              }

              this.store.dispatch(
                PackagesActions.ValidatePackage({
                  payload: {
                    packageGuid: packageGuid,
                    packageUserGuid: userGuid,
                  },
                })
              );
            }
          });

        return result;
      })
    );
  }

  getAreClientHintsSupportedOnBrowser(): boolean {
    return navigator.userAgentData ? true : false;
  }

  validatePackageWithUserAgent(packageGuid: string, userGuid: string) {
    this.store.dispatch(
      PackagesActions.CheckDeviceSupportWithUserAgent({
        payload: {
          packageGuid: packageGuid,
          packageUserGuid: userGuid,
        },
      })
    );
  }

  validatePackageWithClientHints(packageGuid: string, userGuid: string, clientHints: UADataValues) {
    this.store.dispatch(
      PackagesActions.CheckDeviceSupportWithClientHints({
        payload: {
          packageGuid: packageGuid,
          packageUserGuid: userGuid,
          clientHints: clientHints,
        },
      })
    );
  }

  getAreClientHintsValid(clientHints: UADataValues): boolean {
    return clientHints.brands.length > 2 && clientHints.platformVersion !== '';
  }

  async getClientHints(): Promise<UADataValues> {
    return navigator.userAgentData.getHighEntropyValues([
      'platform',
      'platformVersion',
      'architecture',
      'model',
      'uaFullVersion',
    ]);
  }

  getProductSpecificUrl(productType: ProductType, userGuid: string, packageGuid: string): UrlTree {
    const productRoute = PRODUCT_TYPE_ROUTE_MAP.get(productType);

    const queryParams = {
      package: packageGuid,
    };

    if (userGuid) {
      queryParams['user'] = userGuid;
    }

    return this.router.createUrlTree([`${productRoute}/welcome`], {
      queryParams,
    });
  }
}
