import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { ModalsActions } from 'src/app/features/modals';
import { RootStoreState } from 'src/app/store';

@Component({
  selector: 'app-endorsement-remaining-modal',
  templateUrl: './endorsement-remaining-modal.component.html',
  styleUrls: ['./endorsement-remaining-modal.component.scss'],
})
export class EndorsementRemainingModalComponent {
  static identifier = 'EndorsementRemainingModalComponent';

  @Input() onSuccess: () => void;
  @Output() public continue = new EventEmitter<void>();

  constructor(private readonly store: Store<RootStoreState.State>) {}

  continueClick() {
    this.onSuccess();
    this.store.dispatch(ModalsActions.ClearModalComponent());
  }

  backClick() {
    this.store.dispatch(ModalsActions.ClearModalComponent());
  }

  static openModal(store: Store<RootStoreState.State>, onSuccess: () => void) {
    store.dispatch(
      ModalsActions.SetStandaloneModalComponent({
        payload: {
          component: EndorsementRemainingModalComponent,
          componentData: {
            onSuccess,
          },
          shouldFade: true,
        },
      })
    );
  }
}
