import { Action, createReducer, on } from '@ngrx/store';
import { ClientActions } from '../actions';
import { ClientState } from '../state';

const reducer = createReducer(
  ClientState.initialState,
  on(ClientActions.SetClient, (state: ClientState.State) => ({
    ...state,
  })),
  on(ClientActions.ClearClientInformation, (state: ClientState.State) => ({
    ...state,
    clientName: null,
    supportPhoneNumber: null,
    shouldBypassRealId: false,
    clientTheme: null,
  })),
  on(ClientActions.SetClientForPackage, (state: ClientState.State, { payload }) => ({
    ...state,
    clientName: payload.client.clientName,
    supportPhoneNumber: payload.client.supportPhoneNumber,
    shouldBypassRealId: payload.client.shouldBypassRealId,
    clientTheme: payload.client.clientTheme,
  })),
  on(ClientActions.SetShouldBypassRealId, (state: ClientState.State, { payload }) => ({
    ...state,
    shouldBypassRealId: payload.shouldBypassRealId,
  }))
);

export function clientReducer(
  state: ClientState.State | undefined,
  action: Action
) {
  return reducer(state, action);
}
