import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

import { FeedbackType } from 'src/app/features/shared/models/FeedbackType.enum';

@Component({
  selector: 'app-feedback',
  templateUrl: 'feedback.component.html',
})
export class FeedbackComponent implements OnInit, OnDestroy {
  feedbackType: FeedbackType;
  feedbackTypes = FeedbackType;

  paramsSubscription: Subscription;

  constructor(private readonly route: ActivatedRoute) {}

  ngOnInit(): void {
    this.paramsSubscription = this.route.params.subscribe((params) => {
      this.feedbackType = params['feedbackType'] ?? FeedbackType.INVALIDURL;
    });
  }

  ngOnDestroy(): void {
    this.paramsSubscription?.unsubscribe();
  }
}
