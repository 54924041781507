import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { select, Store } from '@ngrx/store';

import { combineLatest, Observable, Subject } from 'rxjs';
import { map, takeUntil, tap } from 'rxjs/operators';

import {
  PackageUser,
  PackageUserRole,
  PackageUsersActions,
  PackageUsersSelectors,
} from 'src/app/features/package-users';
import { PackagesSelectors } from 'src/app/features/packages';
import { getPathForUrl } from 'src/app/features/shared';
import { CheckInActions, CheckInSelectors } from 'src/app/features/check-in/store';

@Component({
  selector: 'app-participant-list',
  templateUrl: './participant-list.component.html',
  styleUrls: ['./participant-list.component.scss'],
})
export class ParticipantListComponent implements OnInit, OnDestroy {
  isSigningAgent$ = this.store.select(PackageUsersSelectors.getIsSigningAgent);
  packageUsers$: Observable<PackageUser[]>;
  isRemoteSigning: boolean;
  usersViewedWaitingRoom: string[];
  signingAgentRoleCode: string = PackageUserRole.SIGNINGAGENT;

  private readonly onDestroyNotifier$ = new Subject();
  private readonly MOBILE_MAX_WIDTH = 575;

  constructor(private readonly route: ActivatedRoute, private readonly store: Store) {}

  get isMobile() {
    return window.innerWidth <= this.MOBILE_MAX_WIDTH;
  }

  ngOnInit(): void {
    const routePath = getPathForUrl(this.route.snapshot.url.toString());

    this.store.dispatch(
      PackageUsersActions.FetchPackageUsers({
        payload: { ignoreIfLoaded: true },
      })
    );

    this.store.dispatch(CheckInActions.FetchWaitingRoomViewedByParticipants());

    this.store
      .select(CheckInSelectors.getUsersViewedWaitingRoom)
      .pipe(
        takeUntil(this.onDestroyNotifier$),
        tap((usersViewedWaitingRoom) => (this.usersViewedWaitingRoom = usersViewedWaitingRoom))
      )
      .subscribe();

    this.store
      .select(PackagesSelectors.isRONOrKRON)
      .pipe(
        takeUntil(this.onDestroyNotifier$),
        tap((isRonOrKron) => (this.isRemoteSigning = isRonOrKron))
      )
      .subscribe();

    this.packageUsers$ = combineLatest([
      this.store.pipe(select(PackageUsersSelectors.getPackageUsers)),
      this.store.pipe(select(PackageUsersSelectors.getNonSigningAgents)),
      this.store.pipe(select(PackageUsersSelectors.getIsSigningAgent)),
      this.store.pipe(select(PackagesSelectors.isRONOrKRON)),
    ]).pipe(
      map(([packageUsers, nonSigningAgents, isSigningAgent, isRonOrKron]) => {
        const isSAForRonKronInWelcomePage =
          isSigningAgent && isRonOrKron && routePath.includes('welcome');
        return isSAForRonKronInWelcomePage ? nonSigningAgents : packageUsers;
      })
    );
  }

  isReady(packageUser: PackageUser) {
    if (this.isRemoteSigning) {
      return (
        packageUser.checkInStatus.isCompleted &&
        this.usersViewedWaitingRoom.includes(packageUser.packageUserGuid)
      );
    }

    return packageUser.checkInStatus.isCompleted;
  }

  ngOnDestroy(): void {
    this.onDestroyNotifier$.next(undefined);
    this.onDestroyNotifier$.complete();
  }
}
