<div class="need-help-text-block">
  <div class="need-help-heading">
    <p data-testid="contact-support" class="bold large-medium-font">Need help?</p>
    <p class="bold small-font">Here are the hours you can reach us</p>
  </div>
  <div class="contact-phone" >
      <p >Call: <app-support-phone></app-support-phone></p>    
  </div>
  <div class="support-hours" >
      <p class="small-font">Monday - Friday 8:30 a.m. - 9 p.m. ET</p>
      <p class="small-font">Saturday 9 a.m. - 5 p.m. ET</p> 
  </div>
</div>