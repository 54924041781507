import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { delayedRetry } from 'src/app/operators';

import {
  EndorsementSelections,
  SaveEndorsementFromCommissionRequestModel,
  SaveEndorsementSelectionRequestModel
} from '../models';

@Injectable()
export class EndorsementSelectionService {
  constructor(private readonly httpClient: HttpClient) {}

  getSignatureOptions(packageUserGuid: string) {
    return this.httpClient
      .get<EndorsementSelections>(`endorsements/signatures/preview/packageUsers/${packageUserGuid}`)
      .pipe(
        delayedRetry(1000),
        catchError((res) => {
          console.log(res.error);
          return throwError(res);
        })
      );
  }

  getInitialsOptions(packageUserGuid: string) {
    return this.httpClient
      .get<EndorsementSelections>(`endorsements/initials/preview/packageUsers/${packageUserGuid}`)
      .pipe(
        delayedRetry(1000),
        catchError((res) => {
          console.log(res.error);
          return throwError(res);
        })
      );
  }

  saveEndorsementSelections(requestParams: SaveEndorsementSelectionRequestModel) {
    return this.httpClient.post('endorsements/packageUsers', requestParams).pipe(
      delayedRetry(500, 3),
      catchError((res) => {
        console.log(res.error);
        return throwError(res);
      })
    );
  }

  confirmSignatureFromCommissionAndSaveInitials(
    requestParams: SaveEndorsementFromCommissionRequestModel
  ) {
    return this.httpClient
      .post(
        `endorsements/packageUsers/${requestParams.packageUserGuid}/commissionConfirmation`,
        requestParams
      )
      .pipe(
        delayedRetry(500, 3),
        catchError((res) => {
          console.log(res.error);
          return throwError(res);
        })
      );
  }
}
