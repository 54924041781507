import { Component, OnInit } from '@angular/core';

import { FeatureManagementService } from 'src/app/features/feature-management/services';
import { Feature } from 'src/app/features/feature-management/models';

import { EndorsementBase } from '../../models';

@Component({
  selector: 'app-initials-endorsement',
  templateUrl: './initials-endorsement.component.html',
  styleUrls: ['./initials-endorsement.component.scss'],
})
export class InitialsEndorsementComponent extends EndorsementBase implements OnInit {
  constructor(private readonly featureManagementService: FeatureManagementService) {
    super();
  }

  protected isNewEndorsementApplicationEnabled: boolean;

  ngOnInit(): void {
    this.isNewEndorsementApplicationEnabled = this.featureManagementService.getIsFeatureEnabledWithCaching(
      Feature.NewEndorsementApplication
    );
  }

  endorsementClicked(): void {
    if (this.isNewEndorsementApplicationEnabled) {
      this.signEndorsement.emit({
        endorsementLocationId: this.endorsementLocation.documentEndorsementLocationId,
        endorsementType: this.endorsementLocation.endorsementTypeCode,
        packageUserGuid: this.endorsementLocation.packageUserGuid,
      });
    } else {
      this.endorsementAttempted.emit({
        endorsementLocationId: this.endorsementLocation.documentEndorsementLocationId,
        endorsementType: this.endorsementLocation.endorsementTypeCode,
        packageUserGuid: this.endorsementLocation.packageUserGuid,
      });
    }
  }

  eraseEndorsementClicked(): void {
    this.eraseSignedEndorsement.emit({
      endorsementLocationId: this.endorsementLocation.documentEndorsementLocationId,
      endorsementType: this.endorsementLocation.endorsementTypeCode,
      packageUserGuid: this.endorsementLocation.packageUserGuid,
    });
  }

  get shouldDisable() {
    return this.isEndorsementDisabled || this.areAllEndorsementsSigned;
  }
}
