import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { ClientService } from 'src/app/features/client/services/client.service';
import { SharedModule } from 'src/app/features/shared';

import { PackageStatusMonitorComponent, SigningDetailsCardComponent } from './components';
import { PackagesService, PackageStatusPollingService } from './services';
import { PackagesEffects, PackagesNavigationEffects, PackagesReducers } from './store';

@NgModule({
  declarations: [PackageStatusMonitorComponent, SigningDetailsCardComponent],
  exports: [PackageStatusMonitorComponent, SigningDetailsCardComponent],
  imports: [
    CommonModule,
    SharedModule,
    StoreModule.forFeature('packages', PackagesReducers.Reducers.packageReducer),
    EffectsModule.forFeature([
      PackagesEffects.PackagesEffects,
      PackagesNavigationEffects.PackagesNavigationEffects,
    ]),
  ],
  providers: [ClientService, PackagesService, PackageStatusPollingService],
})
export class PackagesModule {}
