import { Injectable, OnDestroy } from "@angular/core";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Store, select } from "@ngrx/store";
import { BroadcastChannel, createLeaderElection, LeaderElector } from "broadcast-channel";
import { Guid } from "guid-typescript";
import { fromEvent, interval, Observable, Subject, Subscription } from "rxjs";
import { filter, map, take, tap, withLatestFrom } from 'rxjs/operators';
import { ModalsActions } from "src/app/features/modals/store/actions";
import { ModalsSelectors } from "src/app/features/modals/store/selectors";
import { RootStoreState } from "src/app/store";
import { environment } from "src/environments/environment";
import { DuplicateSessionModalComponent } from "../duplicate-session-modal";
import { DuplicateSessionDetectorActions } from "../store/actions";
import { DuplicateSessionDetectorSelectors } from "../store/selectors";

export enum DuplicateSessionMessageType {
  HeartBeat,
  Activate,
  Deactivate,
  CheckExisting,
  YesExisting
}

export type DuplicateSessionMessage = {
  type: DuplicateSessionMessageType,
  guid: string,
  state?: DuplicateSessionTabState
};

export type DuplicateSessionTabState = {
  guid: string,
  created: number,
  isActive: boolean,
  timeStamp: number
};

export type DuplicateStorageTabState = {
  tabs: DuplicateSessionTabState[];
};

/*
    See the following wiki article for more details:
    https://dev.azure.com/nexsys-tech/Clear%20Sign/_wiki/wikis/Clear-Sign.wiki/1365/RON-Duplicate-Session-Blocker

    There you will find details on how the system functions as well as diagrams.
*/

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class DuplicateSessionDetectorService implements OnDestroy
{
  // How often to post out our tab's state.
  readonly intervalTimeMs: number = 1000;

  // A tab is considered dead by the leader tab if it
  // has not heard from that particular tab in this amount of time.
  readonly considerTabDeadIfDelayTimeIsGreatherThanMs: number = 1500;

  // How often to send out a check existing message
  // in order to prevent tabs with duplicated session storage.
  readonly checkExistingIntervalTimeMs: number = 8000;

  readonly localStorageKeyName: string = 'dup-storage-state';

  readonly broadcastChannelName: string = 'duplicate-session';

  // Messages received will ultimate result in a call to
  // this.onHeard{MSGTYPE}Message where {MSGTYPE} is one of the
  // enumerations in RonDuplicateSessionMessageType;
  // e.g. onHeardHeartBeatMessage
  readonly messageCallbackFunctionName: string = 'onHeard{MSGTYPE}Message'

  channel: BroadcastChannel<DuplicateSessionMessage> = null;
  elector: LeaderElector = null;

  // An observable to the currently displayed modal.
  // This is to help keep us from accidentally clearing
  // another modal when our tab becomes active.
  activeModalComponent$: Observable<any> = null;

  // The following four variables are synchronized
  // to the values in the store using a subscription
  // to their equivalent observables.

  // The reason for this is for quick access to the latest
  // values when posting messages as needed without having
  // one off (take(1)) subscriptions everywhere.

  tabIsLeader$: Observable<boolean> = null;
  tabIsLeader: boolean = null;

  tabIsActive$: Observable<boolean> = null;
  tabIsActive: boolean = null;

  tabGuid$: Observable<string> = null;
  tabGuid: string = null;

  tabCreated$: Observable<number> = null;
  tabCreated = 0;

  // Whether or not we're enabled.
  // If we're not enabled then we'll clear the displayed modal (if applicable)
  // and stop processing messages.
  detectorIsEnabled$: Observable<boolean> = null;
  detectorIsEnabled = false;

  // An observable that broadcast messages pass through.
  messages$: Subject<DuplicateSessionMessage> = null;

  // A subscription that fires on a regular interval
  // using the intervalTimeMs variable.
  interval$: Subscription = null;

  // The time when we sent out a CheckExisting message.
  // We're not maintaing a seperate interval timer for this purpose
  // but instead we just want to send a CheckExisting message, to
  // prevent duplicate tab session data, every so often just in case
  // the one we sent in ngOnInit gets missed due to a tab freezing up
  // and other similar scenarios.
  // (See the this.doDuplicationWorkaround method for more details.)
  lastCheckExistingTime: number = Date.now().valueOf();

  constructor(private readonly store: Store<RootStoreState.State>) {
    this.initObservables();
    this.initSubscriptions();
  }

  ngOnDestroy() : void {
    this.onDisabled();

    this.elector?.die();
    this.elector = null;

    this.channel?.close();
    this.channel = null;
  }

  enable(): void
  {
    this.setEnabled(true);
  }

  disable(): void
  {
    this.setEnabled(false);
  }

  setEnabled(enabled: boolean) : void
  {
    this.store.dispatch(
      DuplicateSessionDetectorActions.SetIsEnabled({
        payload: {
          enabled: enabled
        }
      })
    );
  }

  onEnabled(): void
  {
    if (this.detectorIsEnabled)
      return;

    this.initBroadcastChannel();
    this.initIntervalSubscription();

    // Set isActive to true in the store
    // so that the modal is not prematurely
    // displayed even if it doesn't need to be.
    // This could be jarring for the end user
    // if they see the modal briefly even if
    // they only have one tab open.
    this.store.dispatch(
      DuplicateSessionDetectorActions.SetIsTabActive({
        payload: {
          isActive: true
        }
      })
    );

    // Post out a blind message so that we can prevent issues with duplicate tabs.
    // (See the this.doDuplicationWorkaround method for more details.)
    this.store.pipe(
      select(DuplicateSessionDetectorSelectors.getTabGuid),
      tap((guid) => {
        this.postBlindMessage({
          type: DuplicateSessionMessageType.CheckExisting,
          guid: guid
        });
      }),
      take(1)
    ).subscribe();

    // Execute a heartbeat right away to say hello to the other tabs
    this.onHeartBeatTimerInterval();
  }

  onDisabled(): void
  {
    if (!this.detectorIsEnabled)
      return;

    this.sayGoodBye();

    this.clearDuplicateSessionModal();

    this.interval$?.unsubscribe();
    this.interval$ = null;
  }

  initBroadcastChannel() : void
  {
    if (!!this.channel && !this.channel?.isClosed)
      return;

    this.channel = new BroadcastChannel(this.broadcastChannelName);

    this.elector = createLeaderElection(this.channel);
    this.elector.awaitLeadership();

    this.channel.onmessage = msg => {
      this.onReceiveMessage(msg);
    };
  }

  initObservables() : void
  {
    this.activeModalComponent$ = this.store.select(ModalsSelectors.getModalComponent);

    this.tabGuid$ = this.store.select(DuplicateSessionDetectorSelectors.getTabGuid);
    this.tabIsActive$ = this.store.select(DuplicateSessionDetectorSelectors.getIsTabActive);
    this.tabIsLeader$ = this.store.select(DuplicateSessionDetectorSelectors.getIsTabLeader);
    this.tabCreated$ = this.store.select(DuplicateSessionDetectorSelectors.getTabCreated);
    this.detectorIsEnabled$ = this.store.select(DuplicateSessionDetectorSelectors.getIsEnabled);

    this.messages$ = new Subject<DuplicateSessionMessage>();
  }

  initSynchronizationSubscription() : void
  {
    this.store.pipe(
      withLatestFrom(
        this.tabGuid$,
        this.tabIsLeader$,
        this.tabIsActive$,
        this.tabCreated$,
        this.detectorIsEnabled$
      ),
      tap(([_, guid, isLeader, isActive, tabCreated, isEnabled]) => {
        this.tabGuid = guid;
        this.tabIsLeader = isLeader;
        this.tabIsActive = isActive;
        this.tabCreated = tabCreated

        if (isEnabled && !this.detectorIsEnabled)
        {
          this.onEnabled();
        }
        else if(!isEnabled && this.detectorIsEnabled)
        {
          this.onDisabled();
        }

        this.detectorIsEnabled = isEnabled;
      }),
      untilDestroyed(this)
    ).subscribe();
  }

  initModalSubscription() : void
  {
    this.store.pipe(
      withLatestFrom(
        this.activeModalComponent$,
        this.tabIsActive$,
        this.detectorIsEnabled$
      ),
      map(([_, modal, tabIsActive, isEnabled]) => {
        const modalIsActive =
          modal === DuplicateSessionModalComponent;

        return {
          modalIsActive: modalIsActive,
          tabIsEnabled: tabIsActive,
          detectorIsEnabled: isEnabled
        };
      }),
      tap((state) => {
        // If the service is disabled but the modal is active...
        if (!state.detectorIsEnabled && state.modalIsActive)
        {
          // ... then clear the modal since we are disabled.
          this.clearDuplicateSessionModal();
        }
        // If the service is enabled, the modal is inactive and the tab is inactive...
        else if (state.detectorIsEnabled && !state.modalIsActive && !state.tabIsEnabled)
        {
          // ... then display the modal since the tab is inactive.
          this.displayDuplicateSessionModal();
        }
        // If the service is enabled, the modal is active but the tab is active...
        else if (state.detectorIsEnabled && state.modalIsActive && state.tabIsEnabled)
        {
          // ... then clear the modal since the tab is active.
          this.clearDuplicateSessionModal();
        }
      }),
      untilDestroyed(this)
    ).subscribe();
  }

  doIHaveMethod(funcName: string) : boolean
  {
    const func = this[funcName];
    return (!!func && typeof func === 'function');
  }

  isMessageValid(msg: DuplicateSessionMessage) : boolean
  {
    return (!!msg && Object.values(DuplicateSessionMessageType).includes(msg?.type));
  }

  initMessageSubscription() : void
  {
    this.messages$.pipe(
      withLatestFrom(
        this.detectorIsEnabled$
      ),
      filter(([_msg, isEnabled]) => isEnabled),
      map(([msg, _isEnabled]) => msg),
      tap((msg) => {
        if (!this.isMessageValid(msg))
        {
          this.nonProdWarnMsg(`Received invalid message of type ${msg?.type ?? 'null'}`);
        }
      }),
      // Filter out null messages and messages that have an unknown type.
      filter((msg) => this.isMessageValid(msg)),
      // Generate a name for the method to call on ourselves.
      map((msg) => {
        const methodName: string =
          this.messageCallbackFunctionName.replace('{MSGTYPE}', DuplicateSessionMessageType[msg.type]);
        return {
          methodName: methodName,
          payload: msg
        };
      }),
      tap((message) => {
        if (!this.doIHaveMethod(message.methodName))
        {
          this.nonProdWarnMsg(`No method '${message.methodName}' exists for message '${DuplicateSessionMessageType[message.payload.type]}'`);
        }
      }),
      // Filter it out if there is no such function.
      filter((message) => this.doIHaveMethod(message.methodName)),
      // If there is a function then make the call.
      tap((message) => {
        this[message.methodName](message.payload);
      }),
      untilDestroyed(this)
    ).subscribe();
  }

  initWindowEventSubscription() : void
  {
    fromEvent(window, 'beforeunload').pipe(
      withLatestFrom(
        this.tabGuid$,
        this.tabIsActive$,
        this.tabIsLeader$,
        this.detectorIsEnabled$
      ),
      filter(([_, _tabGuid, _tabActive, _tabLeader, isEnabled]) => isEnabled),
      tap(([_, tabGuid, tabActive, tabLeader]) => {
        this.sayGoodBye(tabGuid, tabActive, tabLeader);
      }),
      untilDestroyed(this)
    ).subscribe();
  }

  sayGoodBye(
    tabGuid: string = this.tabGuid,
    tabActive: boolean = this.tabIsActive,
    tabLeader: boolean = this.tabIsLeader
  ) : void
  {
    // Let everyone know we're saying goodbye.

    this.postMessage({
      type: DuplicateSessionMessageType.Deactivate,
      guid: tabGuid
    });

    // But just in case we happen to be the leader
    // and also the active tab, we'll want to make the oldest tab
    // the active tab (which the new leader will do anyway)
    // so there will not be much of a visible delay to the user.

    if (tabActive && tabLeader)
    {
      this.asLeaderPromoteOldestTabToActive(tabGuid);
    }
  }

  initIntervalSubscription(): void
  {
    if (!!this.interval$ && !this.interval$.closed)
      return;

    this.interval$ = interval(this.intervalTimeMs).pipe(
      tap(() => {
        this.onHeartBeatTimerInterval();
      }),
      untilDestroyed(this)
    ).subscribe();
  }

  initSubscriptions() : void
  {
    this.initSynchronizationSubscription();
    this.initModalSubscription();
    this.initMessageSubscription();
    this.initWindowEventSubscription();
  }

  setLeader(isLeader: boolean) : void
  {
    this.store.dispatch(
      DuplicateSessionDetectorActions.SetIsTabLeader({
        payload: {
          isLeader: isLeader
        }
      })
    );
  }

  setActive(isActive: boolean) : void
  {
    this.store.dispatch(
      DuplicateSessionDetectorActions.SetIsTabActive({
        payload: {
          isActive: isActive
        }
      })
    );
  }

  onElectedAsLeader() : void
  {
    this.setLeader(true);
  }

  onLostLeadership() : void
  {
    this.setLeader(false);
  }

  asLeaderPromoteOldestTabToActive(except?: string) : void
  {
    if (!this.tabIsLeader)
      return;

    const state = this.getStorageState();

    const now = Date.now().valueOf();

    state.tabs =
      state.tabs
        // Filter out any specific tab (if required. e.g. in initWindowEventSubscription)
        .filter((t) => except === undefined || except !== t.guid)
        // Filter out all the stale tabs we haven't heard from in awhile
        .filter((t) => (now - t.timeStamp) <= this.considerTabDeadIfDelayTimeIsGreatherThanMs)
        // Set isActive to false in all of the filtered tabs
        .map((t) => {
          return {
            ...t,
            isActive: false
          }
        })
        // Sort the tabs from oldest to newest
        .sort((a, b) => a.created - b.created);

    // If we have any filtered tabs left then promote
    // the oldest one to the active tab.
    if (state.tabs.length > 0)
    {
      state.tabs[0].isActive = true;

      this.postMessage({
        type: DuplicateSessionMessageType.Activate,
        guid: state.tabs[0].guid
      });
    }

    // Store our updated tab data into local storage
    this.setStorageState(state);
  }

  onHeartBeatTimerInterval() : void
  {
    const now = Date.now().valueOf();

    this.postMessage({
      type: DuplicateSessionMessageType.HeartBeat,
      guid: this.tabGuid,
      state: {
        guid: this.tabGuid,
        isActive: this.tabIsActive,
        timeStamp: now,
        created: this.tabCreated
      }
    });

    if ((now - this.lastCheckExistingTime) >= this.checkExistingIntervalTimeMs)
    {
      this.lastCheckExistingTime = now;

      // Post out a blind message so that we can prevent issues with duplicate tabs.
      // (See the this.doDuplicationWorkaround method for more details.)
      this.postBlindMessage({
        type: DuplicateSessionMessageType.CheckExisting,
        guid: this.tabGuid
      });
    }

    const isDead = this.isElectorDead();
    const isLeader = this.isElectorLeader();

    if (!isDead)
    {
      if (!isLeader && this.tabIsLeader)
      {
        this.onLostLeadership();
      }
      else if (isLeader && !this.tabIsLeader)
      {
        this.onElectedAsLeader();
      }
      else if (isLeader && this.tabIsLeader)
      {
        this.onHeartBeatLeaderTimerInterval();
      }
    }
  }

  isElectorDead() : boolean
  {
    return this.elector?.isDead;
  }

  isElectorLeader() : boolean
  {
    return this.elector?.isLeader;
  }

  onHeartBeatLeaderTimerInterval() : void
  {
    if (!this.tabIsLeader)
      return;

    this.asLeaderPromoteOldestTabToActive();
  }

  nonProdErrorMsg(msg: string) : void
  {
    if (environment.production)
      return;

    window.console.error(msg);
  }

  nonProdWarnMsg(msg: string) : void
  {
    if (environment.production)
      return;

    window.console.warn(msg);
  }

  checkMessageValidForHeardEvent(msg: DuplicateSessionMessage, caller: string, expectedType: DuplicateSessionMessageType) : boolean
  {
    if (!!!msg)
    {
      this.nonProdErrorMsg(
        `Unexpected null or undefined message in '${caller}'`
      );
      return false;
    }

    if (msg.type !== expectedType) {
      this.nonProdErrorMsg(
        `Unexpected message of type '${DuplicateSessionMessageType[msg.type]}' in ` +
        `'${caller}' when type '${DuplicateSessionMessageType[expectedType]}' ` +
        `was expected.`
      );
      return false;
    }

    return true;
  }

  onHeardHeartBeatMessage(msg: DuplicateSessionMessage) : void
  {
    if (!this.checkMessageValidForHeardEvent(
      msg,
      'onHeardHeartBeatMessage',
      DuplicateSessionMessageType.HeartBeat)) {
      return;
    }

    if (!this.tabIsLeader)
      return;

    const state = this.getStorageState();

    const tabIndex = state.tabs.findIndex(
      (t) => t.guid === msg.state.guid);

    if (tabIndex !== -1)
    {
      if (state.tabs[tabIndex].timeStamp < msg.state.timeStamp)
      {
        state.tabs[tabIndex] = msg.state;
      }
    }
    else
    {
      state.tabs.push(msg.state);
    }

    this.setStorageState(state);
  }

  onHeardActivateMessage(msg: DuplicateSessionMessage) : void
  {
    if (!this.checkMessageValidForHeardEvent(
      msg,
      'onHeardActivateMessage',
      DuplicateSessionMessageType.Activate)) {
      return;
    }

    this.setActive(msg.guid === this.tabGuid);
  }

  onHeardDeactivateMessage(msg: DuplicateSessionMessage) : void
  {
    if (!this.checkMessageValidForHeardEvent(
      msg,
      'onHeardDeactivateMessage',
      DuplicateSessionMessageType.Deactivate)) {
      return;
    }

    this.asLeaderPromoteOldestTabToActive(msg.guid);
  }

  onHeardYesExistingMessage(msg: DuplicateSessionMessage) : void
  {
    if (!this.checkMessageValidForHeardEvent(
      msg,
      'onHeardYesExistingMessage',
      DuplicateSessionMessageType.YesExisting)) {
      return;
    }

    if (msg.guid !== this.tabGuid)
      return;

    this.doDuplicationWorkaround();
  }

  onHeardCheckExistingMessage(msg: DuplicateSessionMessage) : void
  {
    if (!this.checkMessageValidForHeardEvent(
      msg,
      'onHeardCheckExistingMessage',
      DuplicateSessionMessageType.CheckExisting)) {
      return;
    }

    if (msg.guid !== this.tabGuid)
      return;

    this.postBlindMessage({
      type: DuplicateSessionMessageType.YesExisting,
      guid: this.tabGuid
    });
  }

  // So Chrome has this unique behavior where if a tab is duplicated
  // via the right-click "Duplicate Tab" it will also duplicate session
  // storage to the newly cloned tab which can mess things up for us.
  // In the event we receive a YesExisting message, this method will
  // be called to generate some new values in the store.
  // This ensures that all tabs always have a unique identifier.
  doDuplicationWorkaround() : void
  {
    const actions = [
      DuplicateSessionDetectorActions.SetTabGuid({
        payload: {
          guid: Guid.create().toString()
        }
      }),
      DuplicateSessionDetectorActions.SetTabCreated({
        payload: {
          created: Date.now().valueOf()
        }
      })
    ];

    actions.forEach((action) => this.store.dispatch(action));
  }

  onReceiveMessage(msg: DuplicateSessionMessage) : void
  {
    if (!this.detectorIsEnabled)
      return;

    this.messages$.next(msg);
  }

  // Posts both a message to the channel
  // and our messages$ observable.
  postMessage(msg: DuplicateSessionMessage)
  {
    this.channel?.postMessage(msg);

    // Post out to the messages observables
    // since we don't hear our own messages
    // from the channel.

    this.messages$?.next(msg);
  }

  // Posts a message only to the channel without
  // posting it back to ourselves via our messages$
  // observable.
  postBlindMessage(msg: DuplicateSessionMessage)
  {
    this.channel?.postMessage(msg);
  }

  getStorageState() : DuplicateStorageTabState
  {
    return (JSON.parse(localStorage.getItem(this.localStorageKeyName)) as DuplicateStorageTabState) || {
      tabs: []
    };
  }

  setStorageState(state: DuplicateStorageTabState) : void
  {
    localStorage.setItem(this.localStorageKeyName, JSON.stringify(state));
  }

  displayDuplicateSessionModal() : void
  {
    if (!this.detectorIsEnabled)
      return;

    this.store.dispatch(
      ModalsActions.SetStandaloneModalComponent({
        payload: {
          component: DuplicateSessionModalComponent,
          shouldFade: false,
          useBackgroundOverlay: true
        },
      })
    );
  }

  clearDuplicateSessionModal() : void
  {
    if (!this.detectorIsEnabled)
      return;

    this.store.dispatch(
      ModalsActions.ClearModalComponentIfOpen({
        payload: {
          component: DuplicateSessionModalComponent
        }
      })
    );
  }
}
