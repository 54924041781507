<div class="generic-confirm-modal">
  <div class="generic-confirm-modal__header">
    <h3 data-testid="all-documents-signed-title" class="modal-title">Complete Your Session</h3>
  </div>
  <div class="generic-confirm-modal__body">
    <div>
      <p data-testid="all-documents-signed-message" >
        Let all participants know that the signing will be completed, and that the audio and video
        feed will end. Inform borrowers that signed documents can be downloaded for their records.
      </p>
      <p data-testid="all-documents-signed-message2" >
        If you click <strong>Complete Session</strong>, you will no longer see the other
        participants. You will be taken to a page where you can download a video of this session,
        and notarization information.
      </p>
    </div>
  </div>
  <div class="generic-confirm-modal__footer">
    <app-button-container>
      <app-button
        type="secondary"
        (click)="backClick()"
        [dataTestId]="'all-documents-signed-go-back-button'"
      >
        Back
      </app-button>
      <app-button
        appDebounceClick
        (debouncedClick)="completeSession()"
        [debounceTimer]="500"
        type="primary"
        [dataTestId]="'all-documents-signed-complete-session-button'"
      >
        <span>Complete Session</span>
      </app-button>
    </app-button-container>
  </div>
</div>
