import { Action, createReducer, on } from '@ngrx/store';

import { AudioVideoCheckActions } from '../actions';
import { AudioVideoCheckState } from '../state';

export const filterAudioDevices = (device) =>
  device.kind !== 'audioinput' && device.kind !== 'audiooutput';

export const filterVideoInputDevices = (device) => device.kind !== 'videoinput';

const reducer = createReducer(
  AudioVideoCheckState.initialState,
  on(
    AudioVideoCheckActions.SaveSessionCapableSuccessful,
    (state, { packageGuid }): AudioVideoCheckState.State => ({
      ...state,
      isAudioVideoCheckComplete: true,
      packageGuid: packageGuid,
    })
  ),
  on(
    AudioVideoCheckActions.EnumerateDevicesSuccess,
    (state, { devices }): AudioVideoCheckState.State => ({
      ...state,
      error: null,
      audioVideoDevices: devices,
    })
  ),
  on(
    AudioVideoCheckActions.GetUserMediaAudioFailure,
    (state, { error }): AudioVideoCheckState.State => ({
      ...state,
      audioVideoDevices: state.audioVideoDevices.filter(filterAudioDevices),
      error: String(error),
    })
  ),
  on(
    AudioVideoCheckActions.GetUserMediaVideoFailure,
    (state, { error }): AudioVideoCheckState.State => ({
      ...state,
      audioVideoDevices: state.audioVideoDevices.filter(filterVideoInputDevices),
      error: String(error),
    })
  ),
  on(
    AudioVideoCheckActions.SetMediaStream,
    (state, { mediaStream }): AudioVideoCheckState.State => ({
      ...state,
      error: null,
      mediaStream,
    })
  )
);

export function audioVideoCheckReducer(
  state: AudioVideoCheckState.State | undefined,
  action: Action
) {
  return reducer(state, action);
}
