import { Injectable } from '@angular/core';
import { Route, Router } from '@angular/router';
import { Store } from '@ngrx/store';

import { of } from 'rxjs';
import { concatMap } from 'rxjs/operators';

import { INITIAL_ROUTE_PATH_SESSION_KEY } from 'src/app/features/shared/constants';

import { MsalAuthService } from '../services/msal-auth.service';
import { MsalAuthActions, MsalAuthState } from '../store';

@Injectable({
  providedIn: 'root',
})
export class MsalAuthGuard {
  private static isProtectedPath(route: Route): boolean {
    return ['ipen', 'kron', 'ron', 'hybrid', 'consumer-portal', 'pre-sign'].some(
      (path) => route.path.toLowerCase() === path
    );
  }

  private static isRouteRestricted(route: Route): boolean {
    const initialRoutePath = sessionStorage.getItem(INITIAL_ROUTE_PATH_SESSION_KEY);
    return !(
      MsalAuthGuard.isProtectedPath(route) &&
      initialRoutePath &&
      initialRoutePath !== route.path &&
      !(initialRoutePath === 'consumer-portal' && route.path === 'pre-sign')
    );
  }

  private isAuthenticated: boolean;

  constructor(
    private readonly msalAuthStore: Store<MsalAuthState.State>,
    private readonly msalAuthService: MsalAuthService,
    private readonly router: Router
  ) {}

  canLoad(route: Route) {
    return this.authGuardResult(route);
  }

  private authGuardResult(route: Route) {
    const initialRoutePath = sessionStorage.getItem(INITIAL_ROUTE_PATH_SESSION_KEY);
    const msalErrorDescription = sessionStorage.getItem('msal.error.description');
    return this.msalAuthService.handleRedirectObservable().pipe(
      concatMap(() => {
        if (msalErrorDescription) {
          if (!this.isAuthenticated && msalErrorDescription.indexOf('AADB2C90118') > -1) {
            sessionStorage.removeItem('msal.error.description');
            this.msalAuthService.resetPassword();
          }
        }

        if (this.msalAuthService.user) {
          if (!MsalAuthGuard.isRouteRestricted(route)) {
            this.router.navigate(['/invalid-link'], {
              replaceUrl: true,
            });
            return of(false);
          }

          this.msalAuthStore.dispatch(
            MsalAuthActions.LoginSuccessful({
              payload: { user: this.msalAuthService.user },
            })
          );
          return of(true);
        }

        if (!this.isAuthenticated) {
          if (
            !initialRoutePath &&
            ['ipen', 'kron', 'hybrid', 'ron'].some((pattern) => route.path === pattern)
          ) {
            this.router.navigate(['/invalid-link'], {
              replaceUrl: true,
            });
          } else {
            sessionStorage.setItem(INITIAL_ROUTE_PATH_SESSION_KEY, route.path);
            this.msalAuthStore.dispatch(MsalAuthActions.Login({ payload: { route } }));
          }
          return of(false);
        }
        return [];
      })
    );
  }
}
