import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { SharedModule } from 'src/app/features/shared';
import { _windowFactory, windowToken } from 'src/app/window.token';

import {
  DocumentsCompleteComponent,
  DocumentScoreboardComponent,
  DocumentTitleComponent,
  DocumentViewerComponent,
  DownloadExecutingClosingPackageButtonComponent,
  SmartDocumentViewerComponent,
} from './components';
import { ViewportSyncDirective } from './directives';
import { CompletedDocumentService, DocumentsService, PreviewDocumentService } from './services';
import { DocumentsEffects, DocumentsReducers } from './store';

const providerServices = [DocumentsService, CompletedDocumentService, PreviewDocumentService];

const providerFactories = [
  {
    provide: windowToken,
    useFactory: _windowFactory,
  },
];

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    SharedModule,
    StoreModule.forFeature('documents', DocumentsReducers.documentsReducer),
    EffectsModule.forFeature([DocumentsEffects.DocumentEffects])
  ],
  providers: [...providerServices, ...providerFactories],
  declarations: [
    DocumentViewerComponent,
    DocumentTitleComponent,
    DocumentScoreboardComponent,
    DocumentsCompleteComponent,
    SmartDocumentViewerComponent,
    ViewportSyncDirective,
    DownloadExecutingClosingPackageButtonComponent,
  ],
  exports: [
    DocumentViewerComponent,
    DocumentTitleComponent,
    DocumentsCompleteComponent,
    DocumentScoreboardComponent,
    ViewportSyncDirective,
    DownloadExecutingClosingPackageButtonComponent,
  ],
})
export class DocumentsModule {}
