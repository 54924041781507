<ng-container *ngIf="refreshOffer; else pleaseWaitTemplate">
  <app-info-icon></app-info-icon>

  <section>
    <header>
      <span>Hmm... Still not working</span>
    </header>
    <div>
      <p>
        We might be having a technical issue. Don't worry, your signing session is still active. Try
        refreshing your browser to help re-establish any lost connections.
      </p>
      <p>
        If the problem persists, please contact support: <app-support-phone></app-support-phone>
      </p>
    </div>

    <footer>
      <app-button matSnackBarAction type="secondary" (click)="handleRefresh()">Refresh</app-button>
    </footer>
  </section>
</ng-container>

<ng-template #pleaseWaitTemplate>
  <app-notifications-icon></app-notifications-icon>

  <section>
    <header>
      <span>Please Wait</span>
    </header>
    <div>
      <p>
        This is taking longer than expected, we're working to resolve it. Thank you for your
        patience.
      </p>
    </div>
  </section>
</ng-template>
