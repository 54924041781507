import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {
  AbstractControl,
  UntypedFormControl,
  UntypedFormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { combineLatest, Observable, Subject } from 'rxjs';
import { distinctUntilChanged, filter, map, take, takeUntil, tap } from 'rxjs/operators';

import { CommissionConsentContent, ConsentsSelectors } from 'src/app/features/consents';
import { EndorsementsCreationActions, SignatureType } from 'src/app/features/endorsements-creation';
import { PackageUser, PackageUsersSelectors } from 'src/app/features/package-users';
import { AbstractWizardStepComponent, CheckInTaskCode, WizardStep } from 'src/app/features/wizard';
import { CertificateValidationComponent } from 'src/app/features/pin-validation';
import { RulesActions, RulesResult, RulesSelectors } from 'src/app/features/rules';
import { RootStoreState } from 'src/app/store';

import { Commission, County } from '../../models';
import { CommissionSelectionActions, CommissionSelectionSelectors } from '../../store';

enum ComponentToDisplay {
  DigitalCertificateExpired = 'DigitalCertificateExpired',
  CertificatePasswordLimitExceeded = 'CertificatePasswordLimitExceeded',
  CommissionSelection = 'CommissionSelection',
}

@Component({
  selector: 'app-commission-selection',
  templateUrl: './commission-selection.component.html',
  styleUrls: ['./commission-selection.component.scss'],
  providers: [
    {
      provide: AbstractWizardStepComponent,
      useExisting: CommissionSelectionComponent,
    },
  ],
})
export class CommissionSelectionComponent
  extends AbstractWizardStepComponent
  implements OnDestroy, OnInit
{
  @Input() user: PackageUser;
  @ViewChild('commissioncontainer') commissionContainer!: ElementRef;

  availableCommissions$: Observable<Commission[]>;
  expiredCommissions: Record<string, Commission> = {};
  availableCounties$: Observable<County[]>;
  selectedCommission$: Observable<Commission>;
  selectedCommissionConsent$: Observable<CommissionConsentContent>;
  certificateCredentialsSaved$: Observable<boolean>;
  isCommissionSelected: boolean;
  isAutoSelectedCommission: boolean;
  autoSelectedCommissionState: string;
  selectedCommissionStateCode: string;
  commissionWithoutSignature: Commission;
  isNotaryCertificatePasswordRequired = true;
  onDestroyNotifier = new Subject();
  componentToDisplay$: Observable<ComponentToDisplay>;
  isPasswordLimitExceeded$?: Observable<boolean>;
  rules?: RulesResult;
  signatureType: SignatureType = SignatureType.Unknown;

  consentText = 'I Agree';
  consentErrorText = 'Please check "I Agree" to the above to continue.';

  constructor(private readonly store: Store<RootStoreState.State>) {
    super(store);
    this.configureStep();
  }

  ngOnInit() {
    super.ngOnInit();
    this.loadCommissionDetails();
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.onDestroyNotifier.next(undefined);
    this.onDestroyNotifier.complete();
  }

  private configureStep() {
    this.stepMetadata = {
      stepName: 'Commission & Signing Location',
      checkInTaskCode: CheckInTaskCode.CommissionSelection,
    } as WizardStep;

    this.formStep = new UntypedFormGroup({
      stateCommission: new UntypedFormControl('', [
        Validators.required,
        this.nonExpiredCommissionValidator(),
      ]),
      county: new UntypedFormControl('', Validators.required),
      consentCheckbox: new UntypedFormControl('', Validators.requiredTrue),
    });
  }

  private loadCommissionDetails() {
    this.store.dispatch(CommissionSelectionActions.GetCommissions());

    this.availableCommissions$ = this.store.pipe(
      select(CommissionSelectionSelectors.getCommissions),
      tap((commissions) => {
        this.setExpiredCommissions(commissions);
        if (commissions?.length === 1) {
          this.isAutoSelectedCommission = true;
          this.autoSelectedCommissionState = commissions[0].stateCode;
          this.autoSetCommission(commissions[0].stateCode);
        }
      })
    );

    this.availableCounties$ = this.store.pipe(select(CommissionSelectionSelectors.getCounties));

    this.selectedCommission$ = this.store.pipe(
      select(CommissionSelectionSelectors.getSelectedCommission),
      tap((commission) => {
        if (commission !== null) {
          this.commissionWithoutSignature = commission;
          this.commissionWithoutSignature.signature = '';
        }
      })
    );

    this.selectedCommissionConsent$ = this.store.pipe(
      select(ConsentsSelectors.getCommissionConsent)
    );

    combineLatest([
      this.store.pipe(select(CommissionSelectionSelectors.getCertificateCredentialsSaved)),
    ])
      .pipe(
        takeUntil(this.onDestroyNotifier),
        filter(([areCertificateCredentialsSaved]) => areCertificateCredentialsSaved),
        take(1),
        tap(() => {
          this.applyCommissionAndNavigate();
        })
      )
      .subscribe();

    this.isPasswordLimitExceeded$ = combineLatest([
      this.store.pipe(
        select(PackageUsersSelectors.getActivePinAttemptsRemaining),
        map((rem) => !rem),
        take(1)
      ),
      this.store.pipe(select(CommissionSelectionSelectors.isCertificatePasswordLimitExceeded)),
    ]).pipe(map(([initial, exceeded]) => initial || exceeded));

    this.componentToDisplay$ = combineLatest([
      this.store.pipe(select(CommissionSelectionSelectors.isDigitalCertificateExpired)),
      this.isPasswordLimitExceeded$,
    ]).pipe(
      map(([isDigitalCertificateExpired, isPasswordLimitExceeded]) => {
        if (isDigitalCertificateExpired) {
          return ComponentToDisplay.DigitalCertificateExpired;
        } else if (isPasswordLimitExceeded) {
          return ComponentToDisplay.CertificatePasswordLimitExceeded;
        } else {
          return ComponentToDisplay.CommissionSelection;
        }
      })
    );

    this.store
      .pipe(
        select(CommissionSelectionSelectors.getSelectedCommissionIsConfirmed),
        takeUntil(this.onDestroyNotifier),
        filter((isConfirmed) => !!isConfirmed),
        take(1),
        tap(() => super.goToNextStep())
      )
      .subscribe();
  }

  commissionSelected(stateCode: string) {
    this.isCommissionSelected = true;
    this.selectedCommissionStateCode = stateCode;
    this.consentCheckbox.reset();

    if (this.counties.dirty) {
      this.counties.setValue('');
      this.counties.markAsUntouched();
      this.counties.markAsPristine();
    }

    if (!this.expiredCommissions[stateCode]) {
      this.store.dispatch(
        CommissionSelectionActions.SetSelectedCommissionByStateCode({
          stateCode,
        })
      );
    }

    this.configureSigningAgentRules();
  }

  autoSetCommission(stateCode: string) {
    this.stateCommission.setValue(stateCode);
    this.stateCommission.markAsDirty();
    this.commissionSelected(stateCode);
  }

  countySelected($event) {
    this.store.dispatch(
      CommissionSelectionActions.SetSelectedCounty({
        payload: {
          county: $event.target.value,
        },
      })
    );
  }

  setExpiredCommissions(commissions: Commission[]) {
    if (!commissions || commissions.length === 0) {
      return;
    }

    const dateInPast = (firstDate, secondDate) =>
      firstDate.setHours(0, 0, 0, 0) < secondDate.setHours(0, 0, 0, 0);

    const today = new Date();

    const expiredCommissionsList = commissions.filter((commission) => {
      if (!commission.expiresOn) {
        return true;
      }

      return dateInPast(commission.expiresOn, today);
    });

    const expiredCommissions: Record<string, Commission> = {};
    expiredCommissionsList.forEach((commission) => {
      expiredCommissions[commission.stateCode] = commission;
    });

    this.expiredCommissions = expiredCommissions;
  }

  goToNextStep(payload?: any): void {
    this.validateStep();

    if (this.formStep.valid) {
      this.attemptToApplyCommissionAndNavigate();
      return;
    }

    this.markVisibleInputsAsDirty();

    const commissionErrorMessage =
      this.commissionContainer?.nativeElement?.querySelector('.error-message');

    if (commissionErrorMessage) {
      commissionErrorMessage.scrollIntoView({ behavior: 'smooth' });
    }
  }

  markVisibleInputsAsDirty() {
    if (this.isCommissionSelected) {
      this.counties.markAsDirty();
      this.consentCheckbox.markAsDirty();
    } else {
      this.stateCommission.markAsDirty();
    }
  }

  get consentCheckbox() {
    return this.formStep.get('consentCheckbox');
  }

  get counties() {
    return this.formStep.get('county');
  }

  get stateCommission() {
    return this.formStep.get('stateCommission');
  }

  nonExpiredCommissionValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const expired = this.expiredCommissions[control.value];
      return expired ? { expiredCommission: { value: control.value } } : null;
    };
  }

  configureSigningAgentRules() {
    const modifiedPropertiesForSigningAgent = {
      stateCode: this.selectedCommissionStateCode,
    };

    this.store.dispatch(
      RulesActions.GetRulesForUser({
        payload: {
          packageUser: this.user,
          modifiedProperties: modifiedPropertiesForSigningAgent,
        },
      })
    );

    this.store
      .select(
        RulesSelectors.getRulesForUserRole(
          this.user.userRoleCode,
          Object.keys(modifiedPropertiesForSigningAgent)
        )
      )
      .pipe(
        takeUntil(this.onDestroyNotifier),
        filter((rules) => !!rules && !!rules.result),
        distinctUntilChanged(),
        tap((rules) => {
          this.rules = rules.result;
          this.signatureType = this.getSignatureTypeFromRules();
        })
      )
      .subscribe();
  }

  attemptToApplyCommissionAndNavigate() {
    const isNotaryCertificatePasswordRequired = this.rules.commission.isCertificateUploadEnabled;
    if (isNotaryCertificatePasswordRequired) {
      CertificateValidationComponent.openModal(this.store, {
        inputPackageUserGuid: this.user.packageUserGuid,
        inputCommissionStateCode: this.selectedCommissionStateCode,
        errorMessage: '',
      });
    } else {
      this.applyCommissionAndNavigate();
    }
  }

  getSignatureTypeFromRules(): SignatureType {
    if (this.rules.commission.isSignatureUploadEnabled) {
      return SignatureType.Uploaded;
    }
    if (this.rules.signature.isDrawnSignatureEnabled) {
      return SignatureType.Drawn;
    }
    return SignatureType.Selected;
  }

  applyCommissionAndNavigate() {
    this.store.dispatch(
      CommissionSelectionActions.SetSelectedCommission({
        payload: this.commissionWithoutSignature,
      })
    );

    this.store.dispatch(CommissionSelectionActions.ApplyCommissionToOrder());
    this.store.dispatch(
      EndorsementsCreationActions.SetSignatureType({
        payload: this.signatureType,
      })
    );
  }
}
