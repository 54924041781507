import { createReducer, on } from '@ngrx/store';

import { LobbyActions } from '../actions';
import { LobbyState } from '../state';

export const lobbyReducer = createReducer(
  LobbyState.initialState,
  on(LobbyActions.FetchLobbyDetailsSuccessful, (state: LobbyState.State, { payload }) => ({
    ...state,
    packageUsers: payload,
  })),
  on(LobbyActions.StartCheckIn, (state: LobbyState.State, { payload }) => ({
    ...state,
    currentPackageUserLobbyGuid: payload.packageUserGuid,
  })),
  on(LobbyActions.ClearCurrentParticipantCheckIn, (state: LobbyState.State) => ({
    ...state,
    currentPackageUserLobbyGuid: null,
  }))
);
