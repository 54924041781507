import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';

import { IdVerificationResult, UserAddressInformation } from '../../models';

@Component({
  selector: 'app-address-information',
  templateUrl: './address-information.component.html',
  styleUrls: ['./address-information.component.scss'],
})
export class AddressInformationComponent implements OnInit, OnChanges {
  @Input() addressInformation: UserAddressInformation | IdVerificationResult;
  streetAddress: string;
  isSignerOrderInfo: boolean;

  constructor() {}

  ngOnInit(): void {
    this.updateAddressInformation();
  }

  ngOnChanges(changes: SimpleChanges) {
    for (const propName in changes) {
      if (changes.hasOwnProperty(propName) && changes.addressInformation) {
        this.updateAddressInformation();
      }
    }
  }

  updateAddressInformation() {
    if (this.isUserAddressInfoModel(this.addressInformation)) {
      this.streetAddress = this.addressInformation.streetAddress2
        ? `${this.addressInformation.streetAddress1} ${this.addressInformation.streetAddress2}`
        : `${this.addressInformation.streetAddress1}`;
      this.isSignerOrderInfo = true;
    } else {
      this.streetAddress = this.addressInformation.streetAddress;
      this.isSignerOrderInfo = false;
    }
  }

  isUserAddressInfoModel(
    addressInfo: UserAddressInformation | IdVerificationResult
  ): addressInfo is UserAddressInformation {
    return (addressInfo as UserAddressInformation).streetAddress1 !== undefined;
  }
}
