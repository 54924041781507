<!--TODO: User Story 50434: Remove Old Endorsement Application Logic-->
<span [appRemoveIfCachedFeatureEnabled]="'NewEndorsementApplication'"
      class="endorsement-flag endorsement-flag--required"
      *ngIf="
    endorsementLocation?.isSignableInCurrentSession &&
    endorsementLocation?.statusCode !== 'SIGNED' &&
    !endorsementLocation?.attempted &&
    endorsementLocation?.isRequired
  "
      data-testid="endorsement-flag-req">
  Action Item
</span>

<span [appRemoveIfCachedFeatureEnabled]="'NewEndorsementApplication'"
      class="endorsement-flag endorsement-flag--optional"
      *ngIf="
    endorsementLocation?.isSignableInCurrentSession &&
    endorsementLocation?.statusCode !== 'SIGNED' &&
    !endorsementLocation?.attempted &&
    !endorsementLocation?.isRequired
  "
      data-testid="endorsement-flag-optional">
  Optional Item
</span>
<!--END TODO-->

<span [appRemoveIfCachedFeatureDisabled]="'NewEndorsementApplication'"
      class="endorsement-flag endorsement-flag--required"
      *ngIf="
    endorsementLocation?.isSignableInCurrentSession &&
    endorsementLocation?.statusCode !== 'SIGNED' &&
    endorsementLocation?.isRequired
  "
      data-testid="endorsement-flag-req">
  Action Item
</span>

<span [appRemoveIfCachedFeatureDisabled]="'NewEndorsementApplication'"
      class="endorsement-flag endorsement-flag--optional"
      *ngIf="
    endorsementLocation?.isSignableInCurrentSession &&
    endorsementLocation?.statusCode !== 'SIGNED' &&
    !endorsementLocation?.isRequired
  "
      data-testid="endorsement-flag-optional">
  Optional Item
</span>
