<ng-container *ngIf="isSigningAgent$ | async">
  <img
    class="feedback-image"
    src="../../../assets/images/unsupported-devices.svg"
    data-testid="unsupported-device"
    alt=""
  />
  <div class="message-container">
    <h1 class="message-container__header">Uh Oh... This device is not supported at this time.</h1>
    <p class="message-container__message">
      You may be seeing this message due to one of the reasons below:
    </p>
    <ul class="message-container__message">
      <li>
        Smartphones are not permitted to be used by notaries at this time, if using one, switch to a
        tablet, laptop or desktop.
      </li>
      <li>Your device's model is not supported, you will need to switch to another device.</li>
    </ul>
    <app-further-assistance [showDefaultClientInfo]="true"></app-further-assistance>
  </div>
</ng-container>

<ng-container *ngIf="(isSigningAgent$ | async) === false">
  <img
    class="feedback-image"
    src="../../../assets/images/unsupported-devices.svg"
    data-testid="unsupported-device"
    alt=""
  />
  <div class="message-container">
    <h1 class="message-container__header">
      Uh Oh... This device model is not supported at this time.
    </h1>
    <p class="message-container__message">Please try another device if available.</p>
    <app-further-assistance [showDefaultClientInfo]="true"></app-further-assistance>
  </div>
</ng-container>
