import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { combineLatest, of } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';

import { PackageUserRole } from 'src/app/features/package-users';
import { PackageUsersSelectors } from 'src/app/features/package-users/store/selectors';
import { PackagesSelectors } from 'src/app/features/packages';
import { RootStoreState } from 'src/app/store';
import { parseRelativeUrl } from 'src/app/features/shared';

import { CommissionSelectionService } from '../services/commission-selection.service';

export abstract class CommissionValidityGuardBase {
  constructor(
    private readonly commissionsService: CommissionSelectionService,
    private readonly router: Router,
    private readonly store: Store<RootStoreState.State>
  ) {}

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return combineLatest([
      this.store.select(PackageUsersSelectors.getActivePackageUserGuid),
      this.store.select(PackageUsersSelectors.getActivePackageUser),
      this.store.select(PackagesSelectors.getActivePackageGuid),
    ]).pipe(
      filter(
        ([activePackageUserGuid, activeUser, activePackageGuid]) =>
          !!!activePackageUserGuid || !!activeUser
      ),
      switchMap(([activePackageUserGuid, activeUser, activePackageGuid]) => {
        if (activeUser?.userRoleCode !== PackageUserRole.SIGNINGAGENT) {
          return of(true);
        }
        return this.commissionsService.getValidCommissionsExist().pipe(
          map((validCommissionsExist) => {
            if (!this.validCommissionTriggersRedirect(validCommissionsExist)) {
              return true;
            }
            return this.router.createUrlTree(
              [parseRelativeUrl(state.url, this.relativeDestinationRoute)],
              {
                queryParams: {
                  package: activePackageGuid,
                  user: activeUser.packageUserGuid,
                },
              }
            );
          })
        );
      })
    );
  }

  protected abstract relativeDestinationRoute: string;
  protected abstract validCommissionTriggersRedirect(hasValidCommission: boolean): boolean;
}
