import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { UserTask } from '../models/usertasks.model';

@Injectable()
export class UserTaskService {
  constructor(private readonly httpClient: HttpClient) {}

  getActiveUserTasks(PackageUserGuid: string): Observable<Array<UserTask<any>>> {
    return this.httpClient.get<Array<UserTask<any>>>(`tasks/dashboard/${PackageUserGuid}`);
  }
}
