import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';

import { RootStoreState } from 'src/app/store';
import { PackageUsersActions } from 'src/app/features/package-users';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-development-package-user',
  template: '',
})
export class SetDevelopmentPackageUserComponent implements OnInit {
  constructor(
    private readonly store: Store<RootStoreState.State>,
    private readonly router: Router,
    private readonly route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.route.queryParams.toPromise().then((params) => {
      if (params && params.packageuser) {
        this.store.dispatch(
          PackageUsersActions.SetActivePackageUserGuid({
            payload: { activePackageUserGuid: params.packageuser },
          })
        );

        setTimeout(() => {
          this.router.navigateByUrl('/');
        }, 500);
      }
    });
  }
}
