import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { filter, first, takeUntil, tap } from 'rxjs/operators';

import { PendoLocationService } from 'src/app/features/pendo-adapter';
import { PackageUsersActions } from '../../package-users';
import { SignalRSelectors } from '../../signal-r';

import { CheckInTaskCode, WizardStep } from '../models';
import { WizardActions, WizardSelectors } from '../store';

@Component({
  template: '',
})
export class AbstractWizardStepComponent implements OnDestroy, OnInit {
  public formStep: UntypedFormGroup;
  public stepMetadata: WizardStep;
  public showStep = false;
  public stepHasBeenShown = false;

  private readonly destroyNotifier = new Subject();
  public currentTaskCode: CheckInTaskCode;
  public previousTaskCode: CheckInTaskCode;

  constructor(private readonly _store: Store) {}

  ngOnInit(): void {
    this._store
      .select(WizardSelectors.getCurrentTaskCode)
      .pipe(
        takeUntil(this.destroyNotifier),
        tap((currentTaskCode) => {
          if (currentTaskCode === this.currentTaskCode) {
            return;
          }

          this.handleStepChange(currentTaskCode);
        })
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.destroyNotifier.next(undefined);
    this.destroyNotifier.complete();
  }

  handleStepChange(updatedTaskCode: CheckInTaskCode) {
    this.previousTaskCode = this.currentTaskCode;
    this.currentTaskCode = updatedTaskCode;

    this.determineVisibility();

    if (this.isCurrentStep()) {
      this.internalOnStepVisible();
      PendoLocationService.instance.identifySubPage(this.stepMetadata.checkInTaskCode);
    } else if (this.isPreviousStep()) {
      this.onStepHidden();
    }
  }

  determineVisibility() {
    this.showStep = this.isCurrentStep();

    if (this.showStep) {
      this.stepHasBeenShown = true;
    }
  }

  isPreviousStep() {
    return !!this.previousTaskCode && this.previousTaskCode === this.stepMetadata.checkInTaskCode;
  }

  isCurrentStep() {
    return this.currentTaskCode === this.stepMetadata.checkInTaskCode;
  }

  public validateStep() {
    if (!this.formStep.valid) {
      this.formStep.setErrors({
        error: 'failed validation',
      });
    }
  }

  public goToPreviousStep() {
    this._store.dispatch(WizardActions.GoToPreviousTask());
  }

  public goToNextStep() {
    this._store.dispatch(WizardActions.CompleteCurrentTask());
    this.resetFocus();
  }

  resetFocus() {
    if (document.activeElement instanceof HTMLElement) {
      document.activeElement.blur();
    }
  }

  public internalOnStepVisible(): void {
    this._store
      .select(SignalRSelectors.selectIsSignalRGroupJoined)
      .pipe(
        first(),
        filter((connected) => connected === false),
        tap((_) => {
          this._store.dispatch(PackageUsersActions.FetchPackageUserCheckInStatuses());
        })
      )
      .subscribe();

    this.onStepVisible();
  }

  public onStepHidden(): void {}

  public onStepVisible(): void {}
}
