//#region imports
import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { of } from 'rxjs';
import {
  catchError,
  concatMap,
  exhaustMap,
  map,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { v4 as uuid } from 'uuid';

import {
  ConsentsActions,
  ConsentsSelectors
} from 'src/app/features/consents';
import { DeviceGroupSelectors } from 'src/app/features/device-group';
import { ModalsActions } from 'src/app/features/modals';
import { MsalAuthSelectors } from 'src/app/features/msal-auth';
import {
  NotificationsActions,
  NotificationType,
} from 'src/app/features/notifications';
import { PackageUsersSelectors } from 'src/app/features/package-users';
import { PackagesSelectors } from 'src/app/features/packages';
import { NEXSYS_IDP_CODE } from 'src/app/features/shared/constants';
import { WizardSelectors } from 'src/app/features/wizard/store/selectors';
import { RootStoreState } from 'src/app/store';
import { environment } from 'src/environments/environment';

import {
  ApplyCommission,
  CommissionStamp,
  StateDynamicCommissionStamps,
  VerifyCertificatePasswordResponseModel,
} from '../../models';
import { CommissionSelectionService } from '../../services';
import { CommissionSelectionActions } from '../actions';
import { CommissionSelectionSelectors } from '../selectors';

//#endregion imports

@Injectable()
export class CommissionSelectionEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly commissionSelectionService: CommissionSelectionService,
    private readonly store: Store<RootStoreState.State>
  ) { }

  getCommissions$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CommissionSelectionActions.GetCommissions),
      switchMap(() => {
        return this.commissionSelectionService.getCommissionList().pipe(
          map((payload) =>
            CommissionSelectionActions.GetCommissionsSuccess({ payload })
          ),
          catchError((err) =>
            of(
              NotificationsActions.AddNotification({
                payload: {
                  exception: err,
                  notificationType: NotificationType.Error,
                  id: uuid(),
                  text: 'Failed to get Commissions',
                },
              })
            )
          )
        );
      })
    );
  });

  fetchDynamicStampsRequiredForState$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CommissionSelectionActions.SetSelectedCommissionByStateCode),
      concatLatestFrom(() => [
        this.store.select(CommissionSelectionSelectors.getDynamicCommissions),
      ]),
      switchMap(
        ([action, dynamicCommissions]: [
          any,
          Record<string, StateDynamicCommissionStamps>
        ]) => {
          const cachedResult =
            dynamicCommissions[action.stateCode]?.dynamicStampsRequired;
          if (cachedResult === true || cachedResult === false) {
            return [];
          }
          return this.commissionSelectionService
            .getDynamicStampRequired(action.stateCode)
            .pipe(
              map((dynamicStampsRequired: boolean) =>
                CommissionSelectionActions.SetDynamicCommissionStampsRequired({
                  payload: {
                    stateCode: action.stateCode,
                    dynamicStampsRequired,
                  },
                })
              ),
              catchError((err) =>
                of(
                  NotificationsActions.AddNotification({
                    payload: {
                      exception: err,
                      notificationType: NotificationType.Error,
                      id: uuid(),
                      text:
                        'Failed to get dynamic commission stamps for state.',
                    },
                  })
                )
              )
            );
        }
      )
    );
  });

  fetchCountiesForState$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CommissionSelectionActions.SetSelectedCommissionByStateCode),
      switchMap((action) => {
        return this.commissionSelectionService
          .getCountiesList(action.stateCode)
          .pipe(
            map((payload) =>
              CommissionSelectionActions.GetCountiesSuccess({ payload })
            ),
            catchError((err) =>
              of(
                NotificationsActions.AddNotification({
                  payload: {
                    exception: err,
                    notificationType: NotificationType.Error,
                    id: uuid(),
                    text: 'Failed to get counties',
                  },
                })
              )
            )
          );
      })
    );
  });

  fetchCommissionsConsent$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CommissionSelectionActions.SetSelectedCommissionByStateCode),
      map((action) =>
        ConsentsActions.FetchCommissionsConsentByState({
          payload: action.stateCode,
        })
      )
    );
  });

  putCommission$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CommissionSelectionActions.ApplyCommissionToOrder),
      concatMap((action) =>
        of(action).pipe(
          withLatestFrom(
            this.store.pipe(select(DeviceGroupSelectors.getDeviceCode)),
            this.store.pipe(select(PackagesSelectors.isPreSign)),
            this.store.pipe(select(PackagesSelectors.getActivePackageGuid)),
            this.store.pipe(
              select(ConsentsSelectors.getCommissionConsentTextId)
            ),
            this.store.pipe(
              select(CommissionSelectionSelectors.getSelectedCommission)
            ),
            this.store.pipe(
              select(CommissionSelectionSelectors.getSelectedCounty)
            )
          )
        )
      ),
      switchMap(
        ([
          _,
          deviceCode,
          isPresign,
          activePackageGuid,
          consentTextId,
          commission,
          selectedCounty,
        ]) => {
          const applyCommissionRequest: ApplyCommission = {
            consentTextId,
            county: selectedCounty,
            deviceCode,
            isPreSign: isPresign,
            packageGuid: activePackageGuid,
            stateCode: commission.stateCode,
          };
          return this.commissionSelectionService
            .putCommission(applyCommissionRequest)
            .pipe(
              map((payload) =>
                CommissionSelectionActions.ApplyCommissionToOrderSuccess()
              ),
              catchError((err) =>
                of(
                  NotificationsActions.AddNotification({
                    payload: {
                      exception: err,
                      notificationType: NotificationType.Error,
                      id: uuid(),
                      text: 'Failed to save commission',
                    },
                  })
                )
              )
            );
        }
      )
    );
  });

  fetchDynamicStamp$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CommissionSelectionActions.SetSelectedCounty),
      concatLatestFrom(() => [
        this.store.select(
          CommissionSelectionSelectors.getSelectedCommissionDetails
        ),
        this.store.select(CommissionSelectionSelectors.getDynamicCommissions),
      ]),
      switchMap(([action, selectedCommission, dynamicCommissions]) => {
        if (
          !dynamicCommissions[selectedCommission.stateCode.toUpperCase()]
            ?.dynamicStampsRequired
        ) {
          return [];
        }
        if (
          !!dynamicCommissions[selectedCommission.stateCode.toUpperCase()].countyStamps[
          action.payload.county
          ]
        ) {
          return [];
        }

        const options = { year: 'numeric', month: 'short', day: 'numeric' }; // Sep 1, 2021
        const stampData = {
          stateCode: selectedCommission.stateCode,
          countyName: selectedCommission.county,
          commissionExpiration: selectedCommission.expiresOn.toLocaleDateString(
            'en-US',
            options as any
          ),
          commissionName: selectedCommission.name,
          commissionNumber: selectedCommission.number,
          actingCounty: action.payload.county,
        } as CommissionStamp;

        return this.commissionSelectionService.getStamp(stampData).pipe(
          map((result: any) => {
            return CommissionSelectionActions.SetDynamicCommissionStamp({
              payload: {
                stateCode: stampData.stateCode,
                stamp: {
                  county: action.payload.county,
                  image: result.stampImage,
                },
              },
            });
          }),
          catchError((err) =>
            of(
              NotificationsActions.AddNotification({
                payload: {
                  exception: err,
                  notificationType: NotificationType.Error,
                  id: uuid(),
                  text: 'Failed to get dynamic commission stamp.',
                },
              })
            )
          )
        );
      })
    );
  });

  getSelectedCommission$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CommissionSelectionActions.FetchSelectedCommission),
      concatMap((action) =>
        of(action).pipe(
          withLatestFrom(
            this.store.pipe(select(WizardSelectors.getActiveWizardUserGuid))
          )
        )
      ),
      switchMap(([action, packageUserGuid]) => {
        return this.commissionSelectionService
          .getSelectedCommission(packageUserGuid)
          .pipe(
            map((payload) =>
              CommissionSelectionActions.SetConfirmedCommission({ payload })
            ),
            catchError((err) =>
              of(
                NotificationsActions.AddNotification({
                  payload: {
                    exception: err,
                    notificationType: NotificationType.Error,
                    id: uuid(),
                    text: 'Failed to get Selected Commission',
                  },
                })
              )
            )
          );
      })
    );
  });

  navigateToUpdateCommissionsPage$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CommissionSelectionActions.NavigateToUpdateCommissionsPage),
        withLatestFrom(
          this.store.pipe(select(MsalAuthSelectors.getClientIDPCode))
        ),
        tap(([action, idpClientCode]) => {
          let commissionRoute = '/#/myaccount/manage-commissions';

          if (idpClientCode !== NEXSYS_IDP_CODE) {
            commissionRoute = `${commissionRoute}?client=${idpClientCode}`;
          }

          const updateCommissionUrl = new URL(
            commissionRoute,
            environment.clearsignPortalUrl
          );

          window.open(updateCommissionUrl.toString(), '_blank');
        })
      ),
    { dispatch: false }
  );

  saveCertificateDetails = createEffect(() => {
    return this.actions$.pipe(
      ofType(CommissionSelectionActions.SaveCertificateDetails),
      switchMap((action) => {
        return this.commissionSelectionService.postCertificateDetails(action.payload.password, action.payload.stateCode)
          .pipe(
            map(() =>
              CommissionSelectionActions.SetCertificateCredentialsSaved()
            ),
            catchError((err) =>
              of(
                NotificationsActions.AddNotification({
                  payload: {
                    exception: err,
                    notificationType: NotificationType.Error,
                    id: uuid(),
                    text: 'Failed to post certificate details',
                  },
                })
              )
            )
          );
      })
    );
  });

  certificatePasswordVerification$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CommissionSelectionActions.VerifyCertificatePassword),
      concatMap((action) =>
        of(action).pipe(
          withLatestFrom(
            this.store.pipe(select(PackageUsersSelectors.getActivePackageUserGuid)),
          )
        )
      ),
      exhaustMap(
        ([
          action,
          packageUserGuid,
        ]) =>
          this.commissionSelectionService
            .verifyCertificatePassword(
              action.payload.certificatePassword,
              action.payload.stateCode
            )
            .pipe(
              switchMap((response: VerifyCertificatePasswordResponseModel) => {
                if (!response.isPasswordValid) {
                  return of(CommissionSelectionActions.CertificatePasswordVerificationFail({
                    payload: {
                      passwordVerification: {
                        isPasswordValid: response.isPasswordValid,
                        attemptsRemaining: response.attemptsRemaining,
                        isCertificateExpired: response.isCertificateExpired,
                        canTryAgain: response.canTryAgain,
                      },
                      packageUserGuid
                    }
                  }));
                } else if (response.isCertificateExpired) {
                  return [
                    NotificationsActions.AddNotification({
                      payload: {
                        notificationType: NotificationType.Info,
                        id: uuid(),
                        text: 'Digital Certificate Expired',
                        logInAppInsights: true
                      }
                    }),
                    CommissionSelectionActions.CertificateExpired(),
                    ModalsActions.ClearModalComponent(),
                  ];
                }

                return of(CommissionSelectionActions.CertificatePasswordVerificationPass({
                  payload: {
                    passwordVerification: {
                      isPasswordValid: response.isPasswordValid,
                      attemptsRemaining: response.attemptsRemaining,
                      isCertificateExpired: response.isCertificateExpired,
                      canTryAgain: response.canTryAgain,
                    },
                    packageUserGuid: packageUserGuid,
                  },
                }),
                  CommissionSelectionActions.SaveCertificateDetails({
                    payload: {
                      password: action.payload.certificatePassword,
                      stateCode: action.payload.stateCode
                    }
                  }),
                );
              })
            )
      )
    )
  );
}
