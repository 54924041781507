import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import {
  PackageUserCheckInStatusPipe,
  PackageUserCurrentStatusPipe,
  PackageUserFirstNamePipe,
  PackageUserFormatNamePipe,
  PackageUserRolePipe,
} from './pipes';
import { PackageUsersService } from './services';
import { PackageUsersEffects, PackageUsersReducers } from './store';

@NgModule({
  declarations: [
    PackageUserFirstNamePipe,
    PackageUserFormatNamePipe,
    PackageUserRolePipe,
    PackageUserCheckInStatusPipe,
    PackageUserCurrentStatusPipe,
  ],
  imports: [
    CommonModule,
    StoreModule.forFeature('packageUsers', PackageUsersReducers.Reducers.packageUsersReducer),
    EffectsModule.forFeature([PackageUsersEffects.PackageUsersEffects]),
  ],
  exports: [
    PackageUserFirstNamePipe,
    PackageUserFormatNamePipe,
    PackageUserRolePipe,
    PackageUserCheckInStatusPipe,
    PackageUserCurrentStatusPipe,
  ],
  providers: [PackageUserFirstNamePipe, PackageUserFormatNamePipe, PackageUsersService],
})
export class PackageUsersModule {}
