import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { RulesService } from '../rules/services/rules.service';
import { RulesEffects } from './store/effects';
import { RulesReducers } from './store/reducers';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(
      'rules',
      RulesReducers.rulesReducer
    ),
    EffectsModule.forFeature([RulesEffects.RulesEffects]),
  ],
  providers: [
    RulesService
  ],
})
export class RulesModule { }
