<div
  [hidden]="!showStep"
  [@openClose]="showStep ? 'open' : 'closed'"
>
  <div class="form-step">
    <p *ngIf="bannerText" class="form-step__banner">{{ bannerText }}</p>
    <div class="form-step__body">
      <h2 class="form-step__header" data-testid="check-in-header" *ngIf="!hideHeader">
        {{ stepTitle }}
      </h2>
      <ng-content></ng-content>
    </div>
  </div>
</div>
