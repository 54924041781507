<!--TODO: User Story 50434: Remove Old Endorsement Application Logic-->
<button
  [appRemoveIfCachedFeatureEnabled]="'NewEndorsementApplication'"
  class="signature-endorsement__button"
  *ngIf="
    endorsementLocation?.statusCode !== 'SIGNED' && !endorsementLocation?.attempted;
    else endorsementImageTemplate
  "
  [tabindex]="disableFocus ? -1 : 0"
  (click)="endorsementClicked()"
  [hidden]="!endorsementLocation?.isSignableInCurrentSession"
  [attr.data-testid]="
    'endorsement-signature-' + this.endorsementLocation?.documentEndorsementLocationId
  "
  [disabled]="isEndorsementDisabled"
>
  <ng-template [ngIf]="!isEndorsementDisabled">
    <div>Sign</div>
    <div class="fas fa-pen-nib signature-endorsement__icon"></div>
  </ng-template>
</button>

<ng-template #endorsementImageTemplate>
  <div
    [appRemoveIfCachedFeatureEnabled]="'NewEndorsementApplication'"
    class="signature-endorsement__image-wrapper"
  >
    <img
      [ngClass]="{
        'signature-endorsement__image': true,
        'signature-endorsement__image--hidden': hideIfSigned
      }"
      [src]="'data:image/png;base64,' + endorsementImage?.endorsementImage"
      alt="signature"
      [attr.data-testid]="
        'endorsement-signature-completed-' + this.endorsementLocation?.documentEndorsementLocationId
      "
      data-private
    />
  </div>
</ng-template>
<!--END TODO-->

<button
  [appRemoveIfCachedFeatureDisabled]="'NewEndorsementApplication'"
  class="signature-endorsement__button"
  *ngIf="endorsementLocation?.statusCode !== 'SIGNED'; else endorsementImageTemplateNew"
  [tabindex]="disableFocus ? -1 : 0"
  appDebounceClick
  (debouncedClick)="endorsementClicked()"
  [debounceTimer]="500"
  [hidden]="!endorsementLocation?.isSignableInCurrentSession"
  [attr.data-testid]="
    'endorsement-signature-' + this.endorsementLocation?.documentEndorsementLocationId
  "
  [disabled]="shouldDisable"
>
  <ng-template [ngIf]="!isEndorsementDisabled">
    <div>Sign</div>
    <div class="fas fa-pen-nib signature-endorsement__icon"></div>
  </ng-template>
</button>

<ng-template #endorsementImageTemplateNew>
  <div
    [appRemoveIfCachedFeatureDisabled]="'NewEndorsementApplication'"
    class="signature-endorsement__image-wrapper"
  >
    <img
      [ngClass]="{
        'signature-endorsement__image': true,
        'signature-endorsement__image--hidden': hideIfSigned
      }"
      [src]="'data:image/png;base64,' + endorsementImage?.endorsementImage"
      alt="signature"
      [attr.data-testid]="
        'endorsement-signature-completed-' + this.endorsementLocation?.documentEndorsementLocationId
      "
      data-private
    />
    <div
      *ngIf="endorsementLocation?.isSignableOnDevice && !shouldDisable"
      class="signature-endorsement__erase-icon fa-solid fa-circle-xmark"
      appDebounceClick
      (debouncedClick)="eraseEndorsementClicked()"
      [debounceTimer]="500"
    ></div>
  </div>
</ng-template>
