import { Component } from '@angular/core';
import { Store } from '@ngrx/store';

import { CancelSigningModalComponent } from 'src/app/features/layout/components';
import { ModalsActions } from 'src/app/features/modals';
import { RootStoreState } from 'src/app/store';

import { CommissionSelectionActions } from '../../store';

@Component({
  selector: 'app-expired-commission',
  templateUrl: './expired-commission.component.html',
  styleUrls: ['./expired-commission.component.scss'],
})
export class ExpiredCommissionComponent {
  constructor(private readonly store: Store<RootStoreState.State>) {}

  updateCommission() {
    this.store.dispatch(
      CommissionSelectionActions.NavigateToUpdateCommissionsPage()
    );
  }

  cancelSigning() {
    this.store.dispatch(
      ModalsActions.SetStandaloneModalComponent({
        payload: {
          component: CancelSigningModalComponent,
          componentData: {
            fixedCategoryTypeCode: 'NotaryCommissionIssue',
          },
        },
      })
    );
  }
}
