import {
  createAction,
  props
} from '@ngrx/store';

import {
  MultiFactorChallengeError,
  MultiFactorChallengeOptions
} from '../../models';

export const StartMultiFactorChallengeFromModalComponent = createAction(
  '[Multi Factor] Start MultiFactor Challenge From Modal Component',
  props<{
    payload: {
      packageUserGuid: string;
    };
  }>()
);

export const CreateMultiFactorChallenge = createAction(
  '[Multi Factor] Create MultiFactor Challenge Resource',
  props<{
    payload: {
      packageUserGuid: string;
    };
  }>()
);

export const RestartMultiFactorChallenge = createAction(
  '[Multi Factor] Restart MultiFactor Challenge',
  props<{
    payload: {
      errorMessage: string;
    };
  }>()
);

export const MultiFactorChallengeCreated = createAction(
  '[Multi Factor] MultiFactor Challenge Resource Created For Type And User',
  props<{
    payload: {
      packageUserGuid: string;
    };
  }>()
);

export const MultiFactorChallengeSelected = createAction(
  '[Multi Factor] MultiFactor Challenge Type Selected',
  props<{
    payload: {
      selectedChallengeType: MultiFactorChallengeOptions;
    };
  }>()
);

export const ResendMultiFactorChallengeCode = createAction(
  '[Multi Factor] Resend MultiFactor Challenge Code'
);

export const CreateMultiFactorChallengeError = createAction(
  '[Multi Factor] Create MultiFactor Challenge Resource HTTP Error',
  props<{
    packageUserGuid: string,
    payload: MultiFactorChallengeError;
  }>()
);

export const CancelMultiFactorChallenge = createAction(
  '[Multi Factor] Cancel Multifactor Challenge'
);

export const CompleteMultiFactorChallenge = createAction(
  '[Multi Factor] Complete MultiFactor Challenge',
  props<{ payload: { challengeCode: string } }>()
);

export const CompleteMultiFactorChallengeError = createAction(
  '[Multi Factor] Complete MultiFactor Challenge HTTP Error',
  props<{
    packageUserGuid: string,
    payload: MultiFactorChallengeError;
  }>()
);

export const MultiFactorAttemptSuccess = createAction(
  '[Multi Factor] Multi Factor Attempt Succeeded',
  props<{ payload: { packageUserGuid: string } }>()
);

export const MultiFactorAttemptInvalidPin = createAction(
  '[Multi Factor] Multi Factor Attempt Invalid Pin',
  props<{ payload: { attemptsRemaining: number } }>()
);

export const MultiFactorChallengeFailed = createAction(
  '[Multi Factor] Multifactor Challenge Failed'
);
