import {
  DocumentEndorsementLocation,
  UniqueEndorsementImage,
} from '../../models';

export function scaffoldUniqueEndorsementImageModel(
  endorsementLocation: DocumentEndorsementLocation
): UniqueEndorsementImage {
  const uniqueEndorsementImage = {
    endorsementLocationId: endorsementLocation.documentEndorsementLocationId,
    endorsementTypeCode: endorsementLocation.endorsementTypeCode,
  } as UniqueEndorsementImage;

  if (
    endorsementLocation.endorsementImage &&
    endorsementLocation.endorsementValue
  ) {
    uniqueEndorsementImage.endorsementImage =
      endorsementLocation.endorsementImage;
  }

  return uniqueEndorsementImage;
}
