<!--TODO: User Story 50434: Remove Old Endorsement Application Logic-->
<input data-private #freetextinput
       [appRemoveIfCachedFeatureEnabled]="'NewEndorsementApplication'"
       type="text"
       *ngIf="
    (isEditing || !endorsementImage) &&
      endorsementLocation?.statusCode !== 'SIGNED';
    else endorsementImageTemplate
  "
       [(ngModel)]="freeText"
       (focus)="endorsementFocused()"
       (blur)="endorsementBlurred()"
       [disabled]="!this.isEditable"
       class="free-text-endorsement__input"
       [hidden]="!endorsementLocation?.isSignableInCurrentSession"
       [tabindex]="disableFocus ? -1 : 0"
       [attr.data-testid]="'endorsement-textbox-' + this.endorsementLocation?.documentEndorsementLocationId"
       [attr.data-alttestid]="'endorsement-textbox-input-dynamic'" />

<ng-template #endorsementImageTemplate>
  <div [appRemoveIfCachedFeatureEnabled]="'NewEndorsementApplication'"
       class="free-text-endorsement__image-wrapper"
       *ngIf="endorsementLocation?.statusCode !== 'SIGNED'">
    <img [ngClass]="{
        'free-text-endorsement__image': true,
        'free-text-endorsement__image--hidden': hideIfSigned
      }"
         [src]="'data:image/png;base64,' + endorsementImage?.endorsementImage"
         [attr.data-testid]="'endorsement-textbox-completed-' + this.endorsementLocation?.documentEndorsementLocationId"
         alt="" data-private/>
    <span *ngIf="isEditable"
          class="free-text-endorsement__edit-icon fas fa-edit"
          (click)="editClicked()"></span>
  </div>
</ng-template>
<!--END TODO-->

<input data-private #freetextinput
       [appRemoveIfCachedFeatureDisabled]="'NewEndorsementApplication'"
       type="text"
       *ngIf="
    ((!endorsementImage) && endorsementLocation?.statusCode !== 'SIGNED') ||
       (isEditing && endorsementLocation?.statusCode === 'SIGNED');
    else endorsementImageTemplateNew
  "
       [(ngModel)]="freeText"
       (focus)="endorsementFocused()"
       (blur)="endorsementBlurred()"
       [disabled]="!this.isEditable"
       class="free-text-endorsement__input"
       [hidden]="!endorsementLocation?.isSignableInCurrentSession"
       [tabindex]="disableFocus ? -1 : 0"
       [attr.data-testid]="'endorsement-textbox-' + this.endorsementLocation?.documentEndorsementLocationId"
       [attr.data-alttestid]="'endorsement-textbox-input-dynamic'" />

<ng-template #endorsementImageTemplateNew>
  <div [appRemoveIfCachedFeatureDisabled]="'NewEndorsementApplication'"
       class="free-text-endorsement__image-wrapper"
       *ngIf="endorsementLocation?.statusCode === 'SIGNED'">
    <img [ngClass]="{
        'free-text-endorsement__image': true,
        'free-text-endorsement__image--hidden': hideIfSigned
      }"
         [src]="'data:image/png;base64,' + endorsementImage?.endorsementImage"
         [attr.data-testid]="'endorsement-textbox-completed-' + this.endorsementLocation?.documentEndorsementLocationId"
         alt="" data-private/>
    <span *ngIf="isEditable"
          class="free-text-endorsement__edit-icon fas fa-edit"
          (click)="editClicked()"></span>
  </div>
</ng-template>
