import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';

import { of } from 'rxjs';
import { catchError, concatMap, exhaustMap, map, switchMap, withLatestFrom } from 'rxjs/operators';

import { DeviceGroupSelectors } from 'src/app/features/device-group/store/selectors';
import { NotificationsActions, NotificationType } from 'src/app/features/notifications';
import { ExceptionType } from 'src/app/features/notifications/models';
import { WizardSelectors } from 'src/app/features/wizard/store/selectors';
import { RootStoreState } from 'src/app/store';
import { v4 as uuid } from 'uuid';
import { PackagesActions } from '../../../packages/store/actions';

import { PresignConsentsService } from '../../services';
import { ConsentsActions, PresignConsentsActions } from '../actions';
import { ConsentsSelectors } from '../selectors';

@Injectable()
export class PresignConsentsEffects {
  acceptTermsOfService$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PresignConsentsActions.AcceptTermsOfService),
      concatMap((action) =>
        of(action).pipe(
          withLatestFrom(
            this.store.pipe(select(WizardSelectors.getActiveWizardUserGuid)),
            this.store.pipe(select(ConsentsSelectors.getTermsAndConditionsConsent)),
            this.store.pipe(select(ConsentsSelectors.getPrivacyPolicyConsent)),
            this.store.pipe(select(DeviceGroupSelectors.getDeviceCode))
          )
        )
      ),
      exhaustMap(
        ([_, packageUserGuid, termsAndConditionsConsent, privacyPolicyConsent, deviceCode]) => {
          return this.presignConsentsService
            .acceptTermsOfService({
              termsOfUseConsentTextId: termsAndConditionsConsent.consentTextId,
              privacyPolicyConsentTextId: privacyPolicyConsent.consentTextId,
              packageUserGuid: packageUserGuid,
              deviceCode,
            })
            .pipe(
              map(() => ConsentsActions.AcceptTermsOfServiceSuccess()),
              catchError((err) =>
                of(
                  NotificationsActions.AddNotification({
                    payload: {
                      exception: err,
                      notificationType: NotificationType.Error,
                      id: uuid(),
                      text: 'Failed to accept presign terms of service.',
                      exceptionType: ExceptionType.CannotProceed,
                    },
                  })
                )
              )
            );
        }
      )
    )
  );

  declineTermsOfService$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PresignConsentsActions.DeclineTermsOfService),
      concatMap((action) =>
        of(action).pipe(
          withLatestFrom(
            this.store.pipe(select(WizardSelectors.getActiveWizardUserGuid)),
            this.store.pipe(select(ConsentsSelectors.getTermsAndConditionsConsent)),
            this.store.pipe(select(ConsentsSelectors.getPrivacyPolicyConsent)),
            this.store.pipe(select(DeviceGroupSelectors.getDeviceCode))
          )
        )
      ),
      exhaustMap(
        ([_, packageUserGuid, termsAndConditionsConsent, privacyPolicyConsent, deviceCode]) => {
          return this.presignConsentsService
            .declineTermsOfService({
              termsOfUseConsentTextId: termsAndConditionsConsent.consentTextId,
              privacyPolicyConsentTextId: privacyPolicyConsent.consentTextId,
              packageUserGuid: packageUserGuid,
              deviceCode,
            })
            .pipe(
              map(() => PackagesActions.OptOutPackage({ payload: { categoryTypeCode: "ParticipantConsentDecline", comments: "Participant declined terms of service" } })),
              catchError((err) =>
                of(
                  NotificationsActions.AddNotification({
                    payload: {
                      exception: err,
                      notificationType: NotificationType.Error,
                      id: uuid(),
                      text: 'Failed to accept presign terms of service.',
                      exceptionType: ExceptionType.CannotProceed,
                    },
                  })
                )
              )
            );
        }
      )
    )
  );

  getPrivacyPolicysConsent$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PresignConsentsActions.FetchPrivacyPolicyConsent),
      concatMap((action) =>
        of(action).pipe(
          withLatestFrom(
            this.store.pipe(select(WizardSelectors.getActiveWizardUserGuid)),
            this.store.pipe(select(DeviceGroupSelectors.getDeviceCode))
          )
        )
      ),
      switchMap(([_, packageUserGuid, deviceCode]) => {
        return this.presignConsentsService.getPrivacyConsent(packageUserGuid, deviceCode).pipe(
          map((payload) => ConsentsActions.SetPrivacyPolicyConsent({ payload })),
          catchError((err) =>
            of(
              NotificationsActions.AddNotification({
                payload: {
                  exception: err,
                  notificationType: NotificationType.Error,
                  id: uuid(),
                  text: 'Failed to get privacy policy.',
                  exceptionType: ExceptionType.CannotProceed,
                },
              })
            )
          )
        );
      })
    )
  );

  getTermsAndConditionsConsent$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PresignConsentsActions.FetchTermsAndConditionsConsent),
      concatMap((action) =>
        of(action).pipe(
          withLatestFrom(
            this.store.pipe(select(WizardSelectors.getActiveWizardUserGuid)),
            this.store.pipe(select(DeviceGroupSelectors.getDeviceCode))
          )
        )
      ),
      switchMap(([_, packageUserGuid, deviceCode]) => {
        return this.presignConsentsService
          .getTermsAndConditionsConsent(packageUserGuid, deviceCode)
          .pipe(
            map((payload) => ConsentsActions.SetTermsAndConditionsConsent({ payload })),
            catchError((err) =>
              of(
                NotificationsActions.AddNotification({
                  payload: {
                    exception: err,
                    notificationType: NotificationType.Error,
                    id: uuid(),
                    text: 'Failed to get terms and conditions.',
                    exceptionType: ExceptionType.CannotProceed,
                  },
                })
              )
            )
          );
      })
    )
  );

  getEndorsementConsent$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PresignConsentsActions.FetchEndorsementsConsent),
      concatMap((action) =>
        of(action).pipe(
          withLatestFrom(
            this.store.pipe(select(WizardSelectors.getActiveWizardUserGuid)),
            this.store.pipe(select(DeviceGroupSelectors.getDeviceCode))
          )
        )
      ),
      switchMap(([_, packageUserGuid, deviceCode]) => {
        return this.presignConsentsService.getEndorsementsConsent(packageUserGuid, deviceCode).pipe(
          map((payload) => ConsentsActions.SetEndorsementsConsent({ payload })),
          catchError((err) =>
            of(
              NotificationsActions.AddNotification({
                payload: {
                  exception: err,
                  notificationType: NotificationType.Error,
                  id: uuid(),
                  text: 'Failed to get endorsement consent.',
                  exceptionType: ExceptionType.CannotProceed,
                },
              })
            )
          )
        );
      })
    )
  );

  constructor(
    private readonly actions$: Actions,
    private readonly presignConsentsService: PresignConsentsService,
    private readonly store: Store<RootStoreState.State>
  ) {}
}
