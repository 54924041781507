import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';

import { RootStoreState } from 'src/app/store';
import { ModalsActions } from 'src/app/features/modals';

import { EnlargeIdModalComponent } from '../enlarge-id-modal';
import { IdImages } from '../../models';

@Component({
  selector: 'app-id-images',
  templateUrl: './id-images.component.html',
  styleUrls: ['./id-images.component.scss'],
})
export class IdImagesComponent {
  @Input() idImages: IdImages;

  frontRotation = 0;
  backRotation = 0;

  get frontRotationStyle() {
    return `transform: rotate(${this.frontRotation}deg)`;
  }

  get backRotationStyle() {
    return `transform: rotate(${this.backRotation}deg)`;
  }

  get frontEnlargeImageIconClass() {
    return `enlarge-image-icon--rotated${Math.abs(this.frontRotation)}`;
  }

  get backEnlargeImageIconClass() {
    return `enlarge-image-icon--rotated${Math.abs(this.backRotation)}`;
  }

  constructor(private readonly store: Store<RootStoreState.State>) {}

  onRotateFront(): void {
    this.frontRotation = this.frontRotation === -270 ? 0 : (this.frontRotation -= 90);
  }

  onRotateBack(): void {
    this.backRotation = this.backRotation === -270 ? 0 : (this.backRotation -= 90);
  }

  enlargeFrontImage(): void {
    this.store.dispatch(
      ModalsActions.SetModalComponent({
        payload: {
          component: EnlargeIdModalComponent,
          allowManualClose: false,
          useBackgroundOverlay: true,
          componentData: {
            idImage: this.idImages?.front
              ? this.idImages?.front
              : 'assets/images/license-front.png',
            isBase64: !!this.idImages?.front,
            width: 100,
          },
        },
      })
    );
  }

  enlargeBackImage(): void {
    this.store.dispatch(
      ModalsActions.SetModalComponent({
        payload: {
          component: EnlargeIdModalComponent,
          allowManualClose: false,
          useBackgroundOverlay: true,
          componentData: {
            idImage: this.idImages?.back ? this.idImages?.back : 'assets/images/license-back.png',
            isBase64: !!this.idImages?.back,
            width: this.idImages?.back ? 65 : 100,
          },
        },
      })
    );
  }
}
