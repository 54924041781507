export enum PackageStatus {
  READY = 'READY',
  COMPLETE = 'COMPLETE',
  IMPORTING = 'IMPORTING',
  CANCELLED = 'CANCELLED',
  EXPIRED = 'EXPIRED',
  INPROGRESS = 'INPROGRESS',
  OPTOUT = 'OPTOUT',
  IMPORTFAILED = 'IMPORTFAILED',
  SESSIONCOMPLETE = 'SESSIONCOMPLETE',
  VAULTING = 'VAULTING',
  VAULTINGFAILED = 'VAULTINGFAILED',
  DELETED = 'DELETED',
  PROCESSING = 'PROCESSING',
  SIGNING = 'SIGNING',
  SIGNINGFAILED = 'SIGNINGFAILED'
}
