<div class="document-selector">
  <label class="document-selector__label">Go to Document:</label>
  <div
    [ngClass]="{
      'document-selector__dropdown-wrapper': true,
      'document-selector--open': showOptions
    }"
  >
    <div
      data-private
      appDocumentSelectorPopover
      [target]="documentOptions"
      class="document-selector__document-name"
      (click)="showOptionList()"
    >
      <span class="document-selector__active-document"
        >{{ (activePackageDocument$ | async)?.documentName }}</span
      >
      <span
        [@rotatedState]="showOptions"
        class="document-selector__dropdown-icon"
        data-testid="doc-select-icon"
      ></span>
    </div>
    <div #documentOptions data-private class="document-selector__options" appDocumentOptions>
      <div
        class="document-selector__option"
        *ngFor="let packageDocument of packageDocuments$ | async"
        (click)="displayDocument(packageDocument.packageDocumentId)"
      >
        <span class="document-selector__option-name" data-testid="doc-select-name">{{
          packageDocument.documentName
        }}</span>
        <app-document-endorsement-status
          [packageDocument]="packageDocument"
        ></app-document-endorsement-status>
      </div>
    </div>
  </div>
</div>
