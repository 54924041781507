import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import zoomFix from './app/features/shared/util/zoom-fix'

if (environment.production) {
  enableProdMode();
}

// Browser Compatibility fix for Chrome IOS
zoomFix(window);

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
