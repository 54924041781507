import { Action, createReducer, on } from '@ngrx/store';

import { MsalAuthActions } from '../actions';
import { MsalAuthState } from '../state';

const reducer = createReducer(
  MsalAuthState.initialState,
  on(MsalAuthActions.Login, (state: MsalAuthState.State) => ({
    ...state,
    loading: true,
    loaded: false,
  })),
  on(MsalAuthActions.FlowLogin, (state: MsalAuthState.State) => ({
    ...state,
    loading: true,
    loaded: false,
  })),
  on(MsalAuthActions.RefreshTokenForVerifiedUser, (state: MsalAuthState.State) => ({
    ...state,
    loading: true,
    loaded: false,
  })),
  on(MsalAuthActions.SetLoadingStatus, (state: MsalAuthState.State, { payload }) => ({
    ...state,
    loading: payload.isLoading,
    loaded: !payload.isLoading,
  })),
  on(
    MsalAuthActions.LoginSuccessful,
    (state: MsalAuthState.State, { payload }) => ({
      ...state,
      loading: false,
      loaded: true,
      error: null,
      ...payload,
    })
  ),
  on(MsalAuthActions.Logout, (state: MsalAuthState.State) => ({
    ...state,
    ...MsalAuthState.initialState,
  })),
  on(
    MsalAuthActions.SetClientIDPCode,
    (state: MsalAuthState.State, { payload }) => ({
      ...state,
      idpCode: payload.idpCode,
    })
  ),
  on(
    MsalAuthActions.HandleLoginRedirect,
    (state: MsalAuthState.State, { payload }) => ({
      ...state,
      loading: false,
      loaded: true,
      user: payload.user,
      redirectState: payload.redirectState,
    })
  ),
  on(
    MsalAuthActions.UpdateLastVerifiedUserGuid,
    (state: MsalAuthState.State, { payload }) => ({
      ...state,
      lastVerifiedUserGuid: payload.packageUserGuid
    })
  ),
  on(
    MsalAuthActions.KBAPassed,
    (state, { packageUserGuid }): MsalAuthState.State => ({
      ...state,
      verifiedUserGuids: state.verifiedUserGuids
        ? [...state.verifiedUserGuids, packageUserGuid]
        : [packageUserGuid],
    })
  )
);

export function msalAuthReducer(
  state: MsalAuthState.State | undefined,
  action: Action
) {
  return reducer(state, action);
}
