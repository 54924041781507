import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DevelopmentRoutingModule } from './development-routing.module';
import { SetDevelopmentPackageUserComponent } from './containers';

@NgModule({
  declarations: [SetDevelopmentPackageUserComponent],
  imports: [CommonModule, DevelopmentRoutingModule],
  providers: [],
})
export class DevelopmentModule {}
