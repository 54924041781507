import {
  AfterViewChecked,
  Component,
  Input,
  ElementRef,
  ViewChildren,
  QueryList,
  OnDestroy,
  OnInit
} from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators, FormControl } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { tap, takeUntil, filter, take, map } from 'rxjs/operators';

import { PackageUser } from 'src/app/features/package-users';
import { EMAIL_REGEX, EXTENDED_ASCII_REGEX } from 'src/app/features/shared/constants';
import {
  AbstractWizardStepComponent,
  CheckInTaskCode,
  WizardSelectors,
  WizardStep,
} from 'src/app/features/wizard';
import { RootStoreState } from 'src/app/store';

import { SignerDetailsSelectors } from '../../store/selectors';
import { SignerDetailsActions } from '../../store/actions';
import {
  SignerDetailsStatus,
  SignerDetailsSubmission,
  StateList,
} from '../../models/signer-details.model';

export const validationMessages: Record<string, Record<string, string>> = {
  firstName: {
    empty: 'Please enter your first name',
    invalid: 'Your first name must be 128 characters or less',
    invalidFormat: 'Your first name cannot contain non-standard characters',
  },
  lastName: {
    empty: 'Please enter your last name',
    invalid: 'Your last name must be 128 characters or less',
    invalidFormat: 'Your last name cannot contain non-standard characters',
  },
  emailAddress: {
    empty: 'Please enter your email address',
    invalid: 'Please enter your email address like: yourname@example.com',
    invalidFormat: 'Please enter your email address like: yourname@example.com',
  },
  phoneNumber: {
    empty: 'Please enter your phone number',
    invalid: 'Please enter your phone number like: (xxx) xxx-xxxx',
    invalidFormat: 'Please enter your phone number like: (xxx) xxx-xxxx',
  },
  dateOfBirth: {
    empty: 'Please enter either your date of birth OR the last 4 of your SSN',
    invalid: 'Please enter a valid date of birth',
    invalidFormat: 'Please enter a valid date of birth',
    age: 'You must be 16 or older',
  },
  ssn: {
    empty: 'Please enter either your date of birth OR the last 4 of your SSN',
    invalid: 'Please enter exactly 4 digits',
    invalidFormat: 'Please enter exactly 4 digits',
  },
  address1: {
    empty: 'Please enter your street address',
    invalid: 'Your street address must be 100 characters or less',
  },
  city: {
    empty: 'Please enter your city',
  },
  state: {
    empty: 'Select your state',
  },
  zipCode: {
    empty: 'Enter zip code',
    invalid: 'Invalid zip code',
    invalidFormat: 'Must be exactly 5 digits',
  },
};

const serviceErrorMap: Record<string, string> = {
  FirstName: 'firstName',
  LastName: 'lastName',
  Email: 'emailAddress',
  PhoneNumber: 'phoneNumber',
  DateOfBirth: 'dateOfBirth',
  Ssn: 'ssn',
  'ParticipantAddress.StreetAddress1': 'address1',
  'ParticipantAddress.City': 'city',
  'ParticipantAddress.StateCode': 'state',
  'ParticipantAddress.ZipCode': 'zipCode',
};

const serviceErrorCodeMap: Record<string, string> = {
  ERRREQUIRED: 'empty',
  ERRINVALID: 'invalid',
  ERRINVALIDAGE: 'age',
};

type ErrorMapping = Record<string, string>;

type FieldValidity = Record<string, boolean>;

@Component({
  selector: 'app-signer-details',
  templateUrl: './signer-details-step.component.html',
  styleUrls: ['./signer-details-step.component.scss'],
  providers: [
    {
      provide: AbstractWizardStepComponent,
      useExisting: SignerDetailsStepComponent,
    },
  ],
})
export class SignerDetailsStepComponent
  extends AbstractWizardStepComponent
  implements AfterViewChecked, OnDestroy, OnInit
{
  @ViewChildren('errorHint') errorHints: QueryList<ElementRef>;
  @Input() user: PackageUser;

  firstName: FormControl<string | null>;
  lastName: FormControl<string | null>;
  signature: string | null;
  emailAddress: UntypedFormControl;
  phoneNumber: UntypedFormControl;
  dobDay: UntypedFormControl;
  dobMonth: UntypedFormControl;
  dobYear: UntypedFormControl;
  ssn: UntypedFormControl;
  address1: UntypedFormControl;
  address2: UntypedFormControl;
  city: UntypedFormControl;
  state: UntypedFormControl;
  zipCode: UntypedFormControl;

  submitAttempted = false;
  isFormValid = true; // Initially true to hide error messages
  jumpToError = false;

  signerDetailsStatus: SignerDetailsStatus;
  states$: Observable<StateList>;
  errors: ErrorMapping;

  private readonly destroySubject: Subject<boolean> = new Subject<boolean>();

  constructor(private readonly store: Store<RootStoreState.State>) {
    super(store);

    this.stepMetadata = {
      stepName: 'Additional Details Required',
      checkInTaskCode: CheckInTaskCode.SignerDetails,
    } as WizardStep;

    this.firstName = new FormControl('', [
      Validators.required,
      Validators.pattern(EXTENDED_ASCII_REGEX),
    ]);
    this.lastName = new FormControl('', [
      Validators.required,
      Validators.pattern(EXTENDED_ASCII_REGEX),
    ]);
    this.signature = '';
    this.emailAddress = new UntypedFormControl('', [
      Validators.required,
      Validators.pattern(EMAIL_REGEX),
    ]);
    this.phoneNumber = new UntypedFormControl('', [Validators.required]);
    this.dobDay = new UntypedFormControl('', [Validators.pattern(/^\d{1,2}$/)]);
    this.dobMonth = new UntypedFormControl('', [Validators.pattern(/^\d{1,2}$/)]);
    this.dobYear = new UntypedFormControl();
    this.ssn = new UntypedFormControl();
    this.address1 = new UntypedFormControl('', [Validators.required]);
    this.address2 = new UntypedFormControl();
    this.city = new UntypedFormControl('', [Validators.required]);
    this.state = new UntypedFormControl('', [Validators.required]);
    this.zipCode = new UntypedFormControl('', [Validators.required]);

    this.formStep = new UntypedFormGroup({
      firstName: this.firstName,
      lastName: this.lastName,
      emailAddress: this.emailAddress,
      phoneNumber: this.phoneNumber,
      dobDay: this.dobDay,
      dobMonth: this.dobMonth,
      dobYear: this.dobYear,
      ssn: this.ssn,
      address1: this.address1,
      address2: this.address2,
      city: this.city,
      state: this.state,
      zipCode: this.zipCode,
    });

    this.firstName.registerOnChange(this.autoSetSignature.bind(this));
    this.lastName.registerOnChange(this.autoSetSignature.bind(this));
  }

  ngOnInit() : void {
    super.ngOnInit();
  }

  ngAfterViewChecked(): void {
    if (this.jumpToError) {
      if (this.errorHints.length > 0) {
        this.errorHints.get(0).nativeElement.scrollIntoView({ block: 'center' });
      }
      this.jumpToError = false;
    }
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.onStepHidden();
  }

  autoSetSignature() {
    this.signature = `${this.firstName.value} ${this.lastName.value}`;
  }

  onStepVisible(): void {
    this.store
      .select(SignerDetailsSelectors.getSignerDetailsStatus)
      .pipe(
        tap((status) => {
          this.signerDetailsStatus = status;

          this.resetValidators();
        }),
        takeUntil(this.destroySubject)
      )
      .subscribe();

    this.store.dispatch(SignerDetailsActions.FetchStates());
    this.store.dispatch(SignerDetailsActions.LogAdditionalDetailsRequested());
    this.states$ = this.store.select(SignerDetailsSelectors.states).pipe(
      map((states) => {
        return states.sort((a, b) => a.name.localeCompare(b.name));
      })
    );

    this.store
      .select(SignerDetailsSelectors.signerDetailsSubmissionErrors)
      .pipe(
        tap((errors) => {
          this.errors = errors.reduce((acc, val) => {
            const field = serviceErrorMap[val.field];
            let error = serviceErrorCodeMap[val.errorCode];
            if (!validationMessages[field].hasOwnProperty(error)) {
              error = 'invalid';
            }
            return {
              ...acc,
              [field]: validationMessages[field][error],
            };
          }, {});
          this.jumpToError = true;
        }),
        takeUntil(this.destroySubject)
      )
      .subscribe();

    this.store
      .select(SignerDetailsSelectors.areSignerDetailsSubmitted)
      .pipe(
        filter((isSubmitted) => isSubmitted),
        take(1),
        tap(() => super.goToNextStep()),
        takeUntil(this.destroySubject)
      )
      .subscribe();
  }

  onStepHidden(): void {
    this.destroySubject.next(undefined);
    this.destroySubject.complete();
  }

  public goToNextStep(payload?: any): void {
    this.submitAttempted = true;
    this.validateStep();

    // Ensure date of birth is a valid date
    const day = parseInt(this.dobDay.value, 10);
    const month = parseInt(this.dobMonth.value, 10) - 1;
    const year = parseInt(this.dobYear.value, 10);
    const dateOfBirth = new Date(year, month, day);

    const isValidYear = year >= 1753 && year <= 9999;

    const dobSsnValidity: FieldValidity = {
      isDobValid:
        isValidYear &&
        dateOfBirth.getDate() === day &&
        dateOfBirth.getMonth() === month &&
        dateOfBirth.getFullYear() === year,
      isDobEmpty:
        this.isFieldEmpty(this.dobDay) &&
        this.isFieldEmpty(this.dobMonth) &&
        this.isFieldEmpty(this.dobYear),
      isSsnEmpty: this.isFieldEmpty(this.ssn),
    };
    const ignoreSsn = !dobSsnValidity.isSsnEmpty || !this.signerDetailsStatus.isMissingSsn;
    const isDobOrSsnOkayToSubmit =
      dobSsnValidity.isDobValid ||
      !this.signerDetailsStatus.isMissingDateOfBirth ||
      (dobSsnValidity.isDobEmpty && ignoreSsn);

    if (this.formStep.valid && isDobOrSsnOkayToSubmit) {
      this.submitData(dobSsnValidity, dateOfBirth);
    } else {
      this.handleErrors(dobSsnValidity, isDobOrSsnOkayToSubmit);
    }
  }

  private resetValidators(): void {
    if (!this.signerDetailsStatus.isMissingFirstName) {
      this.clearValidators(['firstName']);
      this.firstName.reset({ value: this.user.firstName, disabled: true });
    }
    if (!this.signerDetailsStatus.isMissingLastName) {
      this.clearValidators(['lastName']);
      this.lastName.reset({ value: this.user.lastName, disabled: true });
    }

    if (!this.signerDetailsStatus.isMissingEmail) {
      this.clearValidators(['emailAddress']);
    }

    if (!this.signerDetailsStatus.isMissingDateOfBirth) {
      this.clearValidators(['dobDay', 'dobMonth', 'dobYear']);
    }

    if (!this.signerDetailsStatus.isMissingPhoneNumber) {
      this.clearValidators(['phoneNumber']);
    }

    if (!this.signerDetailsStatus.isMissingAddress) {
      this.clearValidators(['address1', 'city', 'state', 'zipCode']);
    }
  }

  private isFieldEmpty(control: UntypedFormControl): boolean {
    return control.value === null || control.value.trim() === '';
  }

  private submitData(dobSsnValidity: FieldValidity, dateOfBirth: Date): void {
    const dataToSubmit: SignerDetailsSubmission = {};

    if (this.signerDetailsStatus.isMissingFirstName) {
      dataToSubmit.firstName = this.firstName.value;
    }

    if (this.signerDetailsStatus.isMissingLastName) {
      dataToSubmit.lastName = this.lastName.value;
    }

    if (this.signerDetailsStatus.isMissingEmail) {
      dataToSubmit.email = this.emailAddress.value;
    }

    if (this.signerDetailsStatus.isMissingPhoneNumber) {
      dataToSubmit.phoneNumber = this.phoneNumber.value;
    }

    if (this.signerDetailsStatus.isMissingDateOfBirth && dobSsnValidity.isDobValid) {
      dataToSubmit.dateOfBirth = dateOfBirth.toISOString();
    }

    if (this.signerDetailsStatus.isMissingSsn && !dobSsnValidity.isSsnEmpty) {
      dataToSubmit.ssn = this.ssn.value;
    }

    if (this.signerDetailsStatus.isMissingAddress) {
      dataToSubmit.participantAddress = {
        streetAddress1: this.address1.value,
        streetAddress2: this.address2.value,
        city: this.city.value,
        stateCode: this.state.value,
        zipCode: this.zipCode.value,
      };
    }

    this.errors = {};
    this.store.dispatch(SignerDetailsActions.SubmitSignerDetails({ payload: dataToSubmit }));
  }

  private handleErrors(dobSsnValidity: FieldValidity, isDobOrSsnOkayToSubmit: boolean): void {
    this.isFormValid = false;
    this.jumpToError = true;
    this.errors = {};
    Object.keys(validationMessages)
      .filter((key) => key !== 'dateOfBirth')
      .forEach((key) => {
        this.populateWithValidationMessage(key);
      });

    if (!isDobOrSsnOkayToSubmit && dobSsnValidity.isSsnEmpty && dobSsnValidity.isDobEmpty) {
      this.errors.dateOfBirth = validationMessages.dateOfBirth.empty;
      this.errors.ssn = ' ';
    }
    if (
      !dobSsnValidity.isDobEmpty &&
      (!dobSsnValidity.isDobValid || !this.dobDay.valid || !this.dobMonth.valid)
    ) {
      this.errors.dateOfBirth = validationMessages.dateOfBirth.invalid;
    }
  }

  private populateWithValidationMessage(fieldName: string): void {
    const control = this.formStep.get(fieldName);
    if (!control) {
      return;
    }

    if (!control.errors) {
      return;
    }

    if (control.errors.required) {
      this.errors[fieldName] = validationMessages[fieldName].empty;
    }
    if (control.errors.pattern || control.errors.mask) {
      this.errors[fieldName] = validationMessages[fieldName].invalidFormat;
    }
  }

  clearValidators(fields: string[]) {
    fields.forEach((f) => {
      this.formStep.get(f).clearValidators();
      this.formStep.get(f).reset();
    });
  }
}
