import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';

import { Toast, ToastPackage, ToastrService } from 'ngx-toastr';
import { RootStoreState } from 'src/app/store';

// not using the barrel to avoid circular dependencies
import { NotificationOptions } from '../../models/notification-options.model';
import { NotificationsActions } from '../../store/actions';

@Component({
  selector: 'app-toast-modal-component',
  templateUrl: './toast-modal.component.html',
  styleUrls: ['./toast-modal.component.scss'],
})
export class ToastModalComponent extends Toast implements OnInit {
  showReloadButton: boolean;
  showLoadingSpinner: boolean;
  notificationId: string;

  constructor(
    protected toastrService: ToastrService,
    public toastPackage: ToastPackage,
    private readonly store: Store<RootStoreState.State>
  ) {
    super(toastrService, toastPackage);
  }

  ngOnInit() {
    this.showReloadButton =
      (this.options as NotificationOptions)?.reloadButton ?? false;
    this.showLoadingSpinner =
      (this.options as NotificationOptions)?.loadingIcon ?? false;
    this.notificationId =
      (this.options as NotificationOptions)?.notificationId ?? '';
  }

  reload() {
    this.removeToastFromStore();
    window.location.reload();
  }

  removeToast() {
    this.removeToastFromStore();
    this.remove();
  }

  removeToastFromStore() {
    if (this.notificationId) {
      this.store.dispatch(
        NotificationsActions.ClearNotification({
          payload: { notificationId: this.notificationId },
        })
      );
    }
  }
}
