import { Component } from '@angular/core';

@Component({
  selector: 'app-awaiting-participants-modal',
  templateUrl: './awaiting-participants-modal.component.html',
  styleUrls: ['./awaiting-participants-modal.component.scss'],
})
export class AwaitingParticipantsModalComponent {
  static identifier = 'AwaitingParticipantsModalComponent';
  constructor() {}
}
