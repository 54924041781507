import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';

import { UniqueEndorsementImage } from '../../models';

export function selectEndorsementImageEndorsementLocationId(
  endorsementImage: UniqueEndorsementImage
): number {
  return endorsementImage.endorsementLocationId;
}

export const uniqueEndorsementImagesAdapter: EntityAdapter<UniqueEndorsementImage> = createEntityAdapter<
  UniqueEndorsementImage
>({
  selectId: selectEndorsementImageEndorsementLocationId,
  sortComparer: false,
});

export interface UniqueEndorsementImagesState
  extends EntityState<UniqueEndorsementImage> {}

const {
  selectEntities,
  selectAll,
  selectIds,
  selectTotal,
} = uniqueEndorsementImagesAdapter.getSelectors();
export const selectUniqueEndorsementEntities = selectEntities;
export const selectAllUniqueEndorsementImages = selectAll;
export const selectUniqueEndorsementImageIds = selectIds;
