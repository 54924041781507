<div class="whose-here">
  <div>
    <h2>Who's here today?</h2>
    <p *ngIf="hasMultiplenonSigningAgents$ | async">
      Select everyone that will be using this device
    </p>
  </div>

  <div class="participants">
    <app-welcome-participant *ngFor="let nonSa of nonSigningAgentsWithDisplayName$ | async" [participant]="nonSa">
    </app-welcome-participant>
  </div>
  <p class="tip" *ngIf="hasMultiplenonSigningAgents$ | async">
    <b>Tip</b>: If checking in on separate devices, make sure you are in different rooms or
    locations. This will help to prevent audio issues.
  </p>

  <div class="button-container" *ngIf="nonSaWelcomePageButtonVm$ | async as buttonVm">
    <app-button
      *ngIf="buttonVm.isBeginCheckInButtonVisible"
      (buttonClick)="beginCheckIn()"
      [type]="'primary'"
      [ariaLabel]="
        (isUserCheckInSequenceEmpty$ | async)
          ? 'button disabled until participant selection is made'
          : null
      "
      [isDisabled]="isUserCheckInSequenceEmpty$ | async"
      [dataTestId]="'begin-check-in-button'"
      >Begin Check In</app-button
    >
    <app-button
      *ngIf="buttonVm.isGoToWaitingRoomButtonVisible"
      (buttonClick)="goToWaitingRoom()"
      [type]="'primary'"
      [dataTestId]="'go-to-waiting-room-button'"
      >Go to Waiting Room</app-button
    >
    <app-button
      *ngIf="buttonVm.isGoToSigningRoomButtonVisible"
      (buttonClick)="goToSigningRoom()"
      [type]="'primary'"
      [dataTestId]="'go-to-signing-room-button'"
      >Go to Signing Room</app-button
    >
  </div>

  <div class="button-container" *ngIf="nonSaWelcomePageRejoinButtonVm$ | async as rejoinButtonVm">
    <app-button
      *ngIf="rejoinButtonVm.isRejoinButtonVisible"
      (buttonClick)="rejoin()"
      [type]="'primary'"
      [isDisabled]="isUserCheckInSequenceEmpty$ | async"
      [dataTestId]="'rejoin-waiting-room-button'"
      >Attempt to Rejoin Signing</app-button
    >
  </div>
</div>
