<app-nav-bar>
  <ul mobile class="nav-bar__mobile__items">
    <li *ngIf="canViewFaqButton | async" class="nav-bar__mobile__item" data-testid="header-faq">
      <a target="_blank" href="{{ faqUrl }}" rel="noopener">FAQs</a>
    </li>
    <li class="nav-bar__mobile__item" data-testid="header-support-phoneNumber">
      <a target="_blank" href="tel:{{ this.nexsysSupportNumber }}" rel="noopener"
      >Support: {{ this.nexsysSupportNumber }}</a
      >
    </li>
    <li class="nav-bar__mobile__item" *ngIf="canViewDeviceSettingsButton$ | async">
      <button (click)="openChangeAudioVideoModal()" data-testid="header-device-settings">
        Device Settings
      </button>
    </li>

    <li class="nav-bar__mobile__item" *ngIf="canViewOrderDetailsButton$ | async">
      <button (click)="openDetailsModal()" data-testid="header-order-details">Order Details</button>
    </li>
    <li class="nav-bar__mobile__item" data-testid="header-privacy-policy">
      <a target="_blank" href="https://www.amrock.com/privacy-policy" rel="noopener"
      >Privacy Policy</a
      >
    </li>
    <li class="nav-bar__mobile__item" data-testid="header-terms-of-use">
      <a target="_blank" href="https://www.amrock.com/terms-of-use" rel="noopener">Terms of Use</a>
    </li>
    <li class="nav-bar__mobile__item">
      <button (click)="openCancelSigningModal()" data-testid="header-cancel-signing">
        Cancel Signing
      </button>
    </li>
  </ul>

  <app-menu [isDeviceSettingsButtonEnabled]="canViewDeviceSettingsButton$ | async"
            [isOrderDetailsButtonEnabled]="canViewOrderDetailsButton$ | async"
            [isPrivacyPolicyButtonEnabled]="true"
            [isTermsOfUseButtonEnabled]="true"
            [isCancelSigningButtonEnabled]="true"
  ></app-menu>

  <app-frequently-asked-questions-button
    [isFaqButtonEnabled]="canViewFaqButton | async"></app-frequently-asked-questions-button>
  <app-support-phone-number-button></app-support-phone-number-button>


</app-nav-bar>
