import { createFeatureSelector, createSelector } from '@ngrx/store';
import { Client } from '../../models/client.model';

import { ClientState } from '../state';

export const getClientState = createFeatureSelector<ClientState.State>('client');

export const getClientModelForRealId = createSelector(
  getClientState,
  (state: ClientState.State): Client => ({
    supportPhoneNumber: state.supportPhoneNumber,
    clientName: state.clientName,
    isDefault: false,
    shouldBypassRealId: state.shouldBypassRealId
  })
);

export const shouldBypassRealId = createSelector(
  getClientState,
  (state: ClientState.State) => state.shouldBypassRealId
);

export const getClientTheme = createSelector(
  getClientState,
  (state: ClientState.State) => state.clientTheme
);

export const getClientName = createSelector(
  getClientState,
  (state: ClientState.State) => state.clientName
);

export const getClientSupportDetails = createSelector(
  getClientState,
  (state: ClientState.State): Client => {
    return {
      supportPhoneNumber: state.supportPhoneNumber,
      clientName: state.clientName,
      isDefault: false,
      shouldBypassRealId: state.shouldBypassRealId
    };
  }
);
