import { createFeatureSelector, createSelector } from '@ngrx/store';

import { AudioVideoConnectionMonitorSelectors } from 'src/app/features/audio-video-connection-monitor/store/selectors';
import { AudioVideoCheckState } from 'src/app/features/av-check/store/state';

export const getAudioVideoCheckState =
  createFeatureSelector<AudioVideoCheckState.State>('audioVideoCheck');

export const getIsAudioVideoCheckComplete = createSelector(
  getAudioVideoCheckState,
  (state: AudioVideoCheckState.State) => {
    return state?.isAudioVideoCheckComplete ?? null;
  }
);

export const selectGetUserMediaFailure = createSelector(
  getAudioVideoCheckState,
  (state: AudioVideoCheckState.State) => state.error
);

export const selectGetUserMediaAudioFailure = createSelector(selectGetUserMediaFailure, (error) =>
  error && error.includes ? error.includes('Audio') : false
);

export const selectGetUserMediaVideoFailure = createSelector(selectGetUserMediaFailure, (error) =>
  error && error.includes ? error.includes('Video') : false
);

export const selectAudioVideoDevices = createSelector(
  getAudioVideoCheckState,
  (state: AudioVideoCheckState.State) => state.audioVideoDevices
);

export const selectAudioInputDevices = createSelector(
  selectAudioVideoDevices,
  (audioVideoDevices: MediaDeviceInfo[]) =>
    audioVideoDevices.filter((device) => device.kind === 'audioinput' && device.deviceId !== '')
);

export const selectAudioOutputDevices = createSelector(
  selectAudioVideoDevices,
  (audioVideoDevices: MediaDeviceInfo[]) =>
    audioVideoDevices.filter((device) => device.kind === 'audiooutput' && device.deviceId !== '')
);

export const selectVideoInputDevices = createSelector(
  selectAudioVideoDevices,
  (audioVideoDevices: MediaDeviceInfo[]) =>
    audioVideoDevices.filter((device) => device.kind === 'videoinput' && device.deviceId !== '')
);

export const selectMediaStream = createSelector(
  getAudioVideoCheckState,
  (state: AudioVideoCheckState.State): MediaStream => state.mediaStream
);

export const selectAreInputDevicesSelected = createSelector(
  AudioVideoConnectionMonitorSelectors.getSelectedAudioDevice,
  AudioVideoConnectionMonitorSelectors.getSelectedVideoDevice,
  (hasAudioDevice, hasVideoDevice) => hasAudioDevice && hasVideoDevice
);

export const selectAreInputAndOutputDevicesSelected = createSelector(
  selectAreInputDevicesSelected,
  AudioVideoConnectionMonitorSelectors.getSelectedAudioOutputDevice,
  (hasInputDevices, hasSpeaker) => !!hasInputDevices && !!hasSpeaker
);

export const selectPackageGuidAVCompleted = createSelector(
  getAudioVideoCheckState,
  (state: AudioVideoCheckState.State) => state?.packageGuid ?? null
);
