<a
  mat-button
  class="phone-button"
  href="tel:{{ this.clearSignSupportNumber }}"
  tabIndex="0"
  data-testid="header-support-phoneNumber"
  rel="noopener"
  title="Support Phone Number"
>
  {{ this.clearSignSupportNumber }}
</a>
