import { createAction, props } from '@ngrx/store';

import {
  SignerDetailsFormData,
  SignerDetailsStatus,
  SignerDetailsSubmission,
  SignerDetailsSubmissionErrors,
  StateList,
} from '../../models';

export const FetchSignerDetailsStatusSuccessful = createAction(
  '[Signer Details] Fetch Signer Details Status Successful',
  props<{ payload: SignerDetailsStatus }>()
);

export const UpdateSignerDetailsForm = createAction(
  '[Signer Details] Update Signer Details Form',
  props<{ payload: SignerDetailsFormData }>()
);

export const SubmitSignerDetails = createAction(
  '[Signer Details] Submit Signer Details',
  props<{ payload: SignerDetailsSubmission }>()
);

export const SubmitSignerDetailsSuccess = createAction(
  '[Signer Details] Submit Signer Details Success'
);

export const SubmitSignerDetailsError = createAction(
  '[Signer Details] Submit Signer Details Error',
  props<{ payload: SignerDetailsSubmissionErrors }>()
);

export const MultifactorVerificationSuccess = createAction('[Signer Details] Verification Success');

export const FetchStates = createAction('[Signer Details] Fetch States');

export const FetchStatesSuccessful = createAction(
  '[Signer Details] Fetch States Success',
  props<{ payload: StateList }>()
);

export const LogAdditionalDetailsRequested = createAction(
  '[Signer Details] Log Additional Details Requested'
);

export const LogAdditionalDetailsRequestedSuccess = createAction(
  '[Signer Details] Log Additional Details Requested Success'
);
