import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { DuplicateSessionDetectorService } from 'src/app/features/duplicate-session-detector';

import { SideBarContent } from '../components/side-bar';

@Component({
  selector: 'app-pre-session-layout',
  templateUrl: './pre-session-layout.component.html',
  styleUrls: ['./pre-session-layout.component.scss'],
})
export class PreSessionLayoutComponent implements OnInit, OnDestroy {
  public useAudioVideoConnectionMonitor = false;
  public sideBarDynamicContent: SideBarContent;

  constructor(
    private readonly route: ActivatedRoute,
    private readonly dupDetectorService: DuplicateSessionDetectorService,
  ) {}

  ngOnInit() {
    this.useAudioVideoConnectionMonitor = this.shouldUseAudioVideoConnectionMonitor();

    if (this.shouldUseDuplicateSessionDetector()) {
      this.dupDetectorService.enable();
    } else {
      this.dupDetectorService.disable();
    }

    this.sideBarDynamicContent = this.route.snapshot?.data?.sideBarContent;
  }

  ngOnDestroy() {
    this.dupDetectorService.disable();
  }

  shouldUseAudioVideoConnectionMonitor(): boolean {
    return (
      this.route.snapshot?.data && !!this.route.snapshot.data['useAudioVideoConnectionMonitor']
    );
  }

  shouldUseDuplicateSessionDetector(): boolean {
    return this.route.snapshot?.data && !!this.route.snapshot.data['useDuplicateSessionDetector'];
  }
}
