<div class="mfa-challenge">
  <div class="mfa-challenge__options" *ngIf="(multiFactorInProgress$ | async) === false; else inputTemplate">
    <div class="message">To verify your identity, Clear Sign will send you a security code.</div>
    <div class="bold-message">Please select where to send your security code</div>
    <div class="form-container">
      <form class="typeform">
        <div class="input-container" *ngIf="this.packageUser.phoneNumber !== null">
          <label for="SMS" class="container" data-testid="mfa-sms">
            <input
              type="radio"
              name="selectedChallengeType"
              (change)="change($event)"
              [value]="multiFactorChallengeOptions.SMS"
              id="SMS"
              [checked]="this.packageUser?.phoneNumber"
            />
            <span> Text to (***) ***-{{ this.packageUser.phoneNumber }} </span>
          </label>
        </div>
        <div class="input-container" *ngIf="this.packageUser.phoneNumber !== null">
          <label for="PhoneCall" class="container" data-testid="mfa-phonecall">
            <input
              type="radio"
              name="selectedChallengeType"
              (change)="change($event)"
              [value]="multiFactorChallengeOptions.PHONE"
              id="PhoneCall"
              class="call-checkbox"
            />
            <span> Call to (***) ***-{{ this.packageUser.phoneNumber }} </span>
          </label>
        </div>
        <div class="input-container" *ngIf="this.packageUser.emailAddress !== null">
          <label
            for="Email"
            class="container"
            [title]="packageUser.emailAddress"
            data-testid="mfa-email"
          >
            <input
              type="radio"
              name="selectedChallengeType"
              (change)="change($event)"
              [value]="multiFactorChallengeOptions.EMAIL"
              id="Email"
              class="email-checkbox"
              [checked]="!this.packageUser?.phoneNumber"
            />
            <span> Email to {{ this.packageUser.emailAddress }} </span>
          </label>
        </div>
      </form>

      <div *ngIf="errorMessage$ | async as errorMessage" class="error mfa-challenge__error">
        {{ errorMessage }}
      </div>
    </div>

    <div class="footer-controls">
      <app-button-container>
        <app-button
          type="tertiary"
          [dataTestId]="'mfa-cancel'"
          (click)="cancelMultiFactorChallenge()"
          >Cancel</app-button
        >
        <app-button
          type="primary"
          [dataTestId]="'mfa-continue'"
          (click)="createMultiFactorChallenge()"
          >Continue</app-button
        >
      </app-button-container>
    </div>
  </div>
  <ng-template #inputTemplate>
    <div *ngIf="(this.challengeCompleteLoading$ | async) === false">
      <div class="message validation" data-private>
        {{ this.packageUser.firstName }} {{ this.packageUser.lastName }}, please enter your security
        code.
      </div>
      <div
        *ngIf="(this.currentlySelectedChallengeType$ | async) === 'Email'"
        class="mfa-challenge-spam-info"
      >
        If you haven't received your security code, please check your junk/spam folder.
      </div>
      <div class="mfa-challenge__input-wrapper">
        <input
          [(ngModel)]="challengeCode"
          class="form-control"
          maxlength="6"
          keyboardType="number"
          data-testid="mfa-enter"
          placeholder="Enter your security code"
          data-private
        />
        <app-button
          type="tertiary"
          [dataTestId]="'mfa-resend'"
          (click)="resendMultiFactorChallenge()"
          >Resend Code</app-button
        >
      </div>
      <h4 class="error" *ngIf="(attemptsRemaining$ | async) !== null">
        Invalid Security Code. {{ attemptsRemaining$ | async }} attempts remaining
      </h4>
      <h4
        class="error"
        *ngIf="
          userCodeValidationState !== codeValidationStates.hide &&
          userCodeValidationState === codeValidationStates.invalid
        "
      >
        Please wait {{ intervalTimer.getRemainingSeconds() }} seconds before requesting another
        code.
      </h4>
      <h4
        class="valid"
        *ngIf="
          userCodeValidationState !== codeValidationStates.hide &&
          userCodeValidationState === codeValidationStates.valid
        "
      >
        Code sent successfully.
      </h4>
      <div class="footer-controls">
        <app-button-container>
          <app-button
            type="secondary"
            [dataTestId]="'mfa-challenge-cancel'"
            (click)="cancelMultiFactorChallenge()"
            >Cancel</app-button
          >
          <app-button
            type="primary"
            [dataTestId]="'mfa-challenge-next'"
            (click)="completeMultiFactorChallenge()"
            >Continue</app-button
          >
        </app-button-container>
      </div>
    </div>
    <div class="spinner-container" *ngIf="(this.challengeCompleteLoading$ | async) === true">
      <app-generic-spinner> </app-generic-spinner>
    </div>
  </ng-template>
</div>
