import { createFeatureSelector, createSelector } from '@ngrx/store';

import { GadgetGuideState } from 'src/app/features/gadget-guide/store/state/state';

export const getGadgetGuideState = createFeatureSelector<GadgetGuideState>('gadgetGuide');

export const selectGadgetGuide = createSelector(
  getGadgetGuideState,
  (state: GadgetGuideState) => state
);

export const selectGadgetGuideBySha256 = (sha256: string) =>
  createSelector(selectGadgetGuide, (state: GadgetGuideState) => state?.gadgetGuide ? state.gadgetGuide[sha256] : null);
